import React, { useState, useCallback } from 'react';
import { OnTop } from './ListService';
import styled from "styled-components";
import { Icon } from './UserProfile';
import svgExit from '../../assets/img/exit.svg'
import svgDropdown from '../../assets/img/dropdown.svg'
import PulseLoader from "react-spinners/PulseLoader";
import { WKT } from "ol/format";
import svgArrow from '../../assets/img/onmap-large.svg';
import svgArrests from '../../assets/img/arrests.svg';
import svgObrems from '../../assets/img/obrems.svg';
import svgDownload from '../../assets/img/download-large.svg';
import { useTranslation } from "react-i18next";

export const Objects = styled.div`
    position: absolute;
    left: 33%;
    top: 25%;
    max-height: 500px;
    height: auto;
    width: 700px;
    background-color: #fff;
    border-radius: 7px;
    z-index: 1;
    padding: 9px;
    font-family: 'sf-pro-display-regular',sans-serif;

    .header {
        display: flex;
        margin: 0px 0px 12px 0px;

        h1 {
            text-align: center;
            height: 30px;
            line-height: 30px;
            width: 100%;
            border: 1px solid #B6D4C0;
            border-radius: 5px;
            color: #26602D;
            font-size: 16px;
            margin: 0px 8px 0px 0px;
            font-weight: normal;
            padding-left: 30px;
        }

        button {
            width: 30px;
            height: 30px;
            background: #005A4D;
            border: none;
            border-radius: 5px;
            padding: 0px 0px 0 8px;
        }

        button:focus {
            outline: none;
        }
    }

    .inventory-item {
        margin: 0 0 11px 0;

        .title {
            display: flex;
            background: #B6D4C0;
            color: #26602D;
            line-height: 20px;
            border-radius: 5px;
            padding-left: 6px;

            .name {
                width: 100%;

                p {
                    margin: 0;
                    padding: 8px;
                }
            }

            button {
                width: 34px;
                background: none;
                border: none;
                padding-top: 10px;
            }

            button:focus {
                outline: none;
            }
        }

        .items-list {
            border: 1px solid #B6D4C0;
            display: none;
            border-radius: 0 0 7px 7px;
            border-top: none;

            .object-item {
                display: flex;
                min-height: 30px;
                color: #26602D;
                line-height: 20px;

                .td {
                    border: 1px solid #B6D4C0;
                    width: 50%;
                    padding: 0 0 0 11px;
                    display: block;

                    p {
                        margin: 8px 0px 8px 0px;
                        width: 96%;
                    }

                    button {
                        width: 36px;
                        height: 38px;
                        background: none;
                        border: initial;
                        border: 2px solid #B6D4C0;
                        border-top: none;
                        border-right: none;
                        padding-top: 8px;

                        div {
                            margin: -4px 0 0 0 !important;
                            padding-right: 10px !important;
                            width: 26px !important;
                            height: 26px !important;
                        }
                    }

                    .report-btn {
                        div {
                            margin: 1px 0 0 -3px !important;
                        }
                    }

                    button:focus {
                        outline: none;
                    }
                }
            }

            .last {
                border-radius: 0 0 7px 7px;

                .last-left {
                    border-radius: 0 0 0 7px;
                }

                .last-right {
                    border-radius: 0 0 7px 0;
                }
            }

            .even {
                background: #e2f1e6;
            }
        }
    }

    .inventory-item:last-child {
        margin: 0;
    }

    .show {
        box-shadow: 2px 3px 9px #00000069;
        border-radius: 7px;

        .title {
            border-radius: 5px 5px 0px 0px;
            background: #007743;
            color: #fff;
        }

        .items-list {
            display: block;
        }
    }

    .objects-list {
        overflow: auto;
        max-height: 440px;
        padding: 0 6px;
    }
`;

export default function ProfileObjects({
    profileObjectsLoading,
    profileObjects,
    setShowProfileObjects,
    handleSelectOnMap,
    handleSetRegion,
    getExtData,
    setShowObjectArrests,
    setObjectArrests,
    setObjectArrestsLoading,
    setArrestsKadNum
}) {

    const [showItemsArr, setShowItemsArr] = useState(profileObjects.map(() => false));
    const { t } = useTranslation();

    const handleShowOnMap = (obj) => {
        setShowProfileObjects(false);
        handleSetRegion(obj.district)
            .then(() => {
                setTimeout(() => {
                    handleSelectOnMap(new WKT().readGeometryFromText(obj.coords), 'EPSG:' + obj.district.srs)
                }, 2000);
            });
    }

    const handleExtData = (kadNum, code) => {
        setObjectArrestsLoading(true);
        getExtData([kadNum], code)
            .then(result => {
                console.log(result);
                setObjectArrests(result[kadNum].items);
                setObjectArrestsLoading(false);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleArrestsClick = (obj, code) => {
        setObjectArrests([]);
        setArrestsKadNum(obj.cadastrNum);
        handleExtData(obj.cadastrNum, code);
        setShowProfileObjects(false);
        setShowObjectArrests(true);
    }

    const handleValuation = useCallback((geom, srs, distCode, regCode, cadNum) => {
        fetch(`${window.REST_URL}/map/valuation?srid=${srs}&geom=${geom}&cadNum=${cadNum}&regCode=${regCode}&distCode=${distCode}`, {
            method: "GET",
            mode: "cors",
            credentials: "include"
        })
            .then(response => { window.open(response.url, '_blank'); })
    }, []);

    const listInventory = () => {
        return profileObjects.map((item, i) => (
            <div key={i} className={"inventory-item" + (showItemsArr[i] ? " show" : "")}>
                <div className="title">
                    <div className="name">
                        <p>{t("profile.inventory") + item.id}<br />{item.addrRu}</p>
                    </div>
                    <button
                        onClick={() => {
                            showItemsArr[i] = !showItemsArr[i]
                            setShowItemsArr(showItemsArr.map(item => item));
                        }}
                    >
                        <Icon url={svgDropdown} />
                    </button>
                </div>
                <div className="items-list">
                    {item.objects.map((obj, j) => (
                        <div key={"" + i + j} className={"object-item" + (j % 2 === 1 ? " even" : "") + (j === (item.objects.length - 1) ? " last" : "")}>
                            <div className={"td" + (j === (item.objects.length - 1) ? " last-left" : "")}>
                                <div style={{ display: "flex", height: "35px", float: "right", marginLeft: "6px" }}>
                                    <button
                                        style={{ marginLeft: "-2px !important" }}
                                        onClick={() => handleArrestsClick(obj, 'INFO_ARREST_BY_CDR')}
                                    >
                                        <Icon url={svgArrests} />
                                    </button>
                                    <button
                                        style={{ paddingLeft: "8px" }}
                                        onClick={() => handleArrestsClick(obj, 'INFO_CHARGE_BY_CDR')}
                                    >
                                        <Icon url={svgObrems} />
                                    </button>
                                    {obj.vidEoCode == 1 &&
                                        <button
                                            style={{ paddingTop: "14px", paddingLeft: "9px" }}
                                            onClick={() => handleShowOnMap(obj)}
                                        >
                                            <Icon url={svgArrow} />
                                        </button>
                                    }
                                    {obj.coords &&
                                        <button
                                            style={{ paddingTop: "8px", paddingLeft: "11px" }}
                                            className="report-btn"
                                            onClick={() => {
                                                handleValuation(obj.coords, obj.district.srs, obj.district.code, obj.district.regionCode, obj.cadastrNum)
                                            }}
                                        >
                                            <Icon url={svgDownload} />
                                        </button>
                                    }
                                </div>
                                <p>{obj.celRu}</p>
                            </div>
                            <div className={"td" + (j === (item.objects.length - 1) ? " last-right" : "")}><p>{t("new-obj.cadnum")} {obj.cadastrNum}</p></div>
                        </div>
                    ))}
                </div>
            </div>
        ));
    }

    return (
        <OnTop>
            <Objects>
                <div className="header">
                    <h1>{t("service.my-obj")}</h1>
                    <button
                        onClick={() => setShowProfileObjects(false)}
                    >
                        <Icon url={svgExit} />
                    </button>
                </div>
                {profileObjectsLoading ?
                    <div className="justify-center" style={{ padding: '15px' }}>
                        <PulseLoader size={15} color={"#02c23e"} loading={true} />
                    </div> :
                    <div className="objects-list">{listInventory()}</div>
                }
            </Objects>
        </OnTop>
    );
}