import React, { useState, useEffect, useCallback, useContext, useRef } from "react";
import styled from "styled-components";
import ClipLoader from "react-spinners/ClipLoader";
import { WKT, GeoJSON } from "ol/format";
import { MultiPolygon } from "ol/geom";
import { Scrollbars } from "react-custom-scrollbars";
import garbage from '../../assets/img/garbage-request.svg';
import { ReactComponent as FileUpload } from '../../assets/img/file-upload.svg';
import { ReactComponent as ChooseCoords } from '../../assets/img/choose.svg';
import { ReactComponent as DrawCoords } from '../../assets/img/draw.svg';
import { ReactComponent as DrawFinish} from '../../assets/img/draw-finish.svg'
import { ReactComponent as FileImport} from '../../assets/img/upload.svg'
import { useTranslation } from 'react-i18next';
import { MODE_DRAW, MODE_SELECT_PDP, MODE_OFF, NotificationContext, MODE_EDIT, MODE_SELECT } from "../../EknMap";
import { NF_NOTICE } from "../Notification";
import { ConfirmContext } from "../../EknMap";
import { Feature } from "ol";
import {matchScale} from "../../util";
import { postDoc } from "../../EknMap";
import { getTemplate, download} from "../../utils/RestApi";
import Collapsible from "react-collapsible";
import svgWord from "../../assets/img/word.svg";
import svgExcel from "../../assets/img/excel.svg";
import svgShape from "../../assets/img/shape.svg";
import { NF_ERROR } from "../Notification";


const LandRequestDialog = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: ${props => props.standalone ? "50px" : "90px"} 
  z-index: 1;
  right: ${props => props.standalone ? "140px" : "120px"};
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 75vh;
}

.land-request-wrapper {
  width: 350px;
  padding: 8px 8px;
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 6.63px 5.3px 20px rgba(152, 152, 152, 0.35);
}

.add-padding {
  padding: 8px 16px 8px 8px !important;
}

  * {
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;ry Monoline OT3;
    color: ${props => props.palette.text};  
  }

  .controls {
    background-color: ${props => props.palette.secondary};
    button {
      font-size: 12px;
      padding: 5px;
      margin: 4px;
      border: none;
      outline: none;
      background-color: ${props => props.palette.additional};
      color: ${props => props.palette.text};
      flex: 1;
      text-align: center;
    }
    button:hover {
      cursor: pointer;
      background-color: ${props => props.palette.turq};
    }
  }

  form {
    width: 42px;
    button {
      margin-top: 4px;
      padding: 0 4px;
    }
    background : -webkit-linear-gradient(90deg, rgba(0 124 50) 0%, rgba(0 191 60) 100%);
    background : -webkit-gradient(linear,50% 100.96% ,50% 4.85% ,color-stop(0,rgba(0 124 50) ),color-stop(1,rgba(0 191 60) ));
    background: linear-gradient(0deg,rgb(0 124 50) 0%,rgb(0 191 60) 100%);
    border-radius : 3px;
    -webkit-border-radius : 3px;
    box-shadow : 2px 3px 5px rgba(144, 144, 144, 0.25);
  }

  #file-upload {
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    z-index: -10;
    background-color: ${props => props.palette.secondary};
  }
  
  .request-file-wrapper {
    text-align: center;
    //display: flex;
    justify-content: center;
    align-items:center;
    height: 100%;
    img {
      margin-right: 10px;
      width:18px;
    }
  }

  .request-file-wrapper label {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items:center;
  }
 

  #file-upload-label {
    font-family: 'Open Sans', sans-serif; Monoline OT3;
    margin-right: 10px;
    font-size : 16px;
    border: none;
    outline: none;
    color: ${props => props.palette.text} !important;
    text-align: center;
    width: 100%;
    height: 100%;
    :hover {
      cursor: pointer;
    }
  }
  
  .table-row {
    padding: 0px 8px;
    border-radius : 0 0 3px 3px;
    display: grid;
    grid-template-columns:  9% 43% 41% 6%;

  align-items: center;
 
    input {
      background-color: ${props => props.palette.highgreen};
      color: ${props => props.palette.darkgreen};
      border: none;
      outline: none;
      width: 14ch;
      border-radius: 6px 6px 0 0;
      border-bottom: 1px dashed ${props => props.palette.darkgreen};
      &::placeholder {
        color: ${props => props.palette.primary};
      }
      &:hover {
        border-bottom: 1px solid ${props => props.palette.text};
      }
      &:focus {
        border-bottom: 1px solid ${props => props.palette.text};
      }
    }


    input::placeholder {
      color: ${props => props.palette.darkgreen};
    }
    svg {
    
      cursor: pointer;
      width: 100%;
      text-align: center;
      fill: ${props => props.palette.darkgreen};
      &:hover {
        cursor: pointer;
      }
    }
    background-color: ${props => props.palette.highgreen};
    // :nth-child(odd) {background: #B4D1BD;}
    
    &.table-header {
      margin-top: 5px;
      border-left: 5px solid #02C23E;
      background-color: ${props => props.palette.darkgreen};
  
    }
  }
  .table-header span{
    font-size:15px;
    white-space: nowrap;
    padding: 10px 0px;
  }

  .table-header p {
    color: ${props => props.palette.text};
  }
  .scroll-wrapper {
    width: 100%;
  }
  p {
    color: ${props => props.palette.darkgreen};
  }
}
  .data-field {

    border-radius : 0px 0px 3px 3px;
    div {
      background-color: ${props => props.palette.secondary};
      sup {
        padding: 1px;
        color: #005A2F;
      }
    }
    .request-download-link {
      color: #fff;
      background : -webkit-linear-gradient(-90deg, rgba(2, 194, 62, 1) 0%, rgba(0, 168, 36, 1) 100%);
      background : -webkit-gradient(linear,50% -0.43% ,50% 103.32% ,color-stop(0,rgba(2, 194, 62, 1) ),color-stop(1,rgba(0, 168, 36, 1) ));
      background : linear-gradient(180deg, rgba(2, 194, 62, 1) 0%, rgba(0, 168, 36, 1) 100%);
      border-radius : 3px;
      margin-top: 6px;
      display: flex;
      justify-content: center;
      align-items:center;
      padding: 10px 0;
      text-decoration: none;
      font-family: 'Open Sans', sans-serif;ry Monoline OT3;
      font-size: 15px;
      font-weight: bold;
      }

    h3 {
      background-color: ${props => props.palette.darkgreen};
            border-left: 5px solid #02C23E;
            font-size: 15px;
            padding: 10px 24px;
            margin: 6px 0 0;
            border-radius: 6px
    }
    div {
      background-color: ${props => props.palette.highgreen};
      color: ${props => props.palette.darkgreen};
      padding: 5px 10px ;
    }
    p {
      background-color: ${props => props.palette.highgreen};
      padding: 5px 10px;
      margin:0;
      border-radius : 0px 0px 3px 3px;
      color: ${props => props.palette.darkgreen};
    }
  }
    
  .buttons {
      display: grid;
   a{
      margin-bottom: 6px;
      grid-area: 1/1/1/3;
      border-radius : 3px;
      text-align: center;
      width: 100%;
      text-decoration: none;
      font-size: 13px;
      font-weight: bold;
    }
    
   button{
      border-radius : 3px;
      text-align: center;
      border: 1px solid green;
      height: 32px;
    }
      button:hover{
      cursor: pointer
    }
  }
  .buttons-save {
    display: flex;

    button {
      flex: 1;
      border: none;
    }
  }

  .request-error-header {
    background : -webkit-linear-gradient(-90deg, rgba(237, 28, 36, 1) 0%, rgba(193, 39, 45, 1) 100%);
    background : -webkit-gradient(linear,50% 4.74% ,50% 122.84% ,color-stop(0,rgba(237, 28, 36, 1) ),color-stop(1,rgba(193, 39, 45, 1) ));
    background : linear-gradient(180deg, rgba(237, 28, 36, 1) 0%, rgba(193, 39, 45, 1) 100%);
  }
  .error h3{
    border: none;
    padding: 10px 24px;
    color: #fff;
  }

  .error {
    background : #E8D3D3;
    border-radius : 0px 0px 4px 4px;
    margin: 6px 0;
  }
  .error p {
    background : #E8D3D3;
    color : #C1272D;
    padding: 5px 10px  ;
  }
  .remove-coord {
    cursor: pointer;
  }
  .remove-all-coord svg{
    cursor: pointer;
    fill: #fff;
  }
  .request-download-btn {
    img {
      margin-right: 10px;
      width:18px;
    }
    p {
      margin-bottom: 7px;
    }
  }

  .request-icon {
    padding: 6px;
    border-radius: 3px;
    cursor: pointer;
    width: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;

    svg {
      .cls-1 {
        fill: none;
      }
    }
  }

  .request-icon-select {
    background : -webkit-linear-gradient(90deg, rgba(0 153 33) 0%, rgba(0 191 60) 100%);
    background : -webkit-gradient(linear,50% 100.96% ,50% 4.85% ,color-stop(0,rgba(0 153 33) ),color-stop(1,rgba(0 191 60) ));
    background: linear-gradient(0deg,rgb(0 153 33) 0%,rgb(0 191 60) 100%);
  }

  .request-icon-draw {
    background : -webkit-linear-gradient(90deg, rgba(0 168 36) 0%, rgba(2 194 62) 100%);
    background : -webkit-gradient(linear,50% 100.96% ,50% 4.85% ,color-stop(0,rgba(0 168 36) ),color-stop(1,rgba(2 194 62) ));
    background: linear-gradient(0deg,rgb(0 168 36) 0%,rgb(2 194 62) 100%);
  }

  .segment-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      padding: 0
    }

    .segment-btn {
      background: linear-gradient( 180deg, rgba(247, 147, 30, 1) 0%, rgba(241, 90, 36, 1) 100% );
      border: none;
      border-radius: 5px;
      padding: 3px 10px;
      outline: none;
    }
  }
  .segment-btn-all {
    width: 100%;
    background: linear-gradient(rgb(2, 194, 62) 0%, rgb(0, 168, 36) 100%);
    border: none;
    outline: none;
    padding: 5px;
  }

  .file-import {
    width: 100%;
    margin: 0;
    margin-top: 10px;
  }
  .templates {
    margin-top: 5px;
    display: flex;
    gap: 5px;
    margin-bottom: 10px;

    .templates__item button {
      border-radius: 5px;
      border: 1px solid var(--Gray-4, #BDBDBD);
      background: linear-gradient(180deg, #F5F5F5 0%, #E2E2E2 100%);
      color: #000;
      font-size: 12px;
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
  .file-wrapper {
    display: flex;
    height: 30px;
    font-size: 14px;
    cursor: pointer;

    .file-wrapper-name {
      border: 1px solid rgb(199, 199, 199);
      border-right: none;
      padding: 5px 10px;
      width: 70%;
      display: block;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      color: #000;
    }
    .file-wrapper-label {
      width: 30%;
      background: #02C23E;
      text-align: center;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
  .file-download-btn {
    width: 100%;
    border: none !important;
    outline: none;
    border-radius: 3px;
    text-align: center;
    height: 32px;
    background: linear-gradient(rgb(2, 194, 62) 0%, rgb(0, 168, 36) 100%);
    display: flex;
    justify-content: center;
    align-items: center;
  }
 
`;

export default function RequestDialog(props) {

  const { t } = useTranslation();
  const [coords, setCoords] = useState([]);
  const [newCoord, setNewCoord] = useState(["", ""]);
  const [area, setArea] = useState(0);
  const [errIntersect, setErrIntersect] = useState();
  const [cost, setCost] = useState(0.0);
  const [orderUrl, setOrderUrl] = useState();
  const [valuationUrl, setValuationUrl] = useState();
  const [fileName, setFileName] = useState();
  const [hackStatus, setHackStatus] = useState(1);
  const { notification, setNotification } = useContext(NotificationContext);
  const { confirm, setConfirm } = useContext(ConfirmContext);
  const [showScroll, setShowScroll] = useState(false);
  const source = props.layers.select.getSource();
  const [segments, setSegments] = useState([]);
  const [showSegment, setShowSegment] = useState(false);
  const templates = [{name: t('coordinates.template') + ' txt', ext: 'txt_template', svg: svgWord},{name: t('coordinates.template') + ' Excel', ext: 'xlsx_template', svg: svgExcel},{name: t('coordinates.template') + ' Shape', ext: 'zip_template', svg: svgShape}];
  const [fileCoord, setFileCoord] = useState(t("coordinates.doc"));
  const fileInputRef = useRef();
  const [newFeature, setNewFeature] = useState();
  const [sendLoading, setSendLoading] = useState(false);

  const downloadError = () => {
    setNotification({
      title: t("info-tab.file-error-title"),
      text: t("info-tab.file-donwload-text-cost")
    })
  }

  const handleChange = (event, pi, ri, i, j) => {
    coords[pi][ri][i][j] = event.target.value;
    setCoords(Object.assign([], coords));

    const feature = props.features[0];
    feature.getGeometry().setCoordinates(coords);
    setArea(feature.getGeometry().getArea());
    props.setFeatures([feature]);
  };

  const removeSegments = () => {
    source.getFeatures().forEach(feature => {
      if (feature.get("type") === "segment") {
        source.removeFeature(feature);
      }
    });
  }

  const handleRemoveAll = (pi,ri) => {
    if (pi || pi === 0 || ri || ri ===0) {
      coords[pi].splice(ri, 1);

      if (coords[pi].length === 0) {
        coords.splice(pi,1);
      }

      setCoords(Object.assign([], coords));
      props.features[0].getGeometry().setCoordinates(coords);

      setArea(props.features[0].getGeometry().getArea());
      props.setFeatures([props.features[0]]);
    } else {
      if (props.layers.select && segments.length>0) {
        removeSegments();
        setSegments([]);
        setShowSegment(false)
      }
      
      setCoords([]);
      if (props.features && props.features.length > 0) {
        props.features[0].getGeometry().setCoordinates([]);
      }
      setCost(0.0);
      setArea(0);
      setErrIntersect([]);
      setValuationUrl();
      setOrderUrl(undefined);
      props.resetPerimeter(0);
      props.setMode(MODE_SELECT_PDP);
      props.setFeatures([]);
      setFileCoord(t("coordinates.doc"));
    }
    
  };

  const handleRemove = (pi, ri, i) => {
    let points = coords[pi][ri];

    if (points.length<5) {
      if (props.layers.select && segments.length>0) {
        removeSegments();
        setSegments([]);
        setShowSegment(false)
      }
    }

    if (points.length < 5) {
      handleRemoveAll(pi, ri);
    } else {
      if (i==0) {
        points[points.length-1]=points[1];
      }
      points.splice(i, 1);
      setCoords(Object.assign([], coords));

      const feature = props.features[0];
      feature.getGeometry().setCoordinates(coords);
      setArea(feature.getGeometry().getArea());
      props.setFeatures([feature]);
      setFileName(null);
    }
  };

  const handleChangeNew = (event, j) => {
    newCoord[j] = event.target.value;
    setNewCoord(Object.assign([], newCoord));
  };

  const handleAdd = (e) => {
    if (coords.length == 0) {
      coords.push([[]]);
    }
    const points = coords[0][0];
    if (points.length == 0) {
      points.push(newCoord);
      points.push(newCoord);
    } else {
      points.splice(-1, 0, newCoord);
    }
    setCoords(Object.assign([], coords));
    setNewCoord(["", ""]);

    if (points.length>3) {
      let feature = props.features[0];
      if (!feature) {
        feature = new Feature({
          geometry: new MultiPolygon(coords),
        });
        feature.set("temp", true);
        props.handleLoadFeature(feature.getGeometry());
      } else {
        feature.getGeometry().setCoordinates(coords);
        props.setFeatures([feature]);
      }
      setArea(Math.abs(feature.getGeometry().getArea()));
    }    
  };

  const checkIntersections = (serviceId, wkt) => {
    fetch(
        `${window.REST_URL}/map/check?layers=lands,redlines,free,req,pdp_u,ohz,voz,vop&egovreq=${serviceId}`,
        {
          method: "POST",
          mode: "cors",
          credentials: "include",
          body: wkt
        },
      )
        .then(response => response.json())
        .then(json => processSegments(json));
  }

  const processSegments = (json) => {
    if (props.layers.select) {
      removeSegments();
      setSegments([]);
      if (json.segments) {
        const segmentsArr = [];
        json.segments.forEach(seg => {
          segmentsArr.push(seg);
          source.addFeature(
            new Feature({
              type: "segment",
              geometry: new WKT().readGeometryFromText(seg.wkt),
            }),
          );
        });
        setSegments(segmentsArr);
      }
    }
    setErrIntersect(json.errors ? json.errors : []);
  }

  const getCost = (wkt) => {
    const scale = matchScale(props.features[0].getGeometry(), [200,500,1000,2000,5000,25000,10000,50000,100000,200000,300000,500000,1000000], 800, 300, 20);

      fetch(window.REST_URL + "/map/cost?geom=" + wkt, {
        method: "GET",
        mode: "cors",
        credentials: "include",
      })
        .then(response => response.json())
        .then(result => setCost(result.cost));

      setOrderUrl(`${window.REST_URL}/map/report?scale=${scale}`);
      setValuationUrl(
        `${window.REST_URL}/map/valuation`,
      );
  }
  
  useEffect(() => {
    if (props.features.length > 0) {

      let wkt = new WKT().writeGeometry(props.features[0].getGeometry());

      const roundedCoords = props.features[0].getGeometry().getCoordinates().map(p =>
        p.map(r =>
          r.map(pt => [
            parseFloat(pt[0]).toFixed(2), 
            parseFloat(pt[1]).toFixed(2) 
          ])
        )
      );

      let feature = new Feature({
        geometry: new MultiPolygon(roundedCoords),
      });
      setNewFeature(feature);

      wkt = new WKT().writeGeometry(feature.getGeometry());
      setCoords(feature.getGeometry().getCoordinates());
      setArea(Math.abs(feature.getGeometry().getArea()));

      let serviceId = props.egovRequest ? props.egovRequest.srvTypeId : 0;

      checkIntersections(serviceId, wkt);
      getCost(wkt);

    } else if (errIntersect) {
      setCoords([]);
      setNewCoord(["", ""]);
      setArea(0);
      const msg = t("request.land-found");
      let newErrors = errIntersect.filter(err => err.msgRu !== msg);
      newErrors.push({ "msgRu": msg, "msgKz": msg });
      setErrIntersect(newErrors);
    } else {
      setErrIntersect([]);
    }
  }, [props.features]);


  useEffect(() => {
    if (props.errors && !errIntersect) {
      setErrIntersect([props.errors]);
    } else if (errIntersect) {
      var errors = errIntersect.filter(err => err.msgRu !== t("request.snapErr"));
      if (props.errors) {
        errors.push(props.errors);
      }
      setErrIntersect(errors);
    }
  }, [props.errors])

  const formatNumber = (n, dec) => {
    if (n) {
      let numAsString = n
        .toFixed(dec)
        .toString()
        .replace(/\./, ",");
      if (/\d{4},/.test(numAsString)) {
        numAsString = numAsString.replace(/(\d)(?=\d{3},)/, "$1 ");
      }
      while (/^\d{4}/g.test(numAsString)) {
        numAsString = numAsString.replace(/(?=\d)(\d{3})(?=\s)/, " $1");
      }
      return numAsString;
    }
    return 0;
  };

  const handleFileUpload = event => {
    const coordinateFile = event.target.files[0];
    let reader = new FileReader();
    reader.onload = e => {
      addCoordsFromFile(e.target.result);
      setFileName(coordinateFile.name);
    };
    reader.readAsText(coordinateFile);
    setHackStatus(80);
  };

  const addCoordsFromFile = text => {
    const points = text
      .split("\n")
      .map(line => line.split(" ").map(str => parseFloat(str)));
    points.push(points[0]);

    const coords = [[points]];
    const geom = new MultiPolygon(coords);
    props.handleLoadFeature(geom);
    
    if (!props.userInfo.egov_req) {
      const wkt = new WKT().writeGeometry(geom);
      fetch(
        `${window.REST_URL}/map/check?layers=free`,
        {
          method: "POST",
          mode: "cors",
          credentials: "include",
          body: wkt
        },
      )
        .then(response => response.json())
        .then(result => {
          if (result.errors) {
            setNotification({
              type: NF_NOTICE,
              title: t("notif.attention"),
              text: result.errors[0].msgRu,
            });
          }
        });
    }
  };

  const handleSendOld = useCallback(() => {
    props.features[0].getGeometry().setCoordinates(newFeature.getGeometry().getCoordinates());
    props.handleSend(hackStatus);
  }, [props, hackStatus]);

  const handleSend = useCallback(
    status => {
        setSendLoading(true);
        const geom = new WKT().writeGeometry(props.features[0].get("geometry"));
        if ( props.egovRequest) {
          const scale = matchScale(props.features[0].get("geometry"), [200,500,1000,2000,5000,25000,10000,50000,100000,200000,300000,500000,1000000], 800, 300, 20);
          fetch(
            `${window.REST_URL}/cabinet/req/create?scale=${scale}`,
            {
              method: "POST",
              mode: "cors",
              credentials: "include",
              body: geom
            },
          )
            .then(response => response.json())
            .then(result => {
              const srvType = props.egovRequest.srvTypeId === "504400" ? 'Com04'
              : props.egovRequest.srvTypeId === "504300" ? 'Com05'
              : props.egovRequest.srvTypeId === "504100" ? 'Com06'
              : '';
              if (result.error) {
                setSendLoading(false);
                if (result.errorMsg === 'check_error') {
                  setNotification({
                    type: NF_ERROR,
                    title: t("notif.error"),
                    text: t("notif.check-error")
                  });
                  processSegments(result.check)
                } else {
                  setNotification({
                    type: NF_ERROR,
                    title: t("notif.error"),
                    text: result.errorMsg === "edw_conn_error"
                      ? t("notif.head-error")
                      : result.errorMsg === "egov_portal_conn_error"
                        ? t("notif.portal-error")
                        : t("notif.unknown-error"),
                    onClose: () => {
                      window.location.href = `${window.EGOV_URL}/${srvType}/?request-number=${result.chainId}`;
                    }
                  });

                  props.setMode(MODE_SELECT);
                  props.setShowDrawLand(false);
                }
              } 
              else {
                window.location.href = `${window.EGOV_URL}/${srvType}/?request-number=${result.chainId}`;
              }
            })
        } else {
          props.send(null, geom, status)
            .then(response => response.json())
            .then((json) => {
              if ('notice' in json) {
                setNotification({
                  type: NF_NOTICE,
                  title: t("notif.attention"),
                  text: json.notice,
                })
              } else {
                props.layers.req.getSource().updateParams({ time: Date.now() });
                if (json.id) {
                  window.open(`${window.REST_URL}/cabinet/auction/scheme?landId=${json.id}&pointNumbers=true&lineLengths=true&adjacentArrows=true`);
                }
              }
            });
          
            props.setMode(MODE_SELECT);
            props.setShowDrawLand(false);
        }
    },
    [props.layers, props.uid, props.features, props.egovRequest],
  );

  const rows = [];
  let pointNum = 0;
  coords.forEach((p, pi) => {
    p.forEach((r, ri) => {
      r.slice(0, -1).forEach((pt, i) => {
        rows.push(
          <div className="table-row" key={`${pi}_${ri}_${i}`}>
            <p>{++pointNum}</p>
          <input
            type="number"
            step="1"
            value={parseFloat(pt[0]).toFixed(2)}
            onChange={e => handleChange(pi, ri, i, 0)}
          />
          <input
            type="number"
            step="1"
            value={parseFloat(pt[1]).toFixed(2)}
            onChange={e => handleChange(pi, ri, i, 1)}
          />
          <img className="remove-coord" src={garbage} alt=""
            onClick={() => handleRemove(pi, ri, i)}
          />
        </div>
        );
      })
    })
  })

  const segmentsRender = () => {
    let num = 0;
    return (segments.length>0 && segments.map((seg,i) => (
        <div className="segment-wrapper" key={i}>
          <div>
            {++num + ") "}
            {formatNumber(new WKT().readGeometry(seg.wkt).getArea(), 2)} м<sup>2</sup> (
            {formatNumber(new WKT().readGeometry(seg.wkt).getArea() / 10000, 4)} га.)
          </div>
          <button 
            className="segment-btn"
            onClick={() => {
              setShowSegment(true)
              if (seg.wkt instanceof Object) {
                props.handleSelect({geometry: new GeoJSON().readGeometry(seg.wkt)}, null, "segment");
              } else {
                props.handleSelect({wkt: seg.wkt}, null, "segment");
              }
            }}
          >
            {t("request.zoom")}
          </button>
        </div>
      ))
    ) 
  }

  const showAllSegments = () => {
    if (props.layers.select) {
      const segmentsCopy = Object.assign([], segments);
      removeSegments();
      setSegments([]);
      if (segmentsCopy) {
        const segmentsArr = [];
        segmentsCopy.forEach(seg => {
          segmentsArr.push(seg);
          source.addFeature(
            new Feature({
              type: "segment",
              geometry: new WKT().readGeometryFromText(seg.wkt),
            }),
          );
        });
        setSegments(segmentsArr);
        setShowSegment(false);
        props.map.getView().fit(props.features[0].getGeometry(), { duration: 500, maxZoom: 19 })
      }
    }
  }

  const titleSegmentStyle = showSegment ? {borderBottomLeftRadius: 0, borderBottomRightRadius: 0} : {};

  const handleUpdate = (values) => {
    setShowScroll(values.clientHeight < values.scrollHeight);
  };

  const imporFile = (e) => {
    if (e.target.files.length> 0) {
      if (props.checkFileFormat(e.target.files[0].name)) {
        props.fileFormatNote();
     } else {
        postDoc(e.target.files[0], "ZKR1").then(result => {
          let feature = props.features[0];
          feature = new WKT().readFeature(result);
          feature.set("temp", true);
          props.handleLoadFeature(feature.getGeometry());
        });
        setFileCoord(e.target.files[0].name);
     }
    }
     
  }

  const handleOrderUrl = () => {
    const wkt = new WKT().writeGeometry(newFeature.getGeometry());
    fetch(orderUrl,{
      method: "POST",
      mode: "cors",
      credentials: "include",
      body: wkt
    })
    .then(result => result.blob())
    .then(blob => download(blob, "Схема"))
  }

  const handleValuationUrl = () => {
    const wkt = new WKT().writeGeometry(newFeature.getGeometry());
    fetch(valuationUrl,{
      method: "POST",
      mode: "cors",
      credentials: "include",
      body: wkt
    })
    .then(result => result.blob())
    .then(blob => download(blob, "Акт оценки"))
  }

  return (
    props.open && (
      <>
        <LandRequestDialog palette={props.theme} >
          <Scrollbars
            autoHeight={true}
            autoHide={false}
            autoHeightMax={"70vh"}
            onUpdate={handleUpdate}
          >
          <div className={`land-request-wrapper ${showScroll ? 'add-padding' : ''}`}>
            <div style={{ display: "flex" }}>
              <div
                className="request-icon request-icon-draw"
                onClick={() => {
                  if (props.mode !== MODE_DRAW) {
                    // handleRemoveAll();
                    if (area == 0) {
                      props.setMode(MODE_DRAW);
                    } else {
                      props.setMode(MODE_EDIT);
                    }
                  }
                }}
              >
                <DrawCoords
                  style={{ width: "22px" }}
                />
              </div>
              
              <div
                className="request-icon request-icon-draw"
                title="Завершить отрисовку"
                onClick={() => {
                  props.setMode(MODE_OFF);
                }}
              >
                <DrawFinish
                  style={{ width: "22px" }}
                />
              </div>


              {/* <div
                className="request-icon request-icon-draw"
                title="Загрузить файл"
                onClick={() => {
                  fileInputRef.current.click();
                }}
              >
                <FileImport
                  style={{ width: "22px" }}
                />
              </div> */}

              

              {/* <input
                  type="file" 
                  accept='application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/plain, application/zip'
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={e => {
                      e.target.files.length !== 0 && imporFile(e)
                  }}
                  onClick={e => e.target.value = null}
              /> */}

            </div>

            <Collapsible
                trigger={
                    <div
                      className="request-icon request-icon-draw file-import"
                      // onClick={() => {
                      //   fileInputRef.current.click();
                      // }}
                    >
                      Загрузить файл координат
                      <FileImport
                        style={{ width: "22px" }}
                      />
                    </div>
                }
                transitionTime={200}
                easing="ease-out"
              >
                <div className="templates">
                  {templates.map((item,i) => 
                      <div key={i} className="templates__item">
                          <button onClick={()=> getTemplate(item.ext)}>
                              {item.name}
                              <img src={item.svg} alt="word" />
                          </button>
                      </div>
                  )}                    
                </div> 
                  
                <div
                  className="file-wrapper"
                  onClick={() => {
                    fileInputRef.current.click();
                  }}
                >
                 <div className="file-wrapper-name">{fileCoord}</div>
                 <div className="file-wrapper-label">Загрузить</div>
                </div>
                

                <input
                    type="file" 
                    accept='application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/plain, application/zip'
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={e => {
                        e.target.files.length !== 0 && imporFile(e)
                    }}
                    onClick={e => e.target.value = null}
                />

            
              </Collapsible>

           

            {props.egovRequest &&                 
              <div className="data-field">
                <h3>{t("request.num")}</h3>
                <div>{props.egovRequest.num} ({props.egovRequest.chainId})</div>
              </div>
            }
            <div id="table">
              <div className="table-row table-header">
                <div>&nbsp;</div>
                <span>     {t('request.2')} </span>
                <span>{t('request.3')}</span>
                <div className="remove-all-coord">
                  {coords.length > 0 && (
                    <svg
                      onClick={() => handleRemoveAll()}
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                    >
                      <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
                    </svg>
                  )}

                </div>
              </div>
              <div id="table-body">
                <Scrollbars
                  autoHeight={true}
                  autoHide={false}
                  autoHeightMax={450}
                >
                  {rows}
                  </Scrollbars>
              </div>
            </div>
            <div className="table-row">
              <div>&nbsp;</div>
              <input

                placeholder={t('request.14')}
                type="number"
                step="1"
                value={newCoord[0]}
                onChange={e => handleChangeNew(e, 0)}
              />
              <input
                placeholder={t('request.15')}
                type="number"
                step="1"
                value={newCoord[1]}
                onChange={e => handleChangeNew(e, 1)}
              />
              <svg
                onClick={handleAdd}
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
              >
                <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
              </svg>
            </div>
            <div className="data-field">
              <h3>  {t('request.4')}</h3>
              <div>
                {formatNumber(area, 2)} м<sup>2</sup> (
                {formatNumber(area / 10000, 4)} га.)
              </div>
            </div>
            <div className="data-field">
              <h3>{t('request.perimetr')}</h3>
              <div>{formatNumber(props.perimeter, 2)} м</div>
            </div>
            {props.userInfo && props.userInfo.egov_req && (
              <div className="data-field request-download-btn" >
                <h3>{t('request.5')}</h3>

                <p>{true ? formatNumber(cost, 2) + " тг" : `${t('request.6')} `}</p>
                {valuationUrl && (!errIntersect || errIntersect.length === 0) ? (                  
                  <button 
                    // className="request-download-link"
                    className="file-download-btn" 
                    onClick={() => {
                      if (cost > 0) {
                        handleValuationUrl();
                      } else {
                        downloadError();
                      }
                    }}
                  >
                    <FileUpload style={{ marginRight: "10px", width: "18px" }} />
                    {t('request.7')}
                  </button>

                ) : null}
              </div>
            )}
            {/* // testzu*/}
            {segments && segments.length>0 && 
              <div className="data-field error">
                  <h3 
                    style={titleSegmentStyle}
                   className="request-error-header">
                    {t("request.intersections")}
                  </h3>
                  {showSegment && segments.length>1 && 
                    <button className="segment-btn-all" onClick={() => showAllSegments()}>
                      {t("request.all-intersections")}
                    </button>
                  }
                {segmentsRender()}
              </div>
            }
            
            {/* // */}
            <div className="data-field error">
              {errIntersect && errIntersect.length > 0 ? (
                <h3 className="request-error-header">
                  {t('request.8')}
                </h3>
              ) : <></>}
              {errIntersect ? errIntersect.map((err, i) => <p key={i}>{err.msgRu} </p>) : <></>}
            </div>
            {(
              <div className="buttons">
                {orderUrl && (!errIntersect || errIntersect.length === 0) && (
                  <button
                    className="file-download-btn"
                    role="button"
                    onClick={handleOrderUrl}
                  >
                    <FileUpload style={{ marginRight: "10px", width: "18px" }} />
                    {t('request.scheme')}
                  </button>
                )}


              </div>
            )}
          </div>
          </Scrollbars>
          <div className="buttons buttons-save">
          <button
                  className="request-cancel"
                  onClick={() => {
                    setConfirm({
                      title: t("notif.confirm"),
                      text: t("notif.continue"),
                      onConfirm: () => props.handleCancel(errIntersect)
                    });
                  }}>
                  {props.standalone ? `${t('request.cancel')}` : "Отменить"}
                </button>
                {orderUrl && (!errIntersect || errIntersect.length === 0) && (
                  <button
                    className="request-save"
                    onClick={() => {
                      props.features[0].getGeometry().setCoordinates(newFeature.getGeometry().getCoordinates());
                      setConfirm({
                        title: t("notif.confirm"),
                        text: t("notif.continue"),
                        // onConfirm: handleSend
                        onConfirm: () => handleSend(hackStatus)
                      });
                    }}
                    disabled={sendLoading}
                  >
                    {!sendLoading ? t('request.13') : <ClipLoader color={"#fff"} loading={true} size={"20px"}/>}
                  </button>
                )}
          </div>
          
        </LandRequestDialog>
      </>
    )
  );
}