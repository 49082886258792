import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ClipLoader from "react-spinners/ClipLoader";
import { useTranslation } from "react-i18next";
import svgDownload from '../../assets/img/download-table.svg';
import styled from "styled-components";
import {OnTop, Modal, ModalHeader, ModalBody, ModalFooter} from "./LecenseeWorkOrders";
import Collapsible from "react-collapsible";
import { Scrollbars } from "react-custom-scrollbars";
import svgPlus from '../../assets/img/plus-box-fill.svg';
import svgMinus from '../../assets/img/minus-box-fill.svg';
import { Icon } from './UserProfile';


const Body = styled(ModalBody)`
  .register-table table tbody tr td {
    line-height: 25px;
    height: 35px;
    text-align: center;

    .download {
      display: flex;
      justify-content: center;
      gap: 7px;
    }
  }
  
`;

export default function PlanArchive ({
  responsive,
  showPlanArchive,
  setShowPlanArchive
}) {
  const { t } = useTranslation();
  const [typesServices, setTypesServices] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [expanded, setExpanded] = useState({});
  const services = {
    503100 : "Согласование проектируемого земельного участка графическим данным ИС ЕГКН",
    502100 : "Определение кадастровой (оценочной) стоимости земельного участка",
    501150 : "Предоставление сведений о качественном состоянии земельного участка",
    504100 : "Прямое предоставление",
    504400 : "Торги с отрисовкой"
  }

  useEffect(() => {
    getPlans();
  }, [])

  const getPlans = () => {
      axios({
          url: `${window.REST_URL}/cabinet/planArchive/?pageNum=1&pageSize=10`,
          method: 'GET',
          mode: "cors",
          credentials: "include"
      })
      .then((res) => {
    setTypesServices(res.data);
      })
      .catch((err) => {
          console.log(err);
      });
  }

  const downloadFile = (service, landId) => {
    fetch(`${window.REST_URL}/cabinet/planArchive/download?service=${service}&landId=${landId}`, {
        method: "GET",
        mode: "cors",
        credentials: "include"
    })
    .then((res) => res.blob())
    .then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = "Схема испрашиваемого ЗУ";
        document.body.appendChild(a);
        a.click();    
        a.remove();
    });
  }

  const handleExpanded = (layer, value) => {
      const x = {};
      x[layer.name] = value;
      setExpanded(Object.assign({}, expanded, x));
  };

  const getPaginator = (service) => {
    let startPage = 1
    let maxNumber = Math.ceil(service.count/10);
    if(service.startPage !== undefined) {
        startPage = service.startPage
    }

    let arrPages = [];
    for(let i = 0; i <= 9; i++) {
        let page = startPage + i;
        if(maxNumber < page) {
            break;
        }
        arrPages.push(page);
    }

    return (
        <div className='paginator'>
            {service.count>10 && <div className='page-fisrt' onClick={() => goPage(1, service.code)}></div>}
            {service.count>100 && <div className='page-left-one' onClick={() => prevPage(service)}></div>}

            {arrPages.map((page, i) =>
                <div className={
                        (service.currPage != undefined && service.currPage == page) || (service.currPage == undefined && page == 1) ? 
                            "page-button-active" : 
                            "page-button"
                    } onClick={() => goPage(page, service.code)} key={i}>
                    <span className="page-number">{ page }</span>
                </div>
            )}

            {service.count>100 && <div className='page-right-one' onClick={() => nextPage(service)}></div>}
            {service.count>10 &&<div className='page-last' onClick={() => goPage(Math.ceil(service.count/10), service.code)}></div>}
        </div>
    );
  }

  const nextPage = (service) => {
        let startPage = 1
        if(service.startPage !== undefined) {
            startPage = service.startPage
        }
        let page = startPage + 10

        if (page <= Math.ceil(service.count/10)) {
            goPage(page, service.code)
        }
    }

    const prevPage = (service) => {
        let startPage = 1
        if(service.startPage !== undefined) {
            startPage = service.startPage
        }
        let page = startPage - 10

        if (page > 0) {
            goPage(page, service.code)
        }
    }

    const goPage = (page, seviceCode) => {
        let payload = `&service=${seviceCode}`
        if(searchText) {
            payload += `&search=${searchText}`
        }

        //вычисляю чтобы было кратное 10, но с учетом того чтобы это не было самой 10, 20, 30 и т.д.
        // потому что тогда страницы начнуться с этого числа
        let startPage = ((page - 1) - (page - 1) % 10) + 1;

        axios({
            url: `${window.REST_URL}/cabinet/planArchive/?pageNum=${page}&pageSize=10${payload}`,
            method: 'GET',
            mode: "cors",
            credentials: "include"
        })
        .then((res) => {
            let newTypesServices = [];
            typesServices.forEach(obj => {
                if(seviceCode == obj.code) {
                    obj.plans = res.data[0].plans
                    obj.currPage = page
                    obj.startPage = startPage
                    newTypesServices.push(obj)
                } else {
                    newTypesServices.push(obj)
                }
            });

            setTypesServices(newTypesServices);
        })
        .catch((err) => {
            console.log(err);
        });
    }

  return (
    <OnTop>
      <Modal responsive={responsive}>

        <ModalHeader>
          <div className="header">
              <div className='title'>{t("user-profile.plan-archive")}</div>
          </div>
        </ModalHeader>

        <Body responsive={responsive}>
          <Scrollbars
                    autoHeight={true}
                    autoHeightMax={"440px"}
                    renderView={props => <div {...props} className="scrollbar-view" />}
          >
            { typesServices && typesServices.length>0 ? typesServices.map((service, i) => (
              <Collapsible
                  key={i}
                  trigger={
                      <div className="register-table">
                          <table>
                              <tbody>
                                  <tr>
                                      <td style={{ width: "5%", padding: "5px 15px" }}>{
                                          expanded[service.code] ? 
                                              <Icon url={svgMinus} className="icon" />
                                          :
                                              <Icon url={svgPlus} className="icon" />
                                      }</td>
                                      <td style={{ width: "85%", textAlign: "left" }}>{services[service.code]}</td>
                                      <td style={{ width: "10%" }}>{service.count}</td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                  }
                  transitionTime={200}
                  easing="ease-out"
                  open={expanded[i]}
                  onOpening={() => handleExpanded(service, true)}
                  onClosing={() => handleExpanded(service, false)}
              >
                  <div className="register-table">
                      <table>
                          <thead>
                              <tr>
                                <td style={{ width: "5%" }}>№</td>
                                <td style={{ width: "35%" }}>{t("info-tab.identifier")}</td>
                                <td style={{ width: "35%" }}>{t("info-tab.nom_plan")}</td>
                                <td style={{ width: "25%" }}>{t("zkr.actions")}</td>
                              </tr>
                          </thead>
                          <tbody>
                          { service.plans && service.plans.map((item, i) => (
                              <tr key={i}>
                                <td style={{ width: "5%" }}>{++i}</td>
                                <td style={{ width: "35%" }}>{item.land_id}</td>
                                <td style={{ width: "35%" }}>{item.nom_plan}</td>
                                <td style={{ width: "25%" }}>
                                  {
                                    item.download ? 
                                    <div className="download" onClick={() => {
                                        if (service.code === "503100" || service.code === "502100" || service.code === "501150") {
                                            downloadFile(service.code, item.nom_plan)
                                        } else {
                                            downloadFile(service.code, item.land_id)
                                        }
                                    }}>
                                      Скачать
                                      <img src={svgDownload} alt="" />
                                    </div> 
                                    : "—"
                                  }
                                </td>
                              </tr>
                          )) }
                          </tbody>
                      </table>
                      { getPaginator(service) }
                      
                  </div>
              </Collapsible>
          )) : 
          // searchText ? <p style={{textAlign: "center"}}>По указанным критерияем заказов не найдено</p> : ''
          ''
          }
          </Scrollbars>
        </Body>

        <ModalFooter responsive={responsive}>
            <button
                className="cancel-btn"
                href=""
                onClick={() => {
                    setShowPlanArchive(false)
                }}
            >
                {t("request.cancel")}
            </button>
        </ModalFooter>
        
      </Modal>
    </OnTop>
  )
}