import React from "react";
import styled from "styled-components";

const RadioComponent = styled.input`
  &[type="radio"] {
    appearance: none;
    position: relative;
    outline: none;
  }
  &[type="radio"]::before {
    content: "";
    box-sizing: border-box;
    width: 15px;
    height: 15px;
    position: absolute;
    background-color: inherit;
    top: -7.5px;
    left: 5px;
    border: 1.5px solid ${props => props.palette.text};
    border-radius: 50%;
  }
  &[type="radio"]:hover:before {
    cursor: pointer;
  }
  &[type="radio"]:hover:after {
    content: "";
    cursor: pointer;
    box-sizing: border-box;
    width: 7px;
    height: 7px;
    position: absolute;
    top: -3px;
    left: 9.5px;
    border-radius: 50%;
    background-color: ${props => props.palette.text};
  }
  &[type="radio"]:checked:after {
    content: "";
    box-sizing: border-box;
    width: 7px;
    height: 7px;
    position: absolute;
    top: -3px;
    left: 9.5px;
    border-radius: 50%;
    background-color: ${props => props.palette.text};
  }
`;

const Radio = ({ theme, isChecked, checkHandler }) => (
  <RadioComponent
    type="radio"
    palette={theme}
    checked={isChecked}
    onChange={checkHandler}
    />
)

export default Radio;