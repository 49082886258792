/**
 * Ищем родителей, пока не дойдем до тега <a> или <button>
 * @param {event} e
 */

export function getEventButton(e) {
  let btn = e.target;
  while (btn.localName !== "button" && btn.localName !== "a") {
    btn = btn.parentElement;
  }
  return btn;
}


export const MENU_MAP_CONTROL = 1;
export const MENU_SEARCH = 2;
export const MENU_LEGEND = 3;
export const MENU_SHOW_STATS = 4;
export const MENU_MEASURE_DISTANCE = 5;
export const MENU_DRAW = 6;
export const MENU_PRINT = 7;
export const MENU_FEEDBACK = 8;
export const MENU_QUEUE = 9;
export const MENU_ANALYTIC = 10;
export const MENU_REGION_ONLINE = 11;
export const MENU_HELP = 12;
