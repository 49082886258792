import React, { useEffect, useState } from 'react'
import axios from 'axios';
import ClipLoader from "react-spinners/ClipLoader";
import { useTranslation } from "react-i18next";
import { Icon } from './UserProfile';
import svgDownload from '../../assets/img/download-table.svg';
import styled from "styled-components";
import { Scrollbars } from "react-custom-scrollbars";
import svgAdd from '../../assets/img/add.svg';

import ConfirmDialog from '../../components/ConfirmDialog';
import ActUstForm from "../../components/Cabinet/services/ActUstForm";
import { signOrder, deleteAppDoc } from '../../RestUtils';
import { signText } from '../../NCALayerOps';
import { download } from '../../utils/RestApi';

export const Modal = styled.div`
    position: absolute;
    left: ${props => props.responsive.mobile ? "0" : "10%"};
    top: ${props => props.responsive.mobile ? "0" : "0%"};
    max-height: ${props => props.responsive.mobile ? "100%" : "900px"};
    width: ${props => props.responsive.mobile ? "100%" : "800px"};
    height: ${props => props.responsive.mobile ? "100%" : "550px"};
    background-color: #fff;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    z-index: 1;
    font-family: 'sf-pro-display-regular',sans-serif;
`;

export const ModalHeader = styled.div`
    .header {
        display: flex;
        height: 50px;
        margin-bottom: 15px;
        border-radius: 5px 5px 0px 0px;
        background: linear-gradient(180deg, #01C13D 0%, #00A824 100%);
        align-items: center;
        padding: 0px 80px 0px 20px;
        
        .title {
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 15px;
            color: #FFFFFF;
        }
    }
`;

export const ModalFooter = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin-top: 5px;

    button {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 10px 10px 0px;
        width: 93%;
        border-radius: 5px;
        color: #fff;
        height: 30px;
        border: none;
        outline: none;
        &:hover {
            cursor: pointer
        }
    }

    .success-btn {
        background: #00A824;
        width: 120px;
    }
    .success-btn-disabled {
        background: #ADD5B8;
        width: 120px;
        cursor: not-allowed !important;
    }

    .cancel-btn {
        background: #777777;
        width: 90px;
    }
`;

export const ModalBody = styled.div`
    padding: 10px;

    .loading {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 400px;
    }

    table {
        border: 1px solid #B6D4C0;
        border-spacing: 0;
        width: 100%;
        margin-bottom: 10px;

        thead {
            tr {
                width: 100%;
                height: auto;
                display: flex;
                margin: 0;
                padding: 0;
                background: #fff;

                td {
                    padding: 3px 8px;
                    border: 1px solid #B6D4C0;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    background: #E0F0E5;
                    color: #008A78;
                }
            }
        }

        tbody {
            tr {
                width: 100%;
                height: 30px;
                display: flex;
                margin: 0;
                padding: 0;
                background: #fff;
                cursor: pointer;

                td {
                    padding: 3px 8px;
                    border: 1px solid #B6D4C0;
                    overflow: hidden;
                    position: relative;
                }
            }
        }

        tbody tr:hover {
            background: #D8EFE0; /* Цвет фона при наведении */
        }

        .even {
            background: #F8FFFA;
        }
    }

    button {
        margin: 0 10px 10px 0px;
        border-radius: 4px;
        color: #fff;
        height: 30px;
        border: none;
        outline: none;
        background-color: #de2027;
        &:hover {
            cursor: pointer
        }
    }

    .block-panel {
        display: inline-block;
        position: relative;
        border: 0.5px solid #828282;
        border-radius: 5px;
        padding: 12px 5px 10px 5px;
        text-align: center;
        height: auto;
        width: 100%;
        margin: 5px 0px;
    
        .border-word {
          font-size: 12px;
          color: #828282;
          background-color: #fff;
          position: absolute;
          padding: 0px 10px;
          top: -9px;
          left: 12px;
        }
    }

    .table-btn-img {
        width: 36px;
        height: 26px;
        cursor: pointer;
        background-position: center !important;
    }
    .sub-table-btn {
        position: relative;
        div {
          display: flex;
          position: absolute;
        //   top: 4px;
          left: 15px
          font-size: 12px;
          padding: 3px 6px;
          width: max-content;
        }
    }
    // .sub-table-btn:hover{
    //     color: #779480;
    //     background: #DCF0E2;
    // }

    .order-col {
        min-height: 100px;
        max-height: ${props => props.responsive.mobile ? "initial" : "352px"};
        height: auto;

        .order-row {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
        }

        .add-doc-btn {
            display: flex;
            padding: 6px 10px;
            background: #009856;
            height: 30px;
            width: 15%;
        }
        .sign-doc-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            background: #009856;
            height: 30px;
            width: 15%;
        }
        .sign-doc-disabled-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            background: #ADD5B8;
            color: #8AB396;
            height: 30px;
            width: 15%;
            cursor: not-allowed;
        }

        .doc-info {
            border: 1px solid #B6D4C0;
            border-radius: 5px;
            margin: 0;
            width: 83%;
            height: 30px;
            padding: 5px 8px;
            text-align: left;
            cursor: pointer;
        }
        .doc-info__download {
            position: absolute;
            top: 13px;
            right: 15px;
        }
    }
    .success-btn {
        background: #00A824;
        width: 120px;
    }
`;

export const OnTop = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    backdrop-filter: blur(3px);
    background-color: rgb(0 0 0 / 49%);
    z-index: 1000;
`;

export default function LecenseeExecWorkOrder({
    order,
    setShowExecWorkOrder,
    setShowWorkOrders,
    responsive,
    setNotification,
    pdfNote,
    checkPdf
}) {
    const { t } = useTranslation();
    const [isShowConfirm, setShowConfirm] = useState(false);
    const [isShowActUstForm, setShowActUstForm] = useState(false);
    const [isActUstForm, setActUstForm] = useState(false);
    const [resolutions, setResolutions] = useState([{id: 1, name: t("zkr.radio-build")}, {id: 2, name: t("zkr.radio-attach")}])
    const [isSelectResolution, setSelectResolution] = useState(true);
    const hiddenActRecoveryInput = React.createRef();
    const hiddenActTransferInput = React.createRef();
    const hiddenActExecutedInput = React.createRef();
    const [actRecovery, setActRecovery] = useState(null);
    const [actTransfer, setActTransfer] = useState(null);
    const [actRecoverySign, setActRecoverySign] = useState({sign: false});
    const [actTransferSign, setActTransferSign] = useState({sign: false});
    const [actExecuted, setActExecuted] = useState(null);
    const [orderDocs, setOrderDocs] = useState([]);
    const [signLoading, setSignLoading] = useState(false);
    const [actUstLoading, setActUstLoading] = useState(false);
    const [actMezhLoading, setActMezhLoading] = useState(false);
    const [act, setAct] = useState({});
    const [loading, setLoading] = useState(false)
    
    useEffect(() => {
        //запрашиваем документы
        setLoading(true)
        fetch(
            `${window.REST_URL}/cabinet/order/` + order.id, {
                method: "GET",
                mode: "cors",
                credentials: "include",
            },
          )
            .then(response => response.json())
            .then(json => {
                const docs = json.docs.filter((item) => item.typeCode!=='ZKR41');
                const decision = json.decision;
                // const decision = order.decision;
                console.log(order);
                order.area = json.objects[0].area;

                for (let i = 0; i < docs.length; i++) {
                    const doc = docs[i];
                    if (decision===1) {
                        if (doc.typeCode==='ZKR8') {
                            actTransferSign.fileName=doc.fileName;
                            actTransferSign.sign=doc.isSigned;
                            setActTransferSign(Object.assign({}, actTransferSign));
                        } else if (doc.typeCode==='ZKR10') {
                            actRecoverySign.fileName=doc.fileName;
                            actRecoverySign.sign=doc.isSigned;
                            setActRecoverySign(Object.assign({}, actRecoverySign));
                        }
                    } else {
                        if (doc.typeCode==='ZKR8') {
                            setActTransfer(doc);
                        } else if (doc.typeCode==='ZKR10') {
                            setActRecovery(doc);
                        }
                    }
                    if (doc.typeCode==='ZKR11') {
                        setActExecuted(doc);
                    }
                    
                }

                if (json.specData && json.specData.saved) {
                    act.data=json.specData;
                }  else {
                    act.data={adjacent: [], appId: order.id, area: order.area}
                }
                setAct(Object.assign({}, act));

                if (decision===2) {
                    setSelectResolution(false);
                }
                setOrderDocs(docs);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
	}, [])

    //открывает выбор файла
    // * @param integer $type - Тип загружаемого файла:
    // ** 1 - Акт установления (восстановления) границ ЗУ
    // ** 2 - Акт сдачи межевых знаков
    // ** 3 - Акт выполненных работ
    const handleOtherClick = (type) => {
        if(type == 1) {
            hiddenActRecoveryInput.current.click();
        } else if(type == 2) {
            hiddenActTransferInput.current.click();
        } else {
            hiddenActExecutedInput.current.click();
        }
    }

    const filNote = () => {
        setNotification({
            title: t("service.file-error-title"),
            text: t("service.file-error-text")
        })
    }

    const checkDuplicateFiles = (fileName) => {
        let isDuplicate = false;
        const found = orderDocs.find(el => el.fileName===fileName);
        found ? isDuplicate=true : isDuplicate=false;
        return isDuplicate;
    }

    const handleActRecoverySelect = event => {
        const fileName = event.target.files[0].name;
        if (checkDuplicateFiles(fileName) || (actTransfer && fileName===actTransfer.fileName) || (actExecuted && fileName===actExecuted.fileName)) {
            filNote();
        } else if (checkPdf(fileName)) {
            pdfNote();
        } 
        else {
            let doc = {
                fileName: fileName,
                file: event.target.files[0]
            };
            setActRecovery(doc);
        }
    }

    const handleActTransferSelect = event => {
        const fileName = event.target.files[0].name;
        if (checkDuplicateFiles(fileName) || (actRecovery && fileName===actRecovery.fileName) || (actExecuted && fileName===actExecuted.fileName)) {
            filNote();
        } else if (checkPdf(fileName)) {
            pdfNote();
        } else {
            let doc = {
                fileName: fileName,
                file: event.target.files[0]
            };
            setActTransfer(doc);
        }
    }

    const handleActExecutedSelect = event => {
        const fileName = event.target.files[0].name;
        if (checkDuplicateFiles(fileName)) {
            filNote();
        } else if (isSelectResolution && ((actRecoverySign && fileName===actRecoverySign.fileName) || (actTransferSign && fileName===actTransferSign.fileName))) {
            filNote();
        } else if (!isSelectResolution &&  ((actRecovery && fileName===actRecovery.fileName) || (actTransfer && fileName===actTransfer.fileName))) {
            filNote();
        } else if (checkPdf(fileName)) {
            pdfNote();
        } else {
            let doc = {
                fileName: fileName,
                file: event.target.files[0]
            };
            setActExecuted(doc);
        }
    }

    const onSend = () => {
        if (isSelectResolution) {
            if (!actRecoverySign.fileName) {
                setNotification({
                    title: t("notif.attention"),
                    text: t("notif.form-act-zu")
                });
                return
            } else {
                if (!actRecoverySign.sign) {
                    setNotification({
                        title: t("notif.attention"),
                        text: t("notif.sign-act-zu")
                    });
                    return
                }
            }
    
            if(!actTransferSign.fileName) {
                setNotification({
                    title: t("notif.attention"),
                    text: t("notif.form-act-mz")
                });
                return
            } else {
                if (!actTransferSign.sign) {
                    setNotification({
                        title: t("notif.attention"),
                        text: t("notif.sign-act-mz")
                    });
                    return
                }
            }
        } else {
            if (!actRecovery) {
                setNotification({
                    title: t("notif.attention"),
                    text: t("notif.choose-act-zu")
                });
                return
            }
    
            if (!actTransfer) {
                setNotification({
                    title: t("notif.attention"),
                    text: t("notif.choose-act-mz")
                });
                return
            }
        }
        if(!actExecuted) {
            setNotification({
                title: t("notif.attention"),
                text: t("notif.choose-act-vr")
            });
            return
        }

        // setShowConfirm(true)
        setSignLoading(true);
        signOrder(order.id, setSignLoading).then(result => sendDataRequest(result));
    }

    const onSave = () => {
        const formData = new FormData();

        if (isSelectResolution) {
            if (actRecoverySign.data) {
                formData.append('actRecovery', new Blob([actRecoverySign.data]), actRecoverySign.fileName);
                formData.append('actRecoverySign', actRecoverySign.sign);
            } else {
                formData.append('actRecoverySign', actRecoverySign.sign);
            }

            if (actTransferSign.data) {
                formData.append('actTransfer', new Blob([actTransferSign.data]), actTransferSign.fileName);
                formData.append('actTransferSign', actTransferSign.sign);
            } else {
                formData.append('actTransferSign', actTransferSign.sign);
            }
            formData.append('decision', 1);
        } else {
            if (actRecovery) {
                if (actRecovery.file) {
                    formData.append('actRecovery', new Blob([actRecovery.file]), actRecovery.file.name);
                } 
            }
            if (actTransfer) {
                if (actTransfer.file) {
                    formData.append('actTransfer', new Blob([actTransfer.file]), actTransfer.file.name);
                } 
            }
            formData.append('decision', 2);
        }
        if (actExecuted) {
            if (actExecuted.file) {
                formData.append('actExecuted', new Blob([actExecuted.file]), actExecuted.file.name);
            } 
        }
        

        axios({
            url: `${window.REST_URL}/cabinet/zkr/order/${order.id}/save`,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            method: "post",
            data: formData
        })
        .then((res) => {
            console.log(`Success` + res.data);
            setShowExecWorkOrder(false)
        })
        .catch((err) => {
            console.log(err);
        });

		// setShowExecWorkOrder(false)
    }

    const onCancel = () => {
        setShowExecWorkOrder(false)
    }

    //отправляет данные формы на сервер
    const sendDataRequest = (sign) => {
        //готовим запрос для отправки
        const formData = new FormData();
        formData.append('sign', sign);
        formData.append('resolution', isSelectResolution);

		if (isSelectResolution) {
            if (actRecoverySign.data) {
                formData.append('actRecovery', new Blob([actRecoverySign.data]), actRecoverySign.fileName);
                formData.append('actRecoverySign', actRecoverySign.sign);
            }
            if (actTransferSign.data) {
                formData.append('actTransfer', new Blob([actTransferSign.data]), actTransferSign.fileName);
                formData.append('actTransferSign', actTransferSign.sign);
            }
            formData.append('decision', 1);
        } else {
            if (actRecovery) {
                if (actRecovery.file) {
                    formData.append('actRecovery', new Blob([actRecovery.file]), actRecovery.file.name);
                } 
            }
            if (actTransfer) {
                if (actTransfer.file) {
                    formData.append('actTransfer', new Blob([actTransfer.file]), actTransfer.file.name);
                } 
            }
            formData.append('decision', 2);
        }
        if (actExecuted.file) {
            formData.append('actExecuted', new Blob([actExecuted.file]), actExecuted.file.name);
        } 

        axios({
            url: `${window.REST_URL}/cabinet/zkr/order/${order.id}/send`,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            method: "post",
            data: formData
        })
        .then((res) => {
            console.log(`Success` + res.data);
        })
        .catch((err) => {
            console.log(err);
        });

		setShowExecWorkOrder(false)
        setShowWorkOrders(false)

        setNotification({
            title: t("egov-service.info"),
            text: t("egov-service.success")
        })
    }

    const downloadDoc = (doc, isFile = false, name) => {
        if (name) {
            download(doc, name);
        } else {
            if(isFile) {
                if(doc.file) {
                    let file = URL.createObjectURL(doc.file);
                    // window.location.assign(file);
                    window.open(file, '_blank');
                } else {
                    downloadDoc(doc.fileName, false)
                }
            } else {
                return fetch(`${window.REST_URL}/upload/${order.id}?filename=${encodeURIComponent(doc)}`, {
                    method: "GET",
                    mode: "cors",
                    credentials: "include"
                })
                .then((res) => res.blob())
                .then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = doc;
                    document.body.appendChild(a);
                    a.click();    
                    a.remove();
                });
            }
        }
    }

    const signActUst = () => {
        setActUstLoading(true);
        const formData = new FormData();
        formData.append('data', JSON.stringify(act.data));
        
        if (act.schemeFile) {
            formData.append('scheme', act.schemeFile, act.schemeFile.name);
        }
        
        axios({
            url: `${window.REST_URL}/cabinet/zkr/sign?orderId=${order.id}&docType=ZKR10`,
            method: 'GET',
            mode: "cors",
            credentials: "include"
        })
        .then(response => {
            formData.append('docNumber', response.data['docNumber']);
            return response.data['text'];
        })
        .then(text => signText(text, 'SIGNATURE'))
        .then(result => result.responseObject)
        .then(sign => {
            formData.append('signature', sign);
            
            axios({
                url: `${window.REST_URL}/cabinet/zkr/actUst`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                method: "post",
                data: formData,
                responseType: 'blob'
            })
            .then(res => {
                setActUstLoading(false);
                actRecoverySign.data=res.data;
                actRecoverySign.fileName='Акт установления (восстановления) границ ЗУ.pdf';
                actRecoverySign.sign=true;
                setActRecoverySign(Object.assign({}, actRecoverySign));
                // if (act.signActMezh) {
                //     setActUstForm(false)
                // }
            })
            .catch(err => {
                console.log(err);
                
            });
        })
        .catch(err=>{
            console.log(err);
            setActUstLoading(false);
        })
    }

    const signActMezh = () => {
        setActMezhLoading(true);
        const formData = new FormData();
        formData.append('data', JSON.stringify(act.data));

        axios({
            url: `${window.REST_URL}/cabinet/zkr/sign?orderId=${order.id}&docType=ZKR8`,
            method: 'GET',
            mode: "cors",
            credentials: "include"
        })
        .then(response => {
            formData.append('docNumber', response.data['docNumber']);
            return response.data['text'];
        })
        .then(text => signText(text, 'SIGNATURE'))
        .then(result => result.responseObject)
        .then(sign => {
            formData.append('signature', sign);

            axios({
                url: `${window.REST_URL}/cabinet/zkr/actMezh`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                method: "post",
                data: formData,
                responseType: 'blob'
            })
            .then(res => {
                setActMezhLoading(false)
                actTransferSign.data=res.data;
                actTransferSign.fileName='Акт на сдачу межевых знаков.pdf';
                actTransferSign.sign=true;
                setActTransferSign(Object.assign({}, actTransferSign));
                // if (act.signActUst) {
                //     setActUstForm(false)
                // }
            })
            .catch(err => {
                console.log(err);
            });
        })
        .catch(err=>{
            console.log(err);
            setActMezhLoading(false);
        })
    }

    return (
        <OnTop>
            <Modal responsive={responsive}>
            <ModalHeader>
            <div className="header">
                <div className='title'>{t("zkr.exec-orders")}</div>
            </div>
            </ModalHeader>
            <Scrollbars
                autoHeight={false}
                // autoHide={true}
                autoHeightMax={"45vh"}
                renderView={props => <div {...props} className="scrollbar-view" />}
            >
            <ModalBody responsive={responsive}>
                {loading ? <div className="loading"><ClipLoader size="50px" color={"#02c23e"} loading={true}/></div> : 
                <>
                    <table>
                        <tbody>
                            <tr>
                                <td style={{ width: "35%", background: '#E0F0E5' }}>{t("zkr.order-number")}</td>
                                <td style={{ width: "65%" }}>{order.id}</td>
                            </tr>
                            <tr>
                                <td style={{ width: "35%", background: '#E0F0E5' }}>{t("zkr.order-applicant")}</td>
                                <td style={{ width: "65%" }}>{order.bin || order.iin}</td>
                            </tr>
                            <tr>
                                <td style={{ width: "35%", background: '#E0F0E5' }}>{t("zkr.phone-number")}</td>
                                <td style={{ width: "65%" }}>{order.phone}</td>
                            </tr>
                            <tr>
                                <td style={{ width: "35%", background: '#E0F0E5' }}>{t("zkr.order-status")}</td>
                                <td style={{ width: "65%" }}>{order.statusName}</td>
                            </tr>
                            <tr>
                                <td style={{ width: "35%", background: '#E0F0E5' }}>{t("zkr.order-start")}</td>
                                <td style={{ width: "65%" }}>{order.timeStart}</td>
                            </tr>
                        </tbody>
                    </table>

                    {orderDocs && orderDocs.length > 0 && (
                        <div className='block-panel'>
                            <table>
                                <thead>
                                    <tr>
                                        <td style={{ width: "5%" }}>№</td>
                                        <td style={{ width: "80%" }}>{t("zkr.name-doc")}</td>
                                        <td style={{ width: "15%" }}>{t("zkr.actions")}</td>
                                    </tr>
                                </thead>
                                <tbody>
                                { orderDocs.map((item, i) => (
                                    <tr key={i}>
                                        <td style={{ width: "5%" }}>{++i}</td>
                                        <td style={{ width: "80%", textAlign: "left" }}>{item.fileName}</td>
                                        <td style={{ width: "15%" }}>
                                            <div className="table-btn-img sub-table-btn" style={{ background: `url(${svgDownload}) no-repeat`, backgroundSize: "18px" }}
                                                onClick={(e) => {
                                                    downloadDoc(item.fileName)
                                                }}
                                            >
                                                <div>
                                                    <div>{t("zkr.download")}</div>
                                                    
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )) }
                                </tbody>
                            </table>
                        
                            <span className="border-word">{t("zkr.docs")}</span>
                        </div>
                    )}

                    <div className='block-panel'>
                        <div className='order-row'>
                            { resolutions && resolutions.map((item, i) => (
                                <div key={i}>
                                    <input
                                        key={i}
                                        type="radio"
                                        value={item.name}
                                        checked={(isSelectResolution && i === 0) || (!isSelectResolution && i === 1)} //подходит только если два пункта выбора
                                        onChange={() => setSelectResolution(!isSelectResolution)}
                                    />
                                    <span className="radio-label">{item.name}</span>
                                </div>
                            ))}
                        </div>

                        <span className="border-word">{t("zkr.order-resolution")}</span>
                    </div>


                    {isSelectResolution && 
                        <div className='block-panel'>
                            <div className='order-row'>
                            <button
                                className="success-btn"
                                href=""
                                onClick={() => {
                                    setShowActUstForm(true)
                                }}
                            >
                                {t("zkr.build")}
                            </button>
                            </div>

                            <span className="border-word">{t("zkr.data-input")}</span>
                        </div>
                    }

                    <div className="order-col">
                        <div className='block-panel'>
                            <div className="order-row">
                            {isSelectResolution ? 
                                <>
                                    <button
                                        className={actRecoverySign.sign || !actRecoverySign.fileName ? "sign-doc-disabled-btn" : "sign-doc-btn" }
                                        href=""
                                        onClick={() => {
                                            signActUst();
                                        }}
                                        disabled={actRecoverySign.sign || !actRecoverySign.fileName}
                                        >
                                        {actUstLoading ?
                                            <ClipLoader size="25px" color={"#fff"} loading={true} className="sign-loader" /> :
                                            t("zkr.sign")
                                        }
                                    </button>
                                    {actRecoverySign && actRecoverySign.fileName && (
                                        <div className="doc-info"
                                            onClick={(e) => {
                                                if (actRecoverySign.data) {
                                                    downloadDoc(actRecoverySign.data, false, actRecoverySign.fileName)
                                                } else {
                                                    downloadDoc(actRecoverySign.fileName, false)
                                                }
                                                
                                            }}
                                        >
                                            <span>{actRecoverySign.fileName}</span>
                                            <div className="table-btn-img doc-info__download" style={{ background: `url(${svgDownload}) no-repeat`, backgroundSize: "18px" }}/>
                                            </div>
                                    )}
                                </>
                                : 
                                <>
                                    <button
                                        className="add-doc-btn"
                                        href=""
                                        onClick={() => {
                                            handleOtherClick(1);
                                        }}
                                        >
                                        <Icon url={svgAdd} className="icon" />
                                        {t("zkr.attach")} 
                                    </button>
                                    <input
                                        type="file"
                                        accept="application/pdf"
                                        ref={hiddenActRecoveryInput}
                                        onChange={event => {
                                            event.target.files.length !== 0 && handleActRecoverySelect(event)
                                        }}
                                        style={{ display: 'none' }}
                                    />
                                    {actRecovery && (
                                        <div className="doc-info"
                                            onClick={(e) => {
                                                downloadDoc(actRecovery, true)
                                            }}
                                        >
                                            <span>{actRecovery.fileName}</span>
                                            <div className="table-btn-img doc-info__download" style={{ background: `url(${svgDownload}) no-repeat`, backgroundSize: "18px" }}/>
                                        </div>
                                    )}
                                </>
                            }
                            
                            
                            </div>

                            <span className="border-word">{t("zkr.act-recovery")}</span>
                        </div>

                        <div className='block-panel'>
                            <div className="order-row">
                                {isSelectResolution ?
                                    <>
                                        <button
                                            className={actTransferSign.sign || !actTransferSign.fileName ? "sign-doc-disabled-btn" : "sign-doc-btn" }
                                            href=""
                                            onClick={() => {
                                                signActMezh();
                                            }}
                                            disabled={actTransferSign.sign || !actTransferSign.fileName}
                                            >
                                            {actMezhLoading ?
                                                <ClipLoader size="25px" color={"#fff"} loading={true} className="sign-loader" /> :
                                                t("zkr.sign")
                                            }
                                        </button>
                                        { actTransferSign && actTransferSign.fileName && (
                                            <div className="doc-info"
                                                onClick={(e) => {
                                                    if (actTransferSign.data) {
                                                        downloadDoc(actTransferSign.data, false, actTransferSign.fileName)
                                                    } else {
                                                        downloadDoc(actTransferSign.fileName, false)
                                                    }
                                                    
                                                }}
                                            >
                                                <span>{actTransferSign.fileName}</span>
                                                <div className="table-btn-img doc-info__download" style={{ background: `url(${svgDownload}) no-repeat`, backgroundSize: "18px" }}/>
                                            </div>
                                        )}
                                    </>
                                    :
                                    <>
                                        <button
                                            className="add-doc-btn"
                                            href=""
                                            onClick={() => {
                                                handleOtherClick(2);
                                            }}
                                            >
                                            <Icon url={svgAdd} className="icon" />
                                            {t("zkr.attach")} 
                                        </button>
                                        <input
                                            type="file"
                                            accept="application/pdf"
                                            ref={hiddenActTransferInput}
                                            onChange={event => {
                                                event.target.files.length !== 0 && handleActTransferSelect(event)
                                            }}
                                            style={{ display: 'none' }}
                                        />
                                        { actTransfer && (
                                            <div className="doc-info"
                                                onClick={(e) => {
                                                    downloadDoc(actTransfer, true)
                                                }}
                                            >
                                                <span>{actTransfer.fileName}</span>
                                                <div className="table-btn-img doc-info__download" style={{ background: `url(${svgDownload}) no-repeat`, backgroundSize: "18px" }}/>
                                            </div>
                                        )}
                                    </>
                                }
                            </div>

                            <span className="border-word">{t("zkr.act-transfer")}</span>
                        </div>

                        <div className='block-panel'>
                            <div className="order-row">
                                <button
                                    className="add-doc-btn"
                                    href=""
                                    onClick={() => {
                                        handleOtherClick(3);
                                    }}
                                >
                                    <Icon url={svgAdd} className="icon" />
                                    {t("zkr.attach")}
                                </button>
                                <input
                                    type="file"
                                    accept="application/pdf"
                                    ref={hiddenActExecutedInput}
                                    onChange={event => {
                                        event.target.files.length !== 0 && handleActExecutedSelect(event)
                                    }}
                                    style={{ display: 'none' }}
                                />
                                { actExecuted && (
                                    <div className="doc-info"
                                        onClick={(e) => {
                                            downloadDoc(actExecuted, true)
                                        }}
                                    >
                                        <span>{actExecuted.fileName}</span>
                                        <div className="table-btn-img doc-info__download" style={{ background: `url(${svgDownload}) no-repeat`, backgroundSize: "18px" }}/>
                                    </div>
                                )}
                            </div>

                            <span className="border-word">{t("zkr.act-avr")}</span>
                        </div>
                    </div>
                </>
                }
            </ModalBody>
            
            </Scrollbars>
            <ModalFooter responsive={responsive}>
                <button
                    className="success-btn"
                    href=""
                    onClick={() => {
                        onSave()
                    }}
                >
                    {t("auf.save")}
                </button>
                <button
                    className="success-btn"
                    href=""
                    onClick={() => {
                        onSend()
                    }}
                >
                    {signLoading ? <ClipLoader color={"#fff"} loading={true} size={"25px"}/> : t("zkr.send")}
                </button>
                <button
                    className="cancel-btn"
                    href=""
                    onClick={() => {
                        onCancel()
                    }}
                >
                    {t("request.cancel")}
                </button>
            </ModalFooter>

            {isShowConfirm && (
                <ConfirmDialog
                    title={t("request.13")}
                    text={t("zkr.confirm")}
                    handleOnConfirm={() => sendDataRequest()}
                    handleOnCancel={() => setShowConfirm(false)}
                />
            )}
            {isShowActUstForm && <ActUstForm
                order={order}
                setVisible={setShowActUstForm}
                setActUstForm={setActUstForm}
                setActRecoverySign={setActRecoverySign}
                setActTransferSign={setActTransferSign}
                responsive={responsive}
                act={act}
                setAct={setAct}
                actRecoverySign={actRecoverySign}
                actTransferSign={actTransferSign}
            />}
            </Modal>
        </OnTop>
    );
}
