import React from "react";
import Layers from "./../../assets/img/layers.svg";
import LayersBlack from "./../../assets/img/layersBlack.svg";
import Distance from "./../../assets/img/distance.svg";
import DistanceBlack from "./../../assets/img/distanceBlack.svg";
import * as CommonUtil from "../../utils/common.js";
import { MODE_SELECT, MODE_MEASURE } from "../../EknMap";
import { useTranslation } from "react-i18next";

export default function SideBarEkgn({
  show,
  openLayers,
  setOpenLayers,
  setMode,
  mode,
  selectedMenu,
}) {
  const { t } = useTranslation();
  return (
    <div className="sidebarEkgn">
      <button
        className={
          " ekgn" +
          (selectedMenu === CommonUtil.MENU_MAP_CONTROL ? " menu-selected" : "")
        }
        style={positionButton("Управление картой")}
        title={t("sidebar-links.2")}
        onClick={(event) => {
          setOpenLayers(event, !openLayers);
          setMode(MODE_SELECT);
        }}
      >
        <img src={show ? LayersBlack : Layers} alt="layers" />

        {show ? <span> {t("sidebar-links.2")}</span> : ""}
      </button>

      <button
        className={
          " ekgn" +
          (selectedMenu === CommonUtil.MENU_MEASURE_DISTANCE
            ? " menu-selected"
            : "")
        }
        title={t("sidebar-links.4")}
        style={positionButton("Измерить расстояние")}
        onClick={() => {
          setMode(mode === MODE_MEASURE ? MODE_SELECT : MODE_MEASURE);
        }}
      >
        <img src={show ? DistanceBlack : Distance} alt="distance icon" />

        {show ? <span> {t("sidebar-links.2")}</span> : ""}
      </button>
    </div>
  );
}

const positionButton = (title) => {
  switch (title) {
    case "Управление картой":
      return {
        top: "110px",
        right: "60px",
      };

    case "Измерить расстояние":
      return {
        top: "170px",
        right: "60px",
      };

    default:
      return null;
  }
};
