import React, { useEffect } from "react";
import svgClose from '../../assets/img/stat_close.svg';
import searchSvg from "../../assets/img/search-obj.svg";
import { useTranslation } from "react-i18next";
import comboImg from "../../assets/img/combo.svg";
import { Scrollbars } from "react-custom-scrollbars";
import styled from "styled-components";
import { RegionsMobileWrapper } from "./RegionsMobile";
import { formatDate } from "./TableQueue";
import firstPageArrow from "../../assets/img/first-page-arrow.svg";
import prevPageArrow from "../../assets/img/prev-page-arrow.svg";
import nextPageArrow from "../../assets/img/next-page-arrow.svg";
import lastPageArrow from "../../assets/img/last-page-arrow.svg";
import { statusCheck } from "./TableQueue";

const QueueMobileWrapper = styled(RegionsMobileWrapper)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .queue-search-btn-mob {
    width: 24px;
    height: 24px;
    background-color: #A0B6A6;
    -webkit-mask: url(${searchSvg}) no-repeat;
    mask: url(${searchSvg}) no-repeat;
    cursor: pointer;
  }

  .queue-search-fld-mob {
    display: flex;
    margin-left: 8px;
    border: 1px solid #B4CFBC;
    border-radius: 6px;
    align-items: center;
    padding: 0 12px;
    width: 100%;
    justify-content: space-between;

    input {
      border: none;
      outline: none;
    }
  }

  .queue-search-status-mob {
    position: relative;
    display: flex;
    margin: 0px 20px 16px 20px;
    border: 1px solid #B4CFBC;
    border-radius: 6px;
    height: 40px;
    align-items: center;
    justify-content: space-between;
    padding-left: 16px;
    font-family: "sf-pro-display-regular";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #A0B6A6;
  }

  .queue-status-btn-mob {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #DCF0E2;
    border-left: 1px solid #B4CFBC;
    height: 100%;
    border-radius: 0px 5px 5px 0px;
  }

  .queue-search-inner-block-mob {
    position: absolute;
    background: rgb(255, 255, 255);
    top: 48px;
    left: 0px;
    z-index: 1;
    width: 100%;
    border: 1px solid #B4CFBC;
  }

  .queue-search-inner-variants-mob {
    border-bottom: 1px solid #B4CFBC;
    font-family: "sf-pro-display-regular";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #294A37;
    padding: 12px 16px;
  }

  .queue-search-inner-variants-mob:last-child {
    border: none;
  }

  .queue-card {
    border: 1px solid #B4CFBC;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    margin: 0px 20px 16px 20px;
    padding: 12px 16px 16px 12px;
    display: flex;
  }

  .queue-card-lbl {
    font-family: "sf-pro-display-regular";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #A0B6A6;
    margin-bottom: 2px;
  }

  .queue-card-num {
    font-family: "sf-pro-display-regular";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #01B733;
  }

  .queue-card-status {
    font-family: "sf-pro-display-regular";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #01B733;
  }

  .queue-card-val {
    font-family: "sf-pro-display-regular";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #294A37;
  }

  .queue-card-line {
    border-top: 1px solid #B4CFBC;
    margin: 8px 0px;
    width: 100%;
  }

  .page-nav-mob {
    display: flex;
    font-size: 12px;
    align-items: center;
    margin: 0 24px;
    font-family: "sf-pro-display-regular";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;

    .page-count {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .first-btn {
      width: 12px;
      height: 15px;
      background: url(${firstPageArrow}) no-repeat;
      background-size: 100%;
      margin-right: 14px;
      cursor: pointer;
    }
    .prev-btn {
      width: 12px;
      height: 11px;
      background: url(${prevPageArrow}) no-repeat;
      background-size: 100%;
      cursor: pointer;
    }
    .next-btn {
      width: 12px;
      height: 11px;
      background: url(${nextPageArrow}) no-repeat;
      background-size: 100%;
      margin-right: 14px;
      cursor: pointer;
    }
    .last-btn {
      width: 12px;
      height: 15px;
      background: url(${lastPageArrow}) no-repeat;
      background-size: 100%;
      cursor: pointer;
    }
  }

  .queue-note-mob {
    font-family: "sf-pro-display-regular";
    font-style: italic;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #606060;
    margin: 14px 20px;
  }

  .queue-not-allow-mob {
    margin: 0 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-family: "sf-pro-display-regular";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #294A37;
  }

  .queue-err {
    color: #D00606;
  }

  .queue-warn {
    color: #c5c2c2;
  }
`;

export default function QueueMobile({
  queueNum, getQueue, ate, nameAndInitials,
  setQueueNum, setNameAndInitials, getQueueByNum,
  getQueueStatus, setOpenRegionsMob, setOpenQueueMob
}) {
  const [queue, setQueue] = React.useState();
  const [queueStatus, setQueueStatus] = React.useState();
  const [queueStatusId, setQueueStatusId] = React.useState('');
  const [showStatuses, setShowStatuses] = React.useState(false);
  const { i18n } = useTranslation();
  const [getObjects, setGetObjects] = React.useState({});
  const [pageNum, setPageNum] = React.useState(0);
  const [count, setCount] = React.useState(0);
  const [queueLoading, setQueueLoading] = React.useState(true);
  const nameRef = React.useRef();
  const statusesRef = React.useRef();

  const handleNextPage = React.useCallback(() => {
    if (pageNum + 1 <= Math.ceil(count / 10) - 1) {
      getObjects.func(pageNum + 1);
      setPageNum(pageNum + 1);
    }
  }, [count, pageNum, getObjects]);

  const handlePrevPage = React.useCallback(() => {
    if (pageNum > 0) {
      getObjects.func(pageNum - 1);
      setPageNum(pageNum - 1);
    }
  }, [count, pageNum, getObjects]);

  const handleLastPage = React.useCallback(() => {
    if (pageNum + 1 <= Math.ceil(count / 10) - 1) {
      getObjects.func(Math.ceil(count / 10) - 1);
      setPageNum(Math.ceil(count / 10) - 1);
    }
  }, [count, pageNum, getObjects]);

  const handleFirstPage = React.useCallback(() => {
    if (pageNum > 0) {
      getObjects.func(0);
      setPageNum(0);
    }
  }, [count, pageNum, getObjects]);

  useEffect(() => {
    if (queueNum) {
      getQueueByNum(i18n.language, queueNum, ate.kato)
        .then(result => {
          setQueue(result);
          setCount(result.count);
          setPageNum(result.pageNum);
          setQueueLoading(false);
        })
    } else {
      getQueue(i18n.language, 0, 10, ate ? ate.kato : '', nameAndInitials).then(result => { //
        setQueue(result);
        setCount(result.count);
        setQueueLoading(false);
      });
    }

    getQueueStatus(i18n.language).then(result => {
      setQueueStatus(result);
    })

    return () => {
      setQueueNum(null);
    };
  }, [i18n.language, getQueue]);

  useEffect(() => {
    setGetObjects({
      func: pageNum => {
        getQueue(i18n.language, pageNum, 10, ate ? ate.kato : '', nameAndInitials, queueStatusId).then(result => {
          setQueue(result);
          setCount(result.count);
        });
      },
    })
  }, [queue, i18n.language, getQueue, ate]);

  const handleSearch = (nameAndInitials, statusId) => {
    getQueue(i18n.language, 0, 10, ate ? ate.kato : '', nameAndInitials, statusId).then(result => {
      setQueue(result);
      setPageNum(0);
      setCount(result.count);
    });
  };

  const statusSelect = id => {
    setQueueStatusId(id);
    handleSearch(nameRef.current.value, id);
    setShowStatuses(false);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (statusesRef.current && !statusesRef.current.contains(event.target)) {
        setShowStatuses(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [statusesRef]);

  return (
    <QueueMobileWrapper>
      <div>
        <div className="queue-head-mob">
          <div className="queue-title-mob">{i18n.t("queue.title1")}</div>
          <div onClick={() => {
            setOpenQueueMob(false);
          }}>
            <img src={svgClose} style={{ width: '16px' }} alt="" />
          </div>
        </div>
        <div style={{ display: "flex", padding: "16px 20px" }}>
          <div className="queue-cancel-mob"
            onClick={() => {
              setOpenQueueMob(false);
              setOpenRegionsMob(true);
            }}
          >
            <div className="mob-cancel-img" />
          </div>
          <div className="queue-search-fld-mob">
            <input placeholder={i18n.t("queue.find")} ref={nameRef} defaultValue={nameAndInitials ? nameAndInitials : ''} />
            <div className="queue-search-btn-mob" onClick={() => {
              setNameAndInitials(nameRef.current.value);
              handleSearch(nameRef.current.value, queueStatusId);
            }} />
          </div>
        </div>

        <div className="queue-search-status-mob">
          <div>{queueStatusId ? queueStatus.find(item => item.id == queueStatusId).statusName : i18n.t("queue.all-status")}</div>
          <div className="queue-status-btn-mob"
            onClick={() => {
              setShowStatuses(!showStatuses);
            }}
          >
            <img src={comboImg}
              style={{
                width: 14,
                height: 7,
                marginTop: "2px"
              }}
              alt=""
            />
          </div>
          {showStatuses &&
            <div ref={statusesRef} className="queue-search-inner-block-mob">
              <div className="queue-search-inner-variants-mob" onClick={() => {
                statusSelect('');
              }}>{i18n.t("queue.all-status")}</div>
              {queueStatus.filter(item => item.id != 4).map((item, i) =>
                <div className="queue-search-inner-variants-mob" key={i} onClick={() => {
                  statusSelect(item.id);
                }}>{item.statusName}</div>
              )}
            </div>
          }
        </div>

        {ate && ate.kato === '750000000' ?
          <div className="queue-not-allow-mob">
            <div>Уважаемый услугополучатель сообщаем, что в связи с отсутствием свободных территорий (согласно утвержденного Генерального плана), в данном регионе специальный учет на получение земельного участка для индивидуального жилищного строительства (очередь) не ведется</div>
            <div>Құрметті көрсетілетін қызметті алушы, бос аумақтардың болмауына байланысты (бекітілген Бас жоспарға сәйкес), осы өңірде жеке тұрғын үй құрылысы үшін жер учаскесін алуға арнайы есепке алудың (кезек) жүргізілмейтінін хабарлаймыз</div>
          </div>
          :
          (ate && ate.refine) || !queue || !queue.items.length ?
            queueLoading ?
              null
              :
              <div className="queue-not-allow-mob" style={{ justifyContent: "flex-end", textAlign: "center" }}>
                {i18n.t("queue.check-list")}
              </div>
            :
            <Scrollbars
              autoHeight={true}
              autoHide={true}
              autoHeightMax={"calc(100vh - 350px)"}
              renderView={props => <div {...props} className="scrollbar-view" />}
            >
              {queue.items.map((item, i) => (
                <div className="queue-card" key={i}>
                  <div style={{ width: "auto" }}>
                    <div className="queue-card-lbl">№</div>
                    <div className={"queue-card-num " + statusCheck(item)}>{item.num}</div>
                    <div className={"queue-card-status " + statusCheck(item)}>{item.status}</div>
                  </div>
                  <div style={{ paddingLeft: "16px", width: "100%" }}>
                    <div className="queue-card-lbl">{i18n.t("queue.fio")}</div>
                    <div className="queue-card-val">{item.name}</div>
                    <div className="queue-card-line"></div>
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "50%" }}>
                        <div className="queue-card-lbl">{i18n.t("queue.num")}</div>
                        <div className="queue-card-val">{item.app_num}</div>
                      </div>
                      <div style={{ width: "50%" }}>
                        <div className="queue-card-lbl">{i18n.t("queue.date") + " " + i18n.t("queue.statement")}</div>
                        <div className="queue-card-val">{formatDate(item.app_date)}</div>
                      </div>
                    </div>
                    <div className="queue-card-line"></div>
                    <div className="queue-card-lbl">{i18n.t("queue.region")}</div>
                    <div className="queue-card-val">{i18n.language === 'kz' ? item.ate_name_kz : item.ate_name_ru}</div>
                  </div>
                </div>
              ))}
            </Scrollbars>
        }
      </div>

      <div>
        <div className="page-nav-mob">
          <div className="first-btn" onClick={handleFirstPage}></div>
          <div className="prev-btn" onClick={handlePrevPage}></div>
          <div className="page-count">
            <div>Страница {Math.min(pageNum + 1, Math.ceil(count / 10))}</div>
            <div>из {Math.ceil(count / 10)}</div>
          </div>
          <div className="next-btn" onClick={handleNextPage}></div>
          <div className="last-btn" onClick={handleLastPage}></div>
        </div>
        <div className="queue-note-mob">
          {i18n.t('queue.descr1')}
        </div>
      </div>
    </QueueMobileWrapper>
  );
}
