const findGroup = (layerConfig, groupName) => layerConfig.filter(group => group.name === groupName)[0];
const filterGroup = (layerConfig, groupName) => layerConfig.filter(group => group.name !== groupName);

const findLayer = (group, layerName) => {
  const layers = group.layers.filter(layer => layer.name === layerName);
  return layers.length > 0 ? layers[0] : null;
}

export const findLayerByName = (layerConfig, layerName) => {
  for (let i = 0; i < layerConfig.length; i++) {
    const group = layerConfig[i];
    for (let j = 0; i < group.layers.length; i++) {
      const layer = group.layers[j];
      if (layer.name === layerName) {
        return layer;
      }
    }
  }
}

async function fetchWithTimeout(resource, options = {}) {
  const { timeout = 1000 } = options;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  const response = await fetch(resource, {
    ...options,
    signal: controller.signal
  });
  clearTimeout(id);

  return response;
}

const getJsonLayers = (region, standalone, layers, isCosmoUp, setIsCosmoUp, showLayer, layersVisibility, setLayersVisibility) => {
  const arcgisLayers = [];
  Object.values(layers).forEach((layerObject) => {
    if (layerObject.get("arcgis")) {
      arcgisLayers.push(layerObject);
    }
  });

  let lang = window.localStorage.getItem("i18nextLng");

  let resultLayers = [];

  const promises = [
    new Promise((resolve, reject) => {
      fetch(`${window.REST_URL}/map/versions?lang=${lang}`, {
        method: "GET",
        mode: "cors",
        credentials: "include",
      })
        .then((response) => response.json())
        .then((json) => {
          Object.keys(json).forEach((layerName) => {
            resultLayers
              .filter((layer) => layer.name === layerName)
              .forEach((layer) => (layer.history = json[layerName]));
          });
          resolve();
        })
        .catch((err) => reject(err));
    }),
  ];

  return new Promise((resolve, reject) => {

    fetch(`${window.REST_URL}/map/layers?lang=${lang}`, {
      method: "GET",
      mode: "cors",
      credentials: "include",
    })
      .then(response => response.json())
      .then(async resultLayers => {
        // Удалить слой проектных ЗУ для ПКК
        let groups = resultLayers.filter(group => group.name === 'real_estate');
        // if (groups && groups.length > 0) {
        //   groups[0].layers = groups[0].layers.filter(layer => layer.name !== 'sogl');
        // }
        // Удалить cosmo из resultLayers если сервер отключен
        if (isCosmoUp) {
          try {
            const res = await fetchWithTimeout(`${window.COSMO_URL}/CosmoServer/up`, { timeout: 3000 });
            if (res && res.status === 200) {
              const cosmoGroup = findGroup(resultLayers, 'cosmo');
              if (standalone && cosmoGroup) {
                arcgisLayers.forEach((layer) => {
                  const layerName = layer.get("name");
                  const rest = layer.get("restPath");

                  const match = layer.getSource().getParams().LAYERS.match(/\d+/);
                  const sublayer = match ? Number(match[0]) : 0;
                  const regionCode = region ? region.regionCode : "00";
                  fetch(`${window.COSMO_URL}/${rest}/MapServer/legend?f=pjson&regionCode=${regionCode}`)
                    .then((res) => {
                      if (res.ok) {
                        return res.json();
                      }
                      throw new Error('Ошибка при получении легенды с MapServer.')
                    })
                    .then((json) => {
                      const garyshSub = json.layers.filter(
                        (layer) => layer.layerId === sublayer
                      )[0];

                      const jsonLayer = findLayer(cosmoGroup, layerName);

                      if (garyshSub && jsonLayer) {
                        jsonLayer.sublayers = garyshSub.legend.map((item) => {
                          return {
                            title: item.label || garyshSub.layerName,
                            src: `data:${item.contentType};base64, ${item.imageData}`,
                          };
                        });
                      } else if (jsonLayer) {
                        jsonLayer.sublayers = [{ title: "Легенда отсутствует" }];
                      }
                    })
                    .catch((err) => console.error(err));
                });
              }
            }
          } catch {
            // Скрыть слои cosmo
            const cosmoGroup = findGroup(resultLayers, 'cosmo');
            cosmoGroup && cosmoGroup.layers.forEach(item => {
              layersVisibility[item.name] = false;
              setLayersVisibility(Object.assign({}, layersVisibility));
              showLayer(item, item.name, false);
            });

            resultLayers = filterGroup(resultLayers, 'cosmo');
            setIsCosmoUp(false);
          }
        } else {
          resultLayers = filterGroup(resultLayers, 'cosmo');
        }
        resolve(resultLayers);
      })
      .catch((e) => reject(e));
  });
};

export default getJsonLayers;
