import React from "react";
import { useEffect } from "react";
import { unByKey } from "ol/Observable.js";
import Overlay from "ol/Overlay.js";
import { LineString, Polygon } from "ol/geom.js";
import Draw from "ol/interaction/Draw.js";
import { Vector as VectorLayer } from "ol/layer.js";
import { Vector as VectorSource } from "ol/source.js";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style.js";
import { Snap } from "ol/interaction";

export default function Line({
  open,
  map,
  createSnapLayer,
  layers,
  region,
  setErrors,
  mode
}) {
  useEffect(() => {
    if (open) {
      layers.draw.getSource().clear();
      createSnapLayer(layers, region, setErrors, mode);
      map.addLayer(layers.snap);
      const snap = new Snap({ source: layers.snap.getSource() });
      const draw = addInteraction(map);
      map.addInteraction(draw);
      map.addInteraction(snap);

      return () => {
        map.removeInteraction(draw);
        map.removeInteraction(snap);
        overlays.forEach(overlay => map.removeOverlay(overlay));
        overlays = [];
        map.removeLayer(vector);
      };
    }
  }, [open]);
  return <div />;
}

var vector;
var measureTooltipElement;
var measureTooltip;
var overlays = [];

/**
 * Format length output.
 * @param {module:ol/geom/LineString~LineString} line The line.
 * @return {string} The formatted length.
 */
var formatLength = function (line) {
  var length = line.getLength();

  var output;
  if (length > 100) {
    output = Math.round((length / 1000) * 100) / 100 + " км";
  } else if (length > 1) {
    output = Math.round(length * 100) / 100 + " м";
  } else if (length < 1) {
    output = Math.round(length * 100) + " см";
  }
  return output;
};

/**
 * Format area output.
 * @param {module:ol/geom/Polygon~Polygon} polygon The polygon.
 * @return {string} Formatted area.
 */
var formatArea = function (polygon) {
  var area = polygon.getArea();
  var output;
  if (area > 10000) {
    output = Math.round((area / 1000000) * 100) / 100 + " км<sup>2</sup>";
  } else {
    output = Math.round(area * 100) / 100 + " м<sup>2</sup>";
  }
  return output;
};

function addInteraction(map) {
  var source = new VectorSource();

  vector = new VectorLayer({
    source: source,
    style: new Style({
      fill: new Fill({
        color: "#FF4500",
      }),
      stroke: new Stroke({
        color: "#FF4500",
        width: 2,
      }),
      image: new CircleStyle({
        radius: 7,
        fill: new Fill({
          color: "#FF4500",
        }),
      }),
    }),
  });
  map.addLayer(vector);

  var type = "LineString";
  const draw = new Draw({
    source: source,
    type: type,
    style: new Style({
      fill: new Fill({
        color: "rgba(255, 255, 255, 0.5)",
      }),
      stroke: new Stroke({
        color: "rgba(255,0,0, .7)",
        lineDash: [10, 10],
        width: 2,
      }),
      image: new CircleStyle({
        radius: 3,
        stroke: new Stroke({
          color: "rgba(0, 0, 255, 1)",
        }),
        fill: new Fill({
          color: "rgba(255, 0, 0, 1)",
        }),
      }),
    }),
  });
  map.addInteraction(draw);

  createMeasureTooltip(map);
  //  createHelpTooltip();

  var sketch;
  var listener;
  draw.on(
    "drawstart",
    function (evt) {
      // set sketch
      sketch = evt.feature;

      /** @type {module:ol/coordinate~Coordinate|undefined} */
      var tooltipCoord = evt.coordinate;

      listener = sketch.getGeometry().on("change", function (evt) {
        var geom = evt.target;
        var output;
        if (geom instanceof Polygon) {
          output = formatArea(geom);
          tooltipCoord = geom.getInteriorPoint().getCoordinates();
        } else if (geom instanceof LineString) {
          output = formatLength(geom);
          tooltipCoord = geom.getLastCoordinate();
        }
        measureTooltipElement.innerHTML = output;
        measureTooltip.setPosition(tooltipCoord);
      });
    },
    this,
  );

  draw.on(
    "drawend",
    function () {
      measureTooltipElement.className =
        "measure-tooltip measure-tooltip-static";
      measureTooltip.setOffset([0, -7]);
      // unset sketch
      sketch = null;
      // unset tooltip so that a new one can be created
      measureTooltipElement = null;
      createMeasureTooltip(map);
      unByKey(listener);
    },
    this,
  );

  return draw;
}

/**
 * Creates a new measure tooltip
 */
function createMeasureTooltip(map) {
  if (measureTooltipElement) {
    measureTooltipElement.parentNode.removeChild(measureTooltipElement);
  }
  measureTooltipElement = document.createElement("div");
  measureTooltipElement.className = "measure-tooltip measure-tooltip-measure";
  measureTooltip = new Overlay({
    element: measureTooltipElement,
    offset: [0, -15],
    positioning: "bottom-center",
  });
  map.addOverlay(measureTooltip);
  overlays.push(measureTooltip);
}
