import React, { useEffect } from 'react'
import svgNotif from '../../assets/img/notif.svg';
import NotifTab from './NotifTab';
import styled from "styled-components";
import { HeadImg } from '../Header';

const NotifIcon = styled.div`
    margin-right: 12px;

    button {
        height: 42px;
        width: 80%;
        padding-top: 0px;
        text-align: left;
        font-size: 9pt;
        background: #fff;
        border: none;
        border-radius: 15px;
        margin-top: 3px;
    }

    button:focus {
        outline: none;
    }

    .notif-count {
        position: absolute;
        margin: -7px 0 0 15px;
        color: #ae2a00;
        background: #fff;
        border-radius: 50%;
        width: 18px;
        height: 18px;
        font-size: 12px;
        font-weight: bold;
        text-align: center;
        line-height: 19px;
        border: 1px solid #AB2900;
        font-family: Arial;
    }
`;

export default function Notif({
    showNotif,
    handleClickNotif,
    notifs,
    handleUpdateNotif,
    orderLoaded,
    setOrder,
    handleSelectOrder,
    handleClickOrder,
    handleGetNotifs,
    palette,
    responsive,
    notifPage,
    setNotifPage,
    flag
}) {

    useEffect(() => {
        var interval = setInterval(() => {
            handleGetNotifs(notifPage, 10);
        }, 1000 * 60);
        return () => clearInterval(interval);
    }, [notifPage, handleGetNotifs])

    return <NotifIcon>
        <button
            onClick={() => {
                handleClickNotif();
            }}
            disabled={flag}
        >
            <HeadImg style={{ boxShadow: "0 3px 6px #ff733e8a" }} img={svgNotif} background="#ae2a00" position="50" />
            {notifs.notReadCnt > 0 &&
                <div className="notif-count">{notifs.notReadCnt}</div>
            }
        </button>
        {showNotif && <NotifTab
            notifs={notifs}
            handleUpdateNotif={handleUpdateNotif}
            orderLoaded={orderLoaded}
            setOrder={setOrder}
            handleSelectOrder={handleSelectOrder}
            handleClickOrder={handleClickOrder}
            handleGetNotifs={handleGetNotifs}
            palette={palette}
            responsive={responsive}
            notifPage={notifPage}
            setNotifPage={setNotifPage}
        />}
    </NotifIcon>;
}