import React from "react";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';
import { EmailVerifyWrap } from "./EmailVerify";
import Cookies from "js-cookie";

export const EmailErrorWrap = styled(EmailVerifyWrap)`
  .email-verify-box {
    .email-verify-btns
      .email-verify-btn.btn-check {
        background: #4a74ed;
        cursor: pointer;
      }
    }
  }
`;

export default function EmailVerify({
  setShowEmailError
}) {
  const { t } = useTranslation()

  return (
    <EmailErrorWrap>
      <div className="email-verify-box">
        <div className="email-verify-head">
          {t('email-verify.head')}
        </div>
        <div className="email-verify-body">
          {t('email-verify.error')}
        </div>
        <div className="email-verify-btns">
          <div
            className="email-verify-btn btn-check"
            onClick={() => {
              Cookies.remove(window.CK_EMAIL_ERROR);
              setShowEmailError(false);
            }}
          >
            Ok
          </div>
        </div>
      </div>
    </EmailErrorWrap>
  );
}