
import { signXml } from "./NCALayerOps";
import {setOrder} from "./EknMap";

export function sendOrder(orderId) {
  return new Promise((resolve, reject) => {
    fetch(`${window.REST_URL}/cabinet/order/${orderId}/xml`, {
      method: "GET",
      mode: "cors",
      credentials: "include",
    })
      .then(response => response.text())
      .then(text => {
        if (text!=='head error') {
          return signXml(text, 'SIGNATURE')
        }
        throw new Error('Head Error');
      })
      .then(result => result.responseObject)
      .then(text => {
        fetch(`${window.REST_URL}/cabinet/order/${orderId}/send`, {
          method: "POST",
          mode: "cors",
          credentials: "include",
          headers: {
            "Content-Type": "application/xml",
          },
          body: text
        })
        .then(response => response.json())
        .then(json => resolve(json))
        .catch(e => reject(e))
      })
      .catch(e => reject(e))
  });
}

export function signOrder(orderId, setSignLoading) {
  return new Promise((resolve, reject) => {
    fetch(`${window.REST_URL}/cabinet/order/${orderId}/xml`, {
      method: "GET",
      mode: "cors",
      credentials: "include",
    })
      .then(response => response.text())
      .then(text => signXml(text, 'SIGNATURE'))
      .then(result => result.responseObject)
      .then(text =>resolve(text))
      .catch(e =>{
        console.log(e);
        setSignLoading(false);
      })
  });
}

export function cancelOrder(orderId) {
	return new Promise((resolve, reject) => {
	  fetch(`${window.REST_URL}/cabinet/order/${orderId}/xml?cancel=true`, {
		method: "GET",
		mode: "cors",
		credentials: "include",
	  })
		.then(response => response.text())
		.then(text => signXml(text, 'SIGNATURE'))
		.then(result => result.responseObject)
		.then(text => {
		  fetch(`${window.REST_URL}/cabinet/order/${orderId}/send?cancel=true`, {
			method: "POST",
			mode: "cors",
			credentials: "include",
			headers: {
			  "Content-Type": "application/xml",
			},
			body: text
		  })
		  .then(response => response.json())
		  .then(json => resolve(json))
		  .catch(e => reject(e))
		})
		.catch(e => reject(e))
	});
  }
  
  export function getLandCost(landId) {
  return new Promise((resolve, reject) => {
    fetch(`${window.REST_URL}/map/cost/${landId}`, {
      method: "GET",
      mode: "cors",
      credentials: "include",
    })
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(e => reject(e));
  });
}


export function postOrderDocs(order) {
  return new Promise((resolve, reject) => {
    fetch(`${window.REST_URL}/cabinet/order/docs`, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(order),
    })
      .then(response => resolve(response))
      .catch(e => reject(e))
  });
  
}

// export function postDoc(doc, docType) {
//   const formData = new FormData();
//   formData.append(doc.name, new Blob([doc]), doc.name.replaceAll('+', ' '));
//   return new Promise((resolve, reject) => {
//     axios({
//       url: `${window.REST_URL}/upload?docType=${docType}`,
//       headers: {
//         'Content-Type': 'multipart/form-data',
//       },
//       method: "post",
//       data: formData
//     })
//       .then(response => resolve(response.data))
//       .catch(e => reject(e))
//   });
// }

export function uploadAppDoc(orderId, file, doctype, name, oldName) {
  const formData = new FormData();
  // formData.append('file', new Blob([file]), name.replaceAll('+', ' '));
  if (oldName) {
    formData.append('oldFileName', oldName);
  } 
  
  formData.append('file', new Blob([file]), name.replaceAll('+', ' '));
  return new Promise((resolve, reject) => {
    fetch(`${window.REST_URL}/cabinet/order/${orderId}/upload?type=${doctype}`, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      // headers: {
      //   "Content-Type": "multipart/form-data",
      // },
      body: formData,
    })
    .then(response =>{
      resolve(response)
    })
    .catch(e => reject(e))
  })
}

export function deleteAppDoc(orderId, fileName) {
  return new Promise((resolve, reject) => {
    fetch(`${window.REST_URL}/cabinet/order/${orderId}/delete?fileName=${fileName}`)
    .then(response =>{
      resolve(response)
    })
    .catch(e => reject(e))
  })
  
}