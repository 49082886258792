import React from "react";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';

const ListButtonComponent = styled.button`
  visibility: ${props => (props.isVisible ? "visible" : "hidden")};
  text-decoration: none;
  background-color: inherit;
  border: none;
  outline: none;
  
  border-radius: 4px;
  border-style : Solid;
  border-color : #005B2D;
  border-color : rgba(0, 91, 45, 1);
  border-width : 1px;
  width: 100%
  min-width : 46px;
  height : 16px;
  
  border-radius : 7px;
  -moz-border-radius : 7px;
  -webkit-border-radius : 7px;
  font-family: 'Open Sans', sans-serif;
  font-size : 11px;
  color : #005B2D;
  color : rgb(0, 91, 45);
`;

export default function ListButton({ theme, listButtonHandler, listButtonVisible }) {


  const { t } = useTranslation();
  return (
    <ListButtonComponent
      className="list-button"
      palette={theme}
      onClick={listButtonHandler}
      isVisible={listButtonVisible}
    >
      {t('manage-layer.1')}
    </ListButtonComponent>

  )
};
