import React from "react";
import styled from "styled-components";

const CheckboxComponent = styled.input`
  &[type="checkbox"] {
    appearance: none;
    position: relative;
    outline: none;
  }
  &[type="checkbox"]::before {
    content: "";
    box-sizing: border-box;
    width: 15px;
    height: 15px;
    position: absolute;
    background-color: inherit;
    top: -7.5px;
    left: 5px;
    border: 1.5px solid ${props => props.palette.additional};
    border-radius: 2px;
  }
  &[type="checkbox"]:hover:before {
    cursor: pointer;
    background-color: ${props => props.palette.additional};
  }
  &[type="checkbox"]:hover:after {
    cursor: pointer;
    position: absolute;
    left: 9.25px;
    top: -6.5px;
    content: "";
    width: 4px;
    height: 8px;
    transform: rotate(45deg);
    border-bottom: 2px solid ${props => props.palette.secondary};
    border-right: 2px solid ${props => props.palette.secondary};
  }
  &[type="checkbox"]:checked:after {
    cursor: pointer;
    position: absolute;
    left: 9.25px;
    top: -6.5px;
    content: "";
    width: 4px;
    height: 8px;
    transform: rotate(45deg);
    border-bottom: 2px solid ${props => props.palette.secondary};
    border-right: 2px solid ${props => props.palette.secondary};
  }
  &[type="checkbox"]:checked:before {
    background-color: ${props => props.palette.text};
    border: 1.5px solid ${props => props.palette.text};
  }
`;

const Checkbox = ({ theme, isChecked, checkHandler, label }) => {
  return (
    <CheckboxComponent
    style={{margin: "revert"}}
    type="checkbox"
    palette={theme}
    checked={isChecked}
    onChange={checkHandler}
    /> 
  )
}

export default Checkbox;