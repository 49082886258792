// export default function getRegionID(code) {
//   console.log(code);
//   switch (code) {
//     case "01001":
//       return 1;
//     case "01002": // Астраханский
//       return 3;
//     case "01003": // Атбасарский
//       return 4;
//     case "01004": // Сандыктауский
//       return 17;
//     case "01005": // Аршалынский
//       return 2;
//     case "01006": // Ерейментауский
//       return 14;
//     case "01007": // Егиндыкольский
//       return 13;
//     case "01008": // Коргалджынский
//       return 16;
//     case "01009": // Буландынский
//       return 6;
//     case "01011": // Целиноградский
//       return 19;
//     case "01012": // Шортандинский
//       return 18;
//     case "01160":
//       return 12;
//     case "01171":
//       return 7;
//     case "01174":
//       return 8;
//     case "01275":
//       return 11;
//     case "01277":
//       return 15;
//     case "01278":
//       return 10;
//     default:
//       return 0;
//   }
// }




const  getRegionID = (regionName, regionCode) => {

  // функция, вызываемая при переключении региона
  // находит ID региона в базе garysh
  // ID региона передаётся в параметры слоя, чтобы отобразить фичи только в этом регионе
  
  if (regionCode === "00") {
    return ""; 
  }

  const range = getRange(regionCode);

  // на всякий случай создаём дополнительный вариант названия региона, меняя "ы" на "и"
  // Например, у нас Аршалынский, на garysh Аршалинский
  const variant = regionName.replace(/ы/, "и");

  // создаём регулярку с обоими вариантами
  let re = new RegExp(`${regionName}|${variant}`, "gi");

  let id;
  if (range.from === range.to) {
    id = range.from;
  } else {
    for (let i = range.from; i <= range.to; i++) {
      if (re.test(lookupTable[i])) {
        id = i;
      }
    }
    if (!id) {
      id = range.from;
    }
  }

  return id;
}

function getRange(code) {
  
  // по коду области определяем, в каком отрезке lookupTable находится нужный нам регион
  switch (code) {
    case "01":
      return { from: 0, to: 19 };
    case "02":
      return { from: 20, to: 33 };
    case "03":
      return { from: 34, to: 50 };
    case "04":
      return { from: 51, to: 59 };
    case "05":
      return { from: 148, to: 166 };
    case "06":
      return { from: 167, to: 178 };
    case "08":
      return { from: 179, to: 192 };
    case "09":
      return { from: 60, to: 69 };
    case "10":
      return { from: 89, to: 97 };
    case "12":
      return { from: 70, to: 88 };
    case "13":
      return { from: 98, to: 103 };
    case "14":
      return { from: 104, to: 116 };
    case "15":
      return { from: 117, to: 131 };
    case "19":
      return { from: 132, to: 147 };
    case "20":
      return { from: 34, to: 34 };
    case "21":
      return { from: 0, to: 0 };
    case "22":
      return { from: 135, to: 135 };
	case "23":
		return { from: 148, to: 166 };
	case "24":
		return { from: 60, to: 69 };
	case "25":
		return { from: 34, to: 50 };
	default:
      break;
  }
}

// индекс региона в этом массиве соответствует id этого региона в базе garysh
const lookupTable = [
  "Акмолинская область",
  "Аккольский",
  "Аршалинский",
  "Астраханский",
  "Атбасарский",
  "Биржан сал",
  "Буландинский",
  "Бурабайский",
  "г. Кокшетау",
  "г. Степногорск",
  "Жаксынский",
  "Жаркаинский",
  "Зерендинский",
  "Егиндыкольский",
  "Ерейментауский",
  "Есильский",
  "Коргалжынский",
  "Сандыктауский",
  "Шортандинский",
  "Целиноградский",
  "Актюбинская область",
  "Айтекебийский",
  "Алгинский",
  "Байганинский",
  "г.а. Актобе",
  "Хобдинский",
  "Хромтауский",
  "Иргизский",
  "Каргалинский",
  "Темирский",
  "Мартукский",
  "Мугалжарский",
  "Уилский",
  "Шалкарский",
  "Алматинская область",
  "Аксуский",
  "Алакольский",
  "Балхашский",
  "Жамбылский",
  "Енбекшиказахский",
  "Ескельдинский",
  "Илийский",
  "Карасайский",
  "Караталский",
  "Кербулакский",
  "Коксуский",
  "Панфиловский",
  "Райымбекский",
  "Саркандинский",
  "Талгарский",
  "Уйгурский",
  "Атырауская область",
  "г. Атырау",
  "Жылыойский",
  "Макатский",
  "Махамбетский",
  "Кзылкогинский",
  "Курмангазинский",
  "Исатайский",
  "Индерский",
  "Карагандинская область",
  "Жанааркинский",
  "Улытауский",
  "Абайский",
  "Актогайский",
  "Бухар-Жырауский",
  "Каркаралинский",
  "Нуринский",
  "Шетский",
  "Осакаровский",
  "Костанайская область",
  "Алтынсаринский",
  "Амангельдинский",
  "Джангельдинский",
  "Аулиекольский",
  "Денисовский",
  "Житикаринский",
  "г.а. Аркалык",
  "г. Костанай",
  "Камыстинский",
  "Карабалыкский",
  "Карасуский",
  "Костанайский",
  "Мендыкаринский",
  "Hаурзумский",
  "Сарыкольский",
  "Федоровский",
  "Тарановский",
  "Узункольский",
  "Кызылординская область",
  "Аральский",
  "г. Кызылорда",
  "Жанакорганский",
  "Жалагашский",
  "Казалинский",
  "Кармакшинский",
  "Сырдарьинский",
  "Чиилийский",
  "Мангистауская область",
  "Бейнеуский",
  "Каракиянский",
  "Мангистауский",
  "Мунайлинский",
  "Тупкараганский",
  "Павлодарская область",
  "Аккулинский",
  "Актогайский",
  "г. Аксу",
  "Баянаульский",
  "Железинский",
  "Иртышский",
  "Майский",
  "Павлодарский",
  "Теренкольский",
  "Щербактинский",
  "Успенский",
  "Экибастузкий",
  "Северо-Казахстанская область",
  "Айыртауский",
  "Аккаинский",
  "Акжарский",
  "г. Петропавловск",
  "Район Магжана Жумабаева",
  "Жамбылский",
  "Есильский",
  "Кызылжарский",
  "Мамлютский",
  "Мусреповский",
  "Тимирязевский",
  "Таиншинский",
  "Уалихановский",
  "Шалакынский",
  "Туркестанская область",
  "Арысский",
  "Байдибекский",
  "г.а. Шымкент",
  "г.а.Туркестан",
  "Казыгуртский",
  "Кентауский",
  "Мактаральский",
  "Ордабасинский",
  "Сайрамский",
  "Сарыагашский",
  "Сузакский",
  "Тюлькубасский",
  "Толебиский",
  "Отырарский",
  "Шардаринский",
  "Восточно-Казахстанская область",
  "Абайский",
  "Аягозский",
  "Бескарагайский",
  "Бородулихинский",
  "Глубоковский",
  "г. Риддер",
  "г. Оскемен",
  "г. Семей",
  "Катон-Карагайский",
  "Кокпектинский",
  "Курчумский",
  "Шемонаихинский",
  "Уланский",
  "Тарбагатайский",
  "Урджарский",
  "Зайсанский",
  "Зыряновский",
  "Жарминский",
  "Жамбылская область",
  "г. Тараз",
  "Жуалынский",
  "Байзакский",
  "Жамбылский",
  "Кордайский",
  "Меркенский",
  "Мойынкумский",
  "Рыскуловский",
  "Таласский",
  "Шуский",
  "Сарысуский",
  "Западно-Казахстанская область",
  "Акжаикский",
  "г. Уральск",
  "Бокейординский",
  "Бурлинский",
  "Жангалинский",
  "Жанибекский",
  "Зеленовский",
  "Чингирлауский",
  "Каратобинский",
  "Казталовский",
  "Сырымский",
  "Теректинский",
  "Таскалинский",
];
export default getRegionID;