export default function getSeasonDyn(regionCode) {
  switch (regionCode) {
    case "01": // Акмолинская
      return 40;
//    case "21": // Нур-Султан
//      return 32;
    case "02": // Актюбинская
      return 41;
    case "03": // Алматинская
      return 42;
//    case "20": // Алматы
//      return 34;
    case "04": // Атырауская
      return 43;
    case "09": // Карагандинская
      return 44;
    case "12": // Костанайская
      return 45;
    case "10": // Кызылординская
      return 46;
    case "13": // Мангистауская
      return 47;
    case "14": // Павлодарская
      return 48;
    case "19": // ЮКО
      return 49;
//    case "22": // Шымкент
//      return 41;
    case "15": // СКО
      return 50;
    case "05": // ВКО
      return 51;
    case "06": // Жамбыльская
      return 52;
    case "08": // ЗКО
      return 53;
    default:
      return 39;
  }
}
