import React, {useEffect, useState, useContext} from "react";
import styled from "styled-components";
import ClipLoader from "react-spinners/ClipLoader";
import { useTranslation } from "react-i18next";
import { OnTop } from "./ListService";
import { Scrollbars } from "react-custom-scrollbars";
import { checkPart } from "../../utils/RestApi";
import { NotificationContext } from "../../EknMap";

const Modal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  height: 450px;
  width: 1200px;
  background-color: #fff;
  border-radius: 10px;
  font-family: 'sf-pro-display-regular',sans-serif;
`;

const ModalHeader = styled.div`
  .header {
    display: flex;
    height: 50px;
    margin-bottom: 15px;
    border-radius: 5px 5px 0px 0px;
    background: linear-gradient(180deg, #01C13D 0%, #00A824 100%);
    align-items: center;
    // padding: 0px 80px 0px 20px;
    justify-content: center;
      
    .title {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 15px;
        color: #FFFFFF;
    }
  }
`;

const ModalBody = styled.div`
  padding: 10px;
  height: 75% !important;

  table {
      border: 1px solid #B6D4C0;
      border-spacing: 0;
      width: 100%;
      margin-bottom: 10px;

      thead {
          tr {
              width: 100%;
              height: auto;
              display: flex;
              margin: 0;
              padding: 0;
              background: #fff;

              td {
                  padding: 3px 8px;
                  border: 1px solid #B6D4C0;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  background: #E0F0E5;
                  color: #008A78;
              }
          }
      }

      tbody {
          tr {
              width: 100%;
              height: auto;
              display: flex;
              margin: 0;
              padding: 0;
              background: #fff;
              cursor: pointer;

              td {
                  padding: 3px 8px;
                  border: 1px solid #B6D4C0;
                  overflow: hidden;
                  position: relative;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  input {
                    cursor: pointer;
                  }
              }
          }
      }

      tbody tr:hover {
          background: #D8EFE0; /* Цвет фона при наведении */
      }

      .even {
          background: #F8FFFA;
      }
  }
  
  .block-loader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .block-panel {
    display: inline-block;
    position: relative;
    border: 0.5px solid #828282;
    border-radius: 5px;
    padding: 12px 5px 10px 5px;
    text-align: center;
    height: auto;
    width: 100%;
    margin: 5px 0px;

    .border-word {
      font-size: 12px;
      color: #828282;
      background-color: #fff;
      position: absolute;
      padding: 0px 10px;
      top: -9px;
      left: 12px;
    }
  }
`;

const ModalFooter = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin-top: 5px;

    button {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 10px 10px 0px;
        width: 93%;
        border-radius: 5px;
        color: #fff;
        height: 30px;
        border: none;
        outline: none;
        &:hover {
            cursor: pointer
        }
    }

    .success-btn {
        background: #00A824;
        width: 90px;
    }

    .cancel-btn {
        background: #777777;
        width: 90px;
    }
`;

const CheckPart = ({rnCode, cadNumData, setCadNumData, iinBin, condominium, setShowCheckPart}) => {
  const { t } = useTranslation();
  const [data, setData] = useState([])
  const [dataCopy, setDataCopy] = useState([])
  const [error, setError] = useState('');
  const [uniqueCadNums, setUniqueCadNums] = useState(0);
  const [checkboxes, setCheckboxes] = useState(Array(data.length).fill(false));
  const { setNotification } = useContext(NotificationContext);


  useEffect(() => {
    let data = [];
    cadNumData.forEach((cadnum) => {
      let part = {}
      part.isCondominium = condominium;
      if (!condominium) {
        part.iinBin = iinBin;
      }
      part.cadNum = cadnum.name;
      part.isPart = cadnum.part;
      data.push(part);
    })
    checkPart(rnCode, data)
      .then(response => {
        if (response['resultCode'] == 310) {
          if (response['parts'].length > 0) {
            const parts= response['parts'];
            let count = 0;
            const uniqueCadNums = [];
            const updatedParts = parts.map((obj, index, arr) => {
              const isDuplicate = arr.some((part, i) => part.cadNum === obj.cadNum && i !== index);
              if (!uniqueCadNums.includes(obj.cadNum)) {
                count += 1;
                uniqueCadNums.push(obj.cadNum);
              }
              return { ...obj, checked: !isDuplicate };
            });
            setUniqueCadNums(count);
            setData(updatedParts)
            setDataCopy(Object.assign([], updatedParts))
          } else {
            setError(t("notif.unknown-error"))
          }
        } else if (response['resultCode'] == 311) {
          setError(t("check-part.zu-search"))
        }
        
      })
  }, [])

  const handleCheckboxChange = (index) => {
    const updatedCheckboxes = [...checkboxes];
    updatedCheckboxes[index] = !updatedCheckboxes[index];
    setCheckboxes(updatedCheckboxes);
  };

  const onSave = () => { 
    const countChecked = dataCopy.filter(obj => obj.checked === true).length;
    if (countChecked!==uniqueCadNums) {
      setNotification({
        title: t("notif.attention"),
        text: t("check-part.count-cadnum")
      })
    } else {
      const updatedData = cadNumData.map(obj => {
        const foundObj = dataCopy.find(item => item.checked && item.cadNum.replace(/:/g, "") === obj.name.replace(/:/g, ""));
        if (foundObj) {
          return { ...obj, objId: foundObj.id, checked: true };
        } else {
          return { ...obj, checked: true };
        }
      });
      setCadNumData(updatedData);
      setShowCheckPart(false)
    }
    
  }

  const onContinue = () => {
    if (error!==t("notif.unknown-error")) {
      const updatedData = cadNumData.map(obj => {
        return { ...obj, checked: true };
      });
      setCadNumData(updatedData);
    } 
    setShowCheckPart(false)
  }

  return (
    <OnTop>
      <Modal>
        <ModalHeader>
          <div className="header">
            <div className='title'>{t("check-part.list")}</div>
          </div>
        </ModalHeader>

        <Scrollbars
                autoHeight={false}
                style={{height: "75%"}}
                renderView={props => <div {...props} className="scrollbar-view" />}
        >
          <ModalBody>
             
            {!error && data.length<1 ? <div className="block-loader"><ClipLoader color={"#02c23e"} loading={true} size={"50px"}/></div> :
              <div className='block-panel'>
                {error}
                {data && data.length>0  && (
                    
                  <table>
                      <thead>
                          <tr>
                              <td style={{ width: "10%" }}>ID</td>
                              <td style={{ width: "15%" }}>{t("info-tab.kad-num")}</td>
                              <td style={{ width: "10%" }}>{t("info-tab.part-num")}</td>
                              <td style={{ width: "15%" }}>{t("info-tab.part-type-id")}</td>
                              <td style={{ width: "20%" }}>{t("check-part.location-desc-rus")}</td>
                              <td style={{ width: "20%" }}>{t("check-part.location-desc-kaz")}</td>
                              <td style={{ width: "10%" }}>{t("check-part.select")}</td>
                          </tr>
                      </thead>
                      <tbody>
                      { data.map((item, i) => (
                          <tr key={i}>
                              <td style={{ width: "10%" }}>{item.id}</td>
                              <td style={{ width: "15%" }}>{item.cadNum}</td>
                              <td style={{ width: "10%" }}>{item.partNum}</td>
                              <td style={{ width: "15%" }}>{item.partType}</td>
                              <td style={{ width: "20%" }}>{item.targetTextRu}</td>
                              <td style={{ width: "20%" }}>{item.targetTextKz}</td>
                              <td style={{ width: "10%" }}>
                                <input 
                                  type="checkbox" 
                                  name="isPart" 
                                  id="isPart"
                                  checked={checkboxes[i] || item.checked}
                                  disabled={item.checked} 
                                  onChange={() => {
                                    handleCheckboxChange(i)
                                    const updatedData = JSON.parse(JSON.stringify(dataCopy));
                                    updatedData[i].checked = !updatedData[i].checked;
                                    setDataCopy(updatedData);
                                  }}
                                  value={checkboxes[i]}
                                />
                              </td>
                              
                          </tr>
                      )) }
                      </tbody>
                  </table>
                )}
              </div>
            }
            
          </ModalBody>
          
        </Scrollbars>

        <ModalFooter>

            {error && 
              <button
                className="success-btn"
                onClick={onContinue}
              >
                  {t("egov-service.continue")}
              </button>
            }

            {data && data.length>0  && (
              <>
                <button
                  className="success-btn"
                  onClick={onSave}
                >
                    {t("auf.save")}
                </button>
                  

                <button
                    className="cancel-btn"
                    onClick={() => setShowCheckPart(false)}
                >
                    {t("auf.cancel")}
                </button>
              </>
            )}
              
            
            
          </ModalFooter>
      </Modal>
    </OnTop>
  )

}

export default CheckPart;