import React from "react";
import searchSvg from "../../assets/img/mobile-search.svg";
import layersSvg from "../../assets/img/mobile-layers.svg";
import printerSvg from "../../assets/img/mobile-printer.svg";
import queueSvg from "../../assets/img/mobile-queue.svg";
import mapPinSvg from "../../assets/img/map-pin.svg";
import bellSvg from "../../assets/img/bell.svg";
import editSvg from "../../assets/img/edit.svg";
import logOutSvg from "../../assets/img/log-out.svg";
import userInfoSvg from "../../assets/img/userinfo.svg";
import { withTranslation } from 'react-i18next';
import i18n from "../../i18n";
import i18next from "i18next";

const closeBtnStyle = {
  height: "70px",
  alignSelf: "end"
}

class Burger extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { t, notifs, userInfo } = this.props;

    return (
      <div className="burger-menu">
        <div className="burger-head" style={!userInfo ? closeBtnStyle : {}}>
          {userInfo && userInfo.result === 0 &&
            <img src={userInfoSvg} alt="profile pic" />
          }
          {userInfo && <div className="burger-name">
            {userInfo.fullName}
          </div>}
          <div className="close-burger"
            onClick={() => this.props.setShowBurger(false)}
          >
            <div></div>
            <div></div>
          </div>
        </div>
        <div className="burger-menu-btns">
          <div className="burger-btn"
            onClick={() => {
              if (this.props.region && this.props.region.regionCode !== "00") {
                this.props.setOpenInfo(true);
              }
              this.props.setShowBurger(false);
            }}
          >
            <img src={searchSvg} alt="search" />
            <div className="burger-btn-text">{t("sidebar-links.1")}</div>
          </div>
          <div className="burger-btn"
            onClick={event => {
              this.props.setOpenInfo(false);
              this.props.setShowBurger(false);
              this.props.setOpenLayers(event, true);
            }}
          >
            <img src={layersSvg} alt="layers" />
            <div className="burger-btn-text">{t("sidebar-links.2")}</div>
          </div>
          <div className="burger-btn"
            onClick={() => {
              this.props.setOpenInfo(false);
              this.props.handleOpenRegionMob();
              this.props.setShowBurger(false);
            }}
          >
            <img src={mapPinSvg} alt="map pin" />
            <div className="burger-btn-text">{t("choose-region.1")}</div>
          </div>
          {userInfo && <div className="burger-btn"
            onClick={() => {
              this.props.setShowBurger(false);
              this.props.setShowOrders(true);
            }}
          >
            <img src={editSvg} alt="edit" />
            <div className="burger-btn-text">{i18n.t("order.title")}</div>
          </div>}
          {userInfo && <div className="burger-btn"
            onClick={() => {
              this.props.setShowBurger(false);
              this.props.setShowNotif(true);
            }}
          >
            {notifs.notReadCnt > 0 && <div className="notif-led-mob"></div>}
            <img src={bellSvg} alt="edit" />
            <div className="burger-btn-text">{i18n.t("profile.notif")}
              {notifs.notReadCnt > 0 && <span className="notif-count-mob">{notifs.notReadCnt}</span>}
            </div>
          </div>}
          <div className="burger-btn"
            onClick={event => {
              this.props.setOpenInfo(false);
              this.props.setShowBurger(false);
              this.props.setOpenLayers(event, false);
              this.props.setOpenRegionsMob(true);
            }}
          >
            <img src={queueSvg} alt="queue" />
            <div className="burger-btn-text">{t("sidebar-links.10")}</div>
          </div>
          {userInfo && <div className="burger-btn"
            onClick={event => {
              this.props.handleLogout();
              this.props.setShowBurger(false);
              event.preventDefault();
            }}
          >
            <img src={logOutSvg} alt="logout" />
            <div className="burger-btn-text">{t("profile.logout")}</div>
          </div>}
          {(!userInfo || userInfo.result !== 0) &&
            <a className="burger-btn"
              onClick={this.props.handleAuthorizationPressed}
              href={window.IDP_URL}
            >
              <img src={logOutSvg} alt="logout" />
              <div className="burger-btn-text">{t("header.auth")}</div>
            </a>
          }
        </div>
        <div className="burger-menu-langs">
          <div className="burger-lang-btn"
            style={{ background: i18n.language == "kz" ? "#01C240" : "#294A37" }}
            onClick={() => i18next.changeLanguage("kz")}
          >
            kz
          </div>
          <div className="burger-lang-btn"
            style={{ background: i18n.language == "ru" ? "#01C240" : "#294A37" }}
            onClick={() => i18next.changeLanguage("ru")}
          >
            ru
          </div>
          <div className="burger-lang-btn"
            style={{ background: i18n.language == "en" ? "#01C240" : "#294A37" }}
            onClick={() => i18next.changeLanguage("en")}
          >
            en
          </div>
        </div>
      </div>
    );
  }

}

export default withTranslation()(Burger);
