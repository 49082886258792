import React from "react";

export default function TableQueue({ queue, lang, i18n }) {
    return (
        <table>
            <thead>
                <tr>
                    <th width="7%">№ </th>
                    <th width="21%">{i18n.t("queue.fio")}</th>
                    <th width="10%">{i18n.t("queue.exist")}<br />{i18n.t("queue.iin")}</th>
                    <th width="13%">{i18n.t("queue.num")}</th>
                    <th width="10%">{i18n.t("queue.date")}<br />{i18n.t("queue.statement")}</th>
                    <th width="16%">{i18n.t("queue.status")}</th>
                    <th width="23%">{i18n.t("queue.region")}</th>
                </tr>
            </thead>
            <tbody>
                {queue.items.map((item, i) => (
                    <tr key={i} className={statusCheck(item)}>
                        <td width="7%">{item.num}</td>
                        <td width="21%">{item.name}</td>
                        <td width="10%">{item.iin ? i18n.t("queue.yes") : i18n.t("queue.no")}</td>
                        <td width="13%">{item.app_num}</td>
                        <td width="10%">{formatDate(item.app_date)}</td>
                        <td width="16%">{item.status}</td>
                        <td width="23%">{lang === 'kz' ? item.ate_name_kz : item.ate_name_ru}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export const statusCheck = (s) => {
    if (s.status_id === "5") {
        return "queue-err";
    } else if (s.status_id === "2" || s.status_id === "3") {
        return "queue-warn";
    } else {
        return "";
    }
};

export function formatDate(date) {
    if (date)
        return dateToLocalStr(dateFromStr(date));
}

function dateToLocalStr(date) {
    return ("0" + date.getDate()).slice(-2) + "." + ("0" + (date.getMonth() + 1)).slice(-2) + "." + date.getFullYear();
}

function dateFromStr(dateStr) {
    return new Date(Date.parse(dateStr));
}