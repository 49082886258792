import React, { useState, useContext } from "react";
import styled from "styled-components";
import svgRefresh from '../../assets/img/refresh.svg';
import { useTranslation } from "react-i18next";
import { formatTime } from "../../util";
import Cookies from "js-cookie";
import { NotificationContext } from "../../EknMap";
import { getTemplate } from "../../utils/RestApi";
import svgDownload from '../../assets/img/download.svg';

export const UserInfo = styled.div`
  position: absolute;
  top: ${props => props.responsive && props.responsive.mobile ? "62px" : "90px"};
  right: 30px;
  box-sizing: border-box;
  text-align: end;
  font-family: 'Open Sans', sans-serif;
  font-size : 12px;
  background: #fff;
  color : rgb(255, 255, 255);
  -moz-border-radius : 3px;
  -webkit-border-radius : 3px;
  width: ${props => props.hasQueues ? "600" : "383"}px;
  ${props => props.hasQueues ? "right: 15px;" : ""}
  padding: 12px;
  border-radius: 7px;
  box-shadow: 14px 14px 20px #00000036;
  overflow-y: auto;
  height: 85vh;

  ::-webkit-scrollbar {
    width: 5px;
  }

  h2 {
    color: #fff;
    padding-bottom: 6px;
    font-size: 14px;
    font-weight: normal;
    background: #009856;
    margin: 0;
    padding: 7px 12px;
    border-radius: 5px;
    text-align: start;
  }

  div {
    padding: 0 9px;
    height: 30px;
    padding-left: 12px;
    text-align: center;
    color: #26602D;
    display: flex;
    align-items: center;
    background: #F8FFFA;
    margin: 9px 0;
  }

  table {
    font-family: Roboto;
    font-size: 12px;
    color: #294A37;
    width: 100%;
    text-align: start;
    border-spacing: 0;
  }

  thead tr th {
    text-align: start;
  }

  th {
    padding: 6px 0 6px 12px;
    background: #DCF0E2;
    border: 1px solid #294A37;
  }

  td {
    padding: 6px 0 6px 12px;
    background: #F7FBF8;
    border: 1px solid #B4CFBC;
  }

  tbody tr {
    cursor: pointer;
  }

  tbody tr:hover td {
    background-color: #DCF0E2 !important;
    color: #294A37;
    font-weight: 600;
  }

  th:first-child {
    border-radius: 5px 0px 0px 0px;
  }

  th:last-child {
    border-radius: 0px 5px 0px 0px;
  }

  tr:last-child td:first-child {
    border-radius: 0px 0px 0px 5px;
  }

  tr:last-child td:last-child {
    border-radius: 0px 0px 5px 0px;
  }

  tbody tr:nth-child(odd) td {
    background: #FFF;
  }

  tbody tr:nth-child(even) td {
    background: #F7FBF8;
  }

  div p {
    margin-left: 4px;
    font-size: 14px;
  }

  div span {
    font-size: 14px;
    padding-left: 12px;
  }
  
  button {
    margin: 0 10px 10px 0px;
    width: 93%;
    border-radius: 4px;
    color: #fff;
    height: 30px;
    border: none;
    outline: none;
    background-color: #de2027;
          
    &:hover {
      cursor: pointer
    }
  }

  td button {
    width: 80%;
    background-color: #015f24;
    margin: -5px;
  }

  .prof-info {
    border: 1px solid #B6D4C0;
    border-radius: 5px;
    margin: 0;
    padding: 0;
    min-height: 30px;
    height: auto;
  }

  .prof-queue {
    display: block;
    height: auto;
    padding: 0;
    margin: 21px 0 0px 0px;
    background: #fff;

    .status {
      padding: 0;
      margin: 0;
      background: #fff;

      button {
        margin: 0;
        padding: 0px 0px 0 8px;
        width: 33px;
        background: #B6D4C0;

        div {
          height: 20px !important;
          padding: 0 !important;
        }
      }

      p {
        width: 100%;
        background: #B6D4C0;
        height: 30px;
        margin: 0px 9px 0 0;
        line-height: 30px;
        text-align: center;
        border-radius: 5px;
      }
    }
  }

  .prof-btn {
    width: auto;
    margin: 0;
    padding: 0;
    background: #009856;
    width: 130px;
    height: 26pxpx;
  }

  .logout-btn {
    background: #AE2A00;
  }

  .block-panel {
    display: inline-block;
    position: relative;
    border: 0.5px solid #828282;
    border-radius: 5px;
    padding: 12px 5px 10px 5px;
    text-align: center;
    height: auto;
    width: ${props => props.hasQueues ? "565px" : "auto"};
    // width: auto;

    .border-word {
      font-size: 12px;
      color: #828282;
      background-color: #fff;
      position: absolute;
      padding: 0px 10px;
      top: -9px;
      left: 12px;
      font-weight: 600;
    }
  }

  .registr-licensee-btn {
    margin: 0;
    padding: 0;
    background: #009856;
    height: 30px;
    width: 100%;
  }

  .licensee-btn {
    margin: 0;
    padding: 0;
    background: #2F80ED;
    height: 30px;
    width: 100%;
    margin-top: 5px;
  }

  .info-wrap {
    padding: 0;
    margin: 9px 0px 0px 0px;
    display: grid;
    grid-template-columns: ${props => props.hasQueues ? "1fr 1fr" : "1fr"};
    column-gap: 12px;
    row-gap: 12px;
    height: auto;

    .user-info {
      margin: 0;
      padding: 0;
      list-style-type: none;
      border-radius: 7px;
      border: 1px solid #828282;
      width: 98%;

      .user-info-item {
        display: flex;
        
        span {
          padding: 7px 10px;
          border-right: 1px solid #828282;
          display: flex;
          align-items: center;
          width: 108px;
        }
        p {
          margin: 0;
          padding: 7px 10px;
          text-align: left;
        }
      }
      .user-info-item:not(:last-child) {
        border-bottom: 1px solid #828282;
      }
    }
  }
  .info {
      display: flex;
      flex-direction: column;
      width: 100%;

      h2 {
        width: 98%;
      }
  }
  .info-title {
      color: #828282;
      font-size: 12px;
      margin: 0;
      text-align: left;
      padding-left: 7px;
  }

  .btns-wrap {
    justify-content: space-between;
    margin: 0;
    padding: 0;
    margin-top: 20px;
  }

  .no-queues {
    background: none;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #26602D;
  }

  .refresh-btn {
    margin: 0 8px 0 0;
    width: 30px;
    background: #a8cfb3;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .refresh-btn div {
    height: 16px !important;
    width: 12px !important;
    margin: 0!important;
    padding: 0 !important;
  }

  .refresh-btn.load div {
    -webkit-animation-name: spin;
    -webkit-animation-duration: 4000ms;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: spin;
    -moz-animation-duration: 4000ms;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: spin;
    -ms-animation-duration: 4000ms;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    
    animation-name: spin;
    animation-duration: 4000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @-ms-keyframes spin {
      from { -ms-transform: rotate(0deg); }
      to { -ms-transform: rotate(360deg); }
  }
  @-moz-keyframes spin {
      from { -moz-transform: rotate(0deg); }
      to { -moz-transform: rotate(360deg); }
  }
  @-webkit-keyframes spin {
      from { -webkit-transform: rotate(0deg); }
      to { -webkit-transform: rotate(360deg); }
  }
  @keyframes spin {
      from {
          transform:rotate(0deg);
      }
      to {
          transform:rotate(360deg);
      }
  }
  
  .service-btns {
    padding: 0;
    margin-top: 20px;
    flex-direction: column;
  }
  .service-wrap {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 15px;
  }
  .service-wrap-instruction {
    display: flex;
    flex-direction: column;
    height: auto;
    background-color: #009856;
    border-radius: 4px;
    margin: 0;

    button {
      width: 100%;
      background: none;
      margin: 0;
      padding: 0;
      border-radius: 0;
      padding: 5px;
      height: auto;
    }
    .instruction-btn {
      border-top: 1px solid #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
    }
  }
  .service-btns_item {
    height: 40px;
    margin: 0;
    width: 100%;
    background-color: #009856;
  }

  .datePicker {
    display: flex;
    width: 100%;
    align-self: stretch;
    justify-content: space-between;
    align-items: center;
    border: 0.5px solid #E7EAF3;
    border-radius: 4px;
    outline: none;
    padding: 8px 15px; 
  }

  .date {
    margin: 0;
    padding : 0 10px;
    height: auto;
  }
`;

export const Icon = styled.div`
    float: left !important;
    margin: 2px 7px 0 0 !important;
    width: 15px !important;
    height: 15px !important;
    background: transparent url('${props => props.url}') 0% 0% no-repeat padding-box !important;
    padding: 0 !important;
    padding-right: 9px !important;
`;

export default function UserProfile({
  userInfo, handleLogout, theme, handleUpdateQueue,
  handleOpenQueue, setQueueNum, setAte, handleClickProfile,
  setSearchQueueFromReg, setEgovService, setShowRegistrationLecensee, setShowWorkOrders, setShowPlanArchive, conf
}) {

  const { i18n, t } = useTranslation();
  const [load, setLoad] = React.useState(false);
  const hasQueues = !!userInfo.queues.length;
  const profileRef = React.useRef();
  const isLicensee = userInfo.roles && userInfo.roles.find(role => role.id === 1);
  const isRole2 = userInfo.roles && userInfo.roles.find(role => role.id === 2);
  const devMode = Cookies.get('mode') === 'dev';
  const { setNotification } = useContext(NotificationContext);
  const [selectedDate, setSelectedDate] = useState('');


  // React.useEffect(() => {
  //   function handleClickOutside(event) {
  //     if (profileRef.current && !profileRef.current.contains(event.target)) {
  //       handleClickProfile();
  //     }
  //   }
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [profileRef]);

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const downloadFile = () => {
    let dateToUse = selectedDate;
    const now = new Date();
    const timezoneOffset = now.getTimezoneOffset() * 60000; 
    const currentDate = (new Date(now - timezoneOffset)).toISOString().slice(0, 16);
  
    if (!dateToUse) {
      dateToUse = currentDate;
    } else {
      const inputDateTime = new Date(dateToUse);
      const currentDateTime = new Date(currentDate);
  
      if (isNaN(inputDateTime.getTime()) || inputDateTime.getFullYear() > 275759 || inputDateTime > currentDateTime) {
        setNotification({
          title: t("notif.attention"),
          text: t("notif.date")
        });
        return;
      }
    }
    
    let url = `${window.REST_URL}/cabinet/excel?datetime=${encodeURIComponent(dateToUse)}`;
    fetch(url, {
      method: "GET",
      mode: "cors",
      credentials: "include"
    })
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'statistics.xlsx');
      document.body.appendChild(link);
      link.click();
    })
    .catch(e => console.error(e));
  };

  return (
    <UserInfo ref={profileRef} palette={theme} hasQueues={hasQueues}>
      <h4 className="info-title">{t("user-profile.info")}</h4>
      <div className="info-wrap info" style={{marginTop: "5px"}}>
        <h2>{userInfo.fullName}</h2>
        <ul className="user-info">
          <li className="user-info-item">
            <span>ИИН:</span>
            <p>{userInfo.iin}</p>
          </li>

           {userInfo.email && userInfo.email !== "null" && (
            <li className="user-info-item">
              <span>Email:</span>
              <p>{userInfo.email}</p>
            </li>
           )}

           {userInfo.bin && userInfo.bin !== "null" && (
            <li className="user-info-item">
              <span>БИН:</span>
              <p>{userInfo.bin}</p>
            </li>
           )}

           {userInfo.orgNameRu && userInfo.orgNameRu !== "null" && (
            <li className="user-info-item">
              <span>{t("user-profile.organization")}</span>
              <p>{userInfo.orgNameRu}</p>
            </li>
           )}
          

        </ul>
      </div>
      <div className="info-wrap">
        <div className="block-panel">
          {(devMode || conf.showServices.includes(503100)) &&
            <div className="service-wrap service-wrap-instruction">

                <button
                  onClick={() => {
                    setEgovService({id: 503100});
                    setNotification({
                      title: t("notif.attention"),
                      text:  t("egov-service.service-notif")
                    })
                    handleClickProfile();
                    }}
                >
                  {t("egov-service.agreement")}
                </button>

                <button 
                  className="instruction-btn"
                  onClick={()=> getTemplate("instructions_sogl", "Согласование проектируемого земельного участка (инструкция)")}
                >
                  {t("help.download-instruction")}
                  <img src={svgDownload} alt="download" />
                </button>

              
            </div>
          }
          {(devMode || conf.showServices.includes(502100)) &&
            <div className="service-wrap">
              <button 
                className="service-btns_item"
                onClick={() => {
                  setEgovService({id: 502100});
                  setNotification({
                    title: t("notif.attention"),
                    text:  t("egov-service.service-notif")
                  })
                  handleClickProfile();
                }}>
                  {t("egov-service.service-cost")}
              </button>
            </div>
          }
          {(devMode || conf.showServices.includes(501150)) &&
            <div className="service-wrap">
              <button 
                className="service-btns_item"
                onClick={() => {
                  setEgovService({id: 501150});
                  setNotification({
                    title: t("notif.attention"),
                    text:  t("egov-service.service-notif")
                  })
                  handleClickProfile();
                }}>
                  {t("egov-service.service-quality")}
              </button>
            </div>
          }
            <span className="border-word">{t("user-profile.services")}</span>
        </div>
      </div>
      {isRole2 && 
        <div className="info-wrap">
          <div className="block-panel">
            <div className="date">
              {t("user-profile.choose-date")}
            </div>
            <div className="service-wrap"  style={{marginTop: "4px"}}>
              <input
                className="datePicker"
                type="datetime-local"
                id="datePicker"
                value={selectedDate}
                onChange={handleDateChange}
              />
            </div>
            <div className="service-wrap">
              <button
                className="service-btns_item"
                onClick={downloadFile}
              >
                {t("user-profile.download_btn")}
              </button>
            </div>
            <span className="border-word">{t("user-profile.statistics")}</span>
          </div>
        </div>
      }

      <div className="info-wrap">
        <div className="block-panel">
              <div className="service-wrap">
                <button
                  className="service-btns_item"
                  onClick={() => setShowPlanArchive(true)}
                >
                  {t("user-profile.plan-archive")}
                </button>
              </div>
          <span className="border-word">{t("user-profile.plan-archive")}</span>
        </div>
      </div>

      {(devMode || conf.showLicensee) && (
        <div className="info-wrap">
          <div className="block-panel">
            {!isLicensee && (
            <button
                className="registr-licensee-btn"
                href=""
                onClick={() => {
                  setShowRegistrationLecensee(true);
                }}
              >
              {t("zkr.registration")}
            </button>
            )}
            {isLicensee && (
              <button
                  className="licensee-btn"
                  href=""
                  onClick={() => {
                    setShowRegistrationLecensee(true);
                  }}
                >
                {t("zkr.edit")}
              </button>
            )}
            {isLicensee && (
              <button
                  className="licensee-btn"
                  href=""
                  onClick={() => {
                    setShowWorkOrders(true);
                  }}
                >
                {t("zkr.orders")}
              </button>
            )}

            <span className="border-word">{t("zkr.profile")}</span>
          </div>
        </div>
      )}

      <div className="info-wrap">
        <div className="block-panel">
          {hasQueues ?
            <table>
              <thead>
                <tr>
                  <th>{t("user-profile.queue")}</th>
                  <th>{t("user-profile.request")}</th>
                  <th>{t("user-profile.date")}</th>
                  <th>{t("user-profile.status")}</th>
                  <th>{t("user-profile.region")}</th>
                </tr>
              </thead>
              <tbody>
                {userInfo.queues.map((item, i) =>
                  <tr key={i} onClick={() => {
                    setAte(item.ate);
                    setQueueNum(item.num);
                    setSearchQueueFromReg(false);
                    handleClickProfile();
                    handleOpenQueue();
                  }}>
                    <td>{item.num}</td>
                    <td>{item.app_num}</td>
                    <td>{item.app_date}</td>
                    <td>{item.status}</td>
                    <td>{item.region}</td>
                  </tr>
                )}
              </tbody>
            </table>
            :
            <div className="no-queues">{t("user-profile.stand-line")}</div>
          }
          
          <span className="border-word">{t("user-profile.queues")}</span>
        </div>
      </div>

      <div style={{ textAlign: "start" }}>
        {t("user-profile.date-prev")} {
          userInfo.lastVisitDate
            ? formatTime(new Date(userInfo.lastVisitDate))
            : t("user-profile.first-visit")
        }
        <br />
        {t("user-profile.date-last")} {
          userInfo.lastInvalidLoginDate
            ? formatTime(new Date(userInfo.lastInvalidLoginDate))
            : t("user-profile.attempt")
        }
      </div>

      

      <div className="btns-wrap">

        <p></p>

        {/*<button
          className="prof-btn"
          onClick={() => {
            setShowMyObjects(true);
          }}
        >
          Мои объекты
        </button>*/}


      

        <div>

          {hasQueues ?
            <button
              className={"refresh-btn" + (load ? " load" : "")}
              href=""
              onClick={() => {
                setLoad(true);
                handleUpdateQueue(i18n.language)
                  .then(res => setLoad(false))
                  .catch(e => {
                    console.err(e);
                    setLoad(false);
                  });
              }}
            >
              <Icon url={svgRefresh} />
            </button>
            : null
          }

          <button
            className="prof-btn logout-btn"
            href=""
            onClick={event => {
              handleLogout();
              event.preventDefault();
            }}
          >
            {t("user-profile.exit")}
          </button>

          

        </div>

      </div>
      
      
    </UserInfo>
  );
}
