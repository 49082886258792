import axios from 'axios';



let districtData;
let atoData;
let categoryData;
let jsondata;
let cause;

export function download(blob, filename) {
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  // the filename you want
  if (filename){
    a.download = filename;
  }
  
  document.body.appendChild(a);

  a.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window
    })
  );
  document.body.removeChild(a);
}

export function getCause(lang) {
  if (cause) {
    return new Promise((resolve, reject) => resolve(cause));
  }
  return fetch(`${window.REST_URL}/cabinet/cause?lang=${lang}`).then(response => response.json());
}

export function getDistricts(lang) {
  if (districtData) {
    return new Promise((resolve, reject) => resolve(districtData));
  }
  return fetch(`${window.REST_URL}/map/districts?lang=${lang}`).then(response => response.json());
}

export async function getAte(lang, districtId) {
  if (atoData) {
    return new Promise((resolve, reject) => resolve(atoData));
  }
  const response = await fetch(`${window.REST_URL}/map/ate?lang=${lang}&districtId=${districtId}`);
  return await response.json();
}

export async function getSo(lang, districtId) {
  const response = await fetch(`${window.REST_URL}/map/so?lang=${lang}&districtId=${districtId}`);
  return await response.json();
}

export function getCategory(lang) {
  if (categoryData) {
    return new Promise((resolve, reject) => resolve(categoryData));
  }
  return fetch(`${window.REST_URL}/map/category?lang=${lang}`).then(response => response.json());
}

export function postJson(jsondata) {

  return new Promise((resolve,reject) => {
    axios({
      url: `${window.REST_URL}/jsonupload`,
      headers: {
        'Content-Type': 'application/json'
      },
      method: "post",
      data: jsondata
    })
    .then(response => resolve(response.data))
      .catch(e => reject(e))
  })
}

export async function getServiceXml(service) {

  const response = await fetch(`${window.REST_URL}/cabinet/egovservice/xml`, {
  method: "POST",
  mode: "cors",
  credentials: "include",
  headers: {
    "Content-Type": "application/json",
  },
  body: JSON.stringify(service),
});
return response;
}

export async function postService(service) {

    const response = await fetch(`${window.REST_URL}/cabinet/egovservice`, {
    method: "POST",
    mode: "cors",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(service),
  })
  // .then(response => response.blob())
  // .then(blob => download(blob, "Схема испрашиваемого земельного участка.pdf"))  
  return response;
}

export function getSvg(plan) {

  return new Promise((resolve, reject) => {
    fetch(`${window.REST_URL}/map/plan`, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(plan),
    })
    .then(response => resolve(response.body))
      .catch(e => reject(e))
  });
  
}

export function documentCreate(service) {

  return new Promise((resolve, reject) => {
    fetch(`${window.REST_URL}/cabinet/egovservice/create`, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(service),
    })
    .then(response => response.blob())
    .then(blob => download(blob, "Схема испрашиваемого земельного участка.pdf"))
  });
  
}

export function getTemplate(ext, fileName = "Шаблон") {
  return fetch(`${window.REST_URL}/cabinet/egovservice/template?ext=${ext}`, {
    method: "GET",
    mode: "cors",
    credentials: "include",
  })
  .then(response => response.blob())
  .then(blob => download(blob, fileName))  
}


export function checkFl(iin) {
  return fetch(`${window.REST_URL}/cabinet/checkFl?iin=${iin}`).then(response => response.json());
}

export function checkUl(bin) {
  return fetch(`${window.REST_URL}/cabinet/checkUl?bin=${bin}`).then(response => response.json());
}

export function getDepartment(rnCode) {
  return fetch(`${window.REST_URL}/cabinet/department?rnCode=${rnCode}`).then(response => response.json());
}

export function checkPart(rnCode, data) {

  return new Promise((resolve, reject) => {
    fetch(`${window.REST_URL}/cabinet/checkPart?rnCode=${rnCode}`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(e => reject(e))
  })
}
