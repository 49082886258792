import React, { useRef } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

export const ListItem = styled.li`
    .list-input {
        background: #f3f3f7;
        background: rgba(243, 243, 247, 1);
        border-style: Solid;
        border-color: #c9d1de;
        border-color: rgba(201, 209, 222, 1);
        border-width: 1px;
        border-right: none;
        height: 33px;
        padding: 0 0 0 15px;
        font-family: "Open Sans", sans-serif;
        font-size: 15px;
        color: #989898;
        color: rgb(152, 152, 152);
        flex: auto;
        border-left: none;
        padding-right: 15px;
        max-width: 526px;

    }
    .list-input:last-child {
        border-radius: 0 0 6px 6px;
    }
    .list-btn {
        color: #e5383b;
        background: #ffffff;
        background: rgba(255, 255, 255, 1);
        border-style: Solid;
        border-color: #c9d1de;
        border-color: rgba(201, 209, 222, 1);
        border-width: 1px;
        width: 35px;
        height: 33px;
        margin-left: -6px;
        outline: none;
        border-right: none
    }
    .part {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        background: #f3f3f7;
        height: 33px;
        border: 1px solid rgba(201,209,222,1);
        padding: 0 10px;
        color: rgb(152,152,152);
        font-weight: bold;
    }

`
const KadNum = (props) => {
    const { t } = useTranslation();
    let cadNumData = props.useInput('', {isEmpty: true, minLength: 11, maxLength: 12})
    const style = props.causeName === t("egov-service.land-consolidation") ? {display: 'block'} : {display: 'none'};
    // const styleMr = props.causeName === t("egov-service.land-consolidation") ? {marginRight: '7px'} : {marginRight: '0px'};
    
    const checkboxRef = useRef();

    const handleCadNumChange = (e) => {
        cadNumData.onChange(e);
        props.updateKadNum(props.num, e.target.value, checkboxRef.current.checked);
    }

    const handleCheckboxChange = () => {
        props.updateKadNum(props.num, cadNumData.value, checkboxRef.current.checked);
    }

    return (
        <ListItem>
            {(cadNumData.isDirty && cadNumData.error) && <div style={{color: 'red'}}>{cadNumData.errorName}</div>}
            
            <div className="fld-region">
                <input
                    // style={styleMr}
                    type="number"
                    className="list-input"
                    placeholder={t("egov-service.cadnum")}
                    value={props.name ? props.name : cadNumData.value}
                    onChange={handleCadNumChange}
                    onBlur={(e) => cadNumData.onBlur(e)}
                    onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                />

                <div className="part">
                    <label htmlFor={`ispart-${props.num}`}>{t("check-part.part")}</label>
                    <input 
                        ref={checkboxRef} 
                        type="checkbox" 
                        name="ispart" 
                        id={`ispart-${props.num}`}
                        onChange={handleCheckboxChange} 
                    />
                </div>
   
                <button
                    style={style}
                    type="submit"
                    className="list-btn"
                    onClick={props.onDeleteKadNum}
                >
                <i className="fas fa-trash"></i>
             </button>
            </div>
            
        </ListItem>
    )

}

export default KadNum;