import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Draw, { createRegularPolygon, createBox } from "ol/interaction/Draw";
import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style.js";
import { useTranslation } from "react-i18next";

const SelectWrapper = styled.div`
  select {
    width: 260px;
    height: 36px;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: #fff;
    border-radius: 5px 5px 0px 0px;
    background: -webkit-linear-gradient(
      -90deg,
      rgba(1, 97, 31, 1) 0%,
      rgba(0, 90, 47, 1) 100%
    );
  }

  .select-shapes option {
    color: #005a2f;
    height: 50px;
    padding: 10px;
  }
  .select-shapes option:nth-child(1) {
    background: #d3e8da;
    height: 50px;
    padding: 10px;
    width: 100px;
  }

`;

const Select = styled.select`
  outline: none;
  border: none;
  padding-left: 25px;
`;

export default function Shapes(props) {
  const [shape, setShape] = useState("MultiPoint");
  const { t } = useTranslation();

  useEffect(() => {
    if (props.open) {
      if (shape) {
        addInteraction(props.map, shape);
        return () => {
          props.map.removeInteraction(draw);
          props.map.removeLayer(vector);
        };
      }
    }
  });

  return (
    <div>
      {props.open && (
        <SelectWrapper>
          <Select
            className="select-shapes"
            style={{
              boxSizing: "border-box",
              zIndex: 1,
              position: "absolute",
              top: "410px",
              left: props.show ? "300px" : "120px",
            }}
            palete={props.theme}
            defaultValue={shape}
            onChange={e => setShape(e.target.value)}
          >
            <option value="MultiPoint">{t("shape.point")}</option>
            <option value="LineString">{t("shape.line")}</option>
            <option value="Circle" defaultValue>
            {t("shape.circle")}
            </option>
            <option value="Square">{t("shape.square")}</option>
            <option value="Rectangle">{t("shape.Прямоугольник")}</option>
            <option value="Polygon">{t("shape.Многоугольник")}</option>
          </Select>
        </SelectWrapper>
      )}
    </div>
  );
}

var draw;
var vector;
function addInteraction(map, shape) {
  if (shape !== "None") {
    const source = new VectorSource();
    vector = new VectorLayer({
      source: source,
      style: new Style({
        fill: new Fill({
          color: "rgba(255, 255, 255, 0.5)",
        }),
        stroke: new Stroke({
          color: "orangered",
          width: 2,
        }),
        image: new CircleStyle({
          radius: 3,
          fill: new Fill({
            color: "orangered",
          }),
        }),
      }),
    });
    map.addLayer(vector);
    let geometryFunction = null;
    if (shape === "Square") {
      shape = "Circle";
      geometryFunction = createRegularPolygon(4);
    } else if (shape === "Rectangle") {
      shape = "Circle";
      geometryFunction = createBox();
    }
    draw = new Draw({
      source: source,
      type: shape,
      geometryFunction: geometryFunction,
    });
    map.addInteraction(draw);
  }
}
