import React from 'react'
import styled from "styled-components";
import { ListOrder } from './Orders';
import PageNav from "../PageNav";
import { useTranslation } from "react-i18next";


export const ListNotif = styled.div`

    position: absolute;
    right: ${props => props.responsive.mobile ? "0" : "180px"};
    width: ${props => props.responsive.mobile ? "100%" : "540px"};
    height: ${props => props.responsive.mobile ? "100%" : "auto"};
    padding: 6px 6px 0px 6px;
    min-height: initial;
    border-radius: 7px;
    min-width: initial;
    max-width: initial;
    z-index: 4;
    top: 90px;
    box-sizing: border-box;
    background: rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    overflow: hidden;

    font-size: 14px;
    box-shadow: rgb(0 0 0 / 21%) 14px 14px 20px;

    h2 {
        float: none;
        width: 97.5%;
        text-align: start;
        padding: 7px 0px;
        padding-left: 11px;
        background: rgb(0, 152, 86);
        color: rgb(255, 255, 255);
        border-radius: 7px;
        font-size: 14px;
        margin: 0px 6px 6px 0px;
        font-weight: normal;
        border: 1px solid rgb(182, 212, 192);
        font-family: sf-pro-display-regular, sans-serif;
    }

    .not-read {
        font-weight: bold;
    }

    .header {
        padding: 0;

        h2 {
            width: 100%;
            text-align: center;
        }
    }

    .order-table {
        margin: 0;
        min-height: 100px;
        max-height: ${props => props.responsive.mobile ? "initial" : "352px"};
        height: auto;
        background: rgb(255, 255, 255);
        padding: 0px;
        overflow-y: auto;
        display: block;
        position: relative;
        text-align: center;
        color: rgb(38, 96, 45);

        table {
          border: 1px solid rgb(182, 212, 192);
          border-spacing: 0px;
          margin: 5px 0px 5px 6px;
          width: 98%;
          padding: 0px;
          background: rgb(255, 255, 255);
          font-family: Roboto;
          font-size: 12px;
          color: rgb(41, 74, 55);
          text-align: start;

            tr {
              width: 100%;
              height: auto;
              display: flex;
              margin: 0px;
              padding: 0px;
              background: rgb(255, 255, 255);
              cursor: pointer;
              color: rgb(32, 64, 36);
              border-radius: 5px;
              font-size: 14px;

                td {
                    padding: 6px 8px;
                    border: 1px solid rgb(182, 212, 192);

                    p {
                        overflow: auto;
                        line-height: 20px;
                        margin: 0px;
                        padding: 0px;
                        background: none;
                        color: rgb(6, 60, 12);
                        border-radius: 5px;
                        font-size: 14px;
                    }
                }
            }

            .even {
                background: #F8FFFA;
            }
        }
    }
    .queue-pagenav {
      width: initial;
      max-width: initial;
      padding-left: 8px;
      border: 1px solid rgb(182, 212, 192);
      border-radius: 7px !important;
      background-color: rgb(0, 152, 86) !important;
      padding: 0px 9px 0px 12px;
      height: 30px;
      text-align: center;
      color: rgb(38, 96, 45);
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      background: rgb(248, 255, 250);
      margin: 9px 0px;
      justify-content: center;

      p {
        margin-left: 4px;
        font-size: 14px;
        margin: 0px 6px;
        display: inline-block;
        color: rgb(255, 255, 255);
      }
      button {
        display: flex;
        justify-content: center;
        border-radius: 5px;
        margin-top: 0px;
        background: rgb(8, 68, 42) !important;
        height: 22px !important;
        width: 22px !important;
        padding: 0px !important;
        margin-left: 6px;
        text-align: left;
        font-size: 9pt;
        border: none;
        color: rgb(255, 255, 255);
        outline: none;
      }

    
    }
`;

export default function Orders({
  notifs, handleGetNotifs, handleUpdateNotif,
  setOrder, handleSelectOrder, handleClickOrder,
  responsive, setNotifPage, orderLoaded, palette
}) {

  const { t } = useTranslation();

  React.useEffect(() => {
    setNotifPage(0);
    handleGetNotifs(0, 10);
  }, []);

  const getNotifs = {
    func: (pageNum) => {
      handleGetNotifs(pageNum, 10);
      setNotifPage(pageNum);
    }
  };

  const listNotif = () => {
    return (
      notifs && notifs.notifs.map((item, i) => (
        <tr key={i} className={(!item.timeRead ? "not-read" : "") + (i % 2 === 1 ? " even" : "")} onClick={() => {
          if (!item.timeRead)
            handleUpdateNotif(item.timeCreated);
          if (!orderLoaded) {
            setOrder({ services: [], objects: [], providers: [], docs: [], orderLoading: true });
            handleSelectOrder(item.appId);
            handleClickOrder(true);
          }
        }}> 
          <td style={{ width: "20%" }}><p>{t("payment-service.order") + item.appId}</p></td>
          <td style={{ width: "55%" }}><p>{item.text}</p></td>
          <td style={{ width: "25%" }}><p>{item.timeCreated.substring(0, 16)}</p></td>
        </tr>
      ))
    )
  };

  return (
    <ListNotif responsive={responsive}>
      <h2>
      {t("profile.notif")}
      </h2>
      <div className="order-table">
        <table>
          <tbody>
            {listNotif()}
          </tbody>
        </table>
      </div>
      {notifs.count > 10 && <PageNav
        className="queue-pagenav"
        count={notifs.count}
        getObjects={getNotifs}
        theme={palette}
        updateObject={handleGetNotifs}
      />}
    </ListNotif>
  );
}