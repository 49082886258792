import React, { useContext } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import comboImg from "../../../assets/img/combo.svg";
import { RegionContext } from "../../../EknMap";
import i18n from "../../../i18n";
import ChooseRegion from "../../ChooseRegion";
import ChooseAto from "../../ChooseAto";
import { useEffect} from "react";
import { getAte, getSo, getCategory, postService, getCause, documentCreate, checkUl, checkFl, getDepartment, getServiceXml, download} from "../../../utils/RestApi";
import Coordinates from "../Coordinates";
import { WKT, GeoJSON } from "ol/format";
import KadNum from "../KadNum";
import { signText } from "../../../NCALayerOps";
import CheckPart from "../CheckPart";
import { NotificationContext } from "../../../EknMap";
import { Feature } from "ol";
import { MODE_SELECT_PDP, MODE_SELECT } from "../../../EknMap";




const EgovServiceBtn = styled.button`
  border: none;
  outline: none;
  background-color: ${props => props.color ? props.color : "#008B78"};
  width: 100%;
  margin-top: 12px;
  border-radius: 6px;
  font-size: 14px;
  color: #fff;
  padding: 10px;

  &:hover {
    cursor: pointer;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const ModalInfo = styled.div`
  .area_inform_content {
    background-color: #fff;
    width: 500px;
    height: auto;
    padding-bottom: 10px;
  }
  h3 {
    text-align: left; 
    padding: 10px 5px 5px 10px;
    background: #009856;
    color: #fff;
    font-size: 16px;
  }
  p {
    padding: 5px;
    padding-left: 10px
  }
  .area-confirm {
    display: flex;
    justify-content: end
  }
  .area-confirm-land {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }
  .doc-num {
    margin: 0
  }
  .area-confirm_doc-num_wrap {
    margin-right: 25px
  }
  .area-confirm_btn {
    border: none;
    outline: none;
    background-color: #008B78;
    width: 50px;
    border-radius: 6px;
    font-size: 12px;
    color: #fff;
    padding: 5px;
    margin-right: 5px;

    &:hover {
    cursor: pointer;
    }
  }
  .area-confirm_btn.error {
    width: 80px;
  }
`
const CheckBoxContainer = styled.div`
  font-size: 14px;
  font-weight: normal;
  background: #009856;
  color: #fff;
  padding: 7px 15px;
  height: 33px;
  margin-top: 12px;
  margin-bottim: 12px;
  position: relative;
  display: flex;
  align-items: center;


  label {
    color: #fff;
    display: flex;
    align-items: center;
  }
  input {
    border: 0.05em solid white;
    border-radius: 0.15em;
    cursor: pointer;
    opacity: 0;
    position: absolute;
    height: 1.5em;
    width: 1.5em;
  }
  input:checked {
    &+label::before {
      content: "\\002714";
      display: flex;
      justify-content:center;
      align-items: center;
    }
  }
  label::before {
    content: '';
    border: 0.05em solid #fff;
    height: 1.45em;
    width: 1.45em;
    border-radius: 0.15em;
    margin-right: 0.5em;
    background-color: #009856;
    margin-left: 4px;
  }
`
export const EgovServiceWidget = styled.div`
    min-width: initial;
    max-width: initial;
    position: ${props => props.coordinatesActive || props.informAreaActive || props.bdError || props.bdSuccess || props.donwloadConfirmActive || props.partActive ? "static" : "absolute"};
    width: ${props => props.responsive.mobile ? "100%" : "530px"};
    max-height: 85vh;
	  top: ${props => props.responsive.mobile ? "62px" : "90px"};
    right: 30px;
    background-color: #d1e8d9;
    padding: 6px 12px;
    border-radius: 7px;
    font-family: 'sf-pro-display-regular',sans-serif;
    font-size: 14px;
    box-shadow: 14px 14px 20px #00000036;
    z-index: 4;
    overflow-y: auto;
    
    .fld-region {
      margin-right: 0px;
    }
    .input-wsub {
      border-radius: 0 0 6px 6px;
      border-left: none;
      padding-right: 15px;
      max-width: 526px;
    }
    .input-sub {
      border-radius: 0 0 6px 6px;
      border-bottom-right-radius: 0;
      max-width: 526px;
      outline:none;
      cursor: pointer;
    }
    input:focus {
      outline: none;
    }
    .header-combo-btn {
      border-radius: 0 0 6px 0;
      border-right: none;
    }
    .header-combo-btn-ct {
      height: 75px;
    }
    h2 {
      font-size: 14px;
      margin-bottom: 0px;
      font-weight: normal;
      background: #009856;
      color: #fff;
      padding: 7px 0px;
      text-align: center;
    }
    .area_inform {
      position absolute;
      top: 0;
      left 0;
      width: 100%;
      height: 100%;
      transform: scale(0);
      -webkit-backdrop-filter: blur(3px);
      backdrop-filter: blur(3px);
      background-color: rgb(0 0 0 / 49%);
      z-index: 15;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .area_inform.active {
      transform: scale(1);
    }
    .kadnum-list {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }
    .list-wrapper {
      position: relative
    }
    .add-list-btn {
      position: absolute;
      top: 5px;
      color: #fff;
      background: #009856;
      border: none;
      left: 10px;
    }
    .service-title {
      font-size: 16px;
      padding: 7px;
    }
    textarea {
      outline: none;
      resize: none;
      font-size: 15px;
      padding-top: 10px;
      padding-bottom: 10px;
      height: 95px;
    }
    .input-sub-ct {
      height: 75px;
    }
    .fid-ta {
      margin-bottom: 75px;
    }
    .fid-ct {
      margin-bottom: 55px;
    }
    .fa-check {
      color: #009856;
      font-size: 25px;
    }
    .fa-xmark {
      color: red;
      font-size: 25px;
    }
    .iin-valid {
      position: absolute;
      right: 10px;
      top: 3px;
    }
    .iin-loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .name-loader {
      position: absolute;
      top: 25px;
      left: 50%;
      transform: translateX(-50%);
    }
    .sign-loading {
      margin-top: 5px;
      text-align: center;
    }
    .segments {
      border: 1px solid rgb(193, 39, 45);
      padding: 0 10px 10px 10px;
    }
    .segment-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;

      .segment-btn {
        background: linear-gradient( 180deg, rgba(247, 147, 30, 1) 0%, rgba(241, 90, 36, 1) 100% );
        border: none;
        border-radius: 5px;
        padding: 3px 10px;
        outline: none;
        color: #fff;
      }
    }
    .segment-btn-all {
      width: 100%;
      background: linear-gradient(rgb(2, 194, 62) 0%, rgb(0, 168, 36) 100%);
      border: none;
      outline: none;
      padding: 5px;
      color: #fff;
    }
    .condominium-wrapper {
      border-radius: 0 0 6px 6px;
      overflow: hidden;

      .condominium-title {
        background: none;
        color: #989898;
        margin: 0;
        padding: 0;
        margin-top: 3px;
        margin-right: 15px;
        font-weight: normal;
        font-size: 15px;
        width: 170px;
      }
      .condominium {
        background: #fff;
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 0 15px;

        label {
          cursor: pointer;
          color: #989898;
          font-size: 15px;
          margin-top: 3px;
        }

        .condominium-item[type="radio"] {
          appearance: none;
          position: relative;
          outline: none;
          width: 19px;
          height: 19px;
          margin-right: 5px;
          border-radius: 50%;
          cursor: pointer;
        }
        .condominium-item[type="radio"]::before {
          content: "";
          box-sizing: border-box;
          width: 20px;
          height: 20px;
          position: absolute;
          background-color: white;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          border: 1.5px solid #009856;
        }
        
        .condominium-item[type="radio"]:checked:after {
          content: "";
          box-sizing: border-box;
          width: 10px;
          height: 10px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          background-color: #009856;
        }
      }
      .condominium-iin {
        display: flex;
        align-items: center;
        background: #fff;
        padding: 0 15px;
        border-top: 1px solid #989898;
        height: 33px;
        position: relative;

        input {
          height: 100%;
          border: 0;
          background: #fff;
        }
        input::placeholder {
          font-size: 14px;
        }
      }
    }
    
`;

const useInput = (initialValue, validations, parameter, publicService, setPublicService) => {
  const[value, setValue] = React.useState(initialValue);
  const[isDirty, setDirty] = React.useState(false);
  const valid = useValidation(value, validations, parameter, publicService, setPublicService);
  const { setNotification } = useContext(NotificationContext);
  const { t } = useTranslation();

  const onChange = (e, publicService, setPublicService) => {
    const value = e.target.value;
      if (publicService) {
        const selected = new Date(value);
        if (selected > new Date()) {
          setNotification({
            title:  t("notif.attention"),
            text: t("notif.date")
        })
          return;
        } else {
          publicService.date = selected;
          setPublicService(Object.assign({}, publicService));
        }
      } 
      setValue(value);
  }

  const onChangeName = (name) => {
    setValue(name);
  }

  const onBlur = () => {
    setDirty(true);
  }

  const resetValues = () => {
    setValue('');
    setDirty(false);
  }

  return {
    value,
    onChange,
    onChangeName,
    onBlur,
    resetValues,
    isDirty,
    ...valid
  }
}
const useValidation = (value, validations, parameter, publicService, setPublicService) => {
  const { t } = useTranslation();
  const[inputValid, setInputValid] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [errorName, setErrorName] = React.useState('');
  const[valid, setValid] = React.useState(false);

  useEffect(() => {
    for (const validation in validations) {
      if (validation === 'isEmpty') {
        if (!value) {
          setError(true);
          setErrorName(t("egov-service.empty-error"))
          break;
        } else {
          setError(false);
          continue;
        }
      } else if (validation === 'minLength') {
        if (value.length < validations[validation]) {
          setError(true);
          setErrorName(t("egov-service.min-length-error") + ` - ${validations[validation]}` );
          break;
        } else {
          continue;
        }
      } else if (validation === 'maxLength') {
        if (value.length > validations[validation]) {
          setError(true);
          setErrorName(t("egov-service.max-length-error") + ` - ${validations[validation]}` );
          break;
        } else {
          continue;
        }
      } else if (validation === 'valid') {
        if (value.length===12) {
          setValid(false);
          setError(false);
          setErrorName('');
          if (value.substring(4, 5)==0 || value.substring(4, 5)==1 || value.substring(4, 5)==2 || value.substring(4, 5)==3) {
            checkFl(value).then(result => {
              if (result.resultCode==='0') {
                setValid(true);
              } else {
                setValid(false);
                setError(true);
                setErrorName(result.resultMessage);
              }
            });
          } else {
            checkUl(value).then(result => {
              if (result.resultCode==='1') {
                setValid(true);
                if (result.organization) {
                  publicService.fio=result.organization;
                    setPublicService(Object.assign({}, publicService));
                  parameter.onChangeName(result.organization);
                }
              } else {
                setValid(false);
                setError(true);
                setErrorName(result.resultMessage);
              }
            });
          
          }
        } 
      }
    }
  }, [value])

  useEffect(() => {
    if (error) {
      setInputValid(false)
    } else {
      setInputValid(true)
    }
  }, [error])
  return {
    inputValid,
    valid,
    error,
    errorName
  }
}

export function EgovService({
  service, 
  regions, 
  openRegionMaxHeight, 
  responsive, 
  setEgovService, 
  fileFormatNote, 
  checkFileFormat, 
  handleSelect,
  setMode, 
  features, 
  setFeatures, 
  errors, 
  setErrors, 
  layers,
  map,
  handleLoadFeature,
  serviceId
  }) {
  const { t } = useTranslation();
  const [publicService, setPublicService] = React.useState({iinLandUser: '', iin: '', fio: '', date: '', purposeRu: '', purposeKz: '', locationRu: '', locationKz: ''});
  const { region, setRegion } = useContext(RegionContext);
  const [regionCheck, setRegionCheck] = React.useState(false);
  const [openRegion, setOpenRegion] = React.useState(false);
  const [openAte, setOpenAte] = React.useState(false);
  const [openSo, setOpenSo] = React.useState(false);
  const [ate, setAte] = React.useState([]);
  const [so, setSo] = React.useState([]);
  const [openCategory, setOpenCategory] = React.useState(false);
  const [category, setCategory] = React.useState([]);
  const [disable, setDisable] = React.useState(false);
  const [coordinatesActive, setCoordinatesActive] = React.useState(false);
  const [check, setCheck] = React.useState(false);
  const [informAreaActive, setInformAreaActive] = React.useState(false);
  const [donwloadConfirmActive, setDonwloadConfirmActive] = React.useState(false);
  const [bdError, setBdError] = React.useState(false);
  const [bdSuccess, setBdSuccess] = React.useState(false);
  const [causeName, setCauseName] = React.useState('');
  const [causeId, setCauseId] = React.useState('');
  const [openCause, setOpenCause] = React.useState(false);
  const [ateName, setAteName] = React.useState('');
  const [soName, setSoName] = React.useState('');
  const [categoryChoose, setCategoryChoose] = React.useState('');
  const [cause, setCause] = React.useState([]);
  const [naoError, setNaoError] = React.useState('');
  const [cadNumData, setCadNumData] = React.useState([]);
  const [showCheckPart, setShowCheckPart] = React.useState(false);
  const { setNotification } = useContext(NotificationContext);
  const [errIntersect, setErrIntersect] = React.useState();
  const source = layers.select.getSource();
  const [segments, setSegments] = React.useState([]);
  const [showSegment, setShowSegment] = React.useState(false);
  const [condominium, setCondominium] = React.useState(false);

  const regionNote = () => {
    setNotification({
      title: t("service.region-error-title"),
      text: t("service.region-error-text")
    })
  }
  
  const ateNote = () => {
    setNotification({
      title: t("notif.attention"),
      text: "Выберите сельский округ или населенный пункт чтобы продолжить"
    })
  }

  const removeSegments = () => {
    source.getFeatures().forEach(feature => {
      if (feature.get("type") === "segment") {
        source.removeFeature(feature);
      }
    });
  }

  const addKadNum = () => {
    if (cadNumData.length===0) {
      setCadNumData([{ id: 1, name: '', part: false, checked: false, objId: ''}]);
    } else {
      const newItem = {
        id: cadNumData.length+1,
        name: '',
        part: false
      };
      const newArr = [...cadNumData, newItem]
      setCadNumData(newArr)
    }
  }

  const onDeleteKadNum = (id) => {
    setCadNumData(cadNumData.filter(item=> item.id!==id));
  }

  const updateKadNum = (id, name, checkboxValue) => {
    const obj = {
      id: id,
      name: name,
      part: checkboxValue,
      checked: false,
      objId: ''
    }
    const updateData = cadNumData.map(item => {
      if (item.id===id) {
        return obj
      } else {
        return item
      }
    })
    setCadNumData(updateData);
  }

  const handleCheckPart = () => {
    if (causeId === "21" && cadNumData.length<2) {
      setNotification({
        title: t("notif.attention"),
        text: t("egov-service.cadnum-count")
      })
    } else if (!region || region.code === "000") {
      regionNote();
    } else if (!condominium && !iinLandUser.inputValid) {
      setNotification({
        title: t("notif.attention"),
        text:  t("check-part.send-valid")
      })
    } else {
      const updatedData = cadNumData.map(obj => {
        return { ...obj, checked: false, objId: '' };
      });
      setCadNumData(updatedData);
      setShowCheckPart(true); 
    }
  }

  const checkPart = () => {
    if (cadNumData.length>0) {
      const hasUnchecked = cadNumData.some(cadnum => !cadnum.checked);
      return hasUnchecked;
    }
    return false;
  }

  // const kdStyle = causeName === t("egov-service.land-consolidation") ? {display: 'block'} : {display: 'none'};
  // const dt = causeName === t("egov-service.land-consolidation")  || causeName === t("egov-service.division-land") || causeName === t("egov-service.land adjustment") ? 
  // cadNumData.length>0 ? [{ id: 1, name: cadNumData[0].name}] : [{ id: 1, name: ''}] : [];

  const kdStyle = causeId === "21" ? {display: 'block'} : {display: 'none'};
  const dt = causeId === "21"  || causeId === "22" || causeId === "23" ? 
  cadNumData.length>0 ? [{ id: 1, name: cadNumData[0].name, part: cadNumData[0].part}] : [{ id: 1, name: '', part: false}] : [];
  

  useEffect(() => {
    setCadNumData(dt);
  }, [causeName])

  const kadNumElements = cadNumData.map((item) => {
    return (
      <KadNum
        key={item.id}
        num={item.id}
        onDeleteKadNum={() => onDeleteKadNum(item.id)}
        updateKadNum={updateKadNum}
        useInput={useInput}
        useValidation={useValidation}
        causeName={causeName}
        name={item.name}
      />
    )
  })

  const kadNumInputValid = () => {
    let valid = false;
    if (causeName.length==0 || causeId === "20") {
      valid = false;
    } else if (cadNumData.length>0) {
      cadNumData.map(item => {
        if (!item.name || item.name.length<11 || item.name.length>12) {
          valid=true
        }
      })
    } else {
      valid=true
    }
    
    return valid;
  }

  const setGeom = (mp, coordSystem, fileName, scale, svg) => {
    publicService.coordSystem=coordSystem;
    publicService.geometry=mp;
    publicService.fileName=fileName;
    publicService.scale=scale;
    publicService.base64svg=Buffer.from(svg).toString('base64');
    let cArea = new WKT().readGeometry(mp).getArea() / 10000;
    publicService.coordArea=cArea.toFixed(4);
    setPublicService(Object.assign({}, publicService));
    if ((publicService.area*1).toFixed(4)!=cArea.toFixed(4)) {
      const interval = setTimeout(() => {
        setInformAreaActive(true);
      }, 300);
    } else {
      let feature = new WKT().readFeature(publicService.geometry);
      feature.set("temp", true);
      handleLoadFeature(feature.getGeometry());
      setMode(MODE_SELECT);
    }
  }

  const saveObj = () => { 
    if (!region || region.code === "000") {
      regionNote();
    } else if (ateSoCheck()) {
      ateNote();
    } else if (checkPart()) {
      setNotification({
        title: t("notif.attention"),
        text:  t("check-part.egkn-send-require")
      })
    } else if ((publicService.area*1).toFixed(4)!=publicService.coordArea) {
      setDonwloadConfirmActive(true);
    } else {
      publicService.signLoading=true;
      setPublicService(Object.assign({}, publicService));
      const ctId = categoryChoose.id ? categoryChoose.id : '00';
      const ctName = categoryChoose.name ? categoryChoose.name : t("egov-service.category-set");

      // const ate = publicService.ateName && publicService.ateName!==t("egov-service.ate-not-found") ? publicService.ateName : region.nameRu
      let ateSoName = soName && soName!==t("egov-service.so-not-found") ? soName : "";
      ateSoName += ateName && ateName!==t("egov-service.ate-not-found") ?
      soName && soName!==t("egov-service.so-not-found") ? `, ${ateName}` : ateName
      : "";
      if (!ateSoName) {
        ateSoName = region.nameRu;
      }

      let kadNumList = '';
      let idList = '';
      let kadNumAndIdList = '';

      cadNumData.forEach((item,i) => {
        if (kadNumList) {
          kadNumList += `,${item.name}`;
        } else {
          kadNumList = `${item.name}`;
        }

        if (item.objId) {
          if (idList) {
            idList += `,${item.objId}`;
          } else {
            idList = `${item.objId}`;
          }
        }

        if (kadNumAndIdList) {
          kadNumAndIdList += `,${item.name}-${item.objId}`
        } else {
          kadNumAndIdList = `${item.name}-${item.objId}`
        }
      });

      publicService.service=service.id;
      publicService.regionCode=region.regionCode;
      publicService.regionName=region.nameRu;
      publicService.districtCode=region.code;
      publicService.districtId=region.id;
      publicService.ateId=region.rn_code;
      publicService.ateName=ateSoName;
      publicService.categoryId=ctId;
      publicService.categoryName=ctName;
      publicService.cause=causeId;
      publicService.kadNumList=kadNumList;
      publicService.idList=idList;
      publicService.kadNumAndIdList=kadNumAndIdList;
      publicService.isCondominium=condominium;
      
      getServiceXml(publicService)
        .then(result => (result.json())
        .then(json => {
          publicService.landId=json['landId'];
          publicService.nomPlan=json['nomPlan'];
          publicService.sign=json['text'];
          sign(publicService)
        }))
    }
  }


  const sign = (publicService) => {
    signText(publicService.sign, "SIGNATURE")
    .then(result => result.responseObject)
    .then(sign => {
      publicService.sign=sign;
      setPublicService(Object.assign({}, publicService));
      return publicService;
    })
    .then(service => postService(service))
    .then(result => {
      if (result.status==200) {
        publicService.signLoading=false;
        if (features && features.length > 0) {
          setFeatures([]);
          removeSegments();
          setSegments([]);
          setMode(MODE_SELECT_PDP);
        }
        setBdSuccess(true);
        return result.blob()
      } else {
        setBdError(true);
      }
    })
    .then(blob => download(blob, "Схема испрашиваемого земельного участка.pdf"))  
    .catch(e=> {
      publicService.signLoading=false;
      setPublicService(Object.assign({}, publicService));
    })
  }

  const downloadFile = () => {
    if (!region || region.code === "000") {
      regionNote();
    } else if (ateSoCheck()) {
      ateNote();
    } else if ((publicService.area*1).toFixed(4)!=publicService.coordArea) {
      setDonwloadConfirmActive(true);
    } else {
      const ct = categoryChoose.id ? categoryChoose.name : t("egov-service.category-set");

      // const ate = publicService.ateName && publicService.ateName!==t("egov-service.ate-not-found") ? publicService.ateName : region.nameRu
      let ateSoName = soName && soName!==t("egov-service.so-not-found") ? soName : "";
      ateSoName += ateName && ateName!==t("egov-service.ate-not-found") ?
      soName && soName!==t("egov-service.so-not-found") ? `, ${ateName}` : ateName
      : "";
      if (!ateSoName) {
        ateSoName = region.nameRu;
      }

      const egovService = {
        date: publicService.date,
        landId: '-',
        districtId: region.id,
        geometry: publicService.geometry,
        purposeRu: publicService.purposeRu,
        ate: ateSoName,
        category: ct,
        base64svg: publicService.base64svg,
        scale: publicService.scale,
        location: publicService.locationRu,
        nomPlan: '-'
      }
      documentCreate(egovService);
      }
  }

  let lang = localStorage.getItem('i18nextLng');
  useEffect(() => {  
    if (region) {
      getAte(lang, region.id).then(json => {
        json.length!=0 ? setAte(json) : setAteName(t("egov-service.ate-not-found"))
        if (json.length!=0) {
          setAte([{name: ""}].concat(json));
          setAteName('');
        } else {
          setAte([]);
          setAteName(t("egov-service.ate-not-found"))
        }
      });

      getSo(lang, region.id).then(json => {
        json.length!=0 ? setSo(json) : setSoName(t("egov-service.so-not-found"))
        if (json.length!=0) {
          setSo([{name: ""}].concat(json));
          setSoName('');
        } else {
          setSo([]);
          setSoName(t("egov-service.so-not-found"))
        }
      });
    }  
    
  }, [region, lang])

  const ateSoCheck = () => {
    if (!ateName && !soName) {
      return true;
    } else if (soName === t("egov-service.so-not-found") && !ateName) {
      return true;
    } else if (ateName === t("egov-service.ate-not-found") && !soName) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {    
    getCategory(lang).then(json => {
      setCategory(json);
    });
    getCause(lang).then(json=> {
      setCause(json);
    })
  }, [lang])

  const checkHandler = () => {
    setCheck(!check);
    fio.resetValues();
    iin.resetValues(); 
    setDisable(!disable);
    setNaoError('');
    publicService.fio='';
    publicService.iin='';
    setPublicService(Object.assign({}, publicService));
    getCheckValues();
  }


  const getCheckValues = () => {
    if (!check) {
      getDepartment(region.rn_code).then(json => {
        if (json['resultCode']!=='311') {
          publicService.fio=json['name'];
          publicService.iin=json['bin'];
          setPublicService(Object.assign({}, publicService));
        } else {
          setNaoError(json['resultMessage'])
          fio.resetValues();
          iin.resetValues(); 
        }
      });
    } 
  }

  const formatNumber = (n, dec) => {
    if (n) {
      let numAsString = n
        .toFixed(dec)
        .toString()
        .replace(/\./, ",");
      if (/\d{4},/.test(numAsString)) {
        numAsString = numAsString.replace(/(\d)(?=\d{3},)/, "$1 ");
      }
      while (/^\d{4}/g.test(numAsString)) {
        numAsString = numAsString.replace(/(?=\d)(\d{3})(?=\s)/, " $1");
      }
      return numAsString;
    }
    return 0;
  };

  const segmentsRender = () => {
    let num = 0;
    return (segments.length>0 && segments.map((seg,i) => (
        <React.Fragment key={i}>
          <div className="segment-wrapper" >
            <div>
              {++num + ") "}
              {formatNumber(new WKT().readGeometry(seg.wkt).getArea(), 2)} м<sup>2</sup> (
              {formatNumber(new WKT().readGeometry(seg.wkt).getArea() / 10000, 4)} га.)
            </div>
            <button 
              className="segment-btn"
              onClick={() => {
                setShowSegment(true)
                if (seg.wkt instanceof Object) {
                  handleSelect({geometry: new GeoJSON().readGeometry(seg.wkt)}, null, "segment");
                } else {
                  handleSelect({wkt: seg.wkt}, null, "segment");
                }
              }}
            >
              {t("request.zoom")}
            </button>
          </div>
          {segments.length-1!==i && <hr />}
        </React.Fragment>
        
      ))
    ) 
  }

  const showAllSegments = () => {
    if (layers.select) {
      const segmentsCopy = Object.assign([], segments);
      removeSegments();
      setSegments([]);
      if (segmentsCopy) {
        const segmentsArr = [];
        segmentsCopy.forEach(seg => {
          segmentsArr.push(seg);
          source.addFeature(
            new Feature({
              type: "segment",
              geometry: new WKT().readGeometryFromText(seg.wkt),
            }),
          );
        });
        setSegments(segmentsArr);
        setShowSegment(false);
        map.getView().fit(features[0].getGeometry(), { duration: 500, maxZoom: 19 })
      }
    }
  }

  let iinLandUser = useInput('', {isEmpty: true, minLength: 12, maxLength: 12, valid: false})
  let fio = useInput('', {isEmpty: true, minLength: 2});
  let iin = useInput('', {isEmpty: true, minLength: 12, maxLength: 12, valid: false}, fio, publicService, setPublicService)
  let date = useInput('', {isEmpty: true});
  let purposeRu = useInput('', {isEmpty: true, maxLength: 255});
  let purposeKz = useInput('', {isEmpty: true, maxLength: 255});
  let locationRu = useInput('', {isEmpty: true});
  let locationKz = useInput('', {isEmpty: true});
  let regionData = useInput('', {isEmpty: true});
  let causeData = useInput('', {isEmpty: true});

  let partBtnStyle = causeName ? causeId !== "20" ? !kadNumInputValid() ? {display: 'block'}: {display: 'none'} : {display: 'none'} : {display: 'none'};

  useEffect(() => {  
    if (region) {
      regionData.onChangeName(region.nameRu);
      if (!regionCheck) {
        setRegionCheck(true);
      }
    }  
    
  }, [region])

  const getField = (fieldType, iin, isCheck, type, label, placeholder, field, propName, focus, clazzName, disable) => {
    let style = fieldType === 'input' ? 'fld-region' : 'fld-region fid-ta';
    return (
      <>
        <h2>{label}</h2>
        {/* {naoError && <div style={{color: 'red'}}>{naoError}</div>} */}
        {iin ? 
          field.value.length===12 ? !field.valid && <div style={{color: 'red'}}>{field.errorName}</div> 
          : (field.isDirty && field.error) && <div style={{color: 'red'}}>{field.errorName}</div>
        : (field.isDirty && field.error) && <div style={{color: 'red'}}>{field.errorName}</div>
        }
        <div className={style}>

          {fieldType==='input' ?
            <input type={type} 
              className="header-combo-input input-wsub"
              placeholder={placeholder ? placeholder : ''}
              name={field}
              value={check && isCheck ? publicService.iin : field.value}
              onChange={(e) => {
                type==='date' ? field.onChange(e, publicService, setPublicService) : field.onChange(e);  
                if (type!=='date') {
                  publicService[propName]=e.target.value;
                  setPublicService(Object.assign({}, publicService));
                }            
              }}
              onFocus={(e) => focus ? e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false }) : ''}
              onBlur={(e) => field.onBlur(e)}
              disabled={disable ? disable : false}
            />
            :
            <textarea
              type="text"
              className="header-combo-input input-wsub"
              placeholder={placeholder ? placeholder : ''}
              name={field}
              value={check && isCheck ? publicService.fio : field.value}
              onChange={(e) => {
                field.onChange(e);
                publicService[propName]=e.target.value;
                setPublicService(Object.assign({}, publicService));
              }}
              onBlur={(e) => field.onBlur(e)}
            />
          }

          {check && isCheck && !publicService[propName] && !naoError && 
            <div className={clazzName}>
              <ClipLoader color={"#02c23e"} loading={true} size={"25px"}/>
            </div>
          }

          {iin && field.value.length===12  && 
            <div className="iin-valid">
              {field.valid ? <i className='fa-solid fa-check'></i> : 
              !field.errorName ? <ClipLoader color={"#02c23e"} loading={true} size={"25px"}/> : <i className="fa-solid fa-xmark"></i>}
            </div>
          }

        </div>
      </>
    )
  }

	return (
      <EgovServiceWidget responsive={responsive} coordinatesActive={coordinatesActive} 
        informAreaActive={informAreaActive} bdError={bdError} bdSuccess={bdSuccess} donwloadConfirmActive={donwloadConfirmActive} partActive={showCheckPart}
        >
        <h2 className="service-title">{t("egov-service.agreement")}</h2>

        <h2>{t("egov-service.region")}*</h2>
        {(regionData.isDirty && regionData.error) && <div style={{color: 'red'}}>{regionData.errorName}</div>}
        <div className="fld-region">
          <input
            type="text"
            className="header-combo-input input-sub"
            placeholder={t("choose-region.1")}
            name="region"
            value={region && region.nameRu}    
            readOnly    
            onClick={(e)=> {
              if (openRegion && !regionCheck) regionData.onBlur(e);
              setOpenRegion(!openRegion);
              if (openCategory) setOpenCategory(false)
              if (openAte) setOpenAte(false)     
              if (openCause) setOpenCause(false)
            }}
          />
          <button
            type="submit"
            className="header-combo-btn"
            disabled={false}
            onClick={(e) => {
              if (openRegion && !regionCheck) regionData.onBlur(e);
              setOpenRegion(!openRegion);
              if (openCategory) setOpenCategory(false)
              if (openAte) setOpenAte(false)      
              if (openCause) setOpenCause(false)
            }}
          >
            <img src={comboImg} style={{width: 14,height: 7,}} alt=""/>
          </button>
          {openRegion && (
            <ChooseRegion
              setRegion={(district) => { setOpenRegion(false); setRegion(district); }}
              regions={regions}
              maxHeight={openRegionMaxHeight}
              lang={i18n.language}
              responsive={responsive}
              top={'33px'}
              width={'498px'}
              mh={'29vh'}
              regionChange={regionData.onChangeName}
              regionBlur={regionData.onBlur}
            />
          )}
        </div>

        <h2>Сельский округ</h2>
        <div className="fld-region">
          <input
            type="text"
            className="header-combo-input input-sub"
            placeholder={t("egov-service.so")}
            value={soName}
            readOnly
            onClick={()=> {
              setOpenSo(!openSo);
              if (openCategory) setOpenCategory(false)
              if (openRegion) setOpenRegion(false)
              if (openCause) setOpenCause(false)
              if (openAte) setOpenAte(false)
            }}
          />
          <button
            type="submit"
            className="header-combo-btn"
            disabled={so.length>0 ? false : true}
            onClick={() => {
              setOpenSo(!openSo);
              if (openCategory) setOpenCategory(false)
              if (openRegion) setOpenRegion(false)
              if (openCause) setOpenCause(false)
              if (openAte) setOpenAte(false)
            }}        
          >
            <img src={comboImg} style={{width: 14,height: 7}} alt=""/>
          </button>
          {openSo && (
            <ChooseAto
              so={so}
              maxHeight={openRegionMaxHeight}
              lang={i18n.language}
              responsive={responsive}
              setSoName={setSoName}
              setOpenSo={setOpenSo}
              top={'33px'}
              handleSelect={handleSelect}
            />
          )}

        </div>

        <h2>Населенный пункт</h2>
        <div className="fld-region">
          <input
            type="text"
            className="header-combo-input input-sub"
            placeholder={t("egov-service.ate")}
            value={ateName}
            readOnly
            onClick={()=> {
              setOpenAte(!openAte);
              if (openCategory) setOpenCategory(false)
              if (openRegion) setOpenRegion(false)
              if (openCause) setOpenCause(false)
              if (openSo) setOpenSo(false)
            }}
          />
          <button
            type="submit"
            className="header-combo-btn"
            disabled={ate.length>0 ? false : true}
            onClick={() => {
              setOpenAte(!openAte)
              if (openCategory) setOpenCategory(false)
              if (openRegion) setOpenRegion(false)
              if (openCause) setOpenCause(false)
              if (openSo) setOpenSo(false)
            }}        
          >
            <img src={comboImg} style={{width: 14,height: 7}} alt=""/>
          </button>
          {openAte && (
            <ChooseAto
              ate={ate}
              maxHeight={openRegionMaxHeight}
              lang={i18n.language}
              responsive={responsive}
              setAteName={setAteName}
              setOpenAte={setOpenAte}
              top={'33px'}
              handleSelect={handleSelect}
            />
          )}

        </div>

        <div className="condominium-wrapper">
          <h2>{t("egov-service.land-user")}</h2>
          <div className='condominium fld-region'>
            <h4 className="condominium-title">{t("egov-service.condominium")}</h4>
            <input
              id="condominium-yes"
              className="condominium-item" 
              type="radio"
              name="condominium" 
              value={t("egov-service.yes")} 
              onClick={() => {
                iinLandUser.resetValues();
                publicService.iinLandUser='';
                setPublicService(Object.assign({}, publicService));
                setCondominium(true);
              }} 
            />
            <label htmlFor="condominium-yes">{t("egov-service.yes")} </label>

            <input 
              id="condominium-no"
              className="condominium-item" 
              type="radio" 
              name="condominium" 
              value={t("egov-service.no")}
              onClick={() => setCondominium(false)} 
              defaultChecked
            />
            <label htmlFor="condominium-no">{t("egov-service.no")}</label>
          </div>

          {!condominium &&
            <>
              <div className="condominium-iin">
                <h4 className="condominium-title">{t("egov-service.iin-bin")}</h4>
                <input 
                  type="number" 
                  className="header-combo-input input-wsub"
                  placeholder={t("egov-service.iin-ph")}
                  name="iinLandUser"
                  value={iinLandUser.value}
                  onChange={(e) => {
                    iinLandUser.onChange(e)
                    publicService.iinLandUser=e.target.value;
                    setPublicService(Object.assign({}, publicService));          
                  }}
                  onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                  onBlur={(e) => iinLandUser.onBlur(e)}
                />

                {iinLandUser.value.length===12  && 
                  <div className="iin-valid">
                    {iinLandUser.valid ? <i className='fa-solid fa-check'></i> : 
                    !iinLandUser.errorName ? <ClipLoader color={"#02c23e"} loading={true} size={"25px"}/> : <i className="fa-solid fa-xmark"></i>}
                  </div>
                }
              </div>

              {
                iinLandUser.value.length===12 ? !iinLandUser.valid && <div style={{color: 'red'}}>{iinLandUser.errorName}</div> 
                : (iinLandUser.isDirty && iinLandUser.error) && <div style={{color: 'red'}}>{iinLandUser.errorName}</div>
              
              }

            </>
          }
          
        </div>

        {naoError && <div style={{color: 'red', marginBottom: '-12px'}}>{naoError}</div>}
        <CheckBoxContainer>
          <input
            type="checkbox"
            className="header-combo-input input-wsub"
            value={check}
            onChange={checkHandler}
            disabled={!region}
          />
          <label>{t("egov-service.developer")}</label>
          
        </CheckBoxContainer>

        {getField('input', true, true, "number", t("egov-service.iin-developer") + ' *', t("egov-service.iin-ph"), iin, 'iin', true, 'iin-loader', disable)}
        {getField('textarea', false, true, "text", t("egov-service.fio-developer") + ' *', t("egov-service.fio-developer-ph"), fio, 'fio', false,  'name-loader', disable)}
        {getField('input', false, false, "date", t("egov-service.date") + ' *', '', date, 'date')}
        {getField('textarea', false, false, "text", t("egov-service.target-rus") + ' *', t("egov-service.target-rus-ph"), purposeRu, 'purposeRu')}
        {getField('textarea', false, false, "text", t("egov-service.target-kz") + ' *', t("egov-service.target-kz-ph"), purposeKz, 'purposeKz')}
        {getField('textarea', false, false, "text", t("egov-service.location-rus") + ' *', t("egov-service.location-rus-ph"), locationRu, 'locationRu')}
        {getField('textarea', false, false, "text", t("egov-service.location-kz") + ' *', t("egov-service.location-kz-ph"), locationKz, 'locationKz')}

        <h2>{t("egov-service.category")}</h2>
        <div className="fld-region fid-ct">
          <textarea 
            type="text"
            className="header-combo-input input-sub input-sub-ct"
            name="category"
            placeholder={t("egov-service.category-choose")}
            value={categoryChoose ? categoryChoose.name : ''}
            readOnly
            onClick={()=> {
              setOpenCategory(!openCategory);
              if (openRegion) setOpenRegion(false) 
              if (openAte) setOpenAte(false)
              if (openCause) setOpenCause(false)
              if (openSo) setOpenSo(false)
            }}
          />
          <button
            type="submit"
            className="header-combo-btn header-combo-btn-ct"
            disabled={false}
            onClick={() => {
              setOpenCategory(!openCategory)
              if (openRegion) setOpenRegion(false) 
              if (openAte) setOpenAte(false)
              if (openCause) setOpenCause(false)
              if (openSo) setOpenSo(false)
            }}
          >
            <img src={comboImg} style={{width: 14, height: 7}} alt=""/>
          </button>

          {openCategory && (
            <ChooseAto
              category={category}
              maxHeight={openRegionMaxHeight}
              lang={i18n.language}
              responsive={responsive}
              setCategoryChoose={setCategoryChoose}
              setOpenCategory={setOpenCategory}
              top={'74px'}
            />
          )}
        </div>

        <h2>{t("egov-service.cause")}*</h2>
        {(causeData.isDirty && causeData.error && cause.length>0) && <div style={{color: 'red'}}>{causeData.errorName}</div>}
        <div className="fld-region">
          <input
            type="text"
            className="header-combo-input input-sub"
            placeholder={t("egov-service.cause-choose")}
            value={causeName}
            readOnly
            onClick={(e)=> {
              if (openCause && causeName && causeName.length==0) causeData.onBlur(e);
              setOpenCause(!openCause);
              if (openCategory) setOpenCategory(false);
              if (openRegion) setOpenRegion(false);
              if (openAte) setOpenAte(false);
              if (openSo) setOpenSo(false)
            }}
          />
          <button
            type="submit"
            className="header-combo-btn"
            disabled={cause.length>0 ? false : true}
            onClick={(e) => {
              if (openCause && causeName.length==0) causeData.onBlur(e);
              setOpenCause(!openCause)
              if (openCategory) setOpenCategory(false);
              if (openRegion) setOpenRegion(false);
              if (openAte) setOpenAte(false);
              if (openSo) setOpenSo(false)
            }}        
          >
            <img src={comboImg} style={{width: 14, height: 7}} alt=""
            />
          </button>

          {openCause && (
            <ChooseAto
              cause={cause || ''}
              maxHeight={openRegionMaxHeight}
              lang={i18n.language}
              responsive={responsive}
              setCauseName={setCauseName}
              setCauseId={setCauseId}
              setOpenCause={setOpenCause}
              causeChange={causeData.onChangeName}
              top={'33px'}
            />
          )}
        </div>

        <div className="list-wrapper">
          <h2>{t("egov-service.cadnum-list")}</h2>
          <button 
                style={kdStyle}
                className="add-list-btn"
                onClick={addKadNum}>
            <i className="fa-sharp fa-solid fa-plus"></i>
          </button>
        </div>
        <ul className="kadnum-list">
          {kadNumElements}
        </ul>
        <EgovServiceBtn  
          onClick={handleCheckPart}
          style={partBtnStyle}
        >
          {t("check-part.egkn-send")}
        </EgovServiceBtn>

        <h2>{t("egov-service.area")}, ГА*</h2>
        <div className="fld-region">
          <input
            type="number"
            className="header-combo-input input-wsub"
            name="area"
            onChange={(e) => {
              publicService.area=e.target.value;
	            setPublicService(Object.assign({}, publicService));
            }}
            onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
            placeholder={t("egov-service.area-choose")}
          />
        </div>

        {segments && segments.length>0 && 
          <>
            <h2 style={{background: "linear-gradient(rgb(237, 28, 36) 0%, rgb(193, 39, 45) 100%)"}}>{t("request.intersections")}</h2>
            {showSegment && segments.length>1 && 
              <button className="segment-btn-all" onClick={() => showAllSegments()}>
                {t("request.all-intersections")}
              </button>
            }
            <div className="segments">
              {segmentsRender()}
            </div>
          </>
        }

        {errIntersect && errIntersect.length > 0 && 
        <>
          <h2 style={{background: "linear-gradient(rgb(237, 28, 36) 0%, rgb(193, 39, 45) 100%)"}}>{t("egov-service.errors")}</h2>
          {errIntersect.map((err, i) => <div className="fld-region draw-error" key={i}>{err.msgRu}</div>)}
        </>
        }

        <EgovServiceBtn onClick={() => {
          if (!region || region.code === "000") {
            regionNote();
          } else if (ateSoCheck()) {
            ateNote();
          } else {
            setCoordinatesActive(true)
          }
        }}>
          {t("egov-service.coordinates")}
        </EgovServiceBtn>
        <EgovServiceBtn
          onClick={downloadFile}
          disabled={(!condominium && !iinLandUser.inputValid) || (!check && (!fio.inputValid || !iin.inputValid)) || !date.inputValid || !purposeRu.inputValid || !purposeKz.inputValid || !locationRu.inputValid || !locationKz.inputValid || !causeData.inputValid || kadNumInputValid() ||!publicService.geometry>0}
        >
          {t("egov-service.doc")}
        </EgovServiceBtn>
        
        {!publicService.signLoading ? 
          <EgovServiceBtn
            onClick={saveObj}
            disabled={(!condominium && !iinLandUser.inputValid) || (!check && (!fio.inputValid || !iin.inputValid)) || !date.inputValid || !purposeRu.inputValid || !purposeKz.inputValid || !locationRu.inputValid || !locationKz.inputValid || !causeData.inputValid || kadNumInputValid() ||!publicService.geometry}      
          >
            {t("egov-service.confirm")}
         </EgovServiceBtn>  
         : <div className="sign-loading">
            <ClipLoader color={"#02c23e"} loading={true} size={"35px"}/>
           </div>
        }
        

        <EgovServiceBtn color={'#ae2a00 '} 
          onClick={()=> {
            if (layers.select && segments.length>0) {
              removeSegments();
              setSegments([]);
              setShowSegment(false)
            }
            setEgovService('');
            setFeatures([]);
            setMode(MODE_SELECT_PDP);
          }}>
          {t("egov-service.close")}
        </EgovServiceBtn>

        <Coordinates
          active={coordinatesActive}
          setActive={setCoordinatesActive}
          setGeom={setGeom}
          area={publicService.area}
          useInput={useInput}
          publicService={publicService}
          setPublicService={setPublicService}
          fileFormatNote={fileFormatNote}
          checkFileFormat={checkFileFormat}
          errors={errors}
          setErrors={setErrors} 
          region={region}
          features={features}
          setFeatures={setFeatures}
          handleLoadFeature={handleLoadFeature}
          setMode={setMode}
          removeSegments={removeSegments}
          setSegments={setSegments}
          layers={layers}
          source={source}
          serviceId={serviceId}
          cadNumData={cadNumData}
        />
        
        <div className={informAreaActive ? "area_inform active" : "area_inform"}>
          <ModalInfo>
            <div className="area_inform_content">
              <h3>{t("egov-service.info")}</h3>
              <p>{t("egov-service.indicated-area1")} ({(publicService.area*1).toFixed(4)}) {t("egov-service.indicated-area2")} ({publicService.coordArea}). {t("egov-service.indicated-area3")}</p>
              <div className="area-confirm">
                <button className="area-confirm_btn" onClick={() => {
                  setInformAreaActive(false);
                  let feature = new WKT().readFeature(publicService.geometry);
                  feature.set("temp", true);
                  handleLoadFeature(feature.getGeometry());
                  setMode(MODE_SELECT);
                }}>
                  {t("egov-service.yes")}
                </button>
                <button className="area-confirm_btn" 
                onClick={() => {
                  setInformAreaActive(!informAreaActive)
                  setCoordinatesActive(true);
                }}>
                  {t("egov-service.no")}
                </button>
              </div>
            </div>
          </ModalInfo> 
          </div>

          <div className={donwloadConfirmActive ? "area_inform active" : "area_inform"}>
          <ModalInfo>
            <div className="area_inform_content">
              <h3>{t("egov-service.info")}</h3>
              <p>{t("egov-service.indicated-area1")} ({(publicService.area*1).toFixed(4)}) {t("egov-service.indicated-area2")} ({publicService.coordArea}).</p>
              <div className="area-confirm">
                <button className="area-confirm_btn" onClick={() => setDonwloadConfirmActive(!donwloadConfirmActive)}>
                  Ок
                </button>
              </div>
            </div>
          </ModalInfo> 
          </div>

          <div className={bdError ? "area_inform active" : "area_inform"}>
          <ModalInfo>
            <div className="area_inform_content error">
              <h3>{t("egov-service.info")}</h3>
              <p>{t("egov-service.error")}</p>
              <div className="area-confirm">
                <button className="area-confirm_btn error" onClick={() => setBdError(!bdError)}>
                  {t("egov-service.continue")}
                </button>
              </div>
            </div>
            
          </ModalInfo> 
          </div>

          <div className={bdSuccess ? "area_inform active" : "area_inform"}>
          <ModalInfo>
            <div className="area_inform_content error">
              <h3>{t("egov-service.info")}</h3>
              <p>{t("egov-service.success")}</p>
              <div className="area-confirm-land">
                <div className="area-confirm_doc-num_wrap">
                  <p className="doc-num">
                    {t("egov-service.doc-number")}<br />
                    {`${publicService.nomPlan} (${publicService.landId})`}
                  </p>
                </div>
                <div className="area-confirm_btn_wrapper">
                  <button className="area-confirm_btn error" onClick={() => {
                    setEgovService('');
                    setBdSuccess(!bdSuccess)}
                  }>
                    {t("egov-service.continue")}
                  </button>
                </div>
              </div>
            </div>
            
          </ModalInfo> 
          </div>

          {showCheckPart && 
            <CheckPart 
              rnCode={region.rn_code} 
              cadNumData={cadNumData} 
              setCadNumData={setCadNumData} 
              iinBin={publicService.iinLandUser}
              condominium={condominium}
              setShowCheckPart={setShowCheckPart}
            />
          }
      </EgovServiceWidget>
  );
}
