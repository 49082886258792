import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useTranslation } from "react-i18next";
import { Icon } from './UserProfile';
import styled from "styled-components";
import Collapsible from "react-collapsible";
import { Scrollbars } from "react-custom-scrollbars";
import svgPlus from '../../assets/img/plus-box-fill.svg';
import svgMinus from '../../assets/img/minus-box-fill.svg';
import svgSearch from '../../assets/img/search-obj.svg';
import svgClear from '../../assets/img/search-clear.svg';
import svgLeft from '../../assets/img/paginator/left.svg';
import svgRight from '../../assets/img/paginator/right.svg';
import svgLeftOne from '../../assets/img/paginator/left-one-step.svg';
import svgRightOne from '../../assets/img/paginator/right-one-step.svg';
import LecenseeViewWorkOrder from '../Cabinet/LecenseeViewWorkOrder';
import LecenseeExecWorkOrder from '../Cabinet/LecenseeExecWorkOrder';
import LecenseeViewOrder from './LecenseeViewOrder';

export const Modal = styled.div`
    position: absolute;
    left: ${props => props.responsive.mobile ? "0" : "50%"};
    top: ${props => props.responsive.mobile ? "0" : "50%"};
    transform: ${props => props.responsive.mobile ? "0" : "translate(-50%, -50%)"};
    max-height: ${props => props.responsive.mobile ? "100%" : "600px"};
    height: ${props => props.responsive.mobile ? "100%" : "auto"};
    width: ${props => props.responsive.mobile ? "100%" : "990px"};
    background-color: #fff;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    z-index: 1;
    font-family: 'sf-pro-display-regular',sans-serif;
`;

export const ModalHeader = styled.div`
    .header {
        display: flex;
        height: 50px;
        margin-bottom: 15px;
        border-radius: 5px 5px 0px 0px;
        background: linear-gradient(180deg, #01C13D 0%, #00A824 100%);
        align-items: center;
        padding: 0px 80px 0px 20px;
        
        .title {
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 15px;
            color: #FFFFFF;
        }
    }
`;

export const ModalFooter = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin-top: 5px;

    button {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 10px 10px 0px;
        width: 93%;
        border-radius: 5px;
        color: #fff;
        height: 30px;
        border: none;
        outline: none;
        &:hover {
            cursor: pointer
        }
    }

    .success-btn {
        background: #00A824;
        width: 90px;
    }

    .cancel-btn {
        background: #777777;
        width: 90px;
    }
`;

export const ModalBody = styled.div`
    padding: 10px;

    .scrollbar-view {
        padding-bottom: 10px !important;
    }

    div.Collapsible__contentOuter {
        background-color: #fff;
      }
    
      .Collapsible {
        .Collapsible__trigger {
          .legend-group {
            width: 260px;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            color: #fff;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            font-weight: normal;
            line-height: 40px;
          }
        }
    
        .legend-title {
          margin-left: 20px;
        }

        .collapsible-img {
            width: 30px;
            height: 30px;
            cursor: pointer;
            background-position: center !important;
        }
    }

    .search-fld {
        border: 1px solid #B4CFBC;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 0 12px;
        display: flex;
        align-items: center;
        width: 50%;
        justify-content: start;
        height: 32px;
        padding-left: 8px;
        margin-bottom: 12px
        width: 100%;
    
        input {
          border: none;
          font-family: 'Roboto';
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 12px;
          color: #294A37;
          width: 100%;
        }
    
        input:focus {
          outline: none;
        }
    }

    .search-btn {
        width: 24px;
        height: 24px;
        background-color: #A0B6A6;
        -webkit-mask: url(${svgSearch}) no-repeat;
        mask: url(${svgSearch}) no-repeat;
        cursor: pointer;
    }

    .search-clear-btn {
        margin-top: 5px
        width: 24px;
        height: 24px;
        background-color: #A0B6A6;
        -webkit-mask: url(${svgClear}) no-repeat;
        mask: url(${svgClear}) no-repeat;
        cursor: pointer;
    }

    .register-table {
        height: auto;

        table {
            border: 1px solid #B6D4C0;
            border-spacing: 0;
            width: 100%;

            thead {
                tr {
                    width: 100%;
                    height: auto;
                    display: flex;
                    margin: 0;
                    padding: 0;
                    background: #fff;
    
                    td {
                        padding: 3px 8px;
                        border: 1px solid #B6D4C0;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        background: #E0F0E5;
                        color: #008A78;
                        text-align: center;
                    }
                }
            }

            tbody {
                tr {
                    width: 100%;
                    height: auto;
                    display: flex;
                    margin: 0;
                    padding: 0;
                    background: #fff;
                    cursor: pointer;
    
                    td {
                        padding: 3px 8px;
                        border: 1px solid #B6D4C0;
                        // height: 42px;
                        height: 45px;
                        overflow: hidden;
                        position: relative;
                    }
                       
                    // td:after {
                    //     content: "";
                    //     position: absolute;
                    //     left: 0;
                    //     bottom: 0;
                    //     width: 100%;
                    //     height: 10px;
                    //     background: linear-gradient(180deg, transparent, #fff 50%);
                    // }
                }
            }

            tbody tr:hover {
                background: #D8EFE0; /* Цвет фона при наведении */
            }

            .even {
                background: #F8FFFA;
            }
        }

        .paginator {
            display: flex;
            flex-direction: row;
            // align-items: flex-start;
            justify-content: center
            padding: 0px;
            gap: 2px;
            margin-top: 5px;
            margin-bottom: 5px;

            .page-button {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 2px;
                gap: 10px;
                width: 20px;
                height: 20px;
                background: #FFFFFF;
                border: 0.5px solid #008A78;
                border-radius: 2px;
                cursor: pointer;
    
                .page-number {
                    font-family: 'SF UI Display';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 7px;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    color: #333333;
                }
            }
    
            .page-button-active {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 2px;
                gap: 10px;
                width: 20px;
                height: 20px;
                background: #008A78;
                border-radius: 2px;
                cursor: pointer;
    
                .page-number {
                    font-family: 'SF UI Display';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 7px;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    color: #FFFFFF;
                }
            }

            .page-fisrt {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 2px;
                width: 20px;
                height: 20px;
                background: transparent url('${svgLeft}') no-repeat;
                -moz-background-size: 100%; /* Firefox 3.6+ */
                -webkit-background-size: 100%; /* Safari 3.1+ и Chrome 4.0+ */
                -o-background-size: 100%; /* Opera 9.6+ */
                background-size: 100%; /* Современные браузеры */;
                border-radius: 2px;
                cursor: pointer;
            }

            .page-left-one {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 2px;
                width: 20px;
                height: 20px;
                background: transparent url('${svgLeftOne}') no-repeat;
                -moz-background-size: 100%; /* Firefox 3.6+ */
                -webkit-background-size: 100%; /* Safari 3.1+ и Chrome 4.0+ */
                -o-background-size: 100%; /* Opera 9.6+ */
                background-size: 100%; /* Современные браузеры */;
                border-radius: 2px;
                cursor: pointer;
            }

            .page-last {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 2px;
                width: 20px;
                height: 20px;
                background: transparent url('${svgRight}') no-repeat;
                -moz-background-size: 100%; /* Firefox 3.6+ */
                -webkit-background-size: 100%; /* Safari 3.1+ и Chrome 4.0+ */
                -o-background-size: 100%; /* Opera 9.6+ */
                background-size: 100%; /* Современные браузеры */;
                border-radius: 2px;
                cursor: pointer;
            }

            .page-right-one {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 2px;
                width: 20px;
                height: 20px;
                background: transparent url('${svgRightOne}') no-repeat;
                -moz-background-size: 100%; /* Firefox 3.6+ */
                -webkit-background-size: 100%; /* Safari 3.1+ и Chrome 4.0+ */
                -o-background-size: 100%; /* Opera 9.6+ */
                background-size: 100%; /* Современные браузеры */;
                border-radius: 2px;
                cursor: pointer;
            }
        }

        .no-result {
            background: none;
            font-size: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #26602D;
            margin: 5px;
        }
    }
`;

export const OnTop = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    backdrop-filter: blur(3px);
    background-color: rgb(0 0 0 / 49%);
    z-index: 1000;
`;

export default function LecenseeWorkOrders({
    setShowWorkOrders,
    responsive,
    setNotification,
    pdfNote,
    checkPdf
}) {
    const { t, i18n } = useTranslation();
    const style1 = {
        backgroundColor: "#fff",
        borderRadius: "5px 5px 0px 0px",
    };
    const style2 = {
        backgroundColor: "#fff",
    };
    const style3 = {
        backgroundColor: "#fff",
        borderRadius: "0px 0px 5px 5px",
    };
    const [typesServices, setTypesServices] = useState([]);
    const [selectOrder, setSelectOrder] = useState({});
    const [isShowViewWorkOrder, setShowViewWorkOrder] = useState(false);
    const [isShowExecWorkOrder, setShowExecWorkOrder] = useState(false);
    const [expanded, setExpanded] = useState({});
    const [searchText, setSearchText] = useState('');
    const listStyleLegend = [style1, style2, style3];
    const searchRef = React.useRef();
    const [isShowViewOrder, setShowViewOrder] = useState(false);
    const [lang, setLang] = useState(i18n.language)

	useEffect(() => {
        getOrders()
	}, [])

    const handleExpanded = (layer, value) => {
        const x = {};
        x[layer.name] = value;
        setExpanded(Object.assign({}, expanded, x));
    };

    const getOrders = () => {
        axios({
            url: `${window.REST_URL}/cabinet/zkr/order?lang=${lang}&pageNum=1&pageSize=10`,
            method: 'GET',
            mode: "cors",
            credentials: "include"
        })
        .then((res) => {
			setTypesServices(res.data);
        })
        .catch((err) => {
            console.log(err);
        });
    }

    const nextPage = (service) => {
        let startPage = 1
        if(service.startPage !== undefined) {
            startPage = service.startPage
        }
        let page = startPage + 10

        if (page <= Math.ceil(service.count/10)) {
            goPage(page, service.code)
        }
    }

    const prevPage = (service) => {
        let startPage = 1
        if(service.startPage !== undefined) {
            startPage = service.startPage
        }
        let page = startPage - 10

        if (page > 0) {
            goPage(page, service.code)
        }
    }

    const goPage = (page, seviceCode) => {
        let payload = `&service=${seviceCode}`
        if(searchText) {
            payload += `&search=${searchText}`
        }

        //вычисляю чтобы было кратное 10, но с учетом того чтобы это не было самой 10, 20, 30 и т.д.
        // потому что тогда страницы начнуться с этого числа
        let startPage = ((page - 1) - (page - 1) % 10) + 1;

        axios({
            url: `${window.REST_URL}/cabinet/zkr/order?lang=${lang}&pageNum=${page}&pageSize=10${payload}`,
            method: 'GET',
            mode: "cors",
            credentials: "include"
        })
        .then((res) => {
            let newTypesServices = [];
            typesServices.forEach(obj => {
                if(seviceCode == obj.code) {
                    obj.orders = res.data[0].orders
                    obj.currPage = page
                    obj.startPage = startPage
                    newTypesServices.push(obj)
                } else {
                    newTypesServices.push(obj)
                }
            });

            setTypesServices(newTypesServices);
        })
        .catch((err) => {
            console.log(err);
        });
    }

    const searchOrder = () => {
        let searchText = searchRef.current.value
        setSearchText(searchText);

        axios({
            url: `${window.REST_URL}/cabinet/zkr/order?lang=${lang}&pageNum=1&pageSize=10&search=${searchText}`,
            method: 'GET',
            mode: "cors",
            credentials: "include"
        })
        .then((res) => {
			setTypesServices(res.data);
        })
        .catch((err) => {
            console.log(err);
        });
    }

    const searchClear = () => {
        setSearchText('')
        searchRef.current.value = '';
        getOrders()
    }

    const openOrder = (order) => {
        setSelectOrder(order)
        if(order.statusId == 14) { //Запрос данных об оплате
            setShowViewWorkOrder(true)
        } else if (order.statusId == 6) { // На исполнении
            setShowExecWorkOrder(true)
        } else {
            setShowViewOrder(true);
        }
    }

    const getPaginator = (service) => {
        let startPage = 1
        let maxNumber = Math.ceil(service.count/10);
        if(service.startPage !== undefined) {
            startPage = service.startPage
        }

        let arrPages = [];
        for(let i = 0; i <= 9; i++) {
            let page = startPage + i;
            if(maxNumber < page) {
                break;
            }
            arrPages.push(page);
        }

        return (
            <div className='paginator'>
                <div className='page-fisrt' onClick={() => goPage(1, service.code)}></div>
                {service.count>100 && <div className='page-left-one' onClick={() => prevPage(service)}></div>}

                {arrPages.map((page, i) =>
                    <div className={
                            (service.currPage != undefined && service.currPage == page) || (service.currPage == undefined && page == 1) ? 
                                "page-button-active" : 
                                "page-button"
                        } onClick={() => goPage(page, service.code)} key={i}>
                        <span className="page-number">{ page }</span>
                    </div>
                )}

                {service.count>100 && <div className='page-right-one' onClick={() => nextPage(service)}></div>}
                <div className='page-last' onClick={() => goPage(Math.ceil(service.count/10), service.code)}></div>
            </div>
        );
    }
    
    return (
        <OnTop>
            <Modal responsive={responsive}>
            <ModalHeader>
            <div className="header">
                <div className='title'>{t("zkr.orders")}</div>
            </div>
            </ModalHeader>
            <ModalBody responsive={responsive}>
                <div className="search-fld">
                    <div className="search-btn" onClick={
                        searchOrder
                    } />
                    <input placeholder={t("zkr.order-search")} title={t("zkr.order-search-title")} ref={searchRef} defaultValue={searchText ? searchText : ''} onKeyDown={(e) => e.key === "Enter" && searchOrder()} />
                    {searchText != '' && (
                        <div className="search-clear-btn" onClick={
                            searchClear
                        } />
                    )}
                </div>
                <Scrollbars
                    autoHeight={true}
                    // autoHide={true}
                    // autoHeightMax={"34vh"}
                    autoHeightMax={"300px"}
                    renderView={props => <div {...props} className="scrollbar-view" />}
                >

                { typesServices && typesServices.length>0 ? typesServices.map((service, i) => (
                    <Collapsible
                        key={i}
                        trigger={
                            <div className="register-table" style={listStyleLegend[i]}>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: "5%", height: "35px", padding: "4px 15px" }}>{
                                                expanded[service.name] ? 
                                                    <Icon url={svgMinus} className="icon" />
                                                :
                                                    <Icon url={svgPlus} className="icon" />
                                            }</td>
                                            <td style={{ width: "85%", height: "35px" }}>{service.name}</td>
                                            <td style={{ width: "10%", height: "35px" }}>{service.count}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        }
                        transitionTime={200}
                        easing="ease-out"
                        open={expanded[i]}
                        onOpening={() => handleExpanded(service, true)}
                        onClosing={() => handleExpanded(service, false)}
                    >
                        <div className="register-table">
                            <table>
                                <thead>
                                    <tr>
                                        <td style={{ width: "5%" }}>№</td>
                                        <td style={{ width: "12%" }}>{t("zkr.order-number")}</td>
                                        <td style={{ width: "32%" }}>{t("zkr.order-applicant")}</td>
                                        <td style={{ width: "17%" }}>{t("zkr.order-status")}</td>
                                        <td style={{ width: "17%" }}>{t("zkr.order-start")}</td>
                                        <td style={{ width: "17%" }}>{t("zkr.order-update")}</td>
                                    </tr>
                                </thead>
                                <tbody>
                                { service.orders && service.orders.map((item, i) => (
                                    <tr key={i} onClick={() => {
                                        openOrder(item)
                                    }}>
                                        <td style={{ width: "5%",  paddingLeft: "15px" }}>{++i}</td>
                                        <td style={{ width: "12%" }}>{item.id}</td>
                                        <td style={{ width: "32%" }}>{item.bin || item.iin}</td>
                                        <td style={{ width: "17%" }}>{item.statusName}</td>
                                        <td style={{ width: "17%" }}>{item.timeStart}</td>
                                        <td style={{ width: "17%" }}>{item.timeUpdate}</td>
                                    </tr>
                                )) }
                                </tbody>
                            </table>
                            { getPaginator(service) }
                            
                        </div>
                    </Collapsible>
                )) : searchText ? <p style={{textAlign: "center"}}>По указанным критерияем заказов не найдено</p> : ''
                }
                </Scrollbars>
            </ModalBody>
            <ModalFooter responsive={responsive}>
                <button
                    className="cancel-btn"
                    href=""
                    onClick={() => {
                        setShowWorkOrders(false)
                    }}
                >
                    {t("request.cancel")}
                </button>
            </ModalFooter>

            {isShowViewWorkOrder && (
                <LecenseeViewWorkOrder
                    order={selectOrder}
                    setShowViewWorkOrder={() => setShowViewWorkOrder()}
                    setShowWorkOrders={() => setShowWorkOrders()}
                    responsive={responsive}
                    setNotification={setNotification}
                    pdfNote={pdfNote}
                    checkPdf={checkPdf}
                />
            )}
            {isShowExecWorkOrder && (
                <LecenseeExecWorkOrder
                    order={selectOrder}
                    setShowExecWorkOrder={() => setShowExecWorkOrder()}
                    setShowWorkOrders={() => setShowWorkOrders()}
                    responsive={responsive}
                    setNotification={setNotification}
                    pdfNote={pdfNote}
                    checkPdf={checkPdf}
                />
            )}
            {isShowViewOrder && (
                <LecenseeViewOrder
                    order={selectOrder}
                    setShowViewOrder={() => setShowViewOrder()}
                    responsive={responsive}
                />
            )}
            </Modal>
        </OnTop>
    );
}
