import React, { useState, useCallback } from "react";
import styled from "styled-components";
import svgRightArrow from '../assets/img/right-arrow.svg'
import svgLeftArrow from '../assets/img/left-arrow.svg'
import svgTwoRightArrow from '../assets/img/two-right-arrow.svg'
import svgTwoLeftArrow from '../assets/img/two-left-arrow.svg'

export const BottomBar = styled.div`
  background-color: ${props => props.palette.green};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  // border: 6px solid #fff;
 border-top: none;
  margin-top: 6px;
 width: 100%;
 z-index: 2;
max-width: 327px;

  
  p {
    margin: 0 6px;
    display: inline-block;
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    color: ${props => props.palette.text};
  }
`;

export const Button = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-left: 6px !important;
  text-transform: uppercase;
  background: rgba(247,147,30,1); 
  color: ${props => props.palette.text};
  width: 22px;
  height: 22px;
  &:hover {
    background: orange;
  }
  img {
    fill: ${props => props.palette.text};
  }
`;

const PageNav = props => {
  let count = Math.ceil(props.count / 10);
  const [pageNum, setPageNum] = useState(0);
  const handleNextPage = useCallback(() => {
    props.getObjects.func(pageNum + 1);
    setPageNum(pageNum + 1);
  }, [props, pageNum]);

  const handlePrevPage = useCallback(() => {
    props.getObjects.func(pageNum - 1);
    setPageNum(pageNum - 1);
  }, [props, pageNum]);

  const handleLastPage = useCallback(() => {
    props.getObjects.func(count - 1);
    setPageNum(count - 1);
  }, []);

  const handleFirstPage = useCallback(() => {
    props.getObjects.func(0);
    setPageNum(0)
  }, []);

  return (
    <BottomBar className={props.className} palette={props.theme}>
      {pageNum > 0 && (
        <>
          <Button onClick={handleFirstPage} palette={props.theme} style={{ marginLeft: "0" }}>
            <img style={{ width: "12px", height: "12px" }} src={svgTwoLeftArrow} />
          </Button>

          <Button onClick={handlePrevPage} palette={props.theme}>
            <img style={{ width: "12px", height: "10px" }} src={svgLeftArrow} />
          </Button>
        </>
      )}

      <p>Страница  {pageNum + 1}  из {count} </p>

      {pageNum + 1 <= count - 1 && (
        <>
          <Button onClick={handleNextPage} palette={props.theme}>
            <img style={{ width: "12px", height: "10px" }} src={svgRightArrow} />
          </Button>

          <Button onClick={handleLastPage} palette={props.theme}>
            <img style={{ width: "12px", height: "12px" }} src={svgTwoRightArrow} />
          </Button>
        </>
      )}
    </BottomBar>
  );
};

export default PageNav;
