import React, { useState, useCallback } from "react";
import styled from "styled-components";
import Collapsible from "react-collapsible";
import { Scrollbars } from "react-custom-scrollbars";
import Row from "./Row";
import SliderMUI from "./SliderMUI";
import "./MapLayers.css";
import { useEffect } from "react";

export const LayersWrapper = styled.div`
  box-sizing: border-box;
  
  .Collapsible {
    overflow: hidden;
    background: rgba(242, 242, 242, 1);
    box-shadow: 7.08px 7.08px 15px rgba(152, 152, 152, 0.35);
  }

  div.Collapsible span div {
    display: flex;
    align-content: center;
    align-items: center
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight:normal;
    white-space: break-spaces;
    color: rgb(255, 255, 255);
    background: linear-gradient(
      180deg,
      rgba(1, 97, 31, 1) 0%,
      rgba(0, 90, 47, 1) 100%
    );
    min-height: 32px;
    margin-bottom: 7px;
  }

  .Collapsible {
    .is-open {
      div {
        .expandedIcon {
          margin-right: 30px;
          border: 1px solid white;
          border-radius: 4px;
          color: white;
          transform: rotate(180deg);
          transition: transform 0.2s;
        }
      }
    }
    .is-closed {
      div {
        .expandedIcon {
          margin-right: 30px;
          border: 1px solid white;
          border-radius: 4px;
          color: white;
          transform: rotate(0deg);
          transition: transform 0.2s;
        }
      }
    }
  }  
   
  .scrollbar-view {
    max-height: ${props => props.maxHeight}vh !important;
  }
`;

export const Trigger = styled.div`
  width: 100%;
  padding: 5px 0px 5px 20px;
  background-color: ${props => props.palette.primary};
  display: flex;
  justify-content: space-between;
  align-items: center;
  svg {
    fill: white;
  }
`;

var opacity = {};


function EknMapLayers(props) {
  //  const [opacity, setOpacity] = useState({});
  const [sliderName, setSliderName] = useState(null);
  const [openSlider, setOpenSlider] = useState(false);
  const [expanded, setExpanded] = useState([
    true,
    true,
    true,
    true,
    true,
    true,
    true,
	true
  ]);
  // const [viewportOffset, setViewportOffset] = useState(null);
  const [opacityValue, setOpacityValue] = useState(null);
  const [opacityTop, setOpacityTop] = useState(null);
  const [opacityLeft, setOpacityLeft] = useState(null);

  props.bindOpenSlider(function (b) {
    setOpenSlider(b);
  });

  const handleOpacityChange = useCallback(
    (name, event, value) => {
      const x = [];
      //const value = parseInt(event.target.value);
      x[name] = value;
      opacity[name] = value;
      //setOpacity(Object.assign([], opacity, x));
      setOpacityValue(value);
      props.setLayerOpacity(name, value);
    },
    [props]
  );

  const handleExpanded = useCallback(
    (index, value) => {
      expanded[index] = value;
      setExpanded(Object.assign({}, expanded));
    },
    [expanded]
  );

  const handleVisibilityChange2 = useCallback(
    
    (group, item, check) => {
      if (group.exclusive) {
        group.layers.forEach(value => {
          if (value.name !== item.name) {
            props.layersVisibility[value.name] = false;
            props.showLayer(value, value.name, false, props.region);
          }
        });
      }
      
      // Скрыть/Показать слой
      let value = check===undefined ? !props.layersVisibility[item.name] : check;
      props.layersVisibility[item.name] = value;
      props.setLayersVisibility(Object.assign({}, props.layersVisibility));
      props.showLayer(item, item.name, value, props.region);

      if (item.layerChilds  && item.name!='ggk_invsupplyzone' && item.name!='ggk_borders' && item.name!='sogl') {
        item.layerChilds.map(el => {
          // handleVisibilityChange2(group, el, value)
   
          if (!props.standalone) {
            handleVisibilityChange2(group, el, value)
          } else {
            if (!el.name.includes("_archive")) {
              handleVisibilityChange2(group, el, value)
            }
          }
           
        })
      }
      if (item.name === 'sogl' && value) {
        ['sogl_agreed', 'sogl_rejected', 'sogl_registered'].forEach(childLayerName => {
          props.layersVisibility[childLayerName] = false;
          props.setLayersVisibility(Object.assign({}, props.layersVisibility));
          props.showLayer(null, childLayerName, false, props.region);
        });
      }

      if ((item.name === 'sogl_agreed' || item.name === 'sogl_rejected' || item.name === 'sogl_registered') && value) {
        props.layersVisibility['sogl'] = false;
        props.setLayersVisibility(Object.assign({}, props.layersVisibility));
        props.showLayer(null, 'sogl', false, props.region);
      }


      /*if (
        value &&
        (item.name === "bing" || item.name === "kazgeo" || item.id === "garysh")
      ) {
        props.setDark(true);
      } else if (value && item.name === "base") {
        props.setDark(false);
      }*/
    },
    [props]
  );

 

  const handleSliderClick = useCallback((name, e) => {
    setSliderName(name);
    setOpenSlider(true);
    setOpacityValue(opacity[name] ? opacity[name] : 100);

    var row = e.target;
    while (
      row != null &&
      row.className != null &&
      row.className.indexOf("layers-row-input") < 0
    )
      {
        row = row.parentElement;
      }

    var conteiner = row.parentElement.parentElement.parentElement.parentElement;
    // if (conteiner.className.indexOf("Collapsible") >= 0) 
    //   conteiner = conteiner.parentElement;

    //
    // if (conteiner.className.indexOf("Collapsible") >= 0 && conteiner.className.indexOf("Collapsible__contentInner")<0
    // && conteiner.className.indexOf("Collapsible__contentOuter")<0 && conteiner.className.indexOf("Collapsible__trigger")<0) {
    //   conteiner = conteiner.parentElement;
    // } else {
    //     while (conteiner.className!="Collapsible") {
    //       conteiner = conteiner.parentElement;
    //     }
    //     conteiner = conteiner.parentElement;
    // }
    while (conteiner.className!="Collapsible") {
      conteiner = conteiner.parentElement;
    }
    conteiner = conteiner.parentElement;

    // console.log(conteiner);

    

    setOpacityTop(row.offsetTop - conteiner.scrollTop);
    setOpacityLeft(row.offsetLeft + row.offsetWidth + 20);
    //setOpacityWindowTop(e.target.getBoundingClientRect().top);
  }, []);

  const style1 = {
    boxSizing: "border-box",
    zIndex: 4,
    position: "absolute",
    top: props.region ? "100px" : "285px",
    //top:   props.top ? props.top - 180 + "px" : "280px",
    left: props.show ? "295px" : props.responsive.mobile ? "55px" : "120px",
    width: props.region ? "400px" : "310px"
  };
  const style2 = {
    boxSizing: "border-box",
    zIndex: 4,
    position: "absolute",
    top: "50px",
    right: "120px",
    width: props.region ? "400px" : "310px"
  };
  const styleMob = {
    boxSizing: "border-box",
    zIndex: "4",
    position: "absolute",
    top: "62px",
    left: "0",
    width: "100%",
    height: "100%"
  };

  const renderRow = (item, j, group) => {
    return (
      <Row
        key={j}
        index={j}
        layer={props.layers[item.name]}
        sublayers={item.sublayers}
        layerChilds={item.layerChilds}
        theme={props.theme}
        child={item.parent}
        // Checkbox / Radio props
        isCheckbox={!group.exclusive}
        isChecked={!!props.layersVisibility[item.name]}
        checkHandler={
          ["zo"].includes(item.name)
            ? () => { }
            : () => handleVisibilityChange2(group, item)
        }
        // HandleRefresh props
        handleRefresh={props.handleRefresh}
        handleSetVersion={props.handleSetVersion}
        // LayerIcon props
        layerName={item.name}
        layerText={item.title}
        history={item.history}
        region={props.region}
        // ListButton props

        // hasListButton={
        //   props.standalone && ![
        //     "redlines",
        //     "func",
        //     "base",
        //     "bing",
        //     "kazgeo",
        //     "garysh",
        //     "lands_label",
        //     "buildings_label"
        //   ].includes(item.name)
        // }
        hasListButton={
          !item.name.startsWith("ggk_") &&
          ![
            "redlines",
            "func",
            "base",
            "bing",
            "kazgeo",
            "garysh",
            "lands_label",
            "buildings_label"
          ].includes(item.name)
        }
        listButtonVisible={!!props.layersVisibility[item.name]}
        listButtonHandler={() =>
          props.handleGetLayerObjects(item.name, !!props.layersVisibility["arrest"])
        }
        // OpacityButton props
        // menuId={`menu${item.id}`}
        menuId={`menu${j}`}
        handleOpacityButtonClick={e =>
          handleSliderClick(item.name, e)

        }
        opacityButtonId={`opacity-button-${j}`}
        tooltipType={props.dark ? "light" : "dark"}
        opacityValue={Math.round(
          opacity[item.name] ? opacity[item.name] : 100
        )}
        openSlider={openSlider && sliderName === item.name}

        //
        group={group}
        layersVisibility={props.layersVisibility}
        handleVisibilityChange2={handleVisibilityChange2}
        standalone={props.standalone}
        handleSliderClick={handleSliderClick}
        opacity={opacity}
        dark={props.dark}
        sliderName={sliderName}
        handleGetLayerObjects={props.handleGetLayerObjects}
        layers={props.layers}
        setOpenSlider={setOpenSlider}
        handleCloseSlider={setOpenSlider}
        //
        
      //base map

      
      />
    )
  }

  return (
    <>
      {props.open && (

        <LayersWrapper
          palette={props.theme}
          className="layers-wrapper"
          style={props.responsive.mobile ? styleMob : props.standalone ? style1 : style2}
          maxHeight={props.responsive.mobile ? 92 : 82}
        >
          {openSlider && (
            <SliderMUI
              standalone={props.standalone}
              theme={props.theme}
              handleCloseSlider={() => {
                setOpenSlider(false);
                setSliderName(null)
              }}
              defaultValue={opacityValue}
              handleSliderChange={handleOpacityChange.bind(this, sliderName)}
              opacityTop={opacityTop}
              opacityLeft={opacityLeft}
              sliderName={sliderName}
              showLayer={props.showLayer}
            />
          )}
          <Scrollbars
            autoHeight={true}
            autoHide={true}
            autoHeightMax={props.responsive.mobile ? "92vh" : "82vh"}
            renderView={props => <div {...props} className="scrollbar-view" />}
          >
            {props.layerConfig && props.layerConfig.map((group, i) => (
              <Collapsible
                key={i}
                transitionTime={200}
                trigger={
                  <Trigger palette={props.theme}>
                    {group.title}
                    <svg
                      className="expandedIcon"
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                    >
                      <path d="M12.44 6.44L9 9.88 5.56 6.44 4.5 7.5 9 12l4.5-4.5z" />
                    </svg>
                  </Trigger>
                }
                open={expanded[i]}
                onOpening={() => handleExpanded(i, true)}
                onClosing={() => handleExpanded(i, false)}
              >
                {group.layers.map((item, j) => (
                  !props.standalone ? renderRow(item, j, group) 
                  : item.name !== 'sogl' ? renderRow(item, j, group)  : <div key={j}></div>
                  // renderRow(item, j, group)
                ))}
              </Collapsible>
            ))}

          </Scrollbars>
        </LayersWrapper>
      )}
    </>
  );
}

export default EknMapLayers;
