import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Scrollbars } from "react-custom-scrollbars";
import svgDownload from '../../assets/img/download-table.svg';

export const Modal = styled.div`
    position: absolute;
    left: ${props => props.responsive.mobile ? "0" : "10%"};
    top: ${props => props.responsive.mobile ? "0" : "0%"};
    max-height: ${props => props.responsive.mobile ? "100%" : "900px"};
    width: ${props => props.responsive.mobile ? "100%" : "800px"};
    height: ${props => props.responsive.mobile ? "100%" : "550px"};
    background-color: #fff;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    z-index: 1;
    font-family: 'sf-pro-display-regular',sans-serif;
`;

export const ModalHeader = styled.div`
    .header {
        display: flex;
        height: 50px;
        margin-bottom: 15px;
        border-radius: 5px 5px 0px 0px;
        background: linear-gradient(180deg, #01C13D 0%, #00A824 100%);
        align-items: center;
        padding: 0px 80px 0px 20px;
        
        .title {
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 15px;
            color: #FFFFFF;
        }
    }
`;

export const ModalFooter = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin-top: 5px;

    button {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 10px 10px 0px;
        width: 93%;
        border-radius: 5px;
        color: #fff;
        height: 30px;
        border: none;
        outline: none;
        &:hover {
            cursor: pointer
        }
    }

    .cancel-btn {
        background: #777777;
        width: 90px;
    }
`;

export const ModalBody = styled.div`
    padding: 10px;

    table {
        border: 1px solid #B6D4C0;
        border-spacing: 0;
        width: 100%;
        margin-bottom: 10px;

        thead {
            tr {
                width: 100%;
                height: auto;
                display: flex;
                margin: 0;
                padding: 0;
                background: #fff;

                td {
                    padding: 3px 8px;
                    border: 1px solid #B6D4C0;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    background: #E0F0E5;
                    color: #008A78;
                }
            }
        }

        tbody {
            tr {
                width: 100%;
                height: 30px;
                display: flex;
                margin: 0;
                padding: 0;
                background: #fff;
                cursor: pointer;

                td {
                    padding: 3px 8px;
                    border: 1px solid #B6D4C0;
                    overflow: hidden;
                    position: relative;
                }
            }
        }

        tbody tr:hover {
            background: #D8EFE0; /* Цвет фона при наведении */
        }

        .even {
            background: #F8FFFA;
        }
    }

    button {
        margin: 0 10px 10px 0px;
        border-radius: 4px;
        color: #fff;
        height: 30px;
        border: none;
        outline: none;
        background-color: #de2027;
        &:hover {
            cursor: pointer
        }
    }

    .block-panel {
        display: inline-block;
        position: relative;
        border: 0.5px solid #828282;
        border-radius: 5px;
        padding: 12px 5px 10px 5px;
        text-align: center;
        height: auto;
        width: 100%;
        margin: 5px 0px;
    
        .border-word {
          font-size: 12px;
          color: #828282;
          background-color: #fff;
          position: absolute;
          padding: 0px 10px;
          top: -9px;
          left: 12px;
        }

        .cause {
            text-align: left;
        }
    }

    .table-btn-img {
        width: 36px;
        height: 26px;
        cursor: pointer;
        background-position: center !important;
    }
    .sub-table-btn {
        position: relative;
        div {
          display: flex;
          position: absolute;
          top: 4px;
          left: 30px
          font-size: 12px;
          padding: 3px 6px;
          width: max-content;
        }
    }
`;

export const OnTop = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    backdrop-filter: blur(3px);
    background-color: rgb(0 0 0 / 49%);
    z-index: 1000;
`;

export default function LecenseeViewOrder({
    order,
    setShowViewOrder,
    responsive
}) {
    const { t } = useTranslation();
    const [orderDocs, setOrderDocs] = useState([]);

    
    useEffect(() => {
        //запрашиваем документы
        fetch(
            `${window.REST_URL}/cabinet/order/` + order.id, {
                method: "GET",
                mode: "cors",
                credentials: "include",
            },
          )
            .then(response => response.json())
            .then(json => {
                const docs = json.docs.filter((item) => item.typeCode!=='ZKR41');
                setOrderDocs(docs);
            })
            .catch((err) => {
                console.log(err);
            });
	}, [])

    const downloadDoc = (fileName, isFile = false) => {
        if(isFile) {
            if(fileName.file) {
                let file = URL.createObjectURL(fileName.file);
                //window.location.assign(file);
                window.open(file, '_blank');
            } else {
                downloadDoc(fileName.fileName, false)
            }
        } else {
            return fetch(`${window.REST_URL}/upload/${order.id}?filename=${encodeURIComponent(fileName)}`, {
                method: "GET",
                mode: "cors",
                credentials: "include"
            })
			.then((res) => res.blob())
			.then((blob) => {
				let url = window.URL.createObjectURL(blob);
				let a = document.createElement('a');
				a.href = url;
            	a.download = fileName;
            	document.body.appendChild(a);
            	a.click();    
            	a.remove();
			});
        }
    }

    return (
        <OnTop>
            <Modal responsive={responsive}>
            <ModalHeader>
            <div className="header">
                <div className='title'>{t("zkr.exec-orders")}</div>
            </div>
            </ModalHeader>
            <Scrollbars
                autoHeight={false}
                autoHide={true}
                autoHeightMax={"45vh"}
                renderView={props => <div {...props} className="scrollbar-view" />}
            >
            <ModalBody responsive={responsive}>
                <table>
                    <tbody>
                        <tr>
                            <td style={{ width: "35%", background: '#E0F0E5' }}>{t("zkr.order-number")}</td>
                            <td style={{ width: "65%" }}>{order.id}</td>
                        </tr>
                        <tr>
                            <td style={{ width: "35%", background: '#E0F0E5' }}>{t("zkr.order-applicant")}</td>
                            <td style={{ width: "65%" }}>{order.bin || order.iin}</td>
                        </tr>
                        <tr>
                            <td style={{ width: "35%", background: '#E0F0E5' }}>{t("zkr.phone-number")}</td>
                            <td style={{ width: "65%" }}>{order.phone}</td>
                        </tr>
                        <tr>
                            <td style={{ width: "35%", background: '#E0F0E5' }}>{t("zkr.order-status")}</td>
                            <td style={{ width: "65%" }}>{order.statusName}</td>
                        </tr>
                        <tr>
                            <td style={{ width: "35%", background: '#E0F0E5' }}>{t("zkr.order-start")}</td>
                            <td style={{ width: "65%" }}>{order.timeStart}</td>
                        </tr>
                    </tbody>
                </table>

                {orderDocs && orderDocs.length > 0 && (
                    <div className='block-panel'>
                        <table>
                            <thead>
                                <tr>
                                    <td style={{ width: "5%" }}>№</td>
                                    <td style={{ width: "80%" }}>{t("zkr.name-doc")}</td>
                                    <td style={{ width: "15%" }}>{t("zkr.actions")}</td>
                                </tr>
                            </thead>
                            <tbody>
                            { orderDocs.map((item, i) => (
                                <tr key={i}>
                                    <td style={{ width: "5%" }}>{++i}</td>
                                    <td style={{ width: "80%", textAlign: "left" }}>{item.fileName}</td>
                                    <td style={{ width: "15%" }}>
                                        <div className="table-btn-img sub-table-btn" style={{ background: `url(${svgDownload}) no-repeat`, backgroundSize: "18px" }}
                                            onClick={(e) => {
                                                downloadDoc(item.fileName)
                                            }}
                                        >
                                            <div>
                                                <div></div>
                                                {t("zkr.download")}
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            )) }
                            </tbody>
                        </table>
                    
                        <span className="border-word">{t("zkr.docs")}</span>
                    </div>
                )}

                {order.cause &&
                    <div className="order-col">
                        <div className='block-panel'>
                            <div className='cause'>{order.cause}</div>
                            <span className="border-word">{t("zkr.justification")}</span>
                        </div>
                    </div>
                }

                
            </ModalBody>
            </Scrollbars>
            <ModalFooter responsive={responsive}>

                <button
                    className="cancel-btn"
                    href=""
                    onClick={() => {
                        setShowViewOrder(false)
                    }}
                >
                    {t("request.cancel")}
                </button>
            </ModalFooter>

            </Modal>
        </OnTop>
    );
}
