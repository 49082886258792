import React, { useState, useEffect, useContext } from 'react';
import { LinkButton, ComboBox, Tree } from 'rc-easyui';
import ClipLoader from "react-spinners/ClipLoader";
import { useTranslation } from 'react-i18next';
import { NotificationContext } from "../EknMap";
import * as Constants from './Constants';
import * as DrawChart from './DrawChart';
import "../assets/css/analytic.css";

export default function Analytic(props) {
    const [chartData, setChartData] = useState([]);
    const [showLegend, setShowLegend] = useState(true);
    const xAxes = props.xAxes;
    const yAxes = props.yAxes;
    const [parentXId, setParentXId] = useState(props.parentXId);
    const [parentYId, setParentYId] = useState(props.parentYId);
    const [prevParentXId, setPrevParentXId] = useState(props.parentXId);
    const [prevParentYId, setPrevParentYId] = useState(props.parentYId);
    const fact = props.fact;
    const aggType = props.aggType;
    const aggField = props.aggField;
    const [type, setType] = useState(props.type);
    const [prevType, setPrevType] = useState(props.type);
    const [chart, setChart] = useState();
    const [nodesNamesX, setNodesNamesX] = useState([]);
    const [nodesNamesY, setNodesNamesY] = useState([]);
    const [prevNodesX, setPrevNodesX] = useState([]);
    const [prevNodesY, setPrevNodesY] = useState([]);
    const [selectedNodesX, setSelectedNodesX] = useState(props.nodesNamesX);
    const [selectedNodesY, setSelectedNodesY] = useState(props.nodesNamesY);
    const [expanded, setExpanded] = useState(false);
    const [content, setContent] = useState(Constants.EMPTY_DATA);
    const { t } = useTranslation();
    const { setNotification } = useContext(NotificationContext);

    useEffect(() => {
        return () => {
            if (chart)
                chart.dispose();
        }
    }, [chart]);

    const hideShowBtn = (expand) => {
        setExpanded(expand);
    }

    const drawChart = (chartData, nodesNamesX, nodesNamesY, type) => {
        let i;
        for (i in chartData) {
            var node = nodesNamesX[i];
            if (type === "LINE") {
                if (node.id[0] === 'y') {
                    chartData[i][Constants.nameX] = new Date((node.name || node.text));
                } else if (node.id[0] === 'q') {
                    chartData[i][Constants.nameX] = new Date(node.id.substring(1, 5), node.id.substring(5) - 1);
                } else {
                    setContent(Constants.NO_DATE);
                    return;
                }
            } else {
                chartData[i][Constants.nameX] = (node.name || node.text);
            }
            if (type === "PIE" || type === "DONUT") {
                chartData[i]['color'] = node.color;
            }
            else {
                let j;
                for (j in nodesNamesY) {
                    chartData[i]['color' + j] = nodesNamesY[j].color;
                }
            }
        }
        content !== Constants.EMPTY_DATA && setContent(Constants.FULL_DATA);
        setChart(DrawChart.createChart(chartData, nodesNamesY, type, showLegend));
    };

    const loadAnalytic = (chartData, nodesNamesX, nodesNamesY) => {
        setPrevNodesX(nodesNamesX);
        setPrevNodesY(nodesNamesY);
        setPrevParentXId(parentXId);
        setPrevParentYId(parentYId);

        var empty = true;
        let i;
        for (i in chartData) {
            let j;
            for (j in chartData[i]) {
                if (chartData[i][j]) {
                    empty = false;
                    break;
                }
            }
        }

        setChartData(chartData);

        if (chart) {
            chart.dispose();
        }

        props.setAnalyticLoading(false);

        if (empty) {
            setContent(Constants.EMPTY_DATA);
            return;
        }
        setContent(Constants.FULL_DATA);
        drawChart(chartData, nodesNamesX, nodesNamesY, type);
    };

    const toggleLegClick = () => {
        if (chart.legend.disabled) {
            chart.legend.disabled = false;
            setShowLegend(true);
        }
        else {
            chart.legend.disabled = true;
            setShowLegend(false);
        }
    };

    const handleXSelectNodesChange = (nodes) => {
        setSelectedNodesX(nodes);
    };

    const handleYSelectNodesChange = (nodes) => {
        setSelectedNodesY(nodes);
    };

    const applyChanges = async () => {
        if (JSON.stringify(selectedNodesX.map(e => e.id)) !== JSON.stringify(prevNodesX.map(e => e.id))
            || JSON.stringify(selectedNodesY.map(e => e.id)) !== JSON.stringify(prevNodesY.map(e => e.id))) {

            if (selectedNodesX.length < 1 || selectedNodesY < 1) {
                setNotification({
                    title: t("notif.meters-title"),
                    text: t("notif.meters-text"),
                })
                return;
            }
            setPrevType(type);
            props.setAnalyticLoading(true);
            setContent(Constants.EMPTY_DATA);
            props.handleStats(parentXId, parentYId, fact, aggType, aggField, xAxes, yAxes, type, selectedNodesX, selectedNodesY);
        } else if (type !== prevType) {
            if (chart) {
                chart.dispose();
            }
            drawChart(chartData, prevNodesX, prevNodesY, type);
        }
    };

    const loadNodes = (value, setNodes, selectedNodes, setSelectedNodes, isX) => {
        fetch(`${window.OLAP_URL}/dim/dimension/${value}?fact=${fact}&level=0&region_code=00`, {
            method: "GET",
            mode: "cors"
        })
            .then(response => response.json())
            .then(json => {
                let tmpNodes = json.map(e => {
                    return {
                        "id": e.id,
                        "text": e.name,
                        "name": e.name,
                        "isLeaf": e.isLeaf,
                        "color": e.color,
                        "state": e.isLeaf ? "" : "closed",
                        "checkState": selectedNodes.find(sel => sel.id === e.id) || (value === 2185641254 && isX) ? "checked" : "unchecked",
                        "position": e.position
                    };
                });
                setNodes(tmpNodes);
                setSelectedNodes(tmpNodes);
            });
    };

    const handleXParentNodeChange = (value) => {
        let newParent = xAxes.find(e => e.id === value);
        setParentXId(newParent);
        loadNodes(value, setNodesNamesX, selectedNodesX, setSelectedNodesX, true);
    };

    const handleYParentNodeChange = (value) => {
        let newParent = yAxes.find(e => e.id === value);
        setParentYId(newParent);
        loadNodes(value, setNodesNamesY, selectedNodesY, setSelectedNodesY, false);
    };

    const addNode = (tree, node, data, selectedNodes) => {
        let i;
        for (i in tree) {
            if (tree[i].children) addNode(tree[i].children, node, data);
            if (tree[i].id != node.id) continue;
            let childNodes = [];
            let j;
            for (j in data) {
                var child = {};
                child.id = data[j].id;
                child.text = data[j].name;
                child.state = data[j].isLeaf ? 'open' : 'closed';
                child.color = data[j].color;
                child.checkState = selectedNodes.find(sel => sel.id === data[j].id) ? "checked" : "unchecked";
                childNodes.push(child);
            }
            tree[i].children = childNodes;
            tree[i].state = 'open';
            break;
        }
    };

    const handleLoadNode = (node, nodesNames, setNodesNames, selectedNodes) => {
        fetch(`${window.OLAP_URL}/dim/dimension/${node.id}?fact=${fact}&level=0&region_code=00`, {
            method: "GET",
            mode: "cors"
        })
            .then(response => response.json())
            .then(json => {
                addNode(nodesNames, node, json, selectedNodes)
                setNodesNames(nodesNames);
            });
    }

    const handleLoadNodeX = (node) => {
        handleLoadNode(node, nodesNamesX, setNodesNamesX, selectedNodesX)
    };

    const handleLoadNodeY = (node) => {
        handleLoadNode(node, nodesNamesY, setNodesNamesY, selectedNodesY)
    };

    const handleChartTypeChange = (value) => {
        setType(value);
        props.handleType(value);
    };

    const clearNodes = e => {
        if (e.children) {
            e.children.map(clearNodes);
        }
        e.checkState = "unchecked";
        return e;
    };

    const selectNodes = e => {
        e.checkState = "checked";
        return e;
    };

    const clearDimX = () => {
        setNodesNamesX(nodesNamesX.map(clearNodes));
        setSelectedNodesX([]);
    };

    const clearDimY = () => {
        setNodesNamesY(nodesNamesY.map(clearNodes));
        setSelectedNodesY([])
    };

    const selectDimX = () => {
        let newNodesX = nodesNamesX.map(selectNodes);
        setNodesNamesX(newNodesX);
        setSelectedNodesX(newNodesX);
    };

    const selectDimY = () => {
        let newNodesY = nodesNamesY.map(selectNodes);
        setNodesNamesY(newNodesY);
        setSelectedNodesY(newNodesY);
    };

    useEffect(() => {
        loadAnalytic(props.chartData, props.nodesNamesX, props.nodesNamesY);
    }, [props.chartData])

    return <div className="analytic-wrapper">
        <table className="full-size">
            <tbody>
                <tr>
                    <td width="2%" className="full-height">
                        <div className="full-height accordion-v" id='acc_pkk' multi='0'>
                            <table className="analytic-acc">
                                <tbody className="full-height">
                                    <tr>
                                        <td className={"bcg-coll-pan analytic-coll-pan" + (expanded ? " active-cursor" : " disable-cursor")}>
                                            <div>
                                                <span
                                                    className={"ttl-coll-pan-v arr-parent"}
                                                    id='t0acc_pkk'
                                                    onClick={() => hideShowBtn(true)}
                                                    style={{ display: expanded ? "none" : "block" }}
                                                >
                                                    <div>
                                                        <span className="acc-arrow arrow-right"></span>
                                                        <span className='acc-arrow arrow-right'></span>
                                                    </div>
                                                </span>
                                            </div>
                                        </td>
                                        <td className="full-height">
                                            <div
                                                className={"accord cnt-coll-pan-v analytic-cnt-coll-pan" + (expanded ? " expanded-v" : "")}
                                                id="cnt0acc_pkk"
                                            >
                                                <div className="analytic-dimension-title">
                                                    <p className="analytic-title-text">ВЫБЕРИТЕ ТИП ДИАГРАММЫ</p>
                                                    <span
                                                        className="arrow-width"
                                                        onClick={() => hideShowBtn(false)}
                                                    >
                                                        <span className="acc-arrow arrow-left"></span>
                                                        <span className="acc-arrow arrow-left"></span>
                                                    </span>
                                                </div>
                                                <hr />
                                                <ComboBox
                                                    data={Constants.types}
                                                    className="inherit-width"
                                                    onChange={handleChartTypeChange}
                                                    value={props.type}
                                                />
                                                <hr />
                                                <div className="analytic-dimension-title">
                                                    <p className="analytic-title-text">ВЫБЕРИТЕ ИЗМЕРИТЕЛИ</p>
                                                </div>
                                                <hr />
                                                <div className="dimension">
                                                    <p className="dimension-text">Измерители X</p>
                                                    <div>
                                                        <button
                                                            title="Снять все"
                                                            onClick={clearDimX}
                                                        >
                                                            <div className="icon-clearAll" />
                                                        </button>
                                                        <button
                                                            title="Выбрать корневые"
                                                            onClick={selectDimX}
                                                        >
                                                            <div className="icon-selectAll"></div>
                                                        </button>
                                                    </div>
                                                </div>
                                                <ComboBox
                                                    data={xAxes}
                                                    className="inherit-width"
                                                    onChange={handleXParentNodeChange}
                                                    value={props.parentXId}
                                                />
                                                <hr />
                                                <Tree
                                                    data={parentXId.id !== 2185641254 ? nodesNamesX : []}
                                                    className="analytic-tree"
                                                    checkbox
                                                    cascadeCheck={false}
                                                    onCheckChange={handleXSelectNodesChange}
                                                    onNodeExpand={handleLoadNodeX}
                                                />
                                                <hr />
                                                <div className="dimension">
                                                    <p className="dimension-text">Измерители Y</p>
                                                    <div>
                                                        <button
                                                            title="Снять все"
                                                            onClick={clearDimY}
                                                        >
                                                            <div className="icon-clearAll" />
                                                        </button>
                                                        <button
                                                            title="Выбрать корневые"
                                                            onClick={selectDimY}
                                                        >
                                                            <div className="icon-selectAll" />
                                                        </button>
                                                    </div>
                                                </div>
                                                <ComboBox
                                                    data={yAxes}
                                                    className="inherit-width"
                                                    onChange={handleYParentNodeChange}
                                                    value={props.parentYId}
                                                />
                                                <hr />
                                                <Tree
                                                    data={nodesNamesY}
                                                    className="analytic-tree"
                                                    checkbox
                                                    cascadeCheck={false}
                                                    onCheckChange={handleYSelectNodesChange}
                                                    onNodeExpand={handleLoadNodeY}
                                                />
                                                <hr />
                                                <LinkButton
                                                    className="analytic-apply"
                                                    onClick={applyChanges}
                                                >
                                                    Применить
                                                </LinkButton>
                                                <LinkButton
                                                    className="analytic-apply show-hide"
                                                    onClick={toggleLegClick}
                                                >
                                                    {(showLegend ? "Скрыть" : "Показать") + " легенду"}
                                                </LinkButton>
                                                <hr />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </td>
                    <td className="full-height">
                        {props.analyticLoading ? <div className="center-content"><ClipLoader color={"#02c23e"} loading={true} /></div> :
                            content === Constants.EMPTY_DATA ?
                                <p className="center-content">НЕТ ДАННЫХ ДЛЯ ОТОБРАЖЕНИЯ</p> :
                                content === Constants.NO_DATE ?
                                    <p className="center-content">В ЛИНЕЙНОЙ ДИАГРАММЕ ИЗМЕРИТЕЛЬ X ДОЛЖЕН БЫТЬ ПЕРИОДОМ</p> :
                                    <p className="axes-title">{prevParentXId.text + ' / ' + prevParentYId.text}</p>
                        }
                        <div
                            onClick={() => hideShowBtn(false)}
                            className="or3-analytic"
                            id="pkk"
                            style={{ height: (content === Constants.FULL_DATA) ? '100%' : '0%' }}
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
}