import React from "react";
import styled from "styled-components";
import Collapsible from "react-collapsible";
import { Scrollbars } from "react-custom-scrollbars";

const RegionSelect = styled.div`
  width: ${props => props.width ? props.width : props.responsive.mobile ? "100%" : "305px"};
  z-index: ${props => props.responsive.mobile ? "5" : "1"};
  top: ${props => props.top ? props.top : props.responsive.mobile ? "62px" : "42px"};
  height: ${props => props.responsive.mobile ? "100%" : "auto"};

  box-sizing: border-box;
  position: absolute;
  right: 0px;
  overflow: hidden;
  background: #ffffff;
  background: rgba(255, 255, 255, 1);
  border-style: Solid;
  border-color: #c9d1de;
  border-color: rgba(201, 209, 222, 1);
  border-width: 1px;
  border-radius: 7px;
  -moz-border-radius: 7px;
  -webkit-border-radius: 7px;

  .Collapsible:last-child {
    padding-bottom: 8px;
  }

  .scrollbar-view {
    max-height: ${props => props.mh ? props.mh : "calc(100vh - 70px)"} !important;
    // max-height: calc(100vh - 70px) !important;
  }
`;

export default function ChooseRegion(props) {
  const byName = (field) => {
    return (a, b) => a[field].toLowerCase() > b[field].toLowerCase() ? 1 : -1;
  }
  return (
    <RegionSelect palette={props.theme} responsive={props.responsive} top={props.top} width={props.width} mh={props.mh}>
      <Scrollbars
        className="select-region-div"
        autoHeight={true}
        autoHide={true}
        autoHeightMax={"calc(100vh - 70px)"}
        renderView={props => <div {...props} className="scrollbar-view" />}
      >
        {props.regions && props.regions.map(region => (
          <Collapsible
            key={region.code}
            trigger={
              <div>
                {props.lang === "kz"
                  ? region.nameKz
                  : props.lang === "ru"
                    ? region.nameRu
                    : region.nameEn}
              </div>
            }
            transitionTime={200}
            easing="ease-out"
          >
            {region.districts.sort(props.lang === "kz" ? byName('nameKz') : props.lang === "ru" ? byName('nameRu') : byName('nameEn')).map(district => (
              <p
                key={region.code + district.code}
                onClick={(e) => {
                  props.setRegion(district);
                }}
              >
                 {props.lang === "kz"
                  ? district.nameKz + " " + district.type_kz
                  : props.lang === "ru"
                  ? district.type + " " + district.nameRu
                  : district.type + " " + district.nameEn
                }
              </p>
            ))}
          </Collapsible>
        ))}
      </Scrollbars>
    </RegionSelect>
  );
}
