import React from 'react'
import styled from "styled-components";
import { Scrollbars } from "react-custom-scrollbars";
import { Icon } from './UserProfile';
import svgExit from '../../assets/img/exit.svg';
import { withTranslation } from 'react-i18next';

export const List = styled.div`
    position: absolute;
    left: ${props => props.responsive.mobile ? "0" : "50%"};
    top: ${props => props.responsive.mobile ? "0" : "50%"};
    transform: ${props => props.responsive.mobile ? "0" : "translate(-50%, -50%)"};
    max-height: ${props => props.responsive.mobile ? "100%" : "500px"};
    height: ${props => props.responsive.mobile ? "100%" : "auto"};
    width: ${props => props.responsive.mobile ? "100%" : "800px"};
    background-color: #fff;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    z-index: 1;
    padding: 11px;
    font-family: 'sf-pro-display-regular',sans-serif;

    .selectable {
        cursor: pointer;
    }

    .selectable:hover {
        background: #D8EFE0;
    }

    .header {
        display: flex;
        height: 30px;
        margin-bottom: 15px;
        
        h2 {
            font-size: 14px;
            font-weight: normal;
            width: -webkit-fill-available;
            width: -moz-available;
            margin: 0 13px 0 0px;
            font-size: 16px;
            background: #009856;
            padding: 6px 0 0 10px;
            color: #fff;
            border-radius: 5px;
        }

        button {
            width: 30px;
            background: #005A4D;
            outline: none;
            border-radius: 5px;
            border: none;

            div {
                width: 15px !important;
                height: 15px !important;
                margin: 0 !important;
            }
        }
    }

    .table {
        .wrapper {
            .scrollbar {
                min-height: initial !important;
                height: auto;
                max-height: ${props => props.responsive.mobile ? "calc(100vh - 60px)" : "433px"} !important;

                .scrollbar-view {
                    max-height: ${props => props.responsive.mobile ? "calc(100vh - 60px)" : "433px"} !important;
                }

                div {
                    min-height: initial !important;

                    .tbody {
                        border: 1px solid #B6D4C0;
                        border-radius: 5px;

                        .tr {
                            .td {
                                padding: 7px 0px 7px 15px;
                                border: 1px solid #B6D4C0;
                                color: #26602D;
                            }
                        }

                        .even {
                            background: #F8FFFA;
                        }

                        .even:hover {
                            background: #D8EFE0;
                        }
                    }
                }
            }
        }
    }
`;

export const OnTop = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    backdrop-filter: blur(3px);
    background-color: rgb(0 0 0 / 49%);
    z-index: 1000;
 `;

class ListService extends React.Component {

    componentDidMount() {
        this.props.handleService();
    }

    handleServiceSelect(item) {
        if (!this.props.order.services.find(i => i.code === item.code)) {
            this.props.order.services=[];
            this.props.order.services.push(item);
            this.props.handleDocsTypeAll(item.code);
            // let childrens = this.props.service.filter(i => i.parentCode === item.code);
            // childrens.map(i => this.props.order.services.push(i));
            // this.props.setOrder(JSON.parse(JSON.stringify(this.props.order)));
            this.props.setOrder(Object.assign({},  this.props.order))
            this.props.setShowListService(false);
        }
    }

    render() {
        const { t } = this.props;
        return (
            <OnTop>
                <List responsive={this.props.responsive}>
                    <div className="header">
                        <h2>{t('new-obj.choose-service')}</h2>
                        <button onClick={() => this.props.setShowListService(false)}>
                            <Icon url={svgExit} />
                        </button>
                    </div>
                    <div>
                        <div className="table">
                            <div className="wrapper">
                                <Scrollbars
                                    className="scrollbar"
                                    style={{}}
                                    autoHeight
                                    autoHide
                                    autoHeightMin={300}
                                    autoHeightMax={450}
                                    renderView={props => <div {...props} className="scrollbar-view" />}
                                >
                                    <div className="tbody">
                                        {this.props.service.filter(item => {
                                            return item.show && !this.props.order.services.find(i => i.code === item.code)
                                                && (!this.props.order.services.length || item.code !== "807000");
                                        }).map((item, i) => (
                                            <div
                                                onClick={() => this.handleServiceSelect(item)}
                                                className={"tr selectable" + (i % 2 === 1 ? " even" : "")}
                                                key={i}
                                                style={{
                                                    display: "grid",
                                                    gridTemplateColumns: `repeat(${1}, 1fr)`,
                                                }}
                                            >
                                                <div className={"td"}>{item.name}</div>
                                            </div>
                                        ))}
                                    </div>
                                </Scrollbars>
                                <div className="tfoot">
                                    <div
                                        className="tr"
                                        key={this.props.service.length}
                                        style={{
                                            display: "grid",
                                            gridTemplateColumns: `repeat(${1}, 1fr)`,
                                        }}
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </List>
            </OnTop>
        );
    }
}

export default withTranslation()(ListService);