const getCoordSystem = srs => {
  srs = srs.toString();
  if (/(326)(\d{2})$/g.test(srs)) {
    return srs.replace(/(326)(\d{2})$/g, "WGS 84 / UTM-зона $2N");
  } else {
    return "WGS 84";
  }
};

export default getCoordSystem;
