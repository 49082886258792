import React from "react";
import styled from "styled-components";

const SelectComp = styled.select`
  background: ${props => props.palete.secondary};
  color: ${props => props.palete.text};
  outline: none;
  border: none;
  font-size: 14px;
  padding-bottom: 2px;
  border-bottom: 2px solid ${props => props.palete.turq};
  option {
    background: ${props => props.palete.secondary};
    color: ${props => props.palete.text};
    padding: 0;
    font-size: 12px;
  }
  option:hover {
    color: ${props => props.palete.turq};
  }
`;

export default function Select(props) {
  return (
    <SelectComp
      palete={props.theme}
      onChange={e => props.handleSetVersion(e.target.value)}
    >
      {props.history.map((layer, index) => (
        <option key={layer} value={layer}>
          {props.layerText} {layer}
        </option>
      ))}
    </SelectComp>
  );
}
