import React, { useContext } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import comboImg from "../../../assets/img/combo.svg";
import { MODE_DRAW, MODE_SELECT, RegionContext, MODE_SELECT_PDP, MODE_EDIT, MODE_OFF } from "../../../EknMap";
import i18n from "../../../i18n";
import ChooseRegion from "../../ChooseRegion";
import ChooseAto from "../../ChooseAto";
import { useEffect} from "react";
import { getAte, getSo, getCategory, documentCreate, postJson, getSvg, postService, checkUl, checkFl, getServiceXml, download, getDocNum } from "../../../utils/RestApi";
import Coordinates from "../Coordinates";
import { WKT, GeoJSON } from "ol/format";
import {matchScale} from "../../../util";
import { signText } from "../../../NCALayerOps";
import Select from 'react-select'
import { NotificationContext } from "../../../EknMap";
import { Feature } from "ol";

const EgovServiceBtn = styled.button`
  border: none;
  outline: none;
  // background-color: #008B78;
  background-color: ${props => props.color ? props.color : "#008B78"};
  // display: block;
  width: 100%;
  margin-top: 12px;
  border-radius: 6px;
  font-size: 14px;
  color: #fff;
  padding: 10px;

  &:hover {
    cursor: pointer;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const ModalInfo = styled.div`
  

  .area_inform_content {
    background-color: #fff;
    width: 500px;
    height: auto;
    padding-bottom: 10px;
  }
  h3 {
    text-align: left; 
    padding: 10px 5px 5px 10px;
    background: #009856;
    color: #fff;
    font-size: 16px;
  }
  p {
    padding: 5px;
    padding-left: 10px
  }

  .area-confirm {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }
  .doc-num {
    margin: 0
  }
  .area-confirm_btn {
    border: none;
    outline: none;
    background-color: #008B78;
    width: 50px;
    border-radius: 6px;
    font-size: 12px;
    color: #fff;
    padding: 5px;
    margin-right: 5px;

    &:hover {
    cursor: pointer;
    }
  }
  .area-confirm_btn.error {
    width: 80px;
  }
`
const CheckBoxContainer = styled.div`
  font-size: 14px;
  font-weight: normal;
  background: #009856;
  color: #fff;
  padding: 7px 15px;
  height: 33px;
  margin-top: 12px;
  margin-bottim: 12px;
  position: relative;
  display: flex;
  align-items: center;

  label {
    color: #fff;
    display: flex;
    align-items: center;
  }
  input {
    border: 0.05em solid white;
    border-radius: 0.15em;
    cursor: pointer;
    opacity: 0;
    position: absolute;
    height: 1.5em;
    width: 1.5em;
  }
  input:checked {
    &+label::before {
      content: "\\002714";
      display: flex;
      justify-content:center;
      align-items: center;
    }
  }
  label::before {
    content: '';
    border: 0.05em solid #fff;
    height: 1.45em;
    width: 1.45em;
    border-radius: 0.15em;
    margin-right: 0.5em;
    background-color: #009856;
    margin-left: 4px;
  }
`
export const EgovServiceWidget = styled.div`
    min-width: initial;
    max-width: initial;
    position: ${props => props.coordinatesActive || props.bdError || props.bdSuccess ? "static" : "absolute"};
    width: ${props => props.responsive.mobile ? "100%" : "530px"};
    max-height: 85vh;
	  top: ${props => props.responsive.mobile ? "62px" : "90px"};
    right: 30px;
    background-color: #d1e8d9;
    padding: 6px 12px;
    border-radius: 7px;
    font-family: 'sf-pro-display-regular',sans-serif;
    font-size: 14px;
    box-shadow: 14px 14px 20px #00000036;
    z-index: 4;
    overflow-y: auto;
    
    .fld-region {
      margin-right: 0px;
    }
    .draw-error {
      background: #E8D3D3;
      color: #C1272D;
      padding: 5px 15px;
      font-size: 15px;
    }
    .input-wsub {
      border-radius: 0 0 6px 6px;
      border-left: none;
      padding-right: 15px;
      max-width: 526px;
    }
    .input-sub {
      border-radius: 0 0 6px 6px;
      border-bottom-right-radius: 0;
      max-width: 526px;
      outline:none;
      cursor: pointer;
    }
    .header-combo-btn {
      border-radius: 0 0 6px 0;
      border-right: none;
    }
    .header-combo-btn-ct {
      height: 75px;
    }
    h2 {
      font-size: 14px;
      margin-bottom: 0px;
      font-weight: normal;
      background: #009856;
      color: #fff;
      padding: 7px 0px;
      text-align: center;
    }
    .area_inform {
      position absolute;
      top: 0;
      left 0;
      width: 100%;
      height: 100%;
      transform: scale(0);
      -webkit-backdrop-filter: blur(3px);
      backdrop-filter: blur(3px);
      background-color: rgb(0 0 0 / 49%);
      z-index: 15;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .area_inform.active {
      transform: scale(1);
    }
    .kadnum-list {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }
    .list-wrapper {
      position: relative
    }
    .add-list-btn {
      position: absolute;
      top: 5px;
      color: #fff;
      background: #009856;
      border: none;
      left: 10px;
    }
    .service-title {
      font-size: 16px;
      padding: 7px;
    }
    textarea {
      outline: none;
      resize: none;
      font-size: 15px;
      padding-top: 10px;
      padding-bottom: 10px;
      height: 95px;
    }
    .input-sub-ct {
      height: 75px;
    }
    .fid-ta {
      margin-bottom: 75px;
    }
    .fid-ct {
      margin-bottom: 55px;
    }
    .scheme-btns {
      display: flex;
      gap: 5px;
    }
    .fa-check {
      color: #009856;
      font-size: 25px;
    }
    .fa-xmark {
      color: red;
      font-size: 25px;
    }
    .iin-valid {
      position: absolute;
      right: 10px;
      top: 3px;
    }
    .sign-loading {
      margin-top: 5px;
      text-align: center;
    }
    .select_test {
      font-size: 16px;
      background: #D9F2E1;
      color: #466E5D;
    }
    .segments {
      border: 1px solid rgb(193, 39, 45);
      padding: 0 10px 10px 10px;
    }
    .segment-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;

      .segment-btn {
        background: linear-gradient( 180deg, rgba(247, 147, 30, 1) 0%, rgba(241, 90, 36, 1) 100% );
        border: none;
        border-radius: 5px;
        padding: 3px 10px;
        outline: none;
        color: #fff;
      }
    }
    .segment-btn-all {
      width: 100%;
      background: linear-gradient(rgb(2, 194, 62) 0%, rgb(0, 168, 36) 100%);
      border: none;
      outline: none;
      padding: 5px;
      color: #fff;
    }
`;

const useInput = (initialValue, validations) => {
  const[value, setValue] = React.useState(initialValue);
  const[isDirty, setDirty] = React.useState(false);
  const valid = useValidation(value, validations);


  const onChange = (e) => {
      setValue(e.target.value);
  }

  const onChangeName = (name) => {
    setValue(name);
  }

  const onBlur = () => {
    setDirty(true);
  }

  const resetValues = () => {
    setValue('');
    setDirty(false);
  }

  return {
    value,
    onChange,
    onChangeName,
    onBlur,
    resetValues,
    isDirty,
    ...valid
  }
}
const useValidation = (value, validations) => {
  const { t } = useTranslation();
  const[inputValid, setInputValid] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [errorName, setErrorName] = React.useState('');
  const[valid, setValid] = React.useState(false);

  useEffect(() => {
    for (const validation in validations) {
      if (validation === 'isEmpty') {
        if (!value) {
          setError(true);
          setErrorName(t("egov-service.empty-error"))
          break;
        } else {
          setError(false);
          continue;
        }
      } else if (validation === 'minLength') {
        if (value.length < validations[validation]) {
          setError(true);
          setErrorName(t("egov-service.min-length-error") + ` - ${validations[validation]}` );
          break;
        } else {
          continue;
        }
      } else if (validation === 'maxLength') {
        if (value.length > validations[validation]) {
          setError(true);
          setErrorName(t("egov-service.max-length-error") + ` - ${validations[validation]}` );
          break;
        } else {
          continue;
        }
      } else if (validation === 'valid') {
        if (value.length===12) {
          setValid(false);
          setError(false);
          setErrorName('');
          if (value.substring(4, 5)==0 || value.substring(4, 5)==1 || value.substring(4, 5)==2 || value.substring(4, 5)==3) {
            checkFl(value).then(result => {
              if (result.resultCode==='0') {
                setValid(true);
              } else {
                setValid(false);
                setError(true);
                setErrorName(result.resultMessage);
              }
            });
          } else {
            checkUl(value).then(result => {
              if (result.resultCode==='1') {
                setValid(true);
              } else {
                setValid(false);
                setError(true);
                setErrorName(result.resultMessage);
              }
            });
          
          }
        } 
      }
    }
  }, [value])

  useEffect(() => {
    if (error) {
      setInputValid(false)
    } else {
      setInputValid(true)
    }
  }, [error])

  return {
    inputValid,
    valid,
    error,
    errorName
  }
}

export function EgovServiceCost({
  handleSelect, 
  service, 
  regions, 
  openRegionMaxHeight, 
  responsive, 
  setEgovService, 
  setMode, 
  features, 
  setFeatures, 
  errors, 
  setErrors, 
  fileFormatNote, 
  checkFileFormat, 
  layers,
  map,
  handleLoadFeature,
  serviceId
  }) {
  const { t } = useTranslation();
  const [publicService, setPublicService] = React.useState({area: ''});
  const { region, setRegion } = useContext(RegionContext);
  const [regionCheck, setRegionCheck] = React.useState(false);
  const [openRegion, setOpenRegion] = React.useState(false);
  const [openAte, setOpenAte] = React.useState(false);
  const [openSo, setOpenSo] = React.useState(false);
  const [ate, setAte] = React.useState([]);
  const [so, setSo] = React.useState([]);
  const [ateName, setAteName] = React.useState('');
  const [soName, setSoName] = React.useState('');
  const [openCategory, setOpenCategory] = React.useState(false);
  const [categoryChoose, setCategoryChoose] = React.useState('');
  const [category, setCategory] = React.useState([]);
  const [coordinatesActive, setCoordinatesActive] = React.useState(false);
  const [bdError, setBdError] = React.useState(false);
  const [bdSuccess, setBdSuccess] = React.useState(false);
  const [methodName, setMethodName] = React.useState('');
  const [openMethod, setOpenMethod] = React.useState(false);
  const [errIntersect, setErrIntersect] = React.useState();
  const { setNotification } = useContext(NotificationContext);
  const source = layers.select.getSource();
  const [segments, setSegments] = React.useState([]);
  const [showSegment, setShowSegment] = React.useState(false);



  const regionNote = () => {
    setNotification({
      title: t("service.region-error-title"),
      text: t("service.region-error-text")
    })
  }

  const ateNote = () => {
    setNotification({
      title: t("notif.attention"),
      text: "Выберите сельский округ или населенный пункт чтобы продолжить"
    })
  }

  const removeSegments = () => {
    source.getFeatures().forEach(feature => {
      if (feature.get("type") === "segment") {
        source.removeFeature(feature);
      }
    });
  }

  useEffect(() => {  
    if (features.length>0 && methodName===t("egov-service.draw-scheme")) {
      publicService.area=(features[0].getGeometry().getArea() /10000).toFixed(4);

      publicService.geometry=new WKT().writeGeometry(features[0].getGeometry());

      const scale = matchScale(features[0].getGeometry(), [200,500,1000,2000,5000,25000,10000,50000,100000,200000,300000,500000,1000000], 425, 380, 20);
      publicService.scale=scale;

      setPublicService(Object.assign({}, publicService));

      const part = {
          geom: publicService.geometry,
          width: 425,
          height: 380,
          scale: scale,
          pointNumbers: true,
          lineLengths: true,
          adjacentArrows: true
      };

      getSvg(part).then(result => {
          new Response(result).text().then(result => {
            // setBase64Svg(Buffer.from(result).toString('base64'));
            publicService.base64svg=Buffer.from(result).toString('base64');
            setPublicService(Object.assign({}, publicService));
          });
      })
    }
    
  }, [features])
  

  const setGeom = (mp, coordSystem, fileName, scale, svg) => {
    publicService.coordSystem=coordSystem;
    publicService.geometry=mp;
    setPublicService(Object.assign({}, publicService));
    let cArea = new WKT().readGeometry(mp).getArea()/10000; // ГА
    publicService.area=cArea.toFixed(4);
    publicService.scale=scale;
    publicService.base64svg=Buffer.from(svg).toString('base64');
    publicService.fileName=fileName;
    setPublicService(Object.assign({}, publicService));

    let feature = new WKT().readFeature(mp);
    feature.set("temp", true);
    handleLoadFeature(feature.getGeometry());
    setMode(MODE_SELECT);
  }

  const saveObj = () => {     
    if (!region || region.code === "000") {
      regionNote();
    } else if (ateSoCheck()) {
      ateNote();
    } else {
      publicService.signLoading=true;
      setPublicService(Object.assign({}, publicService));
      const ctId = categoryChoose.id ? categoryChoose.id : '00';
      const ctName = categoryChoose.name ? categoryChoose.name : t("egov-service.category-set");
      const file = publicService.fileName && methodName===t("egov-service.download-coordinates") ? publicService.fileName : t("egov-service.file");
      const coord = publicService.coordSystem && methodName===t("egov-service.download-coordinates") ? publicService.coordSystem : 'null';
      // const ate = ateName && ateName!==t("egov-service.plot-set") ? ateName : region.nameRu
      let ateSoName = soName && soName!==t("egov-service.so-not-found") ? soName : "";
      ateSoName += ateName && ateName!==t("egov-service.ate-not-found") ?
      soName && soName!==t("egov-service.so-not-found") ? `, ${ateName}` : ateName
      : "";
      if (!ateSoName) {
        ateSoName = region.nameRu;
      }

      publicService.service=service.id;
      publicService.regionCode=region.regionCode;
      publicService.regionName=region.nameRu;
      publicService.districtCode=region.code;
      publicService.districtId=region.id;
      publicService.ateId=region.rn_code;
      publicService.ateName=ateSoName;
      publicService.categoryId=ctId;
      publicService.categoryName=ctName;
      publicService.fileName=file;
      publicService.coordSystem=coord;

      publicService.cause=0;

      getServiceXml(publicService)
        .then(result => (result.json())
        .then(json => {
          publicService.landId=json['landId'];
          publicService.nomPlan=json['nomPlan'];
          publicService.sign=json['text'];
          sign(publicService)
        }))
    }
  }

  const sign = (publicService) => {
    signText(publicService.sign, "SIGNATURE")
    .then(result => result.responseObject)
    .then(sign => {
      publicService.sign=sign;
      setPublicService(Object.assign({}, publicService));
      return publicService;
    })
    .then(service => postService(service))
    .then(result => {
      if (result.status==200) {
        publicService.signLoading=false;
        if (features && features.length > 0) {
          setFeatures([]);
          setMode(MODE_SELECT_PDP);
        }
        setBdSuccess(true);
        return result.blob()
      } else {
        setBdError(true);
      }
    })
    .then(blob => download(blob, "Схема испрашиваемого земельного участка.pdf"))  
    .catch(e=> {
      publicService.signLoading=false;
      setPublicService(Object.assign({}, publicService));
    })
  }

  const downloadFile = () => {
    if (!region || region.code === "000") {
      regionNote();
    } else if (ateSoCheck()) {
      ateNote();
    } else {
        const ct = categoryChoose.id ? categoryChoose.name : t("egov-service.category-set");
        // const ate = ateName && ateName!==t("egov-service.plot-set") ? ateName : region.nameRu
        let ateSoName = soName && soName!==t("egov-service.so-not-found") ? soName : "";
        ateSoName += ateName && ateName!==t("egov-service.ate-not-found") ?
        soName && soName!==t("egov-service.so-not-found") ? `, ${ateName}` : ateName
        : "";
        if (!ateSoName) {
          ateSoName = region.nameRu;
        }

        const egovService = {
        landId: '-',
        districtId: region.id,
        geometry: publicService.geometry,
        purposeRu: publicService.purposeRu,
        ate: ateSoName,
        category: ct,
        base64svg: publicService.base64svg,
        scale: publicService.scale,
        location: publicService.locationRu,
        nomPlan: '-'
      }

      documentCreate(egovService);
    }
  }
  
  let lang = localStorage.getItem('i18nextLng');
  useEffect(() => {  
    if (region) {
      getAte(lang, region.id).then(json => {
        json.length!=0 ? setAte(json) : setAteName(t("egov-service.ate-not-found"))
        if (json.length!=0) {
          setAte([{name: ""}].concat(json));
          setAteName('');
        } else {
          setAte([]);
          setAteName(t("egov-service.ate-not-found"))
        }
      });

      getSo(lang, region.id).then(json => {
        json.length!=0 ? setSo(json) : setSoName(t("egov-service.so-not-found"))
        if (json.length!=0) {
          setSo([{name: ""}].concat(json));
          setSoName('');
        } else {
          setSo([]);
          setSoName(t("egov-service.so-not-found"))
        }
      });
    }  
    
  }, [region, lang])

  useEffect(() => {    
    getCategory(lang).then(json => {
      setCategory(json);
    });
  }, [lang])

  let iinLandUserData = useInput('', {isEmpty: true, minLength: 12, maxLength: 12, valid: false})
  let purposeRuData = useInput('', {isEmpty: true, maxLength: 255});
  let purposeKzData = useInput('', {isEmpty: true, maxLength: 255});
  let locationRuData = useInput('', {isEmpty: true});
  let locationKzData = useInput('', {isEmpty: true});
  let regionData = useInput('', {isEmpty: true});
  let ateData = useInput('', {isEmpty: true});
  let methodData = useInput('', {isEmpty: true});

  useEffect(() => {  
    if (region) {
      regionData.onChangeName(region.nameRu);
      if (!regionCheck) {
        setRegionCheck(true);
      }
    }  
    
  }, [region])

  const ateSoCheck = () => {
    if (!ateName && !soName) {
      return true;
    } else if (soName === t("egov-service.so-not-found") && !ateName) {
      return true;
    } else if (ateName === t("egov-service.ate-not-found") && !soName) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (features.length > 0 && methodName===t("egov-service.draw-scheme")) {
      const wkt = new WKT().writeGeometry(features[0].getGeometry());
      fetch(
        `${window.REST_URL}/map/check?geom=${wkt}&layers=lands,redlines,free,req,pdp_u,ohz,voz,vop&egovreq=${serviceId}&ate=${region.ate_code}&checkAte=true`,
        {
          method: "POST",
          mode: "cors",
          credentials: "include",
        },
      )
        .then(response => response.json())
        .then(result => {
          if (layers.select) {
            removeSegments();
            setSegments([]);
            if (result.segments) {
              const segmentsArr = [];
              result.segments.forEach(seg => {
                segmentsArr.push(seg);
                source.addFeature(
                  new Feature({
                    type: "segment",
                    geometry: new WKT().readGeometryFromText(seg.wkt),
                  }),
                );
              });
              setSegments(segmentsArr);
            }
          }
          setErrIntersect(result.errors ? result.errors : []);
        });
    }
  }, [features]);

  const formatNumber = (n, dec) => {
    if (n) {
      let numAsString = n
        .toFixed(dec)
        .toString()
        .replace(/\./, ",");
      if (/\d{4},/.test(numAsString)) {
        numAsString = numAsString.replace(/(\d)(?=\d{3},)/, "$1 ");
      }
      while (/^\d{4}/g.test(numAsString)) {
        numAsString = numAsString.replace(/(?=\d)(\d{3})(?=\s)/, " $1");
      }
      return numAsString;
    }
    return 0;
  };

  const segmentsRender = () => {
    let num = 0;
    return (segments.length>0 && segments.map((seg,i) => (
        <React.Fragment key={i}>
          <div className="segment-wrapper" >
            <div>
              {++num + ") "}
              {formatNumber(new WKT().readGeometry(seg.wkt).getArea(), 2)} м<sup>2</sup> (
              {formatNumber(new WKT().readGeometry(seg.wkt).getArea() / 10000, 4)} га.)
            </div>
            <button 
              className="segment-btn"
              onClick={() => {
                setShowSegment(true)
                if (seg.wkt instanceof Object) {
                  handleSelect({geometry: new GeoJSON().readGeometry(seg.wkt)}, null, "segment");
                } else {
                  handleSelect({wkt: seg.wkt}, null, "segment");
                }
              }}
            >
              {t("request.zoom")}
            </button>
          </div>
          {segments.length-1!==i && <hr />}
        </React.Fragment>
        
      ))
    ) 
  }

  const showAllSegments = () => {
    if (layers.select) {
      const segmentsCopy = Object.assign([], segments);
      removeSegments();
      setSegments([]);
      if (segmentsCopy) {
        const segmentsArr = [];
        segmentsCopy.forEach(seg => {
          segmentsArr.push(seg);
          source.addFeature(
            new Feature({
              type: "segment",
              geometry: new WKT().readGeometryFromText(seg.wkt),
            }),
          );
        });
        setSegments(segmentsArr);
        setShowSegment(false);
        map.getView().fit(features[0].getGeometry(), { duration: 500, maxZoom: 19 })
      }
    }
  }

  useEffect(() => {
    if (errors && !errIntersect) {
      setErrIntersect([errors]);
    } else if (errIntersect) {
      var ers = errIntersect.filter(err => err.msgRu !== t("request.snapErr"));
      if (errors) {
        ers.push(errors);
      }
      setErrIntersect(ers);
    }
  }, [errors])

  const getField = (fieldType, iin, type, label, placeholder, field, propName, focus, disable) => {
    let style = fieldType === 'input' ? 'fld-region' : 'fld-region fid-ta';
    return (
      <>
        <h2>{label}</h2>
        {iin ? 
          field.value.length===12 ? !field.valid && <div style={{color: 'red'}}>{field.errorName}</div> 
          : (field.isDirty && field.error) && <div style={{color: 'red'}}>{field.errorName}</div>
        : (field.isDirty && field.error) && <div style={{color: 'red'}}>{field.errorName}</div>
        }
        <div className={style}>

          {fieldType==='input' ?
            <input type={type} 
              className="header-combo-input input-wsub"
              placeholder={placeholder ? placeholder : ''}
              name={field}
              value={field.value}
              onChange={(e) => {
                field.onChange(e);
                publicService[propName]=e.target.value;
                setPublicService(Object.assign({}, publicService));
              }}
              onFocus={(e) => focus ? e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false }) : ''}
              onBlur={(e) => field.onBlur(e)}
              disabled={disable ? disable : false}
            />
            :
            <textarea
              type="text"
              className="header-combo-input input-wsub"
              placeholder={placeholder ? placeholder : ''}
              name={field}
              value={field.value}
              onChange={(e) => {
                field.onChange(e);
                publicService[propName]=e.target.value;
                setPublicService(Object.assign({}, publicService));
              }}
              onBlur={(e) => field.onBlur(e)}
            />
          }

          {iin && field.value.length===12  && 
            <div className="iin-valid">
              {field.valid ? <i className='fa-solid fa-check'></i> : 
              !field.errorName ? <ClipLoader color={"#02c23e"} loading={true} size={"25px"}/> : <i className="fa-solid fa-xmark"></i>}
            </div>
          }

        </div>
      </>
    )
  }
  
  const method = [t("egov-service.download-coordinates"), t("egov-service.draw-scheme")];
  const styleCoord = methodName==t("egov-service.download-coordinates") ? {display: 'block'} : {display: 'none'};
  const styleScheme = methodName==t("egov-service.draw-scheme") ? {display: 'block', width: '49%'} : {display: 'none'};
  const serviceName = service.id=='502100' ? t("egov-service.service-cost") : t("egov-service.service-quality")
	return (

      <EgovServiceWidget responsive={responsive} coordinatesActive={coordinatesActive} bdError={bdError} bdSuccess={bdSuccess}>
    
        <h2 className="service-title">{serviceName}</h2>
        <h2>{t("egov-service.region")}*</h2>
        
        {(regionData.isDirty && regionData.isEmpty) && <div style={{color: 'red'}}>{regionData.emptyError}</div>}
        <div className="fld-region">
          <input
            type="text"
            className="header-combo-input input-sub"
            placeholder={t("choose-region.1")}
            name="region"
            value={region && region.nameRu}    
            readOnly    
            onClick={(e)=> {
              if (openRegion && !regionCheck) regionData.onBlur(e);
              setOpenRegion(!openRegion);
              if (openCategory) setOpenCategory(false)
              if (openAte) setOpenAte(false)
              if (openMethod) setOpenMethod(false)   
            }}
          />
          <button
            type="submit"
            className="header-combo-btn"
            disabled={false}
            onClick={(e) => {
              if (openRegion && !regionCheck) regionData.onBlur(e);
              setOpenRegion(!openRegion);
              if (openCategory) setOpenCategory(false)
              if (openAte) setOpenAte(false)
              if (openMethod) setOpenMethod(false)
            }}

          >
          <img src={comboImg} style={{width: 14, height: 7}} alt=""/>
          </button>
          {openRegion && (
            <ChooseRegion
              setRegion={(district) => { setOpenRegion(false); setRegion(district); }}
              regions={regions}
              maxHeight={openRegionMaxHeight}
              lang={i18n.language}
              responsive={responsive}
              top={'33px'}
              width={'498px'}
              mh={'29vh'}
              regionChange={regionData.onChangeName}
              regionBlur={regionData.onBlur}
            />
          )}
        </div>

        <h2>Сельский округ</h2>
        <div className="fld-region">
          <input
            type="text"
            className="header-combo-input input-sub"
            placeholder={t("egov-service.so")}
            value={soName}
            readOnly
            onClick={()=> {
              setOpenSo(!openSo);
              if (openCategory) setOpenCategory(false)
              if (openRegion) setOpenRegion(false)
              if (openMethod) setOpenMethod(false)
              if (openAte) setOpenAte(false)
            }}
          />
          <button
            type="submit"
            className="header-combo-btn"
            disabled={so.length>0 ? false : true}
            onClick={(e) => {
              setOpenSo(!openSo);
              if (openCategory) setOpenCategory(false)
              if (openRegion) setOpenRegion(false)
              if (openMethod) setOpenMethod(false)
              if (openAte) setOpenAte(false)
            }}        
          >
            <img src={comboImg} style={{width: 14, height: 7}} alt=""/>
          </button>
          {openSo && (
            <ChooseAto
              handleSelect={handleSelect}
              so={so}
              maxHeight={openRegionMaxHeight}
              lang={i18n.language}
              responsive={responsive}
              setSoName={setSoName}
              setOpenSo={setOpenSo}
              top={'33px'}
            />
          )}
        </div>

        <h2>Населенный пункт</h2>
        <div className="fld-region">
          <input
            type="text"
            className="header-combo-input input-sub"
            placeholder={t("egov-service.ate")}
            value={ateName}
            readOnly
            onClick={()=> {
              setOpenAte(!openAte);
              if (openCategory) setOpenCategory(false)
              if (openRegion) setOpenRegion(false)
              if (openMethod) setOpenMethod(false)
              if (openSo) setOpenSo(false)
            }}
          />
          <button
            type="submit"
            className="header-combo-btn"
            disabled={ate.length>0 ? false : true}
            onClick={(e) => {
              setOpenAte(!openAte)
              if (openCategory) setOpenCategory(false)
              if (openRegion) setOpenRegion(false)
              if (openMethod) setOpenMethod(false)
              if (openSo) setOpenSo(false)
            }}        
          >
            <img src={comboImg} style={{width: 14, height: 7}} alt=""/>
          </button>
          {openAte && (
            <ChooseAto
              handleSelect={handleSelect}
              ate={ate}
              maxHeight={openRegionMaxHeight}
              lang={i18n.language}
              responsive={responsive}
              setAteName={setAteName}
              setOpenAte={setOpenAte}
              top={'33px'}
            />
          )}
        </div>

        {getField('input', true, 'number', t("egov-service.iin-landuser") + ' *', t("egov-service.iin-ph"), iinLandUserData, 'iinLandUser', true)}
        {getField('textarea', false, 'text', t("egov-service.target-rus") + ' *', t("egov-service.target-rus-ph"), purposeRuData, 'purposeRu')}
        {getField('textarea', false, 'text', t("egov-service.target-kz") + ' *', t("egov-service.target-kz-ph"), purposeKzData, 'purposeKz')}
        {getField('textarea', false, 'text', t("egov-service.location-rus") + ' *', t("egov-service.location-rus-ph"), locationRuData, 'locationRu')}
        {getField('textarea', false, 'text', t("egov-service.location-kz") + ' *', t("egov-service.location-kz-ph"), locationKzData, 'locationKz')}

        <h2>{t("egov-service.category")}</h2>
        <div className="fld-region fid-ct">
          <textarea
            type="text"
            className="header-combo-input input-sub input-sub-ct"
            name="category"
            placeholder={t("egov-service.category-choose")}
            value={categoryChoose ? categoryChoose.name : ''}
            readOnly
            onClick={()=> {
              setOpenCategory(!openCategory);
              if (openRegion) setOpenRegion(false)
              if (openAte) setOpenAte(false)
              if (openMethod) setOpenMethod(false)
              if (openSo) setOpenSo(false)
            }}
          />
          <button
            type="submit"
            className="header-combo-btn header-combo-btn-ct"
            disabled={false}
            onClick={() => {
              setOpenCategory(!openCategory)
              if (openRegion) setOpenRegion(false)
              if (openAte) setOpenAte(false)
              if (openMethod) setOpenMethod(false)
              if (openSo) setOpenSo(false)
            }}
          >
            <img src={comboImg} style={{width: 14, height: 7}} alt=""/>
          </button>
          {openCategory && (
            <ChooseAto
              category={category}
              maxHeight={openRegionMaxHeight}
              lang={i18n.language}
              responsive={responsive}
              setCategoryChoose={setCategoryChoose}
              setOpenCategory={setOpenCategory}
              top={'74px'}
            />
          )}
        </div>

        {/* <Select
          className="select_test"
          styles={customStyles}
          placeholder={t("egov-service.category-choose")}
          options={category.map(item => item.name)}
          noOptionsMessage={() => ''}
          isSearchable={true}
          onChange={e => {
            console.log(e)
          }}
        /> */}

        <h2>{t("egov-service.project-create")}</h2>
        {(methodData.isDirty && methodData.isEmpty) && <div style={{color: 'red'}}>{methodData.emptyError}</div>}
        <div className="fld-region">
          <input
            type="text"
            className="header-combo-input input-sub"
            name="method"
            placeholder={t("egov-service.project-create-ph")}
            value={methodName}
            readOnly
            onClick={(e)=> {
              if (openMethod && methodName.length==0) methodData.onBlur(e);
              setOpenMethod(!openMethod);
              if (openCategory) setOpenCategory(false)
              if (openAte) setOpenAte(false)
              if (openRegion) setOpenRegion(false)
              if (openSo) setOpenSo(false)
            }}
          />
          <button
            type="submit"
            className="header-combo-btn"
            disabled={false}
            onClick={(e) => {
              if (openMethod && methodName.length==0) methodData.onBlur(e);
              setOpenMethod(!openMethod)
              if (openCategory) setOpenCategory(false)
              if (openAte) setOpenAte(false)
              if (openRegion) setOpenRegion(false)
              if (openSo) setOpenSo(false)
            }}
          >
            <img src={comboImg} style={{width: 14, height: 7}} alt=""/>
          </button>
          {openMethod && (
            <ChooseAto
              method={method}
              maxHeight={openRegionMaxHeight}
              lang={i18n.language}
              responsive={responsive}
              setMethodName={setMethodName}
              setOpenMethod={setOpenMethod}
              methodChange={methodData.onChangeName}
              top={'33px'}
            />
          )}
        </div>

        <h2>{t("egov-service.area")}, ГА</h2>
        <div className="fld-region">
          <input
            type="number"
            className="header-combo-input input-wsub"
            name="area"
            value={publicService.area}
            disabled={true}
          />
        </div>

        {segments && segments.length>0 && 
          <>
            <h2 style={{background: "linear-gradient(rgb(237, 28, 36) 0%, rgb(193, 39, 45) 100%)"}}>{t("request.intersections")}</h2>
            {showSegment && segments.length>1 && 
              <button className="segment-btn-all" onClick={() => showAllSegments()}>
                {t("request.all-intersections")}
              </button>
            }
            <div className="segments">
              {segmentsRender()}
            </div>
          </>
        }

        {errIntersect && errIntersect.length > 0 && methodName==t("egov-service.draw-scheme") && 
        <>
          <h2 style={{background: "linear-gradient(rgb(237, 28, 36) 0%, rgb(193, 39, 45) 100%)"}}>{t("egov-service.errors")}</h2>
          {errIntersect.map((err, i) => <div className="fld-region draw-error" key={i}>{err.msgRu}</div>)}
        </>
        }

        <EgovServiceBtn
          style={styleCoord}
          onClick={() => {
            if (!region || region.code === "000") {
              regionNote();
            } else if (ateSoCheck()) {
              ateNote();
            } else {
              setCoordinatesActive(true)
            }
          }}
        >
          {t("egov-service.coordinates")}
        </EgovServiceBtn>
        
        <div className="scheme-btns">
          <EgovServiceBtn
            style={styleScheme}
            color={"#009856"}
            onClick={() => {
              if (!region || region.code === "000") {
                regionNote();
              } else if (ateSoCheck()) {
                ateNote();
              } else if (publicService.area === 0) {
                setMode(MODE_DRAW)
              } else {
                setMode(MODE_EDIT)
              }
            }}
          >
            {t("egov-service.draw")}
          </EgovServiceBtn>

          <EgovServiceBtn
            style={styleScheme}
            color={'#008B78'}
            onClick={() => setMode(MODE_OFF)}
          >
            Завершить
          </EgovServiceBtn>

          <EgovServiceBtn
            color={'#ae2a00'}
            style={styleScheme}
            onClick={() => {
              if (layers.select && segments.length>0) {
                removeSegments();
                setSegments([]);
                setShowSegment(false)
              }
              setFeatures([]);
              if (setErrors) {
                setErrors(undefined);
              }
              setErrIntersect([]);
              setMode(MODE_SELECT);
              publicService.area='';
              setPublicService(Object.assign({}, publicService));
            }}
          >
            {t("egov-service.clear")}
          </EgovServiceBtn>
        </div>
        
        <EgovServiceBtn
            onClick={downloadFile}
            disabled={!iinLandUserData.inputValid || !purposeRuData.inputValid || !purposeKzData.inputValid || !locationRuData.inputValid || !locationKzData.inputValid || !methodData.inputValid || !publicService.area || (methodName===t("egov-service.draw-scheme") && errIntersect && errIntersect.length > 0)}
            >
            {t("egov-service.doc")}
        </EgovServiceBtn>
        
        {!publicService.signLoading ? 
          <EgovServiceBtn
            onClick={saveObj}
            disabled={!iinLandUserData.inputValid || !purposeRuData.inputValid || !purposeKzData.inputValid || !locationRuData.inputValid || !locationKzData.inputValid || !methodData.inputValid || !publicService.area || (methodName===t("egov-service.draw-scheme") && errIntersect && errIntersect.length > 0)}
          >
          {t("egov-service.confirm")}
          </EgovServiceBtn>
          : <div className="sign-loading">
              <ClipLoader color={"#02c23e"} loading={true} size={"35px"}/>
            </div>
         }
        
        <EgovServiceBtn
          color={'#ae2a00 '}
          onClick={()=> {
            if (layers.select && segments.length>0) {
              removeSegments();
              setSegments([]);
              setShowSegment(false)
            }
            setEgovService('');
            setFeatures([]);
            setMode(MODE_SELECT_PDP);
          }}    
        >
          {t("egov-service.close")}
        </EgovServiceBtn>
        
        <Coordinates
          active={coordinatesActive}
          setActive={setCoordinatesActive}
          setGeom={setGeom}
          useInput={useInput}
          publicService={publicService}
          setPublicService={setPublicService}
          fileFormatNote={fileFormatNote}
          checkFileFormat={checkFileFormat}
          errors={errors}
          setErrors={setErrors} 
          region={region}
          features={features}
          setFeatures={setFeatures}
          handleLoadFeature={handleLoadFeature}
          setMode={setMode}
          removeSegments={removeSegments}
          setSegments={setSegments}
          layers={layers}
          source={source}
          serviceId={serviceId}
        />
        
          <div className={bdError ? "area_inform active" : "area_inform"}>
          <ModalInfo>
            <div className="area_inform_content error">
              <h3>{t("egov-service.close")}</h3>
              <p>{t("egov-service.error")}</p>
              <div className="area-confirm">
                <button className="area-confirm_btn error" onClick={() => setBdError(!bdError)}>
                  {t("egov-service.continue")}
                </button>
              </div>
            </div>
            
          </ModalInfo> 
          </div>

          <div className={bdSuccess ? "area_inform active" : "area_inform"}>
          <ModalInfo>
            <div className="area_inform_content error">
              <h3>{t("egov-service.info")}</h3>
              <p>{t("egov-service.success")}</p>
              
              <div className="area-confirm">
                <div className="area-confirm_doc-num_wrap">
                  <p className="doc-num">
                    {t("egov-service.doc-number")}<br />
                    {`${publicService.nomPlan} (${publicService.landId})`}
                  </p>
                </div>
                <div className="area-confirm_btn_wrapper">
                  <button className="area-confirm_btn error" onClick={() => {
                    setEgovService('');
                    setBdSuccess(!bdSuccess)}
                  }>
                    {t("egov-service.continue")}
                  </button>
                </div>
              </div>
            </div>
            
          </ModalInfo> 
          </div>
      </EgovServiceWidget>

  );
}