import React, { useEffect, useState } from 'react'
import axios from 'axios';
import ClipLoader from "react-spinners/ClipLoader";
import { useTranslation } from "react-i18next";
import { Icon } from './UserProfile';
import svgDownload from '../../assets/img/download-table.svg';
import styled from "styled-components";
import { Scrollbars } from "react-custom-scrollbars";
import svgAdd from '../../assets/img/add.svg';
import ConfirmDialog from '../ConfirmDialog';
import { signOrder } from '../../RestUtils';

export const Modal = styled.div`
    position: absolute;
    left: ${props => props.responsive.mobile ? "0" : "10%"};
    top: ${props => props.responsive.mobile ? "0" : "0%"};
    max-height: ${props => props.responsive.mobile ? "100%" : "900px"};
    width: ${props => props.responsive.mobile ? "100%" : "800px"};
    height: ${props => props.responsive.mobile ? "100%" : "550px"};
    background-color: #fff;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    z-index: 1;
    font-family: 'sf-pro-display-regular',sans-serif;
`;

export const ModalHeader = styled.div`
    .header {
        display: flex;
        height: 50px;
        margin-bottom: 15px;
        border-radius: 5px 5px 0px 0px;
        background: linear-gradient(180deg, #01C13D 0%, #00A824 100%);
        align-items: center;
        padding: 0px 80px 0px 20px;
        
        .title {
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 15px;
            color: #FFFFFF;
        }
    }
`;

export const ModalFooter = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin-top: 5px;

    button {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 10px 10px 0px;
        width: 93%;
        border-radius: 5px;
        color: #fff;
        height: 30px;
        border: none;
        outline: none;
        &:hover {
            cursor: pointer
        }
    }

    .success-btn {
        background: #00A824;
        width: 160px;
    }

    .cancel-btn {
        background: #777777;
        width: 90px;
    }
`;

export const ModalBody = styled.div`
    padding: 10px;
    height: 90%;

    .loading {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 400px;
    }

    table {
        border: 1px solid #B6D4C0;
        border-spacing: 0;
        width: 100%;
        margin-bottom: 10px;

        thead {
            tr {
                width: 100%;
                height: auto;
                display: flex;
                margin: 0;
                padding: 0;
                background: #fff;

                td {
                    padding: 3px 8px;
                    border: 1px solid #B6D4C0;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    background: #E0F0E5;
                    color: #008A78;
                }
            }
        }

        tbody {
            tr {
                width: 100%;
                height: 30px;
                display: flex;
                margin: 0;
                padding: 0;
                background: #fff;
                cursor: pointer;

                td {
                    padding: 3px 8px;
                    border: 1px solid #B6D4C0;
                    overflow: hidden;
                    position: relative;
                }
            }
        }

        tbody tr:hover {
            background: #D8EFE0; /* Цвет фона при наведении */
        }

        .even {
            background: #F8FFFA;
        }
    }

    button {
        margin: 0 10px 10px 0px;
        border-radius: 4px;
        color: #fff;
        height: 30px;
        border: none;
        outline: none;
        background-color: #de2027;
        &:hover {
            cursor: pointer
        }
    }

    .block-panel {
        display: inline-block;
        position: relative;
        border: 0.5px solid #828282;
        border-radius: 5px;
        padding: 12px 5px 10px 5px;
        text-align: center;
        height: auto;
        width: 100%;
        margin: 5px 0px;
    
        .border-word {
          font-size: 12px;
          color: #828282;
          background-color: #fff;
          position: absolute;
          padding: 0px 10px;
          top: -9px;
          left: 12px;
        }
    }

    .order-col {
        min-height: 100px;
        max-height: ${props => props.responsive.mobile ? "initial" : "352px"};
        height: auto;

        .order-row {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
        }

        .input-wsub {
            border-radius: 6px;
            padding-right: 15px;
            max-width: 526px;
        }

        .input-textarea {
            border-radius: 6px;
            padding-right: 15px;
            width: 100%;
            height: 80px;
            background: #f3f3f7;
            border-style: Solid;
            border-color: #c9d1de;
            border-width: 1px;
            -moz-border-radius: 6px;
            -webkit-border-radius: 6px;
            font-family: "Open Sans", sans-serif;
            font-size: 15px;
        }

        .add-doc-btn {
            display: flex;
            padding: 6px 10px;
            background: #009856;
            height: 30px;
            width: 15%;
        }

        .doc-info {
            border: 1px solid #B6D4C0;
            border-radius: 5px;
            margin: 0;
            width: 83%;
            height: 30px;
            padding: 5px 8px;
            text-align: left;
            cursor: pointer
        }
        .doc-info__download {
            position: absolute;
            top: 13px;
            right: 15px;
        }
    }
    .table-btn-img {
        width: 36px;
        height: 26px;
        cursor: pointer;
        background-position: center !important;
    }
    .sub-table-btn {
        position: relative;
        div {
          display: flex;
          position: absolute;
          top: 4px;
          left: 30px
          font-size: 12px;
          padding: 3px 6px;
          width: max-content;
        }
    }
    // .sub-table-btn:hover{
    //     color: #779480;
    //     background: #DCF0E2;
    // }
`;

export const OnTop = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    backdrop-filter: blur(3px);
    background-color: rgb(0 0 0 / 49%);
    z-index: 1000;
`;

export default function LecenseeViewWorkOrder({
    order,
    setShowViewWorkOrder,
    setShowWorkOrders,
    responsive,
    setNotification,
    pdfNote,
    checkPdf
}) {
    const { t } = useTranslation();
    const hiddenInvoiceInput = React.createRef();
    const hiddenContractInput = React.createRef();
    const [isShowConfirm, setShowConfirm] = useState(false);
    const [resolutions, setResolutions] = useState([t("zkr.order-resolution-accept"), t("zkr.order-resolution-refuse")])
    const [isSelectResolution, setSelectResolution] = useState(true);
    const [summa, setSumma] = useState('');
    const [cause, setCause] = useState('');
    const [docInvoice, setDocInvoice] = useState(null);
    const [docContract, setDocContract] = useState(null);
    const [signLoading, setSignLoading] = useState(false);
    const [orderDocs, setOrderDocs] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        //запрашиваем документы
        setLoading(true);
        fetch(
            `${window.REST_URL}/cabinet/order/` + order.id, {
                method: "GET",
                mode: "cors",
                credentials: "include",
            },
          )
            .then(response => response.json())
            .then(json => {
                const docs = json.docs.filter((item) => item.typeCode!=='ZKR41');
                setOrderDocs(docs);
                setLoading(false)
            })
            .catch((err) => {
                console.log(err);
            });
	}, [])

    const onSave = () => {
        //для начала запустим флк
        if(isSelectResolution) {
            if(!docInvoice) {
                alert('Выберете скан-копию счета на оплату')
                return
            }

            if(!docContract) {
                alert('Выберете скан-копию договора')
                return
            }

            if(!summa || summa == '') {
                alert('Заполните сумму к оплате')
                return
            }
        } else {
            if(!cause || cause == '') {
                alert('Заполните обоснование')
                return
            }
		}
        //покажем диалоговое окно с запросом подветверждения отправки данных
        // setShowConfirm(true)
        setSignLoading(true);
        signOrder(order.id, setSignLoading).then(result => sendDataRequest(result));
    }

    const downloadDoc = (doc, isFile = false) => {
        if(isFile) {
            if(doc.file) {
                let file = URL.createObjectURL(doc.file);
                //window.location.assign(file);
                window.open(file, '_blank');
            } else {
                downloadDoc(doc.fileName, false)
            }
        } else {
            return fetch(`${window.REST_URL}/upload/${order.id}?filename=${encodeURIComponent(doc)}`, {
                method: "GET",
                mode: "cors",
                credentials: "include"
            })
            .then((res) => res.blob())
            .then((blob) => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = doc;
                document.body.appendChild(a);
                a.click();    
                a.remove();
            });
        }
    }


	//отправляет заявителю решение Поставщика (Лицензита)
    const sendDataRequest = (sign) => {
        //готовим запрос для отправки
        const formData = new FormData();
        formData.append('sign', sign);
        formData.append('orderId', order.id);
        formData.append('resolution', isSelectResolution);

        // if(isSelectResolution == 'Отказать') {
        if(isSelectResolution) {
            formData.append('summa', summa);
            formData.append('invoice', new Blob([docInvoice.file]), docInvoice.file.name);
            formData.append('contract', new Blob([docContract.file]), docContract.file.name);   
        } else {
            formData.append('cause', cause);
        }
        
        axios({
            url: `${window.REST_URL}/cabinet/zkr/order/${order.id}/send`,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            method: "post",
            data: formData
        })
        .then((res) => {
            console.log(`Success` + res.data);
        })
        .catch((err) => {
            console.log(err);
        });

		setShowViewWorkOrder(false)
        setShowWorkOrders(false)

        setNotification({
            title: t("egov-service.info"),
            text: t("egov-service.success")
        })
    }

    //открывает выбор файла
    // * @param integer $type - Тип загружаемого файла: 1- Скан-копия счета на оплату, 2 - Скан-копия договора
    const handleOtherClick = (type) => {
        if(type == 1) {
            hiddenInvoiceInput.current.click();
        } else {
            hiddenContractInput.current.click();
        }
        
    }

    const filNote = () => {
        setNotification({
            title: t("service.file-error-title"),
            text: t("service.file-error-text")
        })
    }

    const checkDuplicateFiles = (fileName) => {
        let isDuplicate = false;
        const found = orderDocs.find(el => el.fileName===fileName);
        found ? isDuplicate=true : isDuplicate=false;
        return isDuplicate;
    }

    const handleInvoiceSelect = event => {
        const fileName = event.target.files[0].name;
        if (checkDuplicateFiles(fileName) || (docContract && fileName===docContract.fileName)) {
            filNote();
        } else if (checkPdf(fileName)) {
            pdfNote();
        } else {
            let doc = {
                fileName: fileName,
                file: event.target.files[0]
            };
            setDocInvoice(doc);
        }
    }

    const handleContractSelect = event => {
        const fileName = event.target.files[0].name;
        if (checkDuplicateFiles(fileName) || (docInvoice && fileName===docInvoice.fileName)) {
            filNote();
        } else if (checkPdf(fileName)) {
            pdfNote();
        } else {
            let doc = {
                fileName: fileName,
                file: event.target.files[0]
            };
            setDocContract(doc);
        }
    }

    return (
        <OnTop>
            <Modal responsive={responsive}>
            <ModalHeader>
            <div className="header">
                <div className='title'>{t("zkr.orders")}</div>
            </div>
            </ModalHeader>
            <Scrollbars
                autoHeight={false}
                // autoHide={true}
                autoHeightMax={"45vh"}
                renderView={props => <div {...props} className="scrollbar-view" />}
            >
            <ModalBody responsive={responsive}>
                {loading ? <div className="loading"><ClipLoader size="50px" color={"#02c23e"} loading={true}/></div> : 
                <>                
                    <table>
                        <tbody>
                            <tr>
                                <td style={{ width: "35%", background: '#E0F0E5' }}>{t("zkr.order-number")}</td>
                                <td style={{ width: "65%" }}>{order.id}</td>
                            </tr>
                            <tr>
                                <td style={{ width: "35%", background: '#E0F0E5' }}>{t("zkr.order-applicant")}</td>
                                <td style={{ width: "65%" }}>{order.bin || order.iin}</td>
                            </tr>
                            <tr>
                                <td style={{ width: "35%", background: '#E0F0E5' }}>{t("zkr.phone-number")}</td>
                                <td style={{ width: "65%" }}>{order.phone}</td>
                            </tr>
                            <tr>
                                <td style={{ width: "35%", background: '#E0F0E5' }}>{t("zkr.order-status")}</td>
                                <td style={{ width: "65%" }}>{order.statusName}</td>
                            </tr>
                            <tr>
                                <td style={{ width: "35%", background: '#E0F0E5' }}>{t("zkr.order-start")}</td>
                                <td style={{ width: "65%" }}>{order.timeStart}</td>
                            </tr>
                        </tbody>
                    </table>


                    {orderDocs && orderDocs.length > 0 && (
                        <div className='block-panel'>
                            <table>
                                <thead>
                                    <tr>
                                        <td style={{ width: "5%" }}>№</td>
                                        <td style={{ width: "80%" }}>{t("zkr.name-doc")}</td>
                                        <td style={{ width: "15%" }}>{t("zkr.actions")}</td>
                                    </tr>
                                </thead>
                                <tbody>
                                { orderDocs.map((item, i) => (
                                    <tr key={i}>
                                        <td style={{ width: "5%" }}>{++i}</td>
                                        <td style={{ width: "80%", textAlign: "left"}}>{item.fileName}</td>
                                        <td style={{ width: "15%" }}>
                                            <div className="table-btn-img sub-table-btn" style={{ background: `url(${svgDownload}) no-repeat`, backgroundSize: "18px" }}
                                                onClick={(e) => {
                                                    downloadDoc(item.fileName)
                                                }}
                                            >
                                                <div>
                                                    {t("zkr.download")}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )) }
                                </tbody>
                            </table>
                        
                            <span className="border-word">{t("zkr.docs")}</span>
                        </div>
                    )}


                    <div className='block-panel'>
                        <div className='order-row'>
                            { resolutions && resolutions.map((name, i) => (
                                <div key={i}>
                                    <input
                                        key={i}
                                        type="radio"
                                        value={name}
                                        checked={(isSelectResolution && i === 0) || (!isSelectResolution && i === 1)} //подходит только если два пункта выбора
                                        onChange={() => setSelectResolution(!isSelectResolution)}
                                    />
                                    <span className="radio-label">{name}</span>
                                </div>
                            ))}
                        </div>

                        <span className="border-word">{t("zkr.order-resolution")}</span>
                    </div>

                    {!isSelectResolution &&
                        <div className="order-col">
                            <div className='block-panel'>
                                <textarea
                                    type="text"
                                    className="input-textarea"
                                    name="cause"
                                    value={cause}
                                    onChange={(e) => {
                                        setCause(e.target.value);
                                    }}
                                    placeholder={t("zkr.enter-justification")}
                                />

                                <span className="border-word">{t("zkr.justification")}</span>
                            </div>
                        </div>
                    }
                    {isSelectResolution &&
                        <div className="order-col">
                            <div className='block-panel'>
                                <div className="order-row">
                                    <button
                                        className="add-doc-btn"
                                        href=""
                                        onClick={() => {
                                            handleOtherClick(1);
                                        }}
                                    >
                                        <Icon url={svgAdd} className="icon" />
                                        {t("zkr.attach")}
                                    </button>
                                    <input
                                        type="file"
                                        accept="application/pdf"
                                        ref={hiddenInvoiceInput}
                                        onChange={event => {
                                            event.target.files.length !== 0 && handleInvoiceSelect(event)
                                        }}
                                        style={{ display: 'none' }}
                                    />
                                    { docInvoice && (
                                        <div className="doc-info"
                                            onClick={(e) => {
                                                downloadDoc(docInvoice, true)
                                            }}
                                        >
                                            <span>{docInvoice.fileName}</span>
                                            <div className="table-btn-img doc-info__download" style={{ background: `url(${svgDownload}) no-repeat`, backgroundSize: "18px" }}/>
                                        </div>
                                    )}
                                </div>

                                <span className="border-word">{t("zkr.scan-invoce")}</span>
                            </div>

                            <div className='block-panel'>
                                <div className="order-row">
                                    <button
                                        className="add-doc-btn"
                                        href=""
                                        onClick={() => {
                                            handleOtherClick(2);
                                        }}
                                    >
                                        <Icon url={svgAdd} className="icon" />
                                        {t("zkr.attach")}
                                    </button>
                                    <input
                                        type="file"
                                        accept="application/pdf"
                                        ref={hiddenContractInput}
                                        onChange={event => {
                                            event.target.files.length !== 0 && handleContractSelect(event)
                                        }}
                                        style={{ display: 'none' }}
                                    />
                                    { docContract && (
                                        <div className="doc-info"
                                            onClick={(e) => {
                                                downloadDoc(docContract, true)
                                            }}
                                        >
                                            <span>{docContract.fileName}</span>
                                            <div className="table-btn-img doc-info__download" style={{ background: `url(${svgDownload}) no-repeat`, backgroundSize: "18px" }}/>
                                        </div>
                                    )}
                                </div>

                                <span className="border-word">{t("zkr.scan-contract")}</span>
                            </div>

                            <div className='block-panel'>
                                <div className="order-row">
                                    <input
                                        type="number"
                                        className="header-combo-input input-wsub"
                                        name="summa"
                                        value={summa}
                                        onChange={(e) => setSumma(e.target.value)}
                                        onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                        placeholder={t("zkr.enter-sum")}
                                    />
                                </div>

                                <span className="border-word">{t("zkr.sum")}</span>
                            </div>
                            
                        </div>
                    }
                </>
                }
            </ModalBody>
            </Scrollbars>
            <ModalFooter responsive={responsive}>
                
                <button
                    className="success-btn"
                    href=""
                    onClick={() => {
                        onSave()
                    }}
                >
                {signLoading ? <ClipLoader color={"#fff"} loading={true} size={"25px"}/> : t("zkr.send-applicant")}
                </button>
                
                
                <button
                    className="cancel-btn"
                    href=""
                    onClick={() => {
                        setShowViewWorkOrder(false)
                    }}
                >
                    {t("request.cancel")}
                </button>
            </ModalFooter>
            {isShowConfirm && (
                <ConfirmDialog
                    title={t("zkr.send")}
                    text={t("zkr.confirm-send")}
                    handleOnConfirm={() => sendDataRequest()}
                    handleOnCancel={() => setShowConfirm(false)}
                />
            )}
            </Modal>
        </OnTop>
    );
}
