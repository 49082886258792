import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { MODE_AUCTION_SELECT, MODE_CHOOSE_OBJECT, MODE_SELECT, NotificationContext } from "./EknMap";
import ReactTooltip from "react-tooltip";
import Collapsible from "react-collapsible";
import { Properties as LayerProps } from "./utils/info/config";
import { WKT, GeoJSON } from "ol/format";
import styled from "styled-components";
import { Scrollbars } from "react-custom-scrollbars";
import TabButton from "./TabButton";
import PulseLoader from "react-spinners/PulseLoader";
import { ConfirmContext } from "./EknMap";
import { getLandCost } from "./RestUtils";
import PageNav from "./components/PageNavOutPageNum";
import eOtinish from "./assets/img/e-otinish.svg"

const CollapsibleContainer = styled.div`

  * {
    margin: 0;
    font-family : 'Open Sans', sans-serif;
  }
  .row-wrapper {
    background-color: ${props => props.palette.blue2};
    padding-bottom: 12px;
  }

  .title {
    white-space: normal;
    font-size: 14px;
  }

`;

const Trigger = styled.div`
  margin: 8px 10px 0px 4px;
  padding: 10px; 
  font-weight: 500;
  align-items: center;
  box-sizing: border-box;
  border-radius: 3px 3px 0 0;
  font-size: 14px;
  display: flex;
  color : ${props => props.expanded ? props.palette.text : props.palette.black}
  border-left:5px solid black;
  background-color: ${props =>
    props.expanded ? props.palette.green : props.palette.text};
  p:first-child {
    width: 76%;
    color : ${props => props.expanded ? props.palette.text : props.palette.black}
  }

  button {
    background-color: ${props =>
    props.expanded ? "rgba(247,147,30,1)" : ""};
      color : ${props =>
    props.expanded ? props.palette.text : props.palette.black}
    margin-right: 10px;
  }
  
  &:hover  {
    color: #000;
    background-color: ${props => props.palette.lightgreen};
  }

`;

const ButtonsAndGoTo = styled.div`
.wrapper-tools-tabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 6px;
  position: relative;

  .eot-btn {
    height: 20px;
    border-radius: 5px
    border: none;
    padding: 0;
    margin-top: 3px;
  }
}
button {
  font-size: 14px;
}
.tools-svg {
  margin-top: 5px;
}
`;

const Download = styled.div`
  margin: 0 6px;
  padding: 5px 8px 5px 8px;
  text-align: right;
  a {
    color: ${props => props.palette.text} !important;
    text-align: right;
    &:visited {
      color: ${props => props.palette.primary};
    }
  }
`;

const Row = styled.div`
  display: grid;
  box-sizing: border-box;
  // grid-template-columns: 50% 50%;
  grid-template-columns: ${props => !props.hasTitle ? "50% 50%" : "none"}
  margin:0;
  font-size: 13px;
  :nth-child(odd) {background: #607B6E;}
  //:nth:child(even) {background: #fff;}
  background-color: #688577;
  color: ${props => props.palette.text};
  font-style: normal;
  padding: 5px;
   //border-bottom: 1px solid ${props => props.palette.additional};
  &:last-of-type {
    border-radius: 0 0 5px 5px;
  }
  p {
    font-size: 12px;
    padding: 5px;
    line-height: 14px;
    color: ${props => props.palette.text};
  }
  p:first-of-type {
      display: flex;
      align-items: center;
      justife-content: center;
    
  }
  .doc-link {
    color: ${props => props.palette.text} !important;
    float: right;
    &:visited {
      color: ${props => props.palette.primary};
    }
    cursor: pointer
  }
`;

const GroupName = styled.div`
  // background: ${props => props.palette.middleblue};
  background: ${props => !props.hasTitle ? props.palette.middleblue : "none"};
  p {
    margin: 0 12px;
  }
  color: ${props => props.palette.text};
  font-size: 13px;
  text-align: center;
  padding: 5px;
  // border-bottom: 1px solid ${props => props.palette.accent};
  border-bottom: ${props => !props.hasTitle ? `1px solid ${props.palette.accent}` : "none"}

  .expanded-wrapper {
    background: linear-gradient(180deg,rgba(247,147,30,1) 0%,rgba(241,90,36,1) 100%);
    border-radius: 5px;
    width: 20px;
    height: 20px;
    position: absolute;
    left: ${props => !props.hasTitle ? "7px" : "5px"};
  }
  .expanded-icon {
    transition: transform 0.5s;
    background: none;
    padding: 0;
  }
  .expanded-icon:hover {
    background: none;
  }
  .expanded-icon-stand {
    transform: rotate(0deg);
  }
  .expanded-icon-deg {
    transform: rotate(90deg);
  }
`;

const Tabs = styled.div`
  background-color: ${props => props.palette.lightblue};
  overflow: hidden;
  border-radius: 0 0 5px 5px;
  padding: 6px;
  svg {
    fill: ${props => props.palette.text};
    width: 20px;
    padding: 2px 5px;
    background : -webkit-linear-gradient(-90deg, rgba(247, 147, 30, 1) 0%, rgba(241, 90, 36, 1) 100%);
  background : -webkit-gradient(linear,50% 11.94% ,50% 97.07% ,color-stop(0,rgba(247, 147, 30, 1) ),color-stop(1,rgba(241, 90, 36, 1) ));
  background : linear-gradient(180deg, rgba(247, 147, 30, 1) 0%, rgba(241, 90, 36, 1) 100%);
    border-radius: 5px;
    :hover {
      cursor: pointer;
      background: orange;
    }
  }
  .group-wrapper {
    padding: 6px;
    &:first-of-type {
      font-size: 13px;
    }

    sup {
      color: #fff;
      font-size: 10px;
    }
  }
  .passport {
    margin-bottom: 6px;
    padding-left: 15px;
    font-style: underline;
    font-size: 12px;
    color: #fff;
    font-style: italic;
  }
  .Collapsible__contentInner {
    margin: 0 !important;
  }
  .group-title {
    position: relative;
    height: 30px;
    display: flex;
    justify-content: center
  }
  .group-title p {
    color: #fff;
    font-size: 13px;
    margin: 2px 12px;
  }
`;

const Line = styled.div`
  height: 4px;
  margin: 0 6px;
  //background-color: ${props => props.palette.middleblue};
`;

const RadiusInput = styled.div`
  display: inline-flex;
  border-radius: 6px;
  align-items: center;
  input {
    height: 24px;
    width: 60px;
    padding: 2px 5px;
    margin: 5px 5px 10px 5px;
    outline: none;
    border-radius: 5px;
    border: none;
    background: ${props => props.palette.secondary};
    &::placeholder {
      color: ${props => props.palette.primary};
    }
  }
  button {
    outline: none;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-transform: uppercase;
    font-weight: bold;
    padding: 2px 5px;
    margin: 5px 5px 10px 5px;
    color: ${props => props.palette.text};
    background: ${props => props.palette.secondary};
    &:hover {
      background: ${props => props.palette.turq};
    }
    height: 28px;
  }
`;

function TableObject({ layerName, obj, i, standalone, mode, region, theme,
  handleSend, setActiveObj, isActive, handleAuctionSelect, arrestIsChecked,
  handleCreateBuffer, handleDeleteBuffer, handleSelect, getExtData, setMode, order, setOrder, handleSearchByCadNum, searchText, searchResult }) {

  const [activeTab, setActiveTab] = useState();
  const [bufferRadius, setBufferRadius] = useState(10);
  const [inputShown, setInputShown] = useState(false);
  const [extDataLoaded, setExtDataLoaded] = useState(false);
  const [dataChange, setDataChange] = useState(false);
  const { t } = useTranslation();
  const { setConfirm } = useContext(ConfirmContext);
  const [cost, setCost] = useState();
  const { setNotification } = useContext(NotificationContext);
  const [expanded, setExpanded] = useState([]);
  const [pageNum, setPageNum] = useState(0);

  let layerObjProps = {};

  const handleObjectSelect = (item) => {
    let obj = {}
    obj.cadnum = item.properties.kad_nomer
    obj.addrRu = item.properties.address_ru;
    obj.addrKz = item.properties.address_kz;
    obj.ateCode = region.ate_code;
    obj.districtName = region.nameRu;
    obj.coords = item.geometry;
    obj.area = item.properties.shape_area;
    obj.onMap = true;
    order.objects.push(obj);
    setOrder(JSON.parse(JSON.stringify(order)));
  }

  const checkExpanded = (group, value, item) => {
    let title = group.getChild ? group.getChild(item) :
    group.getTitle().props.children;

    const x = {};
    x[title] = value;
    setExpanded(Object.assign({}, expanded, x));
  }

  const getChilds = {
    func: pageNum => {
      handleSearchByCadNum(searchText, pageNum * 10, 10, obj, searchResult);
    }
  };

  const downloadError = () => {
    setNotification({
      title: t("info-tab.file-error-title"),
      text: t("info-tab.file-donwload-text")
    })
  }

  const handleObjExtData = (kad_props, tab) => {
    getExtData(Object.keys(kad_props), "INFO_BY_CDR")
      .then(result => {
        for (var cad in result) {
          for (var attr in result[cad].items[0]) {
            kad_props[cad][attr] = result[cad].items[0][attr];
          }
        }
        if (obj.properties[tab.id]) {
          obj.properties[tab.id].isLoaded = true;
        } else {
          obj.properties[tab.id] = { isLoaded: true };
        }
        setDataChange(!dataChange);
      })
      .catch(e => {
        setExtDataLoaded(false);
      });
  }

  const handleObjCost = (obj) => {
    const id = obj.id.substring(obj.id.indexOf('.') + 1);
    if (id.indexOf('.')) {
      return;
    } else {
      getLandCost(id)
      .then(result => {
        obj.properties.cost = result.cost;
        setCost(result.cost);
      });
    }
    
  }

  const displayButtons = (layerName, obj) => {
    if (LayerProps[layerName] && LayerProps[layerName].tabs) {
      return LayerProps[layerName].tabs.map(
        (tab, i) =>
        (
          <TabButton
            setActiveTab={setActiveTab}
            tab={tab}
            theme={theme}
            key={i}
            i={i}
            activeTab={activeTab}
            obj={obj}
            handleObjExtData={handleObjExtData}
            getExtData={getExtData}
            dataChange={dataChange}
            setDataChange={setDataChange}
            standalone={standalone}
          />
        ),
      );
    }
    
  };

  const showField = (layerName, key) => {
    let render = true;
    if (standalone) {
      if (layerName === 'lands') {
        const forbiddenFields = ['cdate', 'cuser', 'uuser', 'udate', 'doc_foundation_end', 'prim', 'shape_length', 'shape_area', 'date_end'];
        if (forbiddenFields.includes(key)) {
          render = false;
        } 
      } 
    } 
    return render;
    
  }

  const getTabContent = (objProps, layerName, obj) => {
    // console.log(objProps);
    // console.log(obj)
    if (activeTab) {
      const items = activeTab.getItems
        ? activeTab.getItems(objProps)
        : [objProps];
      if (layerName === "buildings" && (!objProps.info || !objProps.info.isLoaded)) {
        return (<div className="justify-center"><PulseLoader size={15} color={"#02c23e"} loading={true} /></div>);
      } else if (
        objProps.buildings &&
        !objProps.buildings.isLoaded &&
        activeTab.getTitle().props.children === t("info-tab.buildings")
      ) {
        return (<div className="justify-center"><PulseLoader size={15} color={"#02c23e"} loading={true} /></div>);
      } else if (
        objProps.owners &&
        !objProps.owners.items.length &&
        activeTab.getItems &&
        activeTab.getTitle().props.children === t("info-tab.owners")
      ) {
        return objProps.owners.isLoaded ? (
          <GroupName palette={theme}>
            {t("info-tab.holder-info")}
          </GroupName>
        ) : (
          <div className="justify-center"><PulseLoader size={15} color={"#02c23e"} loading={true} /></div>
        );
      } else if (
        objProps.arrests &&
        !objProps.arrests.items.length &&
        activeTab.getItems &&
        activeTab.getTitle().props.children === t("info-tab.arrests")
      ) {
        return objProps.arrests.isLoaded ? (
          <GroupName palette={theme}>
            {t("info-tab.arrest-info")}
          </GroupName>
        ) : (
          <div className="justify-center"><PulseLoader size={15} color={"#02c23e"} loading={true} /></div>
        );
      } else if (
        objProps.obrems &&
        !objProps.obrems.items.length &&
        activeTab.getItems &&
        activeTab.getTitle().props.children === t("info-tab.mortgage")
      ) {
        return objProps.obrems.isLoaded ? (
          <GroupName palette={theme}>
            {t("info-tab.obrem-info")}
          </GroupName>
        ) : (
          <div className="justify-center"><PulseLoader size={15} color={"#02c23e"} loading={true} /></div>
        );
      } else if (
        objProps.docs &&
        !objProps.docs.length &&
        activeTab.getItems &&
        activeTab.getTitle().props.children === "Документы"
      ) {
        return (
          <GroupName palette={theme}>{t("info-tab.doc-info")}</GroupName>
        );
      } else if (
        objProps.childs &&
        !objProps.childs.length &&
        activeTab.getItems &&
        activeTab.getTitle().props.children === "Архив"
      ) {
        return (
          <GroupName palette={theme}>{t("info-tab.archive-info")}</GroupName>
        );
      } else if (
        objProps.buildings &&
        !objProps.buildings.length &&
        activeTab.getItems &&
        activeTab.getTitle().props.children === t("info-tab.buildings")
      ) {
        return (
          <GroupName palette={theme}>{t("info-tab.build-info")}</GroupName>
        );
      } else {
        return (
          <>
            {items.map((item, j) =>

            activeTab.groups.map((group, i) => (
              <div className="group-wrapper" key={i}>
                {group.getTitle ? (
                  activeTab.id === 'specification' ? 
                    <Collapsible
                      transitionTime={200}
                      easing="ease-out"
                      trigger={
                        <GroupName palette={theme} className="group-title" 
                        >
                          <div className="expanded-wrapper">
                            <svg
                              className={"expanded-icon " + (expanded[group.getChild ? group.getChild(item) : group.getTitle(item, j + 1).props.children] ? " expanded-icon-deg" : " expanded-icon-stand")}
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 22 22"
                            >
                              <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z" />
                            </svg>
                          </div>
                          <p>{group.getChild ? 'Дата закрытия: ' + group.getChild(item) : group.getTitle(item, j + 1)}</p>
                        </GroupName>
                      }
                      onOpening={() => checkExpanded(group, true, item)}
                      onClosing={() => checkExpanded(group, false, item)}
                    >
                      {getProps(group, item, theme, objProps, activeTab.id,layerName)}
                  </Collapsible> 
                  : 
                  <GroupName palette={theme}><p>{group.getTitle(item, j + 1)}</p></GroupName>
                ) : (
                  <Line palette={theme} />
                )}
                {item["owner_type_person_id"] === "001" ? (
                  <Row palette={theme}>{t("info-tab.hidden-info")}</Row>
                ) : 
                activeTab.id === 'specification' ? !group.getTitle ? getProps(group, item, theme, objProps, activeTab.id, layerName) : null :  getProps(group, item, theme, objProps, activeTab.id, layerName)
                }
              </div>
            )),
          )}

          {activeTab.id === 'archive' && objProps.childsCount  > 10 && <PageNav
          count={Math.ceil(objProps.childsCount / 10)}
          getObjects={getChilds}
          theme={theme}
          pageNum={pageNum}
          setPageNum={setPageNum}
        />}
          </>
        ) 
        
      }
    }
  };

  const getProps = (group, item, theme, objProps, activeTabId, layerName) => (
    Object.keys(group.props).map(key => {
      return (
        showField(layerName, key) &&
        <Row palette={theme} key={key} hasTitle={group.props[key].getTitle}>
          {/* typeof group.getTitle(item, j + 1).props.children === 'boolean' */}
          
          {
            group.props[key].getTitle 
            ? 
            <Collapsible
                  transitionTime={200}
                  easing="ease-out"
                  trigger={
                    <GroupName hasTitle={group.props[key].getTitle} palette={theme} className="group-title" 
                    // onClick={() => checkExpanded(group.props[key])} 
                    >
                      <div className="expanded-wrapper">
                        <svg
                          // className={"expanded-icon " + (expanded.includes(group.props[key].getTitle().props.children) ? " expanded-icon-deg" : " expanded-icon-stand")}
                          className={"expanded-icon " + (expanded[group.props[key].getTitle().props.children] ? " expanded-icon-deg" : " expanded-icon-stand")}
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 22 22"
                        >
                          <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z" />
                        </svg>
                      </div>
                      {/* <p>{Object.keys( group.props[key].props).length>0 ? Object.entries( group.props[key].props)[0][1][0]().props.children : <></>}</p> */}
                      <p>{group.props[key].getTitle()}</p>
                    </GroupName>
                  }
                  onOpening={() => checkExpanded(group.props[key], true)}
                  onClosing={() => checkExpanded(group.props[key], false)}
                >
                  {LayerProps.formatValue(
                    item[key],
                    Object.entries( group.props[key].props)[0][1]
                )}
              </Collapsible>
            :            
            <>
              
              <p>
              {key === "file_name" &&
                objProps.docs &&
                objProps.docs.length
                ? item.file_name
                : group.props[key]
                  ? group.props[key][0]() || group.props[key]()
                  : key}
              </p>
              {typeof item[key] === 'boolean' ? item[key] ? <p>✓</p> : <p><b style={{color: "#fff"}}>−</b></p> 
              : 
              <p>
                {LayerProps.formatValue(
                  item[key],
                  group.props[key],
                  objProps.docs && objProps.docs && objProps.docs.length 
                  ? item.type_code === 'ZKR4' || item.type_code === 'ZKR31' ? 
                  objProps.checkIin ? `${window.REST_URL}/map/doc?id=${item.id}` : null
                  : `${window.REST_URL}/map/doc?id=${item.id}`
                  : null,
                  handleSend.bind(this, item.id, 6),
                  handleSelect,
                  downloadError,
                  activeTabId
                )}
              </p>
              }
            </>
          }
          
        </Row>
      );
    })
  )
  

  const timeLeft = function (obj) {
    if (!obj.properties.auction_time)
      return 0;
    var dateTime = obj.properties.auction_time.split(" ");
    var date = dateTime[0].split(".");
    var time = dateTime[1].split(":");
    var auctionDate = new Date(date[2], date[1], date[0], time[0], time[1]);
    var cur = new Date();
    return auctionDate - cur;
  }

  const handleClick = () => {
    window.open(`${window.EOTN}?locationKato=${region.kato}`, '_blank');
  };

  return (
    <CollapsibleContainer palette={theme}>
      <Collapsible
        transitionTime={200}
        open={isActive}

        trigger={
          <Trigger
            palette={theme}
            expanded={isActive}
            data-tip
            data-for="learn-more"
            onClick={() => {
              if (isActive) {
                setActiveObj(undefined);
              } else {
                setActiveObj(obj);
                setActiveTab(LayerProps[layerName].tabs[0]);
                if (!extDataLoaded && layerName === "buildings") {
                  setExtDataLoaded(true);
                  var kad_props = {}
                  kad_props[obj.properties.kad_nomer] = obj.properties
                  handleObjExtData(kad_props, LayerProps[layerName].tabs[0]);
                }
                if (obj.properties.cost === undefined) {
                  handleObjCost(obj);
                }
              }
            }}
          >
            <p className="title">
              {/* Наименование */}
              {LayerProps[layerName] && LayerProps[layerName].getTitle
                ? LayerProps[layerName].getTitle(obj.properties)
                : obj.id}
            </p>
            {mode === MODE_CHOOSE_OBJECT && <button className="btn-layer-item" onClick={e => {
              handleObjectSelect(obj);
            }}>{t("new-obj.choose")}</button>}
            {mode === MODE_AUCTION_SELECT && obj.properties.auction_land_status_id === 93 && (timeLeft(obj) > 7200000 ?
              <button className="btn-layer-item" onClick={e => {
                setConfirm({
                  title: t("notif.confirm"),
                  text: t("notif.continue"),
                  onConfirm: () => {
                    e.preventDefault();
                    handleAuctionSelect(obj.properties.id);
                  }
                });
              }}>{t("new-obj.choose")}</button>
              : <div className="time-over-msg"><p>Срок подачи</p><p>заявки окончен!</p></div>
            )}

          </Trigger>
        }
      >

        <Scrollbars
          autoHeight={true}
          autoHide={true}
          autoHeightMax={"65vh"}
          // autoHeightMax={"57vh"}
        >
          {isActive && (
            <Tabs palette={theme}>
              <ButtonsAndGoTo palette={theme}>
                <div className="wrapper-tools-tabs">
                  <div style={{display: "flex"}}>
                    {displayButtons(layerName, obj, arrestIsChecked)}
                    {standalone && 
                    <>
                      <div>
                      <button className="eot-btn" data-tip data-for="eot" onClick={handleClick}>
                          <img src={eOtinish} alt="eOtinish Logo" />
                      </button>
                      </div>
                      <ReactTooltip
                          className="react-tooltip"
                          id="eot"
                          delayShow={250}>
                          {t("sidebar-links.12")}
                      </ReactTooltip>
                    </>
                    }
                  </div>
                  
                  <div className="tools-svg">
                    {!standalone ? (
                      inputShown ? (
                        <RadiusInput palette={theme}>
                          <input
                            className={inputShown ? "expanded" : null}
                            defaultValue={10}
                            type="number"
                            min="1"
                            max="100"
                            onChange={e =>
                              setBufferRadius(Number(e.target.value))
                            }
                          />
                          <button
                            onClick={e => {
                              handleCreateBuffer(
                                layerName,
                                obj.properties,
                                bufferRadius,
                              );
                              setInputShown(false);
                            }}
                            palette={theme}
                          >
                            {t("info-tab.radius")}
                          </button>
                        </RadiusInput>
                      ) : (
                        <>
                          {layerName === "buf" ? (
                            <>
                              <ReactTooltip
                                id={`del-buf-${i}`}
                                delayShow={250}
                              >
                                {t("info-tab.buf-delete")}
                              </ReactTooltip>
                              <svg
                                data-tip
                                data-for={`del-buf-${i}`}
                                onClick={() =>
                                  handleDeleteBuffer(obj.properties)
                                }
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                              >
                                <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
                              </svg>
                            </>
                          ) : (
                            <>
                              <ReactTooltip
                                id={`buf-${i}`}
                                delayShow={250}
                              >
                                {t("info-tab.buf-create")}
                              </ReactTooltip>
                              <svg
                                data-tip
                                data-for={`buf-${i}`}
                                onClick={() => setInputShown(true)}
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                              >
                                <path d="M19 4H5c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H5V6h14v12z" />
                              </svg>
                            </>
                          )}
                        </>
                      )
                    ) : null}
                    <ReactTooltip
                      id={`go-to-${i}`}
                      delayShow={250}
                    >
                      {t('object-info-table.2')}
                    </ReactTooltip>
                    <svg
                      style={{marginLeft: "2px"}}
                      data-tip
                      data-for={`go-to-${i}`}
                      onClick={() => {
                        if (obj.geometry instanceof Object) {
                          handleSelect({geometry: new GeoJSON().readGeometry(obj.geometry)});
                        } else {
                          handleSelect({wkt: obj.geometry});
                        }
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path d="M9 5v2h6.59L4 18.59 5.41 20 17 8.41V15h2V5z" />
                    </svg>
                  </div>
                </div>

              </ButtonsAndGoTo>
              {(layerName === "lands" || layerName === "buildings") &&
                obj.properties.passport_id ? (
                <Download palette={theme} download={true}>
                  <p></p>
                  <a
                    href={`${window.REST_URL}/map/doc?id=${obj.properties.passport_id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-tip
                    data-for={`download-tooltip-${i}`}
                  >
                    <ReactTooltip
                      delayShow={250}
                      id={`download-tooltip-${i}`}
                    >
                      {t("info-tab.open")}
                    </ReactTooltip>
                    {t("info-tab.kp")}
                  </a>
                </Download>
              ) : layerName === "lands" || layerName === "buildings" ? (
                <p className="passport">
                  {t('object-info-table.1')}
                </p>
              ) : null}
              {layerObjProps = Object.assign({}, obj.properties), region && region.length === 6 && (region === "vostok" ? layerObjProps.value = layerObjProps.name : ""),
                region && region.length > 6 && (region === "aktubinsk" ? layerObjProps.value = layerObjProps.label : "")}
              {getTabContent(layerObjProps, layerName, obj)}

            </Tabs>
          )}
        </Scrollbars>
      </Collapsible>
    </CollapsibleContainer>
  );
}

export default TableObject;