import React from 'react'
import { useTranslation } from "react-i18next";

export default function ConfirmDialog({ title, text, handleOnConfirm, handleOnCancel }) {
    const { t } = useTranslation();
    return (
        <div className={"notification-wrapper"}>
            <div className="notification-header" style={{ background: "#fff", color: "#000" }} >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                >
                    <path className="cls-1" d="M18.65,15.76a3.36,3.36,0,0,0,0-3.4L12.5,1.71a3.39,3.39,0,0,0-5.88,0L.46,12.37a3.4,3.4,0,0,0,2.94,5.1H15.69A3.41,3.41,0,0,0,18.65,15.76ZM17.31,15a1.86,1.86,0,0,1-1.62.94H3.4A1.84,1.84,0,0,1,1.8,15a1.86,1.86,0,0,1,0-1.88L8,2.47a1.86,1.86,0,0,1,3.22,0l6.15,10.67A1.83,1.83,0,0,1,17.31,15Z" /><path className="cls-1" d="M9.31,5.38a1.07,1.07,0,0,0-.76,1.07c0,.31,0,.63.07.94.07,1.18.13,2.34.2,3.52a.71.71,0,0,0,.73.69.73.73,0,0,0,.74-.71c0-.24,0-.47,0-.72,0-.75.09-1.51.14-2.27,0-.49.06-1,.09-1.47a1.3,1.3,0,0,0-.09-.49A1,1,0,0,0,9.31,5.38Z" />
                </svg>
                <span> {title}</span>
            </div>
            <div className="notification-content">
                <p>{text.replaceAll('\\n', '<br/>')}</p>
                <div className={"confirm-buttons"}>
                    <button onClick={() => {
                        handleOnCancel();
                        handleOnConfirm();
                    }}>Ок  </button>
                    <button style={{background: "rgb(204,0,0)"}} onClick={() => {
                        handleOnCancel();
                    }}>{t("service.cancel")}  </button>
                </div>
            </div>
        </div >
    )
}