import React, { Component } from 'react';
import styled from "styled-components";
import { LayersWrapper } from '../Layers/EknMapLayers';
import { Scrollbars } from "react-custom-scrollbars";
import Row from './Row';

const LegendWrapper = styled(LayersWrapper)`
    .scrollbar-view {
        margin-top: 5px;
        padding-bottom: 5px;
        max-height: 70vh !important;
    }
`;

const style1 = {
    boxSizing: "border-box",
    zIndex: 4,
    position: "absolute",
    top: "100px",
    right: "130px",
    width: "400px"
};

class EknMapLegend extends Component {

    constructor(props) {
        super(props);

        this.filterAndExtractLayers = this.filterAndExtractLayers.bind(this);
    }

    filterAndExtractLayers() {
        let layers = [];
        if (this.props.layerConfig) {
            let layerConfig = JSON.parse(JSON.stringify(this.props.layerConfig));
            layerConfig.forEach(group => {
                layers.push.apply(layers, group.layers.filter(item => { return !!this.props.layersVisibility[item.name] }));
            })
        }
        return layers;
    }

    render() {

        const layers = this.filterAndExtractLayers();

        return (<div>
            {this.props.open && (
                <LegendWrapper
                    palette={this.props.theme}
                    className="layers-wrapper"
                    style={style1}
                    maxHeight={82}
                >
                    <Scrollbars
                        autoHeight={true}
                        autoHide={true}
                        autoHeightMax="70vh"
                        renderView={props => <div {...props} className="scrollbar-view" />}
                    >
                        {layers.map((item, j) => (
                            <Row
                                key={j}
                                layer={this.props.layers[item.name]}
                                sublayers={item.sublayers}
                                theme={this.props.theme}
                                child={item.parent}
                                isChecked={!!this.props.layersVisibility[item.name]}
                                layerName={item.name}
                                layerText={item.title}
                                history={item.history}
                                region={this.props.region}
                            />
                        ))}
                    </Scrollbars>
                </LegendWrapper>
            )}
        </div>);
    }
}

export default EknMapLegend;