import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import svgInfoClose from "../../assets/img/info_close.svg";

const InfoComp = styled.div`
  overflow: hidden;
  position: absolute;
  bottom: 0px;
  left: 410px;
  width: 312px;
  height: ${props => (props.open ? "199px" : "78px")};
  transition: height 1s;
  pointer-events: none;
`;

const Grid = styled.div`
  background: -moz-linear-gradient(
    50% 95.55% 90deg,
    rgba(2, 194, 62, 1) 0%,
    rgba(0, 187, 97, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(2, 194, 62, 1) 0%,
    rgba(0, 187, 97, 1) 100%
  );
  background: -webkit-gradient(
    linear,
    50% 95.55%,
    50% -0.49%,
    color-stop(0, rgba(2, 194, 62, 1)),
    color-stop(1, rgba(0, 187, 97, 1))
  );
  background: -o-linear-gradient(
    90deg,
    rgba(2, 194, 62, 1) 0%,
    rgba(0, 187, 97, 1) 100%
  );
  background: -ms-linear-gradient(
    90deg,
    rgba(2, 194, 62, 1) 0%,
    rgba(0, 187, 97, 1) 100%
  );
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#02C23E', endColorstr='#00BB61' ,GradientType=0)";
  background : linear-gradient(0deg, rgba(2, 194, 62, 1) 0%, rgba(0, 187, 97, 1) 100%);
  position : absolute;
  top: 50px;
  width: 312px;
  height: 149px;
  border-radius: 9px 9px 0px 0px;
  -moz-border-radius: 9px 9px 0px 0px;
  -webkit-border-radius: 9px 9px 0px 0px;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00BB61',endColorstr='#02C23E' , GradientType=0);
  -webkit-transition: height 2s; /* Safari prior 6.1 */
  pointer-events: auto;
`;

const RowStyled = styled.div`
  float: left;
  width: 100px;
  height: 90px;
  text-align: center;
  margin: 28px 0 0 0;

  *{
    font-family: 'Open Sans', sans-serif;
  }
  .title {
    margin: 8px 10px 0 10px;
    height: 20px;
  
    font-size: 11px;
    line-height: 10px;
    color: #ffffff;
    color: rgb(255, 255, 255);
  }
  .value {
    margin: 0;
    height: 45px;
    font-weight: 500;
    font-size: 28px;
    line-height: 45px;
    color: #ffffff;
    color: rgb(255, 255, 255);
  }
  .unit {
    margin: 0;
 
    font-size: 11px;
    line-height: 10px;
    color: #ffffff;
    color: rgb(255, 255, 255);
  }
`;

const CloseButton = styled.div`
    border-radius: 50%;
    position : absolute;
    width: 27px;
    height: 27px;
    top: 36px;
    left: 142px;
    background-color: #ffffff;
    background-image: url(${svgInfoClose});
    background-size: 12px 6px;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow : 2px 2px 5px rgba(0, 0, 0, 0.25);
    filter: progid:DXImageTransform.Microsoft.dropshadow(OffX=2, OffY=2, Color='#000000');
    pointer-events: auto;
    transform: ${props => props.open ? '' : 'rotate(-180deg)'};
    transition: transform 1s;

    &:hover {
      cursor: pointer;
    }
    `;

const titles = [
  "Земельные участки",
  "Площадь ЗУ",
  "Строения"
];

export default function Info({ theme, region }) {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const openHandler = useCallback(() => {
    setOpen(!open);
  }, [open]);
  let lang = localStorage.getItem('i18nextLng')

  useEffect(() => {
    async function fetchData() {
      let dimId;
      let arr = [];
      const queryURL = `${window.OLAP_URL}/dim/dimension?ekn_code=${region && region.regionCode !== "00" ? region.regionCode : "region"}`;
      const q = { "dim1": { "id": 2185641254, "value": [] }, "fact": 'fact_zu', "agg": 'count', "value": '' };
      await fetch(queryURL, {
        method: "GET",
        mode: "cors",
        credentials: "include"
      })
        .then(response => response.json())
        .then(json => {
          dimId = json.id;
        });

      q.dim1.value.push(dimId);
      await fetch(`${window.OLAP_URL}/ds/data`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(q),
      })
        .then(response => response.json())
        .then(json => {
          arr.push(json[0])
        });

      q.agg = "sum";
      q.value = "square_fact";
      await fetch(`${window.OLAP_URL}/ds/data`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(q),
      })
        .then(response => response.json())
        .then(json => {
          json[0].unit = 1;
          arr.push(json[0]);
        });

      q.agg = "count";
      q.value = "";
      q.fact = "fact_eo_po";
      await fetch(`${window.OLAP_URL}/ds/data`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(q),
      })
        .then(response => response.json())
        .then(json => {
          json[0].unit = 2;
          arr.push(json[0]);
        });

      setData(arr);
    }

    fetchData();
  }, [region, lang]);

  return (
    <InfoComp open={open}>
      <Grid open={open}>
        {data
          .filter(datum => !!datum.value)
          .map((datum, i) => (
            <Row
              key={i}
              num={i}
              datum={datum}
              level={0}
              style={i === 0 ? {} : { borderLeft: "1px solid #FFFFFF" }}
            />
          ))}
      </Grid>
      <CloseButton onClick={openHandler} open={open} />
    </InfoComp>
  );
}

function Row({ level, num, datum, palette, style }) {
  return (
    <RowStyled level={level} num={num} palette={palette} style={style}>
      <p className="title">{titles[num]}</p>
      <p className="value">{formatNumber(datum.value)}</p>
      <p className="unit">{getUnit(datum.value, datum.unit)}</p>
    </RowStyled>
  );
}

function getUnit(num, unit) {
  const q = num >= 1e9 ? "млрд" : num >= 1e6 ? "млн" : num >= 1e3 ? "тыс" : "";
  const end = num >= 1e3 ? ")" : "";
  const start = num >= 1e3 ? "(" : "";
  switch (unit) {
    case 0:
      return start + q + end;
    case 1:
      return <>({q} м<sup>2</sup>)</>;
    case 2:
      return <>({q} га)</>;
    default:
      return start + q + end;
  }
}

function formatNumber(num) {
  // Nine Zeroes for Billions
  return num >= 1.0e9
    ? Math.round(num / 1.0e9 * 100) / 100
    : // Six Zeroes for Millions
    num >= 1.0e6
      ? Math.round(num / 1.0e6 * 100) / 100
      : // Three Zeroes for Thousands
      num >= 1.0e3
        ? Math.round(num / 1.0e3 * 100) / 100
        : Math.round(num * 100) / 100;
}
