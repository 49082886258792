import React, { useState, useEffect } from 'react'
import svgOrders from '../../assets/img/orders.svg';
import Orders from "./Orders";
import Services from "./Services";
import PaymentService from "./PaymentService";
import styled from "styled-components";
import { MODE_CHOOSE_OBJECT, MODE_SELECT } from "../../EknMap";
import { useTranslation } from "react-i18next";

const HeadOrder = styled.div`
  padding-right: 15px;

  .order-btn {
    height: 30px;
    width: 212px;
    padding-top: 13px;
    text-align: left;
    font-size: 16px;
    border: none;
    border-radius: 15px;
    margin-top: 3px;
    background: #008B78;
    color: #fff;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center
  }

  .order-btn:focus {
    outline: none;
  }

  .order-img {
    float: left;
    margin: 0px 7px 0 0;
    width: 12px;
    height: 12px;
    background: transparent url('${svgOrders}') 0% 0% no-repeat padding-box;
  }
`;

export default function ObjectsAndServices({
  setShowListService,
  handleClickOrders,
  setMode,
  handleSelectOrder,
  showOrders,
  handleOrders,
  orders,
  showOrder,
  handleClickOrder,
  handlePayment,
  setShowListMyObjs,
  setShowObjSearch,
  handleCloseStats,
  setOrder,
  order,
  docs,
  handlePostDoc,
  handlePostOther,
  region,
  orderLoaded,
  handleDocsType,
  palette,
  setDisplayFormCreate,
  handlePostCoords,
  handleSelectOnMap,
  userInfo,
  mode,
  setNotification,
  setIsNewObject,
  responsive,
  setShowLandIdObj,
  setShowProviders,
  setLandInd,
  setCadNum,
  setDocs,
  pdfNote,
  checkPdf,
  fileFormatNote,
  checkFileFormat,
  checkFileSize,
  checkFileSizeNote,
  flag
}) {

  const { i18n } = useTranslation();

  useEffect(() => {
    if (mode === MODE_CHOOSE_OBJECT && (!showOrder || (order && order.statusId >= 2))) {
      setMode(MODE_SELECT);
    }
  }, [showOrder, mode, setMode, order])

  return <HeadOrder><button className="order-btn"
    onClick={handleClickOrders}
    disabled={flag}
  >
    <div className="order-img" />{i18n.t("order.title")}
  </button>
    {showOrders && <Orders
      handleSelectOrder={handleSelectOrder}
      handleOrders={handleOrders}
      orders={orders}
      handleClickOrder={handleClickOrder}
      handleClickOrders={handleClickOrders}
      setOrder={setOrder}
      orderLoaded={orderLoaded}
      handleDocsType={handleDocsType}
      palette={palette}
      responsive={responsive}
      setNotification={setNotification}
      region={region}
    />}
    {showOrder && order && !order.orderLoading && (order.statusId >= 2 && order.statusId!=18 ? <PaymentService
      order={order}
      handleClickOrder={handleClickOrder}
      handleSelectOrder={handleSelectOrder}
      orderLoaded={orderLoaded}
      setOrder={setOrder}
      handleSelectOnMap={handleSelectOnMap}
      setNotification={setNotification}
      responsive={responsive}
      handlePostOther={handlePostOther}
      pdfNote={pdfNote}
      checkPdf={checkPdf}
      region={region}
      checkFileSize={checkFileSize}
      checkFileSizeNote={checkFileSizeNote}
    /> : !order.orderLoading && <Services
      setLandInd={setLandInd}
      setShowListService={setShowListService}
      setMode={setMode}
      handleClickOrder={handleClickOrder}
      handlePayment={handlePayment}
      setShowListMyObjs={setShowListMyObjs}
      setShowLandIdObj={setShowLandIdObj}
      setShowProviders={setShowProviders}
      setShowObjSearch={setShowObjSearch}
      handleCloseStats={handleCloseStats}
      setOrder={setOrder}
      order={order}
      docsType={docs}
      handlePostDoc={handlePostDoc}
      handlePostOther={handlePostOther}
      region={region}
      setDisplayFormCreate={setDisplayFormCreate}
      handlePostCoords={handlePostCoords}
      handleSelectOnMap={handleSelectOnMap}
      userInfo={userInfo}
      setIsNewObject={setIsNewObject}
      responsive={responsive}
      setCadNum={setCadNum}
      setDocs={setDocs}
      pdfNote={pdfNote}
      checkPdf={checkPdf}
      fileFormatNote={fileFormatNote}
      checkFileFormat={checkFileFormat}
      checkFileSize={checkFileSize}
      checkFileSizeNote={checkFileSizeNote}
    />)}
  </HeadOrder>;
}