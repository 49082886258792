import React, { useState, useEffect } from "react";
import "./SideBar.css";
import MenuButton from "./../../assets/img/menu.svg";
import MenuButtonSelected from "./../../assets/img/menuSelected.svg";
import Layers from "./../../assets/img/layers.svg";
import LayersBlack from "./../../assets/img/layersBlack.svg";
import LayersSelected from "./../../assets/img/layersSelected.svg";
import Done from "./../../assets/img/done.svg";
import Distance from "./../../assets/img/distance.svg";
import Statistics from "./../../assets/img/statistic.svg";
import PrintMaps from "./../../assets/img/print_maps.svg";
import Fedback from "./../../assets/img/feedback.svg";
import DistanceBlack from "./../../assets/img/distanceBlack.svg";
import StatisticsBlack from "./../../assets/img/statisticBlack.svg";
import PrintMapsBlack from "./../../assets/img/print_mapsBlack.svg";
import FedbackBlack from "./../../assets/img/feedbackBlack.svg";
import DistanceSelected from "./../../assets/img/distanceSelected.svg";
import eotinish from "./../../assets/img/e-otinish.svg";
import RedInfo from "./../../assets/img/redinfo.svg";

import StatisticsSelected from "./../../assets/img/statisticSelected.svg";
import PrintMapsSelected from "./../../assets/img/print_mapsSelected.svg";
import FedbackSelected from "./../../assets/img/feedbackSelected.svg";
import WhaitingLists from "./../../assets/img/whaitinglists.svg";
import WhaitingListsGrey from "./../../assets/img/whaitinglists-grey.svg";
import WhaitingListsSelected from "./../../assets/img/whaitinglistsSelected.svg";
import Close from "./../../assets/img/close.svg";
import CloseSelected from "./../../assets/img/closeSelected.svg";
import menuSelector from "./../../assets/img/menuSelector.svg";
import searchImg from "../../assets/img/search.svg";
import searchBlackImg from "../../assets/img/searchBlack.svg";
import searchSelectedImg from "../../assets/img/searchSelected.svg";

import helpImgWhite from "../../assets/img/help-circle.svg";
import helpImgBlack from "../../assets/img/help-circle-black.svg";
import helpImgGray from "../../assets/img/help-circle-gray.svg";

import eotinishBlack from "../../assets/img/eotinish-black.svg";
import eotinishGrey from "../../assets/img/eotinish-grey.svg";
import eotinishWhite from "../../assets/img/eotinish-white.svg";
import online from "../../assets/img/online.svg";
import onlineBlack from "../../assets/img/online-black.svg";
import onlineGrey from "../../assets/img/online-grey.svg";


import { MODE_SELECT, MODE_MEASURE } from "../../EknMap";
import * as CommonUtil from "../../utils/common.js";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import ReactTooltip from "react-tooltip";

const menuTop = 220;
const menuDiff = 60;

export default function SideBar({
  show,
  selectedMenu,
  setShowSidebar,
  openLayers,
  setOpenLayers,
  setMode,
  mode,
  showStats,
  handleOpenStats,
  handleCloseStats,
  showQueue,
  handleOpenQueue,
  setOpenInfo,
  openInfo,
  showSelector,
  region,
  dark,
  handleCloseQueueAndRegions,
  handleOpenRegions,
  handleOpenRegionsOnline,
  handleOpenHelp,
  responsive,
  setShowLiabilityNotice,
  conf
}) {

  const { t } = useTranslation();
  const info1 = t("sidebar-info.1");
  const [lang, showLang] = useState(false);
  const [choiced, setChoiced] = useState(false);

  const info = () => (
    <div className="info">
      <img src={Done} alt={"done logo"} />
      <span className="info1">{info1}</span>
      <span className="info2">{t("sidebar-info.2")}</span>
      <br />
      <span className="info2"> {t("sidebar-info.3")}</span>
      {/*<a className="ekgn_link" target="_blank" href="www.gov4c.kz"> www.gov4c.kz </a>*/}
    </div>
  );

  const divProps = {};
  if (responsive.mobile) {
    if (!show) {
      divProps['style'] = { width: '50px' };
    }
  }

  const menuLeft = responsive.mobile ? 5 : 20;
  const mobSuffix = responsive.mobile ? " mobile" : "";

  const [menuTop, setMenuTop] = useState(220);
  const [menuDiff, setMenuDiff] = useState(60);
  
  useEffect(() => {
    const updateMenuPositions = () => {
      if (window.innerHeight <= 800) {
        setMenuTop(150);
        setMenuDiff(60);
      } else {
        setMenuTop(250);
        setMenuDiff(60);
      }
    };
    updateMenuPositions();
    setTimeout(() => {
      window.addEventListener('resize', updateMenuPositions);
    }, 0);
    return () => {
      window.removeEventListener('resize', updateMenuPositions);
    };
  }, []);
  
  const handleClick = () => {
    const kato = region ? region.kato : null;
    window.open(`${window.EOTN}?locationKato=${kato}`, '_blank');
  };
  return (
    <div className={show ? "sidebarFull" : "sidebarHidden" + mobSuffix}>
      {show ? info() : ""}
      {!show ? (
        <button
          className={"menu-btn" + mobSuffix}
          style={positionButton("Показать", menuLeft)}
          title={t("sidebar-links.0")}
          onClick={() => setShowSidebar(true)}
        >
          <img src={MenuButton} alt="menu icon" />
          <img className="selected" src={MenuButtonSelected} alt="layers" />
        </button>
      ) : (
        <button
          title={t("sidebar-links.8")}
          className="close_btn menu-btn"
          onClick={() => setShowSidebar(false)}
        >
          <img src={Close} alt="close icon" />
          <img className="selected" src={CloseSelected} alt="layers" />
        </button>
      )}

      <button
        data-tip data-for="searchbtn-tooltip"
        className={
          "menu-btn" +
          (selectedMenu === CommonUtil.MENU_SEARCH ? " menu-selected" : "") +
          (!region || region.regionCode === "00" ? " disabled" : "")
        }
        style={positionButton("Поиск объекта", menuLeft, undefined, menuTop, menuDiff)}
        title={region && region.regionCode !== "00" ? t("sidebar-links.1") : ""}
        onClick={event => {
          if (region && region.regionCode !== "00")
            setOpenInfo(!openInfo);
          //setMode(MODE_SELECT)
        }}
      >
        {(!region || region.regionCode === "00") &&
          <ReactTooltip
            id="searchbtn-tooltip"
            effect="solid"
            border
            type={dark ? "light" : "dark"}
            place="right"
          >
            {t("tooltip.searchbar")}
          </ReactTooltip>
        }
        <img src={show ? searchBlackImg : searchImg} alt="layers" />
        <img className="selected" src={searchSelectedImg} alt="layers" />
        <img className={"menuSelector" + mobSuffix} src={menuSelector} alt="" />
        {show ? <span> {t("sidebar-links.1")}</span> : ""}
      </button>

      <button
        className={
          "menu-btn" +
          (selectedMenu === CommonUtil.MENU_MAP_CONTROL ? " menu-selected" : "")
        }
        style={positionButton("Управление картой", menuLeft, undefined, menuTop, menuDiff)}
        title={t("sidebar-links.2")}
        onClick={event => {
          setOpenLayers(event, !openLayers);
        }}
      >
        <img src={show ? LayersBlack : Layers} alt="layers" />
        <img className="selected" src={LayersSelected} alt="layers" />
        <img className={"menuSelector" + mobSuffix} src={menuSelector} alt="" />

        {show ? <span> {t("sidebar-links.2")}</span> : ""}
      </button>
      <button
        className={
          "menu-btn" +
          (selectedMenu === CommonUtil.MENU_MEASURE_DISTANCE
            ? " menu-selected"
            : "")
        }
        title={t("sidebar-links.4")}
        style={positionButton("Измерить расстояние", menuLeft, undefined, menuTop, menuDiff)}
        onClick={() => {
          setMode(mode === MODE_MEASURE ? MODE_SELECT : MODE_MEASURE);
        }}
      >
        <img src={show ? DistanceBlack : Distance} alt="distance icon" />
        <img className="selected" src={DistanceSelected} alt="layers" />
        <img className={"menuSelector" + mobSuffix} src={menuSelector} alt="" />

        {show ? <span> {t("sidebar-links.4")}</span> : ""}
      </button>

      {/*<button
        className={
          "menu-btn" +
          (selectedMenu === CommonUtil.MENU_SHOW_STATS ? " menu-selected" : "")
        }
        title={t("sidebar-links.5")}
        style={positionButton("Статистика")}
        onClick={event => {
          showStats ? handleCloseStats() : handleOpenStats();
        }}
      >
        <img
          className="statistics_image"
          src={show ? StatisticsBlack : Statistics}
          alt="statistics icon"
        />
        <img className="selected" src={StatisticsSelected} alt="layers" />
        {showSelector && <img className="menuSelector" src={menuSelector} alt="" />}
        {show ? <span> {t("sidebar-links.5")}</span> : ""}
      </button>*/}
      {/*<button
        className={
          "menu-btn" +
          (selectedMenu === CommonUtil.MENU_PRINT ? " menu-selected" : "")
        }
        style={positionButton("Печать карты", menuLeft)}
        title={t("sidebar-links.7")}
        onClick={() => console.log("print maps ")}
      >
        <img src={show ? PrintMapsBlack : PrintMaps} alt="print maps  icon" />
        <img className="selected" src={PrintMapsSelected} alt="layers" />
        <img className={"menuSelector" + mobSuffix} src={menuSelector} alt="" />
        {show ? <span> {t("sidebar-links.7")}</span> : ""}
      </button>
    */}
      {/*
      <a href={"mailto:" + t("mail.address") + "?subject=" + t("mail.subject")}>
        <button
          className={
            "menu-btn" +
            (selectedMenu === CommonUtil.MENU_FEEDBACK ? " menu-selected" : "")
          }
          style={positionButton("Обратная связь")}
          title={t("sidebar-links.6")}
          onClick={() => console.log("feedback ")}
        >
          <img src={show ? FedbackBlack : Fedback} alt="feedback  icon" />
          <img className="selected" src={FedbackSelected} alt="layers" />
          <img className="menuSelector" src={menuSelector} alt="" />

          {show ? <span> {t("sidebar-links.6")}</span> : ""}
        </button>
      </a>
		*/}

      <button
        className={
          "menu-btn" +
          (selectedMenu === CommonUtil.MENU_QUEUE ? " menu-selected" : "")
        }
        style={positionButton("Список очередников", menuLeft, undefined, menuTop, menuDiff)}
        title={t("sidebar-links.10")}
        onClick={() => {
          if (showQueue) {
            handleCloseQueueAndRegions();
          } else {
            handleOpenRegions();
          }
        }}
      >


        <img src={show ? WhaitingLists : WhaitingListsGrey} alt="queue  icon" />
        <img className="selected" src={WhaitingListsSelected} alt="layers" />
        <img className={"menuSelector" + mobSuffix} src={menuSelector} alt="" />

        {show ? <span> {t("sidebar-links.10")}</span> : ""}
      </button>

      {conf && conf.showRegionsOnline && 
        <button
          className={
            "menu-btn" +
            (selectedMenu === CommonUtil.MENU_REGION_ONLINE ? " menu-selected" : "")
          }
          style={positionButton("Online", menuLeft, show, menuTop, menuDiff)}
          onClick={handleOpenRegionsOnline}
          title={t("sidebar-links.13")}
        >
          <img src={show ? onlineBlack : onlineGrey} alt="distance icon" />
          <img className="selected" src={online} alt="layers" />
          <img className={"menuSelector" + mobSuffix} src={menuSelector} alt="" />
          {show ? <span>{t("sidebar-links.13")}</span> : ""}       
        </button>
      }

      <button
        className="menu-btn"
        style={positionButton("Обращение eOtinish", menuLeft, show, menuTop, menuDiff)}
        onClick={handleClick}
        title={t("sidebar-links.12")}
      >
        <img src={show ? eotinishBlack : eotinishGrey} alt="distance icon" />
        <img className="selected" src={eotinishWhite} alt="layers" />
        {show ? <span>{t("sidebar-links.12")}</span> : ""}       
      </button>

      <button
        className={
          "menu-btn" +
          (selectedMenu === CommonUtil.MENU_HELP ? " menu-selected" : "")
        }
        style={positionButton("Help", menuLeft, show, menuTop, menuDiff)}
        onClick={handleOpenHelp}
        title={t("sidebar-links.14")}
      >
        <img style={show? { height: '24px', width: '24px' } : { height: '30px', width: '30px' }} src={show ? helpImgBlack : helpImgGray} alt="distance icon" />
        <img style={show? { height: '24px', width: '24px' } : { height: '30px', width: '30px' }} className="selected" src={helpImgWhite} alt="layers" />
        <img style={{ height: '24px', width: '24px' }} className={"menuSelector" + mobSuffix} src={menuSelector} alt="" />
        {show ? <span>{t("sidebar-links.14")}</span> : ""}       
      </button>
      

      {
        !show ? (
          <>
            <div className="languages">
              <button
                className="active-lang switch"
                onClick={() => {
                  showLang(true);
                  setChoiced(!choiced);
                }}
              >
                {i18n.language === "kz"
                  ? "kz"
                  : i18n.language === "ru"
                    ? "ru"
                    : "en"}{" "}
              </button>

              {lang && (
                <div onClick={e => e.stopPropagation()}>
                  <button
                    style={positionButton("kaz2", menuLeft)}
                    title={t("sidebar-links.9")}
                    onClick={() => {
                      setChoiced(false);
                      i18next.changeLanguage("kz");
                    }}
                    className={`${choiced ? "language-sidebar" : "hidden"}`}
                  >
                    {"kz"}
                  </button>

                  <button
                    title={t("sidebar-links.9")}
                    style={positionButton("rus2", menuLeft)}
                    onClick={() => {
                      setChoiced(false);
                      i18next.changeLanguage("ru");
                    }}
                    className={`${choiced ? "language-sidebar" : "hidden"}`}
                  >
                    {"ru"}
                  </button>

                  <button
                    style={positionButton("eng2", menuLeft)}
                    title={t("sidebar-links.9")}
                    onClick={() => {
                      setChoiced(false);
                      i18next.changeLanguage("en");
                    }}
                    className={`${choiced ? "language-sidebar" : "hidden"}`}
                  >
                    {"en"}
                  </button>
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <button
              style={positionButton("rus", menuLeft)}
              className={
                i18n.language === "ru" ? "active-lang" : "language-sidebar"
              }
              title={t("sidebar-links.9")}
              onClick={() => i18next.changeLanguage("ru")}
            >
              {"ru"}
            </button>
            <button
              style={positionButton("kaz", menuLeft)}
              className={
                i18n.language === "kz" ? "active-lang" : "language-sidebar"
              }
              title={t("sidebar-links.9")}
              onClick={() => i18next.changeLanguage("kz")}
            >
              {"kz"}
            </button>

            <button
              style={positionButton("eng", menuLeft)}
              title={t("sidebar-links.9")}
              onClick={() => {
                i18next.changeLanguage("en");
              }}
              className={
                i18n.language === "en" ? "active-lang" : "language-sidebar"
              }
            >
              {"en"}
            </button>
          </>
        )
      }

      {/*<img
        src={RedInfo}
        alt="red info"
        className={"info-btn" + (show ? " left" : "")}
        onClick={() => setShowLiabilityNotice(true)}
      />*/}
    </div>
  );
}

const positionButton = (title, left, show, menuTop, menuDiff) => {
  switch (title) {
    case "Показать":
      return {
        top: "30px",
        left: left
      };

    case "Поиск объекта":
      return {
        top: menuTop,
        left: left
      };
    case "Управление картой":
      return {
        top: menuTop + menuDiff,
        left: left
      };

    case "Измерить расстояние":
      return {
        top: menuTop + menuDiff * 2,
        left: left
      };
    case "Статистика":
      return {
        top: menuTop + menuDiff * 3,
        left: left
      };

    case "Обратная связь":
      return {
        top: menuTop + menuDiff * 3,
        left: left
      };
    case "Печать карты":
      return {
        top: menuTop + menuDiff * 3,
        left: left
      };

    case "Список очередников":
      return {
        top: menuTop + menuDiff * 3,
        left: left
      };

    // case "Online":
    //   return {
    //     top: menuTop + menuDiff * 4,
    //     left: left,
    //     height: show ? "70px" : "58px"
    //   };

    // case "Обращение eOtinish":
    //   return {
    //     top: show ? (menuTop + 20) + menuDiff * 5 : menuTop + menuDiff * 5,
    //     left: left,
    //     height: show ? "70px" : "58px"
    //   };
    case "Обращение eOtinish":
      return {
        top: menuTop + menuDiff * 4,
        left: left,
        height: show ? "70px" : "58px"
      };

    case "Online":
      return {
        top: show ? (menuTop + 20) + menuDiff * 5 : menuTop + menuDiff * 5,
        left: left,
        height: show ? "70px" : "58px"
      };

      case "Help":
        return {
          top: show ? (menuTop + 40) + menuDiff * 6 : menuTop + menuDiff * 6,
          left: left,
          height: show ? "70px" : "58px"
        };
    

    case "Аналитика":
      return {
        top: menuTop + menuDiff * 7,
        left: left
      };

    case "kaz":
      return {
        bottom: 30,
        left: 33
      };

    case "eng2":
      return {
        bottom: 10,
        left: 0
      };

    case "rus":
      return {
        bottom: 30,
        left: 75
      };

    case "eng":
      return {
        bottom: 30,
        left: 115
      };
    case "rus2":
      return {
        bottom: 50,
        left: 0
      };

    case "kaz2":
      return {
        bottom: 90,
        left: 0
      };

    default:
      return null;
  }
};
