import React from "react";

import styled from "styled-components";

import Checkbox from "./Checkbox";
import Radio from "./Radio";
import Select from "./Select";
import RefreshLayer from "./RefreshLayer";
import LayerIcon from "./LayerIcon";
import ListButton from "./ListButton";
import OpacityButton from "./OpacityButton";
import { LayerSvgComponent } from "./LayerIcon";

export const RowComponent = styled.div`
  padding: 2px;
  display: grid;
  grid-template-columns: ${props =>
    !props.region
      ? "3% 6% 6% 56% 11% 5% 10%"
      : props.child || props.level2
        ? "7% 7% 7% 7% 49% 13% 5% 5%"
        : "7% 7% 7% 56% 13% 5% 5%"};
  align-items: center;
  div:hover {
    cursor: default;
  }
`;

export const CollapsibleRow = styled.div`
> .ekn-map-sub {
  // grid-template-columns: 7% 5% 8% 41% 17% 11% 0% !important;

  grid-template-columns: ${props => (
    // props.standalone ? 
    // (props.layerName === "req" || props.layerName === "ugl" || props.layerName === "buf" || props.layerName === "free"
    // ? props.isChecked ? "7% 5% 8% 41% 16.8% 11.5% 0%" : "7% 5% 8% 57.8% 0% 11.5% 0%"
    // : props.isChecked ? "7% 5% 8% 48% 16.8% 4.5% 0%" : "7% 5% 8% 68.3% 0% 1% 0%") : "7% 5% 8% 58% 0% 11% 0%"
    props.layerName === "req" || props.layerName === "ugl" || props.layerName === "buf" || props.layerName === "free"
    ? props.isChecked ? "7% 5% 8% 41% 16.8% 11.5% 0%" : "7% 5% 8% 57.8% 0% 11.5% 0%"
    : props.isChecked ? "7% 5% 8% 48% 16.8% 4.5% 0%" : "7% 5% 8% 68.3% 0% 1% 0%"
    )} !important;

 

  
  
  padding-left: 0px !important;
}
.ekn-map-childs-sub {
  margin-bottom: 5px;
}
   > .row {
    background: #d1e8d9;
    display: grid;
    // grid-template-columns: 5% 9% 43% 18% 10% 12%;

    grid-template-columns: ${props => (
      // props.standalone ? 
      // (props.layerName === "req" || props.layerName === "ugl" || props.layerName === "buf" || props.layerName === "free"
      // ? props.isChecked ? "5% 9% 43% 18% 10% 12%" : "5% 9% 61% 0% 10% 12%"
      // : props.isChecked ? "5% 9% 52% 18% 1% 12%" : "5% 9% 70% 0% 1% 12%") : "5% 9% 61% 0% 10% 12%" 
      props.layerName === "req" || props.layerName === "ugl" || props.layerName === "buf" || props.layerName === "free"
      ? props.isChecked ? "5% 9% 43% 18% 10% 12%" : "5% 9% 61% 0% 10% 12%"
      : props.isChecked ? "5% 9% 52% 18% 1% 12%" : "5% 9% 70% 0% 1% 12%"
      )
    }
    
    


    padding: 2px 0px 2px 6px;
    padding-left: 27px;
    align-items: center;
    border-right: ${props => (props.openSlider ? "6px solid #00C533" : "none")};
    div:hover {
      cursor: default;
    }
      svg {
        fill: #02c23e;
      }
      &:hover {
        svg {
          fill: #fff;
        }
      }
    }
  }
  > div > .expanded-icon {
      margin-left: ${props => (props.isExpanded ? "8px" : "4px")};
      margin-bottom: 5px;
      transform: rotate(${props => (props.isExpanded ? "90deg" : "0deg")});
      fill: ${props => (props.isExpanded ? "#000" : "#02c23e")};
      transition: transform 0.5s;
    }
  }

  > .expandable {
    overflow: hidden;
    max-height: ${props => (props.isExpanded ? "500vh" : 0)};
    transition: max-height 0.5s ease-in-out;
  }

`;

export const LegendRow = styled.div`
  background-color: #d1e8d9;
  font-family: 'Open Sans', sans-serif;
  color: #005b2d;
  align-items: center;
  font-size: 13px;
  display: grid;
  grid-template-columns: 6% 5% 10% 80%;

  img {
    width: 14px;
    height: 14px;
  }
`;

const Row = ({

  index,
  theme,
  layer,
  sublayers,
  layerChilds,
  child,
  isCheckbox,
  isChecked,
  checkHandler,
  layerName,
  layerText,
  history,
  region,
  showLayer,
  handleRefresh,
  handleSetVersion,
  hasListButton,
  listButtonHandler,
  listButtonVisible,
  menuId,
  handleOpacityButtonClick,
  opacityButtonId,
  tooltipType,
  opacityValue,
  openSlider,
  //
  group,
  layersVisibility,
  handleVisibilityChange2,
  standalone,
  handleSliderClick,
  opacity,
  dark,
  sliderName,
  handleGetLayerObjects,
  layers,
  setOpenSlider,
  handleCloseSlider
  //

}) => {

  const [expanded, setExpanded] = React.useState(false);

  const renderSvg = () => {
    return (
      <svg
        className="expanded-icon"
        onClick={() => setExpanded(!expanded)}
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 20 20"
      >
        <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z" />
      </svg>
    )
  }
  const renderRow = (item, j) => {
    return (
      <Row
        key={index + "." + j}
        index={index + "." + j}
        layer={layer}
        // layer={layers[item.name]}
        sublayers={item.sublayers}
        layerChilds={item.layerChilds}
        theme={theme}
        child={item.parent}
        // Checkbox / Radio props
        isCheckbox={!group.exclusive}
        isChecked={!!layersVisibility[item.name]}
        checkHandler={
          ["zo"].includes(item.name)
            ? () => { }
            : () => handleVisibilityChange2(group, item)
        }
        // HandleRefresh props
        handleRefresh={handleRefresh}
        handleSetVersion={handleSetVersion}
        // LayerIcon props
        layerName={item.name}
        layerText={item.title}
        history={item.history}
        region={region}
        // ListButton props
        // hasListButton={
        //   standalone && ![
        //     "redlines",
        //     "func",
        //     "base",
        //     "bing",
        //     "kazgeo",
        //     "garysh",
        //     "lands_label",
        //     "buildings_label"
        //   ].includes(item.name)
        // }
        hasListButton={
          ![
            "redlines",
            "func",
            "base",
            "bing",
            "kazgeo",
            "garysh",
            "lands_label",
            "buildings_label"
          ].includes(item.name)
        }
        listButtonVisible={!!layersVisibility[item.name]}
        listButtonHandler={() =>
          handleGetLayerObjects(item.name, !!layersVisibility["arrest"])
        }
        // OpacityButton props
        // menuId={`menu${item.id}`}
        menuId={`menu${index + "." + j}`}
        handleSliderClick={handleSliderClick}
        handleOpacityButtonClick={e =>
          handleSliderClick(item.name, e)
        }
        // opacityButtonId={`opacity-button-${j}`}
        opacityButtonId={`opacity-button-${index + "." + j}`}
        tooltipType={dark ? "light" : "dark"}
        opacityValue={Math.round(
          opacity[item.name] ? opacity[item.name] : 100
        )}

        //  openSlider={openSlider && sliderName === item.name}
        openSlider={setOpenSlider && sliderName === item.name}
        setOpenSlider={setOpenSlider}
        handleCloseSlider={handleCloseSlider}
        //
        group={group}
        layersVisibility={layersVisibility}
        handleVisibilityChange2={handleVisibilityChange2}
        standalone={standalone}
        opacity={opacity}
        dark={dark}
        handleGetLayerObjects={handleGetLayerObjects}
        sliderName={sliderName}
        
      //base map
      />
    )
  }


  return sublayers || layerChilds ? (
    <CollapsibleRow palette={theme} isExpanded={expanded} openSlider={openSlider} isChecked={isChecked} layerName={layerName}
    standalone={standalone}>
      <div
        // className={"row layers-row-input " + (expanded ? " layer-expanded " : "") + (sublayers.length > 0 || layerChilds.length >0 ? " ekn-map-sub" : "")
        // + (layerChilds.length >0 && expanded ? " ekn-map-childs-sub" : "")}
        className={"row layers-row-input " + (expanded ? " layer-expanded " : "") + 
        (sublayers.length > 0 || (!standalone && layerChilds.length >0) ? " ekn-map-sub" : 
        standalone && layerChilds.filter(item => !item.name.includes("_archive")).length>0 ? " ekn-map-sub" : "")
      + (layerChilds.length >0 && expanded ? " ekn-map-childs-sub" : "")}
      >
        {/* {sublayers.length > 0 || layerChilds.length>0 ? (
          <svg
            className="expanded-icon"
            onClick={() => setExpanded(!expanded)}
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 20 20"
          >
            <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z" />
          </svg>

        ) : null
        } */}
        {sublayers.length > 0 || (!standalone && layerChilds.length>0) ? renderSvg() :
        standalone && layerChilds.filter(item => !item.name.includes("_archive")).length>0 ? renderSvg() : null
        }
        {isCheckbox ? (
          <Checkbox
            theme={theme}
            //click  parent
            isChecked={isChecked}
            checkHandler={checkHandler}
          />
        ) : (
          <Radio
            theme={theme}
            isChecked={isChecked}
            checkHandler={checkHandler}
          />
        )}
        {[
          "free",
          "req",
          "eval",
          "func",
          "pdp",
          "gp",
          "voz",
          "vop",
          "inv",
          "water",
          "gas",
          "storm_water",
          "con",
          "heat",
          "sewer",
          "electr",
          "pch",
          "rst",
          "ugl",
          "ugpol",
          "base",
          "bing",
          "garysh",
          "kazgeo",

        ].includes(layerName) ? (
          <div />
        ) : (
          <LayerIcon theme={theme} layerName={layerName} />
        )}
        {history ? (
          <Select
            theme={theme}
            layerText={layerText}
            history={history}
            handleSetVersion={handleSetVersion.bind(this, layerName)}
          ></Select>
        ) : (
          <span>{layerText}</span>
        )}
        {hasListButton ? (
          <ListButton
            theme={theme}
            listButtonHandler={listButtonHandler}
            listButtonVisible={listButtonVisible}
          />
        ) : (
          <div />
        )}
        {(layerName === "req" || layerName === "ugl" || layerName === "buf" || layerName === "free") &&
          isChecked ? (
          <RefreshLayer
            theme={theme}
            handleRefresh={handleRefresh.bind(this, layerName)}
          />
        ) : (
          <div />
        )}
        <OpacityButton
          theme={theme}
          menuId={menuId}
          handleOpacityButtonClick={handleOpacityButtonClick}
          opacityButtonId={opacityButtonId}
          tooltipType={tooltipType}
          opacityValue={opacityValue}
          openSlider={openSlider}
        />
      </div>

      <div className="expandable">
        <div className="expandable-legend">
          {sublayers.map((sublayer, i) => (
            <LegendRow palette={theme} key={i} className="legend-row">
              <div></div>
              <div />
              {sublayer.src ? (
                <img src={sublayer.src} alt="" style={{ borderRadius: "3px" }} />
              ) : sublayer.svg ? (
                <LayerSvgComponent>
                  <rect x="0" y="0" height="16" width="16" style={sublayer.svg} />
                </LayerSvgComponent>
              ) : (
                <LayerIcon theme={theme} layerName={sublayer.name} />
              )}
              <span>{sublayer.title}</span>
            </LegendRow>
          ))}
        </div>
        

        <div className="expandable-layer-child">
          {layerChilds.map((item, j) => (
              !standalone ? renderRow(item, j) 
              : !item.name.includes("_archive") ? renderRow(item, j)  : <div key={index + "." + j}></div>
          ))}
        </div>
        
      </div>






    </CollapsibleRow>
  ) : (
    //first 2 input row, maplayers
    <RowComponent
      palette={theme}
      child={child}
      region={region}
      className="layers-row-input"
    >
      <div></div>
      {child ? <div></div> : null}
      {isCheckbox ? (
        <Checkbox
          theme={theme}
          isChecked={isChecked}
          checkHandler={checkHandler}
        />
      ) : (
        <Radio
          theme={theme}
          isChecked={isChecked}
          checkHandler={checkHandler}
        />
      )}
      {[
        "req",
        "eval",
        "func",
        "pdp",
        "gp",
        "voz",
        "vop",
        "inv",
        "water",
        "gas",
        "storm_water",
        "con",
        "heat",
        "sewer",
        "electr",
        "pch",
        "rst",
        "ugl",
        "ugpol",
        "base",
        "bing",
        "garysh",
        "kazgeo",

      ].includes(layerName) ? (
        <div />
      ) : (
        <LayerIcon theme={theme} layerName={layerName} />
      )}
      {history ? (
        <Select
          theme={theme}
          layerText={layerText}
          history={history}
          handleSetVersion={handleSetVersion.bind(this, layerName)}
        ></Select>
      ) : (
        <span className="layer-row-select">{layerText}</span>
      )}
      {hasListButton ? (
        <ListButton
          theme={theme}
          listButtonHandler={listButtonHandler}
          listButtonVisible={listButtonVisible}
        />
      ) : (
        <div />
      )}
      <div />
      <OpacityButton
        theme={theme}
        menuId={menuId}
        handleOpacityButtonClick={handleOpacityButtonClick}
        opacityButtonId={opacityButtonId}
        tooltipType={tooltipType}
        opacityValue={opacityValue}
      />
    </RowComponent>
  );
};

export default Row;
