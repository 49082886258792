import React, { useEffect, useState } from 'react'
import { ObjectsAndServices } from './Services';
import styled from "styled-components";
import PageNav from "../PageNav";
import axios from 'axios';
import { useTranslation } from "react-i18next";
import { Icon } from './UserProfile';
import svgAdd from '../../assets/img/add.svg';
import { order } from '@amcharts/amcharts4/.internal/core/utils/Number';
import Collapsible from "react-collapsible";
import { Scrollbars } from "react-custom-scrollbars";
import svgPlus from '../../assets/img/plus-box-fill.svg';
import svgMinus from '../../assets/img/minus-box-fill.svg';
import svgSearch from '../../assets/img/search-obj.svg';
import svgClear from '../../assets/img/search-clear.svg';
import svgLeft from '../../assets/img/paginator/left.svg';
import svgRight from '../../assets/img/paginator/right.svg';
import svgLeftOne from '../../assets/img/paginator/left-one-step.svg';
import svgRightOne from '../../assets/img/paginator/right-one-step.svg';

export const ListOrder = styled.div`
    position: absolute;
    min-height: initial;
    right: initial;
    padding: 6px 6px 0px 6px;
    border-radius: 7px;
    min-width: initial;
    max-width: initial;
    width: ${props => props.responsive.mobile ? "100%" : "540px"};
    z-index: 4;


    top: 90px;

    box-sizing: border-box;
       
    background: #fff;

   
    

    // overflow-y: hidden;
    // overflow-x: hidden;
    
    
    
    font-family: 'sf-pro-display-regular',sans-serif;
    font-size: 14px;
    box-shadow: 14px 14px 20px #00000036;

    table {
        padding: 0;
        margin: 0;
        width: 100%;
        background: #fff;
    }

    tr {
        height: 30px;
        cursor: ${props => props.orderLoaded ? "default" : "pointer"};
        color: #204024;
        background: #d1e8d9;
        border-radius: 5px;
        font-size: 14px;
        padding: 0;
        padding-left: 10px;
        margin-top: 5px;
    }

    td {
        width: 100%;
        background: none;
        // line-height: 30px;

        p {
            margin: 0;
            padding: 0;
            background: none;
        }
    }

    .order-table {
        height: auto;
        margin: 0;
        background: #fff;
        padding: 0;
    }

    .header {
        height: 30px;
        // padding: 0 0 0 62px;
        margin: 0px;
        width: 100%;
        background: #009856;
        border-radius: 5px;
        text-align: start;
        font-size: 14px;
        margin-bottom: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        border: none;
        outline: none;
    }

    button {
        width: 40px;
        margin: 0;
    }

    h2 {
        float: right;
        width: 50%;
        text-align: end;
        background: none;
    }

    .btn-header {
        float: right;
        width: 50%;
        padding: 0;
        margin: 0;
        background: none;
    }

    .queue-pagenav {
        button {
            width: initial;
            height: initial;
            margin-left: 6px;
            background: rgba(247,147,30,1);
        }
    }

    .queue-pagenav {
        background-color: #02C23E;
        width: initial;
        max-width: initial;
        padding-left: 8px;
    }
`;

export const ModalBody = styled.div`
    display: block;
    padding: 0 !important;

    .scrollbar-view {
        padding-bottom: 10px !important;
    }

    div.Collapsible__contentOuter {
        background-color: #fff;
      }
    
      .Collapsible {
        .Collapsible__trigger {
          .legend-group {
            width: 260px;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            color: #fff;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            font-weight: normal;
            line-height: 40px;
          }
        }
    
        .legend-title {
          margin-left: 20px;
        }

        .collapsible-img {
            width: 30px;
            height: 30px;
            cursor: pointer;
            background-position: center !important;
        }
    }

    .search-fld {
        border: 1px solid #B4CFBC;
        box-sizing: border-box;
        border-radius: 5px;
        display: flex;
        align-items: center;
        width: 50%;
        justify-content: start;
        height: 32px;
        padding-left: 8px;
        margin-bottom: 12px
        width: 100%;
    
        input {
          border: none;
          font-family: 'Roboto';
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 12px;
          color: #294A37;
          width: 100%;
          background-color: #fff
        }
    
        input:focus {
          outline: none;
        }
    }

    .search-btn {
        width: 24px;
        height: 24px;
        background-color: #A0B6A6;
        -webkit-mask: url(${svgSearch}) no-repeat;
        mask: url(${svgSearch}) no-repeat;
        cursor: pointer;
    }

    .search-clear-btn {
        margin-top: 5px;
        width: 24px;
        height: 24px;
        background-color: #A0B6A6;
        -webkit-mask: url(${svgClear}) no-repeat;
        mask: url(${svgClear}) no-repeat;
        cursor: pointer;
    }

    .register-table {
        height: auto;

        table {
            border: 1px solid #B6D4C0; 
            border-spacing: 0;
            width: 100%;

            thead {
                tr {
                    width: 100%;
                    height: auto;
                    display: flex;
                    margin: 0;
                    padding: 0;
                    background: #fff;
    
                    td {
                        padding: 3px 8px;
                        border: 1px solid #B6D4C0;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        background: #E0F0E5;
                        color: #008A78;
                    }
                }
            }

            tbody {
                tr {
                    width: 100%;
                    height: auto;
                    display: flex;
                    margin: 0;
                    padding: 0;
                    background: #fff;
                    cursor: pointer;
    
                    td {
                        padding: 3px 8px;
                        border: 1px solid #B6D4C0;
                        height: auto;
                        min-height: 30px;
                        overflow: hidden;
                        position: relative;
                    }
                }
            }

            tbody tr:hover {
                background: #D8EFE0; /* Цвет фона при наведении */
            }

            .even {
                background: #F8FFFA;
            }
        }

        .paginator {
            display: flex;
            flex-direction: row;
            // align-items: flex-start;
            justify-content: center
            padding: 0px;
            gap: 2px;
            margin-top: 5px;
            margin-bottom: 5px;

            .page-button {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 2px;
                gap: 10px;
                width: 20px;
                height: 20px;
                background: #FFFFFF;
                border: 0.5px solid #008A78;
                border-radius: 2px;
                cursor: pointer;
    
                .page-number {
                    font-family: 'SF UI Display';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 7px;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    color: #333333;
                }
            }
    
            .page-button-active {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 2px;
                gap: 10px;
                width: 20px;
                height: 20px;
                background: #008A78;
                border-radius: 2px;
                cursor: pointer;
    
                .page-number {
                    font-family: 'SF UI Display';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 7px;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    color: #FFFFFF;
                }
            }

            .page-fisrt {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 2px;
                width: 20px;
                height: 20px;
                background: transparent url('${svgLeft}') no-repeat;
                -moz-background-size: 100%; /* Firefox 3.6+ */
                -webkit-background-size: 100%; /* Safari 3.1+ и Chrome 4.0+ */
                -o-background-size: 100%; /* Opera 9.6+ */
                background-size: 100%; /* Современные браузеры */;
                border-radius: 2px;
                cursor: pointer;
            }

            .page-left-one {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 2px;
                width: 20px;
                height: 20px;
                background: transparent url('${svgLeftOne}') no-repeat;
                -moz-background-size: 100%; /* Firefox 3.6+ */
                -webkit-background-size: 100%; /* Safari 3.1+ и Chrome 4.0+ */
                -o-background-size: 100%; /* Opera 9.6+ */
                background-size: 100%; /* Современные браузеры */;
                border-radius: 2px;
                cursor: pointer;
            }

            .page-last {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 2px;
                width: 20px;
                height: 20px;
                background: transparent url('${svgRight}') no-repeat;
                -moz-background-size: 100%; /* Firefox 3.6+ */
                -webkit-background-size: 100%; /* Safari 3.1+ и Chrome 4.0+ */
                -o-background-size: 100%; /* Opera 9.6+ */
                background-size: 100%; /* Современные браузеры */;
                border-radius: 2px;
                cursor: pointer;
            }

            .page-right-one {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 2px;
                width: 20px;
                height: 20px;
                background: transparent url('${svgRightOne}') no-repeat;
                -moz-background-size: 100%; /* Firefox 3.6+ */
                -webkit-background-size: 100%; /* Safari 3.1+ и Chrome 4.0+ */
                -o-background-size: 100%; /* Opera 9.6+ */
                background-size: 100%; /* Современные браузеры */;
                border-radius: 2px;
                cursor: pointer;
            }
        }

        .no-result {
            background: none;
            font-size: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #26602D;
            margin: 5px;
        }
    }
`;

export default function Orders({
  orders, handleOrders, setOrder, palette,
  handleSelectOrder, handleClickOrder,
  orderLoaded, responsive, handleDocsType, handleClickOrders, setNotification, region
}) {

  const { t, i18n } = useTranslation();
  const style1 = {
      backgroundColor: "#fff",
      borderRadius: "5px 5px 0px 0px",
  };
  const style2 = {
      backgroundColor: "#fff",
  };
  const style3 = {
      backgroundColor: "#fff",
      borderRadius: "0px 0px 5px 5px",
  };
  const [typesServices, setTypesServices] = useState([]);
  const [expanded, setExpanded] = useState({});
  const [searchText, setSearchText] = useState('');
  const listStyleLegend = [style1, style2, style3];
  const searchRef = React.useRef();
  const [lang, setLang] = useState(i18n.language)

  const regionNote = () => {
    setNotification({
      title: t("service.region-error-title"),
      text: t("service.region-error-text")
    })
  }

  useEffect(() => {
    getOrders();
    return () => {
        setTypesServices({}); 
      };
}, [])

const handleExpanded = (layer, value) => {
    const x = {};
    x[layer.name] = value;
    setExpanded(Object.assign({}, expanded, x));
};


  const getOrders = () => {
    axios({
        url: `${window.REST_URL}/cabinet/order/all?lang=${lang}&pageNum=1&pageSize=10`,
        method: 'GET',
        mode: "cors",
        credentials: "include"
    })
    .then((res) => setTypesServices(res.data))
    .catch((err) => {
        console.log(err);
    });
}

  const searchOrder = () => {
    let searchText = searchRef.current.value
    setSearchText(searchText);

    axios({
        url: `${window.REST_URL}/cabinet/order/all?lang=${lang}&pageNum=1&pageSize=10&search=${searchText}`,
        method: 'GET',
        mode: "cors",
        credentials: "include"
    })
    .then((res) => {
  setTypesServices(res.data);
    })
    .catch((err) => {
        console.log(err);
    });
}

const searchClear = () => {
  setSearchText('')
  searchRef.current.value = '';
  getOrders()
}

const nextPage = (service) => {
  let startPage = 1
  if(service.startPage !== undefined) {
      startPage = service.startPage
  }
  let page = startPage + 10

  if (page <= Math.ceil(service.count/10)) {
      goPage(page, service.code)
  }
}

const prevPage = (service) => {
  let startPage = 1
  if(service.startPage !== undefined) {
      startPage = service.startPage
  }
  let page = startPage - 10

  if (page > 0) {
      goPage(page, service.code)
  }
}

const goPage = (page, seviceCode) => {
  let payload = `&service=${seviceCode}`
  if(searchText) {
      payload += `&search=${searchText}`
  }

  //вычисляю чтобы было кратное 10, но с учетом того чтобы это не было самой 10, 20, 30 и т.д.
  // потому что тогда страницы начнуться с этого числа
  let startPage = ((page - 1) - (page - 1) % 10) + 1;

  axios({
      // url: `${window.REST_URL}/cabinet/zkr/order?lang=${lang}&pageNum=${page}&pageSize=10${payload}`,
      url: `${window.REST_URL}/cabinet/order/all?lang=${lang}&pageNum=${page}&pageSize=10${payload}`,
      method: 'GET',
      mode: "cors",
      credentials: "include"
  })
  .then((res) => {
      let newTypesServices = [];
      typesServices.forEach(obj => {
          if(seviceCode == obj.code) {
              obj.orders = res.data[0].orders
              obj.currPage = page
              obj.startPage = startPage
              newTypesServices.push(obj)
          } else {
              newTypesServices.push(obj)
          }
      });

      setTypesServices(newTypesServices);
  })
  .catch((err) => {
      console.log(err);
  });
}

const getPaginator = (service) => {
  let startPage = 1
  let maxNumber = Math.ceil(service.count/10);
  if(service.startPage !== undefined) {
      startPage = service.startPage
  }

  let arrPages = [];
  for(let i = 0; i <= 9; i++) {
      let page = startPage + i;
      if(maxNumber < page) {
          break;
      }
      arrPages.push(page);
  }

  return (
      <div className='paginator'>
          <div className='page-fisrt' onClick={() => goPage(1, service.code)}></div>
          {service.count>100 && <div className='page-left-one' onClick={() => prevPage(service)}></div>}

          {arrPages.map((page, i) =>
              <div className={
                      (service.currPage != undefined && service.currPage == page) || (service.currPage == undefined && page == 1) ? 
                          "page-button-active" : 
                          "page-button"
                  } onClick={() => goPage(page, service.code)} key={i}>
                  <span className="page-number">{ page }</span>
              </div>
          )}

          {service.count>100 && <div className='page-right-one' onClick={() => nextPage(service)}></div>}
          <div className='page-last' onClick={() => goPage(Math.ceil(service.count/10), service.code)}></div>
      </div>
  );
}

  // const listOrders = () => {
  //   return (
  //     orders.orders.map((item, i) => (
  //       <tr key={i} onClick={() => {
  //         if (!orderLoaded) {
  //           setOrder({ services: [], objects: [], providers: [],  docs: [], orderLoading: true });
  //           handleSelectOrder(item.id);
  //           handleClickOrder(true);
  //         }
  //       }}>
  //         <td><p>{"Заказ №" + item.id + " (" + item.statusName + ")"}</p></td>
  //       </tr>
  //     ))
  //   )
  // };

  return (
    <ListOrder orderLoaded={orderLoaded} responsive={responsive}>
      <button
        className="header"
        onClick={() => {
            if (!region || region.code === "000") {
                regionNote();
            } else if (!orderLoaded) {
                setOrder({ services: [], objects: [], providers: [], docs: [] });
                handleClickOrder(true);
            }
        }
        }>
        <Icon url={svgAdd} className="icon" />
        {t("order.add-order")}
      </button>
      
      <ModalBody responsive={responsive}>
        <div className="search-fld">
            <div className="search-btn" onClick={
                searchOrder
            } />
            <input style={{paddingLeft: "6px"}} placeholder={t("zkr.order-search")} title={t("order.search-title")} ref={searchRef} defaultValue={searchText ? searchText : ''} onKeyDown={(e) => e.key === "Enter" && searchOrder()} />
            {searchText != '' && (
                <div className="search-clear-btn" onClick={
                    searchClear
                } />
            )}
        </div>
        <Scrollbars
            autoHeight={true}
            // autoHide={true}
            // autoHeightMax={"34vh"}
            autoHeightMax={"500px"}
            renderView={props => <div {...props} className="scrollbar-view" />}
        >
          { typesServices && typesServices.length>0 ? 
          typesServices.map((service, i) => (
              <Collapsible
                  key={i}
                  trigger={
                      <div className="register-table" style={listStyleLegend[i]}>
                          <table>
                              <tbody>
                                  <tr>
                                      <td style={{ width: "7%" }}>{
                                          expanded[service.name] ? 
                                              <Icon url={svgMinus} className="icon" />
                                          :
                                              <Icon url={svgPlus} className="icon" />
                                      }</td>
                                      <td style={{ width: "83%" }}>{service.name}</td>
                                      <td style={{ width: "10%" }}>{service.count}</td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                  }
                  transitionTime={200}
                  easing="ease-out"
                  open={expanded[i]}
                  onOpening={() => handleExpanded(service, true)}
                  onClosing={() => handleExpanded(service, false)}
              >
                  <div className="register-table">
                      <table>
                          <thead>
                              <tr>
                                  <td style={{ width: "7%" }}>№</td>
                                  <td style={{ width: "12%" }}>{t("order.name")}</td>
                                  {/* <td style={{ width: "32%" }}>{t("zkr.order-applicant")}</td> */}
                                  <td style={{ width: "27%" }}>{t("zkr.order-status")}</td>
                                  <td style={{ width: "27%" }}>{t("zkr.order-start")}</td>
                                  <td style={{ width: "27%" }}>{t("zkr.order-update")}</td>
                              </tr>
                          </thead>
                          <tbody>
                          { service.orders && service.orders.map((item, i) => (
                              <tr key={i} onClick={() => {
                                if (!orderLoaded) {
                                  setOrder({ services: [], objects: [], providers: [],  docs: [], orderLoading: true });
                                  handleSelectOrder(item.id, service.code);
                                  handleClickOrder(true);
                                }
                              }}>
                                  <td style={{ width: "7%" }}>{++i}</td>
                                  <td style={{ width: "12%" }}>{item.id}</td>
                                  {/* <td style={{ width: "32%" }}>{item.bin || item.iin}</td> */}
                                  <td style={{ width: "27%" }}>{item.statusName}</td>
                                  <td style={{ width: "27%" }}>{item.timeStart}</td>
                                  <td style={{ width: "27%" }}>{item.timeUpdate}</td>
                              </tr>
                          )) }
                          </tbody>
                      </table>
                      { service.count>10 && getPaginator(service) }
                  </div>
              </Collapsible>
          )) 
          : searchText ? <p style={{fontSize: "16px", textAlign: "center"}}>{t("order.find")}</p> : ''
          }
        </Scrollbars>
      </ModalBody>
      {/* {orders.count > 10 && <PageNav
        className="queue-pagenav"
        count={orders.count}
        getObjects={getOrders}
        theme={palette}
        updateObject={handleOrders}
      />} */}
      <button
        className="header"
        onClick={handleClickOrders}
        >
        {t("egov-service.close")}
      </button>
    </ListOrder>
  );
}