import React, { useEffect, useCallback, useState, createContext, useRef } from "react";
import "./App.css";
import "./index.css";
import { Map, View, Feature } from "ol";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import ImageLayer from "ol/layer/Image";
import { Vector, TileWMS, BingMaps } from "ol/source";
import { ImageArcGISRest } from "ol/source";
import { GeoJSON, WKT } from "ol/format";
import { MousePosition, OverviewMap, ScaleLine } from "ol/control.js";
import { Draw, Interaction, Modify, Snap } from "ol/interaction";
import { bbox as bboxStrategy } from "ol/loadingstrategy";
import { Circle, Fill, Stroke, Style, Text } from "ol/style.js";
import { createStringXY } from "ol/coordinate.js";
import { MultiPoint, MultiPolygon, Point } from "ol/geom";
import * as olExtent from "ol/extent";
import EknMapLayers from "./components/Layers/EknMapLayers";
import EknMapInfo from "./EknMapInfo";
import EknMapLandRequest from "./components/Request";
import Header from "./components/Header";
import UserProfile from "./components/Cabinet/UserProfile";
import MobileProfile from "./components/Cabinet/MobileProfile";
import Stats from "./components/Stats";
import { transform } from "ol/proj";
import { register } from "ol/proj/proj4";
import proj4 from "proj4";
import getCoordSystem from "./utils/info/getCoordSystem";
import getJsonLayers from "./utils/layers/getJsonLayers";
import "./buttons.css";
import Line from "./components/Measure/Line";
import Shapes from "./components/Shapes";
import getRegionID from "./utils/info/agroLookup";
import getFireDepts from "./utils/info/getFireDepts";
import getSeasonDyn from "./utils/info/getSeasonDyn";
import Info from "./components/Info";
import SideBar from "./components/SideBar/SideBar";
import svgLegend from "./assets/img/legend.svg";
import svgPanKz from "./assets/img/pan_kz.svg";
import svgPanDistrict from "./assets/img/pan_district.svg";
import svgZoomIn from "./assets/img/zoom_in.svg";
import svgZoomOut from "./assets/img/zoom_out.svg";
import MapButton from "./components/MapButton";
import LayerGroup from "ol/layer/Group";
import * as CommonUtil from "./utils/common.js";
import { useTranslation } from "react-i18next";
import HeaderEkgn from '../src/components/HeaderEkgn'
import SideBarEkgn from '../src/components/SideBar/SideBarEkgn';
import { tsel } from "./utils/info/garishDistrictId";
import Notification, { NF_ERROR, NF_NOTICE } from './components/Notification';
import styled from "styled-components";
import i18n from "./i18n";
import ConfirmDialog from "./components/ConfirmDialog";
import Queue from "./components/Queue/Queue";
import Regions from "./components/Queue/Regions";
import RegionOnline from "./components/Queue/RegionOnline";
import ListService from './components/Cabinet/ListService';
import RegistrationLecensee from './components/Cabinet/RegistrationLecensee';
import LecenseeWorkOrders from './components/Cabinet/LecenseeWorkOrders';
import ListMyObjs from './components/Cabinet/ListMyObjs';
import SearchObject from './components/Cabinet/SearchObject';
import FormNewObj from './components/Cabinet/FormNewObj';
import ProfileObjects from './components/Cabinet/ProfileObjects';
import axios from 'axios';
import { sendOrder } from "./RestUtils";
import ObjectArrests from './components/Cabinet/ObjectArrests';
import EknMapLegend from "./components/Legend/EknMapLegend";
import { findPoint } from "./util";
import Burger from "./components/Burger";
import ChooseRegion from "./components/ChooseRegion";
import QueueMobile from "./components/Queue/QueueMobile";
import RegionsMobile from "./components/Queue/RegionsMobile";
import userInfoSvg from "./assets/img/userinfo.svg";
import Orders from "./components/Cabinet/Orders";
import Services from "./components/Cabinet/Services";
import PaymentService from "./components/Cabinet/PaymentService";
import NotifTab from "./components/Cabinet/NotifTab";
import EmailVerify from "./components/2FA/EmailVerify";
import EmailError from "./components/2FA/EmailError";
import Cookies from "js-cookie";
import LiabilityNotice from "./components/LiabilityNotice";
import { EgovService } from "./components/Cabinet/services/EgovService";
import { EgovServiceCost } from "./components/Cabinet/services/EgovServiceCost";
import { getDistricts} from "./utils/RestApi";
import DistrictLayers from "./components/Layers/DistrictLayers";
import ActUstForm from "./components/Cabinet/services/ActUstForm";
import ListProviders from "./components/Cabinet/ListProviders";
import LayerBundle from "./components/Layers/LayerBundle";
import {matchScale} from "./util";
import ClusterLayer from './components/Layers/ClusterLayer'; 
import Help from './components/Help';
import PlanArchive from "./components/Cabinet/PlanArchive.js";

export const MODE_SELECT = 0;
export const MODE_DRAW = 1;
export const MODE_EDIT = 2;
export const MODE_MEASURE = 3;
export const MODE_SHAPE = 4;
export const MODE_OFF = 5;
export const MODE_AUCTION_SELECT = 5;
export const MODE_SEARCH = 6;
export const MODE_CHOOSE_OBJECT = 7;
export const MODE_SELECT_PDP = 8;

proj4.defs(
  "EPSG:32638",
  "+proj=utm +zone=38 +ellps=WGS84 +datum=WGS84 +units=m +no_defs",
);
proj4.defs(
  "EPSG:32639",
  "+proj=utm +zone=39 +ellps=WGS84 +datum=WGS84 +units=m +no_defs",
);
proj4.defs(
  "EPSG:32640",
  "+proj=utm +zone=40 +ellps=WGS84 +datum=WGS84 +units=m +no_defs",
);
proj4.defs(
  "EPSG:32641",
  "+proj=utm +zone=41 +ellps=WGS84 +datum=WGS84 +units=m +no_defs",
);
proj4.defs(
  "EPSG:32642",
  "+proj=utm +zone=42 +ellps=WGS84 +datum=WGS84 +units=m +no_defs",
);
proj4.defs(
  "EPSG:32643",
  "+proj=utm +zone=43 +ellps=WGS84 +datum=WGS84 +units=m +no_defs",
);
proj4.defs(
  "EPSG:32644",
  "+proj=utm +zone=44 +ellps=WGS84 +datum=WGS84 +units=m +no_defs",
);
proj4.defs(
  "EPSG:32645",
  "+proj=utm +zone=45 +ellps=WGS84 +datum=WGS84 +units=m +no_defs",
);
register(proj4);

const LayerType = { EGKN: 1, ARCMAP: 2, GGK: 3, GGK_GS: 4 };

const editStyle = [
  new Style({
    stroke: new Stroke({
      color: "#f00",
      width: 1,
    }),
    fill: new Fill({
      color: "rgba(255, 255, 255, 0.3",
    }),
    text: new Text({
      font: "12px Calibri,sans-serif",
      fill: new Fill({
        color: "#000",
      }),
      stroke: new Stroke({
        color: "#f00",
        width: 3,
      }),
    }),
  }),
  new Style({
    image: new Circle({
      radius: 5,
      fill: new Fill({
        color: "orange",
      }),
    }),
    geometry: function (feature) {
      // return the coordinates of the first ring of the polygon
      var coordinates = feature.getGeometry().getCoordinates()[0];
      return new MultiPoint(coordinates);
    },
  }),
];

const NotifWrapper = styled.div`
  position: absolute;
  right :40px;
  top: 10%;
  width: 100%;
  max-width: 200px;
  background: rgba(75,74,75,0.4);
  z-index: 1;
  color: #fff;
  text-align: center;
  border-radius: 5px;
}
  `;
const selectStyle = [
  new Style({
    stroke: new Stroke({
      color: "#f00",
      width: 1,
    }),
    fill: new Fill({
      color: "rgba(255, 255, 255, 0.3",
    }),
    text: new Text({
      font: "12px Calibri,sans-serif",
      fill: new Fill({
        color: "#000",
      }),
      stroke: new Stroke({
        color: "#f00",
        width: 3,
      }),
    }),
  }),
];

const borderStyle = new Style({
  stroke: new Stroke({
    color: "#f00",
    width: 2,
  }),
});

const extent = [5055850, 5074195, 9747249, 7432125];

let overviewMap;

let districtLayers;

let ggkGsLayers;

let editingPolygon = -1;

export const NotificationContext = createContext(null);
export const ConfirmContext = createContext(null);
export const RegionContext = createContext(null);

function EknMap(props) {

  const [windowWidth, setWindowWidth] = React.useState();
  const [map, setMap] = React.useState();
  const [layers, setLayers] = React.useState();
  const [layerConfig, setLayerConfig] = React.useState();
  const [openLayers, setOpenLayers] = React.useState(false);
  const [openLayersMaxHeight, setOpenLayersMaxHeight] = React.useState();
  const [openLayersTop, setOpenLayersTop] = React.useState();
  const [openLegend, setOpenLegend] = React.useState(false);
  const [setOpenLegendMaxHeight] = React.useState();
  const [setOpenLegendTop] = React.useState();
  const [openInfo, setOpenInfo] = React.useState(false);
  const [openInfoMaxHeight, setOpenInfoMaxHeight] = React.useState(0);
  const [mode, setMode] = React.useState(MODE_SELECT);
  const [landRequestMaxHeight, setLandRequestMaxHeight] = React.useState();
  const [landRequestTop, setLandRequestTop] = React.useState();
  const [openProfile, setOpenProfile] = React.useState(false);
  const [openRegion, setOpenRegion] = React.useState(false);
  const [showRegionNotSelected, setShowRegionNotSelected] = React.useState(
    false,
  );
  const { i18n } = useTranslation();
  const [showStats, setShowStats] = React.useState(false);
  const [showQueue, setShowQueue] = React.useState(false);
  const [showRegions, setShowRegions] = React.useState(false);
  const [showRegionsOnline, setShowRegionsOnline] = React.useState(false);
  const [showAnalytic, setShowAnalytic] = React.useState(false);
  const [openRegionMaxHeight, setOpenRegionMaxHeight] = React.useState();
  const [searchResult, setSearchResult] = React.useState({});
  const [features, setFeatures] = React.useState([]);
  const [servicesJson, setServicesJson] = React.useState([])
  const [dark, setDark] = React.useState(false);
  const [userInfo, setUserInfo] = React.useState();
  const [egovRequest, setEgovRequest] = React.useState();
  const [region, setRegion] = React.useState();
  const [getObjects, setGetObjects] = React.useState({});
  const [arrestIsChecked, setArrestIsChecked] = React.useState();
  const [showSidebar, setShowSidebar] = React.useState(true);
  const [selectedMenu, setSelectedMenu] = React.useState(-1);
  const [perimeter, setPerimeter] = useState(0);
  const [regionsData, setRegionsData] = useState();
  const [notification, setNotification] = useState(null);
  const [simpleNotification, setSimpleNotification] = useState(false);
  const [layersVisibility, setLayersVisibility] = useState({ "base": true });
  const [errors, setErrors] = React.useState();
  const [confirm, setConfirm] = React.useState(null);
  const [prevMode, setPrevMode] = React.useState(MODE_SELECT);
  const [service, setService] = React.useState([]);
  const [showListService, setShowListService] = useState(false);
  const [docs, setDocs] = React.useState([]);
  const [orders, setOrders] = React.useState({ orders: [] });
  const [showListMyObjs, setShowListMyObjs] = useState(false);
  const [showLandIdObj, setShowLandIdObj] = useState(false);
  const [showProvider, setShowProviders] = useState(false);
  const [showObjSearch, setShowObjSearch] = useState(false);
  const [myObjs, setMyObjs] = React.useState([]);
  const [providers, setProviders] = React.useState([])
  const [myObjLoaded, setMyObjLoaded] = React.useState(false);
  const [order, setOrder] = React.useState({ services: [], objects: [], providers: [], docs: []});
  const [foundObject, setFoundObject] = React.useState();
  const [isNewObject, setIsNewObject] = React.useState(false);
  const [orderLoaded, setOrderLoaded] = React.useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [showOrders, setShowOrders] = useState(false);
  const [showOrder, setShowOrder] = useState(false);
  const [showNotif, setShowNotif] = useState(false);
  const [notifs, setNotifs] = useState({ notifs: [] });
  const [displayFormCreate, setDisplayFormCreate] = useState(false);
  const [dicts, setDicts] = useState({});
  const [createdObject, setCreatedObject] = useState({});
  const [showProfileObjects, setShowProfileObjects] = useState(false);
  const [profileObjectsLoading, setProfileObjectsLoading] = useState(false);
  const [profileObjects, setProfileObjects] = useState([]);
  const [showObjectArrests, setShowObjectArrests] = useState(false);
  const [objectArrests, setObjectArrests] = useState();
  const [objectArrestsLoading, setObjectArrestsLoading] = useState(false);
  const [arrestsKadNum, setArrestsKadNum] = useState();
  const [showSelector, setShowSelector] = useState(true);
  const [analyticLoading, setAnalyticLoading] = useState(false);
  const [isCosmoUp, setIsCosmoUp] = useState(true);
  const [openLayersLegend, setOpenLayersLegend] = useState(false);
  const [showRegistrationLecensee, setShowRegistrationLecensee] = useState(false); //позволяет открывать диалог регистрационных данных Лицензиата 
  const [isShowWorkOrders, setShowWorkOrders] = useState(false); //позполяет открывать Заказы на землеустроительные работы
  const [isShowActUstForm, setShowActUstForm] = useState(false);
  const [ates, setAtes] = useState([]);
  const [regionsOnline, setRegionsOnline] = useState([]);
  const [ate, setAte] = useState();
  const [nameAndInitials, setNameAndInitials] = useState();
  const [searchQueueNum, setSearchQueueNum] = useState();
  const [searchQueueFromReg, setSearchQueueFromReg] = useState(false);
  const [queueNum, setQueueNum] = useState();
  const [ateChain, setAteChain] = useState('');
  const [showBurger, setShowBurger] = useState(false);
  const [chooseRegionMob, setChooseRegionMob] = useState(false);
  const [openRegionsMob, setOpenRegionsMob] = useState(false);
  const [openQueueMob, setOpenQueueMob] = useState(false);
  const [notifPage, setNotifPage] = useState(0);
  const userMobIconRef = useRef();
  const [showEmailVerify, setShowEmailVerify] = useState(false);
  const [showEmailError, setShowEmailError] = useState(false);
  const [showLiabilityNotice, setShowLiabilityNotice] = useState(false);
  const [egovService, setEgovService] = useState();
  const [landInd, setLandInd] = useState();
  const [showDrawLand, setShowDrawLand] = useState(false);
  const [cadNum, setCadNum] = useState();
  const [serviceId, setServiceId] = React.useState();
  const [showHelp, setShowHelp] = React.useState(false);
  const [showPlanArchive, setShowPlanArchive] = React.useState(false);
  const { t } = useTranslation();

  let orderItemLoaded = false;
  let docTypeLoaded = false;

  const [mousePosition] = useState(
    new MousePosition({
      coordinateFormat: createStringXY(4),
      className: "custom-mouse-position",
      undefinedHTML: "&nbsp;",
    }),
  );
  const [openService, setOpenService] = useState(false)
  const darkPalette = {
    text: "#ffffff",
    black: "#000000",
    primary: "#303030",
    secondary: "#606060",
    additional: "#484848",
    accent: "#909090",
    turq: "#2EA297",
    darkgreen: "#005A2F",
    green: "#02C23E",
    lightgreen: "#B4D1BD",
    highgreen: "#D3E8DA",
    blue: "rgba(29, 114, 236, 1)",
    lightblue: "#789A8A",
    middleblue: "#587064",
    blue1: "#789A8A",
    blue2: "#688577",
    blue3: "#587064",

  };

  const lightPalette = {
    black: "#000000",
    text: "#000000",
    primary: "#DCDCDC",
    secondary: "#ffffff",
    additional: "#C5C5C5",
    accent: "#909090",
    turq: "#2ea297",
    darkgreen: "#005A2F",
    green: "#02C23E",
    lightgreen: "#B4D1BD",
    highgreen: "#D3E8DA",
    blue: "rgba(29, 114, 236, 1)",
    lightblue: "#789A8A",
    middleblue: "#587064",
    blue1: "#789A8A",
    blue2: "#688577",
    blue3: "#587064",
  };

  const findGroup = (groups, groupName) => groups.filter(group => group.name === groupName)[0];
  const filterGroup = (groups, groupName) => groups.filter(group => group.name !== groupName);

  // Интервал проверки доступности cosmo сервера
  // Отключение слоев если не доступен
  useEffect(() => {
    var interval = setInterval(() => {
      if (isCosmoUp) {
        fetch(`${window.COSMO_URL}/CosmoServer/up`)
          .catch(() => {
            const groups = layerConfig ? layerConfig : [];
            const cosmoGroup = findGroup(groups, 'cosmo');
            cosmoGroup && cosmoGroup.layers.forEach(item => {
              layersVisibility[item.name] = false;
              setLayersVisibility(Object.assign({}, layersVisibility));
              handleShowLayer(item, item.name, false);
            });

            groups && setLayerConfig(filterGroup(groups, 'cosmo'));
            setIsCosmoUp(false);
          })
      }
    }, 1000 * 60);
    return () => clearInterval(interval);
  }, [layerConfig])

  // Реакция на изменение размеров окна
  useEffect(() => {
    updateDimensions();

    window.addEventListener('resize', updateDimensions);
    return () =>
      window.removeEventListener('resize', updateDimensions);
  }, [])

  const updateDimensions = () => {
    const width = window.innerWidth
    setWindowWidth(width)
  }

  const responsive = {
    mobile: windowWidth < 1024
  }

  const handleSelect = useCallback(
    (selectedObject, srs, type="object") => {
      const source = layers.select.getSource();
      if (!selectedObject) {
        source.clear();
        return;
      }
      if (!Array.isArray(selectedObject)) {
        selectedObject = [selectedObject];
      } else if (selectedObject.length == 0) {
        source.clear();
        return;
      }
      selectedObject.forEach(obj => {
        if (obj.id) {
          source.getFeatures().forEach(feature => {
            if (feature.get('objId') === obj.id) {
              source.removeFeature(feature);
            }
          })
        } else {
          source.clear();
        }
        const view = map.getView();
        const extent = olExtent.createEmpty();
        // Main WKT
        let selGeom;
        if (obj.wkt) {
          obj.wkt.split(";").map(value => new WKT().readGeometryFromText(value)).forEach(geom => {
            selGeom = geom
              .clone()
              .transform(srs || "EPSG:" + region.srs, view.getProjection());
            source.addFeature(
              new Feature({
                geometry: selGeom,
                type: type,
                objId: obj.id,
                strokeColor: obj.strokeColor
              }),
            );
            olExtent.extend(extent, selGeom.getExtent());
          });
        } else if (obj.geometry) {
          selGeom = obj.geometry
            .clone()
            .transform(srs || "EPSG:" + region.srs, view.getProjection());
          source.addFeature(
            new Feature({
              geometry: selGeom,
              type: type
            }),
          );
          olExtent.extend(extent, selGeom.getExtent());
        }
        // Parts
        if (obj.parts) {
          obj.parts.forEach(part => {
            source.addFeature(
              new Feature({
                geometry: new WKT().readGeometryFromText(part.wkt),
                type: 'servitude',
                objId: obj.id
              }),
            );
          });
        }
        if (obj.pointNum) {
          let pt = findPoint(selGeom, obj.pointNum);
          if (pt) {
            view.fit([pt[0], pt[1], pt[0], pt[1]], { duration: 500, maxZoom: 25 });
          } else {
            view.fit(extent, { duration: 500, maxZoom: 19 });
          }
        } else if (!olExtent.isEmpty(extent)) {
          view.fit(extent, { duration: 500, maxZoom: 19 });
        }
      });
    },
    [map, layers, region],
  );

  const handleRefresh = useCallback(
    layerName => {
      layers[layerName].getSource().updateParams({ time: Date.now() });
      if (layerName === "lands") {
        layers.lands_label.getSource().clear();
      } else if (layerName === "part") {
        layers.part.getSource().clear();
      } else if (layerName === "buildings") {
        layers.buildings_label.getSource().clear();
      } else if (layerName === "req") {
        layers.req_label.getSource().clear();
      } else if (layerName === "free") {
        layers.free_label.getSource().clear();
      }
    },
    [layers],
  );

  const handleSetVersion = useCallback(
    (layerName, version) => {
      layers[layerName].getSource().updateParams({
        cql_filter: `ver='${version}'`,
        time: Date.now(),
      });
    },
    [layers],
  );

  const handleSetLayerOpacity = useCallback(
    (name, value) => {
      layers[name].setOpacity(value / 100);
    },
    [layers]
  );

  const handleShowLayer = useCallback(
    (layerItem, layerName, show, region) => {
      if (layerItem) {
        layerName = layerItem.name;
      }
      const gsUrl = window.GS_URL;

      if (layerName === "pch_label") {
        let layer = layers.pch;
        let params = layer.getSource().getParams();
        params.styles = show ? "egkn:pch_label_style" : "egkn:pch_style";
        layer.getSource().updateParams(params);
      } else if (layerName === "rst_label") {
        let layer = layers.rst;
        let params = layer.getSource().getParams();
        params.styles = show ? "egkn:rst_label_style" : "egkn:rst_style";
        layer.getSource().updateParams(params);
      } else if (layerName in layers) {
        layers[layerName].setVisible(show);
        if (layerName === "lands") {
          layers.lands_label.setVisible(show);
        } else if (layerName === "part") {
          layers.part.setVisible(show);
        } else if (layerName === "ul") {
          layers.ul.setVisible(show);
        } else if (layerName === "buildings") {
          layers.buildings_label.setVisible(show);
        } else if (layerName === "req") {
          layers.req_label.setVisible(show);
        } else if (layerName === "free") {
          layers.free_label.setVisible(show);
          // layers.cluster.setDistrictId(region.id);
          // layers.cluster.setVisible(show);
        } 
      } else if (districtLayers.hasLayer(layerName)) {
        districtLayers.setVisible(layerName, show);
      } else if (ggkGsLayers.hasLayer(layerName)) {
        ggkGsLayers.setVisible(layerName, show);
      } else {
        // Пользовательский слой
        layers[layerName] = new TileLayer({
          source: new TileWMS({
            url: `${gsUrl}/egkn/wms`,
            params: { LAYERS: `egkn:${layerName}`, STYLES: 'egkn:ext_01', TILED: true },
            serverType: "geoserver",
            projection: `EPSG:32640`,
            transition: 0,
          }),
          visible: show,
        });
        map.addLayer(layers[layerName]);
      }
    },
    [map, layers]
  );

  const handleCreateBuffer = useCallback(
    (layerName, objProps, radius) => {
      createBuffer(layerName, objProps.gid, radius).then(() => {
        handleShowLayer(null, "buf", true);
        handleRefresh("buf");
      });
    },
    [handleRefresh, handleShowLayer],
  );

  const handleDeleteBuffer = useCallback(
    objProps => {
      deleteBuffer(objProps.gid).then(() => {
        handleRefresh("buf");
      });
    },
    [handleRefresh],
  );

  const handleLoadFeature = useCallback(
    (geom, isNew = false) => {
      if (isNew) {
        layers.draw.getSource().clear();
        setMode(MODE_EDIT);
        setFeatures([]);
      } else if (geom) {
        const feature = new Feature({
          geometry: geom,
        });
        feature.set("temp", true);
        layers.draw.getSource().clear();
        layers.draw.getSource().addFeature(feature);
        setMode(MODE_EDIT);
        setFeatures([feature]);
        map.getView().fit(feature.getGeometry(), { duration: 500, maxZoom: 19 });
      } else if (layers) {
        layers.draw.getSource().clear();
        setMode(MODE_SELECT);
        setFeatures([]);
      }
    },
    [map, layers],
  );

  //get regions from backend
  let lang = localStorage.getItem('i18nextLng')
  useEffect(() => {
    getDistricts(lang).then(json => setRegionsData(json));
  }, [lang]);

  //get service data
  useEffect(() => {
    fetch(
      `${window.REST_URL}/cabinet/egov/req`, {
      method: "GET",
      mode: "cors",
      credentials: "include",
    },
    )

      .then(response => response.json())
      //  .then(data =>  data.map(i  => { return servicesJson.push(i)} ))
      .then(json => {
        setServicesJson(json)
      })
  }, []);

  const handleSetRegionMob = district => {
    setChooseRegionMob(false);
    handleSetRegion(district);
  };

  const handleSetRegion = useCallback(
    district => {
      console.log('handleSetRegion', district);
      return new Promise((resolve, reject) => {
        handleCloseStats()
        updateLayers(
          layers,
          district
        );
        restSetDistrict(district, props.standalone, layers).then(json => {
          district.geom = json.geom;
          setRegion(district);
          setOpenRegion(false);

          const newPojection = "EPSG:" + district.srs;
          layers.borders.getSource().clear();

          let geom = null;

          if (district.n) {
            geom = new WKT().readGeometry(district.n.geom);
          } else if (district.geom) {
            geom = new WKT().readGeometry(district.geom).transform("EPSG:3857", newPojection);
          }

          if (geom) {
            layers.borders.getSource().addFeature(
              new Feature({
                geometry: geom,
              }),
            );
          }

          const viewCenter = transform(
            map.getView().getCenter(),
            map.getView().getProjection(),
            newPojection,
          );
          const view = new View({
            projection: newPojection,
            center: viewCenter,
            zoom: map.getView().getZoom(),
          });

          map.setView(view);
          view.fit(geom ? geom : extent, { duration: 1000 });

          const ovmap = overviewMap.getOverviewMap();
          ovmap.setView(
            new View({
              projection: newPojection,
              center: viewCenter,
              zoom: ovmap.getView().getZoom(),
            }),
          );
          setOpenLayers(false);
          setOpenLegend(false);
          setOpenRegion(false);
          setOpenInfo(false);
          setSelectedMenu(-1);
          handleShowLayer(null, "uk", true);
          handleShowLayer(null, "lands", true);
          if (!props.standalone) {
            handleShowLayer(null, "sogl", true);
          }
          layersVisibility["uk"] = true;
          layersVisibility["lands"] = true;
          layersVisibility["sogl"] = true;
          mousePosition.setProjection(newPojection);
          resolve();
        });
      })
    },
    [map, layers, handleShowLayer]
  );

  /**
   * Внешние функции
   */
  useEffect(() => {
    if (props.updateSize) {
      console.log("size before: ", map.getSize());
      map.updateSize();
      console.log("size after: ", map.getSize());
    }
  }, [map, props.updateSize]);

  useEffect(() => {
    if (layers && props.changeLayerVisibility) {
      for (let layerName in props.changeLayerVisibility) {
        let visibility = props.changeLayerVisibility[layerName];
        handleShowLayer(null, layerName, visibility);
      }
    }
  }, [layers, props.changeLayerVisibility, handleShowLayer]);

  // Выбор района
  useEffect(() => {
    if (props.selectDistrict) {
      getDistrictByRnCode(props.selectDistrict).then(json => {
        map.updateSize();
        handleSetRegion(json);
      });
    }
  }, [map, handleSetRegion, props.selectDistrict, props.selectDistrict_ts]);

  // Выделение ЗУ по кадастровому номеру.
  useEffect(() => {
    if (props.selectCadnum) {
      console.log(props.selectCadnum, "cadNum")
      search(props.selectCadnum, 0, 10).then(res => {
        if (res.lands && res.lands.length > 0) {
          handleSelect({ wkt: res.lands[0].geometry});
        }
      });
    }
  }, [handleSelect, props.selectCadnum, props.selectCadnum_ts]);

  // Выделение полигона
  useEffect(() => {
    if (map) {
      handleSelect(props.selection);
    }
  }, [handleSelect, map, props.selection]);

  // Приближение к экстенту
  useEffect(() => {
    if (map && props.extFitExtent && !olExtent.isEmpty(props.extFitExtent.extent)) {
      const view = map.getView();
      view.fit(props.extFitExtent.extent, { duration: 500, maxZoom: props.extFitExtent.maxZoom || 20 });
    }
  }, [map, props.extFitExtent]);

  // Редактирование полигона
  useEffect(() => {
    if (props.extEditGeom === '') {
      handleLoadFeature(props.extEditGeom, true);
    } else {
      handleLoadFeature(props.extEditGeom ? new WKT().readGeometryFromText(props.extEditGeom) : undefined, false);
    }
  }, [handleLoadFeature, props.extEditGeom]);

  useEffect(() => {
    if (window["requestFromGis"]) {
      window["requestFromGis"](props.uid, { search: searchResult });
    }
  }, [props.uid, searchResult]);

  useEffect(() => {
    if (features.length > 0) {
      getMeasures(features[0].getGeometry(), region.srs).then(json => {
        json.geom = new WKT().writeGeometryText(features[0].getGeometry());
        setPerimeter(json.perimeter);
        if (window["requestFromGis"]) {
          window["requestFromGis"](props.uid, { extEditGeom: json });
        }
      });
    }
  }, [props.uid, features, region]);

  useEffect(() => {
    if (props.extGetNeighbours) {
      getNeighbours(
        props.extGetNeighbours.wkt,
        props.extGetNeighbours.scad,
        props.extGetNeighbours.rnCode
      ).then(json => {
        json.param = props.extGetNeighbours.param;
        console.log(json);
        if (window["requestFromGis"]) {
          window["requestFromGis"](props.uid, { extGetNeighbours: json });
        }
      });
    }
  }, [props.uid, props.extGetNeighbours]);

  useEffect(() => {
    if (props.extDrawItems) {
      if (layers.select) {
        const source = layers.select.getSource();
        source.getFeatures().forEach(feature => {
          if (feature.get("type") === "item") {
            source.removeFeature(feature);
          }
        });
        if (props.extDrawItems.items) {
          const extent = olExtent.createEmpty();
          props.extDrawItems.items.forEach(item => {
            const geom = new WKT().readGeometryFromText(item.wkt);
            source.addFeature(
              new Feature({
                type: "item",
                title: item.title,
                geometry: geom,
              }),
            );
            olExtent.extend(extent, geom.getExtent());
          });
          map.getView().fit(extent, { duration: 500, maxZoom: 19 });
        }
      }
    }
  }, [props.uid, props.extDrawItems, layers, map]);

  useEffect(() => {
    if (props.extGetIntersections) {
      extGetIntersections(
        props.extGetIntersections.wkt,
        props.extGetIntersections.layers,
        props.extGetIntersections.scad,
        props.extGetIntersections.rnCode
      ).then(json => {
        json.param = props.extGetIntersections.param;
        console.log(json);

        // Наносим пересечения на карту
        if (layers.select) {
          const source = layers.select.getSource();
          source.getFeatures().forEach(feature => {
            if (feature.get("type") === "segment") {
              source.removeFeature(feature);
            }
          });
          if (json.segments) {
            json.segments.forEach(seg => {
              source.addFeature(
                new Feature({
                  type: "segment",
                  geometry: new WKT().readGeometryFromText(seg.wkt),
                }),
              );
            });
          }
        }

        if (window["requestFromGis"]) {
          window["requestFromGis"](props.uid, { extGetIntersections: json });
        }
      });
    }
  }, [props.uid, props.extGetIntersections, layers]);

  useEffect(() => {
    if (props.extGetMeasures) {
      extGetMeasures(props.extGetMeasures.wkt, region.srs).then(json => {
        json.param = props.extGetMeasures.param;
        console.log(json);
        if (window["requestFromGis"]) {
          window["requestFromGis"](props.uid, { extGetMeasures: json });
        }
      });
    }
  }, [props.uid, props.extGetMeasures, region]);

  useEffect(() => {
    if (props.extGetQuality) {
      extGetQuality(props.extGetQuality.wkt, props.extGetQuality.rnCode).then(json => {
        json.param = props.extGetQuality.param;
        console.log(json);
        if (window["requestFromGis"]) {
          window["requestFromGis"](props.uid, { extGetQuality: json });
        }
      });
    }
  }, [props.uid, props.extGetQuality, region]);
  // Окончание блока внешних функций

  useEffect(() => {
    if (layers != null) {
      getJsonLayers(region, props.standalone, layers, isCosmoUp, setIsCosmoUp, handleShowLayer, layersVisibility, setLayersVisibility).then(layers => {
        setLayerConfig(layers);
      });
    }
  }, [i18n.language, region, props.standalone, layers]);

  const viewRegion = useCallback(() => {
    if (region && region.geom) {
      map
        .getView()
        .fit(
          new WKT()
            .readGeometry(region.geom)
            .transform("EPSG:3857", "EPSG:" + region.srs),
          { duration: 1000 },
        );
    } else {
      map.getView().fit(extent, { duration: 1000 });
    }
  }, [map, region]);

  const setDefaultView = useCallback(() => {
    handleSetRegion({
      id: 0,
      regionCode: "00",
      code: "000",
      srs: 3857,
      nameRu: "Выбрать регион",
    });
  }, [handleSetRegion]);

  const handleShowRegionNotSelected = () => {
    setShowRegionNotSelected(true);

    setTimeout(() => {
      setShowRegionNotSelected(false);
    }, 2000);
  };

  const handleLogin = userInfo => {
    login(userInfo)
      .then(result => {
        if (result.result === 0) {
          setUserInfo(result);
        }
      })
      .catch(e => console.log(e));
  };

  const handleLogout = () => {
    logout()
      .then(() => {
        setUserInfo(null);
        setOpenProfile(false);
        if (window.IDP_LOGOUT_URL) {
          window.location.href = window.IDP_LOGOUT_URL;
        }
      })
      .catch(e => console.log(e));
  };

  const handleUpdateQueue = (iin) => {
    return new Promise((resolve, reject) => {
      if (userInfo)
        updateQueue(iin)
          .then((result) => {
            setUserInfo(Object.assign({}, userInfo, { queues: result }));
            resolve(result)
          })
          .catch(e => reject(e));
    });
  }

  const handleService = () => {
    getService()
      .then((result) => {
        setService(result.sort(sortService));
      })
      .catch(e => console.log(e));
  }

  const sortService = (x,y) => {
    if (x.name < y.name) {return -1;}
    if (x.name > y.name) {return 1;}
    return 0;
  }

  const pdfNote = () => {
    setNotification({
      title: t("service.format-error-title"),
      text: t("service.pdf-error-text")
    })
  }

  const checkPdf = (fileName) => {
    return fileName.split('.').pop() !== 'pdf' ? true : false
  }

  const fileFormatNote = () => {
    setNotification({
      title: t("service.format-error-title"),
      text: t("service.format-error-text")
    })
  }

  const checkFileFormat = (fileName) => {
    const formats = ["txt", "xls", "xlsx", "zip"];
    return !formats.includes(fileName.split('.').pop()) ? true : false
  }

  const checkFileSize = (file) => {
    return file.size >= window.HEAD_FILE_SIZE ? true : false;
  }

  const checkFileSizeNote = (type) => {
    setNotification({
      type: type ? type : NF_NOTICE,
      title: t("notif.attention"),
      text: t("notif.file-size")
    })
  }

  

  const handleMyObjs = (type) => {
    getMyObjs(type)
      .then((result) => {
        setMyObjs(result);
        setMyObjLoaded(true);
      })
      .catch(e => {
        setMyObjLoaded(true);
        console.log(e);
      });
  }

  const handleLand = (landInd) => {
    getMyObjsByLandId(landInd)
      .then((result) => {
        setMyObjs(result);
        setMyObjLoaded(true);
      })
      .catch(e => {
        setMyObjLoaded(true);
        console.log(e);
      });
  }

  const handleProviders = (district) => {
    getProviders(district)
      .then((result) => {
        setProviders(result);
        setMyObjLoaded(true);
      })
      .catch(e => {
        setMyObjLoaded(true);
        console.log(e);
      });
  }

  const handlePayment = (e) => {
    // order.isLoading = true;
    // setOrder(Object.assign({}, order))
    postOrder(order, setNotification)
      .then((result) => {
        setOrder(result);
      })
      .catch(e => {
        console.log(e)
        order.isLoading = false;
        setOrder(Object.assign({}, order))
      });
  }

  const handleDocsType = () => {
    getDocs()
      .then((result) => {
        setDocs(result);
      })
      .catch(e => console.log(e));
  }

  const handleDocsTypeAll = (serviceId) => {
    getDocTypes(serviceId)
      .then((result) => {
        if (result.length>0) {
          setDocs(result);
        } else {
          handleDocsType();
        }
      })
      .catch((err) => {
          console.log(err);
      });
  }

  const handlePostDoc = (doc, item) => {
    postDoc(doc, "")
      .then(() => {
        item.isLoading = false;
        setOrder(order);
      })
      .catch(() => {
        item.isLoading = false;
        setOrder(order);
      });
  }

  const handlePostOther = (doc, setOther) => {
    postDoc(doc, "")
      .then(() => {
        setOther({ isLaoded: false });
      })
      .catch(() => {
        setOther({ isLaoded: false });
      })
  }

  const handlePostCoords = (doc, item, obj) => {
    postDoc(doc, "ZKR1")
      .then(result => {
        item.isLoading = false;
        obj.coords = result;
        setOrder(order);
      })
      .catch((e) => {
        console.log(e);
        item.isLoading = false;
        setOrder(order);
      })
  }

  const handleGetCoords = (obj, type) => {
    getCoords(obj,type)
      .then(result => {
        obj.coords = result;
        setOrder(order);
      })
      .catch((e) => {
        console.log(e);
        setOrder(order);
      })
  }

  const handleOrders = (pageNum, pageSize) => {
    getOrders(pageNum, pageSize)
      .then((result) => {
        setOrders(result);
      })
      .catch(e => {
        console.log(e);
      });
  }

  const handleGetNotifs = (pageNum, pageSize) => {
    getNotifs(pageNum, pageSize)
      .then((result) => {
        setNotifs(result);
      })
      .catch(e => {
        console.log(e);
      });
  }

  const handleUpdateNotif = (timeCreated) => {
    updateNotif(timeCreated)
      .then((result) => {
        setNotifs(result);
      })
      .catch(e => {
        console.log(e);
      });
  }

  const handleDicts = () => {
    getDicts()
      .then((result) => {
        setDicts(result);
      })
      .catch(e => {
        console.log(e);
      });
  }

  const handleSelectOrder = (id, code) => {
    setOrderLoaded(true);
    orderItemLoaded = true;
    docTypeLoaded = true;
    getOrder(id)
      .then(result => {
        setOrder(result);
        orderItemLoaded = false;
        if (code && !orderItemLoaded && !docTypeLoaded) {
          setOrderLoaded(false);
        } else if (!code && !orderItemLoaded) {
          setOrderLoaded(false);
        }
          
      })
      .catch(e => {
        console.log(e)
        orderItemLoaded = false;
        if (code && !orderItemLoaded && !docTypeLoaded) {
          setOrderLoaded(false);
        } else if (!code && !orderItemLoaded) {
          setOrderLoaded(false);
        }
      });
    if (code) {
      getDocTypes(code)
      .then(result => {
        setDocs(result);
        docTypeLoaded = false;
        if (!orderItemLoaded && !docTypeLoaded) {
          setOrderLoaded(false);
        }
      })
      .catch(e => {
        console.log(e);
        docTypeLoaded = false;
        if (!orderItemLoaded && !docTypeLoaded) {
          setOrderLoaded(false);
        }
      });
    }
  }

  const handleProfileObjects = () => {
    setProfileObjectsLoading(true);
    getProfileObjects()
      .then(result => {
        setProfileObjects(result)
        setProfileObjectsLoading(false);
      })
      .catch(e => {
        console.log(e);
        setProfileObjectsLoading(false);
      })
  }

  const handleSearch = (text, offset, limit) => {
    search(text, offset, limit).then(result => {
      setSearchResult(result);
    });
    setGetObjects({});
  };

  const handleSearchByCadNum = (cadNum, offset, limit, obj, searchResult) => {
    searchByCadNum(cadNum, offset, limit, obj).then(result => {
      findIntObj(searchResult, result)
    });
    setGetObjects({});
  };

  const findIntObj = (searchResult, result) => {
    const layer = result['layer'];
    const id = result['id'];
    const newObj = Object.assign({}, searchResult);
    const objIndex = newObj[layer].findIndex(item => item.id === id);
    newObj[layer][objIndex].properties.childs = result['childs'];
    setSearchResult(newObj);
  }

  const handleGetLayerObjects = useCallback((layerName, arrestChecked) => {
    getLayerObjects(layerName, 0, 10).then(result => {
      if (result.eval) {
        setSearchResult(layerSort(result))
      } else {
        setSearchResult(result);
      }
      setArrestIsChecked(arrestChecked);
      handleOpenInfo(true);
    });

    setGetObjects({
      func: pageNum => {
        getLayerObjects(layerName, pageNum, 10).then(result => {
          if (result.eval) {
            setSearchResult(layerSort(result))
          } else {
            setSearchResult(result);
          }
        });
      },
    });
  }, []);

  const layerSort = (result) => {
    let sorted = {}
    let e = Object.keys(result);
    if (e[0] === "eval") {
      let sortedEval = Object.values(result.eval).sort((a, b) => (a.properties.zonenumber > b.properties.zonenumber) ? 1 : -1)
      sorted = {
        eval: sortedEval,
        count: result.count
      }
    }
    return sorted;
  }

  const handleGetStatObjects = (legendId, region, district) => {
    getStatObjects(legendId, region, district, 0, 10).then(result => {
      setSearchResult(result);
      setArrestIsChecked(legendId === 1 ? true : false);
      handleOpenInfo(true);
    });

    setGetObjects({
      func: pageNum => {
        getStatObjects(legendId, region, district, pageNum, 10).then(result => {
          setSearchResult(result);
        });
      },
    });
  };

  const handleAuctionSelect = useCallback((landId) => {
    sendActionSelect(landId).then(result => {
      setMode(MODE_SELECT);
      setOpenInfo(false);
      window.location.href = `${window.EGOV_URL}/Com05/?request-number=${result.chainId}`;
    }).catch((reason) => {
      setNotification({
        type: NF_ERROR,
        title: t("notif.error"),
        text: reason,
        onClose: () => {
          //window.location.href = `${window.EGOV_URL}/Com05/?request-number=${result.chainId}`;
          //setOpenInfo(false);
          // `${window.REST_URL}/cabinet/create/cancel`, ? create backend ,
          setMode(MODE_SELECT);
          console.log('error back')
        }
      });
    });
  }, []);

  const removeEmailVerify = () => {
    fetch(`${window.REST_URL}/cabinet/email/remove`, {
      method: "GET",
      mode: "cors",
      credentials: "include"
    })
      .then(response => {
        if (response.ok) {
          Cookies.remove(window.CK_EMAIL_VERIFY);
          setShowEmailVerify(false);
          loginEffect();
        }
      })
      .catch(e => {
        console.log(e);
      });
  };

  const checkEmailVerify = (code) => {
    return new Promise((resolve, reject) => {
      fetch(`${window.REST_URL}/cabinet/email/${code}`, {
        method: "GET",
        mode: "cors",
        credentials: "include"
      })
        .then(response => {
          if (response.ok) {
            Cookies.remove(window.CK_EMAIL_VERIFY);
            setShowEmailVerify(false);
            loginEffect();
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch(e => {
          reject(e);
        });
    });
  };

  const resendEmail = () => {
    return new Promise((resolve, reject) => {
      fetch(`${window.REST_URL}/cabinet/email/resend`, {
        method: "GET",
        mode: "cors",
        credentials: "include"
      })
        .then(response => {
          if (response.ok) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch(e => {
          reject(e);
        });
    });
  };

  const handleClickProfile = useCallback(() => {
    setShowOrders(false);
    setShowOrder(false);
    setShowNotif(false);
    setOpenRegion(false);
    if (egovService) {
      setEgovService(false);
    }
    if (egovRequest) {
      if (egovRequest.srvTypeId!=='504100' && egovRequest.srvTypeId!=='504400') {
        setShowDrawLand(false);
      }
    } else {
      setShowDrawLand(false);
    }
    setMode(MODE_SELECT);
    setErrors(undefined);
    setFeatures([]);
    setShowProfile(!showProfile);
  }, [showProfile,egovRequest]);

  const handleClickOrders = useCallback(() => {
    setShowProfile(false);
    setShowOrder(false);
    setShowNotif(false);
    setOpenRegion(false);
    setEgovService(false);
    if (egovRequest) {
      if (egovRequest.srvTypeId!=='504100' && egovRequest.srvTypeId!=='504400') {
        setShowDrawLand(false);
      }
    } else {
      setShowDrawLand(false);
    }
    setMode(MODE_SELECT);
    setShowOrders(!showOrders);
  }, [showOrders, egovRequest]);

  const handleClickOrder = (show) => {
    setShowProfile(false);
    setShowOrders(false);
    setShowNotif(false);
    setOpenRegion(false);
    setDocs([]);
    setShowOrder(show);
  }

  const handleClickNotif = useCallback(() => {
    setShowProfile(false);
    setShowOrders(false);
    setShowOrder(false);
    setOpenRegion(false);
    setEgovService(false);
    if (egovRequest) {
      if (egovRequest.srvTypeId!=='504100' && egovRequest.srvTypeId!=='504400') {
        setShowDrawLand(false);
      }
    } else {
      setShowDrawLand(false);
    }
    setMode(MODE_SELECT);
    setShowNotif(!showNotif);
  }, [showNotif,egovRequest]);

  const handleClickDrawLand = useCallback(() => {
    setShowProfile(false);
    setShowOrders(false);
    setShowOrder(false);
    setOpenRegion(false);
    setEgovService(false);
    setShowNotif(false);
    setErrors(undefined);
    setFeatures([]);
    setShowDrawLand(!showDrawLand);
  },[showDrawLand])

  useEffect(() => {
    Cookies.remove('mode');
    if (window.location.search) {
      const searchParams = new URLSearchParams(window.location.search);
      const serviceId = searchParams.get('serviceId');
      if (serviceId) {
        Cookies.set('serviceId', serviceId);
      }      
      const mode = searchParams.get('mode');
      if (mode) {
        Cookies.set('mode', mode);
      }
    }  
    
    if (document.referrer && document.referrer.includes('egov.kz')) {
      window.location.href = window.IDP_URL;
    }
         
  }, [])

  const loginEffect = () => {
    const userInfo = props.standalone && props.check ? { check: props.check } : {};
    login(userInfo)
      .then(result => {
        if (result.result === 0) {
          setUserInfo(result);
          if (props.standalone) {
            getMyQueue(i18n.language)
              .then(res => {
                setUserInfo(Object.assign({}, result, { queues: res }));
                console.log(res);
              })
              .catch(err => {
                console.log(err);
              });

            const serviceId = Cookies.get('serviceId');
            if (serviceId) {
              setServiceId(serviceId);
              getService(serviceId)
              .then(result => {
                setOrder({ services: [result.name ? result : {name: "По указанному коду не найдено услуг, необходимо указать услугу"}], objects: [], providers: [], docs: [] });
                handleDocsTypeAll(serviceId);
                handleClickOrder(true);
              });
              Cookies.remove('serviceId');
            } else if (result.egov_req) {
              setEgovRequest(result.egov_req);
              if (result.egov_req.srvTypeId === '504100' || result.egov_req.srvTypeId === '504400') {
                if (result.egov_req.srvTypeId === '504400') {
                  handleShowLayer(null, "free", true);
                  layersVisibility["free"] = true;
                }
                else if (result.egov_req.srvTypeId === '504100') {
                  handleShowLayer(null, "req", true);
                  layersVisibility["req"] = true;
                }

                handleShowLayer(null, "ggk_invsupplyzonewater", true);
                layersVisibility["ggk_invsupplyzonewater"] = true;
                handleShowLayer(null, "ggk_invsupplyzonegas", true);
                layersVisibility["ggk_invsupplyzonegas"] = true;
                handleShowLayer(null, "ggk_invsupplyzonesewerage", true);
                layersVisibility["ggk_invsupplyzonesewerage"] = true;
                handleShowLayer(null, "ggk_invsupplyzonephone", true);
                layersVisibility["ggk_invsupplyzonephone"] = true;
                handleShowLayer(null, "ggk_invsupplyzoneheat", true);
                layersVisibility["ggk_invsupplyzoneheat"] = true;
                handleShowLayer(null, "ggk_invsupplyzonefecal", true);
                layersVisibility["ggk_invsupplyzonefecal"] = true;
                handleShowLayer(null, "ggk_invsupplyzoneelectro", true);
                layersVisibility["ggk_invsupplyzoneelectro"] = true;
                handleShowLayer(null, "ggk_gp_zones", true);
                layersVisibility["ggk_gp_zones"] = true;
                handleShowLayer(null, "ggk_gp_redlines", true);
                layersVisibility["ggk_gp_redlines"] = true;

                setLandRequestTop(120);
                handleSetRegion(result.egov_req.district).then(() => setMode(MODE_SELECT_PDP));
                setNotification({
                  type: NF_NOTICE,
                  title: t("notif.attention"),
                  text: t("notif.draw"),
                });
                setShowDrawLand(true);
              } else if (result.egov_req.srvTypeId === '504300') {
                handleSetRegion(result.egov_req.district).then(() => {
                  setMode(MODE_AUCTION_SELECT);
                  handleShowLayer(null, "free", true);
                  layersVisibility["free"] = true;
                  handleGetLayerObjects('free', false);
                  setNotification({
                    type: NF_NOTICE,
                    title: t("notif.attention"),
                    text: t("notif.choose"),
                  });
                });
              }
            } else if (result.iin && result.district) {
              //setUserInfo(result);
              handleSetRegion(result.district);
            }
            setNotifs(result.notifs);

            
          }
        } else {
          setUserInfo(null);
          if (result.district)
            handleSetRegion(result.district);
        }
        if (result.order) {
          setOrder(result.order);
          setShowOrder(true);
        }
        handleProfileObjects();
      })
      .catch(e => console.log(e));
  };

  useEffect(() => {
    if (Cookies.get(window.CK_EMAIL_ERROR) === "true") {
      setShowEmailError(true);
    } else if (Cookies.get(window.CK_EMAIL_VERIFY)) {
      setShowEmailVerify(true);
    }
    else if (layers) {
      loginEffect();
    }
  }, [handleSetRegion, handleGetLayerObjects, layers, setShowEmailVerify]);

  useEffect(() => {
    if (mode === MODE_DRAW) {
      layers.draw.getSource().clear();
      createSnapLayer(layers, region, setErrors, mode);
      map.addLayer(layers.snap);
      const snap = new Snap({ source: layers.snap.getSource() });
      const draw = new Draw({
        source: layers.draw.getSource(),
        type: "Polygon",
      });

      draw.on("drawend", drawEvent => {
        const feature = new Feature({
          geometry: new MultiPolygon([]),
        });
        feature.set("temp", true);
        feature.getGeometry().appendPolygon(drawEvent.feature.getGeometry());

        setTimeout(() => {
          layers.draw.getSource().removeFeature(drawEvent.feature);
          layers.draw.getSource().addFeature(feature);
        }, 5);

        setFeatures([feature])
        setMode(MODE_EDIT);
      });
      map.addInteraction(draw);
      map.addInteraction(snap);

      return () => {
        map.removeLayer(layers.snap);
        map.removeInteraction(draw);
        map.removeInteraction(snap);
      }
    } else if (mode === MODE_EDIT) {
      if (!layers.snap) {
        createSnapLayer(layers, region, setErrors, mode);
      }

      map.addLayer(layers.snap);
      const snap = new Snap({ source: layers.snap.getSource() });
      const modify = new Modify({ source: layers.draw.getSource() });
      modify.on("modifyend", event => {
        setFeatures([features[0]]);
      });

      const draw = new Draw({
        source: layers.draw.getSource(),
        type: "Polygon",
      });

      draw.on("drawstart", e => {
        if (features.length > 0) {
          let mp = features[0].getGeometry();
          for (let i = 0; i < mp.getPolygons().length; i++) {
            if (mp.getPolygon(i).intersectsExtent(e.feature.getGeometry().getExtent())) {
              editingPolygon = i;
              break;
            }
          }
        }
      });

      draw.on("drawend", drawEvent => {

        drawEvent.preventDefault();

        if (editingPolygon >= 0) {
          let pols = features[0].getGeometry().getPolygons();
          pols[editingPolygon].appendLinearRing(drawEvent.feature.getGeometry().getLinearRing(0));
          let mp = new MultiPolygon([]);
          pols.forEach(pol => {
            mp.appendPolygon(pol);
          });
          features[0].setGeometry(mp);
          editingPolygon = -1;
        } else {
          if (features.length === 0) {
            const feature = new Feature({
              geometry: new MultiPolygon([]),
            });
            feature.set("temp", true);
            layers.draw.getSource().addFeature(feature);
            features.push(feature);
          }
          features[0].getGeometry().appendPolygon(drawEvent.feature.getGeometry());
        }

        setTimeout(() => {
          layers.draw.getSource().removeFeature(drawEvent.feature);
        }, 5);

        setFeatures([features[0]])
      });

      const editKeyHandler = (e) => {
        console.log(e);
        if (e.keyCode === 27) {
          editingPolygon = -1;
          draw.abortDrawing();
        }
      };

      document.addEventListener('keyup', editKeyHandler);

      map.addInteraction(draw);
      map.addInteraction(modify);
      map.addInteraction(snap);

      return () => {
        map.removeLayer(layers.snap);
        map.removeInteraction(draw);
        map.removeInteraction(modify);
        map.removeInteraction(snap);
        document.removeEventListener('keyup', editKeyHandler);
      };
    }
  }, [map, mode, layers, region, features]);

  const handleOpenLayers = (event, value) => {
    // Закрываем окно поиска
    setOpenInfo(false);
    // Закрываем окно легенды
    setOpenLegend(false);
    setOpenLayers(value);
    setOpenSlider(false);
    setShowHelp(false);
    handleCloseStats();
    handleCloseQueueAndRegions();

    setSelectedMenu(value ? CommonUtil.MENU_MAP_CONTROL : -1);

    if (!responsive.mobile) {
      let btn = CommonUtil.getEventButton(event);
      setOpenLayersTop(btn.offsetTop);
      setOpenLayersMaxHeight(btn.parentElement.clientHeight - btn.offsetTop - 20);
    } else {
      setOpenLayersTop(70);
      let topEl = document.getElementById(props.uid);
      setOpenLayersMaxHeight(topEl.clientHeight - 100);
    }
  };

  const handleOpenLegend = (event, value) => {
    // Закрываем окно слоев
    setOpenLayers(false);
    setOpenSlider(false);
    // Закрываем окно поиска
    setOpenInfo(false);
    handleCloseStats();
    handleCloseQueueAndRegions();
    setOpenLegend(value);
    setSelectedMenu(value ? CommonUtil.MENU_LEGEND : -1);

    let btn = CommonUtil.getEventButton(event);

    setOpenLegendTop(btn.offsetTop);
    setOpenLegendMaxHeight(btn.parentElement.clientHeight - btn.offsetTop - 45);
  };

  const handleOpenRegion = event => {
    setShowProfile(false);
    setShowOrders(false);
    if (!serviceId) {
      setShowOrder(false);
    }
    setShowNotif(false);
    setShowHelp(false);
    setOpenRegion(!openRegion);
    setMode(MODE_SELECT);

    let topEl = document.getElementById(props.uid);
    setOpenRegionMaxHeight(topEl.clientHeight - 100);
  };

  const handleOpenRegionMob = () => {
    let topEl = document.getElementById(props.uid);
    setOpenRegionMaxHeight(topEl.clientHeight - 100);
    setChooseRegionMob(true);
  };

  const handleOpenStats = (event) => {
    setShowSelector(true);
    // Закрываем окно слоев
    setOpenLayers(false);
    // Закрываем окно поиска
    setOpenInfo(false);
    setOpenLegend(false);
    setOpenSlider(false);
    handleCloseQueueAndRegions();
    setPrevMode(mode);
    setMode(MODE_OFF);
    setSelectedMenu(CommonUtil.MENU_SHOW_STATS);
    setShowStats(true);
  };

  const handleCloseStats = () => {
    if (mode === MODE_OFF)
      setMode(prevMode);
    setSelectedMenu(-1);
    setShowStats(false);
  };

  const handleOpenQueue = () => {
    // Закрываем окно слоев
    setOpenLayers(false);
    // Закрываем окно поиска
    setOpenInfo(false);
    setOpenLegend(false);
    setOpenSlider(false);
    handleCloseStats();
    handleCloseRegions();
    setShowProfile(false);
    setShowRegionsOnline(false);
    setSelectedMenu(CommonUtil.MENU_QUEUE);
    setShowQueue(true);
  };

  const handleCloseQueue = () => {
    setShowQueue(false);
  };

  const handleOpenRegions = () => {
    handleOpenQueue();
    setShowQueue(false);
    setShowHelp(false);
    setShowRegions(true);
  };

  const handleOpenRegionsOnline = () => {
    handleOpenQueue();
    setShowQueue(false);
    setShowRegions(false);
    setShowHelp(false);
    setSelectedMenu(CommonUtil.MENU_REGION_ONLINE);
    setShowRegionsOnline(true);
  };

  const handleOpenHelp = () => {
    // Close all other windows
    handleOpenQueue();
    setShowQueue(false);
    setShowRegions(false);
    setShowRegionsOnline(false);

    // Set the selected menu
    setSelectedMenu(CommonUtil.MENU_HELP);

    // Show the help window
    setShowHelp(true);
  }

  const handleCloseHelp = () => {
    setSelectedMenu(-1);
    setShowHelp(false);
  }

  const handleCloseRegions = () => {
    setShowRegions(false);
  };

  const handleCloseRegionsOnline = () => {
    setSelectedMenu(-1);
    setShowRegionsOnline(false);
  };

  const handleCloseQueueAndRegions = () => {
    setSelectedMenu(-1);
    setShowQueue(false);
    setShowRegions(false);
    setShowRegionsOnline(false);
  };

  //get data json -> list, dynamic -> show dialog window
  const handleSendApplication = event => {
    if (region !== null && region !== undefined) {
      // let btn = CommonUtil.getEventButton(event);
      let topEl = document.getElementById(props.uid);
      setLandRequestTop(120);
      setLandRequestMaxHeight(topEl.clientHeight - 430);
      setMode(mode === MODE_SELECT ? MODE_DRAW : MODE_SELECT);
      handleClickDrawLand();
    } else {
      handleShowRegionNotSelected();
    }
  };
  //show list service
  const handleSendAppService = event => {
    let topEl = document.getElementById(props.uid);
    setLandRequestTop(120);
    setLandRequestMaxHeight(topEl.clientHeight - 430);

    setOpenService(!openService)
  }
  //hide service modal
  const handleHideService = event => {
    setMode(mode === MODE_SELECT ? MODE_DRAW : MODE_SELECT);
  }

  const handleAuthorizationPressed = event => {
    setOpenProfile(!openProfile);
    setMode(MODE_SELECT);
  };

  const handleOpenInfo = useCallback(
    value => {
      // Закрываем окно слоев
      setOpenLayers(false);
      // Закрываем окно легенды
      setOpenLegend(false);
      setOpenSlider(false);
      setShowHelp(false);
      handleCloseStats();
      handleCloseQueueAndRegions();
      setOpenInfo(value);
      setSelectedMenu(value ? CommonUtil.MENU_SEARCH : -1);

      let parent = document.getElementById(props.uid);
      setOpenInfoMaxHeight(parent.clientHeight - 225);
    },
    [props.uid],
  );

  const handleZoomIn = useCallback(() => {
    const view = map.getView();
    view.animate({ zoom: view.getZoom() + 1, duration: 200 });
    setSimpleNotification(false);
  }, [map]);

  const handleZoomOut = useCallback(() => {
    const view = map.getView();
    view.animate({ zoom: view.getZoom() - 1, duration: 200 });
    setSimpleNotification(true);
  }, [map]);

  const handleSetDark = useCallback(value => {
    if (value) {
      document.getElementsByClassName("ol-scale-line-inner")[0].style.color =
        "white";
      document.getElementsByClassName(
        "ol-scale-line ol-unselectable",
      )[0].style.border = "1px solid white";
      document.getElementsByClassName("ol-scale-line ol-unselectable")[0].style[
        "border-top"
      ] = "none";
    } else {
      document.getElementsByClassName("ol-scale-line-inner")[0].style.color =
        "black";
      document.getElementsByClassName(
        "ol-scale-line ol-unselectable",
      )[0].style.border = "1px solid black";
      document.getElementsByClassName("ol-scale-line ol-unselectable")[0].style[
        "border-top"
      ] = "none";
    }
    setDark(value);
  }, []);

  const infoHandler = useCallback(
    event => {
      if (mode !== MODE_SELECT_PDP) {
        get(event, region, layers, layersVisibility, lang).then(result => {
          setGetObjects({});
          setSearchResult(formatResult(result));
          handleOpenInfo(true);
        });
      }
    },
    [handleOpenInfo, region, layers, layersVisibility, lang, mode],
  );

  const formatResult = result => {
    let info = {};
    for (let i = 0; i < result.length; i++) {
      if (result[i].external) {
        const layerName = result[i].layerName;
        if (result[i].features && result[i].features.length) {
          const properties = result[i].features[0].attributes;
          info[layerName] = [{ properties: properties }];
        } else {
          info[layerName] = [];
        }
      } else {
        info = { ...info, ...result[i] };
      }
    }
    return info;
  };

  const handleSend = useCallback(
    status => {
      if (props.standalone) {
        const geom = new WKT().writeGeometry(features[0].get("geometry"));
        if (egovRequest) {
          const scale = matchScale(features[0].get("geometry"), [200,500,1000,2000,5000,25000,10000,50000,100000,200000,300000,500000,1000000], 800, 300, 20);
          fetch(
            `${window.REST_URL}/cabinet/req/create?scale=${scale}`,
            {
              method: "POST",
              mode: "cors",
              credentials: "include",
              body: geom
            },
          )
            .then(response => response.json())
            .then(result => {
              const srvType = egovRequest.srvTypeId === "504400" ? 'Com04'
              : egovRequest.srvTypeId === "504300" ? 'Com05'
              : egovRequest.srvTypeId === "504100" ? 'Com06'
              : '';
              if (result.error) {
                setNotification({
                  type: NF_ERROR,
                  title: t("notif.error"),
                  text: result.errorMsg === "edw_conn_error"
                    ? t("notif.head-error")
                    : result.errorMsg === "egov_portal_conn_error"
                      ? t("notif.portal-error")
                      : t("notif.unknown-error"),
                  onClose: () => {
                    window.location.href = `${window.EGOV_URL}/${srvType}/?request-number=${result.chainId}`;
                  }
                });
              } else {
                window.location.href = `${window.EGOV_URL}/${srvType}/?request-number=${result.chainId}`;
              }
            })
        } else {
          send(null, geom, status)
            .then(response => response.json())
            .then((json) => {
              if ('notice' in json) {
                setNotification({
                  type: NF_NOTICE,
                  title: t("notif.attention"),
                  text: json.notice,
                })
              } else {
                layers.req.getSource().updateParams({ time: Date.now() });
                if (json.id) {
                  window.open(`${window.REST_URL}/cabinet/auction/scheme?landId=${json.id}&pointNumbers=true&lineLengths=true&adjacentArrows=true`);
                }
              }
            });
        }
        setShowDrawLand(false);
      } else {
        if (window["requestFromGis"]) {
          window["requestFromGis"](props.uid, {
            send: new WKT().writeGeometry(features[0].get("geometry")),
          });
        }
      }
      setMode(MODE_SELECT);
    },
    [layers, props.standalone, props.uid, features, egovRequest],
  );

  const handleCancelEgovRequest = useCallback(
    (errors) => {
      if (props.standalone) {
        if (egovRequest) {
          cancelEgovRequest().then(() => {
            let srvType = egovRequest.srvTypeId === "504400" ? 'Com04/'
             : egovRequest.srvTypeId === "504300" ? 'Com05/'
             : egovRequest.srvTypeId === "504100" ? 'Com06/'
             : '';

            srvType += `?request-number=${egovRequest.chainId}`;

            if (errors && egovRequest.srvTypeId === "504400") {
              var error = errors.find((err) => err.statusId === 91 || err.statusId === 93);
              if (error)
                srvType = 'Com05/';
            }

            window.location.href = `${window.EGOV_URL}/${srvType}`;
          });
        }
      }
      setMode(MODE_SELECT);
    },
    [props.standalone, egovRequest],
  );

  const handleSendById = useCallback(
    (id, status) => {
      send(id, null, status).then(() => {
        layers.req.getSource().updateParams({ time: Date.now() });
      });
    },
    [layers],
  );


  useEffect(() => {
    let myMap = map;
    if (!myMap) {
      myMap = new Map({
        target: "map",
        controls: [mousePosition, new ScaleLine()],
        layers: [],
      });

      myMap.getView().fit(extent);

      const layers = createLayersOld(myMap, props.standalone);
      setMap(myMap);
      setLayers(layers);

    }
  }, [map, mousePosition, layers, props.standalone]);

  useEffect(() => {
    if (map && infoHandler && region && (mode === MODE_SELECT || mode === MODE_CHOOSE_OBJECT || mode === MODE_SELECT_PDP)) {
      layers.draw.getSource().clear();
      map.on("singleclick", infoHandler);
      return () => {
        map.un("singleclick", infoHandler);
      };
    }
  }, [map, layers, mode, infoHandler, region]);

  const tempHandler = useCallback(e => {
    // console.log(e.coordinate);
  }, []);

  var setOpenSlider;
  const bindOpenSlider = function (method) {
    setOpenSlider = method;
  }

  useEffect(() => {
    if (map) {
      map.on("singleclick", tempHandler);
      return () => {
        map.un("singleclick", tempHandler);
      };
    }
  }, [map, tempHandler]);

  function closeLayers() {
    setOpenLayers(false);
  }

  function closeMapInfo() {
    setOpenInfo(false);
  }
  const createButtons = () => {
    let res = [];
    if (!responsive.mobile) {
      res.push(<MapButton
        key="legend"
        style={{
          // top: responsive.mobile ? "88px" : "100px",
          top: responsive.mobile ? "98px" : "110px",
          right: responsive.mobile ? "16px" : "60px",
          backgroundImage: `url(${svgLegend})`,
        }}
        handleClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          event.nativeEvent.stopImmediatePropagation();
          setOpenLayersLegend(!openLayersLegend);
        }}
      />);
    }
    res = res.concat([
      <MapButton
        key="panKz"
        style={{
          bottom: responsive.mobile ? "212px" : "230px",
          right: responsive.mobile ? "16px" : "60px",
          backgroundImage: `url(${svgPanKz})`,
        }}
        handleClick={setDefaultView}
      />,
      <MapButton
        key="panDistrict"
        style={{
          bottom: responsive.mobile ? "152px" : "170px",
          right: responsive.mobile ? "16px" : "60px",
          backgroundImage: `url(${svgPanDistrict})`,
        }}
        handleClick={viewRegion}
      />,
      <MapButton
        key="zoomIn"
        style={{
          bottom: responsive.mobile ? "92px" : "110px",
          right: responsive.mobile ? "16px" : "60px",
          backgroundImage: `url(${svgZoomIn})`,
        }}
        handleClick={handleZoomIn}
      />,
      <MapButton
        key="zoomOut"
        style={{
          bottom: responsive.mobile ? "32px" : "50px",
          right: responsive.mobile ? "16px" : "60px",
          backgroundImage: `url(${svgZoomOut})`,
        }}
        handleClick={handleZoomOut}

      />,
    ]);
    return res;
  };

  return (
    <div style={{ height: "100%" }}>
      <RegionContext.Provider value={{ region, setRegion: handleSetRegion }}>
      <NotificationContext.Provider value={{ notification, setNotification }}>
        <ConfirmContext.Provider value={{ confirm, setConfirm }}>
          {props.standalone ? (
            <Header
              setLandInd={setLandInd}
              egovRequest={egovRequest}
              palette={dark ? lightPalette : darkPalette}
              handleTitleClick={setDefaultView}
              handleRegionClick={handleOpenRegion}
              handleSendApplication={handleSendApplication}
              handleSendAppService={handleSendAppService}
              handleHideService={handleHideService}
              handleAuthorizationPressed={handleAuthorizationPressed}
              openService={openService}
              region={region}
              openRegion={openRegion}
              setRegion={handleSetRegion}
              regions={regionsData}
              maxHeight={openRegionMaxHeight}
              showSidebar={showSidebar}
              setMode={setMode}
              mode={mode}
              services={servicesJson}
              userInfo={userInfo}
              handleLogout={handleLogout}
              handleUpdateQueue={handleUpdateQueue}
              setShowListService={setShowListService}
              handleDocsType={handleDocsType}
              handleSelectOrder={handleSelectOrder}
              handleOrders={handleOrders}
              orders={orders}
              handlePayment={handlePayment}
              setShowListMyObjs={setShowListMyObjs}
              setShowObjSearch={setShowObjSearch}
              handleCloseStats={handleCloseStats}
              setOrder={setOrder}
              order={order}
              docs={docs}
              handlePostDoc={handlePostDoc}
              handlePostOther={handlePostOther}
              orderLoaded={orderLoaded}
              handleClickOrder={handleClickOrder}
              handleClickOrders={handleClickOrders}
              handleClickProfile={handleClickProfile}
              handleClickNotif={handleClickNotif}
              showOrder={showOrder}
              showOrders={showOrders}
              showProfile={showProfile}
              showNotif={showNotif}
              notifs={notifs}
              handleUpdateNotif={handleUpdateNotif}
              handleGetNotifs={handleGetNotifs}
              setDisplayFormCreate={setDisplayFormCreate}
              handlePostCoords={handlePostCoords}
              handleSelectOnMap={handleSelect}
              setShowMyObjects={setShowProfileObjects}
              setShowLandIdObj={setShowLandIdObj}
              setShowProviders={setShowProviders}
              setNotification={setNotification}
              setIsNewObject={setIsNewObject}
              setQueueNum={setQueueNum}
              setAte={setAte}
              handleOpenQueue={handleOpenQueue}
              responsive={responsive}
              setShowBurger={setShowBurger}
              setChooseRegionMob={setChooseRegionMob}
              handleOpenInfo={handleOpenInfo}
              handleOpenLayers={handleOpenLayers}
              setOpenQueueMob={setOpenQueueMob}
              setOpenRegionsMob={setOpenRegionsMob}
              setShowOrders={setShowOrders}
              setShowOrder={setShowOrder}
              setShowNotif={setShowNotif}
              notifPage={notifPage}
              setNotifPage={setNotifPage}
              openLayers={openLayers}
              chooseRegionMob={chooseRegionMob}
              openInfo={openInfo}
              setSearchQueueFromReg={setSearchQueueFromReg}
              conf={props.conf}
              setEgovService={setEgovService}
              setShowRegistrationLecensee={setShowRegistrationLecensee}
              setShowWorkOrders={setShowWorkOrders}
              setCadNum={setCadNum}
              setDocs={setDocs}
              pdfNote={pdfNote}
              checkPdf={checkPdf}
              fileFormatNote={fileFormatNote}
              checkFileFormat={checkFileFormat}
              checkFileSize={checkFileSize}
              checkFileSizeNote={checkFileSizeNote}
              setShowPlanArchive={setShowPlanArchive}
              />
            ) : <HeaderEkgn
            region={region}
            handleRegionClick={handleOpenRegion}
            handleSendApplication={handleSendApplication}
            openRegion={openRegion}
            setOpenInfo={setOpenInfo}
            open={openInfo}
            dark={dark}
          />}

          {responsive.mobile ?
            <></>
            :
            props.standalone ? (
              <SideBar
                show={showSidebar}
                setShowSidebar={setShowSidebar}
                selectedMenu={selectedMenu}
                mode={mode}
                setMode={setMode}
                setOpenLayers={handleOpenLayers}
                openLayers={openLayers}
                setOpenLegend={handleOpenLegend}
                openLegend={openLegend}
                showStats={showStats}
                handleOpenStats={handleOpenStats}
                handleCloseStats={handleCloseStats}
                showQueue={showQueue}
                handleOpenQueue={handleOpenQueue}
                handleCloseQueueAndRegions={handleCloseQueueAndRegions}
                setOpenInfo={handleOpenInfo}
                openInfo={openInfo}
                showAnalytic={showAnalytic}
                showSelector={showSelector}
                region={region}
                dark={dark}
                handleOpenRegions={handleOpenRegions}
                handleOpenRegionsOnline={handleOpenRegionsOnline}
                handleOpenHelp = {handleOpenHelp}
                responsive={responsive}
                setShowLiabilityNotice={setShowLiabilityNotice}
                conf={props.conf}
              />
            ) : <SideBarEkgn
              mode={mode}
              setMode={setMode}
              setOpenLayers={handleOpenLayers}
              openLayers={openLayers}

            />
          }

          <div id="map" className="map" />

          <EknMapLayers
            standalone={props.standalone}
            bindOpenSlider={bindOpenSlider}
            show={showSidebar}
            theme={lightPalette}
            open={openLayers}
            openSlider={false}
            top={openLayersTop}
            maxHeight={openLayersMaxHeight}
            layerConfig={layerConfig}
            setOpen={closeLayers.bind(this)}
            showLayer={handleShowLayer}
            layersVisibility={layersVisibility}
            setLayersVisibility={setLayersVisibility}
            setLayerOpacity={handleSetLayerOpacity}
            setDark={handleSetDark}
            handleGetLayerObjects={handleGetLayerObjects}
            dark={dark}
            region={region}
            layers={layers}
            handleRefresh={handleRefresh}
            handleSetVersion={handleSetVersion}
            responsive={responsive}
          />

          <EknMapLegend
            layerConfig={layerConfig}
            layers={layers}
            layersVisibility={layersVisibility}
            theme={lightPalette}
            open={openLayersLegend}
            setOpenLayersLegend={setOpenLayersLegend}
          />

          {responsive.mobile && userInfo && userInfo.result === 0 &&
            <div ref={userMobIconRef} className="profile-img-mob" onClick={handleClickProfile}>
              <img src={userInfoSvg} alt="profile pic" />
            </div>
          }

          {responsive.mobile && showProfile && userInfo && <MobileProfile
            userInfo={userInfo}
            handleLogout={handleLogout}
            handleUpdateQueue={handleUpdateQueue}
            setShowMyObjects={setShowProfileObjects}
            setQueueNum={setQueueNum}
            setAte={setAte}
            handleOpenQueue={handleOpenQueue}
            handleClickProfile={handleClickProfile}
            userMobIconRef={userMobIconRef}
          />}

          {responsive.mobile && showOrders && <Orders
            handleSelectOrder={handleSelectOrder}
            handleOrders={handleOrders}
            orders={orders}
            handleClickOrder={handleClickOrder}
            handleClickOrders={handleClickOrders}
            setOrder={setOrder}
            orderLoaded={orderLoaded}
            handleDocsType={handleDocsType}
            getObjects={getObjects}
            palette={dark ? lightPalette : darkPalette}
            responsive={responsive}
            setNotification={setNotification}
            region={region}
          />}

          {responsive.mobile &&
            (showOrder && order && !order.orderLoading && (order.statusId >= 2 && order.statusId!=18 ? <PaymentService
              order={order}
              handleClickOrder={handleClickOrder}
              handleSelectOrder={handleSelectOrder}
              orderLoaded={orderLoaded}
              setOrder={setOrder}
              handleSelectOnMap={handleSelect}
              setNotification={setNotification}
              responsive={responsive}
              handlePostOther={handlePostOther}
              pdfNote={pdfNote}
              checkPdf={checkPdf}
              region={region}
              checkFileSize={checkFileSize}
              checkFileSizeNote={checkFileSizeNote}
            /> : !order.orderLoading && <Services
              setShowListService={setShowListService}
              setMode={setMode}
              handleClickOrder={handleClickOrder}
              handlePayment={handlePayment}
              setShowListMyObjs={setShowListMyObjs}
              setShowLandIdObj={setShowLandIdObj}
              setShowObjSearch={setShowObjSearch}
              handleCloseStats={handleCloseStats}
              setOrder={setOrder}
              order={order}
              docsType={docs}
              handlePostDoc={handlePostDoc}
              handlePostOther={handlePostOther}
              region={region}
              setDisplayFormCreate={setDisplayFormCreate}
              handlePostCoords={handlePostCoords}
              handleSelectOnMap={handleSelect}
              userInfo={userInfo}
              setIsNewObject={setIsNewObject}
              responsive={responsive}
              setCadNum={setCadNum}
              setDocs={setDocs}
              pdfNote={pdfNote}
              checkPdf={checkPdf}
              fileFormatNote={fileFormatNote}
              checkFileFormat={checkFileFormat}
              checkFileSize={checkFileSize}
              checkFileSizeNote={checkFileSizeNote}
            />))
          }

          {responsive.mobile && showNotif &&
            <NotifTab
              notifs={notifs}
              handleUpdateNotif={handleUpdateNotif}
              orderLoaded={orderLoaded}
              setOrder={setOrder}
              handleSelectOrder={handleSelectOrder}
              handleClickOrder={handleClickOrder}
              handleGetNotifs={handleGetNotifs}
              palette={dark ? lightPalette : darkPalette}
              getObjects={getObjects}
              responsive={responsive}
              notifPage={notifPage}
              setNotifPage={setNotifPage}
            />
          }

          {createButtons()}

          <div className="srs-title">
            {region ? getCoordSystem(region.srs) : "WGS 84"}
          </div>

          {showRegionNotSelected && (
            <div
              className="not-selected-region"
              style={{
                position: "absolute",
                top: 50 + (props.standalone ? 40 : 20),
                right: props.standalone ? 230 : 140,
              }}
            >
              <span>
                Выберите регион!
              </span>
            </div>
          )}

          {openProfile && userInfo && (
            <UserProfile
              theme={dark ? lightPalette : darkPalette}
              userInfo={userInfo}
              handleLogout={handleLogout}
              handleOpenQueue={handleOpenQueue}
              handleUpdateQueue={handleUpdateQueue}
              setQueueNum={setQueueNum}
              setAte={setAte}
              setSearchQueueFromReg={setSearchQueueFromReg}
              setShowRegistrationLecensee={setShowRegistrationLecensee}
              setShowWorkOrders={setShowWorkOrders}
              conf={props.conf}
              setShowPlanArchive={setShowPlanArchive}
            />
          )}

          {egovService && egovService.id==503100 && (
            <EgovService
              handleSelect={handleSelect} 
              theme={lightPalette}
              service={egovService}
              responsive={responsive}
              regions={regionsData}
              openRegionMaxHeight={openRegionMaxHeight}
              setEgovService={setEgovService}
              fileFormatNote={fileFormatNote}
              checkFileFormat={checkFileFormat}
              setMode={setMode}
              features={features}
              setFeatures={setFeatures}
              errors={errors}
              setErrors={setErrors}
              layers={layers}
              map={map}
              handleLoadFeature={handleLoadFeature}
              serviceId={egovService.id}
            />
          )}
          {egovService && egovService.id==502100 && (
            <EgovServiceCost
              handleSelect={handleSelect} 
              theme={lightPalette}
              service={egovService}
              responsive={responsive}
              regions={regionsData}
              openRegionMaxHeight={openRegionMaxHeight}
              setEgovService={setEgovService}
              setMode={setMode}
              mode={mode}
              features={features}
              setFeatures={setFeatures}
              errors={errors}
              setErrors={setErrors}
              fileFormatNote={fileFormatNote}
              checkFileFormat={checkFileFormat}
              layers={layers}
              map={map}
              handleLoadFeature={handleLoadFeature}
              serviceId={egovService.id}
            />
          )}

          {egovService && egovService.id==501150 && (
            <EgovServiceCost
              handleSelect={handleSelect}
              theme={lightPalette}
              service={egovService}
              responsive={responsive}
              regions={regionsData}
              openRegionMaxHeight={openRegionMaxHeight}
              setEgovService={setEgovService}
              setMode={setMode}
              features={features}
              setFeatures={setFeatures}
              errors={errors}
              setErrors={setErrors}
              fileFormatNote={fileFormatNote}
              checkFileFormat={checkFileFormat}
              layers={layers}
              map={map}
              handleLoadFeature={handleLoadFeature}
              serviceId={egovService.id}
            />
          )}

          {/* {props.standalone && (
            <EknMapInfo
              setOpenInfo={setOpenInfo}
              setMode={setMode}
              handleCancel={handleCancelEgovRequest}
              region={region}
              mode={mode}
              open={openInfo}
              maxHeight={openInfoMaxHeight}
              setOpen={closeMapInfo.bind(this)}
              anchorEl={document.getElementById("map")}
              searchResult={searchResult}
              map={map}
              layers={layers}
              handleSelect={handleSelect}
              handleSearch={handleSearch}
              getObjects={getObjects}
              theme={dark ? lightPalette : darkPalette}
              arrestIsChecked={arrestIsChecked}
              dark={dark}
              handleSend={handleSendById}
              handleCreateBuffer={handleCreateBuffer}
              handleDeleteBuffer={handleDeleteBuffer}
              standalone={props.standalone}
              showSidebar={showSidebar}
              handleAuctionSelect={handleAuctionSelect}
              getExtData={getExtData}
              order={order}
              setOrder={setOrder}
              responsive={responsive}
            />
          )} */}
          <EknMapInfo
              setOpenInfo={setOpenInfo}
              setMode={setMode}
              handleCancel={handleCancelEgovRequest}
              region={region}
              mode={mode}
              open={openInfo}
              maxHeight={openInfoMaxHeight}
              setOpen={closeMapInfo.bind(this)}
              anchorEl={document.getElementById("map")}
              searchResult={searchResult}
              map={map}
              layers={layers}
              handleSelect={handleSelect}
              handleSearch={handleSearch}
              handleSearchByCadNum={handleSearchByCadNum}
              getObjects={getObjects}
              theme={dark ? lightPalette : darkPalette}
              arrestIsChecked={arrestIsChecked}
              dark={dark}
              handleSend={handleSendById}
              handleCreateBuffer={handleCreateBuffer}
              handleDeleteBuffer={handleDeleteBuffer}
              standalone={props.standalone}
              showSidebar={showSidebar}
              handleAuctionSelect={handleAuctionSelect}
              getExtData={getExtData}
              order={order}
              setOrder={setOrder}
              responsive={responsive}
            />
          {!responsive.mobile && showDrawLand && <EknMapLandRequest
            userInfo={userInfo}
            standalone={props.standalone}
            theme={dark ? lightPalette : darkPalette}
            // open={mode === MODE_DRAW || mode === MODE_EDIT || mode === MODE_SELECT_PDP}
            setOpen={setMode}
            open={showDrawLand}
            top={landRequestTop}
            maxHeight={landRequestMaxHeight}
            features={features}
            setFeatures={setFeatures}
            handleLoadFeature={handleLoadFeature}
            handleSend={handleSend}
            handleCancel={handleCancelEgovRequest}
            perimeter={perimeter}
            resetPerimeter={setPerimeter}
            setMode={setMode}
            egovRequest={egovRequest}
            errors={errors}
            layers={layers}
	          handleSelect={handleSelect}
            map={map}
            checkFileFormat={checkFileFormat}
            fileFormatNote={fileFormatNote}
            uid={props.uid}
            setShowDrawLand={setShowDrawLand}
            send={send}
          />}
          <Line
            className="line-wrapper"
            open={region && mode === MODE_MEASURE}
            map={map}
            createSnapLayer={createSnapLayer}
            layers={layers}
            region={region}
            setErrors={setErrors}
            setFeatures={setFeatures}
            mode={mode}
          />
          <Shapes
            show={showSidebar}
            open={mode === MODE_SHAPE}
            map={map}
            theme={dark ? lightPalette : darkPalette}
          />

          {responsive.mobile && showBurger &&
            <Burger
              setShowBurger={setShowBurger}
              region={region}
              setOpenInfo={handleOpenInfo}
              openInfo={openInfo}
              handleOpenRegionMob={handleOpenRegionMob}
              setOpenLayers={handleOpenLayers}
              openLayers={openLayers}
              showQueue={showQueue}
              setOpenRegionsMob={setOpenRegionsMob}
              setOpenQueueMob={setOpenQueueMob}
              notifs={notifs}
              userInfo={userInfo}
              handleLogout={handleLogout}
              setShowOrders={setShowOrders}
              setShowNotif={setShowNotif}
              handleAuthorizationPressed={handleAuthorizationPressed}
            />
          }

          {responsive.mobile && chooseRegionMob &&
            <ChooseRegion
              maxHeight={openRegionMaxHeight}
              responsive={responsive}
              regions={regionsData}
              setRegion={handleSetRegionMob}
            />
          }

          {responsive.mobile && openRegionsMob &&
            <RegionsMobile
              ate={ate}
              setAte={setAte}
              getAtes={getAtes}
              ates={ates}
              setAtes={setAtes}
              handleCloseQueueAndRegions={handleCloseQueueAndRegions}
              setNameAndInitials={setNameAndInitials}
              handleOpenQueue={handleOpenQueue}
              ateChain={ateChain}
              setAteChain={setAteChain}
              setOpenRegionsMob={setOpenRegionsMob}
              setOpenQueueMob={setOpenQueueMob}
            />
          }

          {responsive.mobile && openQueueMob &&
            <QueueMobile
              getQueue={getQueue}
              getQueueStatus={getQueueStatus}
              setOpenRegionsMob={setOpenRegionsMob}
              setOpenQueueMob={setOpenQueueMob}
              setQueueNum={setQueueNum}
              ate={ate}
              setNameAndInitials={setNameAndInitials}
            />
          }

          {!responsive.mobile && showStats && (
            <Stats
              show={showSidebar}
              map={map}
              layers={layers}
              dark={dark}
              theme={dark ? lightPalette : darkPalette}
              getStats={extGetStatistcs}
              region={region}
              handleGetStatObjects={handleGetStatObjects}
              handleCloseStats={handleCloseStats}
              setShowListService={setShowListService}
              setShowListMyObjs={setShowListMyObjs}
              setShowObjSearch={setShowObjSearch}
              setShowSelector={setShowSelector}
              analyticLoading={analyticLoading}
              setAnalyticLoading={setAnalyticLoading}
              handleSelect={handleSelect}
              responsive={responsive}
            />
          )}

          {!responsive.mobile && showQueue && (
            <Queue
              palette={dark ? lightPalette : darkPalette}
              handleCloseQueue={handleCloseQueue}
              handleCloseQueueAndRegions={handleCloseQueueAndRegions}
              getQueue={getQueue}
              getQueueStatus={getQueueStatus}
              ate={ate}
              setAte={setAte}
              handleOpenQueue={handleOpenQueue}
              nameAndInitials={nameAndInitials}
              setNameAndInitials={setNameAndInitials}
              handleOpenRegions={handleOpenRegions}
              setQueueNum={setQueueNum}
              queueNum={queueNum}
              getQueueByNum={getQueueByNum}
              responsive={responsive}
              searchQueueNum={searchQueueNum}
              searchQueueFromReg={searchQueueFromReg}
            />
          )}

          {!responsive.mobile && showRegions && (
            <Regions
              palette={dark ? lightPalette : darkPalette}
              ate={ate}
              setAte={setAte}
              ates={ates}
              setAtes={setAtes}
              getAtes={getAtes}
              handleCloseQueueAndRegions={handleCloseQueueAndRegions}
              nameAndInitials={nameAndInitials}
              setNameAndInitials={setNameAndInitials}
              handleOpenQueue={handleOpenQueue}
              ateChain={ateChain}
              setAteChain={setAteChain}
              responsive={responsive}
              queueNum={searchQueueNum}
              setQueueNum={setSearchQueueNum}
              setSearchQueueFromReg={setSearchQueueFromReg}
            />
          )}
          {!responsive.mobile && showRegionsOnline && (
            <RegionOnline
             palette={dark ? lightPalette : darkPalette}
              handleCloseRegionsOnline={handleCloseRegionsOnline}
              getRegionsOnline={getRegionsOnline}
              regionsOnline={regionsOnline}
              setRegionsOnline={setRegionsOnline}
            />
          )}
          {!responsive.mobile && showPlanArchive && (
            <PlanArchive  
              responsive={responsive}
              showPlanArchive={showPlanArchive}
              setShowPlanArchive={setShowPlanArchive}
            />
          )}
          {/*props.standalone ?
            <Info theme={dark ? lightPalette : darkPalette} region={region} />
		  : ""*/}
          {showHelp && (
            <Help
              palette={dark ? lightPalette : darkPalette}
              handleCloseHelp = {handleCloseHelp}
            />
          )}
          {notification && (
            <Notification
              type={notification.type}
              title={notification.title}
              text={notification.text}
              handleHide={setNotification.bind(null)}
              handleOnClose={notification.onClose}
            />
          )}
          {confirm && (
            <ConfirmDialog
              title={confirm.title}
              text={confirm.text}
              handleOnConfirm={confirm.onConfirm}
              handleOnCancel={setConfirm.bind(null)}
            />
          )}

          {/* {infoDialog && (
          <InfoDialog
            title={infoDialog.title}
            text={infoDialog.text}
            setInfoDialog={infoDialog.setInfoDialog}
          />
          )} */}
          {/* // setTimeout(() => { setSimpleNotification(false)}, 2000 )     */}
          {simpleNotification && (<NotifWrapper><p>Уменьшение масштаба</p></NotifWrapper>)}
          {showListService && <ListService
            handleService={handleService}
            service={service}
            setShowListService={setShowListService}
            palette={dark ? lightPalette : darkPalette}
            order={order}
            setOrder={setOrder}
            responsive={responsive}
            handleDocsTypeAll={handleDocsTypeAll}
          />}
          {showRegistrationLecensee && <RegistrationLecensee
            setShowRegistrationLecensee={setShowRegistrationLecensee}
            responsive={responsive}
            regionsData={regionsData}
			userInfo={userInfo}
          />}
          {isShowWorkOrders && <LecenseeWorkOrders
            setShowWorkOrders={setShowWorkOrders}
            responsive={responsive}
            setNotification={setNotification}
            pdfNote={pdfNote}
            checkPdf={checkPdf}
            checkFileSize={checkFileSize}
            checkFileSizeNote={checkFileSizeNote}
          />}
          {(showListMyObjs || showLandIdObj)  && <ListMyObjs
            handleMyObjs={handleMyObjs}
            handleLand={handleLand}
            myObjs={myObjs}
            setShowListMyObjs={setShowListMyObjs}
            setShowLandIdObj={setShowLandIdObj}
            palette={dark ? lightPalette : darkPalette}
            myObjLoaded={myObjLoaded}
            setMyObjLoaded={setMyObjLoaded}
            order={order}
            setOrder={setOrder}
            region={region}
            handleGetCoords={handleGetCoords}
            responsive={responsive}
            showLandIdObj={showLandIdObj}
            showListMyObjs={showListMyObjs}
            landInd={landInd}
          />}
          {isShowActUstForm && <ActUstForm
		  	setVisible={setShowActUstForm}
            responsive={responsive}
            regionsData={regionsData}
          />}

          
          {showProvider  && <ListProviders
            handleProviders={handleProviders}
            providers={providers}
            setShowProviders={setShowProviders}
            palette={dark ? lightPalette : darkPalette}
            myObjLoaded={myObjLoaded}
            setMyObjLoaded={setMyObjLoaded}
            order={order}
            setOrder={setOrder}
            region={region}
            handleGetCoords={handleGetCoords}
            responsive={responsive}
            showProvider={showProvider}
          />}   

          {showObjSearch && <SearchObject
            region={region}
            setShowObjSearch={setShowObjSearch}
            order={order}
            setOrder={setOrder}
            setFoundObject={setFoundObject}
            isNewObject={isNewObject}
            responsive={responsive}
            cadNum={cadNum}
            handleGetCoords={handleGetCoords}
          />}
          {<FormNewObj
            display={displayFormCreate}
            setDisplay={setDisplayFormCreate}
            dicts={dicts}
            setDicts={setDicts}
            order={order}
            setOrder={setOrder}
            handleDicts={handleDicts}
            region={region}
            createdObject={createdObject}
            setCreatedObject={setCreatedObject}
            handlePostDoc={handlePostDoc}
            setShowObjSearch={setShowObjSearch}
            setIsNewObject={setIsNewObject}
            foundObject={foundObject}
            setFoundObject={setFoundObject}
            responsive={responsive}
            setCadNum={setCadNum}
            cadNum={cadNum}
          />}
          {showProfileObjects && <ProfileObjects
            profileObjectsLoading={profileObjectsLoading}
            profileObjects={profileObjects}
            setShowProfileObjects={setShowProfileObjects}
            handleSelectOnMap={handleSelect}
            handleSetRegion={handleSetRegion}
            getExtData={getExtData}
            setShowObjectArrests={setShowObjectArrests}
            setObjectArrests={setObjectArrests}
            setObjectArrestsLoading={setObjectArrestsLoading}
            setArrestsKadNum={setArrestsKadNum}
          />}
          {showObjectArrests && <ObjectArrests
            setShowObjectArrests={setShowObjectArrests}
            setShowProfileObjects={setShowProfileObjects}
            objectArrests={objectArrests}
            objectArrestsLoading={objectArrestsLoading}
            arrestsKadNum={arrestsKadNum}
          />}
          {showEmailVerify && <EmailVerify
            setShowEmailVerify={setShowEmailVerify}
            removeEmailVerify={removeEmailVerify}
            checkEmailVerify={checkEmailVerify}
            resendEmail={resendEmail}
          />}
          {showEmailError && <EmailError
            setShowEmailError={setShowEmailError}
          />}
          {/*showLiabilityNotice && <LiabilityNotice
            setShowLiabilityNotice={setShowLiabilityNotice}
          />*/}
        </ConfirmContext.Provider>
      </NotificationContext.Provider>
      </RegionContext.Provider>
    </div>
  );
}


function createLayersOld(map, standalone) {
  const gsUrl = window.GS_URL;
  const ggkGsUrl = window.GGK_GS_URL;
  const layers = {};

  layers.maxar = new TileLayer({
    source: new TileWMS({
      url: `${gsUrl}/egkn/wms`,
      params: {
        LAYERS: "egkn:maxar_raster_tiles",
        TILED: true,
        projection: "EPSG:4326",
      },
      serverType: "geoserver",
      transition: 0,
    }),
    visible: false,
  });
  map.addLayer(layers.maxar);

  layers.garysh = new TileLayer({
    source: new TileWMS({
      url: `${gsUrl}/egkn/wms`,
      params: { LAYERS: "egkn:basemap.For_all_Mosaic_KZ", TILED: true },
      serverType: "geoserver",
      transition: 0,
      projection: 'EPSG:3857',
    }),
    visible: false,
  });
  map.addLayer(layers.garysh);

  layers.bing = new TileLayer({
    preload: Infinity,
    source: new BingMaps({
      key: "AuydQjZggYuwKfCMY808Wmzcil6xXHWOkb-t20IZ8ivHXq6ZtaZ5DDZrbRJ6BQq4",
      imagerySet: "AerialWithLabelsOnDemand",
    }),
    visible: false,
  });
  map.addLayer(layers.bing);

  layers.kazgeo = new LayerGroup({
    layers: [
      /*new TileLayer({
        preload: Infinity,
        source: new BingMaps({
          key:
            "AuydQjZggYuwKfCMY808Wmzcil6xXHWOkb-t20IZ8ivHXq6ZtaZ5DDZrbRJ6BQq4",
          imagerySet: "AerialWithLabelsOnDemand",
        }),
      }),*/
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/base/wms`,
          params: { LAYERS: "base:basemap.geocom", TILED: true },
          serverType: "geoserver",
          transition: 0,
          projection: 'EPSG:3857',
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: "https://map.gov.kz/geoserver/wms",
          params: { LAYERS: "kz-aero", TILED: true, FORMAT: 'image/jpeg' },
          serverType: "geoserver",
          transition: 0,
        }),
      })
    ],
    visible: false
  });
  map.addLayer(layers.kazgeo);

  layers.base = new LayerGroup({
    layers: [
      /*new TileLayer({
        preload: Infinity,
        source: new BingMaps({
          key:
            "AuydQjZggYuwKfCMY808Wmzcil6xXHWOkb-t20IZ8ivHXq6ZtaZ5DDZrbRJ6BQq4",
          imagerySet: "AerialWithLabelsOnDemand",
        }),
      }),*/
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/base/wms`,
          params: { LAYERS: "base:basemap.geocom", TILED: true },
          serverType: "geoserver",
          transition: 0,
          projection: 'EPSG:3857',
        }),
      }),
    ],
  });
  map.addLayer(layers.base);

  layers.base_kazgeo = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: "https://kazmap.kz/geoserver/wms",
          params: { LAYERS: "basemap", TILED: true },
          serverType: "geoserver",
          transition: 0,
          projection: 'EPSG:3857',
        }),
      }),
    ],
    visible: false
  });
  layers.base_kazgeo.set("wms", true);
  map.addLayer(layers.base_kazgeo);

  layers.uk = new TileLayer({
    source: new TileWMS({
      url: `${gsUrl}/egkn/wms`,
      params: { LAYERS: "egkn:uk_view", TILED: true },
      serverType: "geoserver",
      transition: 0,
	  }),
	  visible: false,
  });
  map.addLayer(layers.uk);

  // TBO
  layers.tbo = new ImageLayer({
    source: new ImageArcGISRest({
      ratio: 1,
      params: { LAYERS: "show:1", service_id: "othodi" },
      url: `${window.COSMO_URL}/othodi/MapServer`,
    }),
    visible: false,
    type: LayerType.ARCMAP
  });
  layers.tbo.set("arcgis", true);
  layers.tbo.set("restPath", "othodi");
  layers.tbo.set("name", "tbo");
  map.addLayer(layers.tbo);

  layers.agro = new ImageLayer({
    source: new ImageArcGISRest({
      ratio: 1,
      params: { LAYERS: "show:" },
      url: `${window.COSMO_URL}/agro/MapServer`,
    }),
    visible: false,
    type: LayerType.ARCMAP,
  });
  layers.agro.set("arcgis", true);
  layers.agro.set("restPath", "agro");
  layers.agro.set("name", "agro");
  map.addLayer(layers.agro);

  layers.tsel = new ImageLayer({
    source: new ImageArcGISRest({
      ratio: 1,
      params: { LAYERS: "show:1" },
      url: `${window.COSMO_URL}/tsel/MapServer`,
    }),
    visible: false,
    type: LayerType.ARCMAP,
  });
  layers.tsel.set("arcgis", true);
  layers.tsel.set("restPath", "tsel");
  layers.tsel.set("name", "tsel");
  map.addLayer(layers.tsel);

  layers.pch = new TileLayer({
    source: new TileWMS({
      url: `${gsUrl}/egkn/wms`,
      params: {
        LAYERS: "egkn:pch_view",
        STYLES: "egkn:pch_label_style",
        TILED: true,
      },
      serverType: "geoserver",
      transition: 0,
    }),
    visible: false
  });
  map.addLayer(layers.pch);

  layers.rst = new TileLayer({
    source: new TileWMS({
      url: `${gsUrl}/egkn/wms`,
      params: { LAYERS: "egkn:rst_view", TILED: true },
      serverType: "geoserver",
      transition: 0,
    }),
    visible: false
  });
  map.addLayer(layers.rst);

  layers.ugl = new TileLayer({
    source: new TileWMS({
      url: `${gsUrl}/egkn/wms`,
      params: { LAYERS: "egkn:ugl_view", TILED: true },
      serverType: "geoserver",
      transition: 0,
    }),
    visible: false
  });
  map.addLayer(layers.ugl);

  layers.eval = new TileLayer({
    source: new TileWMS({
      url: `${gsUrl}/egkn/wms`,
      params: { LAYERS: "egkn:oz_view", TILED: true },
      serverType: "geoserver",
      transition: 0,
    }),
    visible: false
  });
  map.addLayer(layers.eval);

  layers.pdp = new TileLayer({
    source: new TileWMS({
      url: `${gsUrl}/egkn/wms`,
      params: { LAYERS: "egkn:pdp_view", TILED: true },
      serverType: "geoserver",
      transition: 0,
    }),
    visible: false,
  });
  map.addLayer(layers.pdp);

  layers.gp = new TileLayer({
    source: new TileWMS({
      url: `${gsUrl}/egkn/wms`,
      params: { LAYERS: "egkn:gp_view", TILED: true },
      serverType: "geoserver",
      transition: 0,
    }),
    visible: false
  });
  map.addLayer(layers.gp);

  ggkGsLayers = new LayerBundle(`${ggkGsUrl}/wms`, 'EPSG:4326', map);

  ggkGsLayers.addLayer('ggk_gp_zones', 'kz:gpzone', false);
  ggkGsLayers.addLayer('ggk_gp_redlines', 'kz:gpreg', false);

  ggkGsLayers.addLayer('ggk_pdp_zones', 'kz:pdpzone', false);
  ggkGsLayers.addLayer('ggk_pdp_redlines', 'kz:pdpreg', false);

  ggkGsLayers.addLayer('ggk_invsupplyzonewater', 'kz:invsupplyzonewater', false);
  ggkGsLayers.addLayer('ggk_invsupplyzoneelectro', 'kz:invsupplyzoneelectro', false);
  ggkGsLayers.addLayer('ggk_invsupplyzonefecal', 'kz:invsupplyzonefecal', false);
  ggkGsLayers.addLayer('ggk_invsupplyzonegas', 'kz:invsupplyzonegas', false);
  ggkGsLayers.addLayer('ggk_invsupplyzoneheat', 'kz:invsupplyzoneheat', false);
  ggkGsLayers.addLayer('ggk_invsupplyzonephone', 'kz:invsupplyzonephone', false);
  ggkGsLayers.addLayer('ggk_invsupplyzonesewerage', 'kz:invsupplyzonesewerage', false);

  layers.vop = new TileLayer({
    source: new TileWMS({
      url: `${gsUrl}/egkn/wms`,
      params: { LAYERS: "egkn:vop_view", TILED: true },
      serverType: "geoserver",
      transition: 0,
    }),
    visible: false
  });
  map.addLayer(layers.vop);

  layers.inv = new TileLayer({
    source: new TileWMS({
      url: `${gsUrl}/egkn/wms`,
      params: { LAYERS: "egkn:inv_02_036", TILED: true },
      serverType: "geoserver",
      transition: 0,
    }),
    visible: false,
    type: LayerType.EGKN,
    prefix: 'egkn:inv',
  });
  map.addLayer(layers.inv);

  if (!standalone) {
    layers.buf = new TileLayer({
      source: new TileWMS({
        url: `${gsUrl}/egkn/wms`,
        params: { LAYERS: "egkn:buf_view", TILED: true },
        serverType: "geoserver",
        transition: 0,
      }),
      visible: false
    });
    map.addLayer(layers.buf);
  }

  layers.ohz = new TileLayer({
    source: new TileWMS({
      url: `${gsUrl}/egkn/wms`,
      params: { LAYERS: "egkn:ohz_view", TILED: true },
      serverType: "geoserver",
      transition: 0,
    }),
    visible: false
  });
  map.addLayer(layers.ohz);

  layers.voz = new TileLayer({
    source: new TileWMS({
      url: `${gsUrl}/egkn/wms`,
      params: { LAYERS: "egkn:voz_view", TILED: true },
      serverType: "geoserver",
      transition: 0,
    }),
    visible: false
  });
  map.addLayer(layers.voz);

  layers.lands = new TileLayer({
    source: new TileWMS({
      url: `${gsUrl}/egkn/wms`,
      params: { LAYERS: "egkn:u_view", TILED: true },
      serverType: "geoserver",
      transition: 0,
    }),
    visible: true,
  });
  map.addLayer(layers.lands);

  if (!standalone) {
    layers.lands_archive = new TileLayer({
      source: new TileWMS({
        url: `${gsUrl}/egkn/wms`,
        params: { LAYERS: "egkn:u_archive", TILED: true },
        serverType: "geoserver",
        transition: 0,
      }),
      visible: false,
    });
    map.addLayer(layers.lands_archive);
  }

  layers.part = new TileLayer({
    source: new TileWMS({
      url: `${gsUrl}/egkn/wms`,
      params: { LAYERS: "egkn:part_view", TILED: true },
      serverType: "geoserver",
      transition: 0,
    }),
    visible: false,
  });
  map.addLayer(layers.part);

  if (!standalone) {
    layers.part_archive = new TileLayer({
      source: new TileWMS({
        url: `${gsUrl}/egkn/wms`,
        params: { LAYERS: "egkn:part_archive", TILED: true },
        serverType: "geoserver",
        transition: 0,
      }),
      visible: false,
    });
    map.addLayer(layers.part_archive);
  }

  layers.ul = new TileLayer({
    source: new TileWMS({
      url: `${gsUrl}/egkn/wms`,
      params: { LAYERS: "egkn:ul_view", TILED: true },
      serverType: "geoserver",
      transition: 0,
    }),
    visible: false,
  });
  map.addLayer(layers.ul);

  if (!standalone) {
    layers.ul_archive = new TileLayer({
      source: new TileWMS({
        url: `${gsUrl}/egkn/wms`,
        params: { LAYERS: "egkn:ul_archive", TILED: true },
        serverType: "geoserver",
        transition: 0,
      }),
      visible: false,
    });
    map.addLayer(layers.ul_archive);
  }

  layers.arrest = new TileLayer({
    source: new TileWMS({
      url: `${gsUrl}/egkn/wms`,
      params: { LAYERS: "egkn:arrest_view", TILED: true },
      serverType: "geoserver",
      transition: 0,
    }),
    visible: false,
  });
  map.addLayer(layers.arrest);

  if (!standalone) {
    layers.sogl = new TileLayer({
      source: new TileWMS({
        url: `${gsUrl}/egkn/wms`,
        params: { LAYERS: "egkn:sogl_view", TILED: true },
        serverType: "geoserver",
        transition: 0,
      }),
      visible: true,
    });
    map.addLayer(layers.sogl);

    layers.sogl_agreed = new TileLayer({
      source: new TileWMS({
        url: `${gsUrl}/egkn/wms`,
        params: { LAYERS: "egkn:sogl_agreed", TILED: true },
        serverType: "geoserver",
        transition: 0,
      }),
      visible: false,
    });
    map.addLayer(layers.sogl_agreed);

    layers.sogl_rejected = new TileLayer({
      source: new TileWMS({
        url: `${gsUrl}/egkn/wms`,
        params: { LAYERS: "egkn:sogl_rejected", TILED: true },
        serverType: "geoserver",
        transition: 0,
      }),
      visible: false,
    });
    map.addLayer(layers.sogl_rejected);

    layers.sogl_registered = new TileLayer({
      source: new TileWMS({
        url: `${gsUrl}/egkn/wms`,
        params: { LAYERS: "egkn:sogl_registered", TILED: true },
        serverType: "geoserver",
        transition: 0,
      }),
      visible: false,
    });
    map.addLayer(layers.sogl_registered);
  }

  layers.err_ohz = new TileLayer({
    source: new TileWMS({
      url: `${gsUrl}/egkn/wms`,
      params: { LAYERS: "egkn:err_ohz_view", TILED: true },
      serverType: "geoserver",
      transition: 0,
    }),
    visible: false,
  });
  map.addLayer(layers.err_ohz);

  layers.err_voz = new TileLayer({
    source: new TileWMS({
      url: `${gsUrl}/egkn/wms`,
      params: { LAYERS: "egkn:err_voz_view", TILED: true },
      serverType: "geoserver",
      transition: 0,
    }),
    visible: false,
  });
  map.addLayer(layers.err_voz);

  layers.err_uk = new TileLayer({
    source: new TileWMS({
      url: `${gsUrl}/egkn/wms`,
      params: { LAYERS: "egkn:err_uk_view", TILED: true },
      serverType: "geoserver",
      transition: 0,
    }),
    visible: false,
  });
  map.addLayer(layers.err_uk);

  layers.err_u = new TileLayer({
    source: new TileWMS({
      url: `${gsUrl}/egkn/wms`,
      params: { LAYERS: "egkn:err_u_view", TILED: true },
      serverType: "geoserver",
      transition: 0,
    }),
    visible: false,
  });
  map.addLayer(layers.err_u);

  layers.free = new TileLayer({
    source: new TileWMS({
      url: `${gsUrl}/egkn/wms`,
      params: { LAYERS: "egkn:freelands_view", TILED: true },
      serverType: "geoserver",
      transition: 0,
    }),
    visible: false,
  });
  map.addLayer(layers.free);

  const clusterLayer = new ClusterLayer(map, gsUrl);
  layers.cluster = clusterLayer;
  map.addLayer(clusterLayer);
  clusterLayer.setVisible(false); 

  layers.req = new TileLayer({
    source: new TileWMS({
      url: `${gsUrl}/egkn/wms`,
      params: { LAYERS: "egkn:reqland_view", TILED: true },
      serverType: "geoserver",
      transition: 0,
    }),
    visible: false,
  });
  map.addLayer(layers.req);

  layers.buildings = new TileLayer({
    source: new TileWMS({
      url: `${gsUrl}/egkn/wms`,
      params: { LAYERS: "egkn:ned_view", TILED: true },
      serverType: "geoserver",
      transition: 0,
    }),
    visible: false,
  });
  map.addLayer(layers.buildings);

  layers.demo_build = new TileLayer({
    source: new TileWMS({
      url: `${gsUrl}/egkn/wms`,
      params: {
        LAYERS: "egkn:1431-Kazakhstan-demo-building-32640",
        TILED: true,
        projection: "EPSG:4326",
      },
      serverType: "geoserver",
      transition: 0,
    }),
    visible: false,
  });
  map.addLayer(layers.demo_build);

  layers.fire_dept = new ImageLayer({
    source: new ImageArcGISRest({
      ratio: 1,
      params: { LAYERS: "show:" },
      url: `${window.COSMO_URL}/kchs/MapServer`,
    }),
    visible: false,
    type: LayerType.ARCMAP,
  });
  layers.fire_dept.set("arcgis", true);
  layers.fire_dept.set("restPath", "kchs");
  layers.fire_dept.set("name", "fire_dept");
  map.addLayer(layers.fire_dept);

  layers.season_dyn = new ImageLayer({
    source: new ImageArcGISRest({
      ratio: 1,
      params: { LAYERS: "show:" },
      url: `${window.COSMO_URL}/kchs/MapServer`,
    }),
    visible: false,
    type: LayerType.ARCMAP,
  });
  layers.season_dyn.set("arcgis", true);
  layers.season_dyn.set("restPath", "kchs");
  layers.season_dyn.set("name", "season_dyn");
  map.addLayer(layers.season_dyn);

  layers.deforested = new ImageLayer({
    source: new ImageArcGISRest({
      ratio: 1,
      params: { LAYERS: "show:0" },
      url: `${window.COSMO_URL}/leshoz/MapServer`,
    }),
    visible: false,
    type: LayerType.ARCMAP,
  });
  layers.deforested.set("arcgis", true);
  layers.deforested.set("restPath", "leshoz");
  layers.deforested.set("name", "deforested");
  map.addLayer(layers.deforested);

  // layers.burned_forests = new ImageLayer({
  //   source: new ImageArcGISRest({
  //     ratio: 1,
  //     params: { LAYERS: "show:1" },
  //     url: `${window.COSMO_URL}/leshoz/MapServer`,
  //   }),
  //   visible: false,
  // });
  // layers.burned_forests.set("arcgis", true);
  // layers.burned_forests.set("restPath", "leshoz");
  // layers.burned_forests.set("name", "burned_forests");
  // map.addLayer(layers.burned_forests);

  layers.forests_DZZ = new ImageLayer({
    source: new ImageArcGISRest({
      ratio: 1,
      params: { LAYERS: "show:533" },
      url: `${window.COSMO_URL}/leshoz/MapServer`,
    }),
    visible: false,
    type: LayerType.ARCMAP,
  });
  layers.forests_DZZ.set("arcgis", true);
  layers.forests_DZZ.set("restPath", "leshoz");
  layers.forests_DZZ.set("name", "forests_DZZ");
  map.addLayer(layers.forests_DZZ);

  layers.regions = new VectorLayer({
    source: new Vector({
      format: new GeoJSON(),
      url: function (extent) {
        return `${gsUrl}/wfs?service=WFS&version=1.1.0&request=GetFeature&typename=egkn:regions&outputFormat=application/json&srsname=EPSG:3857
        &bbox=${extent.join(",")},EPSG:3857`;
      },
      strategy: bboxStrategy,
      transition: 250,
    }),
    visible: false,
    style: getRegionStyle,
  });
  map.addLayer(layers.regions);

  //GGK
  districtLayers = new DistrictLayers(`${gsUrl}/egkn/wms`);

  districtLayers.createPool('ggk_gpzone_pol_local', 'egkn:ggk_gpzone_pol_local', null, map);
  districtLayers.createPool('ggk_gpzone_pol_global', 'egkn:ggk_gpzone_pol_global', 'EPSG:4326', map);

  // Функциональные зоны - ggk.gpzone
  districtLayers.createLayer('ggk_gpzone_pol_local', 'gpzonejil', false);
  districtLayers.createLayer('ggk_gpzone_pol_local', 'gpzoneodz', false);
  districtLayers.createLayer('ggk_gpzone_pol_local', 'gpzoneprom', false);
  districtLayers.createLayer('ggk_gpzone_pol_local', 'gpzonetransport', false);
  districtLayers.createLayer('ggk_gpzone_pol_local', 'gpzoneeng', false);
  districtLayers.createLayer('ggk_gpzone_pol_local', 'gpzoneprotect', false);
  districtLayers.createLayer('ggk_gpzone_pol_local', 'gpzoneagricult', false);
  districtLayers.createLayer('ggk_gpzone_pol_local', 'gpzonerec', false);
  districtLayers.createLayer('ggk_gpzone_pol_local', 'gpzonerez', false);
  districtLayers.createLayer('ggk_gpzone_pol_local', 'gpzonennt', false);
  districtLayers.createLayer('ggk_gpzone_pol_local', 'gpzonerestrict', false);
  districtLayers.createLayer('ggk_gpzone_pol_local', 'gpzonespec', false);
  districtLayers.createLayer('ggk_gpzone_pol_local', 'gpzonesan', false);
  districtLayers.createLayer('ggk_gpzone_pol_local', 'gpzonesub', false);
  districtLayers.createLayer('ggk_gpzone_pol_local', 'gpzonerecult', false);

  districtLayers.createLayer('ggk_gpzone_pol_global', 'gpzonejil', false);
  districtLayers.createLayer('ggk_gpzone_pol_global', 'gpzoneodz', false);
  districtLayers.createLayer('ggk_gpzone_pol_global', 'gpzoneprom', false);
  districtLayers.createLayer('ggk_gpzone_pol_global', 'gpzonetransport', false);
  districtLayers.createLayer('ggk_gpzone_pol_global', 'gpzoneeng', false);
  districtLayers.createLayer('ggk_gpzone_pol_global', 'gpzoneprotect', false);
  districtLayers.createLayer('ggk_gpzone_pol_global', 'gpzoneagricult', false);
  districtLayers.createLayer('ggk_gpzone_pol_global', 'gpzonerec', false);
  districtLayers.createLayer('ggk_gpzone_pol_global', 'gpzonerez', false);
  districtLayers.createLayer('ggk_gpzone_pol_global', 'gpzonennt', false);
  districtLayers.createLayer('ggk_gpzone_pol_global', 'gpzonerestrict', false);
  districtLayers.createLayer('ggk_gpzone_pol_global', 'gpzonespec', false);
  districtLayers.createLayer('ggk_gpzone_pol_global', 'gpzonesan', false);
  districtLayers.createLayer('ggk_gpzone_pol_global', 'gpzonesub', false);
  districtLayers.createLayer('ggk_gpzone_pol_global', 'gpzonerecult', false);

  //Здания и сооружения населенного пункта - gpbuild
  layers.ggk_gpbuild = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpbuild",
    //        STYLES: "egkn:pch_label_style",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpbuild_all",
            TILED: true,
            viewparams: "layer_name:gpbuild"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_gpbuild.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_gpbuild);

  //Инженерные коммуникации линейные - gpenglin
  layers.ggk_gpenggaslin = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpenggaslin",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpenglin_all",
            TILED: true,
            viewparams: "layer_name:gpenggaslin"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_gpenggaslin.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_gpenggaslin);

  layers.ggk_gpengwodlin = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpengwodlin",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpenglin_all",
            TILED: true,
            viewparams: "layer_name:gpengwodlin"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_gpengwodlin.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_gpengwodlin);

  layers.ggk_gpengkanlin = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpengkanlin",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpenglin_all",
            TILED: true,
            viewparams: "layer_name:gpengkanlin"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_gpengkanlin.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_gpengkanlin);

  layers.ggk_gpengteplin = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpengteplin",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpenglin_all",
            TILED: true,
            viewparams: "layer_name:gpengteplin"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_gpengteplin.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_gpengteplin);

  layers.ggk_gpenglin = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpenglin",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpenglin_all",
            TILED: true,
            viewparams: "layer_name:gpenglin"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_gpenglin.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_gpenglin);

  layers.ggk_gpengellin = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpengellin",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpenglin_all",
            TILED: true,
            viewparams: "layer_name:gpengellin"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_gpengellin.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_gpengellin);

  layers.ggk_gpengtellin = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpengtellin",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpenglin_all",
            TILED: true,
            viewparams: "layer_name:gpengtellin"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_gpengtellin.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_gpengtellin);

  layers.ggk_gpengoillin = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpengoillin",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpenglin_all",
            TILED: true,
            viewparams: "layer_name:gpengoillin"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_gpengoillin.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_gpengoillin);

  layers.ggk_gpenglivlin = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpenglivlin",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpenglin_all",
            TILED: true,
            viewparams: "layer_name:gpenglivlin"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_gpenglivlin.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_gpenglivlin);

  // Инженерные коммуникации полигональные - ggk.gpengpol
  layers.ggk_gpenggaspol = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpenggaspol",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpengpol_all",
            TILED: true,
            viewparams: "layer_name:gpenggaspol"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_gpenggaspol.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_gpenggaspol);

  layers.ggk_gpengwodpol = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpengwodpol",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpengpol_all",
            TILED: true,
            viewparams: "layer_name:gpengwodpol"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_gpengwodpol.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_gpengwodpol);

  layers.ggk_gpengpol = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpengpol",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpengpol_all",
            TILED: true,
            viewparams: "layer_name:gpengpol"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_gpengpol.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_gpengpol);

  layers.ggk_gpengelpol = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpengelpol",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpengpol_all",
            TILED: true,
            viewparams: "layer_name:gpengelpol"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_gpengelpol.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_gpengelpol);

  layers.ggk_gpengkanpol = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpengkanpol",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpengpol_all",
            TILED: true,
            viewparams: "layer_name:gpengkanpol"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_gpengkanpol.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_gpengkanpol);

  layers.ggk_gpengteppol = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpengteppol",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpengpol_all",
            TILED: true,
            viewparams: "layer_name:gpengteppol"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_gpengteppol.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_gpengteppol);

  layers.ggk_gpengtelpol = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpengtelpol",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpengpol_all",
            TILED: true,
            viewparams: "layer_name:gpengtelpol"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_gpengtelpol.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_gpengtelpol);

  layers.ggk_gpengoilpol = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpengoilpol",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpengpol_all",
            TILED: true,
            viewparams: "layer_name:gpengoilpol"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_gpengoilpol.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_gpengoilpol);

  layers.ggk_gpenglivpol = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpenglivpol",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpengpol_all",
            TILED: true,
            viewparams: "layer_name:gpenglivpol"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_gpenglivpol.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_gpenglivpol);



  // Границы градостроительного проекта
  // Границы населенного пункта - ggk.gpgrnp
  layers.ggk_gpgrnp = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpgrnp",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpgrnp_all",
            TILED: true,
            viewparams: "layer_name:gpgrnp"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_gpgrnp.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_gpgrnp);

  // Граница ПДП - ggk.gpgrpdp
  layers.ggk_gpgrpdp = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpgrpdp",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpgrpdp_all",
            TILED: true,
            viewparams: "layer_name:gpgrpdp"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_gpgrpdp.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_gpgrpdp);

  // Границы учетных кварталов - ggk.gpgrmkr
  layers.ggk_gpgrmkr = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpgrmkr",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpgrmkr_all",
            TILED: true,
            viewparams: "layer_name:gpgrmkr"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_gpgrmkr.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_gpgrmkr);

  //Градостроительные регламенты 
  // Желтые линии линейные - ggk.gpregline
  layers.ggk_gpregyellowline = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpregyellowline",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpregline_all",
            TILED: true,
            viewparams: "layer_name:gpregyellowline"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_gpregyellowline.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_gpregyellowline);

  // Красные линии полигональные - ggk.gpregredlinepol
  layers.ggk_gpregredlinepol = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpregredlinepol",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpregredlinepol_all",
            TILED: true,
            viewparams: "layer_name:gpregredlinepol"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_gpregredlinepol.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_gpregredlinepol);

  // Водоохранные полосы - ggk.gpregwodpls
  layers.ggk_gpregwodpls = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpregwodpls",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpregwodpls_all",
            TILED: true,
            viewparams: "layer_name:gpregwodpls"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_gpregwodpls.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_gpregwodpls);

  // Красные линии линейные - ggk.gpregline
  layers.ggk_gpregredlinelin = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpregredlinelin",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpregline_all",
            TILED: true,
            viewparams: "layer_name:pregredlinelin"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_gpregredlinelin.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_gpregredlinelin);

  // Автомобильный транспорт
  // Мосты - ggk.gpautotran
  layers.ggk_gpautotranbridg = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpautotranbridg",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpautotran_all",
            TILED: true,
            viewparams: "layer_name:gpautotranbridg"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_gpautotranbridg.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_gpautotranbridg);

  //Остановки  - ggk.gpautotran
  layers.ggk_gpautotranost = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpautotranost",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpautotran_all",
            TILED: true,
            viewparams: "layer_name:gpautotranost"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_gpautotranost.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_gpautotranost);

  // Парковки - ggk.gpautotran
  layers.ggk_gpautotranprc = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpautotranprc",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpautotran_all",
            TILED: true,
            viewparams: "layer_name:gpautotranprc"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_gpautotranprc.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_gpautotranprc);

  // Дороги и проезды - ggk.gpautotranrdc
  layers.ggk_gpautotranrdc = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpautotranrdc",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpautotranrdc_all",
            TILED: true,
            viewparams: "layer_name:gpautotranrdc"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_gpautotranrdc.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_gpautotranrdc);

  // Осевые линии дорог и улиц - ggk.gpautotranstreet
  layers.ggk_gpautotranstreet = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpautotranstreet",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpautotranstreet_all",
            TILED: true,
            viewparams: "layer_name:gpautotranstreet"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_gpautotranstreet.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_gpautotranstreet);

  // Железные дороги, пути и линейные сооружения - ggk.gprrstranlin
  layers.ggk_gprrstran = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gprrstran",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gprrstranlin_all",
            TILED: true,
            viewparams: "layer_name:gprrstran"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_gprrstran.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_gprrstran);

  // Благоустройство населенного пункта
  // Ограждения - ggk.gpblaglin
  layers.ggk_gpblagogr = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpblagogr",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpblaglin_all",
            TILED: true,
            viewparams: "layer_name:gpblagogr"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_gpblagogr.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_gpblagogr);

  // Детские игровые площадки - ggk.gpblagpol
  layers.ggk_gpblagchildpl = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpblagchildpl",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpblagpol_all",
            TILED: true,
            viewparams: "layer_name:gpblagchildpl"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_gpblagchildpl.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_gpblagchildpl);

  // Площадка для сушки белья - ggk.gpblagpol
  layers.ggk_gpblagdryerpl = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpblagdryerpl",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpblagpol_all",
            TILED: true,
            viewparams: "layer_name:gpblagdryerpl"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_gpblagdryerpl.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_gpblagdryerpl);

  // Мусорные контейнерные площадки - ggk.gpblagpol
  layers.ggk_gpblagdumppl = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpblagdumppl",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpblagpol_all",
            TILED: true,
            viewparams: "layer_name:gpblagdumppl"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_gpblagdumppl.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_gpblagdumppl);

  // Мусорные участки - ggk.gpblagpol
  layers.ggk_gpblagdumpuch = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpblagdumpuch",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpblagpol_all",
            TILED: true,
            viewparams: "layer_name:gpblagdumpuch"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_gpblagdumpuch.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_gpblagdumpuch);

  // Фонтаны - ggk.gpblagpol
  layers.ggk_gpblagfontpol = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpblagfontpol",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpblagpol_all",
            TILED: true,
            viewparams: "layer_name:gpblagfontpol"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_gpblagfontpol.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_gpblagfontpol);

  // Паркинг - ggk.gpblagpol
  layers.ggk_gpblagparking = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpblagparking",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpblagpol_all",
            TILED: true,
            viewparams: "layer_name:gpblagparking"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_gpblagparking.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_gpblagparking);

  // Спортивная площадка - ggk.gpblagpol
  layers.ggk_gpblagsportpl = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpblagsportpl",
            TILED: true
          },
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpblagpol_all",
            TILED: true,
            viewparams: "layer_name:gpblagsportpl"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_gpblagsportpl.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_gpblagsportpl);

  // Эстакадные сооружения - ggk.gpblagpol
  layers.ggk_gpblagstockad = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpblagstockad",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpblagpol_all",
            TILED: true,
            viewparams: "layer_name:gpblagstockad"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_gpblagstockad.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_gpblagstockad);

  // Тротуары, брусчатка, пешеходные дорожки - ggk.gpblagpol
  layers.ggk_gpblagtrotuar = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpblagtrotuar",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpblagpol_all",
            TILED: true,
            viewparams: "layer_name:gpblagtrotuar"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_gpblagtrotuar.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_gpblagtrotuar);

  // Городское озеленение - ggk.gpblagpol
  layers.ggk_gpblagzelen = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpblagzelen",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpblagpol_all",
            TILED: true,
            viewparams: "layer_name:gpblagzelen"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_gpblagzelen.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_gpblagzelen);

  // Рекламно-информационный объект (билборд) - ggk.gpblagpol
  layers.ggk_gpblagbillboard = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpblagbillboard",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpblagpol_all",
            TILED: true,
            viewparams: "layer_name:gpblagbillboard"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_gpblagbillboard.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_gpblagbillboard);

  // Линейные объекты, имеющие социально-культурную значимость (исторические стены, валы, тропы, дороги) - ggk.gpscsclin
  layers.ggk_gpscsclin = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpscsclin",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpscsclin_all",
            TILED: true,
            viewparams: "layer_name:gpscsclin"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_gpscsclin.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_gpscsclin);

  // Культурные, религиозные объекты (монументы, памятники, городища, места археологических раскопок, исторические площади и т.д.) - ggk.gpscscpol
  layers.ggk_gpscscpol = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpscscpol",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpscscpol_all",
            TILED: true,
            viewparams: "layer_name:gpscscpol"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_gpscscpol.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_gpscscpol);

  // Объекты гидрографии - ggk.gpgs
  layers.ggk_gpgshdrpol = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpgshdrpol",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpgs_all",
            TILED: true,
            viewparams: "layer_name:gpgshdrpol"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_gpgshdrpol.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_gpgshdrpol);

  // Гидротехнические объекты - ggk.gpgs
  layers.ggk_gpgshdtpol = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpgshdtpol",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }), 
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpgs_all",
            TILED: true,
            viewparams: "layer_name:gpgshdtpol"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_gpgshdtpol.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_gpgshdtpol);

  // Проект детальной планировки
  // Функциональные зоны
  // Жилые зоны - ggk.pdpzone
  layers.ggk_pdpzonejil = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpzonejil",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpzone_all",
            TILED: true,
            viewparams: "layer_name:pdpzonejil"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_pdpzonejil.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpzonejil);

  // Общественно-деловая зона - ggk.pdpzone
  layers.ggk_pdpzoneodz = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpzoneodz",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpzone_all",
            TILED: true,
            viewparams: "layer_name:pdpzoneodz"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_pdpzoneodz.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpzoneodz);

  // Территории производственной (промышленной) застройки - ggk.pdpzone
  layers.ggk_pdpzoneprom = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpzoneprom",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpzone_all",
            TILED: true,
            viewparams: "layer_name:pdpzoneprom"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_pdpzoneprom.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpzoneprom);

  // Территории транспортной инфраструктуры - ggk.pdpzone
  layers.ggk_pdpzonetransport = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpzonetransport",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpzone_all",
            TILED: true,
            viewparams: "layer_name:pdpzonetransport"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_pdpzonetransport.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpzonetransport);

  // Зона обеспеченности инженерными коммуникациями - ggk.pdpzone
  layers.ggk_pdpzoneeng = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpzoneeng",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpzone_all",
            TILED: true,
            viewparams: "layer_name:pdpzoneeng"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_pdpzoneeng.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpzoneeng);

  // Охраняемые территории - ggk.pdpzone
  layers.ggk_pdpzoneprotect = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpzoneprotect",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpzone_all",
            TILED: true,
            viewparams: "layer_name:pdpzoneprotect"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_pdpzoneprotect.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpzoneprotect);

  // Зоны сельскохозяйственного и лесохозяйственного - ggk.pdpzone
  layers.ggk_pdpzoneagricult = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpzoneagricult",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpzone_all",
            TILED: true,
            viewparams: "layer_name:pdpzoneagricult"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_pdpzoneagricult.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpzoneagricult);

  // Рекреационные зоны - ggk.pdpzone
  layers.ggk_pdpzonerec = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpzonerec",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpzone_all",
            TILED: true,
            viewparams: "layer_name:pdpzonerec"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
  })
  layers.ggk_pdpzonerec.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpzonerec);

  // Резервные территории - ggk.pdpzone
  layers.ggk_pdpzonerez = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpzonerez",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpzone_all",
            TILED: true,
            viewparams: "layer_name:pdpzonerez"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_pdpzonerez.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpzonerez);

  // Неудобные и неиспользуемые территории - ggk.pdpzone
  layers.ggk_pdpzonennt = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpzonennt",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpzone_all",
            TILED: true,
            viewparams: "layer_name:pdpzonennt"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_pdpzonennt.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpzonennt);

  // Зоны режимных территорий - ggk.pdpzone
  layers.ggk_pdpzonerestrict = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpzonerestrict",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpzone_all",
            TILED: true,
            viewparams: "layer_name:pdpzonerestrict"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_pdpzonerestrict.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpzonerestrict);

  // Зоны специального назначения - ggk.pdpzone
  layers.ggk_pdpzonespec = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpzonespec",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpzone_all",
            TILED: true,
            viewparams: "layer_name:pdpzonespec"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_pdpzonespec.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpzonespec);

  // Санитарно-защитные зоны - ggk.pdpzone
  layers.ggk_pdpzonesan = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpzonesan",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpzone_all",
            TILED: true,
            viewparams: "layer_name:pdpzonesan"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_pdpzonesan.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpzonesan);

  // Пригородная зона - ggk.pdpzone
  layers.ggk_pdpzonesub = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpzonesub",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpzone_all",
            TILED: true,
            viewparams: "layer_name:pdpzonesub"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_pdpzonesub.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpzonesub);

  // Земли рекультивации - ggk.pdpzone
  layers.ggk_pdpzonerecult = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpzonerecult",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpzone_all",
            TILED: true,
            viewparams: "layer_name:pdpzonerecult"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_pdpzonerecult.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpzonerecult);

  // Инженерные коммуникации линейные 
  // Объекты энергоснабжения линейные - ggk.pdpenglin
  layers.ggk_pdpengellin = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpengellin",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpenglin_all",
            TILED: true,
            viewparams: "layer_name:pdpengellin"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_pdpengellin.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpengellin);

  // Объекты газоснабжения линейные - ggk.pdpenglin
  layers.ggk_pdpenggaslin = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpenggaslin",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpenglin_all",
            TILED: true,
            viewparams: "layer_name:pdpenggaslin"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_pdpenggaslin.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpenggaslin);

  // Объекты водоснабжения линейные - ggk.pdpenglin
  layers.ggk_pdpengwodlin = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpengwodlin",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpenglin_all",
            TILED: true,
            viewparams: "layer_name:pdpengwodlin"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_pdpengwodlin.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpengwodlin);

  // Объекты водоотведения линейные - ggk.pdpenglin
  layers.ggk_pdpengkanlin = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpengkanlin",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpenglin_all",
            TILED: true,
            viewparams: "layer_name:pdpengkanlin"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
    
  })
  layers.ggk_pdpengkanlin.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpengkanlin);

  // Объекты теплоснабжения линейные - ggk.pdpenglin
  layers.ggk_pdpengteplin = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpengteplin",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpenglin_all",
            TILED: true,
            viewparams: "layer_name:pdpengteplin"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_pdpengteplin.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpengteplin);

  // Объекты связи и телекоммуникаций линейные - ggk.pdpenglin
  layers.ggk_pdpengtellin = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpengtellin",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpenglin_all",
            TILED: true,
            viewparams: "layer_name:pdpengtellin"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_pdpengtellin.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpengtellin);

  // Объекты нефтепроводов линейные - ggk.pdpenglin
  layers.ggk_pdpengoillin = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpengoillin",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpenglin_all",
            TILED: true,
            viewparams: "layer_name:pdpengoillin"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_pdpengoillin.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpengoillin);

  // Объекты ливневой канализации линейные - ggk.pdpenglin
  layers.ggk_pdpenglivlin = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpenglivlin",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpenglin_all",
            TILED: true,
            viewparams: "layer_name:pdpenglivlin"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_pdpenglivlin.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpenglivlin);
  
  // Объекты энергоснабжения полигональные - ggk.pdpengpol
  layers.ggk_pdpengelpol = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpengelpol",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpengpol_all",
            TILED: true,
            viewparams: "layer_name:pdpengelpol"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_pdpengelpol.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpengelpol);

  // Объекты газоснабжения полигональные - ggk.pdpengpol
  layers.ggk_pdpenggaspol = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpenggaspol",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpengpol_all",
            TILED: true,
            viewparams: "layer_name:pdpenggaspol"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_pdpenggaspol.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpenggaspol);

  // Объекты водоснабжения полигональные - ggk.pdpengpol
  layers.ggk_pdpengwodpol = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpengwodpol",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpengpol_all",
            TILED: true,
            viewparams: "layer_name:pdpengwodpol"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_pdpengwodpol.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpengwodpol);

  // Объекты водоотведения полигональные - ggk.pdpengpol
  layers.ggk_pdpengkanpol = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpengkanpol",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpengpol_all",
            TILED: true,
            viewparams: "layer_name:pdpengkanpol"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_pdpengkanpol.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpengkanpol);

  // Объекты теплоснабжения полигональные - ggk.pdpengpol
  layers.ggk_pdpengteppol = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpengteppol",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpengpol_all",
            TILED: true,
            viewparams: "layer_name:pdpengteppol"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_pdpengteppol.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpengteppol);

  // Объекты связи и телекоммуникаций полигональные - ggk.pdpengpol
  layers.ggk_pdpengtelpol = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpengtelpol",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpengpol_all",
            TILED: true,
            viewparams: "layer_name:pdpengtelpol"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_pdpengtelpol.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpengtelpol);

  // Объекты нефтепроводов полигональные - ggk.pdpengpol
  layers.ggk_pdpengoilpol = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpengoilpol",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpengpol_all",
            TILED: true,
            viewparams: "layer_name:pdpengoilpol"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_pdpengoilpol.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpengoilpol);

  // Объекты ливневой канализации полигональные - ggk.pdpengpol
  layers.ggk_pdpenglivpol = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpenglivpol",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpengpol_all",
            TILED: true,
            viewparams: "layer_name:pdpenglivpol"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_pdpenglivpol.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpenglivpol);

  // Границы градостроительного проекта
  // Границы населенного пункта - ggk.pdpgrnp
  layers.ggk_pdpgrnp = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpgrnp",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpgrnp_all",
            TILED: true,
            viewparams: "layer_name:pdpgrnp"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_pdpgrnp.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpgrnp);

  // Граница ПДП - ggk.pdpgrpdp
  layers.ggk_pdpgrpdp = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpgrpdp",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpgrpdp_all",
            TILED: true,
            viewparams: "layer_name:pdpgrpdp"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_pdpgrpdp.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpgrpdp);

  // Границы учетных кварталов - ggk.pdpgrmkr
  layers.ggk_pdpgrmkr = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpgrmkr",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpgrmkr_all",
            TILED: true,
            viewparams: "layer_name:pdpgrmkr"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_pdpgrmkr.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpgrmkr);

   // Градостроительные регламенты
  // Красные линии линейные - ggk.pdpregline
  layers.ggk_pdpregredlinelin = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpregredlinelin",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpregline_all",
            TILED: true,
            viewparams: "layer_name:pdpregredlinelin"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_pdpregredlinelin.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpregredlinelin);

  // Красные линии полигональные - ggk.pdpregredlinepol
  layers.ggk_pdpregredlinepol = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpregredlinepol",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpregredlinepol_all",
            TILED: true,
            viewparams: "layer_name:pdpregredlinepol"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_pdpregredlinepol.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpregredlinepol);

  // Желтые линии - ggk.pdpregline
  layers.ggk_pdpregyellowline = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpregyellowline",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpregline_all",
            TILED: true,
            viewparams: "layer_name:pdpregyellowline"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_pdpregyellowline.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpregyellowline);

  // Водоохранные полосы - ggk.pdpregwodpls
  layers.ggk_pdpregwodpls = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpregwodpls",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpregwodpls_all",
            TILED: true,
            viewparams: "layer_name:pdpregwodpls"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_pdpregwodpls.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpregwodpls);

  // Автомобильный транспорт
  // Мосты - ggk.pdpautotran
  layers.ggk_pdpautotranbridg = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpautotranbridg",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpautotran_all",
            TILED: true,
            viewparams: "layer_name:pdpautotranbridg"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_pdpautotranbridg.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpautotranbridg);

  // Остановки - ggk.pdpautotran
  layers.ggk_pdpautotranost = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpautotranost",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpautotran_all",
            TILED: true,
            viewparams: "layer_name:pdpautotranost"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_pdpautotranost.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpautotranost);

  // Парковки - ggk.pdpautotran
  layers.ggk_pdpautotranprc = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpautotranprc",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpautotran_all",
            TILED: true,
            viewparams: "layer_name:pdpautotranprc"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_pdpautotranprc.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpautotranprc);

  // Дороги и проезды - ggk.pdpautotranrdc
  layers.ggk_pdpautotranrdc = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpautotranrdc",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpautotranrdc_all",
            TILED: true,
            viewparams: "layer_name:pdpautotranrdc"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_pdpautotranrdc.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpautotranrdc);

  // Осевые линии дорог и улиц - ggk.pdpautotranstreet
  layers.ggk_pdpautotranstreet = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpautotranstreet",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpautotranstreet_all",
            TILED: true,
            viewparams: "layer_name:pdpautotranstreet"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_pdpautotranstreet.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpautotranstreet);

  // Здания и сооружения населенного пункта - ggk.pdpbuild
  layers.ggk_pdpbuild = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpbuild",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpbuild_all",
            TILED: true,
            viewparams: "layer_name:pdpbuild"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_pdpbuild.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpbuild);

  // Железные дороги, пути и линейные сооружения - ggk.pdprrstranlin
  layers.ggk_pdprrstranlin = new LayerGroup({
    layers: [ 
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdprrstranlin",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdprrstranlin_all",
            TILED: true,
            viewparams: "layer_name:pdprrstranlin"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
   
  })
  layers.ggk_pdprrstranlin.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdprrstranlin);

  // Благоустройство населенного пункта - ggk.pdpblagpol
  layers.ggk_pdpblag = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpblag",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpblagpol_all",
            TILED: true,
            viewparams: "layer_name:pdpblag"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_pdpblag.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpblag);

  // Рекламно-информационный объект (билборд) - ggk.pdpblagpol
  layers.ggk_pdpblagbillboard = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpblagbillboard",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpblagpol_all",
            TILED: true,
            viewparams: "layer_name:pdpblagbillboard"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_pdpblagbillboard.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpblagbillboard);

  // Детские игровые площадки - ggk.pdpblagpol
  layers.ggk_pdpblagchildpl = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpblagchildpl",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpblagpol_all",
            TILED: true,
            viewparams: "layer_name:pdpblagchildpl"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_pdpblagchildpl.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpblagchildpl);

  // Мусорные участки - ggk.pdpblagpol
  layers.ggk_pdpblagdumpuch = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpblagdumpuch",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpblagpol_all",
            TILED: true,
            viewparams: "layer_name:pdpblagdumpuch"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_pdpblagdumpuch.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpblagdumpuch);

  // Площадка для сушки белья - ggk.pdpblagpol
  layers.ggk_pdpblagdryerpl = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpblagdryerpl",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpblagpol_all",
            TILED: true,
            viewparams: "layer_name:pdpblagdryerpl"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_pdpblagdryerpl.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpblagdryerpl);

  // Мусорные контейнерные площадки - ggk.pdpblagpol
  layers.ggk_pdpblagdumppl = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpblagdumppl",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpblagpol_all",
            TILED: true,
            viewparams: "layer_name:pdpblagdumppl"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_pdpblagdumppl.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpblagdumppl);

  // Фонтаны - ggk.pdpblagpol
  layers.ggk_pdpblagfontpol = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpblagfontpol",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpblagpol_all",
            TILED: true,
            viewparams: "layer_name:pdpblagfontpol"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_pdpblagfontpol.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpblagfontpol);

  // Паркинг - ggk.pdpblagpol
  layers.ggk_pdpblagparking = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpblagparking",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpblagpol_all",
            TILED: true,
            viewparams: "layer_name:pdpblagparking"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_pdpblagparking.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpblagparking);

  // Спортивная площадка - ggk.pdpblagpol
  layers.ggk_pdpblagsportpl = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpblagsportpl",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpblagpol_all",
            TILED: true,
            viewparams: "layer_name:pdpblagsportpl"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_pdpblagsportpl.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpblagsportpl);

  // Эстакадные сооружения - ggk.pdpblagpol
  layers.ggk_pdpblagstockad = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpblagstockad",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpblagpol_all",
            TILED: true,
            viewparams: "layer_name:pdpblagstockad"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_pdpblagstockad.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpblagstockad);

  // Тротуары, брусчатка, пешеходные дорожки - ggk.pdpblagpol
  layers.ggk_pdpblagtrotuar = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpblagtrotuar",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpblagpol_all",
            TILED: true,
            viewparams: "layer_name:pdpblagtrotuar"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_pdpblagtrotuar.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpblagtrotuar);

  // Городское озеленение - ggk.pdpblagpol
  layers.ggk_pdpblagzelen = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpblagzelen",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpblagpol_all",
            TILED: true,
            viewparams: "layer_name:pdpblagzelen"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_pdpblagzelen.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpblagzelen);

  // Малые архитектурные формы - ggk.pdpblagpol
  layers.ggk_pdpblagmaf = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpblagmaf",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpblagpol_all",
            TILED: true,
            viewparams: "layer_name:pdpblagmaf"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_pdpblagmaf.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpblagmaf);

  // Ограждения - ggk.pdpblaglin
  layers.ggk_pdpblagogr = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpblagogr",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpblaglin_all",
            TILED: true,
            viewparams: "layer_name:pdpblagogr"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_pdpblagogr.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpblagogr);

  // Гидрография и гидротехнические сооружения
  // Гидрография и гидротехнические сооружения - ggk.pdpgs
  layers.ggk_pdpgs = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpgs",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpgs_all",
            TILED: true,
            viewparams: "layer_name:pdpgs"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
   
  })
  layers.ggk_pdpgs.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpgs);

  // Объекты гидрографии - ggk.pdpgs
  layers.ggk_pdpgshdrpol = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpgshdrpol",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpgs_all",
            TILED: true,
            viewparams: "layer_name:pdpgshdrpol"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_pdpgshdrpol.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpgshdrpol);

  // Гидротехнические объекты - ggk.pdpgs
  layers.ggk_pdpgshdtpol = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpgshdtpol",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpgs_all",
            TILED: true,
            viewparams: "layer_name:pdpgshdtpol"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_pdpgshdtpol.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_pdpgshdtpol);

  // Границы инвентаризации инженерных сетей ГГК
  // Границы инвентаризации инженерных сетей ГГК - ggk.invsupplyzone
  /*
  layers.ggk_invsupplyzone = new TileLayer({
    source: new TileWMS({
      url: `${gsUrl}/egkn/wms`,
      params: {
        LAYERS: "egkn:ggk_invsupplyzone",
//        STYLES: "egkn:pch_label_style",
        TILED: true
      },
      serverType: "geoserver",
      transition: 0
    }),
    visible: false,
    type: LayerType.GGK
  });
  map.addLayer(layers.ggk_invsupplyzone);
  */

  // Водоснабжение - ggk.invsupplyzone
  layers.ggk_invsupplyzone_water = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_invsupplyzone_water",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_invsupplyzone_all",
            TILED: true,
            viewparams: "layer_name:invsupplyzone_water"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_invsupplyzone_water.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_invsupplyzone_water);

  // Газоснабжение - ggk.invsupplyzone
  layers.ggk_invsupplyzone_gas = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_invsupplyzone_gas",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_invsupplyzone_all",
            TILED: true,
            viewparams: "layer_name:invsupplyzone_gas"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_invsupplyzone_gas.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_invsupplyzone_gas);

  // Канализация - ggk.invsupplyzone
  layers.ggk_invsupplyzone_fecal = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_invsupplyzone_fecal",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_invsupplyzone_all",
            TILED: true,
            viewparams: "layer_name:invsupplyzone_fecal"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_invsupplyzone_fecal.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_invsupplyzone_fecal);

  // Уличное освещение - ggk.invsupplyzone
  layers.ggk_invsupplyzone_streetlight = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_invsupplyzone_streetlight",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_invsupplyzone_all",
            TILED: true,
            viewparams: "layer_name:invsupplyzone_streetlight"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_invsupplyzone_streetlight.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_invsupplyzone_streetlight);

  // Ливневая канализация - ggk.invsupplyzone
  layers.ggk_invsupplyzone_sewerage = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_invsupplyzone_sewerage",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_invsupplyzone_all",
            TILED: true,
            viewparams: "layer_name:invsupplyzone_sewerage"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_invsupplyzone_sewerage.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_invsupplyzone_sewerage);

  // Телефонизация - ggk.invsupplyzone
  layers.ggk_invsupplyzone_phone = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_invsupplyzone_phone",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_invsupplyzone_all",
            TILED: true,
            viewparams: "layer_name:invsupplyzone_phone"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_invsupplyzone_phone.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_invsupplyzone_phone);

  // Теплоснабжение - ggk.invsupplyzone
  layers.ggk_invsupplyzone_heat = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_invsupplyzone_heat",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_invsupplyzone_all",
            TILED: true,
            viewparams: "layer_name:invsupplyzone_heat"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_invsupplyzone_heat.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_invsupplyzone_heat);

  // Электроснабжение - ggk.invsupplyzone
  layers.ggk_invsupplyzone_electro = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_invsupplyzone_electro",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_invsupplyzone_all",
            TILED: true,
            viewparams: "layer_name:invsupplyzone_electro"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_invsupplyzone_electro.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_invsupplyzone_electro);

  // Объекты гидрографии полигональные - ggk.invgshdrpol
  layers.ggk_invgshdrpol = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_invgshdrpol",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_invgshdrpol_all",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      }),
    ],
    visible: false
    
  })
  layers.ggk_invgshdrpol.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_invgshdrpol);

  // Населенные пункты полигон - ggk.invadmnppol
  layers.ggk_invadmnppol = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_invadmnppol",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_invadmnppol_all",
            TILED: true,
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_invadmnppol.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_invadmnppol);

  // Граница Государства, областей, районов, сельских округов, населенных пунктов линейный - ggk.invadmlin
  layers.ggk_invadmlin = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_invadmlin",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_invadmlin_all",
            TILED: true,
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_invadmlin.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_invadmlin);

  // Красные линии - ggk.pdpregredlinepol
  layers.ggk_redlines = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_redlines",
    //        STYLES: "egkn:pch_label_style",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_pdpregredlinepol_all",
            TILED: true,
            viewparams: "layer_name:redlines"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  }) 
  layers.ggk_redlines.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_redlines);

  // Строения - ggk.invsitibuild
  layers.ggk_invsitibuild = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_invsitibuild",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }), 
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_invsitibuild_all",
            TILED: true,
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_invsitibuild.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_invsitibuild);

  // Железные дороги, пути - ggk.invrrspol
  layers.ggk_invrrspol = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_invrrspol",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_invrrspol_all",
            TILED: true,
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_invrrspol.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_invrrspol);

  // Функциональные зоны
  // Функциональные зоны - ggk.gpzone
  layers.ggk_func_zone = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_func_zone",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpzone_all",
            TILED: true,
            viewparams: "layer_name:func_zone"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_func_zone.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_func_zone);

  // Жилые зоны - ggk.gpzone
  layers.ggk_func_zone_jil = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_func_zone_jil",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpzone_all",
            TILED: true,
            viewparams: "layer_name:func_zone_jil"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_func_zone_jil.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_func_zone_jil);

  // Общественно-деловая зона - ggk.gpzone
  layers.ggk_func_zone_odz = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_func_zone_odz",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpzone_all",
            TILED: true,
            viewparams: "layer_name:func_zone_odz"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_func_zone_odz.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_func_zone_odz);

  // Территории производственной (промышленной) застройки - ggk.gpzone
  layers.ggk_func_zone_prom = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_func_zone_prom",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpzone_all",
            TILED: true,
            viewparams: "layer_name:func_zone_prom"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_func_zone_prom.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_func_zone_prom);

  // Территории транспортной инфраструктуры - ggk.gpzone
  layers.ggk_func_zone_transport = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_func_zone_transport",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpzone_all",
            TILED: true,
            viewparams: "layer_name:func_zone_transport"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_func_zone_transport.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_func_zone_transport);

  // Зона обеспеченности инженерными коммуникациями - ggk.gpzone
  layers.ggk_func_zone_eng = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_func_zone_eng",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpzone_all",
            TILED: true,
            viewparams: "layer_name:func_zone_eng"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_func_zone_eng.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_func_zone_eng);

  // Охраняемые территории - ggk.gpzone
  layers.ggk_func_zone_protect = new LayerGroup({
    layers: [ 
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_func_zone_protect",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpzone_all",
            TILED: true,
            viewparams: "layer_name:func_zone_protect"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_func_zone_protect.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_func_zone_protect);

  // Зоны сельскохозяйственного и лесохозяйственного назначения - ggk.gpzone
  layers.ggk_func_zone_agricult = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_func_zone_agricult",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpzone_all",
            TILED: true,
            viewparams: "layer_name:func_zone_agricult"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_func_zone_agricult.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_func_zone_agricult);

  // Рекреационные зоны - ggk.gpzone
  layers.ggk_func_zone_rec = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_func_zone_rec",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpzone_all",
            TILED: true,
            viewparams: "layer_name:func_zone_rec"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_func_zone_rec.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_func_zone_rec);

  // Резервные территории - ggk.gpzone
  layers.ggk_func_zone_rez = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_func_zone_rez",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpzone_all",
            TILED: true,
            viewparams: "layer_name:func_zone_rez"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_func_zone_rez.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_func_zone_rez);

  // Неудобные и неиспользуемые территории - ggk.gpzone
  layers.ggk_func_zone_nnt = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_func_zone_nnt",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpzone_all",
            TILED: true,
            viewparams: "layer_name:func_zone_nnt"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_func_zone_nnt.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_func_zone_nnt);

  // Зоны режимных территорий - ggk.gpzone
  layers.ggk_func_zone_restrict = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_func_zone_restrict",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpzone_all",
            TILED: true,
            viewparams: "layer_name:func_zone_restrict"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_func_zone_restrict.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_func_zone_restrict);

  // Зоны специального назначения - ggk.gpzone
  layers.ggk_func_zone_spec = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_func_zone_spec",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpzone_all",
            TILED: true,
            viewparams: "layer_name:func_zone_spec"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_func_zone_spec.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_func_zone_spec);

  // Санитарно-защитные зоны - ggk.gpzone
  layers.ggk_func_zone_san = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_func_zone_san",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpzone_all",
            TILED: true,
            viewparams: "layer_name:func_zone_san"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_func_zone_san.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_func_zone_san);

  // Пригородная зона - ggk.gpzone
  layers.ggk_func_zone_sub = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_func_zone_sub",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpzone_all",
            TILED: true,
            viewparams: "layer_name:func_zone_sub"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_func_zone_sub.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_func_zone_sub);

  // Земли рекультивации - ggk.gpzone
  layers.ggk_func_zone_recult = new LayerGroup({
    layers: [
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_func_zone_recult",
            TILED: true
          },
          serverType: "geoserver",
          transition: 0
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url: `${gsUrl}/egkn/wms`,
          params: {
            LAYERS: "egkn:ggk_gpzone_all",
            TILED: true,
            viewparams: "layer_name:func_zone_recult"
          },
          serverType: "geoserver",
          transition: 0,
          projection: "EPSG:4326",
        }),
      })
    ],
    visible: false
    
  })
  layers.ggk_func_zone_recult.set('type', LayerType.GGK);
  map.addLayer(layers.ggk_func_zone_recult);


  function getRegionStyle(feature) {
    return [
      new Style({
        stroke: new Stroke({
          color: [0, 150, 255, 1],
          width: 2,
        }),
        fill: new Fill({
          color: [255, 255, 255, 0.6],
        }),
        text: new Text({
          text: feature.get("name_ru").replace(" область", ""),
          font: "600 16px 'Open Sans', sans-serif;",
          textAlign: "center",
          fill: new Fill({
            color: [20, 20, 60, 1],
          }),
          stroke: new Stroke({
            color: [255, 255, 255, 1],
            width: 2,
          }),
          overflow: true,
        }),
        geometry: function (feature) {
          var retPoint;
          if (feature.getGeometry().getType() === "MultiPolygon") {
            retPoint = getMaxPoly(feature.getGeometry().getPolygons());
          } else if (feature.getGeometry().getType() === "Polygon") {
            retPoint = feature.getGeometry();
          }
          return retPoint;
        },
      }),
    ];
  }

  layers.districts = new VectorLayer({
    source: new Vector({
      format: new GeoJSON(),
      url: function (extent) {
        return `${gsUrl}/wfs?service=WFS&version=1.1.0&request=GetFeature&typename=egkn:districts&outputFormat=application/json&srsname=EPSG:3857
          &bbox=${extent.join(",")},EPSG:3857`;
      },
      strategy: bboxStrategy,
      transition: 1000,
    }),
    visible: false,
    style: getDistrictStyle,
  });
  map.addLayer(layers.districts);

  function getDistrictStyle(feature) {
    return [
      new Style({
        stroke: new Stroke({
          color: [0, 150, 255, 1],
          width: 2,
        }),
        fill: new Fill({
          color: [255, 255, 255, 0.6],
        }),
        text: new Text({
          text: feature.get("rn_name_ru"),
          font: "600 12px 'Open Sans', sans-serif;",
          textAlign: "center",
          fill: new Fill({
            color: [20, 20, 60, 1],
          }),
          stroke: new Stroke({
            color: [255, 255, 255, 1],
            width: 2,
          }),
          overflow: true,
        }),
      }),
    ];
  }

  const nedLabelSource = new Vector({
    wrapX: false,
    format: new GeoJSON(),
    projection: "EPSG:3857",
    strategy: (extent, resolution) => {
      if (nedLabelSource.resolution_ !== resolution) {
        nedLabelSource.loadedExtentsRtree_.clear();
      }
      return [extent];
    },
  });

  const landsLabelSource = new Vector({
    wrapX: false,
    format: new GeoJSON(),
    projection: "EPSG:3857",
    strategy: (extent, resolution) => {
      if (landsLabelSource.resolution_ !== resolution) {
        landsLabelSource.loadedExtentsRtree_.clear();
      }
      return [extent];
    },
  });

  const reqLabelSource = new Vector({
    wrapX: false,
    format: new GeoJSON(),
    projection: "EPSG:3857",
    strategy: (extent, resolution) => {
      if (reqLabelSource.resolution_ !== resolution) {
        reqLabelSource.loadedExtentsRtree_.clear();
      }
      return [extent];
    },
  });

  const freeLabelSource = new Vector({
    wrapX: false,
    format: new GeoJSON(),
    projection: "EPSG:3857",
    strategy: (extent, resolution) => {
      if (freeLabelSource.resolution_ !== resolution) {
        freeLabelSource.loadedExtentsRtree_.clear();
      }
      return [extent];
    },
  });

  const labelStyle = new Style({
    text: new Text({
      font: "12px Calibri,sans-serif",
      overflow: true,
      fill: new Fill({
        color: "#000",
      }),
      stroke: new Stroke({
        color: "#fff",
        width: 3,
      }),
    }),
  });

  layers.lands_label = new VectorLayer({
    source: landsLabelSource,
    style: feature => {
      labelStyle.getText().setText(feature.get("label"));
      return labelStyle;
    },
    visible: false,
  });
  map.addLayer(layers.lands_label);

  layers.buildings_label = new VectorLayer({
    source: nedLabelSource,
    style: feature => {
      labelStyle.getText().setText(feature.get("label"));
      return labelStyle;
    },
    visible: false,
  });
  map.addLayer(layers.buildings_label);

  layers.req_label = new VectorLayer({
    source: reqLabelSource,
    style: feature => {
      labelStyle.getText().setText(feature.get("label"));
      return labelStyle;
    },
    visible: false,
  });
  map.addLayer(layers.req_label);

  layers.free_label = new VectorLayer({
    source: freeLabelSource,
    style: feature => {
      labelStyle.getText().setText(feature.get("label"));
      return labelStyle;
    },
    visible: false,
  });
  map.addLayer(layers.free_label);

  layers.borders = new VectorLayer({
    source: new Vector(),
    style: borderStyle,
  });
  map.addLayer(layers.borders);

  layers.draw = new VectorLayer({
    source: new Vector(),
    style: feature => {
      let styles = [...editStyle];
	  if (map.getView().getZoom() > 14) {
      const extent = map.getView().calculateExtent(map.getSize());
      let polygons = feature.getGeometry().getType() === 'Polygon' ? [feature.getGeometry()] : feature.getGeometry().getPolygons();
      let pointNum = 1;
      for (let j = 0; j < polygons.length; j++) {
        let polygon = polygons[j];
        const visible = olExtent.intersects(extent, polygon.getExtent());
        let rings = polygon.getLinearRings();
        for (let k = 0; k < rings.length; k++) {
          let ring = rings[k];
          let coords = ring.getCoordinates();
          for (let i = 0; i < coords.length - 1; i++) {
            if (visible) {
              let coord = coords[i];
              styles.push(new Style({
                image: new Circle({
                  radius: 3,
                  fill: new Fill({
                    color: "orange",
                  }),
                }),
                text: new Text({
                  text: '' + pointNum,
                  font: "12px Calibri,sans-serif",
                  textAlign: 'left',
                  offsetX: 5
                }),
                geometry: new Point(coord)
              }));
            }
            pointNum++;
          }
        }
      }
	}
      return styles;
    }
  });
  map.addLayer(layers.draw);

  const segmentStyle = new Style({
    stroke: new Stroke({
      color: "#f00",
      width: 1,
    }),
    fill: new Fill({
      color: getPattern(),
    }),
  });

  const servitudeStyle = new Style({
    stroke: new Stroke({
      color: "#000",
      width: 1,
    }),
    fill: new Fill({
      color: getServitudePattern(),
    }),
  });

  const itemStyle = new Style({
    stroke: new Stroke({
      color: "#f00",
      width: 1,
    }),
    text: new Text({
      font: "12px Calibri,sans-serif",
      overflow: true,
      fill: new Fill({
        color: "#000",
      }),
      stroke: new Stroke({
        color: "#fff",
        width: 3,
      }),
    }),
  });

  layers.select = new VectorLayer({
    source: new Vector(),
    style: feature => {
      const type = feature.get("type");
      const strokeColor = feature.get("strokeColor") || "red";
      if ("segment" === type) {
        return segmentStyle;
      }
      if ("servitude" === type) {
        return servitudeStyle;
      }
      if ("item" === type) {
        itemStyle.getText().setText(feature.get("title"));
        const styles = [itemStyle];
        feature
          .getGeometry()
          .getCoordinates()[0]
          .slice(0, -1)
          .forEach((coords, index) => {
            styles.push(
              new Style({
                image: new Circle({
                  radius: 3,
                  fill: new Fill({
                    color: "orange",
                  }),
                }),
                text: new Text({
                  font: "12px Calibri,sans-serif",
                  overflow: true,
                  fill: new Fill({
                    color: "#000",
                  }),
                  stroke: new Stroke({
                    color: "#fff",
                    width: 3,
                  }),
                  offsetX: 10,
                  offsetY: 10,
                  text: (index + 1).toString(),
                }),
                geometry: new Point(coords),
              }),
            );
          });
        return styles;
      }
      if ("object" === type && !standalone) {
		    itemStyle.getText().setText(feature.get("title"));
        const styles = [new Style({
          stroke: new Stroke({
            color: strokeColor,
            width: 1,
          }),
          text: new Text({
            font: "12px Calibri,sans-serif",
            overflow: true,
            fill: new Fill({
              color: "#000",
            }),
            stroke: new Stroke({
              color: "#fff",
              width: 3,
            }),
          }),
        })];
        if (map.getView().getZoom() > 14) {
          const extent = map.getView().calculateExtent(map.getSize());
          let polygons = feature.getGeometry().getType() === 'Polygon' ? [feature.getGeometry()] : feature.getGeometry().getPolygons();
          let pointNum = 1;
          for (let j = 0; j < polygons.length; j++) {
            let polygon = polygons[j];
            const visible = olExtent.intersects(extent, polygon.getExtent());
            let rings = polygon.getLinearRings();
            for (let k = 0; k < rings.length; k++) {
              let ring = rings[k];
              let coords = ring.getCoordinates();
              for (let i = 0; i < coords.length - 1; i++) {
                if (visible) {
                  let coord = coords[i];
                  styles.push(new Style({
                    image: new Circle({
                      radius: 3,
                      fill: new Fill({
                        color: "orange",
                      }),
                    }),
                    text: new Text({
                      text: '' + pointNum,
                      font: "12px Calibri,sans-serif",
                      textAlign: 'left',
                      offsetX: 5
                    }),
                    geometry: new Point(coord)
                  }));
                }
                pointNum++;
              }
            }
          }
        }
        return styles;
      }
      return selectStyle;
    },
  });
  map.addLayer(layers.select);

  overviewMap = new OverviewMap({
    target: "1",
    layers: [
      ...map
        .getLayers()
        .getArray()
        .filter(
          layer =>
            layer !== layers.lands_label &&
            layer !== layers.buildings_label &&
            layer !== layers.req_label &&
            layer !== layers.free_label,
        ),
    ],
  });
  map.addControl(overviewMap);

  return layers;
}

function updateLayers(layers, district) {
  const regionCode = district.regionCode;
  const code = district.code;
  const srs = district.srs;
  const regionName = district.nameRu;
 
  districtLayers.setDistrictId(district.id);

  if (district.kato) {
    ggkGsLayers.setEnabled(true);
    ggkGsLayers.setProjection(`EPSG:${srs}`);
  } else {
    ggkGsLayers.setEnabled(false);
  }

  for (let layerName in layers) {
    const layer = layers[layerName];
    if (layer.get('type') === LayerType.EGKN) {
      let params = layer.getSource().getParams();

      //TODO Оптимизировать.
      /*if (layerName === 'ugl') {
        params.LAYERS = `egkn:ugl_${regionCode}_${code},egkn:ugpol_${regionCode}_${code}`;

      } else*/
      if (layerName === 'buildings') {
        if (regionCode === "22") {
          params.LAYERS = `egkn:ned_19_309`;
        } else {
          params.LAYERS = `egkn:ned_${regionCode}_${code}`;
        }
      } else {
        params.LAYERS = `${layer.get('prefix')}_${regionCode}_${code}`;
      }

      params.projection = `EPSG:${srs}`;
      layer.getSource().updateParams(params);
    } else if (layer.get('type') === LayerType.GGK) {
        //let params = layer.getSource().getParams();
        let params = layer.getLayers().item(0).getSource().getParams();
        params.projection = `EPSG:${srs}`;
        params.viewparams = `district_id:${district.id}`;
        layer.getLayers().item(0).getSource().updateParams(params);
    } else if (layerName === 'uk' || layerName === 'free' || layerName === 'req' || layerName === 'lands' || layerName === 'lands_archive' || layerName === 'part' || layerName === 'part_archive' || layerName === 'ul' || layerName === 'ul_archive' || layerName === 'pdp' || layerName === 'arrest'
      || layerName === "rst" || layerName === "redlines" || layerName === "func" || layerName === "buf" || layerName === "pch" || layerName === "ugl"
      || layerName === "err_u" || layerName === "err_ohz" || layerName === "err_voz" || layerName === "err_uk" || layerName === 'buildings'
      || layerName === "gp" || layerName === "eval" || layerName === "ohz" || layerName === "voz" || layerName === "vop" || layerName === "sogl" || layerName === "sogl_agreed" || layerName === "sogl_rejected" || layerName === "sogl_registered") {
      let params = layer.getSource().getParams();
      if (layerName === "ugl") {
        params.LAYERS = `egkn:ugl_view,egkn:ugpol_view`;
      }
      params.projection = `EPSG:${srs}`;
      params.viewparams = `district_id:${district.id}`

      layer.getSource().updateParams(params);
    } else if (layerName === "water" || layerName === "gas" || layerName === "storm_water" || layerName === "heat" || layerName === "con" || layerName === "electr" || layerName === "sewer") {
      const layerEng = layers[layerName];
      let params = layer.getSource().getParams();
      params.projection = `EPSG:${srs}`;
      params.viewparams = `district_id:${district.id};type_id:${layerEng.get('typeId')}`;
      layer.getSource().updateParams(params);
    }
  }

  let layer = layers.agro;
  let params = layer.getSource().getParams();
  params.LAYERS = `show:${getRegionID(regionName, regionCode)}`;
  params.projection = `EPSG:${srs}`;
  layer.getSource().updateParams(params);

  layer = layers.deforested;
  params = layer.getSource().getParams();
  params.regionCode = regionCode;
  layer.getSource().updateParams(params);

  layer = layers.forests_DZZ;
  params = layer.getSource().getParams();
  params.regionCode = regionCode;
  layer.getSource().updateParams(params);

  layer = layers.tsel;
  params = layer.getSource().getParams();
  params.LAYERS = `show:${tsel[code]}`;
  params.projection = `EPSG:${srs}`;
  layer.getSource().updateParams(params);

  layer = layers.fire_dept;
  params = layer.getSource().getParams();
  params.LAYERS = `show:${getFireDepts(regionCode)}`;
  params.projection = `EPSG:${srs}`;
  layer.getSource().updateParams(params);

  layer = layers.season_dyn;
  params = layer.getSource().getParams();
  params.LAYERS = `show:${getSeasonDyn(regionCode)}`;
  params.projection = `EPSG:${srs}`;
  layer.getSource().updateParams(params);

  const landsLabelSource = layers.lands_label.getSource();
  landsLabelSource.setLoader((extent, resolution, projection) => {
    getLayerLabels("lands", extent, resolution).then(json => {
      landsLabelSource.resolution_ = resolution;
      const wkt = new WKT();
      landsLabelSource.getFeatures().forEach(feature => {
        landsLabelSource.removeFeature(feature);
      });
      if (json.lands) {
        landsLabelSource.addFeatures(
          json.lands.map(e => {
            return new Feature({
              label: e.label,
              geometry: wkt.readGeometry(e.geometry),
            });
          }),
        );
      }
    });
  });
  landsLabelSource.clear();

  const nedLabelSource = layers.buildings_label.getSource();
  nedLabelSource.setLoader((extent, resolution, projection) => {
    getLayerLabels("buildings", extent, resolution).then(json => {
      nedLabelSource.resolution_ = resolution;
      const wkt = new WKT();
      nedLabelSource.getFeatures().forEach(feature => {
        nedLabelSource.removeFeature(feature);
      });
      if (json.buildings) {
        nedLabelSource.addFeatures(
          json.buildings.map(e => {
            return new Feature({
              label: e.label,
              geometry: wkt.readGeometry(e.geometry),
            });
          }),
        );
      }
    });
  });
  nedLabelSource.clear();

  const reqLabelSource = layers.req_label.getSource();
  reqLabelSource.setLoader((extent, resolution, projection) => {
    getLayerLabels("req", extent, resolution).then(json => {
      reqLabelSource.resolution_ = resolution;
      const wkt = new WKT();
      reqLabelSource.getFeatures().forEach(feature => {
        reqLabelSource.removeFeature(feature);
      });
      if (json.req) {
        reqLabelSource.addFeatures(
          json.req.map(e => {
            return new Feature({
              label: e.label,
              geometry: wkt.readGeometry(e.geometry),
            });
          }),
        );
      }
    });
  });
  reqLabelSource.clear();

  const freeLabelSource = layers.free_label.getSource();
  freeLabelSource.setLoader((extent, resolution, projection) => {
    getLayerLabels("free", extent, resolution).then(json => {
      freeLabelSource.resolution_ = resolution;
      const wkt = new WKT();
      freeLabelSource.getFeatures().forEach(feature => {
        freeLabelSource.removeFeature(feature);
      });
      if (json.free) {
        freeLabelSource.addFeatures(
          json.free.map(e => {
            return new Feature({
              label: e.label,
              geometry: wkt.readGeometry(e.geometry),
            });
          }),
        );
      }
    });
  });
  freeLabelSource.clear();
}

function login(userInfo) {
  return new Promise((resolve, reject) => {
    fetch(`${window.REST_URL}/cabinet/login`, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userInfo),
    })
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(e => reject(e));
  });
}

function logout() {
  return new Promise((resolve, reject) => {
    fetch(`${window.REST_URL}/cabinet/logout`, {
      method: "GET",
      mode: "cors",
      credentials: "include",
    })
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(e => reject(e));
  });
}

function updateQueue(lang) {
  return getMyQueue(lang);
}

function getService(id) {
  let url = `${window.REST_URL}/cabinet/service`;
  if (id) {
    url = `${window.REST_URL}/cabinet/service/${id}`
  }
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "GET",
      mode: "cors",
      credentials: "include"
    })
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(e => reject(e))
  });
}

function getMyObjs(type) {
  return new Promise((resolve, reject) => {
    fetch(`${window.REST_URL}/cabinet/${type}`, {
      method: "GET",
      mode: "cors",
      credentials: "include"
    })
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(e => reject(e))
  });
}

function getMyObjsByLandId(land) {
  return new Promise((resolve, reject) => {
    fetch(`${window.REST_URL}/cabinet/appobject/${land}`, {
      method: "GET",
      mode: "cors",
      credentials: "include"
    })
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(e => reject(e))
  });
}

function getProviders(district) {
  return new Promise((resolve, reject) => {
    fetch(`${window.REST_URL}/cabinet/zkr/providers?district=${district}`, {
      method: "GET",
      mode: "cors",
      credentials: "include"
    })
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(e => reject(e))
  });
}

// function postOrder(order) {
//   if (order.id) {
//     return sendOrder(order.id);
//   } else {
//     return new Promise((resolve, reject) => {
//       fetch(`${window.REST_URL}/cabinet/order`, {
//         method: "POST",
//         mode: "cors",
//         credentials: "include",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(order),
//       })
//         .then(response => response.json())
//         .then(json => sendOrder(json['id']))
//         .then(order => resolve(order))
//         .catch(e => reject(e))
//     });
//   }
// }

function postOrder(order, setNotification) {
    const formData = new FormData();
    formData.append('phone', order.phone);
    if (order.providers) {
      formData.append('providers', JSON.stringify(order.providers));
    }
    if (order.iinBin) {
      formData.append('iinBin', order.iinBin);
    }
    if (order.services) {
      formData.append('services', JSON.stringify(order.services));
    }
    if (order.objects) {
      formData.append('objects', JSON.stringify(order.objects));
    }

    order.docs.map((item,i) => {
      if (item.blob) {
        // formData.append(item.typeCode + '-' + i, new Blob([item.blob]), item.fileName);
        formData.append(item.typeCode + '-' + i, item.blob, item.fileName);
      } else {
        formData.append(item.typeCode + '-' + i, item.fileName);
      }
    })

  if (order.id) {
    formData.append('orderId', order.id);
    return new Promise((resolve, reject) => {
      fetch(`${window.REST_URL}/cabinet/order/update/${order.id}`, {
        method: "POST",
        mode: "cors",
        credentials: "include",
        body: formData
      })
        .then(response => response.json())
        .then(json => sendOrder(json['id']))
        .then(order => resolve(order))
        .catch(e => {
          if (e=='Error: Head Error') {
            setNotification({
              title: i18n.t("notif.error"),
              text: i18n.t("notif.head-error-2"),
            })
          }
          reject(e);
        })
    });
  } else {
    return new Promise((resolve, reject) => {
      fetch(`${window.REST_URL}/cabinet/order`, {
        method: "POST",
        mode: "cors",
        credentials: "include",
        // headers: {
        //   "Content-Type": "application/json",
        // },
        // body: JSON.stringify(order),
        body: formData
      })
        .then(response => response.json())
        .then(json => {
          order.id=json['id'];
          return sendOrder(json['id'])
        })
        .then(order => resolve(order))
        .catch(e =>{
          if (e=='Error: Head Error') {
            setNotification({
              title: i18n.t("notif.error"),
              text: i18n.t("notif.head-error-2"),
            })
          }
          reject(e);
        })
    });
  }
}



export function postDoc(doc, docType) {
  const formData = new FormData();
  formData.append(doc.name, new Blob([doc]), doc.name.replaceAll('+', ' '));
  return new Promise((resolve, reject) => {
    axios({
      url: `${window.REST_URL}/upload?docType=${docType}`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      method: "post",
      data: formData
    })
      .then(response => resolve(response.data))
      .catch(e => reject(e))
  });
}

function getDocs() {
  return new Promise((resolve, reject) => {
    fetch(`${window.REST_URL}/cabinet/docs`, {
      method: "GET",
      mode: "cors",
      credentials: "include"
    })
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(e => reject(e))
  });
}

function getDocTypes(serviceId) {
  return new Promise((resolve, reject) => {
    fetch(`${window.REST_URL}/cabinet/typesDoc?serviceCode=${serviceId}`, {
      method: "GET",
      mode: "cors",
      credentials: "include"
    })
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(e => reject(e))
    });
}

function getOrders(pageNum, pageSize) {
  return new Promise((resolve, reject) => {
    fetch(`${window.REST_URL}/cabinet/order?pageNum=${pageNum}&pageSize=${pageSize}`, {
      method: "GET",
      mode: "cors",
      credentials: "include"
    })
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(e => reject(e))
  });
}

function getCoords(obj, type) {
  obj.type=type;
  return new Promise((resolve, reject) => {
    fetch(`${window.REST_URL}/cabinet/object/coords`, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then(response => {
        resolve(response.text())
      })
      .catch(e => reject(e))
  });
}

function getNotifs(pageNum, pageSize) {
  return new Promise((resolve, reject) => {
    fetch(`${window.REST_URL}/cabinet/notif?pageNum=${pageNum}&pageSize=${pageSize}`, {
      method: "GET",
      mode: "cors",
      credentials: "include"
    })
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(e => reject(e))
  });
}

function updateNotif(timeCreated) {
  return new Promise((resolve, reject) => {
    fetch(`${window.REST_URL}/cabinet/notif`, {
      method: "PUT",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ timeCreated: timeCreated }),
    })
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(e => reject(e))
  });
}

function getDicts() {
  return new Promise((resolve, reject) => {
    fetch(`${window.REST_URL}/cabinet/dicts`, {
      method: "GET",
      mode: "cors",
      credentials: "include",
    })
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(e => reject(e))
  });
}

function getOrder(id) {
  return new Promise((resolve, reject) => {
    fetch(`${window.REST_URL}/cabinet/order/` + id, {
      method: "GET",
      mode: "cors",
      credentials: "include"
    })
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(e => reject(e))
  });
}

function getProfileObjects() {
  return new Promise((resolve, reject) => {
    fetch(`${window.REST_URL}/cabinet/object`, {
      method: "GET",
      mode: "cors",
      credentials: "include"
    })
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(e => reject(e))
  });
}

function restSetDistrict(district, standalone, layers) {
  return new Promise((resolve, reject) => {
    fetch(`${window.REST_URL}/map/district?code=${district.code}`, {
      method: "PUT",
      mode: "cors",
      credentials: "include",
    })
      .then(response => response.json())
      .then(json => {
        resolve(json);
      })
      .catch(e => reject(e));
  });
}

function getMyQueue(lang) {
  return new Promise((resolve, reject) => {
    fetch(`${window.REST_URL}/cabinet/myqueue?lang=${lang}`, {
      method: "GET",
      mode: "cors",
      credentials: "include"
    })
      .then(response => response.json())
      .then(json => {
        resolve(json);
      })
      .catch(e => reject(e));
  });
}

function get(event, region, layers, layersVisibility) {

  let promises = [];
  let lang = localStorage.getItem('i18nextLng')
  const [x, y] = transform(
    event.coordinate,
    event.map.getView().getProjection(),
    "EPSG:" + region.srs,
  );
  // распределяем слои
  const arcgisLayers = [];
  const geoserverLayers = [];

  Object.entries(layers).forEach(([layerName, layerObject]) => {

    if (layerObject.get("arcgis")) {
      arcgisLayers.push(layerName);
    } else if (layerName === 'pdp') {
      geoserverLayers.push(layerName);
    } else if (layerName.startsWith('ggk_gpzone')) {
      geoserverLayers.push(layerName);
    } else if (layerName.startsWith('ggk_pdpzone')) {
      geoserverLayers.push(layerName);
    } else if (layerObject.getVisible() && layerObject instanceof LayerGroup && layerObject.getLayers().item(0)) {
      const source = layerObject.getLayers().item(0).getSource();
      if (source instanceof TileWMS && layerName !== 'base' && layerName !== 'kazgeo') {
        const coords = transform(
          event.coordinate,
          event.map.getView().getProjection(),
          "EPSG:" + region.srs,
        );
        const url = source.getFeatureInfoUrl(coords, event.map.getView().getResolution(), "EPSG:" + region.srs, { 'INFO_FORMAT': 'application/json' });
        if (url) {
          promises.push(new Promise((resolve, reject) => {
            fetch(url, {
              method: "GET",
            })
              .then(response => response.json())
              .then(json => {
                const res = {};
                res[layerName] = json.features;
                resolve(res);
              })
              .catch(e => reject(e));
          }));
        }
      }
    } else {
      geoserverLayers.push(layerName);
    }
  });

  for (const layerName of districtLayers.getLayerNames()) {
    geoserverLayers.push(layerName);
  }

  if (ggkGsLayers.wmsLayer.getVisible()) {
    const view = event.map.getView();
    const url = ggkGsLayers.wmsLayer.getSource().getFeatureInfoUrl(event.coordinate, view.getResolution(), view.getProjection(), { INFO_FORMAT: 'application/json', FEATURE_COUNT: 100 });
    if (url) {
      promises.push(new Promise((resolve, reject) => {
        fetch(url, {
          method: "GET",
        })
          .then(response => response.json())
          .then(json => {
            const res = {};
            json.features.forEach(feature => {
              const layerName = 'ggkgs_' + feature.id.substring(0, feature.id.indexOf('.'));
              if (!(layerName in res)) {
                res[layerName] = [];
              }
              res[layerName].push(feature);
            });
            resolve(res);
          })
          .catch(e => reject(e));
      }));
    }
  }

  const visibleGsLayers = geoserverLayers.filter(layer =>
    !!layersVisibility[layer]
  );

  if (visibleGsLayers.length) {
    const internalPromise = new Promise((resolve, reject) => {
      const queryURL = `${window.REST_URL}/map/get?x=${x}&y=${y}&layers=${visibleGsLayers}&lang=${lang}`;
      fetch(queryURL, {
        method: "GET",
        mode: "cors",
        credentials: "include",
      })
        .then(response => response.json())
        .then(json => {
          resolve(json);
        })
        .catch(e => reject(e));
    });
    promises.push(internalPromise);
  }

  const visibleArcLayers = arcgisLayers.filter(layer =>
    !!layersVisibility[layer],
  );
  if (visibleArcLayers.length) {
    visibleArcLayers.forEach(layer => {
      const layerObj = layers[layer];
      const restPath = layerObj.get("restPath");
      const layerName = layerObj.get("name");
      const sublayer = layerObj
        .getSource()
        .getParams()
        .LAYERS.match(/\d+/)[0];
      const queryURL = `${window.COSMO_URL}/${restPath}/MapServer/${sublayer}/query?where=&text=&objectIds=&time=&geometry=%7B%22x%22+%3A+${x}%2C+%22y%22+%3A+${y}%2C+%22spatialReference%22+%3A+%7B%22wkid%22+%3A+${region.srs}%7D%7D&geometryType=esriGeometryPoint&inSR=&spatialRel=esriSpatialRelIntersects&relationParam=&outFields=*&returnGeometry=true&returnTrueCurves=false&maxAllowableOffset=&geometryPrecision=&outSR=&having=&returnIdsOnly=false&returnCountOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&gdbVersion=&historicMoment=&returnDistinctValues=false&resultOffset=&resultRecordCount=&queryByDistance=&returnExtentOnly=false&datumTransformation=&parameterValues=&rangeValues=&quantizationParameters=&f=pjson`;
      const newPromise = new Promise((resolve, reject) => {
        fetch(queryURL, {
          method: "GET",
          mode: "cors",
          credentials: "include",
        })
          .then(response => response.json())
          .then(json => {
            json.external = true;
            json.layerName = layerName;
            resolve(json);
          })
          .catch(error => reject(error));
      });
      promises.push(newPromise);
    });
  }

  return Promise.all(promises);
}

function getDistrictByRnCode(rnCode) {
  return new Promise((resolve, reject) => {
    fetch(`${window.REST_URL}/map/district?rnCode=${rnCode}`, {
      method: "GET",
      mode: "cors",
      credentials: "include",
    })
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(e => reject(e));
  });
}


//check choice region
function search(text, offset, limit) {

  let lang = localStorage.getItem('i18nextLng')
  return new Promise((resolve, reject) => {
    fetch(`${window.REST_URL}/map/search?searchText=${text}&offset=${offset}&limit=${limit}&lang=${lang}`, {
      method: "GET",
      mode: "cors",
      credentials: "include",
    })
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(e => reject(e));
  });
}

function searchByCadNum(cadNum, offset, limit, obj) {

  let lang = localStorage.getItem('i18nextLng')
  return new Promise((resolve, reject) => {
    fetch(`${window.REST_URL}/map/searchByCadNum?searchText=${cadNum}&offset=${offset}&limit=${limit}&lang=${lang}&gid=${obj.id}`, {
      method: "GET",
      mode: "cors",
      credentials: "include",
    })
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(e => reject(e));
  });
}

function getLayerObjects(layerName, pageNum, pageSize) {
  let lang = localStorage.getItem('i18nextLng')
  return new Promise((resolve, reject) => {
    fetch(
      `${window.REST_URL}/map/layer?name=${layerName}&pageNum=${pageNum}&pageSize=${pageSize}&lang=${lang}`,
      {
        method: "GET",
        mode: "cors",
        credentials: "include",
      },
    )
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(e => reject(e));
  });
}

function getLayerLabels(layerName, extent, resolution) {
  return new Promise((resolve, reject) => {
    fetch(
      `${window.REST_URL}/map/labels?name=${layerName}&extent=${extent.join(
        ",",
      )}&res=${resolution}&srs=1`,
      {
        method: "GET",
        mode: "cors",
        credentials: "include",
      },
    )
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(e => reject(e));
  });
}

function wfsGetFeatures(typeName, extent, srs, limit, viewparams) {

  return new Promise((resolve, reject) => {
    var url = `${window.GS_URL
      }/wfs?service=WFS&version=2.0.0&request=GetFeature&typename=${typeName}&outputFormat=application/json&srsname=EPSG:${srs}&bbox=${extent.join(
        ",",
      )},EPSG:${srs}&propertyname=geom&count=${limit}&viewparams=${viewparams}`;
    fetch(url, {
      method: "GET",
      mode: "cors",
      credentials: "include",
    })
      .then(response => {
        if (
          response.ok &&
          response.headers.get("Content-Type").startsWith("application/json")
        ) {
          response
            .json()
            .then(json => {
              var features = new GeoJSON().readFeatures(json);
              var res = { "features": features, "total": json.totalFeatures }
              resolve(res);
            });
        } else {
          resolve([]);
        }
      })
      .catch(e => reject(e));
  });
}

function send(id, geom, status) {
  return new Promise((resolve, reject) => {
    fetch(
      //      `${window.REST_URL}/map/send?id=${id}&geom=${geom}&status=${status}`,
      `${window.REST_URL}/cabinet/auction/create?geom=${geom}`,
      {
        method: "POST",
        mode: "cors",
        credentials: "include",
      },
    )
      .then(result => resolve(result))
      .catch(e => reject(e));
  });
}

function cancelEgovRequest() {
  return new Promise((resolve, reject) => {
    fetch(
      `${window.REST_URL}/cabinet/req/cancel`,
      {
        method: "POST",
        mode: "cors",
        credentials: "include",
      },
    )
      .then(response => response.json())
      .then(result => resolve(result))
      .catch(e => reject(e));
  });
}

function sendActionSelect(landId) {
  return new Promise((resolve, reject) => {
    fetch(
      `${window.REST_URL}/cabinet/auction/select?landId=${landId}`,
      {
        method: "POST",
        mode: "cors",
        credentials: "include",
      },
    )
      .then(response => response.ok
        ? response.json().then(json => resolve(json))
        : response.text().then(message => reject(message)))
      .catch(e => reject(e));
  });
}

function createBuffer(layer, gid, radius) {
  return new Promise((resolve, reject) => {
    fetch(
      `${window.REST_URL}/map/buffer?layer=${layer}&gid=${gid}&radius=${radius}`,
      {
        method: "GET",
        mode: "cors",
        credentials: "include",
      },
    )
      .then(() => resolve())
      .catch(e => reject(e));
  });
}

function deleteBuffer(gid) {
  return new Promise((resolve, reject) => {
    fetch(`${window.REST_URL}/map/buffer?gid=${gid}`, {
      method: "DELETE",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(() => resolve())
      .catch(e => reject(e));
  });
}

function getMeasures(geom, srs) {
  return extGetMeasures(new WKT().writeGeometryText(geom), srs);
}

function extGetMeasures(geomText, srs) {
  return new Promise((resolve, reject) => {
		let params = {
			geom: geomText,
			srs: srs
		};

    fetch(`${window.REST_URL}/map/measures`, {
      method: "POST",
      mode: "cors",
      credentials: "include",
			body: JSON.stringify(params)
    })
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(e => reject(e));
  });
}

function extGetQuality(geomText, rnCode) {
  return new Promise((resolve, reject) => {
    fetch(`${window.REST_URL}/map/quality?geom=${geomText}${rnCode ? "&rnCode=" + rnCode : ""}`, {
      method: "GET",
      mode: "cors",
      credentials: "include",
    })
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(e => reject(e));
  });
}

function getNeighbours(geomText, scad, rnCode) {
  return new Promise((resolve, reject) => {
		let params = {
			geom: geomText,
			scad: scad,
			rnCode: rnCode ? rnCode : ""
		};
    fetch(`${window.REST_URL}/map/neighbours`, {
      method: "POST",
      mode: "cors",
      credentials: "include",
			body: JSON.stringify(params)
    })
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(e => reject(e));
  });
}

function extGetIntersections(geomText, layers, srcCadNum, rnCode) {
  return new Promise((resolve, reject) => {
		let params = {
			geom: geomText,
			layers: layers,
			scad: srcCadNum ? srcCadNum : "",
			rnCode: rnCode || ""
		};

    fetch(
      `${window.REST_URL}/map/intersections`,
      {
        method: "POST",
        mode: "cors",
        credentials: "include",
				body: JSON.stringify(params)
      },
    )
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(e => reject(e));
  });
}

const loadDim = async (dim, fact, regionCode) => {
  let nodes;
  await fetch(`${window.OLAP_URL}/dim/dimension/${dim.id}?fact=${fact}&region_code=${regionCode}`, {
    method: "GET",
    mode: "cors"
  })
    .then(response => response.json())
    .then(json => {
      nodes = json.map(e => {
        return {
          "id": e.id,
          "text": e.name,
          "name": e.name,
          "isLeaf": e.isLeaf,
          "color": e.color,
          "state": e.isLeaf ? "" : "closed",
          "checkState": "checked",
          "position": e.position
        };
      });
    });
  return nodes;
}

//lang add, i18n?
async function extGetStatistcs(idx, idy, fact, agg, agg_value, nodesNamesX, nodesNamesY, regionCode) {
  if (!nodesNamesX)
    nodesNamesX = await loadDim(idx, fact, regionCode);
  if (!nodesNamesY)
    nodesNamesY = await loadDim(idy, fact, '00');
  const q = { "dim1": { "id": idx.id, "value": [] }, "dim2": { "id": idy.id, "value": [] }, "fact": fact, "agg": agg, "value": agg_value };
  for (var i in nodesNamesX) {
    q["dim1"].value.push(nodesNamesX[i].id);
  }
  for (i in nodesNamesY) {
    q["dim2"].value.push(nodesNamesY[i].id);
  }

  return new Promise((resolve, reject) => {
    fetch(`${window.OLAP_URL}/ds/data`, {
      method: 'POST',
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(q),
    })
      .then(response => response.json())
      .then(json => {
        let data = JSON.parse(JSON.stringify(json));
        let items = nodesNamesX.map((e, i) => {
          data[i].position = e.position;
          e.data = data[i];
          return e;
        });
        const res = {
          items: items,
          chartData: json,
          legend: nodesNamesY,
          nodesNamesX: nodesNamesX,
          parentXId: idx,
          parentYId: idy,
          fact: fact,
          aggType: agg,
          aggField: agg_value
        };
        return resolve(res);
      })
      .catch(e => reject(e));
  });

}

function getQueue(lang, pageNum, pageSize, kato, nameAndInitials, statusId, queueNum) {
  return new Promise((resolve, reject) => {
    fetch(`${window.REST_URL}/cabinet/queue?lang=${lang}&pageNum=${pageNum}&pageSize=${pageSize}&kato=${nullToStr(kato)}&nameAndInitials=${nullToStr(nameAndInitials)}&statusId=${nullToStr(statusId)}&queueNum=${nullToStr(queueNum)}`, {
      method: "GET",
      mode: "cors",
      credentials: "include",
    })
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(e => reject(e));
  });
}

function getQueueStatus(lang) { // here
  return new Promise((resolve, reject) => {
    fetch(`${window.REST_URL}/cabinet/queue-status?lang=${lang}`, {
      method: "GET",
      mode: "cors",
      credentials: "include",
    })
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(e => reject(e));
  });
}

function getQueueByNum(lang, num, kato) {
  return new Promise((resolve, reject) => {
    fetch(`${window.REST_URL}/cabinet/queue/${num}?lang=${lang}&kato=${kato}`, {
      method: "GET",
      mode: "cors",
      credentials: "include"
    })
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(e => reject(e));
  });
}

const nullToStr = (x) => x ? x : '';

function getAtes(lang, kato) {
  return new Promise((resolve, reject) => {
    fetch(`${window.REST_URL}/cabinet/regions?lang=${lang}&kato=${kato}`, {
      method: "GET",
      mode: "cors",
      credentials: "include"
    })
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(e => reject(e));
  });
}

function getRegionsOnline(lang) {
  return new Promise((resolve, reject) => {
    fetch(`${window.REST_URL}/map/districts/online?lang=${lang}`, {
      method: "GET",
      mode: "cors",
      credentials: "include"
    })
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(e => reject(e));
  });
}

function getStatObjects(legendId, region, district, pageNum, pageSize) {
  return new Promise((resolve, reject) => {

    fetch(
      `${window.REST_URL}/map/statobjs?legend=${legendId}&region=${region}&district=${district}&pageNum=${pageNum}&pageSize=${pageSize}`,
      {
        method: "GET",
        mode: "cors",
        credentials: "include",
      },
    )
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(e => reject(e));
  });
}

function createSnapLayer(layers, region, setErrors, mode) {

  const snapSource = new Vector({
    wrapX: false,
    format: new GeoJSON(),
    projection: "EPSG:" + region.srs,
    loader: function (extent, resolution, projection) {
      snapSource.resolution_ = resolution;

      if (resolution < 100) {
        const box = transform(extent, projection, "EPSG:" + region.srs);
        wfsGetFeatures(
          `egkn:u_view`,
          box,
          region.srs,
          100,
          `district_id:${region.id}`
        )
          .then(res => {
            snapSource.addFeatures(res.features);
            if (res.total > 100 && mode === MODE_DRAW) {
              var err = { "msgRu": i18n.t("request.snapErr") };
              setErrors(err);
            } else {
              setErrors(undefined);
            }
          })
      }
    },
    strategy: (extent, resolution) => {
      if (snapSource.resolution_ !== resolution) {
        snapSource.loadedExtentsRtree_.clear();
      }
      return [extent];
    },
  });

  layers.snap = new VectorLayer({
    source: snapSource,
    style: null,
  });

  return layers.snap;
}

function getExtData(cadNums, code) {
  let lang = localStorage.getItem('i18nextLng')

  return new Promise((resolve, reject) => {
    fetch(`${window.REST_URL}/map/getextdata?lang=${lang}&code=${code}`, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(cadNums),
    })
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(e => reject(e));
  });
}

export default EknMap;

function getPattern() {
  const patternCanvas = document.createElement("canvas");
  const pctx = patternCanvas.getContext("2d", { antialias: true });
  const colour = "rgba(255, 0, 0, 0.5)";
  const CANVAS_SIDE_LENGTH = 10;
  const WIDTH = CANVAS_SIDE_LENGTH;
  const HEIGHT = CANVAS_SIDE_LENGTH;
  const DIVISIONS = 4;

  patternCanvas.width = WIDTH;
  patternCanvas.height = HEIGHT;
  pctx.fillStyle = colour;

  // Top line
  pctx.beginPath();
  pctx.moveTo(0, HEIGHT * (1 / DIVISIONS));
  pctx.lineTo(WIDTH * (1 / DIVISIONS), 0);
  pctx.lineTo(0, 0);
  pctx.lineTo(0, HEIGHT * (1 / DIVISIONS));
  pctx.fill();

  // Middle line
  pctx.beginPath();
  pctx.moveTo(WIDTH, HEIGHT * (1 / DIVISIONS));
  pctx.lineTo(WIDTH * (1 / DIVISIONS), HEIGHT);
  pctx.lineTo(0, HEIGHT);
  pctx.lineTo(0, HEIGHT * ((DIVISIONS - 1) / DIVISIONS));
  pctx.lineTo(WIDTH * ((DIVISIONS - 1) / DIVISIONS), 0);
  pctx.lineTo(WIDTH, 0);
  pctx.lineTo(WIDTH, HEIGHT * (1 / DIVISIONS));
  pctx.fill();

  // Bottom line
  pctx.beginPath();
  pctx.moveTo(WIDTH, HEIGHT * ((DIVISIONS - 1) / DIVISIONS));
  pctx.lineTo(WIDTH * ((DIVISIONS - 1) / DIVISIONS), HEIGHT);
  pctx.lineTo(WIDTH, HEIGHT);
  pctx.lineTo(WIDTH, HEIGHT * ((DIVISIONS - 1) / DIVISIONS));
  pctx.fill();

  return pctx.createPattern(patternCanvas, "repeat");
}

function getServitudePattern() {
  const patternCanvas = document.createElement("canvas");
  const pctx = patternCanvas.getContext("2d", { antialias: true });
  const colour = "rgba(0, 0, 0)";
  const CANVAS_SIDE_LENGTH = 10;
  const WIDTH = CANVAS_SIDE_LENGTH;
  const HEIGHT = CANVAS_SIDE_LENGTH;

  patternCanvas.width = WIDTH;
  patternCanvas.height = HEIGHT;
  pctx.strokeStyle = colour;
  pctx.lineWidth = 1;

  // Top line
  pctx.beginPath();
  pctx.moveTo(0, 0);
  pctx.lineTo(WIDTH, HEIGHT);
  pctx.moveTo(0, HEIGHT);
  pctx.lineTo(WIDTH, 0);
  pctx.stroke();

  return pctx.createPattern(patternCanvas, "repeat");
}

function getMaxPoly(polys) {
  var polyObj = [];
  for (var b = 0; b < polys.length; b++) {
    polyObj.push({ poly: polys[b], area: polys[b].getArea() });
  }
  polyObj.sort(function (a, b) {
    return a.area - b.area;
  });

  return polyObj[polyObj.length - 1].poly;
}
