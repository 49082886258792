import React, { useCallback, useState, useContext, useRef, useEffect } from 'react'
import styled from "styled-components";
import { ObjectsAndServices } from "./Services";
import svgDownload from '../../assets/img/download.svg';
import svgSign from '../../assets/img/sign.svg';
import { WKT } from "ol/format";
import ClipLoader from "react-spinners/ClipLoader";
import { Icon } from './UserProfile';
import svgRefresh from '../../assets/img/refresh.svg';
import svgExit from '../../assets/img/exit.svg';
import svgCheckUrl from '../../assets/img/check-url.svg';
import svgPayCheck from '../../assets/img/pay-check.svg';
import svgArrow from '../../assets/img/arrow.svg';
import { sendOrder, postOrderDocs, cancelOrder, uploadAppDoc, deleteAppDoc } from '../../RestUtils';
import { signText } from "../../NCALayerOps";
import { NF_ERROR } from '../Notification';
import { ConfirmContext } from '../../EknMap';
import { useTranslation } from "react-i18next";

export const PaymentTable = styled(props => <ObjectsAndServices {...props} />)`
    width: ${props => props.responsive.mobile ? "100%" : "542px"};
    height: ${props => props.responsive.mobile ? "100%" : "auto"};
    max-height: 85vh;
    overflow-y: auto;
    padding: 12px 6px 6px 12px;

    table {
      width: 96.7%;
      padding-left: 11px;
    }
    
    tbody tr:hover td {
      background-color: #DCF0E2 !important;
      color: #294A37;
      font-weight: 600;
    }

    td {
        button {
            width: 75px;
        }
    }

    tr {
      height: initial;
      padding-bottom: 10px;
    }

    .tr-head {
        background: #F8FFFA !important;
        padding: 5px;
        width: 100%;
        border-radius: 5px 5px 0 0;
        font-weight: bold;
    }

    th {
        color: #fff;
        text-align: left;
    }

    .order-table {

      overflow-y: initial;
    }

    .price-column {
        text-align: end;
        width: 20%;
    }

    .name-column {
      width: 80%;
    }

    .btn-td {
      margin: 0 0 0 auto;
    }

    .srvs-btns {
      margin: 3px 0 0 0;
      display: flex;
      justify-content: center;
      button {
        width: 218px;
        margin: 6px 0 6px 0;
        background: #04A2C2;
      }
    }

    .btn-minus {
      width: 100%;
    }

    .order-head {
      background: #fff;
      color: #fff;
      font-size: 16px;
      font-weight: bold;
      margin: 0 6px 12px 0;
      padding: 0;

      .btn-td {
        width: 30px;
        margin: 0;
        margin-right: 6px;
        background: #00BCA2;
      }
    }

    .show-on-map {
      width: 30px;
      margin: 0 0 0 10px;
      background: #008B78;
      padding: 7px 0 0 7px;

      div {
        margin-left: 3px !important;
      }
    }

    .head-order {
      height: 29px;
      line-height: 30px;
      border: 1px solid #B6D4C0;
      border-radius: 5px;
      margin: 0;
      margin-right: 6px;
      padding-left: 6px;
      font-size: 14px;
      font-weight: normal;
      color: #26602D;
      width: 110px;
      text-align: start;
    }

    .status {
      width: 326px;
    }

    .head-icons {
      height: 16px !important;
      width: 13px !important;
      margin: 5px 0 0 0 !important;
    }

    .exit {
      margin-bottom: 3px !important;
    }

    .refresh {
      margin-bottom: 5px !important;
    }
    
    .tabs {
      margin: 0 6px 10px 0;
      background: #fff;
      padding: 0;
      border-width: 0;

      button {
        background: #D9F2E1;
        color: #466E5D;
        margin: 0 8px 0px 0px;
        width: 33.3%;
      }

      .active-tab {
        background: #009856;
        color: #fff;
      }

      .right {
        margin: 0;
      }
    }

    .service-tab {
      display: block;
      height: auto;
      background: #fff;
      margin: 0 6px 0 0;
      padding: 0;
    }

    .obj-tab {
      border: 1px solid #B6D4C0;
      border-radius: 5px;
      padding: 11px;
      margin-top: 11px;
      margin-bottom: 3px;

      div {
        margin: 0;
        background: #fff;
        padding: 0;
        min-height: 30px;
        height: auto;

        p {
          background: #F8FFFA;
          border: 1px solid #B6D4C0;
          border-radius: 5px;
          margin: 0;
          line-height: 20px;
          padding: 8px 0 8px 8px;
          // width: 456px;
          width: 100%;
          color: #26602D;
          text-align: start;
        }
      }
    }

    .service-wrap {
      border: 1px solid #B6D4C0;
      border-radius: 5px;
      display: inline-table;
      background: #fff;
      margin: 0 0 10px 0px;
      padding: 10px;
      width: 95%;
      border-spacing: 0px;
      box-shadow: 0px 3px 6px #00000036;

      table {
        padding: 0;
        border: 1px solid #B6D4C0;
        border-radius: 5px;
        width: 100%;
        border-spacing: 0;

        tr {
          background: #fff;
          margin: 0;
          padding: 0;
          display: flex;
          color: #26602D;

          td {
            border: 1px solid #B6D4C0;
            margin: 0;
            padding: 10px;
          }
        }

        .even {
          background: #F8FFFA;
        }
      }
    }

    .pay-btns {
      margin: 10px 0px 0px 0px;
      padding: 0;
      background: #fff;

      .pay-date {
        margin: 0;
        padding: 8px;
        height: 100%;
        width: 100%;
        border: 1px solid #B6D4C0;
        border-radius: 5px;
        background: #D9F2E1;
        color: #26602D;
        text-align: start;
        font-size: 14px;

        .check-icon {
          height: 15px !important;
          margin: 0 8px 0 0 !important;
        }
      }

      button {
        width: 30%;
        margin: 0 0 0 8px;
        background: #00BCA2;
        text-align: start;

        div {
          margin-left: ${props => props.responsive.mobile ? "0" : "20px !important"};
          padding-right: 0 !important;
        }
      }

      .pay-btn {
        margin: 0 0 0 8px;
        width: 45%;
        text-align: center;
        background: #008B78;
      }
    }

    .doc-wrap {
      display: block;
      height: auto;
      margin: 0 0 8px 0 !important;

      p {
        width: 98% !important;
        margin-bottom: 9px !important;
      }

      .file-name {
        display: flex;

        p {
          background: #fff;
          margin: 0 8px 0 0px !important;
          font-weight: bold;
        }

        .zkr11-doc {
          width: 83% !important;
        }

        button {
          width: 30px;
          background: #008B78;
          padding: 8px 0 0 6px;

          div {
            height: 16px !important;
            margin: 0px 0px 0 2px !important;
          }
        }

        .btn-minus {
          .css-1flfzbn {
            width: 30px;
            padding: 0;
            margin: -8px 0px 0px -5px !important;
            height: 27px !important;
          }
        }

        .disable-btn {
          background: gray;
          cursor: default;
        }
      }
    }

    .servn-input[type="radio"] {
      appearance: none;
      position: relative;
      outline: none;
      width: 19px;
      height: 19px;
      margin-right: 5px;
      border-radius: 50%;
      cursor: ${props => props.order.statusId===2 ? "pointer" : "not-allowed"};
    }
    .servn-input[type="radio"]::before {
      content: "";
      box-sizing: border-box;
      width: 20px;
      height: 20px;
      position: absolute;
      background-color: white;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      border: 1.5px solid #009856;
    }
    
    .servn-input[type="radio"]:checked:after {
      content: "";
      box-sizing: border-box;
      width: 10px;
      height: 10px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      background-color: #009856;
    }
    .field__file-button {
      width: 165px;
      height: 24px;
      background: #D8F1E0;
      color: #26602D;
      font-size: 14px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      cursor: pointer;
  }
  .field__file-button-payment {
    height: 35px
  }
  .input__file {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    width: 460px;
    left: 30px
  }
  .field__file-wrapper {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .field__file-fake {
    min-height: 24px;
    height: auto;
    width: 275px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 15px;
    border: 1px solid #c7c7c7;
    border-radius: 3px 0 0 3px;
    cursor: pointer;
  }
  .record-sum {
    display: flex;
    align-items: center
  }
  .sum-title {
    padding: 5px;
    margin: 0;
    margin-right: 18px;
    width: 165px;
  }
  .provider-desc {
    text-align: left;
    margin: 0;
  }
`;

export default function PaymentService({
  handleClickOrder,
  order,
  handleSelectOrder,
  orderLoaded,
  setOrder,
  handleSelectOnMap,
  setNotification,
  responsive,
  handlePostDoc,
  handlePostOther,
  pdfNote,
  checkPdf,
  region,
  checkFileSize,
  checkFileSizeNote
}) {

  const SERVICE_TAB = 0;
  const OBJECT_TAB = 1;
  const DOC_TAB = 2;
  const ZKR_TAB = 3;

  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const [signLoading, setSignLoading] = useState(false);
  const [paymentType, setPaymentType] = useState();
  const [dogFileName, setDogFileName] = useState(t("payment-service.select-file")); 
  const [kvFileName, setKvFileName] = useState(t("payment-service.select-file"));
  const [sum, setSum] = useState(0);
  const { setConfirm } = useContext(ConfirmContext);

  const [kvFile, setKvFile] = useState();
  const [dogFile, setDogFile] = useState();

  const [fullFileName, setFullFileName] = useState();
  const [paymentName, setPaymentName] = useState();
  

  const regionNote = () => {
    setNotification({
      title: t("service.region-error-title"),
      text: t("service.region-error-text")
    })
  }
  
  const contractNote = () => {
    setNotification({
      title: t("payment-service.dv-error-title"),
      text: t("payment-service.dv-error-text"),
    })
  }

  const filNote = () => {
    setNotification({
        title: t("service.file-error-title"),
        text: t("service.file-error-text")
    })
}

const checkDuplicateFiles = (fileName) => {
  let isDuplicate = false;
  const found = order.docs.find(el => el.fileName===fileName);
  found ? isDuplicate=true : isDuplicate=false;
  return isDuplicate;
}
  
  const importDogFile = (e) => {
    if (e.target.files.length> 0) {
      if (checkPdf(e.target.files[0].name)) {
        pdfNote();
      } else if (checkFileSize(e.target.files[0])) {
        checkFileSizeNote();
      }  else {
        setDogFileName(e.target.files[0].name);
        setDogFile(e.target.files[0]);   
        if (!order.provider) {
          const dvf = order.docs.find(doc => doc.typeCode === 'ZKR7' && doc.fileName.includes('(Заявитель)'));
          let filename = e.target.files[0].name;
          let index1 = filename.indexOf('.')+1;
          let str1 = filename.substring(index1, filename.length);
          let str2 = filename.substring(0,index1-1);
          let oldName = '';
          const newName = str2 + ' (Заявитель).' + str1;
          if (fullFileName) {
            oldName = fullFileName;
          } else if (dvf) {
            oldName = dvf.fileName;
          }
          setFullFileName(newName);
          uploadAppDoc(order.id, e.target.files[0], "ZKR7", newName, oldName);
        }   
      }
    }
  }

  const importKvFile = (e) => {
    const fileName = e.target.files[0].name;
    if (checkDuplicateFiles(fileName)) {
      filNote();
    } else if (checkPdf(fileName)) {
      pdfNote();
    } else if (checkFileSize(e.target.files[0])) {
      checkFileSizeNote();
    } else {
      // order.docs.push({ typeCode: "ZKR57", fileName: fileName });
      setKvFileName(fileName);
      setKvFile(e.target.files[0]);
    }
  }

  const handleInitPayment = useCallback((service, surcharge) => {
    const dvf = order.docs.find(doc => doc.typeCode === 'ZKR7' && doc.fileName.includes('(Заявитель)'));
    if (!dogFile && !dvf) {
          contractNote();
    } else {
      service.isLoading = true;
      setOrder(Object.assign({}, order))
      fetch(`${window.REST_URL}/cabinet/order/${order.id}/initPayment?service=${service.code}&surcharge=${surcharge}`, {
        method: "POST",
        mode: "cors",
        credentials: "include",
      })
        .then(response => response.text())
        .then(url => window.location.href = url)
        .catch(e => {
          service.isLoading = false;
          setOrder(Object.assign({}, order))
          console.log(e)
        })
    }
  }, [order, setOrder, dogFile, setDogFile]);


  const handleDownloadDoc = useCallback((fileName) => {
    fetch(`${window.REST_URL}/upload/${order.id}?filename=${encodeURIComponent(fileName)}`, {
      method: "GET",
      mode: "cors",
      credentials: "include"
    })
      // .then(response => { 
      //   window.location.href = response.url; 
      // })
      .then((res) => res.blob())
			.then((blob) => {
				let url = window.URL.createObjectURL(blob);
				let a = document.createElement('a');
				a.href = url;
            	a.download = fileName;
            	document.body.appendChild(a);
            	a.click();    
            	a.remove();
			});
  }, [order]);

  const handleGetDataForSign = useCallback((fileName) => {
    return fetch(`${window.REST_URL}/upload/${order.id}?filename=${encodeURIComponent(fileName)}`, {
      method: "GET",
      mode: "cors",
      credentials: "include"
    });
  }, [order]);

  const handleSendSign = useCallback((sign) => {
    return fetch(`${window.REST_URL}/cabinet/sign/act/${order.id}`, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "text/plain",
      },
      body: sign,
    });
  }, [order]);

  const handleSignDoc = useCallback(() => {
    setSignLoading(true);
    const signedDoc = order.docs.find(item => item.typeCode === 'ZKR41');
    handleGetDataForSign(signedDoc.fileName)
      .then(response => response.text())
      .then(text => signText(text, "SIGNATURE"))
      .then(result => result.responseObject)
      .then(sign => {
        handleSendSign(sign)
          .then((response) => {
            if (!response.ok) {
              return response.text();
            } 
          })
          .then(data => {
            if (data) {
              setNotification({
                type: NF_ERROR,
                title: t("payment-service.error"),
                text: data,
                onClose: () => {
                  console.log('error back');
                }
              });
              setSignLoading(false);
            } else {
              handleSelectOrder(order.id);
            }
          })
          .catch(err => {
            setNotification({
              type: NF_ERROR,
              title: t("payment-service.error"),
              text: err,
              onClose: () => {
                console.log('error back');
              }
            });
            setSignLoading(false);
          });
      })
      .catch(err => {
        console.log(err);
        setSignLoading(false);
      });
  }, [order, setOrder]);

  const handleSendOrder = useCallback(() => {
    order.isLoading = true;
    setOrder(Object.assign({}, order))

    let str1 = '';
    let str2 = ''
    if (order.provider) {
      let filename = dogFile.name;
      let index1 = filename.indexOf('.')+1;
      str1 = filename.substring(index1, filename.length);
      str2 = filename.substring(0,index1-1);
      uploadAppDoc(order.id, dogFile, "ZKR7", str2 + ' (Заявитель).' + str1);
    }
    if (kvFile) {
      uploadAppDoc(order.id, kvFile, "ZKR57", kvFile.name)
      .then(response => {
        if (response.ok) {
          sendMessage(str2, str1)
        }
      })
    } else {
      sendMessage(str2,str1);
    }
    
  }, [order, setOrder, dogFile, setDogFile, kvFile, setKvFile])

  const sendMessage = (str2, str1) => {
    sendOrder(order.id)
      .then(data => {
        setOrder(data);
      })
      .catch((e)=> {
        if (e=='Error: Head Error') {
          setNotification({
            title: t("notif.error"),
            text: t("notif.head-error-2"),
          })
        }
        order.isLoading = false;
        if (order.provider) {
          deleteAppDoc(order.id, str2 + ' (Заявитель).' + str1);
          order.docs = order.docs.filter(doc => !doc.fileName.includes('(Заявитель)'));
        }
        if (kvFile) {
          deleteAppDoc(order.id, kvFile.name);
          order.docs = order.docs.filter((item) => item.typeCode !=='ZKR57');
        }
        setOrder(Object.assign({}, order))
      });
  } 
  const handleCancelOrder = useCallback(() => {
    order.isCancelLoading = true;
    setOrder(Object.assign({}, order))
    cancelOrder(order.id)
      .then(data => {
        setOrder(data);
    })
    .catch((e)=> {
      if (e=='Error: Head Error') {
        setNotification({
          title: t("notif.error"),
          text: t("notif.head-error-2"),
        })
      }
      order.isCancelLoading = false;
      setOrder(Object.assign({}, order))
    });
  }, [order, setOrder, dogFile, setDogFile, kvFile, setKvFile])

  const listService = () => {
    const dvf = order.docs.find(doc => doc.typeCode === 'ZKR7' && doc.fileName.includes('(Заявитель)'));
    const pld = order.docs.find(doc => doc.typeCode === 'ZKR57');
    
    return (order && order.services.map((item, i) => (
      <div key={i} className="service-wrap">
        
        {!order.provider && 
        <>
          <table><tbody>
            <tr className="tr-head">
              <td>{item.name}{(item.status_id === 10 ?
                <span style={{ color: "red" }}>{" " + t("payment-service.cause") + ": " + item.refusalReasonRu}</span> : "")}
              </td>
            </tr>
            {item.works.map((itm, j) => (
              <tr
                key={i + "" + j}
                className={j % 2 === 1 ? "even" : ""}
              >
                <td className="name-column">{itm.workTypeName}</td>
                <td className="price-column">{itm.price} тг.</td>
              </tr>
            ))}
            <tr key={i + "all"}
              style={{ background: "#D9F2E1" }}>
              <td className="name-column">{t("payment-service.total")}</td>
              <td className="price-column">{item.price ? item.price : 0} тг.</td>
            </tr>
          </tbody></table>

            <>
              {(order.statusId===2 || order.statusId===3) && 
                <>
                  <div className="input__wrapper" style={{padding: 0}}>
                    <label className="field__file-wrapper" htmlFor="payment__file1">
                    <div className="field__file-button">{t("payment-service.attach-contract")}</div>
                    <div className="field__file-fake">{dogFileName!==t("payment-service.select-file") ? dogFileName : dvf ? dvf.fileName : t("payment-service.select-file")}</div>
                    </label>
                    <input
                      type="file" 
                      accept="application/pdf"
                      name="file1" 
                      id="payment__file1" 
                      className="input input__file" 
                      onChange={(e) => {
                        importDogFile(e)
                      }}
                      disabled={order.statusId!==2 && order.statusId!==3}
                    />
                  </div>

                  <div className='serv-wrap'>
                    <input 
                      className="servn-input" 
                      type="radio" 
                      name="paymentType" 
                      value={t("payment-service.online-payment")} 
                      onClick={()=>{
                        setPaymentType(t("payment-service.online-payment"));
                        order.paymentType=t("payment-service.online-payment");
                        setKvFileName(t("payment-service.select-file"));
                        setKvFile(null);
                        setOrder(Object.assign({}, order))
                      }} 
                      defaultChecked
                      disabled={order.statusId!==2}
                    />{t("payment-service.online-payment")}

                    <input 
                      className="servn-input" 
                      type="radio" 
                      name="paymentType" 
                      value={paymentName} 
                      onClick={()=>{
                        setPaymentType(paymentName);
                        order.paymentType=paymentName;
                        setOrder(Object.assign({}, order))
                      }} 
                      // defaultChecked={pld || (order.paymentType && order.paymentType===paymentName)}
                      defaultChecked={order.paymentType && order.paymentType===paymentName}
                      disabled={order.statusId!==2}
                    />{paymentName}

                  </div> 
                </>
              }

              {paymentType===paymentName && order.statusId===2 && 
                <>
                  <div className="input__wrapper" style={{padding: 0}}>
                    <label className="field__file-wrapper" htmlFor="payment__file2">
                    <div className="field__file-button">{t("payment-service.attach-receipt")}</div>
                    {/* <div className="field__file-fake">{pld ? pld.fileName : kvFileName}</div> */}
                    <div className="field__file-fake">{kvFileName}</div>
                    </label>
                    <input
                      type="file" 
                      accept="application/pdf"
                      name="file2" 
                      id="payment__file2" 
                      className="input input__file" 
                      onChange={(e) =>{
                        importKvFile(e)
                      }}
                      disabled={order.statusId!==2}
                    />
                </div>
                </>
              }
            </>
        </>
        }

        {order.provider && 
          <>
            <p>{item.name}</p>
            <div className="record-sum">
              <h2 className='sum-title'>{(t("payment-service.total-price"))}</h2>
              <p>{order.services[0].price ? order.services[0].price : sum}</p>
            </div>

            {order.statusId===2 &&
              <div className="input__wrapper" style={{padding: 0}}>
                <label className="field__file-wrapper" htmlFor="payment__file1">
                <div className="field__file-button">{t("payment-service.attach-contract")}</div>
                <div className="field__file-fake">{dvf ? dvf.fileName : dogFileName}</div>
                </label>
                <input
                  type="file" 
                  accept="application/pdf"
                  name="file" 
                  id="payment__file1" 
                  className="input input__file" 
                  onChange={(e) => {
                    importDogFile(e)
                  }}
                  disabled={order.statusId!==2}
                />
              </div>
            }

            {order.statusId===2 && 
              <div className="input__wrapper" style={{padding: 0}}>
                <label className="field__file-wrapper" htmlFor="payment__file2">
                <div className="field__file-button field__file-button-payment">{paymentName}</div>
                {/* <div className="field__file-fake">{pld ? pld.fileName : kvFileName}</div> */}
                <div className="field__file-fake">{kvFileName}</div>
                </label>
                <input
                  type="file" 
                  accept="application/pdf"
                  name="file" 
                  id="payment__file2" 
                  className="input input__file" 
                  onChange={(e) => {
                    importKvFile(e)
                  }}
                  disabled={order.statusId!==2}
                  />
              </div>
            }

          
          </>
        }

        {!order.provider && paymentType==='Онлайн оплата'  && 

          <div className="pay-btns">
            <div className="pay-date">{item.paymentTime && <Icon url={svgPayCheck} className="check-icon" />}
              {item.paymentTime ? t("payment-service.paid") + " " + item.paymentTime : t("payment-service.not-paid")}
            </div>
            {item.paymentCheckUrl &&
              <button className="btn-minus"
                onClick={() => {
                  window.open(item.paymentCheckUrl, '_blank');
                }}
              >
                <Icon url={svgCheckUrl} />
                {t("payment-service.check")}
              </button>
            }
            {!item.paymentTime && (order.statusId===2 || order.statusId===3) && (item.isLoading ?
              <div className="load-wrap pay-load"><ClipLoader color={"#02c23e"} loading={true} /></div> :
              <button className="btn-minus pay-btn"
                onClick={handleInitPayment.bind(this, item, false)}
                disabled={order.statusId!==2}
              >
                {t("payment-service.pay")}
              </button>
            )}
            {item.priceExtra && !item.paymentExtraTime && (order.statusId===2 || order.statusId===3 || order.statusId===7) && (item.isLoading ?
              <div className="load-wrap pay-load"><ClipLoader color={"#02c23e"} loading={true} /></div> :
              <button className="btn-minus pay-btn"
                onClick={handleInitPayment.bind(this, item, true)}
              >
                {t("payment-service.pay-extra")} {item.priceExtra} тг.
              </button>
            )}
          </div>
        }
        {item.priceExtra && item.paymentExtraTime && <div className="pay-btns">

          <div className="pay-date"><Icon url={svgPayCheck} className="check-icon" />{t("payment-service.extra-paid") + " " + item.paymentExtraTime}</div>

          {item.paymentExtraCheckUrl &&
            <button className="btn-minus"
              onClick={() => {
                window.open(item.paymentExtraCheckUrl, '_blank');
              }}
            >
              <Icon url={svgCheckUrl} />
              {t("payment-service.check")}
            </button>
          }
        </div>}
      </div>

     
    )))
  }

  const listObject = () => {
    return (order && order.objects.map((item, i) => (
      <div key={i}>
        <p>{item.districtName + " " + (item.addrRu ? item.addrRu : item.funcUseNameRu)}</p>
        {item.coords && 
          <button
            className="btn-minus show-on-map"
            title={t("service.on-map")}
            onClick={() => {
              // handleSelectOnMap(new WKT().readGeometryFromText(item.coords));
              if (!region || region.code === "000") {
                regionNote();
              } else {
                handleSelectOnMap({wkt: item.coords});
              }
            }}
          >
            <Icon url={svgArrow} />
          </button>
        }
      </div>
    )))
  }

  const listDoc = () => {;
    return (order && order.docs.map((item, i) => (
      item.typeName && item.typeCode !== 'ZKR41' &&
      <div key={i}
        className="doc-wrap"
      >
        <div className="file-name">
          <p title={item.isDisabled ? t("payment-service.avr-title") : ""} className={(order.statusId === 12 && item.typeCode === 'ZKR11' ? "zkr11-doc" : "")}>{item.typeName + ":" + item.fileName}</p>

          <button title={item.isDisabled ? t("payment-service.avr-title") : t("zkr.download")} className={"btn-minus" + (!item.isDisabled ? "" : " disable-btn")}
            onClick={() => {
              if (!item.isDisabled) {
                handleDownloadDoc(item.fileName);
              }
            }}
          >
            <Icon url={svgDownload} />
          </button>
          {order.statusId === 12 && item.typeCode === 'ZKR11' &&
            <button 
              className="btn-minus"
              title={t("zkr.sign")}
              onClick={() => {
                if (!signLoading) {
                  handleSignDoc();
                }
              }}
            >
              {signLoading ?
                <ClipLoader color={"#fff"} loading={true} className="sign-loader" /> :
                <Icon url={svgSign} />
              }
            </button>
          }
        </div>
      </div>
    )))
  }


  useEffect(() => {
    let pName = order.iin ? t("payment-service.scan-receipt") : t("payment-service.scan-payment");
    setPaymentName(pName)

    const pld = order.docs.find(doc => doc.typeCode === 'ZKR57');
    if (pld || (order.paymentType && order.paymentType===pName)) {
      setPaymentType(pName);
    } else {
      setPaymentType(t("payment-service.online-payment"));
    }

    const dg = order.docs.find(doc => doc.typeCode === 'ZKR7' && doc.fileName.includes('(Заявитель)'));
    if (dg) setDogFileName(dg.fileName);
    
  }, [])


  return (
    <PaymentTable responsive={responsive} order={order}>
      <div className="order-table">
        <div className="order-head">
          <p className="head-order" onClick={() => console.log(order)}>{t("payment-service.order") + (order && order.id)}</p>
          <p className="head-order status">{t("payment-service.order-status") + (order && order.statusName)}</p>
          <button title="Обновить статус" className="btn-td" onClick={() => {
            if (!orderLoaded) {
              handleSelectOrder(order.id, order.services[0].code);
            }
          }}>
            <Icon
              className="head-icons refresh"
              url={svgRefresh}
            />
          </button>
          <button
            className="btn-td"
            style={{ margin: "0", background: "#005A4D" }}
            href=""
            onClick={() => {
              handleClickOrder(false);
            }}
          >
            <Icon
              className="head-icons exit"
              url={svgExit}
            />
          </button>
        </div>
        {order.errorTextRu && <div className="error">
          {order.errorTextRu}
        </div>}

        <div className="tabs">
          <button
            className={activeTab === SERVICE_TAB ? "active-tab" : ""}
            onClick={() => {
              setActiveTab(SERVICE_TAB);
            }}
          >
            {t("payment-service.service-title")}
          </button>
          <button
            className={activeTab === OBJECT_TAB ? " active-tab" : ""}
            onClick={() => {
              setActiveTab(OBJECT_TAB);
            }}
          >
            {t("payment-service.obj-title")}
          </button>
          <button
            // className={"right" + (activeTab === DOC_TAB ? " active-tab" : "")}
            className={activeTab === DOC_TAB ? " active-tab" : ""}
            onClick={() => {
              setActiveTab(DOC_TAB);
            }}
          >
            {t("payment-service.doc-title")}
          </button>
          {order.services[0].code==='602100' && 
            <button
              // className={"right" + (activeTab === ZKR_TAB ? " active-tab" : "")}
              className={activeTab === ZKR_TAB ? " active-tab" : ""}
              onClick={() => {
                setActiveTab(ZKR_TAB);
              }}
            >
              {t("service.provider-title")}
            </button>
          }
        </div>

        {activeTab === SERVICE_TAB &&
          <div className="service-tab">
            {listService()}
          </div>
        }

        {activeTab === OBJECT_TAB &&
          <div className="service-tab obj-tab">
            {listObject()}
          </div>
        }

        {activeTab === DOC_TAB &&
          <div className="service-tab obj-tab">
            {listDoc()}
          </div>
        }

        {activeTab === ZKR_TAB &&
          <div className="service-tab obj-tab">
            {order.provider ?
              <p className='provider-desc'>{order.providers[0].title}</p>
            : <p className='provider-desc'>{t("payment-service.nao-title")}</p>
            }
          </div>
        }

      </div>



      <div className="srvs-btns">
        {((order.statusId === 3 || order.statusId === 8) || (order.statusId == 2 && kvFileName!==t("payment-service.select-file") && dogFileName!==t("payment-service.select-file")))  && (order.isLoading ?
          <div className="load-wrap pay-load"><ClipLoader key={1} color={"#02c23e"} loading={true} /></div> :

            <button
            href=""
            onClick={() => {
              handleSendOrder();
            }}
          >
            {t("payment-service.send")}
          </button>

          
          )
          
        }
        {(order.statusId === 2 || order.statusId === 3 || order.statusId === 8) && (order.isCancelLoading ?
          <div className="load-wrap pay-load"><ClipLoader key={2} color={"#02c23e"} loading={true} /></div> :
         

          <button
            style={{ backgroundColor: "red" }}
            href=""
            onClick={() => {
              setConfirm({
                title: t("payment-service.confirm-title"),
                text: t("payment-service.confirm-text"),
                onConfirm: () => handleCancelOrder()
              });
            }}
          >
            {t("payment-service.refuse")}
          </button>

          
          )
          
        }
      </div>

    </PaymentTable>
  );
}