import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Wrapper, Header, Title, CloseButton } from "../Stats/BarChart";
import { QueuePopup, QueueWrapper } from "./Queue";
import svgClose from '../../assets/img/stat_close.svg';
import Collapsible from "react-collapsible";
import { Scrollbars } from "react-custom-scrollbars";
import ClipLoader from "react-spinners/ClipLoader";

const Popup = styled(QueuePopup)`
    .regions {
        width: 1060px; 
        display: flex; 
        flex-wrap: wrap; 
        padding: 10px; 
        padding-bottom: 30px;
        justify-content: space-between;

        .region-row {
            width: 33%
        }

        .region-btn {
            height: fit-content;
            position: relative;
            border: 2px solid #DCF0E2;
            border-radius: 5px;
            display: flex;
            align-items: center;
            padding: 8px 15px;
            font-family: Roboto;
            font-size: 14px;
            line-height: 17px;
            color: #294A37;
            justify-content: space-between;
            width: 100%;
            margin: 5px 0;
        }
        .region-btn_title {
            line-height: 0;
            padding-left: 20px;
        }
        .active {
            background: linear-gradient(180deg,rgba(2,194,62,1) 0%,rgba(0,168,36,1) 100%);
            color: #fff;
            margin-bottom: 0;
            border: none;
            border-radius: 5px 5px 0 0;
        }

        .expanded-icon {
            transition: transform 0.5s;
            background: none;
            padding: 0;
            position: absolute;
            left: 10px;
        }
        .expanded-icon-stand {
            transform: rotate(0deg);
        }
        .expanded-icon-deg {
            transform: rotate(90deg);
            fill: #fff;
        }

        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            background: #DCF0E2;
            margin-bottom: 5px;

            li {
                color: #294A37;
                font-size: 14px;
                padding: 8px;
            }
            li:nth-child(even) {
                background-color: #c9e8d3;
              }
        }
    }
`;

export default function RegionOnline({
    palette,
    handleCloseRegionsOnline,
    getRegionsOnline,
    regionsOnline,
    setRegionsOnline
}) {

    const { i18n } = useTranslation();
    const [expanded, setExpanded] = useState([]);

    useEffect(() => {
        getRegionsOnline(i18n.language).then(result => {
            setRegionsOnline(result);
        });
    }, [i18n.language, getRegionsOnline]);


    const column1 = []
    const column2 = []
    const column3 = []

    if (regionsOnline) {
        for (let i = 0; i < regionsOnline.length; i++) {
            if (i % 3 === 0) {
                column1.push(regionsOnline[i]);
            } else if (i % 3 === 1) {
                column2.push(regionsOnline[i]);
            } else {
                column3.push(regionsOnline[i]);
            }
          }
    }

    const checkExpanded = (code, value) => {
        const x = {};
        x[code] = value;
        setExpanded(Object.assign({}, expanded, x));
    }

    const renderColumns = (column) => {
        if (column.length>0) {
            return column.map((value) => (
                <div key={value.code}>
                    <Collapsible 
                    transitionTime={200}
                    easing="ease-out"
                    onOpening={() => checkExpanded(value.code, true)}
                    onClosing={() => checkExpanded(value.code, false)}
                    trigger={

                        <div 
                            className={"region-btn " + (expanded[value.code] && value.districts.length>0 ? "active" : "")} 
                        >
                            {value.districts.length>0 && 
                                <svg
                                    className={"expanded-icon " + (expanded[value.code] ? " expanded-icon-deg" : " expanded-icon-stand")}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 22 22"
                                >
                                    <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z" />
                                </svg>
                            }
                            <p className="region-btn_title">{value.name}</p>
                        </div>

                    }
                    >
                    {value.districts.length>0 && 
                        <ul>
                            {value.districts.map((item, index) => (
                            <li key={index}>{item.name}</li>
                            ))}
                        </ul>
                    }
                    </Collapsible>
                </div>
                ));
        }
    };

    return (
        <Wrapper palette={palette}>
            <Popup className="center-div"
                style={{
                minWidth: "1060px",
                width: "auto",
                left: "-50%"
                }}
            >
                <QueueWrapper>
                    <Header className="queue-head">
                        <Title className="queue-title">Список городов для онлайн предоставления земельного участка</Title>
                        <CloseButton className="queue-cls-btn" onClick={() => {
                        handleCloseRegionsOnline();
                        }}>
                        <img src={svgClose} style={{ width: '16px' }} alt="" />
                        </CloseButton>
                    </Header>
                    <Scrollbars
                        autoHeight={true}
                        autoHide={true}
                        autoHeightMax={"70vh"}
                    >
                        <div className="regions">

                            {!regionsOnline.length>0 ? <div style={{width: "100%", textAlign: "center"}}><ClipLoader color={"#02c23e"} loading={true} size={"45px"}/></div>  :
                                <>
                                <div className="region-row">{renderColumns(column1)}</div>
                                <div className="region-row">{renderColumns(column2)}</div>
                                <div className="region-row">{renderColumns(column3)}</div>
                                </> 
                            }
                        
                        </div>
                    </Scrollbars>
                </QueueWrapper>
            </Popup>
        </Wrapper>
    )
}