import i18n from 'i18next';
import Xhr from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next'



// backend : {loadPath: '/public/locales/{{lng}}/{{translation}}.json'},
//fallback lng - last choiced lng -> set
i18n
    .use(Xhr)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        lng: "ru",
        fallbackLng: "ru",
        whitelist: ['ru', 'kz', 'en'],
        nonExplicitWhitelist: true,
        interpolation: {
            escapeValue: false
        },
        react: {
            useSuspense: false
        },
        backend: {
            loadPath: 'locales/{{lng}}/translation.json?v=111'
        }
    })


export default i18n;
