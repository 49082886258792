import { Vector as VectorLayer } from 'ol/layer';
import { Cluster } from 'ol/source';
import VectorSource from 'ol/source/Vector';
import { Circle as CircleStyle, Fill, Stroke, Style, Text } from 'ol/style';
import { GeoJSON } from 'ol/format';
import { Point } from 'ol/geom';
import { getCenter } from 'ol/extent';
import { bbox } from 'ol/loadingstrategy';
import Icon from 'ol/style/Icon';
import * as olExtent from 'ol/extent';

class ClusterLayer extends VectorLayer {
  constructor(map, gsUrl, districtId) {
    const source = ClusterLayer.createClusterSource(gsUrl, districtId);
    const style = ClusterLayer.createClusterStyle();

    super({
      source: source,
      style: style,
    });

    this.gsUrl = gsUrl;
    this.map = map;
    this.addPointerMoveInteraction();
    this.addClickInteraction(); 
  }
  
  static createClusterSource(gsUrl, districtId) {
    const wfsSource = new VectorSource({
      format: new GeoJSON(),
      url: function (extent) {
        return (
          gsUrl +
          '/wfs?' +
          'service=WFS&' +
          'version=1.1.0&' +
          'request=GetFeature&' +
          'typename=egkn:freelands_cluster&' +
          `viewparams=district_id:${districtId};&` +
          'outputFormat=application/json&' +
          'srsname=EPSG:404000&' +
          'bbox=' +
          extent.join(',') + 
          ',EPSG:404000'
        );
      },
      strategy: bbox,
    });

    return new Cluster({
      distance: 20,
      minDistance: 16,
      source: wfsSource,
      geometryFunction: ClusterLayer.calculateClusterPoint.bind(this),
    });

  }

  static calculateClusterPoint(feature) {
    return new Point(getCenter(feature.getGeometry().getExtent()));
  }

  static createClusterStyle() {
    return (feature, resolution) => {
      const auctionLandStatusId = feature.get('features')[0].get('auction_land_status_id');
      const auctionTime = feature.get('features')[0].get('auction_time');
      const isHovered = feature.get('isHovered') || false;
      let text = '';

  
      const formatAuctionTime = (auctionTime) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        return new Date(auctionTime).toLocaleString('ru-RU', options);
      };
  
      // Set the minimum zoom level for displaying detailed tooltips
      const tooltipZoomLevel = 8;
  
      if (auctionLandStatusId === 93) {
        text = `Начат прием заявок на торг,\nсостоящийся ${formatAuctionTime(auctionTime)}`;
      } else if (auctionLandStatusId === 89) {
        text = `Предстоящий торг\n${formatAuctionTime(auctionTime)}`;
      } else {
        text = 'Нет данных';
      }
      // Create a style for clusters
      const clusterStyle = new Style({
        text: new Text({
          text: isHovered ? 'Информация о торгах' : '', 
          fill: new Fill({
            color: '#fff', // Text color
          }),
          backgroundFill: new Fill({
            color: 'rgba(0, 0, 0, 0.7)', 
          }),
          padding: [5, 5, 5, 5], 
          textAlign: 'center',
          offsetY: -15,
        }),
        image: new CircleStyle({
          radius: 10,
          stroke: new Stroke({
            color: '#fff',
          }),
          fill: new Fill({
            color: '#3399CC',
          }),
        }),
      });
  
      const tooltipStyle = new Style({
        image: new Icon({
          src: '/marker.png',
          anchor: [0.5, 1], 
          scale: 0.5,
        }),
        text: new Text({
          text: text, 
          font: '12px sans-serif',
          offsetY: -25, 
          fill: new Fill({
            color: '#ffffff' 
          }),

        })
      });
      // Determine the style to use based on the current resolution
      return resolution > tooltipZoomLevel ? clusterStyle : tooltipStyle;
    };
  }
  
  setDistrictId(districtId) {
    const oldSource = this.getSource().getSource();
    const extent = oldSource.get('extent');
    const gsUrl = this.gsUrl; 

    const newSource = ClusterLayer.createClusterSource(gsUrl, districtId);

    this.setSource(newSource);
  }

  setFeatureHoverState(feature, isHovered) {
    feature.set('isHovered', isHovered);
    this.getSource().changed();
  }  

  addPointerMoveInteraction() {
    let hoveredFeature = null; 
  
    this.map.on('pointermove', function(evt) {
      if (hoveredFeature) { 
        this.setFeatureHoverState(hoveredFeature, false); 
        hoveredFeature = null; 
      }
  
      this.map.forEachFeatureAtPixel(evt.pixel, function(feature, layer) {
        if (layer === this) { 
          this.setFeatureHoverState(feature, true); 
          hoveredFeature = feature; 
        }
      }.bind(this));
    }.bind(this));
  }

  addClickInteraction() {
    this.map.on('click', function(evt) {
      this.map.forEachFeatureAtPixel(evt.pixel, function(feature, layer) {
        if (layer === this) { 
          const features = feature.get('features'); 
  
          const extent = olExtent.createEmpty();
          for (let i = 0; i < features.length; i++) {
            olExtent.extend(extent, features[i].getGeometry().getExtent());
          }
  
          this.map.getView().fit(extent, { duration: 500, padding: [150, 150, 150, 150], maxZoom: 19}); 
        }
      }.bind(this));
    }.bind(this));
  }
}

export default ClusterLayer;
