import React from "react";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";
import { useTranslation } from 'react-i18next';

const Search = styled.div`
  input {
    width:103%;
    max-width: ${props => props.responsive.mobile ? "100%" : "350px"};
    box-sizing: border-box;
    height: 33px;
    border-radius: 6px 0 0 6px;
    background: #f3f3f7;
    color: "#000";
    padding: 0 0 0 10px;
    border: none;
    outline: none;
    margin-left: ${props => props.responsive.mobile ? "0" : "-6px"};
    
    border-bottom: 1px solid #c9d1de;
    font-size: 15px;

    &::placeholder {
      font-weight: normal
    }
  }
  #search-tooltip {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    opacity: 1;
  }
`;

export default function SearchBar(props) {
  const { t } = useTranslation()
  return (
    <Search
      palette={props.theme}
      data-tip data-for="search-tooltip"
      className="queue-search"
      responsive={props.responsive}
    >
      {props.tooltip && <ReactTooltip
        id="search-tooltip"
        effect="solid"
        border
        type={props.dark ? "light" : "dark"}
      >
        {props.tooltip}
      </ReactTooltip>}

      <input
        className="search-img"
        placeholder={props.placeholder}
        onChange={e => {
          if (props.handleKeyPress) {
            props.handleKeyPress(e.target.value);
          }
        }}
        onKeyPress={e => {
          if (e.charCode === 13) {
            props.handleSearch(e.target.value);
          }
        }}
      />
    </Search>
  );
}
