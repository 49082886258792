import React from "react";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";

const RefreshButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  background: inherit;
  border: none;
  padding: 6px 1px;
  width:20px;
  height:20px;
  margin-left:10px;
  border-radius: 50%;
  border: 1px solid green;
  svg {
    fill: #005b2d;
  }
  &:hover {
    background: rgba(1, 97, 31, 1);
    svg {
      fill: #fff;
    }
  }
  &:active {
    fill: #02c23e;
    svg {
      transform: scale(1.25);
    }
  }
`;

const RefreshLayer = ({ theme, handleRefresh, tooltipType }) => {
  const { t } = useTranslation();
  return (
    <RefreshButton
      palette={theme}
      onClick={handleRefresh}
      data-tip
      data-for="refresh-tooltip"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
      >
        <path d="M9 13.5c-2.49 0-4.5-2.01-4.5-4.5S6.51 4.5 9 4.5c1.24 0 2.36.52 3.17 1.33L10 8h5V3l-1.76 1.76C12.15 3.68 10.66 3 9 3 5.69 3 3.01 5.69 3.01 9S5.69 15 9 15c2.97 0 5.43-2.16 5.9-5h-1.52c-.46 2-2.24 3.5-4.38 3.5z" />
      </svg>
      <ReactTooltip
        id="refresh-tooltip"
        type={tooltipType}
        border
        delayShow={250}
      >
        {t("manage-map.3")}
      </ReactTooltip>
    </RefreshButton>
  );
};

export default RefreshLayer;
