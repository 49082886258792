import React, { useState } from "react";
import ObjectsInfoTable from "./ObjectsInfoTable";
import NoObj from "./components/NoObj";

import styled from "styled-components";
import SearchBar from "./components/SearchBar";
import { useTranslation } from 'react-i18next';
import { MODE_AUCTION_SELECT } from "./EknMap";
import PageNav from "./components/PageNavOutPageNum";


//    right: 120px; top: 50px;

const Wrapper = styled.div`
  box-sizing: border-box;
  position: absolute;
  // left: ${props => (props.responsive.mobile ? "0" : props.showSidebar ? "295px" : "120px")};
  right: ${props => !props.standalone ? "120px" : "0"}
  left: ${props => !props.standalone ? "none" : (props.responsive.mobile ? "0" : props.showSidebar ? "295px" : "120px")}
  
  // top: ${props => props.responsive.mobile ? "62px" : "230px"};
  top: ${props => !props.standalone ? "50px" : props.responsive.mobile ? "62px" : "230px"}
  width: 100%;
  max-width: ${props => props.responsive.mobile ? "100%" : "350px"};
  height: ${props => props.responsive.mobile ? "100%" : "auto"}
  padding: ${props => props.responsive.mobile ? "0" : "0px 0px 6px  6px"};
  background: #fff;
  z-index: 2;
  font-family: 'Open Sans', sans-serif;
  `;

const NotifWrapper = styled.div`
  position: absolute;
  left:0;
  top: -60px;
  width: 100%;
  max-width: 350px;
  background: rgba(75,74,75,0.4);
  z-index: 1;
  color: #fff;
  text-align: center;
  border-radius: 5px;
}
  `;


function EknMapInfo(props) {
  const { t } = useTranslation()
  const handleClose = () => {
    props.setOpen();
  };
  const nothingFound = () => {
    let resultEmpty = false;
    if (Object.keys(props.searchResult).length === 0 || props.searchResult.totalCount === 0) {
      resultEmpty = true;
      return resultEmpty;
    } else {
      for (let prop in props.searchResult) {
        resultEmpty = props.searchResult[prop].length === 0;
        if (!resultEmpty) {
          return resultEmpty;
        }
      }
    }
    return resultEmpty;
  };

  const [searchText, setSearchText] = useState("");
  const [pageNum, setPageNum] = useState(0);

  const getObjects = {
    func: pageNum => {
      props.handleSearch(searchText, pageNum * 10, 10);
    }
  };

  const handleSearch = searchText => {
    setSearchText(searchText);
    setPageNum(0);
    props.handleSearch(searchText, 0, 10);
  }

  return (
    props.open && (
      <Wrapper onClose={handleClose} showSidebar={props.showSidebar} responsive={props.responsive} standalone={props.standalone}>
        <SearchBar
          handleSearch={handleSearch}
          theme={props.theme}
          dark={props.dark}
          tooltip={t('search-bar.2')}
          placeholder={t('search-bar.1')}
          responsive={props.responsive}
        />
        {nothingFound() && (
          <NoObj
            theme={props.theme}
            msg={t('search-bar.3')}
            responsive={props.responsive}
          />
        )}
        {props.mode === MODE_AUCTION_SELECT && <NotifWrapper><p>Выберите участок!
          <button
            className="choice-land-cancel"
            onClick={() => {
              props.handleCancel();
              props.setOpenInfo(false);
            }}>
            {props.standalone ? `${t('request.cancel')}` : "Отменить"}
          </button>
        </p></NotifWrapper>}
        {props.searchResult && (Object.keys(props.searchResult).length > 0
          || props.searchResult.totalCount > 0) && (
            <ObjectsInfoTable
              region={props.region}
              info={props.searchResult}
              maxHeight={props.maxHeight}
              map={props.map}
              layers={props.layers}
              handleSelect={props.handleSelect}
              getObjects={props.getObjects}
              theme={props.theme}
              arrestIsChecked={props.arrestIsChecked}
              dark={props.dark}
              handleSend={props.handleSend}
              handleCreateBuffer={props.handleCreateBuffer}
              handleDeleteBuffer={props.handleDeleteBuffer}
              standalone={props.standalone}
              handleAuctionSelect={props.handleAuctionSelect}
              mode={props.mode}
              getExtData={props.getExtData}
              setMode={props.setMode}
              order={props.order}
              setOrder={props.setOrder}
              handleSearchByCadNum={props.handleSearchByCadNum}
              searchText={searchText}
            />
          )}
        {props.searchResult && props.searchResult.totalCount > 10 && <PageNav
          count={Math.ceil(props.searchResult.totalCount / 10)}
          getObjects={getObjects}
          theme={props.theme}
          pageNum={pageNum}
          setPageNum={setPageNum}
        />}
      </Wrapper>
    )
  );
}

export default EknMapInfo;
