import React, { useState } from "react";
import styled from "styled-components";
import { Icon } from './Cabinet/UserProfile';
import flagSvg from "../assets/img/chevrons-right.svg";
import cornerSvg from "../assets/img/corner-up-left.svg";
import { useTranslation } from "react-i18next";

const RegionSelect = styled.div`
  width: ${props => props.responsive.mobile ? "100%" : "800px"};
  z-index: ${props => props.responsive.mobile ? "5" : "1"};
  top: ${props => props.responsive.mobile ? "62px" : "42px"};
  height: ${props => props.responsive.mobile ? "100%" : "auto"};
  box-sizing: border-box;
  position: absolute;
  right: 0px;
  overflow: hidden;
  background: #ffffff;
  background: rgba(255, 255, 255, 1);
  border-style: Solid;
  border-color: #c9d1de;
  border-color: rgba(201, 209, 222, 1);
  border-width: 1px;
  border-radius: 7px;
  -moz-border-radius: 7px;
  -webkit-border-radius: 7px;

  .corner-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px 10px 0px;
    border-radius: 5px;
    color: #fff;
    background: #828282;
    width: 90px;
    height: 30px;
    border: none;
    outline: none;
    &:hover {
        cursor: pointer
    }
  }

  .regions {
    width: 100%; 
    display: flex; 
    flex-wrap: wrap; 
    padding: 10px; 
    padding-bottom: 30px;

    .sub-reg-btn {
        position: relative;
        div {
          display: none;
          position: absolute;
          top: -30px;
          font-size: 12px;
          background: #DCF0E2;
          padding: 3px 6px;
          width: max-content;
          color: #779480;
          font-family: Roboto;
  
          div {
            top: 14px;
            left: 10px;
            width: 10px;
            height: 11px;
            z-index: -1;
            transform: rotate(45deg);
          }
        }
    }

  .region-btn {
    height: fit-content;
    position: relative;
    border: 1px solid #DCF0E2;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 8px 15px;
    font-family: Roboto;
    font-size: 14px;
    line-height: 17px;
    color: #294A37;
    justify-content: space-between;
    cursor: pointer;
  }

  .region-btn.mobile {
    flex-grow: 1;
  }

  .region-btn-img {
    width: 35px;
    height: 35px;
    border: 1px solid #DCF0E2;
    margin-left: 2px;
    cursor: pointer;
    background-position: center !important;
  }
`;

export const ModalFooter = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;
    flex: none;
    order: 1;
    flex-grow: 0;

    button {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 10px 10px 0px;
        width: 93%;
        border-radius: 5px;
        color: #fff;
        height: 30px;
        border: none;
        outline: none;
        &:hover {
            cursor: pointer
        }
    }

    .success-btn {
        background: #00A824;
        width: 90px;
    }

    .cancel-btn {
        background: #777777;
        width: 90px;
    }
`;

export default function RegionDialog(props) {
    const [regionsData, setRegionsData] = useState(props.regions)
    const [parentRegion, setParentRegion] = useState(null)
    const { t } = useTranslation();

    const handleAteClick = (region) => {
        if (region.districts) {
            setParentRegion(region)
            setRegionsData(region.districts)
        }
    };

    const onBack = () => {
        setParentRegion(null)
        setRegionsData(props.regions)
    }

    const selectRegionClick = (region) => {
        let list = [];
        let selectedRegions = props.selectedRegions
        if(selectedRegions && selectedRegions.length > 0) {
            //проверка на наличие данного регина в списке ранее добавленных
            for (var i = 0; i < selectedRegions.length; i++) {
                if(region.code === selectedRegions[i].code) {
                    alert('Выбранный регион уже присутствует в списке')
                    return
                }
            }

            //добавляем родительский элемент если он есть
            if(!region.districts && parentRegion) {
                region['parent'] = parentRegion;
            }

            selectedRegions.push(region);
            list = selectedRegions;
        } else {
            //добавляем родительский элемент если он есть
            if(!region.districts && parentRegion) {
                region['parent'] = parentRegion;
            }
            
            list.push(region);
        }
        
        props.setRegion(list)
        props.setShowRegionsModal(false)
    };

    return (
        <RegionSelect palette={props.theme} responsive={props.responsive} >
            {parentRegion && (
                <button
                    className="corner-btn"
                    href=""
                    onClick={() => {
                        onBack()
                    }}
                >
                    <Icon url={cornerSvg} className="icon" />
                    {t("region.back")}
                </button>
            )}
            <div style={{ height: "481px" }}>
                <div className="regions">
                    {regionsData.map((item, i) => (
                        <div key={i} style={{ display: "flex", width: "31%", marginBottom: "5px" }}>
                            <div className="region-btn" title="Выбрать" style={{ width: item.districts ? "86%" : "90%" }}
                                onClick={(e) => {
                                    selectRegionClick(item);
                                }}
                            >
                            {item.nameRu}
                            </div>
                            {item.districts &&
                            <div className="region-btn-img sub-reg-btn" title="Перейти на список регионов" style={{ background: `url(${flagSvg}) no-repeat`, backgroundSize: "18px", marginRight: "5px" }}
                                onClick={(e) => {
                                    handleAteClick(item);
                                }}
                            >
                                <div>
                                <div></div>
                                {t("region.list")}
                                </div>
                            </div>
                            }
                        </div>
                    ))}
                </div>
            </div>

            <ModalFooter responsive={props.responsive}>
                <button
                    className="cancel-btn"
                    href=""
                    onClick={() => {
                        props.setShowRegionsModal(false)
                    }}
                >
                    {t("request.cancel")}
                </button>
            </ModalFooter>
        </RegionSelect>
    );
}
