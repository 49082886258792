import React from "react";
import styled from "styled-components";


const ButtonComp = styled.button`
  background : #FFFFFF;
  background : rgba(255, 255, 255, 1);
  background-size : 25px 25px;
  background-position: center center;
  background-repeat: no-repeat;
  position : absolute ;
  width : 50px;
  height : 50px;
  border-radius : 8px;
  -webkit-border-radius : 8px;
  border: none;
  cursor: pointer;
`;

export default function MapButton(props) {
  return (
    <ButtonComp
      onClick={props.handleClick}
      palette={props.theme}
      active={props.active}
      style={props.style}
      tooltipText={props.tooltipText}
      data-tip
      data-for={props.dataFor}
      title={props.title}
    >
      {props.title ? (
        <p>{props.title}</p>
      ) : (
          null
        )}

    </ButtonComp>
  );
}
