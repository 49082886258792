import React from "react";
import styled from "styled-components";

const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
  var angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

  return {
    x: centerX + (radius * Math.cos(angleInRadians)),
    y: centerY + (radius * Math.sin(angleInRadians))
  };
}

const Chart = styled.svg`
  width: ${props => props.width || '60px'};
  height: ${props => props.height || '60px'};
  display: block;
  margin-top: auto;
  margin-bottom: auto;
`;

const Pie = (props) => {
  // const [selected, setSelected] = useState(-1);
  const total = props.items.reduce((sum, item) => sum + item);

  const cr = 350;
  const r = 300;
  const sr = 320;

  let begAngle = 0;
  const selPaths = [];
  const paths = [];
  props.items.forEach((item, i) => {
    const mr = props.selected === i ? sr : r;
    const endAngle = begAngle + item * 359.99 / total;
    const beg = polarToCartesian(cr, cr, mr, begAngle)
    const end = polarToCartesian(cr, cr, mr, endAngle);
    const path = <path key={i} fill={props.colors[i]} d={
      `M ${cr} ${cr} L ${beg.x} ${beg.y} A ${mr} ${mr} 0 ${item * 359.99 / total > 180 ? 1 : 0} 1 ${end.x} ${end.y} L ${cr} ${cr}`
    }
      filter={props.selected === i ? 'url(#shadow)' : ''}
      //onClick={()=> this.setSelected.bind(this,i)}
      onClick={() => props.setSelected && props.setSelected(i)}
    />;
    props.selected === i ? selPaths.push(path) : paths.push(path);
    begAngle = endAngle;
    return path;
  });

  var str = '' + props.items[props.selected]
  const vw = props.selected !== undefined && props.selected !== -1 ? str.length * 60 : 0;

  return <Chart width={props.width} height={props.height} viewBox="0 0 700 700">
    <linearGradient id="grad1" x1="0" x2="1" y1="0" y2="1">
      <stop offset="0%" stopColor="rgba(255, 255, 255, 1)" />
      <stop offset="100%" stopColor="rgba(230, 230, 230, 1)" />
    </linearGradient>
    {props.selected !== -1 && <filter id="shadow">
      <feDropShadow dx="0" dy="0" stdDeviation="10" floodColor={props.colors[props.selected]} floodOpacity="1" />
    </filter>}
    {paths}
    <circle cx={cr} cy={cr} r={285} stroke="#3b4573" strokeOpacity={0.3} strokeWidth={30} fillOpacity={0} pointerEvents="none" />
    {selPaths}
    <circle cx={cr} cy={cr} r={195} stroke="#3b4573" strokeOpacity={0.3} strokeWidth={30} fillOpacity={0} pointerEvents="none" />
    <circle cx={cr} cy={cr} r={180} fill="url(#grad1)" />
    {/* {selected !== -1 && <rect x={cr - vw / 2} y={cr - 65} width={vw} height={100} rx="50" ry="50" fill={props.colors[selected]}/>} */}
    {props.selected !== -1 && <rect x={cr - vw / 2} y={cr - 65} width={vw} height={100} rx="50" ry="50" fill={!props.colors[props.selected] ? "#f1f1f1" : props.colors[props.selected]} />}
    {props.selected !== -1 && <text
      x="50%" y={props.total ? "55%" : "52%"} textAnchor="middle"
      fontFamily="'Open Sans', sans-serif;" fontWeight="bold" fontSize="80px"
      fill="black">
      {props.total ? props.total.toLocaleString(navigator.language, { minimumFractionDigits: 0 })
        : props.items[props.selected] ? props.items[props.selected].toLocaleString(navigator.language, { minimumFractionDigits: 0 }) : ''}
    </text>}
  </Chart>
}
export default Pie;