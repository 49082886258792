import React, { useState, useEffect } from "react";
import { StatsStyled } from "./StatsStyled";
import PieChart from "./PieChart";
import BarChart from "./BarChart";
import styled from "styled-components";
import Collapsible from "react-collapsible";
import { useTranslation } from "react-i18next";

const ButtonRow = styled.button`
  display: block;
  margin: 3px 0;
  font-size: 12px;
  width: 100%;
  text-align: left;
  outline: none;
  border: none;
  cursor: pointer;
  background: #d3e8da;

  span {
    color: #005a2f;
    margin-left: 5px;
    line-height: 32px;
    vertical-align: middle;
  }
  svg {
    fill: ${props => props.palette.text};
  }
`;

const RowStyled = styled.div`
  box-sizing: border-box;
  position: relative;

`;

export const VIEW_TABLE = 1;
export const VIEW_BARS = 2;
export const VIEW_PIES = 3;

export default function Stats(props) {
  const [data, setData] = useState();
  const [stats, setStats] = useState();
  const [active, setActive] = useState();
  const [items, setItems] = useState([]);
  const [item, setItem] = useState();
  const [view, setView] = useState();
  const { i18n } = useTranslation();


  useEffect(() => {
    const queryURL = `${window.REST_URL}/map/statconf?region=${props.region ? props.region.regionCode : "00"
      }&district=${props.region ? props.region.code : "000"}&lang=${i18n.language}`;
    fetch(queryURL, {
      method: "GET",
      mode: "cors",
      credentials: "include",
    })
      .then(response => response.json())
      .then(json => {
        setData(json);
      });
  }, [props.region, i18n.language]);


  const handleGetStats = e => {
    if (!items.length || e.currentTarget.value !== active) {
      props.setShowSelector(false);
      props.setShowListService(false);
      props.setShowListMyObjs(false);
      props.setShowObjSearch(false);
      const category = e.currentTarget.value;
      setActive(category);
      setView(VIEW_BARS);
    }
  };

  function download(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    // the filename you want
    a.download = filename;
    document.body.appendChild(a);

    a.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
    );
    document.body.removeChild(a);
  }

  const handleLoadStats = () => {
    fetch(`${window.REST_URL}/upload`, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ items: items, legend: stats.legend, xTitle: stats.parentXId.text })
    })
      .then(response => {
        return response.blob();
      })
      .then(blob => {
        download(blob, "stats.pdf");
      })
  }

  const handleStats = (parentXId, parentYId, fact, agg, agg_value, idsx, idsy, chartType, nodesNamesX, nodesNamesY) => {
    props.setAnalyticLoading(true);
    let regionCode = props.region ? props.region.regionCode : "00";
    props.getStats(parentXId, parentYId, fact, agg, agg_value, nodesNamesX, nodesNamesY, regionCode).then(data => {
      data.xAxes = idsx;
      data.yAxes = idsy;
      data.chartType = chartType;
      setStats(data);
      setItems(data.items);
      props.setAnalyticLoading(false);
    });
  };

  //set lang, and send getStats -> send  params -> backend
  useEffect(() => {
    if (active) {
      let ids = active.split(',');
      let group = data.find(e => e.id == ids[0]);
      let item = group.children.find(e => e.id == ids[1]);
      let firstXId = item.idsx.find(e => e.id === item.firstXId);
      let firstYId = item.idsy.find(e => e.id === item.firstYId);

      handleStats(firstXId, firstYId, item.fact, item.agg, item.agg_value, item.idsx, item.idsy, item.chartType);
    } else {
      setStats();
      setItems([]);
    }
    setItem(null);
  }, [active, i18n.language]);


  useEffect(() => {
    props.layers.regions.setVisible(true);
    const handler = () => {
      const showDistricts = props.map.getView().getZoom() > 7;
      props.layers.districts.setVisible(showDistricts);
      props.layers.regions.setVisible(!showDistricts);
    };

    props.map.on("moveend", handler);
    return () => {
      props.layers.regions.setVisible(false);
      props.layers.districts.setVisible(false);
      props.map.un("moveend", handler);
    };
  }, [props.map, props.layers, props.region]);

  let k = 0;
  return (
    <>
      {view ? (
        <>
          {view === VIEW_PIES && !item ? (
            <>
              {items.map((item, i) => (
                <PieChart
                  key={i}
                  num={i}
                  info={item}
                  setItem={setItem}
                  setView={setView}
                  legend={stats.legend}
                  map={props.map}
                  dark={props.dark}
                  open={true}
                  theme={props.theme}
                  region={props.region}
                  handleGetStatObjects={props.handleGetStatObjects}
                />
              ))}
            </>
          ) : (
            <BarChart
              stats={stats || {}}
              item={item}
              setItem={setItem}
              theme={props.theme}
              view={view}
              setView={setView}
              dark={props.dark}
              handleCloseStats={props.handleCloseStats}
              handleLoadStats={handleLoadStats}
              handleStats={handleStats}
              analyticLoading={props.analyticLoading}
              setAnalyticLoading={props.setAnalyticLoading}
              region={props.region}
              handleSelect={props.handleSelect}
              responsive={props.responsive}
            />
          )}
        </>
      ) : (
        <StatsStyled
          palette={props.theme}
          style={{
            boxSizing: "border-box",
            zIndex: 1,
            position: "absolute",
            top: "410px",
            left: props.show ? "300px" : "120px",
          }}

        >
          {data &&
            data.map((datum, i) => {
              return (
                datum.children && (
                  <Collapsible
                    key={i}
                    transitionTime={200}
                    trigger={
                      <Row
                        idx={k++}
                        level={0}
                        palette={props.theme}
                        handleGetStats={() => { }}
                        datum={datum}
                      />
                    }
                  >
                    {datum.children.map((childDatum, j) => (
                      <ClickableRow
                        key={j}
                        datum={childDatum}
                        parent={datum.id}
                        level={1}
                        handleGetStats={handleGetStats}
                        palette={props.theme}
                      />
                    ))}
                  </Collapsible>
                )
              );
            })}
        </StatsStyled>
      )}

    </>
  );
}

let names = ["stat-title1", "stat-title2", "stat-title3", "stat-title3", "stat-title3"];
let images = names.map(name => (
  <img src={require(`./../../assets/img/${name}.svg`)} alt="" />
));

const style1 = {
  backgroundColor: "rgba(1, 97, 31, 1)",
  borderRadius: "5px 5px 0px 0px",
};
const style2 = {
  backgroundColor: "#018E36",
};
const style3 = {
  backgroundColor: "#02C23E",
  borderRadius: "0px 0px 5px 5px",
};

const listStyleLegend = [style1, style2, style3, style2, style3];

//stats group menu
function Row({ idx, level, datum, handleGetStats, palette }) {

  return (

    <RowStyled
      level={level}
      palette={palette}
      value={datum.id}
      onClick={e => handleGetStats(e)}
    >
      <div style={listStyleLegend[idx]}>
        <span value={datum.id}>
          {images[idx]}
          <span className="stats-title"> {datum.title} </span>
        </span>
      </div>
    </RowStyled>
  );
}

function ClickableRow({ level, datum, handleGetStats, palette, parent }) {
  return (
    <div className="stats-group">
      <ButtonRow
        level={level}
        palette={palette}
        value={parent + "," + datum.id}
        onClick={e => handleGetStats(e)}
      >
        <span value={datum.id}>
          <span> {datum.title} </span>
        </span>
      </ButtonRow>
    </div>
  );
}


