import React from "react";
import styled from "styled-components";
import { WKT, GeoJSON } from "ol/format";
import i18n from "../../i18n";

const Table = styled.table`
  width: 100%;
  border: 1px solid #fff;
  margin-top: 5px !important;
  border-spacing: 0;
  font-size: 12px;
  color: #fff;
  thead {
    color: #fff;
    tr {
      color: #fff;
      height: 25px;
      background: #02C23E;
      td {
        border: 1px solid #fff;
        color: #fff;
      }
    }
    
  }
  tbody {
    tr {
      td {
        color: #fff;
        border: 1px solid #fff;
        padding: 4px;
      }
    }
  }
`;

const Type = Object.freeze({
  string: 1,
  date: 2,
  area_m2: 3,
  area_ga: 4,
  length: 5,
  cost_kzt: 6,
  capacity_kwt: 7,
  capacity_gcal_h: 8,
  capacity_m3_h: 9,
  FNT: 10,
  closed: 11,
  hidden: 12,
  link: 13,
  area_m2only: 14,
  service: 15,
  othodi: 16,
  esriDate: 17,
  dict: 18,
  dateTime: 19,
  checkInst: 20,
  geom_text: 21,
  capacity_kwa: 22,
  capacity_m3_day: 23,
  capacity_l_sec: 24,
  capacity_t_h: 25,
  table: 26,
  capacity_mpa: 27,
  area_m3: 28,
  diameter_mm: 29,
  cost_kzt_2: 30,
  egov_date: 31
});
export const Properties = {

  lands: {
    getTitle: props =>
      (props.kad_nomer ? props.kad_nomer : "") +
      (props.address_ru ? ", " + props.address_ru : "") +
      (props.rka ? ", РКА " + props.rka : "") + 
      (props.desc ? ", (" + props.desc + ")" : ""),
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              kad_nomer: [() => <>{i18n.t("info-tab.kad-num")}</>],
              address_ru: [() => <>{i18n.t("info-tab.address-land")}</>],
              category_ru: [() => <>{i18n.t("info-tab.land-category")}</>],
              pravo_ru: [() => <>{i18n.t("info-tab.law-type")}</>],
              tsn_ru: [() => <>{i18n.t("info-tab.spec-purps")}</>],
              shape_length: [
                () => <>{i18n.t("info-tab.shape-length")}</>,
                Type.length,
              ],
              shape_area: [
                () => <>{i18n.t("info-tab.shape-area")}</>, 
                Type.area_m2
              ],
              squ: [
                () => <>{i18n.t("info-tab.area-doc")}</>, 
                Type.area_m2
              ],
              cost: [
                () => <>{i18n.t("info-tab.cad-cost")}</>, 
                Type.cost_kzt_2
              ],
              status_name: [() => <>{i18n.t("info-tab.land-status")}</>],
              date_end: [()=> <>{i18n.t("info-tab.date-moved")}</>, Type.date],
              cdate: [()=> <>{i18n.t("info-tab.cdate")}</>, Type.date],
              cuser: [()=> <>{i18n.t("info-tab.cuser_land")}</>],
              uuser: [()=> <>{i18n.t("info-tab.uuser")}</>],
              udate: [()=> <>{i18n.t("info-tab.udate")}</>, Type.date],
              doc_foundation_end: [()=> <>{i18n.t("info-tab.doc_foundation_end")}</>],
              prim: [()=> <>{i18n.t("info-tab.prim")}</>],
              rka_id: [() => <>{i18n.t("info-tab.rca-id")}</>],
              address_ru_id: [() => <>{i18n.t("info-tab.address-id-ru")}</>],
              address_kz_id: [() => <>{i18n.t("info-tab.address-id-kz")}</>],
            },
          },
        ],
      },
      {
        id: 'owners',
        getTitle: () => <>{i18n.t("info-tab.owners")}</>,
        getItems: props => props.owners.items,
        groups: [
          {
            getTitle: () => <>{i18n.t("info-tab.owner")}</>,
            props: {
              nameUl: [() => <>{i18n.t("info-tab.name")}</>],
              bin: [() => <>{i18n.t("info-tab.bin")}</>],
            },
          },
        ],
      },
      {
        id: 'arrests',
        getTitle: () => <>{i18n.t("info-tab.arrests")}</>,
        getItems: props => props.arrests.items,
        isArrest: true,
        groups: [
          {
            getTitle: (props, i) => props.chargeTypeRu +  (props.count ? " " + i + " из " + props.count : ""),
            props: {
              chargeOwnerRu: [() => <>{i18n.t("info-tab.inter-party")}</>,
              ],
              regDate: [() => <>{i18n.t("info-tab.beginning-date")}</>],
              regDateClose: [() => <>{i18n.t("info-tab.expiration-date")}</>],
              docNameRu: [() => <>{i18n.t("info-tab.doc-name")}</>],
              docNum: [() => <>{i18n.t("info-tab.doc-num")}</>],
              docDate: [() => <>{i18n.t("info-tab.doc-date")}</>],
            },
          },
        ],
      },
      {
        id: 'obrems',
        getTitle: () => <>{i18n.t("info-tab.mortgage")}</>,
        getItems: props => props.obrems.items,
        groups: [
          {
            getTitle: (props, i) => props.chargeTypeRu + (props.count ? i + " из " + props.count : ""),
            props: {
              chargeOwnerRu: [() => <>{i18n.t("info-tab.inter-party")}</>,
              ],
              regDate: [() => <>{i18n.t("info-tab.beginning-date")}</>],
              regDateClose: [() => <>{i18n.t("info-tab.expiration-date")}</>],
              docNameRu: [() => <>{i18n.t("info-tab.doc-name")}</>],
              docNum: [() => <>{i18n.t("info-tab.doc-num")}</>],
              docDate: [() => <>{i18n.t("info-tab.doc-date")}</>],
            },
          },
        ],
      },
      {
        id: 'buildings',
        getTitle: () => <>{i18n.t("info-tab.buildings")}</>,
        getItems: props => props.buildings,
        groups: [
          {
            getTitle: props => <>{i18n.t("info-tab.building")}</>,
            props: {
              kad_nomer: [() => <>{i18n.t("info-tab.kad-num")}</>],
              geom_text: [() => <>{i18n.t("info-tab.show-on-map")}</>, Type.geom_text],
              rca: [() => <>{i18n.t("info-tab.rca")}</>],
              address_ru: [() => <>{i18n.t("info-tab.address-land")}</>],
              floors: [() => <>{i18n.t("info-tab.floors")}</>],
              txIsZhiloe: [() => <>{i18n.t("info-tab.fund-category")}</>],
              //litera: [() => <>{i18n.t("info-tab.letter")}</>],
              celRu: [() => <>{i18n.t("info-tab.funct-purps")}</>],
              shape_length: [() => <>{i18n.t("info-tab.shape-length")}</>, Type.length],
              shape_area: [() => <>{i18n.t("info-tab.shape-area")}</>, Type.area_m2only],
            },
          }
        ],
      },
      {
        id: 'archive',
        getTitle: () => <>{i18n.t("info-tab.archive")}</>,
        getItems: props => props.childs,
        groups: [
          {
            getTitle: () => <>{i18n.t("info-tab.archive")}</>, 
            getChild: props => props.date_end,
            props: {
              geometry:  [() => <>{i18n.t("object-info-table.2")}</>, Type.geom_text],
              kad_nomer: [() => <>{i18n.t("info-tab.kad-num")}</>],
              address_ru: [() => <>{i18n.t("info-tab.address-land")}</>],
              category_ru: [() => <>{i18n.t("info-tab.land-category")}</>],
              pravo_ru: [() => <>{i18n.t("info-tab.law-type")}</>],
              tsn_ru: [() => <>{i18n.t("info-tab.spec-purps")}</>],
              shape_length: [
                () => <>{i18n.t("info-tab.shape-length")}</>,
                Type.length,
              ],
              shape_area: [
                () => <>{i18n.t("info-tab.shape-area")}</>, 
                Type.area_m2
              ],
              cost: [
                () => <>{i18n.t("info-tab.cad-cost")}</>, 
                Type.cost_kzt_2
              ],
              status_name: [() => <>{i18n.t("info-tab.land-status")}</>],
              date_end: [()=> <>{i18n.t("info-tab.date-moved")}</>, Type.date],
              cdate: [()=> <>{i18n.t("info-tab.cdate")}</>, Type.date],
              cuser: [()=> <>{i18n.t("info-tab.cuser_land")}</>],
              uuser: [()=> <>{i18n.t("info-tab.uuser")}</>],
              udate: [()=> <>{i18n.t("info-tab.udate")}</>, Type.date],
              doc_foundation_end: [()=> <>{i18n.t("info-tab.doc_foundation_end")}</>],
              prim: [()=> <>{i18n.t("info-tab.prim")}</>],
              rka_id: [() => <>{i18n.t("info-tab.rca-id")}</>],
              address_ru_id: [() => <>{i18n.t("info-tab.address-id-ru")}</>],
              address_kz_id: [() => <>{i18n.t("info-tab.address-id-kz")}</>],
            },
          },
        ],
      },
    ],
  },

  lands_archive: {
    getTitle: props =>
      (props.kad_nomer ? props.kad_nomer : "") +
      (props.address_rus ? ", " + props.address_ru : "") +
      (props.rka ? ", РКА " + props.rka : "") + 
      (props.desc ? ", (" + props.desc + ")" : ""),
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              kad_nomer: [() => <>{i18n.t("info-tab.kad-num")}</>],
              address_ru: [() => <>{i18n.t("info-tab.address-land")}</>],
              category_ru: [() => <>{i18n.t("info-tab.land-category")}</>],
              pravo_ru: [() => <>{i18n.t("info-tab.law-type")}</>],
              tsn_ru: [() => <>{i18n.t("info-tab.spec-purps")}</>],
              shape_length: [
                () => <>{i18n.t("info-tab.shape-length")}</>,
                Type.length,
              ],
              shape_area: [
                () => <>{i18n.t("info-tab.shape-area")}</>, 
                Type.area_m2
              ],
              squ: [
                () => <>{i18n.t("info-tab.area-doc")}</>, 
                Type.area_m2
              ],
              cost: [
                () => <>{i18n.t("info-tab.cad-cost")}</>, 
                Type.cost_kzt_2
              ],
              status_name: [() => <>{i18n.t("info-tab.land-status")}</>],
              date_end: [()=> <>{i18n.t("info-tab.date-moved")}</>, Type.date],
              cdate: [()=> <>{i18n.t("info-tab.cdate")}</>, Type.date],
              cuser: [()=> <>{i18n.t("info-tab.cuser_land")}</>],
              uuser: [()=> <>{i18n.t("info-tab.uuser")}</>],
              udate: [()=> <>{i18n.t("info-tab.udate")}</>, Type.date],
              doc_foundation_end: [()=> <>{i18n.t("info-tab.doc_foundation_end")}</>],
              prim: [()=> <>{i18n.t("info-tab.prim")}</>],
              rka_id: [() => <>{i18n.t("info-tab.rca-id")}</>],
              address_ru_id: [() => <>{i18n.t("info-tab.address-id-ru")}</>],
              address_kz_id: [() => <>{i18n.t("info-tab.address-id-kz")}</>],
            },
          },
        ],
      },
      {
        id: 'owners',
        getTitle: () => <>{i18n.t("info-tab.owners")}</>,
        getItems: props => props.owners.items,
        groups: [
          {
            getTitle: () => <>{i18n.t("info-tab.owner")}</>,
            props: {
              nameUl: [() => <>{i18n.t("info-tab.name")}</>],
              bin: [() => <>{i18n.t("info-tab.bin")}</>],
            },
          },
        ],
      },
      {
        id: 'arrests',
        getTitle: () => <>{i18n.t("info-tab.arrests")}</>,
        getItems: props => props.arrests.items,
        isArrest: true,
        groups: [
          {
            getTitle: (props, i) => props.chargeTypeRu +  (props.count ? " " + i + " из " + props.count : ""),
            props: {
              chargeOwnerRu: [() => <>{i18n.t("info-tab.inter-party")}</>,
              ],
              regDate: [() => <>{i18n.t("info-tab.beginning-date")}</>],
              regDateClose: [() => <>{i18n.t("info-tab.expiration-date")}</>],
              docNameRu: [() => <>{i18n.t("info-tab.doc-name")}</>],
              docNum: [() => <>{i18n.t("info-tab.doc-num")}</>],
              docDate: [() => <>{i18n.t("info-tab.doc-date")}</>],
            },
          },
        ],
      },
      {
        id: 'obrems',
        getTitle: () => <>{i18n.t("info-tab.mortgage")}</>,
        getItems: props => props.obrems.items,
        groups: [
          {
            getTitle: (props, i) => props.chargeTypeRu + (props.count ? i + " из " + props.count : ""),
            props: {
              chargeOwnerRu: [() => <>{i18n.t("info-tab.inter-party")}</>,
              ],
              regDate: [() => <>{i18n.t("info-tab.beginning-date")}</>],
              regDateClose: [() => <>{i18n.t("info-tab.expiration-date")}</>],
              docNameRu: [() => <>{i18n.t("info-tab.doc-name")}</>],
              docNum: [() => <>{i18n.t("info-tab.doc-num")}</>],
              docDate: [() => <>{i18n.t("info-tab.doc-date")}</>],
            },
          },
        ],
      },
      {
        id: 'buildings',
        getTitle: () => <>{i18n.t("info-tab.buildings")}</>,
        getItems: props => props.buildings,
        groups: [
          {
            getTitle: props => <>{i18n.t("info-tab.building")}</>,
            props: {
              kad_nomer: [() => <>{i18n.t("info-tab.kad-num")}</>],
              geom_text: [() => <>{i18n.t("info-tab.show-on-map")}</>, Type.geom_text],
              rca: [() => <>{i18n.t("info-tab.rca")}</>],
              address_ru: [() => <>{i18n.t("info-tab.address-land")}</>],
              floors: [() => <>{i18n.t("info-tab.floors")}</>],
              txIsZhiloe: [() => <>{i18n.t("info-tab.fund-category")}</>],
              //litera: [() => <>{i18n.t("info-tab.letter")}</>],
              celRu: [() => <>{i18n.t("info-tab.funct-purps")}</>],
              shape_length: [() => <>{i18n.t("info-tab.shape-length")}</>, Type.length],
              shape_area: [() => <>{i18n.t("info-tab.shape-area")}</>, Type.area_m2only],
            },
          }
        ],
      },
    ],
  },

  part: {
    getTitle: props =>
      (props.kad_nomer ? props.kad_nomer : "") +
      (props.address_ru ? ", " + props.address_ru : "") +
      (props.rka ? ", РКА " + props.rka : "") + 
      (props.desc ? ", (" + props.desc + ")" : ""),
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              kad_nomer: [() => <>{i18n.t("info-tab.kad-num")}</>],
              part_num: [() => <>{i18n.t("info-tab.part-num")}</>],
              part_type_name : [() => <>{i18n.t("info-tab.part-type-id")}</>],
              address_ru: [() => <>{i18n.t("info-tab.address")}</>],
              category_ru: [() => <>{i18n.t("info-tab.land-category")}</>],
              pravo_ru: [() => <>{i18n.t("info-tab.law-type")}</>],
              tsn_ru: [() => <>{i18n.t("info-tab.spec-purps")}</>],
              shape_length: [
                () => <>{i18n.t("info-tab.shape-length")}</>,
                Type.length,
              ],
              shape_area: [
                () => <>{i18n.t("info-tab.shape-area")}</>, 
                Type.area_m2
              ],
              land_status: [() => <>{i18n.t("info-tab.land-status")}</>],
              date_end: [()=> <>{i18n.t("info-tab.date-moved")}</>, Type.date]
            },
          },
        ],
      },
      {
        id: 'archive',
        getTitle: () => <>{i18n.t("info-tab.archive")}</>,
        getItems: props => props.childs,
        groups: [
          {
            getTitle: () => <>{i18n.t("info-tab.archive")}</>, 
            getChild: props => props.date_end,
            props: {
              geometry:  [() => <>{i18n.t("object-info-table.2")}</>, Type.geom_text],
              kad_nomer: [() => <>{i18n.t("info-tab.kad-num")}</>],
              part_num: [() => <>{i18n.t("info-tab.part-num")}</>],
              part_type_name : [() => <>{i18n.t("info-tab.part-type-id")}</>],
              address_ru: [() => <>{i18n.t("info-tab.address")}</>],
              category_ru: [() => <>{i18n.t("info-tab.land-category")}</>],
              pravo_ru: [() => <>{i18n.t("info-tab.law-type")}</>],
              tsn_ru: [() => <>{i18n.t("info-tab.spec-purps")}</>],
              shape_length: [
                () => <>{i18n.t("info-tab.shape-length")}</>,
                Type.length,
              ],
              shape_area: [
                () => <>{i18n.t("info-tab.shape-area")}</>, 
                Type.area_m2
              ],
              land_status: [() => <>{i18n.t("info-tab.land-status")}</>],
              date_end: [()=> <>{i18n.t("info-tab.date-moved")}</>, Type.date]
            },
          },
        ],
      },
    ]
  },

  part_archive: {
    getTitle: props =>
      (props.kad_nomer ? props.kad_nomer : "") +
      (props.address_rus ? ", " + props.address_ru : "") +
      (props.rka ? ", РКА " + props.rka : "") +
      (props.desc ? ", (" + props.desc + ")" : ""),
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              kad_nomer: [() => <>{i18n.t("info-tab.kad-num")}</>],
              part_num: [() => <>{i18n.t("info-tab.part-num")}</>],
              part_type_name : [() => <>{i18n.t("info-tab.part-type-id")}</>],
              address_ru: [() => <>{i18n.t("info-tab.address")}</>],
              category_ru: [() => <>{i18n.t("info-tab.land-category")}</>],
              pravo_ru: [() => <>{i18n.t("info-tab.law-type")}</>],
              tsn_ru: [() => <>{i18n.t("info-tab.spec-purps")}</>],
              shape_length: [
                () => <>{i18n.t("info-tab.shape-length")}</>,
                Type.length,
              ],
              shape_area: [
                () => <>{i18n.t("info-tab.shape-area")}</>, 
                Type.area_m2
              ],
              land_status: [() => <>{i18n.t("info-tab.land-status")}</>]
            },
          },
        ],
      }
    ]
  },

  sogl: {
    getTitle: props =>
      (props.ident_land ? props.ident_land : "") +
      (props.adress_rus ? ", " + props.adress_rus : "") +
      (props.rka ? ", РКА " + props.rka : "") + 
      (props.desc ? ", (" + props.desc + ")" : ""),
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              ident_land: [() => <>{i18n.t("info-tab.ident-land")}</>],
              nom_plan: [() => <>{i18n.t("info-tab.nom_plan")}</>],
              kad_nomer: [() => <>{i18n.t("info-tab.kad-num")}</>],
              nom_akt: [() => <>{i18n.t("info-tab.nom-act")}</>],
              partInfo: [() => <>{i18n.t("info-tab.part-info")}</>],
              zakazchik: [() => <>{i18n.t("info-tab.zakazchik")}</>],
              datecreated: [() => <>{i18n.t("info-tab.date-created")}</>, Type.date],
              datemoved: [() => <>{i18n.t("info-tab.date-moved")}</>, Type.date],
              cuser: [() => <>{i18n.t("info-tab.cuser")}</>],
              uuser: [() => <>{i18n.t("info-tab.uuser")}</>],
              udate: [() => <>{i18n.t("info-tab.udate")}</>, Type.date],
              category: [() => <>{i18n.t("info-tab.land-category")}</>],
              date_razrab: [() => <>{i18n.t("info-tab.date-razrab")}</>, Type.date],
              razrab: [() => <>{i18n.t("info-tab.developer")}</>],
              iin_razrab: [() => <>{i18n.t("info-tab.developer-iin")}</>],
              iin_sobstvennika: [() => <>{i18n.t("info-tab.owner-iin")}</>],
              sobstvennik: [() => <>{i18n.t("info-tab.sobstvennik")}</>],
              tsn_rus: [() => <>{i18n.t("info-tab.tsn_rus")}</>],
              tsn_kaz: [() => <>{i18n.t("info-tab.tsn_kaz")}</>],
              adress_rus: [() => <>{i18n.t("info-tab.adress_rus")}</>],
              adress_kaz: [() => <>{i18n.t("info-tab.adress_kaz")}</>],
              sogl_type: [() => <>{i18n.t("info-tab.sogl-type")}</>],
              nom_zayavka: [() => <>{i18n.t("info-tab.nom-zayavka")}</>],
              date_zayavka: [() => <>{i18n.t("info-tab.date-zayavka")}</>, Type.date],
              prim: [() => <>{i18n.t("info-tab.prim")}</>],
              date_sogl: [() => <>{i18n.t("info-tab.date-sogl")}</>, Type.date],
              squ: [() => <>{i18n.t("info-tab.squ")}</>, Type.area_m2],
              shape_area: [() => <>{i18n.t("info-tab.shape-area-sogl")}</>,  Type.area_m2],
              shape_length: [() => <>{i18n.t("info-tab.shape-length-sogl")}</>, Type.length],
              status_rus: [() => <>{i18n.t("info-tab.status-rus")}</>], 
              status_kaz: [() => <>{i18n.t("info-tab.status-kaz")}</>]
            },
          },
        ],
      }
    ]
  },

  sogl_agreed: {
    getTitle: props =>
      (props.ident_land ? props.ident_land : "") +
      (props.adress_rus ? ", " + props.adress_rus : "") +
      (props.rka ? ", РКА " + props.rka : "") + 
      (props.desc ? ", (" + props.desc + ")" : ""),
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              ident_land: [() => <>{i18n.t("info-tab.ident-land")}</>],
              nom_plan: [() => <>{i18n.t("info-tab.nom_plan")}</>],
              kad_nomer: [() => <>{i18n.t("info-tab.kad-num")}</>],
              nom_akt: [() => <>{i18n.t("info-tab.nom-act")}</>],
              partInfo: [() => <>{i18n.t("info-tab.part-info")}</>],
              zakazchik: [() => <>{i18n.t("info-tab.zakazchik")}</>],
              datecreated: [() => <>{i18n.t("info-tab.date-created")}</>, Type.date],
              datemoved: [() => <>{i18n.t("info-tab.date-moved")}</>, Type.date],
              cuser: [() => <>{i18n.t("info-tab.cuser")}</>],
              uuser: [() => <>{i18n.t("info-tab.uuser")}</>],
              udate: [() => <>{i18n.t("info-tab.udate")}</>, Type.date],
              category: [() => <>{i18n.t("info-tab.land-category")}</>],
              date_razrab: [() => <>{i18n.t("info-tab.date-razrab")}</>, Type.date],
              razrab: [() => <>{i18n.t("info-tab.developer")}</>],
              iin_razrab: [() => <>{i18n.t("info-tab.developer-iin")}</>],
              iin_sobstvennika: [() => <>{i18n.t("info-tab.owner-iin")}</>],
              sobstvennik: [() => <>{i18n.t("info-tab.sobstvennik")}</>],
              tsn_rus: [() => <>{i18n.t("info-tab.tsn_rus")}</>],
              tsn_kaz: [() => <>{i18n.t("info-tab.tsn_kaz")}</>],
              adress_rus: [() => <>{i18n.t("info-tab.adress_rus")}</>],
              adress_kaz: [() => <>{i18n.t("info-tab.adress_kaz")}</>],
              sogl_type: [() => <>{i18n.t("info-tab.sogl-type")}</>],
              nom_zayavka: [() => <>{i18n.t("info-tab.nom-zayavka")}</>],
              date_zayavka: [() => <>{i18n.t("info-tab.date-zayavka")}</>, Type.date],
              prim: [() => <>{i18n.t("info-tab.prim")}</>],
              date_sogl: [() => <>{i18n.t("info-tab.date-sogl")}</>, Type.date],
              squ: [() => <>{i18n.t("info-tab.squ")}</>, Type.area_m2],
              shape_area: [() => <>{i18n.t("info-tab.shape-area-sogl")}</>,  Type.area_m2],
              shape_length: [() => <>{i18n.t("info-tab.shape-length-sogl")}</>, Type.length],
              status_rus: [() => <>{i18n.t("info-tab.status-rus")}</>], 
              status_kaz: [() => <>{i18n.t("info-tab.status-kaz")}</>], 
            },
          },
        ],
      }
    ]
  },

  sogl_rejected: {
    getTitle: props =>
      (props.ident_land ? props.ident_land : "") +
      (props.adress_rus ? ", " + props.adress_rus : "") +
      (props.rka ? ", РКА " + props.rka : "") + 
      (props.desc ? ", (" + props.desc + ")" : ""),
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              ident_land: [() => <>{i18n.t("info-tab.ident-land")}</>],
              nom_plan: [() => <>{i18n.t("info-tab.nom_plan")}</>],
              kad_nomer: [() => <>{i18n.t("info-tab.kad-num")}</>],
              nom_akt: [() => <>{i18n.t("info-tab.nom-act")}</>],
              partInfo: [() => <>{i18n.t("info-tab.part-info")}</>],
              zakazchik: [() => <>{i18n.t("info-tab.zakazchik")}</>],
              datecreated: [() => <>{i18n.t("info-tab.date-created")}</>, Type.date],
              datemoved: [() => <>{i18n.t("info-tab.date-moved")}</>, Type.date],
              cuser: [() => <>{i18n.t("info-tab.cuser")}</>],
              uuser: [() => <>{i18n.t("info-tab.uuser")}</>],
              udate: [() => <>{i18n.t("info-tab.udate")}</>, Type.date],
              category: [() => <>{i18n.t("info-tab.land-category")}</>],
              date_razrab: [() => <>{i18n.t("info-tab.date-razrab")}</>, Type.date],
              razrab: [() => <>{i18n.t("info-tab.developer")}</>],
              iin_razrab: [() => <>{i18n.t("info-tab.developer-iin")}</>],
              iin_sobstvennika: [() => <>{i18n.t("info-tab.owner-iin")}</>],
              sobstvennik: [() => <>{i18n.t("info-tab.sobstvennik")}</>],
              tsn_rus: [() => <>{i18n.t("info-tab.tsn_rus")}</>],
              tsn_kaz: [() => <>{i18n.t("info-tab.tsn_kaz")}</>],
              adress_rus: [() => <>{i18n.t("info-tab.adress_rus")}</>],
              adress_kaz: [() => <>{i18n.t("info-tab.adress_kaz")}</>],
              sogl_type: [() => <>{i18n.t("info-tab.sogl-type")}</>],
              nom_zayavka: [() => <>{i18n.t("info-tab.nom-zayavka")}</>],
              date_zayavka: [() => <>{i18n.t("info-tab.date-zayavka")}</>, Type.date],
              prim: [() => <>{i18n.t("info-tab.prim")}</>],
              date_sogl: [() => <>{i18n.t("info-tab.date-sogl")}</>, Type.date],
              squ: [() => <>{i18n.t("info-tab.squ")}</>, Type.area_m2],
              shape_area: [() => <>{i18n.t("info-tab.shape-area-sogl")}</>,  Type.area_m2],
              shape_length: [() => <>{i18n.t("info-tab.shape-length-sogl")}</>, Type.length],
              status_rus: [() => <>{i18n.t("info-tab.status-rus")}</>], 
              status_kaz: [() => <>{i18n.t("info-tab.status-kaz")}</>], 
            },
          },
        ],
      }
    ]
  },

  sogl_registered: {
    getTitle: props =>
      (props.ident_land ? props.ident_land : "") +
      (props.adress_rus ? ", " + props.adress_rus : "") +
      (props.rka ? ", РКА " + props.rka : "") +
      (props.desc ? ", (" + props.desc + ")" : ""),
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              ident_land: [() => <>{i18n.t("info-tab.ident-land")}</>],
              nom_plan: [() => <>{i18n.t("info-tab.nom_plan")}</>],
              kad_nomer: [() => <>{i18n.t("info-tab.kad-num")}</>],
              nom_akt: [() => <>{i18n.t("info-tab.nom-act")}</>],
              partInfo: [() => <>{i18n.t("info-tab.part-info")}</>],
              zakazchik: [() => <>{i18n.t("info-tab.zakazchik")}</>],
              datecreated: [() => <>{i18n.t("info-tab.date-created")}</>, Type.date],
              datemoved: [() => <>{i18n.t("info-tab.date-moved")}</>, Type.date],
              cuser: [() => <>{i18n.t("info-tab.cuser")}</>],
              uuser: [() => <>{i18n.t("info-tab.uuser")}</>],
              udate: [() => <>{i18n.t("info-tab.udate")}</>, Type.date],
              category: [() => <>{i18n.t("info-tab.land-category")}</>],
              date_razrab: [() => <>{i18n.t("info-tab.date-razrab")}</>, Type.date],
              razrab: [() => <>{i18n.t("info-tab.developer")}</>],
              iin_razrab: [() => <>{i18n.t("info-tab.developer-iin")}</>],
              iin_sobstvennika: [() => <>{i18n.t("info-tab.owner-iin")}</>],
              sobstvennik: [() => <>{i18n.t("info-tab.sobstvennik")}</>],
              tsn_rus: [() => <>{i18n.t("info-tab.tsn_rus")}</>],
              tsn_kaz: [() => <>{i18n.t("info-tab.tsn_kaz")}</>],
              adress_rus: [() => <>{i18n.t("info-tab.adress_rus")}</>],
              adress_kaz: [() => <>{i18n.t("info-tab.adress_kaz")}</>],
              sogl_type: [() => <>{i18n.t("info-tab.sogl-type")}</>],
              nom_zayavka: [() => <>{i18n.t("info-tab.nom-zayavka")}</>],
              date_zayavka: [() => <>{i18n.t("info-tab.date-zayavka")}</>, Type.date],
              prim: [() => <>{i18n.t("info-tab.prim")}</>],
              date_sogl: [() => <>{i18n.t("info-tab.date-sogl")}</>, Type.date],
              squ: [() => <>{i18n.t("info-tab.squ")}</>, Type.area_m2],
              shape_area: [() => <>{i18n.t("info-tab.shape-area-sogl")}</>,  Type.area_m2],
              shape_length: [() => <>{i18n.t("info-tab.shape-length-sogl")}</>, Type.length],
              status_rus: [() => <>{i18n.t("info-tab.status-rus")}</>], 
              status_kaz: [() => <>{i18n.t("info-tab.status-kaz")}</>], 
            },
          },
        ],
      }
    ]
  },

  ul: {
    getTitle: props => (props.kad_nomer ? props.kad_nomer : "") +
      (props.address_ru ? ", " + props.address_ru : "") +
      (props.rka ? ", РКА " + props.rka : "") + 
      (props.desc ? ", (" + props.desc + ")" : ""),
    tabs: [
      {
        id: 'info',
        getTitle: () => <>aaa</>,
        groups: [
          {
            props: {
              kad_nomer: [() => <>{i18n.t("info-tab.kad-num")}</>],
              address_ru: [() => <>{i18n.t("info-tab.address")}</>],
              category_ru: [() => <>{i18n.t("info-tab.land-category")}</>],
              pravo_ru: [() => <>{i18n.t("info-tab.law-type")}</>],
              tsn_ru: [() => <>{i18n.t("info-tab.spec-purps")}</>],
              shape_length: [
                () => <>{i18n.t("info-tab.shape-length")}</>,
                Type.length,
              ],
              land_status: [() => <>{i18n.t("info-tab.land-status")}</>],
              date_end: [()=> <>{i18n.t("info-tab.date-moved")}</>, Type.date]
            },
          },
        ],
      },
      {
        id: 'archive',
        getTitle: () => <>{i18n.t("info-tab.archive")}</>,
        getItems: props => props.childs,
        groups: [
          {
            getTitle: () => <>{i18n.t("info-tab.archive")}</>, 
            getChild: props => props.date_end,
            props: {
              geometry:  [() => <>{i18n.t("object-info-table.2")}</>, Type.geom_text],
              kad_nomer: [() => <>{i18n.t("info-tab.kad-num")}</>],
              address_ru: [() => <>{i18n.t("info-tab.address")}</>],
              category_ru: [() => <>{i18n.t("info-tab.land-category")}</>],
              pravo_ru: [() => <>{i18n.t("info-tab.law-type")}</>],
              tsn_ru: [() => <>{i18n.t("info-tab.spec-purps")}</>],
              shape_length: [
                () => <>{i18n.t("info-tab.shape-length")}</>,
                Type.length,
              ],
              land_status: [() => <>{i18n.t("info-tab.land-status")}</>],
              date_end: [()=> <>{i18n.t("info-tab.date-moved")}</>, Type.date]
            },
          },
        ],
      },
    ],
  },

  ul_archive: {
    getTitle: props => (props.kad_nomer ? props.kad_nomer : "") +
      (props.address_rus ? ", " + props.address_ru : "") +
      (props.rka ? ", РКА " + props.rka : "") +
      (props.desc ? ", (" + props.desc + ")" : ""),
    tabs: [
      {
        id: 'info',
        getTitle: () => <>aaa</>,
        groups: [
          {
            props: {
              kad_nomer: [() => <>{i18n.t("info-tab.kad-num")}</>],
              address_ru: [() => <>{i18n.t("info-tab.address")}</>],
              category_ru: [() => <>{i18n.t("info-tab.land-category")}</>],
              pravo_ru: [() => <>{i18n.t("info-tab.law-type")}</>],
              tsn_ru: [() => <>{i18n.t("info-tab.spec-purps")}</>],
              shape_length: [
                () => <>{i18n.t("info-tab.shape-length")}</>,
                Type.length,
              ],
              land_status: [() => <>{i18n.t("info-tab.land-status")}</>]
            },
          },
        ],
      },
    ],
  },

  tbo: {
    getTitle: props => "Отходы " + props.OBJECTID,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              "SHAPE.STArea()": [() => <>{i18n.t("info-tab.shape-length")}</>, Type.area_m2],
              "SHAPE.STLength()": [() => <>{i18n.t("info-tab.shape-area")}</>, Type.length],
              Tip_othoda2: [() => <>{i18n.t("info-tab.waste-type")}</>, Type.othodi],
            },
          },
        ],
      },
    ],
  },

  agro: {
    getTitle: props => i18n.t("info-tab.agro") + props.OBJECTID,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              SHAPE_Area: [() => <>{i18n.t("info-tab.shape-area")}</>, Type.area_m2],
              SHAPE_Length: [() => <>{i18n.t("info-tab.shape-length")}</>, Type.length],
              YEAR: [() => <>{i18n.t("info-tab.year")}</>],
              SOWING_DATE: [() => <>{i18n.t("info-tab.sowing-date")}</>, Type.esriDate],
              harvest_date: [() => <>{i18n.t("info-tab.harvest-date")}</>, Type.esriDate],
            },
          },
        ],
      },
    ],
  },

  tsel: {
    getTitle: props => "Использование земель " + props.KAD_NOMER,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              NAIMENOVANIE: [() => <>{i18n.t("info-tab.name")}</>],
              ZEM_HA: [() => <>{i18n.t("info-tab.arable-balance")}</>, Type.area_m2],
              DZZ_GEN_HA: [() => <>{i18n.t("info-tab.arable-ers")}</>, Type.area_m2],
              DZZ_HA: [() => <>{i18n.t("info-tab.arable-cerac")}</>, Type.area_m2],
              COMMENT: [() => <>{i18n.t("info-tab.comment")}</>],
              Field_Use_Condition: [() => <>{i18n.t("info-tab.state-land-acc")}</>, Type.dict, {
                1: "Целевое использование земель",
                2: "Нецелевое использование земель",
                3: "Прочее"
              }],
              Land_Lot_Situation: [() => <>{i18n.t("info-tab.situation-site")}</>, Type.dict, {
                1: "Пашня",
                2: "Пастбище",
                3: "Сенокос",
                4: "Нарушение границ сельскохозяйственных угодий",
                5: "Нет данных в земельном балансе",
                6: "Расхождение данных АИС ГЗК и Земельного баланса",
                7: "Неиспользуемые земли"
              }],
              Full_name: [() => <>Ф.И.О.</>],
              Condition: [() => <>{i18n.t("info-tab.land-state")}</>, Type.dict, {
                1: "Подтверждено",
                2: "Не подтверждено"
              }],
              Description_Commentary: [() => <>{i18n.t("info-tab.incpector-comment")}</>],
              SHAPE_Area: [() => <>{i18n.t("info-tab.shape-area")}</>, Type.area_m2],
              SHAPE_Length: [() => <>{i18n.t("request.perimetr")}</>, Type.length]
            },
          },
        ],
      },
    ],
  },

  season_dyn: {
    getTitle: props => "Сезонная динамика " + props.OBJECTID,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              "SHAPE.STArea()": [() => <>{i18n.t("info-tab.shape-area")}</>, Type.area_m2],
              "SHAPE.STLength()": [() => <>{i18n.t("request.perimetr")}</>, Type.length],
              Period: [() => <>{i18n.t("info-tab.period")}</>],
              Start_date: [() => <>{i18n.t("info-tab.beginning-date")}</>, Type.esriDate],
            },
          },
        ],
      },
    ],
  },

  fire_dept: {
    getTitle: props => "Пожарная часть " + props.OBJECTID,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              Naimenovanie: [() => <>{i18n.t("info-tab.name")}</>],
              Yur_adres: [() => <>Юр. адрес</>],
              name: [() => <>{i18n.t("info-tab.name")}</>],
              adres: [() => <>Юр. адрес</>],
            },
          },
        ],
      },
    ],
  },

  deforested: {
    getTitle: props => "Вырубки лесов " + props.OBJECTID,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              Shape_Area: [() => <>{i18n.t("info-tab.shape-area")}</>, Type.area_m2],
              Shape_Length: [() => <>{i18n.t("request.perimetr")}</>, Type.length],
              Законность: [() => <>Законность</>],
              Дата_билета: [() => <>Дата билета</>],
              Дата_сьемки_вырубки: [() => <>Дата сьемки вырубки</>],
              Дата_сьемки_леса: [() => <>Дата сьемки леса</>],
              Квартал: [() => <>Квартал</>],
              Лесничество: [() => <>Лесничество</>],
              Лесное_учереждение: [() => <>Лесное учереждение</>],
              Лесорубочный_билет: [() => <>Лесорубочный билет</>],
              Номер_билета: [() => <>Номер билета</>],
              Породы_по_билету: [() => <>Породы по билету</>],
            },
          },
        ],
      },
    ],
  },

  forests_DZZ: {
    getTitle: props => "Леса " + props.OBJECTID_12,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              Shape_Area: [() => <>{i18n.t("info-tab.shape-area")}</>, Type.area_m2],
              Shape_Length: [() => <>{i18n.t("request.perimetr")}</>, Type.length],
              Данны: [() => <>{i18n.t("info-tab.data")}</>],
            },
          },
        ],
      },
    ],
  },

  free: {
    getTitle: props => 
          props.id + (props.address_rus ? ", " + props.address_rus : '') + 
          (props.desc ? ", (" + props.desc + ")" : ""),
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              id: [() => <>ID</>],
              // address_rus: [() => 
              //   <>
              //     {i18n.t("new-obj.address")}
              //     <br />
              //     ({i18n.t("info-tab.location")})
              //   </>,
              // ],
              address_rus: [() => <>{i18n.t("info-tab.location-desc-rus")}</>],
              address_kaz: [() => <>{i18n.t("info-tab.location-desc-kaz")}</>],
              restrict_burd_rus: [() => <>{i18n.t("info-tab.limit")}</>],
              participation_cost: [() => <>{i18n.t("info-tab.margin")}</>, Type.cost_kzt],
              auction_method_name_ru: [() => <>{i18n.t("info-tab.auction-method")}</>],
              seller_name_rus: [() => <>{i18n.t("info-tab.seller")}</>],
              inst_selling: [() => <>{i18n.t("info-tab.installment")}</>, Type.checkInst],
              inst_period: [() => <>{i18n.t("info-tab.installment-period")}</>],
              lot_number: [() => <>{i18n.t("info-tab.etp-lot")}</>],
              lot_number_rgis: [() => <>{i18n.t("info-tab.rgis-lot")}</>],
              land_status_name_ru: [() => <>{i18n.t("info-tab.land-status")}</>],
              land_category_name: [() => <>{i18n.t("info-tab.land-category")}</>],
              purpose_name_rus: [() => <>{i18n.t("new-obj.purpose")}</>],
              func_name_ru: [() => <>{i18n.t("info-tab.funct-purps")}</>],
              usedesc_ru: [() => <>{i18n.t("info-tab.tsn_rus")}</>],
              usedesc_kz: [() => <>{i18n.t("info-tab.tsn_kaz")}</>],
              divisibility_name_ru: [() => <>{i18n.t("info-tab.divisibility")}</>],
              area: [() => <>{i18n.t("info-tab.area-actually")}</>, Type.area_m2],
              squ: [() => <>{i18n.t("info-tab.area-doc")}</>, Type.area_m2],
              pvp_name_ru: [() => <>{i18n.t("info-tab.pravo-name")}</>],
              rent_condition_rus: [() => <>{i18n.t("info-tab.rental-condition")}</>],
              temporary_period: [() => <>{i18n.t("info-tab.temporary-period")}</>],
              rent_lease: [() => <>{i18n.t("info-tab.rental-period")}</>, Type.date],
              cadastre_cost: [() => <>{i18n.t("info-tab.cad-cost")}</>, Type.cost_kzt],
              start_cost: [() => <>{i18n.t("info-tab.init-cost")}</>, Type.cost_kzt],
              tax_cost: [() => <>{i18n.t("info-tab.tax-cost")}</>, Type.cost_kzt],
              auction_time: [() => <>{i18n.t("info-tab.auction-date")}</>],
              auction_place_rus: [() => <>{i18n.t("info-tab.auction-location")}</>],
              request_address_rus: [() => <>{i18n.t("info-tab.app-address")}</>],
              publish_date: [() => <>{i18n.t("info-tab.pub-date")}</>, Type.date],
              comment_rus: [() => <>{i18n.t("info-tab.description")}</>],
              // placedesc_ru: [() => <>{i18n.t("info-tab.location-desc-rus")}</>],
              // placedesc_kz: [() => <>{i18n.t("info-tab.location-desc-kaz")}</>],
              request_date: [()=> <>Дата и время запроса</>, Type.egov_date],
              response_date: [()=> <>Дата и время ответа</>, Type.egov_date],
              chain_id:  [() => <>Номер запроса ПЭП</>],
            },
          },
        ],
      },

      {
        id: 'docs',
        getTitle: () => <>{i18n.t("zkr.docs")}</>,
        getItems: props => props.docs,
        groups: [
          {
            props: {
              file_name: [<></>, Type.link],
            },
          },
        ],
      },
      {
        id: 'specification',
        getTitle: () => <>ТУ</>,
        groups: [
          {
            getTitle: () => <>{i18n.t("info-tab.power-supply")}</>,
            props: {
              electr_power: [() => <>{i18n.t("info-tab.power-limit")}</>,  Type.capacity_kwt],
              is_electr_phaze_1: [() => <>{i18n.t("info-tab.type-load-1")}</>],
              is_electr_phaze_3: [() => <>{i18n.t("info-tab.type-load-3")}</>],
              is_electr_constant: [() => <>{i18n.t("info-tab.type-load-const")}</>],
              is_electr_temp: [() => <>{i18n.t("info-tab.type-load-temp")}</>],
              is_electr_season: [() => <>{i18n.t("info-tab.type-load-season")}</>],
              electr_safety_category: [() => <>{i18n.t("info-tab.category-safety")}</>],
              electr_safety_1: [() => <>{i18n.t("info-tab.category-safety-1")}</> ,Type.capacity_kwt],
              electr_safety_2: [() => <>{i18n.t("info-tab.category-safety-2")}</> ,Type.capacity_kwt],
              electr_safety_3: [() => <>{i18n.t("info-tab.category-safety-3")}</> ,Type.capacity_kwt],
              loadRecords: {
                getTitle: () => <>{i18n.t("info-tab.max-load")}</>,
                props: {
                  table: [() => <>{i18n.t("info-tab.max-load")}</>, Type.table]
                }
              },
              electr_receivers_1: [() => <>{i18n.t("info-tab.power-max-load-1")},</> ,Type.capacity_kwt],
              electr_receivers_2: [() => <>{i18n.t("info-tab.power-max-load-2")}</> ,Type.capacity_kwt],
              electr_receivers_3: [() => <>{i18n.t("info-tab.power-max-load-3")}</> ,Type.capacity_kwt],
              electrDevices: {
                getTitle: () => <>{i18n.t("info-tab.electr-devices")}</>,
                props: {
                  table: [() => <>{i18n.t("info-tab.electr-devices")}</>, Type.table]
                }
              },
              electr_exist_load: [() => <>{i18n.t("info-tab.electr-exist-load")}</> ,Type.capacity_kwt],
              electrTransforms: {
                getTitle: () => <>{i18n.t("info-tab.electr-transforms")}</>,
                props: {
                  table: [() => <>{i18n.t("info-tab.electr-transforms")}</>, Type.table]
                }
              },
              electr_source: [() => <>{i18n.t("info-tab.electr-source")}</>],
              electr_conn_point: [() => <>{i18n.t("info-tab.electr-conn-point")}</>],
            },
          },
          {
            getTitle: () => <>{i18n.t("info-tab.water")}</>,
            props: {
              water_power: [() => <>{i18n.t("info-tab.water-power")}</>, Type.capacity_m3_day],
              water_power_hour: [() => <>{i18n.t("info-tab.water-power")}</>, Type.capacity_m3_h],
              water_power_sec: [() => <>{i18n.t("info-tab.water-power")}</>, Type.capacity_l_sec],
              water_hoz: [() => <>{i18n.t("info-tab.water-hoz")}</>, Type.capacity_m3_day],
              water_hoz_hour:  [() => <>{i18n.t("info-tab.water-hoz")}</>, Type.capacity_m3_h],
              water_hoz_sec:  [() => <>{i18n.t("info-tab.water-hoz")}</>, Type.capacity_l_sec],
              water_prod:  [() => <>{i18n.t("info-tab.water-prod")}</>, Type.capacity_m3_day],
              water_prod_hour:  [() => <>{i18n.t("info-tab.water-prod")}</>, Type.capacity_m3_h],
              water_prod_sec:  [() => <>{i18n.t("info-tab.water-prod")}</>, Type.capacity_l_sec],
              water_fire:  [() => <>{i18n.t("info-tab.water-fire")}</>, Type.capacity_m3_day],
              water_fire_sec:  [() => <>{i18n.t("info-tab.water-fire")}</>, Type.capacity_l_sec],
              water_hoz_war_pressure:  [() => <>{i18n.t("info-tab.water-hoz-war-pressure")}</>, Type.capacity_mpa],
              water_conn_text: [() => <>{i18n.t("info-tab.water-conn-text")}</>],
            },
          },
          {
            getTitle: () => <>{i18n.t("info-tab.sewerage")}</>,
            props: {
              sewerage_power: [() => <>{i18n.t("info-tab.sewerage-power")}</>, Type.capacity_m3_day],
              sewerage_power_hour: [() => <>{i18n.t("info-tab.sewerage-power")}</>, Type.capacity_m3_h],
              sewerage_fecal: [() => <>{i18n.t("info-tab.sewerage-fecal")}</>, Type.capacity_m3_day],
              sewerage_fecal_hour: [() => <>{i18n.t("info-tab.sewerage-fecal")}</>, Type.capacity_m3_h],
              sewerage_prod: [() => <>{i18n.t("info-tab.sewerage-prod")}</>, Type.capacity_m3_day],
              sewerage_prod_hour: [() => <>{i18n.t("info-tab.sewerage-prod")}</>, Type.capacity_m3_h],
              sewerage_clean: [() => <>{i18n.t("info-tab.sewerage-clean")}</>, Type.capacity_m3_day],
              sewerage_clean_hour: [() => <>{i18n.t("info-tab.sewerage-clean")}</>, Type.capacity_m3_h],
              sewerage_discharge_sink: [() => <>{i18n.t("info-tab.sewerage-discharge-sink")}</>],
            },
          },
          {
            getTitle: () => <>{i18n.t("info-tab.heat")}</>,
            props: {
              heat_power: [() => <>{i18n.t("info-tab.heat-power")}</>, Type.capacity_gcal_h],
              heat_firing:  [() => <>{i18n.t("info-tab.heat-firing")}</>, Type.capacity_gcal_h],
              heat_ventilation: [() => <>{i18n.t("info-tab.heat-ventilation")}</>, Type.capacity_gcal_h],
              heat_hot_water: [() => <>{i18n.t("info-tab.heat-hot-water")}</>, Type.capacity_gcal_h],
              heat_tech_need: [() => <>{i18n.t("info-tab.heat-tech-need")}</>, Type.capacity_t_h],
              heat_share_load: [() => <>{i18n.t("info-tab.heat-share-load")}</>],
              heat_energy_event: [() => <>{i18n.t("info-tab.heat-energy-event")}</>],
              heat_source: [() => <>{i18n.t("info-tab.heat-source")}</>],
              heat_conn_point: [() => <>{i18n.t("info-tab.heat-conn-point")}</>],
            },
          },
          {
            getTitle: () => <>{i18n.t("info-tab.telekom")}</>,
            props: {
              telekom_count_ota: [() => <>{i18n.t("info-tab.telekom-count-ota")}</>],
              telekom_volume: [() => <>{i18n.t("info-tab.telekom-volume")}</>],
              telekom_plan_sewerage: [() => <>{i18n.t("info-tab.telekom-plan-sewerage")}</>],
              telekom_wish: [() => <>{i18n.t("info-tab.telekom-wish")}</>],
            },
          },
          {
            getTitle: () => <>{i18n.t("info-tab.gas")}</>,
            props: {
              gas_power: [() => <>{i18n.t("info-tab.gas-power")}</>, Type.capacity_m3_h],
              gas_on_cooking: [() => <>{i18n.t("info-tab.gas-on-cooking")}</>, Type.capacity_m3_h],
              gas_heating: [() => <>{i18n.t("info-tab.gas-heating")}</>, Type.capacity_m3_h],
              gas_ventilation: [() => <>{i18n.t("info-tab.heat-ventilation")}</>, Type.capacity_m3_h],
              gas_conditioning: [() => <>{i18n.t("info-tab.gas-conditioning")}</>, Type.capacity_m3_h],
              gas_hot_water: [() => <>{i18n.t("info-tab.gas-hot-water")}</>,Type.capacity_m3_h],
              gas_pipeline: [() => <>{i18n.t("info-tab.gas-pipeline")}</>],
              gas_pipeline_diameter: [() => <>{i18n.t("info-tab.gas-pipeline-diameter")}</>,Type.diameter_mm],
              gas_pipeline_pressure: [() => <>{i18n.t("info-tab.gas-pipeline-pressure")}</>, Type.capacity_mpa],
            },
          },
          {
            getTitle: () => <>{i18n.t("info-tab.other")}</>,
            props: {
              quality_comp: [() => <>{i18n.t("info-tab.quality-comp")}</>],
              is_storm_water: [() => <>{i18n.t("info-tab.is-storm-water")}</>],
              storm_water_text: [() => <>{i18n.t("info-tab.storm-water-text")}</>],
              storm_power: [() => <>{i18n.t("info-tab.sewerage-power")}</>, Type.area_m3],
              storm_conn_point: [() => <>{i18n.t("info-tab.storm-conn-point")}</>],
            },
          },
        ],
      },
    ],
  },

  buildings: {
    getTitle: props => props.kad_nomer || props.address_ru || i18n.t("info-tab.building"),
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              rca: [() => <>РКА</>],
              address_ru: [() => 
                <>
                  {i18n.t("new-obj.address")}
                  <br />
                  ({i18n.t("info-tab.location")})
                </>,
              ],
              floors: [() => <>{i18n.t("info-tab.floors")}</>],
              txIsZhiloe: [() => 
                <>
                  {i18n.t("info-tab.category")}
                  <br />
                  {i18n.t("info-tab.fund")}
                </>,
              ],
              //litera: [() => <>Литера</>],
              celRu: [() => 
                <>
                  {i18n.t("info-tab.func-use-1")}
                  <br />
                  {i18n.t("info-tab.func-use-2")}
                </>,
              ],
              shape_length: [() => 
                <>
                  {i18n.t("info-tab.perimetr-1")}
                  <br />
                  {i18n.t("info-tab.perimetr-2")}
                </>,
                Type.length,
              ],
              shape_area: [() => <>{i18n.t("info-tab.shape-area")}</>, Type.area_m2only],
            },
          },
        ],
      },
    ],
  },

  eval: {
    getTitle: props => "Оценочная зона " + props.zonenumber, //sort by zonenumber
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              zonenumber: [() => <>{i18n.t("info-tab.zone-num")}</>],
              price: [() => <>{i18n.t("info-tab.base-rate")}</>, Type.cost_kzt],
              coef: [() => <>{i18n.t("info-tab.correction-factor")}</>],
              shape_leng: [() => 
                <>
                  {i18n.t("info-tab.perimetr-1")}
                  <br />
                  {i18n.t("info-tab.perimetr-2")}
                </>,
                Type.length,
              ],
              shape_area: [() => <>{i18n.t("info-tab.shape-area")}</>, Type.area_m2],
            },
          },
        ],
      },
    ],
  },

  req: {
    getTitle: props => 
          props.id + 
          (props.address_ru ? ", " + props.address_ru : '') + 
          (props.desc ? ", (" + props.desc + ")" : ""),
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              id: [() => <>{i18n.t("info-tab.identifier")}</>],
              address_ru: [() => <>{i18n.t("info-tab.addr")}</>],
              right_type_name_ru: [() => <>{i18n.t("info-tab.law-type")}</>],
              land_cat_name_ru: [() => <>{i18n.t("info-tab.land-category")}</>],
              purp_name_ru: [() => <>{i18n.t("new-obj.purpose")}</>],
              func_name_ru: [() => <>{i18n.t("info-tab.funct-purps")}</>],
              usedesc_ru: [() => <>{i18n.t("info-tab.tsn_rus")}</>],
              usedesc_kz: [() => <>{i18n.t("info-tab.tsn_kaz")}</>],

              land_div_name_ru: [() => <>{i18n.t("info-tab.divisibility")}</>],
              shape_area: [() => <>{i18n.t("info-tab.area-actually")}</>, Type.area_m2],
              squ: [() => <>{i18n.t("info-tab.area-doc")}</>, Type.area_m2],
              shape_leng: [() => <>{i18n.t("request.perimetr")}</>, Type.length],
              

              land_status_name_ru: [() => <> {i18n.t("info-tab.land-status")}</>],
              //datecreate: [<>Дата создания</>],
              placedesc_ru: [() => <>{i18n.t("info-tab.location-desc-rus")}</>],
              placedesc_kz: [() => <>{i18n.t("info-tab.location-desc-kaz")}</>],
              
              temporary_period: [() => <>{i18n.t("info-tab.temporary-period")}</>],
              request_date: [()=> <>Дата и время запроса</>, Type.egov_date],
              response_date: [()=> <>Дата и время ответа</>, Type.egov_date],
              chain_id:  [() => <>Номер запроса ПЭП</>],
            },
          },
        ],
      },
      {
        id: 'docs',
        getTitle: () => <>{i18n.t("zkr.docs")}</>,
        getItems: props => props.docs,
        groups: [
          {
            props: {
              file_name: [<></>, Type.link],
            },
          },
        ],
      },
      {
        id: 'specification',
        getTitle: () => <>ТУ</>,
        groups: [
          {
            getTitle: () => <>{i18n.t("info-tab.power-supply")}</>,
            props: {
              electr_power: [() => <>{i18n.t("info-tab.power-limit")}</>,  Type.capacity_kwt],
              is_electr_phaze_1: [() => <>{i18n.t("info-tab.type-load-1")}</>],
              is_electr_phaze_3: [() => <>{i18n.t("info-tab.type-load-3")}</>],
              is_electr_constant: [() => <>{i18n.t("info-tab.type-load-const")}</>],
              is_electr_temp: [() => <>{i18n.t("info-tab.type-load-temp")}</>],
              is_electr_season: [() => <>{i18n.t("info-tab.type-load-season")}</>],
              electr_safety_category: [() => <>{i18n.t("info-tab.category-safety")}</>],
              electr_safety_1: [() => <>{i18n.t("info-tab.category-safety-1")}</> ,Type.capacity_kwt],
              electr_safety_2: [() => <>{i18n.t("info-tab.category-safety-2")}</> ,Type.capacity_kwt],
              electr_safety_3: [() => <>{i18n.t("info-tab.category-safety-3")}</> ,Type.capacity_kwt],
              loadRecords: {
                getTitle: () => <>{i18n.t("info-tab.max-load")}</>,
                props: {
                  table: [() => <>{i18n.t("info-tab.max-load")}</>, Type.table]
                }
              },
              electr_receivers_1: [() => <>{i18n.t("info-tab.power-max-load-1")}</> ,Type.capacity_kwt],
              electr_receivers_2: [() => <>{i18n.t("info-tab.power-max-load-2")}</> ,Type.capacity_kwt],
              electr_receivers_3: [() => <>{i18n.t("info-tab.power-max-load-3")}</> ,Type.capacity_kwt],
              electrDevices: {
                getTitle: () => <>{i18n.t("info-tab.electr-devices")}</>,
                props: {
                  table: [() => <>{i18n.t("info-tab.electr-devices")}</>, Type.table]
                }
              },
              electr_exist_load: [() => <>{i18n.t("info-tab.electr-exist-load")}</> ,Type.capacity_kwt],
              electrTransforms: {
                getTitle: () => <>{i18n.t("info-tab.electr-transforms")}</>,
                props: {
                  table: [() => <>{i18n.t("info-tab.electr-transforms")}</>, Type.table]
                }
              },
              electr_source: [() => <>{i18n.t("info-tab.electr-source")}</>],
              electr_conn_point: [() => <>{i18n.t("info-tab.electr-conn-point")}</>],
            },
          },
          {
            getTitle: () => <>{i18n.t("info-tab.water")}</>,
            props: {
              water_power: [() => <>{i18n.t("info-tab.water-power")}</>, Type.capacity_m3_day],
              water_power_hour: [() => <>{i18n.t("info-tab.water-power")}</>, Type.capacity_m3_h],
              water_power_sec: [() => <>{i18n.t("info-tab.water-power")}</>, Type.capacity_l_sec],
              water_hoz: [() => <>{i18n.t("info-tab.water-hoz")}</>, Type.capacity_m3_day],
              water_hoz_hour:  [() => <>{i18n.t("info-tab.water-hoz")}</>, Type.capacity_m3_h],
              water_hoz_sec:  [() => <>{i18n.t("info-tab.water-hoz")}</>, Type.capacity_l_sec],
              water_prod:  [() => <>{i18n.t("info-tab.water-prod")}</>, Type.capacity_m3_day],
              water_prod_hour:  [() => <>{i18n.t("info-tab.water-prod")}</>, Type.capacity_m3_h],
              water_prod_sec:  [() => <>{i18n.t("info-tab.water-prod")}</>, Type.capacity_l_sec],
              water_fire:  [() => <>{i18n.t("info-tab.water-fire")}</>, Type.capacity_m3_day],
              water_fire_sec:  [() => <>{i18n.t("info-tab.water-fire")}</>, Type.capacity_l_sec],
              water_hoz_war_pressure:  [() => <>{i18n.t("info-tab.water-hoz-war-pressure")}</>, Type.capacity_mpa],
              water_conn_text: [() => <>{i18n.t("info-tab.water-conn-text")}</>],
            },
          },
          {
            getTitle: () => <>{i18n.t("info-tab.sewerage")}</>,
            props: {
              sewerage_power: [() => <>{i18n.t("info-tab.sewerage-power")}</>, Type.capacity_m3_day],
              sewerage_power_hour: [() => <>{i18n.t("info-tab.sewerage-power")}</>, Type.capacity_m3_h],
              sewerage_fecal: [() => <>{i18n.t("info-tab.sewerage-fecal")}</>, Type.capacity_m3_day],
              sewerage_fecal_hour: [() => <>{i18n.t("info-tab.sewerage-fecal")}</>, Type.capacity_m3_h],
              sewerage_prod: [() => <>{i18n.t("info-tab.sewerage-prod")}</>, Type.capacity_m3_day],
              sewerage_prod_hour: [() => <>{i18n.t("info-tab.sewerage-prod")}</>, Type.capacity_m3_h],
              sewerage_clean: [() => <>{i18n.t("info-tab.sewerage-clean")}</>, Type.capacity_m3_day],
              sewerage_clean_hour: [() => <>{i18n.t("info-tab.sewerage-clean")}</>, Type.capacity_m3_h],
              sewerage_discharge_sink: [() => <>{i18n.t("info-tab.sewerage-discharge-sink")}</>],
            },
          },
          {
            getTitle: () => <>{i18n.t("info-tab.heat")}</>,
            props: {
              heat_power: [() => <>{i18n.t("info-tab.heat-power")}</>, Type.capacity_gcal_h],
              heat_firing:  [() => <>{i18n.t("info-tab.heat-firing")}</>, Type.capacity_gcal_h],
              heat_ventilation: [() => <>{i18n.t("info-tab.heat-ventilation")}</>, Type.capacity_gcal_h],
              heat_hot_water: [() => <>{i18n.t("info-tab.heat-hot-water")}</>, Type.capacity_gcal_h],
              heat_tech_need: [() => <>{i18n.t("info-tab.heat-tech-need")}</>, Type.capacity_t_h],
              heat_share_load: [() => <>{i18n.t("info-tab.heat-share-load")}</>],
              heat_energy_event: [() => <>{i18n.t("info-tab.heat-energy-event")}</>],
              heat_source: [() => <>{i18n.t("info-tab.heat-source")}</>],
              heat_conn_point: [() => <>{i18n.t("info-tab.heat-conn-point")}</>],
            },
          },
          {
            getTitle: () => <>{i18n.t("info-tab.telekom")}</>,
            props: {
              telekom_count_ota: [() => <>{i18n.t("info-tab.telekom-count-ota")}</>],
              telekom_volume: [() => <>{i18n.t("info-tab.telekom-volume")}</>],
              telekom_plan_sewerage: [() => <>{i18n.t("info-tab.telekom-plan-sewerage")}</>],
              telekom_wish: [() => <>{i18n.t("info-tab.telekom-wish")}</>],
            },
          },
          {
            getTitle: () => <>{i18n.t("info-tab.gas")}</>,
            props: {
              gas_power: [() => <>{i18n.t("info-tab.gas-power")}</>, Type.capacity_m3_h],
              gas_on_cooking: [() => <>{i18n.t("info-tab.gas-on-cooking")}</>, Type.capacity_m3_h],
              gas_heating: [() => <>{i18n.t("info-tab.gas-heating")}</>, Type.capacity_m3_h],
              gas_ventilation: [() => <>{i18n.t("info-tab.heat-ventilation")}</>, Type.capacity_m3_h],
              gas_conditioning: [() => <>{i18n.t("info-tab.gas-conditioning")}</>, Type.capacity_m3_h],
              gas_hot_water: [() => <>{i18n.t("info-tab.gas-hot-water")}</>,Type.capacity_m3_h],
              gas_pipeline: [() => <>{i18n.t("info-tab.gas-pipeline")}</>],
              gas_pipeline_diameter: [() => <>{i18n.t("info-tab.gas-pipeline-diameter")}</>,Type.diameter_mm],
              gas_pipeline_pressure: [() => <>{i18n.t("info-tab.gas-pipeline-pressure")}</>, Type.capacity_mpa],
            },
          },
          {
            getTitle: () => <>{i18n.t("info-tab.other")}</>,
            props: {
              quality_comp: [() => <>{i18n.t("info-tab.quality-comp")}</>],
              is_storm_water: [() => <>{i18n.t("info-tab.is-storm-water")}</>],
              storm_water_text: [() => <>{i18n.t("info-tab.storm-water-text")}</>],
              storm_power: [() => <>{i18n.t("info-tab.sewerage-power")}</>, Type.area_m3],
              storm_conn_point: [() => <>{i18n.t("info-tab.storm-conn-point")}</>],
            },
          },
        ],
      },      /*
      {
        getTitle: () => <>Сервисы</>,
        groups: [
          {
            props: {
              srv_reg: [<>Регистрация</>, Type.service],
            },
          },
        ],
      },
      */
    ],
  },

  pch: {
    getTitle: props => props.label || "",
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              label: [() => <>{i18n.t("info-tab.cipher")}</>],
              ts_name_ru: [() => <>{i18n.t("info-tab.name-rus")}</>],
              desc_: [() => <>{i18n.t("info-tab.description")}</>],
              mcs_name_ru: [() => <>{i18n.t("info-tab.fur-comp")}</>],
              ball_b: [() => <>{i18n.t("info-tab.quality-score")}</>],
              shape_area: [() => <>{i18n.t("info-tab.shape-area")}</>, Type.area_m2],
              shape_length: [() => <>{i18n.t("request.perimetr")}</>, Type.length],
            },
          },
        ],
      },
    ],
  },

  rst: {
    getTitle: props => props.bg_name || "Нет значения",
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              bg_name: [() => <>{i18n.t("info-tab.name")}</>],
              uroj: [() => <>{i18n.t("info-tab.plant")}</>],
              shape_area: [() => <>{i18n.t("info-tab.shape-area")}</>, Type.area_m2],
              shape_length: [() => <>{i18n.t("request.perimetr")}</>, Type.length],
            },
          },
        ],
      },
    ],
  },

  ugl: {
    getTitle: props => props.name || "С/х угодие",
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              name: [() => <>{i18n.t("info-tab.name")}</>],
              shape_length: [() => <>{i18n.t("info-tab.perimetr-1")}</>, Type.length],
              shirina: [() => <>{i18n.t("info-tab.width")}</>, Type.length],
            },
          },
        ],
      },
    ],
  },

  ugpol: {
    getTitle: props => props.name || "С/х угодие",
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              name: [() => <>{i18n.t("info-tab.name")}</>],
              shape_length: [() => <>{i18n.t("request.perimetr")}</>, Type.length],
              shape_area: [() => <>{i18n.t("info-tab.shape-area")}</>, Type.area_m2],
            },
          },
        ],
      },
    ],
  },

  func: {
    getTitle: props => props.name || "Функциональная зона",
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              name: [() => <>{i18n.t("info-tab.name")}</>],
              category: [() => <>{i18n.t("info-tab.category")}</>],
              descritpion: [() => <>{i18n.t("info-tab.description")}</>],
              shape_leng: [() => <>{i18n.t("request.perimetr")}</>, Type.length],
              shape_area: [() => <>{i18n.t("info-tab.shape-area")}</>, Type.area_m2],
            },
          },
        ],
      },
    ],
  },

  pdp: {
    getTitle: props => props.name || props.caption || "ПДП",
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              description_ru: [() => <>{i18n.t("info-tab.description")}</>],
              type_name : [() => <>{i18n.t("info-tab.type")}</>],
              subtype_name : [() => <>{i18n.t("info-tab.subtype")}</>],
              parcel_number : [() => <>{i18n.t("info-tab.lot-number")}  </>],
              num_floors  : [() => <>{i18n.t("info-tab.floors-count")} </>],
              approvement_date : [() => <>{i18n.t("info-tab.approve-date")} </>],
              author : [() => <>{i18n.t("info-tab.executor")} </>],
              shape_area: [() => <>{i18n.t("info-tab.shape-area")}</>, Type.area_m2],
              shape_length: [() => <>{i18n.t("request.perimetr")}</>, Type.length],
              note  : [() => <> {i18n.t("info-tab.prim")}</>],
            },
          },
        ],
      },
    ],
  },

  gp: {
    getTitle: props => props.id || "Генеральный план",
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              id: [() => <>{i18n.t("info-tab.identifier")}</>],
            },
          },
        ],
      },
    ],
  },

  inv: {
    getTitle: props => props.note || "Граница инвентаризации",
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {},
          },
        ],
      },
    ],
  },

  ohz: {
    getTitle: props => props.name || "Охранная зона",
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              nraion: [() => <>{i18n.t("info-tab.district-name")}</>],
              name: [() => <>{i18n.t("info-tab.name")}</>],
              shape_length: [() => <>{i18n.t("request.perimetr")}</>, Type.length],
              shape_area: [() => <>{i18n.t("info-tab.shape-area")}</>, Type.area_m2],
            }
          }
        ],
      }
    ],
  },

  vop: {
    getTitle: () => "Водоохранная полоса",
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              shape_length: [() => <>{i18n.t("request.perimetr")}</>, Type.length],
              shape_area: [() => <>{i18n.t("info-tab.shape-area")}</>, Type.area_m2],
            },
          },
        ],
      },
    ],
  },

  voz: {
    getTitle: () => "Водоохранная зона",
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              shape_length: [() => <>{i18n.t("request.perimetr")}</>, Type.length],
              shape_area: [() => <>{i18n.t("info-tab.shape-area")}</>, Type.area_m2],
            },
          },
        ],
      },
    ],
  },

  water: {
    getTitle: props => props.name_ru,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              shape_length: [() => <>{i18n.t("request.perimetr")}</>, Type.length],
              shape_area: [() => <>{i18n.t("info-tab.shape-area")}</>, Type.area_m2],
            },
          },
        ],
      },
    ],
  },

  gas: {
    getTitle: props => props.name_ru,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              shape_length: [() => <>{i18n.t("request.perimetr")}</>, Type.length],
              shape_area: [() => <>{i18n.t("info-tab.shape-area")}</>, Type.area_m2],
            },
          },
        ],
      },
    ],
  },

  storm_water: {
    getTitle: props => props.name_ru,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              shape_length: [() => <>{i18n.t("request.perimetr")}</>, Type.length],
              shape_area: [() => <>{i18n.t("info-tab.shape-area")}</>, Type.area_m2],
            },
          },
        ],
      },
    ],
  },

  con: {
    getTitle: props => props.name_ru,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              shape_length: [() => <>{i18n.t("request.perimetr")}</>, Type.length],
              shape_area: [() => <>{i18n.t("info-tab.shape-area")}</>, Type.area_m2],
            },
          },
        ],
      },
    ],
  },

  heat: {
    getTitle: props => props.name_ru,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              shape_length: [() => <>{i18n.t("request.perimetr")}</>, Type.length],
              shape_area: [() => <>{i18n.t("info-tab.shape-area")}</>, Type.area_m2],
            },
          },
        ],
      },
    ],
  },

  sewer: {
    getTitle: props => props.name_ru,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              shape_length: [() => <>{i18n.t("request.perimetr")}</>, Type.length],
              shape_area: [() => <>{i18n.t("info-tab.shape-area")}</>, Type.area_m2],
            },
          },
        ],
      },
    ],
  },

  electr: {
    getTitle: props => props.name_ru,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              shape_length: [() => <>{i18n.t("request.perimetr")}</>, Type.length],
              shape_area: [() => <>{i18n.t("info-tab.shape-area")}</>, Type.area_m2],
            },
          },
        ],
      },
    ],
  },

  redlines: {
    getTitle: () => "Красные линии",
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              shape_length: [() => <>{i18n.t("request.perimetr")}</>, Type.length],
              shape_area: [() => <>{i18n.t("info-tab.shape-area")}</>, Type.area_m2],
            },
          },
        ],
      },
    ],
  },

  buf: {
    getTitle: () => "Буферная зона",
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              radius: [() => <>{i18n.t("info-tab.radius-name")}</>, Type.length],
              shape_length: [() => <>{i18n.t("request.perimetr")}</>, Type.length],
              shape_area: [() => <>{i18n.t("info-tab.shape-area")}</>, Type.area_m2],
            },
          },
        ],
      },
    ],
  },

  base_kazgeo: {
    getTitle: props => props.class || props.type,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              form: [() => <>form</>],
              title: [() => <>title</>],
              usage: [() => <>usage</>],
              name_str: [() => <>{i18n.t("info-tab.name")}</>],
            },
          },
        ],
      },
    ],
  },

  // GGK
  // Генеральный план
  // Функциональные зоны 
  ggk_gpzone: {
    getTitle: props => props.functional,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентификатор объекта</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              microdistrict: [() => <>Микрорайон</>],
              usl_i32: [() => <>Код по классификатору</>],
              gpc_name: [() => <>Наименование по классификатору</>],
              functional: [() => <>Наименование функциональной зоны</>],
              // gpsubtype_id: [() => <>gpsubtype_id</>],
              // shape_length: [() => <>{i18n.t("request.perimetr")}</>, Type.length],
              // shape_area: [() => <>{i18n.t("info-tab.shape-area")}</>, Type.area_m2],
              layer_fullname: [() => <>Полное наименование функциональной зоны</>],
              data_update: [() => <>Дата актуализации</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>]
            },
          },
        ],
      },
    ],
  },
  // Здания и сооружения населенного пункта - gpbuild
  ggk_gpbuild: {
    getTitle: props => props.functional,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентификатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              note: [() => <>Примечание</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              microdistrict: [() => <>Микрорайон</>],
              gpsubtype_id: [() => <>Подтип</>],
              floor: [() => <>Этажность</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>]
            },
          },
        ],
      },
    ],
  },
  // Инженерные коммуникации линейные - gpenglin
  ggk_gpenggaslin: {
    getTitle: props => props.object_name,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентификатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              implementation: [() => <>Статус реализации</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              amount_pr_f64: [() => <>Количество проводов/кабелей</>],
              voltage_f64: [() => <>Напряжение</>],
              diameter_f64: [() => <>Диаметр трубы, мм</>],
              material: [() => <>Материал трубы</>]
            },
          },
        ],
      },
    ],
  },
  // Объекты водоснабжения линейные - ggk.gpenglin
  ggk_gpengwodlin: {
    getTitle: props => props.object_name,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентификатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              implementation: [() => <>Статус реализации</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              amount_pr_f64: [() => <>Количество проводов/кабелей</>],
              voltage_f64: [() => <>Напряжение</>],
              diameter_f64: [() => <>Диаметр трубы, мм</>],
              material: [() => <>Материал трубы</>]
            },
          },
        ],
      },
    ],
  },
  // Объекты водоотведения линейные - ggk.gpenglin
  ggk_gpengkanlin: {
    getTitle: props => props.object_name,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентификатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              implementation: [() => <>Статус реализации</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              amount_pr_f64: [() => <>Количество проводов/кабелей</>],
              voltage_f64: [() => <>Напряжение</>],
              diameter_f64: [() => <>Диаметр трубы, мм</>],
              material: [() => <>Материал трубы</>]
            },
          },
        ],
      },
    ],
  },
  // Объекты теплоснабжения линейные - ggk.gpenglin
  ggk_gpengteplin: {
    getTitle: props => props.object_name,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентификатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              implementation: [() => <>Статус реализации</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              amount_pr_f64: [() => <>Количество проводов/кабелей</>],
              voltage_f64: [() => <>Напряжение</>],
              diameter_f64: [() => <>Диаметр трубы, мм</>],
              material: [() => <>Материал трубы</>]
            },
          },
        ],
      },
    ],
  },
  // Инженерные коммуникации линейные - ggk.gpenglin
  ggk_gpenglin: {
    getTitle: props => props.object_name,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентификатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              implementation: [() => <>Статус реализации</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              amount_pr_f64: [() => <>Количество проводов/кабелей</>],
              voltage_f64: [() => <>Напряжение</>],
              diameter_f64: [() => <>Диаметр трубы, мм</>],
              material: [() => <>Материал трубы</>]
            },
          },
        ],
      },
    ],
  },
  // Объекты энергоснабжения линейные - ggk.gpenglin
  ggk_gpengellin: {
    getTitle: props => props.object_name,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентификатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              implementation: [() => <>Статус реализации</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              amount_pr_f64: [() => <>Количество проводов/кабелей</>],
              voltage_f64: [() => <>Напряжение</>],
              diameter_f64: [() => <>Диаметр трубы, мм</>],
              material: [() => <>Материал трубы</>]
            },
          },
        ],
      },
    ],
  },
  // Объекты связи и телекоммуникаций линейные - ggk.gpenglin
  ggk_gpengtellin: {
    getTitle: props => props.object_name,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентификатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              implementation: [() => <>Статус реализации</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              amount_pr_f64: [() => <>Количество проводов/кабелей</>],
              voltage_f64: [() => <>Напряжение</>],
              diameter_f64: [() => <>Диаметр трубы, мм</>],
              material: [() => <>Материал трубы</>]
            },
          },
        ],
      },
    ],
  },
  // Объекты нефтепроводов линейные - ggk.gpenglin
  ggk_gpengoillin: {
    getTitle: props => props.object_name,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентификатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              implementation: [() => <>Статус реализации</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              amount_pr_f64: [() => <>Количество проводов/кабелей</>],
              voltage_f64: [() => <>Напряжение</>],
              diameter_f64: [() => <>Диаметр трубы, мм</>],
              material: [() => <>Материал трубы</>]
            },
          },
        ],
      },
    ],
  },
  // Объекты ливневой канализации линейные - ggk.gpenglin
  ggk_gpenglivlin: {
    getTitle: props => props.object_name,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентификатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              implementation: [() => <>Статус реализации</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              amount_pr_f64: [() => <>Количество проводов/кабелей</>],
              voltage_f64: [() => <>Напряжение</>],
              diameter_f64: [() => <>Диаметр трубы, мм</>],
              material: [() => <>Материал трубы</>]
            },
          },
        ],
      },
    ],
  },
  // Инженерные коммуникации полигональные - ggk.gpengpol
  ggk_gpenggaspol: {
    getTitle: props => props.object_name,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              implementation: [() => <>Статус реализации</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>]
            },
          },
        ],
      },
    ],
  },
  // Объекты водоснабжения полигональные - ggk.gpengpol
  ggk_gpengwodpol: {
    getTitle: props => props.object_name,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              implementation: [() => <>Статус реализации</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>]
            },
          },
        ],
      },
    ],
  },
  // Инженерные коммуникации полигональные - ggk.gpengpol
  ggk_gpengpol: {
    getTitle: props => props.object_name,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              implementation: [() => <>Статус реализации</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>]
            },
          },
        ],
      },
    ],
  },
  // Объекты энергоснабжения полигональные - ggk.gpengpol
  ggk_gpengelpol: {
    getTitle: props => props.object_name,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              implementation: [() => <>Статус реализации</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>]
            },
          },
        ],
      },
    ],
  },
  // Объекты водоотведения полигональные - ggk.gpengpol
  ggk_gpengkanpol: {
    getTitle: props => props.object_name,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              implementation: [() => <>Статус реализации</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>]
            },
          },
        ],
      },
    ],
  },
  // Объекты теплоснабжения полигональные - ggk.gpengpol
  ggk_gpengteppol: {
    getTitle: props => props.object_name,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              implementation: [() => <>Статус реализации</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>]
            },
          },
        ],
      },
    ],
  },
  // Объекты связи и телекоммуникаций полигональные - ggk.gpengpol
  ggk_gpengtelpol: {
    getTitle: props => props.object_name,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              implementation: [() => <>Статус реализации</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>]
            },
          },
        ],
      },
    ],
  },
  // Объекты нефтепроводов полигональные - ggk.gpengpol
  ggk_gpengoilpol: {
    getTitle: props => props.object_name,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              implementation: [() => <>Статус реализации</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>]
            },
          },
        ],
      },
    ],
  },
  // Объекты ливневой канализации полигональные - ggk.gpengpol
  ggk_gpenglivpol: {
    getTitle: props => props.object_name,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              implementation: [() => <>Статус реализации</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>]
            },
          },
        ],
      },
    ],
  },
  // Границы градостроительного проекта
  // Границы населенного пункта - ggk.gpgrnp
  ggk_gpgrnp: {
    // getTitle: props => props.kad_number,
    getTitle: () => <>Границы населенного пункта</>,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              region: [() => <>Наименование района</>],
              oblast: [() => <>Наименование области</>],
              name_np: [() => <>Наименование населенного пункта</>],
              confirm_info: [() => <>Информация об утверждении ГП</>],
              gpsubtype_id: [() => <>Подтип</>]
            },
          },
        ],
      },
    ],
  },
  // Граница ПДП - ggk.gpgrpdp
  ggk_gpgrpdp: {
    getTitle: props => props.pdp_name,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              region: [() => <>Наименование района</>],
              oblast: [() => <>Наименование области</>],
              name_np: [() => <>Наименование населенного пункта</>]
            },
          },
        ],
      },
    ],
  },
  // Границы учетных кварталов - ggk.gpgrmkr
  ggk_gpgrmkr: {
    getTitle: props => props.mkr_name,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              region: [() => <>Наименование района</>],
              oblast: [() => <>Наименование области</>],
              name_np: [() => <>Наименование населенного пункта</>]
            },
          },
        ],
      },
    ],
  },
  //Градостроительные регламенты 
  // Желтые линии линейные - ggk.gpregline
  ggk_gpregyellowline: {
    getTitle: props => props.name,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              number_post: [() => <>Номер постановления утверждения ГП</>],
              approved_date: [() => <>Дата утверждения</>],
              entry_date: [() => <>Дата внесения</>],
              num_data_regist: [() => <>Номер и дата регистрации «локальных линий»</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
            },
          },
        ],
      },
    ],
  },
  // Красные линии полигональные - ggk.gpregredlinepol
  ggk_gpregredlinepol: {
    getTitle: props => props.name,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              number_post: [() => <>Номер постановления утверждения ГП</>],
              approved_date: [() => <>Дата утверждения</>],
              entry_date: [() => <>Дата внесения</>],
              num_data_regist: [() => <>Номер и дата регистрации «локальных линий»</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
            },
          },
        ],
      },
    ],
  },
  // Водоохранные полосы - ggk.gpregwodpls
  ggk_gpregwodpls: {
    getTitle: () => <>Водоохранные полосы</>,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              width_f64: [() => <>Ширина, м</>, Type.length]
            },
          },
        ],
      },
    ],
  },
  // Красные линии линейные - ggk.gpregline
  ggk_gpregredlinelin: {
    getTitle: props => props.name,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              number_post: [() => <>Номер постановления утверждения ГП</>],
              approved_date: [() => <>Дата утверждения</>],
              entry_date: [() => <>Дата внесения</>],
              num_data_regist: [() => <>Номер и дата регистрации «локальных линий»</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
            },
          },
        ],
      },
    ],
  },
  // Автомобильный транспорт
  // Мосты - ggk.gpautotran
  ggk_gpautotranbridg: {
    getTitle: () => <>Мосты</>,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
            },
          },
        ],
      },
    ],
  },
  // Остановки  - ggk.gpautotran
  ggk_gpautotranost: {
    getTitle: () => <>Остановки</>,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
            },
          },
        ],
      },
    ],
  },
  // Парковки - ggk.gpautotran
  ggk_gpautotranprc: {
    getTitle: () => <>Парковки</>,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
            },
          },
        ],
      },
    ],
  },
  // Дороги и проезды - ggk.gpautotranrdc
  ggk_gpautotranrdc: {
    getTitle: () => <>Дороги и проезды</>,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
            },
          },
        ],
      },
    ],
  },
  // Осевые линии дорог и улиц - ggk.gpautotranstreet
  ggk_gpautotranstreet: {
    getTitle: props => props.street_name,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              street_name: [() => <>Наименование улицы</>]
            },
          },
        ],
      },
    ],
  },
  // Железные дороги, пути и линейные сооружения - ggk.gprrstranlin
  ggk_gprrstran: {
    getTitle: () => <>Железные дороги, пути и линейные сооружения</>,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
            },
          },
        ],
      },
    ],
  },
  // Благоустройство населенного пункта
  // Ограждения - ggk.gpblaglin
  ggk_gpblagogr: {
    getTitle: () => <>Ограждения</>,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              implementation: [() => <>Статус реализации</>]
            },
          },
        ],
      },
    ],
  },
  // Детские игровые площадки - ggk.gpblagpol
  ggk_gpblagchildpl: {
    getTitle: () => <>Детские игровые площадки</>,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              implementation: [() => <>Статус реализации</>]
            },
          },
        ],
      },
    ],
  },
  // Площадка для сушки белья - ggk.gpblagpol
  ggk_gpblagdryerpl: {
    getTitle: () => <>Площадка для сушки белья</>,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              implementation: [() => <>Статус реализации</>]
            },
          },
        ],
      },
    ],
  },
  // Мусорные контейнерные площадки - ggk.gpblagpol
  ggk_gpblagdumppl: {
    getTitle: () => <>Мусорные контейнерные площадки</>,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              implementation: [() => <>Статус реализации</>]
            },
          },
        ],
      },
    ],
  },
  // Мусорные участки - ggk.gpblagpol
  ggk_gpblagdumpuch: {
    getTitle: () => <>Мусорные участки</>,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              implementation: [() => <>Статус реализации</>]
            },
          },
        ],
      },
    ],
  },
  // Фонтаны - ggk.gpblagpol
  ggk_gpblagfontpol: {
    getTitle: () => <>Фонтаны</>,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              implementation: [() => <>Статус реализации</>]
            },
          },
        ],
      },
    ],
  },
  // Паркинг - ggk.gpblagpol
  ggk_gpblagparking: {
    getTitle: () => <>Паркинг</>,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              implementation: [() => <>Статус реализации</>]
            },
          },
        ],
      },
    ],
  },
  // Спортивная площадка - ggk.gpblagpol
  ggk_gpblagsportpl: {
    getTitle: () => <>Спортивная площадка</>,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              implementation: [() => <>Статус реализации</>]
            },
          },
        ],
      },
    ],
  },
  // Эстакадные сооружения - ggk.gpblagpol
  ggk_gpblagstockad: {
    getTitle: () => <>Эстакадные сооружения</>,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              implementation: [() => <>Статус реализации</>]
            },
          },
        ],
      },
    ],
  },
  // Тротуары, брусчатка, пешеходные дорожки - ggk.gpblagpol
  ggk_gpblagtrotuar: {
    getTitle: () => <>Тротуары, брусчатка, пешеходные дорожки</>,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              implementation: [() => <>Статус реализации</>]
            },
          },
        ],
      },
    ],
  },
  // Городское озеленение - ggk.gpblagpol
  ggk_gpblagzelen: {
    getTitle: () => <>Городское озеленение</>,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              implementation: [() => <>Статус реализации</>]
            },
          },
        ],
      },
    ],
  },
  // Рекламно-информационный объект (билборд) - ggk.gpblagpol
  ggk_gpblagbillboard: {
    getTitle: () => <>Рекламно-информационный объект (билборд)</>,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              implementation: [() => <>Статус реализации</>]
            },
          },
        ],
      },
    ],
  },
  // Линейные объекты, имеющие социально-культурную значимость (исторические стены, валы, тропы, дороги) - ggk.gpscsclin
  ggk_gpscsclin: {
    getTitle: props => props.object_name,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
            },
          },
        ],
      },
    ],
  },
  // Культурные, религиозные объекты (монументы, памятники, городища, места археологических раскопок, исторические площади и т.д.) - ggk.gpscscpol
  ggk_gpscscpol: {
    getTitle: props => props.object_name,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
            },
          },
        ],
      },
    ],
  },
  // Объекты гидрографии - ggk.gpgs
  ggk_gpgshdrpol: {
    getTitle: props => props.object_name,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              gpsubtype_id: [() => <>Подтип</>]
            },
          },
        ],
      },
    ],
  },
  // Гидротехнические объекты - ggk.gpgs
  ggk_gpgshdtpol: {
    getTitle: props => props.object_name,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              gpsubtype_id: [() => <>Подтип</>]
            },
          },
        ],
      },
    ],
  },
  // Проект детальной планировки
  // Функциональные зоны
  ggk_pdpzone: {
    getTitle: () => <>Жилые зоны</>,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              pdpc_name: [() => <>Наименование по классификатору</>],
              functional: [() => <>Наименование функциональной зоны</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              microdistrict: [() => <>Микрорайон</>],
              implementation: [() => <>Статус реализации</>],
              gpsubtype_id: [() => <>Подтип</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              layer_fullname: [() => <>Полное наименование функциональной зоны</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>]
            },
          },
        ],
      },
    ],
  },
  // Здания и сооружения населенного пункта - ggk.pdpbuild
  ggk_pdpbuild: {
    getTitle: props => props.functional,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              microdistrict: [() => <>Микрорайон</>],
              gpsubtype_id: [() => <>Подтип</>],
              floor: [() => <>Этажность</>],
              status: [() => <>Статус</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>]
            },
          },
        ],
      },
    ],
  },
  // Инженерные коммуникации линейные 
  // Объекты энергоснабжения линейные - ggk.pdpenglin
  ggk_pdpengellin: {
    getTitle: props => props.object_name,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              implementation: [() => <>Статус реализации</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              amount_pr_f64: [() => <>Количество проводов/кабелей</>],
              voltage_f64: [() => <>Напряжение</>],
              diameter_f64: [() => <>Диаметр трубы, мм</>],
              material: [() => <>Материал трубы</>]
            },
          },
        ],
      },
    ],
  },
  // Объекты газоснабжения линейные - ggk.pdpenglin
  ggk_pdpenggaslin: {
    getTitle: props => props.object_name,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              implementation: [() => <>Статус реализации</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              amount_pr_f64: [() => <>Количество проводов/кабелей</>],
              voltage_f64: [() => <>Напряжение</>],
              diameter_f64: [() => <>Диаметр трубы, мм</>],
              material: [() => <>Материал трубы</>]
            },
          },
        ],
      },
    ],
  },
  // Объекты водоснабжения линейные - ggk.pdpenglin
  ggk_pdpengwodlin: {
    getTitle: props => props.object_name,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              implementation: [() => <>Статус реализации</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              amount_pr_f64: [() => <>Количество проводов/кабелей</>],
              voltage_f64: [() => <>Напряжение</>],
              diameter_f64: [() => <>Диаметр трубы, мм</>],
              material: [() => <>Материал трубы</>]
            },
          },
        ],
      },
    ],
  },
  // Объекты водоотведения линейные - ggk.pdpenglin
  ggk_pdpengkanlin: {
    getTitle: props => props.object_name,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              implementation: [() => <>Статус реализации</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              amount_pr_f64: [() => <>Количество проводов/кабелей</>],
              voltage_f64: [() => <>Напряжение</>],
              diameter_f64: [() => <>Диаметр трубы, мм</>],
              material: [() => <>Материал трубы</>]
            },
          },
        ],
      },
    ],
  },
  // Объекты теплоснабжения линейные - ggk.pdpenglin
  ggk_pdpengteplin: {
    getTitle: props => props.object_name,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              implementation: [() => <>Статус реализации</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              amount_pr_f64: [() => <>Количество проводов/кабелей</>],
              voltage_f64: [() => <>Напряжение</>],
              diameter_f64: [() => <>Диаметр трубы, мм</>],
              material: [() => <>Материал трубы</>]
            },
          },
        ],
      },
    ],
  },
  // Объекты связи и телекоммуникаций линейные - ggk.pdpenglin
  ggk_pdpengtellin: {
    getTitle: props => props.object_name,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              implementation: [() => <>Статус реализации</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              amount_pr_f64: [() => <>Количество проводов/кабелей</>],
              voltage_f64: [() => <>Напряжение</>],
              diameter_f64: [() => <>Диаметр трубы, мм</>],
              material: [() => <>Материал трубы</>]
            },
          },
        ],
      },
    ],
  },
  // Объекты нефтепроводов линейные - ggk.pdpenglin
  ggk_pdpengoillin: {
    getTitle: props => props.object_name,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              implementation: [() => <>Статус реализации</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              amount_pr_f64: [() => <>Количество проводов/кабелей</>],
              voltage_f64: [() => <>Напряжение</>],
              diameter_f64: [() => <>Диаметр трубы, мм</>],
              material: [() => <>Материал трубы</>]
            },
          },
        ],
      },
    ],
  },
  // Объекты ливневой канализации линейные - ggk.pdpenglin
  ggk_pdpenglivlin: {
    getTitle: props => props.object_name,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              implementation: [() => <>Статус реализации</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              amount_pr_f64: [() => <>Количество проводов/кабелей</>],
              voltage_f64: [() => <>Напряжение</>],
              diameter_f64: [() => <>Диаметр трубы, мм</>],
              material: [() => <>Материал трубы</>]
            },
          },
        ],
      },
    ],
  },
  // Инженерные коммуникации полигональные
  // Объекты энергоснабжения полигональные - ggk.pdpengpol
  ggk_pdpengelpol: {
    getTitle: props => props.object_name,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              implementation: [() => <>Статус реализации</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              tp_number: [() => <>Номер подстанции</>],
              actual_power: [() => <>Установленная мощность</>],
              material: [() => <>Материал трубы</>],
            },
          },
        ],
      },
    ],
  },
  // Объекты газоснабжения полигональные - ggk.pdpengpol
  ggk_pdpenggaspol: {
    getTitle: props => props.object_name,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              implementation: [() => <>Статус реализации</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              tp_number: [() => <>Номер подстанции</>],
              actual_power: [() => <>Установленная мощность</>],
              material: [() => <>Материал трубы</>],
            },
          },
        ],
      },
    ],
  },
  // Объекты водоснабжения полигональные - ggk.pdpengpol
  ggk_pdpengwodpol: {
    getTitle: props => props.object_name,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              implementation: [() => <>Статус реализации</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              tp_number: [() => <>Номер подстанции</>],
              actual_power: [() => <>Установленная мощность</>],
              material: [() => <>Материал трубы</>],
            },
          },
        ],
      },
    ],
  },
  // Объекты водоотведения полигональные - ggk.pdpengpol
  ggk_pdpengkanpol: {
    getTitle: props => props.object_name,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              implementation: [() => <>Статус реализации</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              tp_number: [() => <>Номер подстанции</>],
              actual_power: [() => <>Установленная мощность</>],
              material: [() => <>Материал трубы</>],
            },
          },
        ],
      },
    ],
  },
  // Объекты теплоснабжения полигональные - ggk.pdpengpol
  ggk_pdpengteppol: {
    getTitle: props => props.object_name,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              implementation: [() => <>Статус реализации</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              tp_number: [() => <>Номер подстанции</>],
              actual_power: [() => <>Установленная мощность</>],
              material: [() => <>Материал трубы</>],
            },
          },
        ],
      },
    ],
  },
  // Объекты связи и телекоммуникаций полигональные - ggk.pdpengpol
  ggk_pdpengtelpol: {
    getTitle: props => props.object_name,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              implementation: [() => <>Статус реализации</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              tp_number: [() => <>Номер подстанции</>],
              actual_power: [() => <>Установленная мощность</>],
              material: [() => <>Материал трубы</>],
            },
          },
        ],
      },
    ],
  },
  // Объекты нефтепроводов полигональные - ggk.pdpengpol
  ggk_pdpengoilpol: {
    getTitle: props => props.object_name,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              implementation: [() => <>Статус реализации</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              tp_number: [() => <>Номер подстанции</>],
              actual_power: [() => <>Установленная мощность</>],
              material: [() => <>Материал трубы</>],
            },
          },
        ],
      },
    ],
  },
  // Объекты ливневой канализации полигональные - ggk.pdpengpol
  ggk_pdpenglivpol: {
    getTitle: props => props.object_name,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              implementation: [() => <>Статус реализации</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              tp_number: [() => <>Номер подстанции</>],
              actual_power: [() => <>Установленная мощность</>],
              material: [() => <>Материал трубы</>],
            },
          },
        ],
      },
    ],
  },
  // Границы градостроительного проекта
  // Границы населенного пункта - ggk.pdpgrnp
  ggk_pdpgrnp: {
    getTitle: props => props.name_np,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              gpsubtype_id: [() => <>Подтип</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              region: [() => <>Наименование района</>],
              oblast: [() => <>Наименование области</>],
              confirm_info: [() => <>Информация об утверждении ГП</>],
            },
          },
        ],
      },
    ],
  },
  // Граница ПДП - ggk.pdpgrpdp
  ggk_pdpgrpdp: {
    getTitle: props => props.pdp_name,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              region: [() => <>Наименование района</>],
              oblast: [() => <>Наименование области</>],
              name_np: [() => <>Наименование населенного пункта</>],
            },
          },
        ],
      },
    ],
  },
  // Границы учетных кварталов - ggk.pdpgrmkr
  ggk_pdpgrmkr: {
    getTitle: props => props.mkr_name,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              region: [() => <>Наименование района</>],
              oblast: [() => <>Наименование области</>],
              name_np: [() => <>Наименование населенного пункта</>],
            },
          },
        ],
      },
    ],
  },
  // Градостроительные регламенты
  // Красные линии линейные - ggk.pdpregline
  ggk_pdpregredlinelin: {
    getTitle: props => props.name,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              number_post: [() => <>Номер постановления утверждения ПДП</>],
              approved_date: [() => <>Дата утверждения</>],
              entry_date: [() => <>Дата внесения</>],
              num_data_regist: [() => <>Номер и дата регистрации «локальных линий»</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
            },
          },
        ],
      },
    ],
  },
  // Красные линии полигональные - ggk.pdpregredlinepol
  ggk_pdpregredlinepol: {
    getTitle: props => props.name,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              number_post: [() => <>Номер постановления утверждения ПДП</>],
              approved_date: [() => <>Дата утверждения</>],
              entry_date: [() => <>Дата внесения</>],
              num_data_regist: [() => <>Номер и дата регистрации «локальных линий»</>],
              data_update: [() => <>Дата актуализации</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
            },
          },
        ],
      },
    ],
  },
  // Желтые линии - ggk.pdpregline
  ggk_pdpregyellowline: {
    getTitle: props => props.name,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              number_post: [() => <>Номер постановления утверждения ПДП</>],
              approved_date: [() => <>Дата утверждения</>],
              entry_date: [() => <>Дата внесения</>],
              num_data_regist: [() => <>Номер и дата регистрации «локальных линий»</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
            },
          },
        ],
      },
    ],
  },
  // Водоохранные полосы - ggk.pdpregwodpls
  ggk_pdpregwodpls: {
    getTitle: props => props.name,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              width_f64: [() => <>Ширина, м</>]
            },
          },
        ],
      },
    ],
  },
  // Автомобильный транспорт
  // Мосты - ggk.pdpautotran
  ggk_pdpautotranbridg: {
    getTitle: () => <>Мосты</>,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
            },
          },
        ],
      },
    ],
  },
  // Остановки - ggk.pdpautotran
  ggk_pdpautotranost: {
    getTitle: () => <>Остановки</>,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
            },
          },
        ],
      },
    ],
  },
  // Парковки - ggk.pdpautotran
  ggk_pdpautotranprc: {
    getTitle: () => <>Парковки</>,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
            },
          },
        ],
      },
    ],
  },
  // Дороги и проезды - ggk.pdpautotranrdc
  ggk_pdpautotranrdc: {
    getTitle: () => <>Дороги и проезды</>,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
            },
          },
        ],
      },
    ],
  },
  // Осевые линии дорог и улиц - ggk.pdpautotranstreet
  ggk_pdpautotranstreet: {
    getTitle: () => <>Осевые линии дорог и улиц</>,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              street_name: [() => <>Наименование улицы</>]
            },
          },
        ],
      },
    ],
  },
  
  // Железные дороги, пути и линейные сооружения - ggk.pdprrstranlin
  ggk_pdprrstranlin: {
    getTitle: () => <>Железные дороги, пути и линейные сооружения</>,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>]
            },
          },
        ],
      },
    ],
  },
  // Благоустройство населенного пункта - ggk.pdpblagpol
  ggk_pdpblag: {
    getTitle: () => <> Благоустройство населенного пункта</>,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>]
            },
          },
        ],
      },
    ],
  },
  // Рекламно-информационный объект (билборд) - ggk.pdpblagpol
  ggk_pdpblagbillboard: {
    getTitle: () => <> Рекламно-информационный объект (билборд)</>,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>]
            },
          },
        ],
      },
    ],
  },
  // Детские игровые площадки - ggk.pdpblagpol
  ggk_pdpblagchildpl: {
    getTitle: () => <>Детские игровые площадки</>,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>]
            },
          },
        ],
      },
    ],
  },
  // Мусорные участки - ggk.pdpblagpol
  ggk_pdpblagdumpuch: {
    getTitle: () => <>Мусорные участки</>,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>]
            },
          },
        ],
      },
    ],
  },
  // Площадка для сушки белья - ggk.pdpblagpol
  ggk_pdpblagdryerpl: {
    getTitle: () => <>Площадка для сушки белья</>,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>]
            },
          },
        ],
      },
    ],
  },
  // Мусорные контейнерные площадки - ggk.pdpblagpol
  ggk_pdpblagdumppl: {
    getTitle: () => <>Мусорные контейнерные площадки</>,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>]
            },
          },
        ],
      },
    ],
  },
  // Фонтаны - ggk.pdpblagpol
  ggk_pdpblagfontpol: {
    getTitle: () => <>Фонтаны</>,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>]
            },
          },
        ],
      },
    ],
  },
  // Паркинг - ggk.pdpblagpol
  ggk_pdpblagparking: {
    getTitle: () => <>Паркинг</>,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>]
            },
          },
        ],
      },
    ],
  },
  // Спортивная площадка - ggk.pdpblagpol
  ggk_pdpblagsportpl: {
    getTitle: () => <>Спортивная площадка</>,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>]
            },
          },
        ],
      },
    ],
  },
  // Эстакадные сооружения - ggk.pdpblagpol
  ggk_pdpblagstockad: {
    getTitle: () => <>Эстакадные сооружения</>,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>]
            },
          },
        ],
      },
    ],
  },
  // Тротуары, брусчатка, пешеходные дорожки - ggk.pdpblagpol
  ggk_pdpblagtrotuar: {
    getTitle: () => <>Тротуары, брусчатка, пешеходные дорожки</>,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>]
            },
          },
        ],
      },
    ],
  },
  // Городское озеленение - ggk.pdpblagpol
  ggk_pdpblagzelen: {
    getTitle: () => <>Городское озеленение</>,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>]
            },
          },
        ],
      },
    ],
  },
  // Малые архитектурные формы - ggk.pdpblagpol
  ggk_pdpblagmaf: {
    getTitle: () => <>Малые архитектурные формы</>,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>]
            },
          },
        ],
      },
    ],
  },
  // Ограждения - ggk.pdpblaglin
  ggk_pdpblagogr: {
    getTitle: () => <>Ограждения</>,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              implementation: [() => <>Статус реализации</>]
            },
          },
        ],
      },
    ],
  },
  // Гидрография и гидротехнические сооружения
  // Гидрография и гидротехнические сооружения - ggk.pdpgs
  ggk_pdpgs: {
    getTitle: props => props.object_name,  
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              gpsubtype_id: [() => <>Подтип</>]
            },
          },
        ],
      },
    ],
  },
  // Объекты гидрографии - ggk.pdpgs
  ggk_pdpgshdrpol: {
    getTitle: props => props.object_name,  
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              gpsubtype_id: [() => <>Подтип</>]
            },
          },
        ],
      },
    ],
  },
  // Гидротехнические объекты - ggk.pdpgs
  ggk_pdpgshdtpol: {
    getTitle: props => props.object_name,  
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              data_update: [() => <>Дата актуализации</>],
              kad_number: [() => <>Кадастровый код района, города, нас. пункта, района в городе</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              gpsubtype_id: [() => <>Подтип</>]
            },
          },
        ],
      },
    ],
  },
  // Границы инвентаризации инженерных сетей ГГК
  // Границы инвентаризации инженерных сетей ГГК - ggk.invsupplyzone
  ggk_invsupplyzone: {
    getTitle: props => props.object_name,  
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              zone_type_eng: [() => <>Тип зоны</>],
              data_update: [() => <>Дата актуализации</>],
              kato: [() => <>Код КАТО</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
            },
          },
        ],
      },
    ],
  },
  // Водоснабжение - ggk.invsupplyzone
  ggk_invsupplyzone_water: {
    getTitle: props => props.object_name,  
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              zone_type_eng: [() => <>Тип зоны</>],
              data_update: [() => <>Дата актуализации</>],
              kato: [() => <>Код КАТО</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
            },
          },
        ],
      },
    ],
  },
  // Газоснабжение - ggk.invsupplyzone
  ggk_invsupplyzone_gas: {
    getTitle: props => props.object_name,  
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              zone_type_eng: [() => <>Тип зоны</>],
              data_update: [() => <>Дата актуализации</>],
              kato: [() => <>Код КАТО</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
            },
          },
        ],
      },
    ],
  },
  // Канализация - ggk.invsupplyzone
  ggk_invsupplyzone_fecal: {
    getTitle: props => props.object_name,  
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              zone_type_eng: [() => <>Тип зоны</>],
              data_update: [() => <>Дата актуализации</>],
              kato: [() => <>Код КАТО</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
            },
          },
        ],
      },
    ],
  },
  // Уличное освещение - ggk.invsupplyzone
  ggk_invsupplyzone_streetlight: {
    getTitle: props => props.object_name,  
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              zone_type_eng: [() => <>Тип зоны</>],
              data_update: [() => <>Дата актуализации</>],
              kato: [() => <>Код КАТО</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
            },
          },
        ],
      },
    ],
  },
  // Ливневая канализация - ggk.invsupplyzone
  ggk_invsupplyzone_sewerage: {
    getTitle: props => props.object_name,  
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              zone_type_eng: [() => <>Тип зоны</>],
              data_update: [() => <>Дата актуализации</>],
              kato: [() => <>Код КАТО</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
            },
          },
        ],
      },
    ],
  },
  // Телефонизация - ggk.invsupplyzone
  ggk_invsupplyzone_phone: {
    getTitle: props => props.object_name,  
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              zone_type_eng: [() => <>Тип зоны</>],
              data_update: [() => <>Дата актуализации</>],
              kato: [() => <>Код КАТО</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
            },
          },
        ],
      },
    ],
  },
  // Теплоснабжение - ggk.invsupplyzone
  ggk_invsupplyzone_heat: {
    getTitle: props => props.object_name,  
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              zone_type_eng: [() => <>Тип зоны</>],
              data_update: [() => <>Дата актуализации</>],
              kato: [() => <>Код КАТО</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
            },
          },
        ],
      },
    ],
  },
  // Электроснабжение - ggk.invsupplyzone
  ggk_invsupplyzone_electro: {
    getTitle: props => props.object_name,  
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              zone_type_eng: [() => <>Тип зоны</>],
              data_update: [() => <>Дата актуализации</>],
              kato: [() => <>Код КАТО</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
            },
          },
        ],
      },
    ],
  },
  // Объекты гидрографии полигональные - ggk.invgshdrpol
  ggk_invgshdrpol: {
    getTitle: props => props.name_object,  
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              zone_type_eng: [() => <>Тип зоны</>],
              condition: [() => <>Состояние объекта</>],
              coordinat_f64: [() => <>Координаты</>],
              data_update: [() => <>Дата актуализации</>],
              kato: [() => <>Код КАТО</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
              oblast: [() => <>Область</>],
              region: [() => <>Район</>],
            },
          },
        ],
      },
    ],
  },
  // Границы
  // Населенные пункты полигон - ggk.invadmnppol
  ggk_invadmnppol: {
    getTitle: props => props.name_object,  
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              name_object_kz: [() => <>Наименование объекта на казахском</>],
              admterr_id: [() => <>Като с геопортала</>],
              data_update: [() => <>Дата актуализации</>],
              kato: [() => <>Код КАТО</>],
              oblast: [() => <>Наименование области</>],
              region: [() => <>Наименование района</>],
              status: [() => <>Статус</>],
              coordinat_f64: [() => <>Координаты</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
            },
          },
        ],
      },
    ],
  },
  // Граница Государства, областей, районов, сельских округов, населенных пунктов линейный - ggk.invadmlin
  ggk_invadmlin: {
    getTitle: props => props.name_category,  
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              category_f64: [() => <>Категория</>],
              admterr_id: [() => <>Като с геопортала</>],
              data_update: [() => <>Дата актуализации</>],
              kato: [() => <>Код КАТО</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
            },
          },
        ],
      },
    ],
  },
  // Красные линии - ggk.pdpregredlinepol
  ggk_redlines: {
    getTitle: props => props.name,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              number_post: [() => <>Номер постановления утверждения ПДП</>],
              approved_date: [() => <>Дата утверждения</>],
              entry_date: [() => <>Дата внесения</>],
              num_data_regist: [() => <>Номер и дата регистрации «локальных линий»</>],
              data_update: [() => <>Дата актуализации</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],
            },
          },
        ],
      },
    ],
  },
  // Строения - ggk.invsitibuild
  ggk_invsitibuild: {
    getTitle: props => props.functional,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              oblast: [() => <>Область</>],
              region: [() => <>Район</>],
              address_array: [() => <>Адресный массив</>],
              street: [() => <>Улица</>],
              number_storeys_f64: [() => <>Этажность</>],
              area_build_f64: [() => <>Площадь застройки</>],
              material: [() => <>Материал стен</>],
              number_kadastr: [() => <>Кадастровый номер здания и сооружения</>],
              id_build_f64: [() => <>Код здания</>],
              condition: [() => <>Состояние</>],
              owner: [() => <>Собственник, балансодержатель</>],
              data_update: [() => <>Дата актуализации</>],
              kato: [() => <>Код КАТО</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],              
            },
          },
        ],
      },
    ],
  },
  // Железные дороги, пути - ggk.invrrspol
  ggk_invrrspol: {
    getTitle: props => props.name_object,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентитфикатор объекта</>],
              usl_i32: [() => <>Код по классификатору</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              oblast: [() => <>Область</>],
              region: [() => <>Район</>],
              status: [() => <>Статус</>],
              extension_f64: [() => <>Протяжённость</>],
              condition: [() => <>Состояние</>],
              name_nod: [() => <>Наименование отделения дорог</>],
              nod: [() => <>НОД номер</>],
              data_update: [() => <>Дата актуализации</>],
              document: [() => <>Документальный</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>],              
            },
          },
        ],
      },
    ],
  },
  // Функциональные зоны
  // Функциональные зоны - ggk.gpzone
  ggk_func_zone: {
    getTitle: props => props.functional,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентификатор объекта</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              microdistrict: [() => <>Микрорайон</>],
              usl_i32: [() => <>Код по классификатору</>],
              implementation: [() => <>Статус реализации</>],
              data_update: [() => <>Дата актуализации</>],
              layer_name: [() => <>Наименование функциональной зоны</>],
              layer_fullname: [() => <>Полное наименование функциональной зоны</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>]
            },
          },
        ],
      },
    ],
  },
  // Жилые зоны - ggk.gpzone
  ggk_func_zone_jil: {
    getTitle: props => props.functional,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентификатор объекта</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              microdistrict: [() => <>Микрорайон</>],
              usl_i32: [() => <>Код по классификатору</>],
              implementation: [() => <>Статус реализации</>],
              data_update: [() => <>Дата актуализации</>],
              layer_name: [() => <>Наименование функциональной зоны</>],
              layer_fullname: [() => <>Полное наименование функциональной зоны</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>]
            },
          },
        ],
      },
    ],
  },
  // Общественно-деловая зона - ggk.gpzone
  ggk_func_zone_odz: {
    getTitle: props => props.functional,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентификатор объекта</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              microdistrict: [() => <>Микрорайон</>],
              usl_i32: [() => <>Код по классификатору</>],
              implementation: [() => <>Статус реализации</>],
              data_update: [() => <>Дата актуализации</>],
              layer_name: [() => <>Наименование функциональной зоны</>],
              layer_fullname: [() => <>Полное наименование функциональной зоны</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>]
            },
          },
        ],
      },
    ],
  },
  // Территории производственной (промышленной) застройки - ggk.gpzone
  ggk_func_zone_prom: {
    getTitle: props => props.functional,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентификатор объекта</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              microdistrict: [() => <>Микрорайон</>],
              usl_i32: [() => <>Код по классификатору</>],
              implementation: [() => <>Статус реализации</>],
              data_update: [() => <>Дата актуализации</>],
              layer_name: [() => <>Наименование функциональной зоны</>],
              layer_fullname: [() => <>Полное наименование функциональной зоны</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>]
            },
          },
        ],
      },
    ],
  },
  // Территории транспортной инфраструктуры - ggk.gpzone
  ggk_func_zone_transport: {
    getTitle: props => props.functional,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентификатор объекта</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              microdistrict: [() => <>Микрорайон</>],
              usl_i32: [() => <>Код по классификатору</>],
              implementation: [() => <>Статус реализации</>],
              data_update: [() => <>Дата актуализации</>],
              layer_name: [() => <>Наименование функциональной зоны</>],
              layer_fullname: [() => <>Полное наименование функциональной зоны</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>]
            },
          },
        ],
      },
    ],
  },
  // Зона обеспеченности инженерными коммуникациями - ggk.gpzone
  ggk_func_zone_eng: {
    getTitle: props => props.functional,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентификатор объекта</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              microdistrict: [() => <>Микрорайон</>],
              usl_i32: [() => <>Код по классификатору</>],
              implementation: [() => <>Статус реализации</>],
              data_update: [() => <>Дата актуализации</>],
              layer_name: [() => <>Наименование функциональной зоны</>],
              layer_fullname: [() => <>Полное наименование функциональной зоны</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>]
            },
          },
        ],
      },
    ],
  },
  // Охраняемые территории - ggk.gpzone
  ggk_func_zone_protect: {
    getTitle: props => props.functional,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентификатор объекта</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              microdistrict: [() => <>Микрорайон</>],
              usl_i32: [() => <>Код по классификатору</>],
              implementation: [() => <>Статус реализации</>],
              data_update: [() => <>Дата актуализации</>],
              layer_name: [() => <>Наименование функциональной зоны</>],
              layer_fullname: [() => <>Полное наименование функциональной зоны</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>]
            },
          },
        ],
      },
    ],
  },
  // Зоны сельскохозяйственного и лесохозяйственного назначения - ggk.gpzone
  ggk_func_zone_agricult: {
    getTitle: props => props.functional,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентификатор объекта</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              microdistrict: [() => <>Микрорайон</>],
              usl_i32: [() => <>Код по классификатору</>],
              implementation: [() => <>Статус реализации</>],
              data_update: [() => <>Дата актуализации</>],
              layer_name: [() => <>Наименование функциональной зоны</>],
              layer_fullname: [() => <>Полное наименование функциональной зоны</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>]
            },
          },
        ],
      },
    ],
  },
  // Рекреационные зоны - ggk.gpzone
  ggk_func_zone_rec: {
    getTitle: props => props.functional,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентификатор объекта</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              microdistrict: [() => <>Микрорайон</>],
              usl_i32: [() => <>Код по классификатору</>],
              implementation: [() => <>Статус реализации</>],
              data_update: [() => <>Дата актуализации</>],
              layer_name: [() => <>Наименование функциональной зоны</>],
              layer_fullname: [() => <>Полное наименование функциональной зоны</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>]
            },
          },
        ],
      },
    ],
  },
  // Резервные территории - ggk.gpzone
  ggk_func_zone_rez: {
    getTitle: props => props.functional,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентификатор объекта</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              microdistrict: [() => <>Микрорайон</>],
              usl_i32: [() => <>Код по классификатору</>],
              implementation: [() => <>Статус реализации</>],
              data_update: [() => <>Дата актуализации</>],
              layer_name: [() => <>Наименование функциональной зоны</>],
              layer_fullname: [() => <>Полное наименование функциональной зоны</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>]
            },
          },
        ],
      },
    ],
  },
  // Неудобные и неиспользуемые территории - ggk.gpzone
  ggk_func_zone_nnt: {
    getTitle: props => props.functional,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентификатор объекта</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              microdistrict: [() => <>Микрорайон</>],
              usl_i32: [() => <>Код по классификатору</>],
              implementation: [() => <>Статус реализации</>],
              data_update: [() => <>Дата актуализации</>],
              layer_name: [() => <>Наименование функциональной зоны</>],
              layer_fullname: [() => <>Полное наименование функциональной зоны</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>]
            },
          },
        ],
      },
    ],
  },
  // Зоны режимных территорий - ggk.gpzone
  ggk_func_zone_restrict: {
    getTitle: props => props.functional,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентификатор объекта</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              microdistrict: [() => <>Микрорайон</>],
              usl_i32: [() => <>Код по классификатору</>],
              implementation: [() => <>Статус реализации</>],
              data_update: [() => <>Дата актуализации</>],
              layer_name: [() => <>Наименование функциональной зоны</>],
              layer_fullname: [() => <>Полное наименование функциональной зоны</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>]
            },
          },
        ],
      },
    ],
  },
  // Зоны специального назначения - ggk.gpzone
  ggk_func_zone_spec: {
    getTitle: props => props.functional,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентификатор объекта</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              microdistrict: [() => <>Микрорайон</>],
              usl_i32: [() => <>Код по классификатору</>],
              implementation: [() => <>Статус реализации</>],
              data_update: [() => <>Дата актуализации</>],
              layer_name: [() => <>Наименование функциональной зоны</>],
              layer_fullname: [() => <>Полное наименование функциональной зоны</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>]
            },
          },
        ],
      },
    ],
  },
  // Санитарно-защитные зоны - ggk.gpzone
  ggk_func_zone_san: {
    getTitle: props => props.functional,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентификатор объекта</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              microdistrict: [() => <>Микрорайон</>],
              usl_i32: [() => <>Код по классификатору</>],
              implementation: [() => <>Статус реализации</>],
              data_update: [() => <>Дата актуализации</>],
              layer_name: [() => <>Наименование функциональной зоны</>],
              layer_fullname: [() => <>Полное наименование функциональной зоны</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>]
            },
          },
        ],
      },
    ],
  },
  // Пригородная зона - ggk.gpzone
  ggk_func_zone_sub: {
    getTitle: props => props.functional,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентификатор объекта</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              microdistrict: [() => <>Микрорайон</>],
              usl_i32: [() => <>Код по классификатору</>],
              implementation: [() => <>Статус реализации</>],
              data_update: [() => <>Дата актуализации</>],
              layer_name: [() => <>Наименование функциональной зоны</>],
              layer_fullname: [() => <>Полное наименование функциональной зоны</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>]
            },
          },
        ],
      },
    ],
  },
  // Земли рекультивации - ggk.gpzone
  ggk_func_zone_recult: {
    getTitle: props => props.functional,
    tabs: [
      {
        id: 'info',
        getTitle: () => <>{i18n.t("info-tab.info")}</>,
        groups: [
          {
            props: {
              object_id_i32: [() => <>Идентификатор объекта</>],
              admterr_id: [() => <>Код КАТО с портала</>],
              microdistrict: [() => <>Микрорайон</>],
              usl_i32: [() => <>Код по классификатору</>],
              implementation: [() => <>Статус реализации</>],
              data_update: [() => <>Дата актуализации</>],
              layer_name: [() => <>Наименование функциональной зоны</>],
              layer_fullname: [() => <>Полное наименование функциональной зоны</>],
              osnovanie: [() => <>Ведомственный источник информации</>],
              document: [() => <>Документальный источник информации</>]
            },
          },
        ],
      },
    ],
  },

  ggkgs_gpzone: {
    getTitle: props => props.name_usl,
    tabs: []
  },

  ggkgs_gpreg: {
    getTitle: props => props.name_usl,
    tabs: []
  },

  ggkgs_pdpzone: {
    getTitle: props => props.name_usl,
    tabs: []
  },

  ggkgs_pdpreg: {
    getTitle: props => props.name_usl,
    tabs: []
  },
  ggkgs_invsupplyzonewater: {
    getTitle: props => props.object_name,
    tabs: []
  },
  ggkgs_invsupplyzonegas: {
    getTitle: props => props.object_name,
    tabs: []
  },
  ggkgs_invsupplyzonesewerage: {
    getTitle: props => props.object_name,
    tabs: []
  }, 
  ggkgs_invsupplyzonephone: {
    getTitle: props => props.object_name,
    tabs: []
  }, 
  ggkgs_invsupplyzoneheat: {
    getTitle: props => props.object_name,
    tabs: []
  }, 
  ggkgs_invsupplyzonefecal: {
    getTitle: props => props.object_name,
    tabs: []
  },
  ggkgs_invsupplyzoneelectro: {
    getTitle: props => props.object_name,
    tabs: []
  },
  //

  formatValue: (value, prop, href, handler, handleSelect, downloadError, activeTabId) => {
    switch (prop[1]) {
      case Type.area_m2:
        return (
          <>
            {value ? activeTabId !== 'specification' ? Number(value).toFixed(2) : value : 0} м<sup>2</sup> (
            {value ? activeTabId !== 'specification' ? (Number(value) / 10000).toFixed(4) : value : 0} га)
          </>
        );
      case Type.area_m3:
        return (
          <>
            {value ? activeTabId !== 'specification' ? Number(value).toFixed(2) : value : 0} м<sup>3</sup>
          </>
        );
      case Type.diameter_mm:
        return <>{value} мм</>;
      case Type.area_m2only:
        return (
          <>
            {value ? activeTabId !== 'specification' ? Number(value).toFixed(2) : value : 0} м<sup>2</sup>
          </>
        );
      case Type.area_ga:
        return (
          <>
            {value ? activeTabId !== 'specification' ? (Number(value) * 10000).toFixed(2) : value : 0} м<sup>2</sup> (
            {value ? activeTabId !== 'specification' ? value.toFixed(4) : value : 0} га)
          </>
        );
      case Type.length:
        return <>{value ? activeTabId !== 'specification' ? Number(value).toFixed(2) : value : 0} м</>;
      case Type.cost_kzt:
        return <>{value ? value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ') : 0} тг</>;
      case Type.cost_kzt_2:
        return <>{value ? Number(value).toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ') : 0} тг</>;
      case Type.capacity_kwt:
        return <>{value ? activeTabId !== 'specification' ? Math.round(value) : value : 0} кВт</>;
      case Type.capacity_kwa:
        return <>{value ? activeTabId !== 'specification' ? Math.round(value) : value : 0} кВА</>;
      case Type.capacity_mpa:
        return <>{value ? activeTabId !== 'specification' ? Math.round(value) : value : 0} МПа</>;
      case Type.capacity_gcal_h:
        return <>{value} ГКал/ч</>;
      case Type.capacity_l_sec:
        return <>{value ? <>{value} л/сек</> : ''}</>;
      case Type.capacity_m3_h:
        return (
          <>
            {value ? <>{value} м<sup>3</sup>/ч</> : ''}
          </>
        );
      case Type.capacity_m3_day:
        return (
          <>
            {value ? <>{value} м<sup>3</sup>/сутки</> : ''}
          </>
        );
      case Type.capacity_t_h:
        return <>{value ? <>{value} т/ч</> : ''}</>;
      case Type.link:
        return (
          <a
            href={href}
            target="_blank"
            className="doc-link"
            rel="noopener noreferrer"
            onClick={() => {
              if (!href) {
                if (downloadError) {
                  downloadError();
                }
              }
            }}
          >
            Скачать
          </a>
        );
      case Type.service:
        return (
          <button className="handler" onClick={() => handler()}>
            Выполнить
          </button>
        );
      case Type.othodi:
        return getWasteType(value);
      case Type.esriDate:
        return value ? formatDate(value) : "нет значения";
      case Type.dict:
        return prop[2][value];
      case Type.date:
        return value ? value.split(" ")[0] : null;
      case Type.egov_date:
        return value ? value.split(".")[0] : null;
      case Type.checkInst:
        return value ? checkInstallment(value) : null;
      case Type.geom_text:
        return (
          <svg
              data-tip
              onClick={() => {
              if (value instanceof Object) {
                  handleSelect(new GeoJSON().readGeometry(value), 'EPSG:3857');
              } else {
                  handleSelect({wkt : value});
              }
              }}
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
          >
              <path d="M9 5v2h6.59L4 18.59 5.41 20 17 8.41V15h2V5z" />
          </svg>
        )
      case Type.table:
        return (
          <>
          {value && value.length>0 ?
          <Table>
            <thead>
              <tr>
                {Object.keys(value[0]).map((key) => (
                  <td key={key} style={{textAlign: "center" }}>{key}</td>
                ))}
              </tr>
            </thead>
            
           <tbody>
            {value.map((item, i) => (
                <tr key={i}>
                  {Object.values(item).map((val, k) => (
                    <td key={k} style={{textAlign: "center" }}>{val}</td>
                  ))}
                </tr>
              ))}
           </tbody>
          </Table>
        : <div style={{textAlign: "center", padding: "5px", background: "#02C23E", color: "#fff"}}>Информация отсутствует</div>}
          </>
        )
      default:
        return <>{value}</>;
    }
  },
};

// Функциональные зоны - ggk.gpzone
Properties.ggk_gpzonejil = Properties.ggk_gpzone;
  // Общественно-деловые зоны - ggk.gpzone
Properties.ggk_gpzoneodz = Properties.ggk_gpzone;
// Промышленные (производственные) зоны - ggk.gpzone
Properties.ggk_gpzoneprom = Properties.ggk_gpzone;
// Зоны транспортной инфраструктуры - ggk.gpzone
Properties.ggk_gpzonetransport = Properties.ggk_gpzone;
// Зоны обеспеченности инженерными коммуникациями - ggk.gpzone
Properties.ggk_gpzoneeng = Properties.ggk_gpzone;
// Зоны охраняемых территорий - ggk.gpzone
Properties.ggk_gpzoneprotect = Properties.ggk_gpzone;
// Зоны сельскохозяйственного и лесохозяйственного использования - ggk.gpzone
Properties.ggk_gpzoneagricult = Properties.ggk_gpzone;
// Рекреационные зоны - ggk.gpzone
Properties.ggk_gpzonerec = Properties.ggk_gpzone;
// Зоны резервных территорий - ggk.gpzone
Properties.ggk_gpzonerez = Properties.ggk_gpzone;
  // Неудобные и неиспользуемые территории - ggk.gpzone
Properties.ggk_gpzonennt = Properties.ggk_gpzone;
// Зоны режимных территорий - ggk.gpzone
Properties.ggk_gpzonerestrict = Properties.ggk_gpzone;
// Зоны специального назначения - ggk.gpzone
Properties.ggk_gpzonespec = Properties.ggk_gpzone;
// Санитарно-защитные зоны - ggk.gpzone
Properties.ggk_gpzonesan = Properties.ggk_gpzone;
// Пригородная зона - ggk.gpzone
Properties.ggk_gpzonesub = Properties.ggk_gpzone;
// Земли рекультивации - ggk.gpzone
Properties.ggk_gpzonerecult = Properties.ggk_gpzone;
    
// Жилые зоны - ggk.pdpzone
Properties.ggk_pdpzonejil = Properties.ggk_pdpzone;
// Общественно-деловая зона - ggk.pdpzone
Properties.ggk_pdpzoneodz = Properties.ggk_pdpzone;
// Территории производственной (промышленной) застройки - ggk.pdpzone
Properties.ggk_pdpzoneprom = Properties.ggk_pdpzone;
// Территории транспортной инфраструктуры - ggk.pdpzone
Properties.ggk_pdpzonetransport = Properties.ggk_pdpzone;
// Зона обеспеченности инженерными коммуникациями - ggk.pdpzone
Properties.ggk_pdpzoneeng = Properties.ggk_pdpzone;
// Охраняемые территории - ggk.pdpzone
Properties.ggk_pdpzoneprotect = Properties.ggk_pdpzone;
// Зоны сельскохозяйственного и лесохозяйственного - ggk.pdpzone
Properties.ggk_pdpzoneagricult = Properties.ggk_pdpzone;
// Рекреационные зоны - ggk.pdpzone
Properties.ggk_pdpzonerec = Properties.ggk_pdpzone;
// Резервные территории - ggk.pdpzone
Properties.ggk_pdpzonerez = Properties.ggk_pdpzone;
// Неудобные и неиспользуемые территории - ggk.pdpzone
Properties.ggk_pdpzonennt = Properties.ggk_pdpzone;
// Зоны режимных территорий - ggk.pdpzone
Properties.ggk_pdpzonerestrict = Properties.ggk_pdpzone;
// Зоны специального назначения - ggk.pdpzone
Properties.ggk_pdpzonespec = Properties.ggk_pdpzone;
// Санитарно-защитные зоны - ggk.pdpzone
Properties.ggk_pdpzonesan = Properties.ggk_pdpzone;
// Пригородная зона - ggk.pdpzone
Properties.ggk_pdpzonesub = Properties.ggk_pdpzone;
// Земли рекультивации - ggk.pdpzone
Properties.ggk_pdpzonerecult = Properties.ggk_pdpzone;

function checkInstallment(v) {
  let value;
  if (v != null) {
    if (v === 1) {
      value = "Да"
    } else if (v === 2) {
      value = "Нет"
    }
  }
  return value;

}

function getWasteType(n) {
  switch (n) {
    case 1:
      return "Несакционированный";
    case 2:
      return "Санкционированный";
    case 3:
      return "Выход за границы санкционированного";
    default:
      return "Нет значения";
  }
}

function formatDate(ticks) {
  const date = new Date(ticks);
  const options = { day: "2-digit", month: "2-digit", year: "numeric" };
  return date.toLocaleDateString("ru-RU", options);
}