import TileLayer from "ol/layer/Tile";
import { get } from "ol/proj";
import { TileWMS } from "ol/source";

export default class LayerBundle {

	constructor(wmsUrl, srs, map) {
    this.enabled = true;
    this.layers = new Map();
    this.wmsLayer = new TileLayer({
		  source: new TileWMS({
        url: wmsUrl,
        params: {
          LAYERS: '',
          TILED: true,
        },
        serverType: "geoserver",
        transition: 0,
        projection: srs
		  }),
      visible: false
		});
	  map.addLayer(this.wmsLayer);
  }

  isEnabled() {
    return this.enabled;
  }

  setEnabled(enabled) {
    this.enabled = enabled;
    this.wmsLayer.setVisible(enabled && this.wmsLayer.getSource().getParams().LAYERS.length > 0);
  }

  addLayer(layerName, wmsLayerName, visible) {
    this.layers.set(layerName, {
      wmsLayerName: wmsLayerName,
      visible: visible
    });
    if (visible) {
      this.updateWmsLayer();
    }
  }

  removeLayer(layerName) {
    const layer = this.layers.get(layerName);
    this.layers.delete(layerName);
    if (layer.visible) {
      this.updateWmsLayer();
    }
  }

  getLayerNames() {
    return this.layers.keys();
  }

  hasLayer(layerName) {
    return this.layers.has(layerName);
  }

  isVisible(layerName) {
    return this.visibleLayers.has(layerName);
  }

  setVisible(layerName, visible) {
    this.layers.get(layerName).visible = visible;
    this.updateWmsLayer();
  }

  getWmsLayerName(layerName) {
    return this.layers.get(layerName).wmsLayerName;
  }

  setWmsLayerName(layerName, wmsLayerName) {
    this.layers.get(layerName).wmsLayerName = wmsLayerName;
    this.updateWmsLayer();
  }

  setProjection(proj) {
    const source = this.wmsLayer.getSource();
    source.projection = get(proj);
  }

  updateWmsLayer() {
    const layers = Array.from(this.layers.values()).filter(layer => layer.visible).map(layer => layer.wmsLayerName).join(',');
    const source = this.wmsLayer.getSource();
    const params = source.getParams();
    if (layers !== params.LAYERS) {
      params.LAYERS = layers;
      source.updateParams(params);
      this.wmsLayer.setVisible(this.enabled && params.LAYERS.length > 0);
    }
  }
}