import React from "react";
import acceptImg from "../assets/img/request-send.svg";
import { useTranslation } from 'react-i18next';
import searcgImg from "../assets/img/search-obj.svg";
import "../assets/css/header.css";
import SearchBar from "./SearchBar";
import ReactTooltip from "react-tooltip";

export default function HeaderEkgn({region, setOpenInfo, open, dark}) {
  const { t } = useTranslation();

  return (
    <>
      <div className="ekgn-header-wrapper">
        <button 
          data-tip data-for="searchbtn-tooltip"
          style={region && region.regionCode !== "00" ? {cursor: "pointer"} : {cursor: "default"}}
          onClick={() => {
            if (region && region.regionCode !== "00")
              setOpenInfo(!open)
          }}
          title={region && region.regionCode !== "00" ? t("sidebar-links.1") : ""}
        >
          {(!region || region.regionCode === "00") &&
            <ReactTooltip
              id="searchbtn-tooltip"
              effect="solid"
              border
              type={dark ? "light" : "dark"}
              place="left"
            >
              {t("tooltip.searchbar")}
            </ReactTooltip>
          }
          <img
            src={searcgImg}
            style={{
              width: 25,
              height: 25,
            }}
            alt=""
          />
        </button>
      </div>      
    </>
  );
}
