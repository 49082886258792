import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Table from "./Table";
import svgPie from '../../assets/img/stat_pie.svg';
import svgTable from '../../assets/img/stat_table.svg';
import svgBar from '../../assets/img/stat_bar.svg';
import svgPieActive from '../../assets/img/stat_pie_active.svg';
import svgTableActive from '../../assets/img/stat_table_active.svg';
import svgBarActive from '../../assets/img/stat_bar_active.svg';
import svgClose from '../../assets/img/stat_close.svg';
import PieView from "./PieView";
import { VIEW_PIES, VIEW_BARS, VIEW_TABLE } from "./Stats";
import { useTranslation } from "react-i18next";
import svgGreenDownload from '../../assets/img/green-download.svg';
import svgDownload from '../../assets/img/download.svg';
import Analytic from '../../analytic/Analytic';
import ClipLoader from "react-spinners/ClipLoader";
import { WKT } from "ol/format";


export const Wrapper = styled.div`
  position: absolute;
  left: ${props => props.view === VIEW_BARS ? '98px' : '50%'};
  top: ${props => props.view === VIEW_BARS ? '70px' : '50%'};
  transform: ${props => props.view === VIEW_BARS ? 'initial' : 'translateY(-50%)'};
  height: ${props => props.view === VIEW_BARS ? 'calc(100% - 70px)' : 'initial'};
  width: ${props => props.view === VIEW_BARS ? 'calc(100% - 98px)' : 'initial'};
  font-family: 'Open Sans', sans-serif;ry Monoline OT3;
  z-index: 7
 
  .center-div {
    position: relative;
    left: ${props => props.view === VIEW_BARS ? '0' : '-50%'};
    z-index: ${props => props.view === VIEW_BARS ? '5' : '1'};
    height: ${props => props.view === VIEW_BARS ? '100%' : 'initial'};
    font-family: 'Open Sans', sans-serif;ry Monoline OT3;
    background : rgba(255, 255, 255, 1);
    border-radius : 13px;
    box-shadow : ${props => props.view === VIEW_BARS ? "initial" : "0px 0px 16px rgba(102, 102, 102, 0.55)"};
    filter: progid:DXImageTransform.Microsoft.dropshadow(OffX=0, OffY=0, Color='#666666') ;
    display: flex;
    flex-direction: column;

  .trigger-stats {
    grid-column: 1/4;
    padding-bottom: 15px
    margin-left: 15px;
    font-family: 'Open Sans', sans-serif;ry Monoline OT3;
    font-size : 16px;
    color: #005A2F;
  }
  }
`;

export const Header = styled.div`
  height: 59px;
  display: flex; align-items: center;
  padding: ${props => props.view === VIEW_BARS ? "0px 60px 0px 20px" : "0px 80px 0px 20px"};
  background : ${props => props.view === VIEW_BARS ? "#e4e5e8" : "-moz-linear-gradient(50% 0.27% -90deg,rgba(2, 194, 62, 1) 0%,rgba(0, 168, 36, 1) 100%)"};
  background : ${props => props.view === VIEW_BARS ? "#e4e5e8" : "-webkit-linear-gradient(-90deg, rgba(2, 194, 62, 1) 0%, rgba(0, 168, 36, 1) 100%)"};
  background : ${props => props.view === VIEW_BARS ? "#e4e5e8" : "-webkit-gradient(linear,50% 0.27% ,50% 100.56% ,color-stop(0,rgba(2, 194, 62, 1) ),color-stop(1,rgba(0, 168, 36, 1) ))"};
  background : ${props => props.view === VIEW_BARS ? "#e4e5e8" : "-o-linear-gradient(-90deg, rgba(2, 194, 62, 1) 0%, rgba(0, 168, 36, 1) 100%)"};
  background : ${props => props.view === VIEW_BARS ? "#e4e5e8" : "-ms-linear-gradient(-90deg, rgba(2, 194, 62, 1) 0%, rgba(0, 168, 36, 1) 100%)"};
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#02C23E', endColorstr='#00A824' ,GradientType=0)";
  background : ${props => props.view === VIEW_BARS ? "#e4e5e8" : "linear-gradient(180deg, rgba(2, 194, 62, 1) 0%, rgba(0, 168, 36, 1) 100%)"};
  border-radius : ${props => props.view === VIEW_BARS ? "0" : "12px 12px 0px 0px"};
  -moz-border-radius : ${props => props.view === VIEW_BARS ? "0" : "12px 12px 0px 0px"};
  -webkit-border-radius : ${props => props.view === VIEW_BARS ? "0" : "12px 12px 0px 0px"};
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#02C23E',endColorstr='#00A824' , GradientType=0);

  button {
    z-index: 2;
    border-radius: 6px;
    border: none;
    outline: none;
    background: ${props => props.view === VIEW_BARS ? "#01611f" : "#fff"};
    height: 33px;
    padding: 2px 4px 0px 4px;
    width: ${props => props.view === VIEW_BARS ? "33px" : "initial"};

    img {
      width: ${props => props.view === VIEW_BARS ? "15px" : "25px"};
      margin: 0;
      height: ${props => props.view === VIEW_BARS ? "15px" : "25px"};
    }
  }
`;

export const Title = styled.div`
  font-family: 'Open Sans', sans-serif;ry Monoline OT3;
  font-weight : bold;
  font-size : 20px;
  color : #FFFFFF;
  color : rgb(255, 255, 255);
  flex: auto;
`;

const ToggleButton = styled.div`
  z-index: 2;
  float: right;
  width: 33px;
  height: 33px;
  margin-right: 9px;
  background-image: url(${props => props.icon});
  background-size: 16px 16px;
  background-position: center;
  background-repeat: no-repeat;
  background-color: ${props => props.view === VIEW_BARS ? (props.active ? '#02bf3b' : '#01611f') : (props.active ? '#01611f' : '#ffffff')};
  border-radius : 6px;
  -moz-border-radius : 6px;
  -webkit-border-radius : 6px;
  box-shadow : 0px 0px 5px rgba(102, 102, 102, 0.25);
  filter: progid:DXImageTransform.Microsoft.dropshadow(OffX=0, OffY=0, Color='#666666');

  :hover {
    cursor: pointer;
  }

`;

export const CloseButton = styled.div`
  position: absolute;
  top: ${props => props.view === VIEW_BARS ? "initial" : "-18px"};
  right: ${props => props.view === VIEW_BARS ? "18px;" : "32px"};
  display: flex;
  width: ${props => props.view === VIEW_BARS ? "33px" : "36px"};
  height: ${props => props.view === VIEW_BARS ? "33px" : "36px"};
  border-radius : ${props => props.view === VIEW_BARS ? "6px" : "50%"};
  ${props => props.view === VIEW_BARS ? "background-color: #db3321" : "background-image: linear-gradient(139.76deg,rgb(255, 73, 44) 0%,rgb(144, 5, 10) 100%)"};  
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: ${props => props.view === VIEW_BARS ? "initial" : "3px 3px 15px #003e13bf"};

  :hover {
    cursor: pointer;
  }
`;

const CanterContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;

  div {
    width: 100px;
    height: 100px;
  }
`;

export default function BarChart(props) {

  const mode = props.view;
  const [chartData, setChartData] = useState();
  const { t } = useTranslation();
  useEffect(() => {
    if (props.stats.items) {
      setChartData(true);
    }
  }, [props.stats]);

  const handleType = type => props.stats.chartType = type;

  return chartData ? (
    <Wrapper
      palette={props.theme}
      view={mode}
      responsive={props.responsive}
    >
      <div className="center-div">
        <Header view={mode}>
          <Title>{props.stats.title}</Title>
          {props.stats.parentXId.id === 2185641254 && !props.analyticLoading &&
            <ToggleButton
              view={mode}
              icon={mode === VIEW_PIES || mode === VIEW_BARS ? svgPieActive : svgPie}
              active={mode === VIEW_PIES}
              onClick={() => {
                props.setItem(null);
                props.setView(VIEW_PIES);
                if (props.region && props.region.geom) {
                  props.handleSelect({ wkt: props.region.geom }, 'EPSG:3857');
                }
              }} />
          }

          <ToggleButton
            view={mode}
            icon={mode === VIEW_BARS ? svgBarActive : svgBar}
            active={mode === VIEW_BARS}
            onClick={() => props.setView(VIEW_BARS)}
          />
          <ToggleButton
            view={mode}
            icon={mode === VIEW_TABLE || mode === VIEW_BARS ? svgTableActive : svgTable}
            active={mode === VIEW_TABLE}
            onClick={() => props.setView(VIEW_TABLE)}
          />
          <button
            onClick={props.handleLoadStats}
          >
            <img src={mode === VIEW_BARS ? svgDownload : svgGreenDownload} alt="" />
          </button>
          <CloseButton view={mode} onClick={() => props.handleCloseStats()}>
            <img src={svgClose} style={{ width: '10px', margin: 'auto' }} alt="" />
          </CloseButton>
        </Header>
        {mode === VIEW_BARS && (
          <Analytic
            chartData={props.stats.chartData}
            nodesNamesX={props.stats.nodesNamesX}
            nodesNamesY={props.stats.legend}
            parentXId={props.stats.parentXId}
            parentYId={props.stats.parentYId}
            fact={props.stats.fact}
            aggType={props.stats.aggType}
            aggField={props.stats.aggField}
            xAxes={props.stats.xAxes}
            yAxes={props.stats.yAxes}
            type={props.stats.chartType}
            handleType={handleType}
            handleStats={props.handleStats}
            analyticLoading={props.analyticLoading}
            setAnalyticLoading={props.setAnalyticLoading}
          />
        )}

        {mode === VIEW_TABLE && (<Table palette={props.theme} stats={props.stats} xDimName={props.stats.parentXId.text} />)}
        {mode === VIEW_PIES && (
          <PieView
            legend={props.stats.legend}
            item={props.stats.legend.map(e => props.item.data[e.id])}
            title={props.item.name}
          />
        )}
      </div>
    </Wrapper>
  ) : <CanterContent><ClipLoader color={"#02c23e"} loading={true} /></CanterContent>;
}
