import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';


let mapDivs = document.getElementsByClassName('or3-gis-map');
for (let i = 0; i < mapDivs.length; i++) {
    const mapDiv = mapDivs[i];

    if (mapDiv.childElementCount === 0) {
        ReactDOM.render(
            <I18nextProvider i18n={i18n}>
                <App
                    ref={(comp) => { window[`map_${mapDiv.getAttribute("id")}`] = comp }}
                    id={mapDiv.getAttribute("id")}
                /></I18nextProvider>, mapDiv);
    }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();