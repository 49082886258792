import React, { useState } from "react";
import Pie from './Pie';
import styled from 'styled-components';

const View = styled.div`
  display: flex;
  padding: 30px;

  .pie-view {
    margin: 0 10px;
    display: block;
    margin-top: auto;
    margin-bottom: auto;
  }
`;

const LegendPie = styled.div`
  overflow-x: auto;
  margin-left: 30px;

  table {
    border-spacing: 0;
    margin: auto;
    overflow-x: auto;
    max-height: 355px;
    display: block;
    width: fit-content;
    border-collapse: collapse;

    ::-webkit-scrollbar {
      width: 16px;
      height: 16px;
    }
    ::-webkit-scrollbar-thumb {
      border: 8px solid rgba(0, 0, 0, 0);  
      background-clip: padding-box;
      -webkit-border-radius: 0;
      background-color: rgba(0, 0, 0, 0.15);
      -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.025);
      border-right-width: 0;
      border-left-width: 8px;
      border-bottom-width: 0;
      border-top-width: 8px;
    }
    ::-webkit-scrollbar-button {
      width: 0;
      height: 0;
      display: none;
    }
    ::-webkit-scrollbar-corner {
        background-color: transparent;
    }

    ::-webkit-scrollbar-track {
      background: none;
      -webkit-box-shadow: none;
      border-radius: none;
    }
  }

  td {
    border: 2px solid #707070; 
  }

  .label {
    text-align: end;
    padding: 5px;
    border-left: none;
    border-right: none;
    font-weight: bold;
  }

  .value {  
    padding: 5px;
    padding-left: 11px;
    border-right: none;
  }

  .icon {
    border-left: none;
  }

  .label, .value {
    font-size : 15px;
    line-height : 21.27px;
    color : #005A2F;
    max-width: 400px;
  }

  .title {
    text-align: center;
    font-weight : bold;
    font-size : 18px;
    color : #005A2F;
    margin-bottom: 10px;
  }
`;

const Icon = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${props => props.color};
  margin: 6px 10px 5px 10px;
  border-radius: 4px;
  padding: 3px;
`;

const PieView = ({ legend, item, title }) => {

  const [selected, setSelected] = useState(-1);
  const legendItems = [];

  legend.forEach((obj, i) => {
    if (item[i]) {
      legendItems.push(<tr
        key={i}
        onClick={() => setSelected(i)}
      >
        <td className="label">
          {item[i]}
        </td>
        <td className="icon">
          <Icon color={obj.color || "#000"} />
        </td>
        <td className="value">
          {obj.text}
        </td>
      </tr>);
    }
  }
  );

  return (
    <View>
      <Pie
        className="pie-view"
        width={200} height={200}
        colors={legend.map(obj => obj.color)}
        items={Object.keys(item).map(key => item[key])}
        selected={selected}
        setSelected={setSelected}
      />
      <LegendPie>
        <div className="title">{title}</div>
        <table>
          <tbody>
            {legendItems}
          </tbody>
        </table>
      </LegendPie>
    </View>
  )
}

export default PieView;