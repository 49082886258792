import React from "react";
import styled from "styled-components";
import Collapsible from "react-collapsible";
import { Scrollbars } from "react-custom-scrollbars";
import { WKT, GeoJSON } from "ol/format";

const RegionSelect = styled.div`
  // width: ${props => props.responsive.mobile ? "100%" : "498px"};
  width: 100%;
  z-index: ${props => props.responsive.mobile ? "5" : "1"};
  top: ${props => props.responsive.mobile ? "62px" : props.top};
  height: ${props => props.responsive.mobile ? "100%" : "auto"};

  box-sizing: border-box;
  position: absolute;
  right: 0px;
  overflow: hidden;
  background: #ffffff;
  background: rgba(255, 255, 255, 1);
  border-style: Solid;
  border-color: #c9d1de;
  border-color: rgba(201, 209, 222, 1);
  border-width: 1px;
  border-radius: 7px;
  -moz-border-radius: 7px;
  -webkit-border-radius: 7px;

  .Collapsible:last-child {
    padding-bottom: 8px;
  }

  .scrollbar-view {
    // max-height: calc(100vh - 70px) !important;
    max-height: 24vh !important;
  }

  p {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    line-height: 25px;
    color: #333333;
    color: rgb(51, 51, 51);
    padding: 0;
    margin: 0;
    cursor: pointer;
    margin-top: 3px;
  }
  p::before {
    content: "• ";
    margin-left: 5px;
  }
  background: #e6e6e6;
`;

export default function ChooseAto(props) {
  return (
    <RegionSelect palette={props.theme} responsive={props.responsive} top={props.top}>
      <Scrollbars
        className="select-region-div"
        autoHeight={true}
        autoHide={true}
        autoHeightMax={"calc(100vh - 70px)"}
        renderView={props => <div {...props} className="scrollbar-view" />}
        
      >

         {props.so && props.so.map((so,i) => (
          
          <p
          key={i+'-'+so.kato}
          onClick={() => {
            if (so.geom instanceof Object) {
              props.handleSelect({geometry: new GeoJSON().readGeometry(so.geom)});
            } else {
              props.handleSelect({wkt: so.geom});
            }
            props.setSoName(so.name);
            props.setOpenSo(false);
          }}

        >
          {so.name}
        </p>
        ))}
        
        {props.ate && props.ate.map((ate,i) => (
          
          <p
          key={i+'-'+ate.kato}
          onClick={() => {
            if (ate.geom instanceof Object) {
              props.handleSelect({geometry: new GeoJSON().readGeometry(ate.geom)});
            } else {
              props.handleSelect({wkt: ate.geom});
            }
            props.setAteName(ate.name);
            props.setOpenAte(false);
          }}

        >
          {ate.name}
        </p>
        ))}
        {props.category && props.category.map(category => (
          
          <p
          key={category.rn_code}
          onClick={(e) => {
            props.setCategoryChoose(category)
            // props.publicService.categoryChoose=category;
            // props.setPublicService(Object.assign({}, props.publicService));
            props.setOpenCategory(false);
          }}
        >
          {category.name}
        </p>
        ))}

        {props.cause && props.cause.map(cause => (
          
          <p
          key={cause.code}
          onClick={(e) => {
            props.setCauseId(cause.code)
            props.setCauseName(cause.name);
            // props.publicService.causeId=cause.code;
            // props.publicService.causeName=cause.name;
            // props.setPublicService(Object.assign({}, props.publicService));
            props.setOpenCause(false);
            if (props.causeChange) {
              props.causeChange(cause.name);
            }
          }}
        >
          {cause.name}
        </p>
        ))}

        {props.method && props.method.map((method,i) => (
          
          <p
          key={i}
          onClick={(e) => {
            props.setMethodName(method)
            props.setOpenMethod(false)
            if (props.methodChange) {
              props.methodChange(method);
            }
          }}
        >
          {method}
        </p>
        ))}

      </Scrollbars>
    </RegionSelect>
  );
}


