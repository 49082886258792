import React from "react";
import styled from "styled-components";
import svgRefresh from '../../assets/img/refresh.svg';
import { useTranslation } from "react-i18next";
import { Scrollbars } from "react-custom-scrollbars";

const MobileProfileWrapper = styled.div`
  position: absolute;
  top: 114px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 44px;
  z-index: 4;

  .profile-wrap-mob {
    background: #FFFFFF;
    box-shadow: 2px 2px 10px rgb(0 0 0 / 38%);
    border-radius: 10px;
    width: 100%;
    padding: 6px 6px 8px 6px;
  }

  .profile-name-mob {
    background: #A0B6A6;
    border-radius: 3px;
    font-family: "sf-pro-display-regular";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
    width: 100%;
    padding: 6px 6px 4px 6px;
  }

  .prof-info-mob {
    border: 1px solid #B4CFBC;
    border-radius: 3px;
    font-family: "sf-pro-display-regular";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #779480;
    padding: 6px 10px;
    margin-top: 4px;
  }

  .queue-info-mob {
    background: #B4CFBC;
    border-radius: 3px;
    font-family: "sf-pro-display-regular";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
    padding: 4px 12px 4px 16px;
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .load {
    -webkit-animation-name: spin;
    -webkit-animation-duration: 4000ms;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: spin;
    -moz-animation-duration: 4000ms;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: spin;
    -ms-animation-duration: 4000ms;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    
    animation-name: spin;
    animation-duration: 4000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @-ms-keyframes spin {
      from { -ms-transform: rotate(0deg); }
      to { -ms-transform: rotate(360deg); }
  }
  @-moz-keyframes spin {
      from { -moz-transform: rotate(0deg); }
      to { -moz-transform: rotate(360deg); }
  }
  @-webkit-keyframes spin {
      from { -webkit-transform: rotate(0deg); }
      to { -webkit-transform: rotate(360deg); }
  }
  @keyframes spin {
      from {
          transform:rotate(0deg);
      }
      to {
          transform:rotate(360deg);
      }
  }

  .queue-prof-wrap {
    padding: 8px 16px;
    border: 1px solid #B4CFBC;
    border-radius: 3px;
    margin-top: 4px;
  }

  .prof-lbl-mob {
    font-family: "sf-pro-display-regular";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #A0B6A6;
    margin-bottom: 2px;
  }

  .prof-val-mob {
    font-family: "sf-pro-display-regular";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #294A37;
  }

  .prof-line {
    border-bottom: 1px solid #B4CFBC;
    margin: 6px 0;
  }

  .scrollbar-view {
    max-height: calc(100vh - 300px) !important;
  }

  .prof-logout-btn {
    width: 100%;
    background: #D00606;
    border-radius: 3px;
    font-family: "sf-pro-display-regular";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 0px;
  }
`;

export default function MobileProfile({
  userInfo, handleLogout, handleUpdateQueue, setAte,
  handleOpenQueue, setQueueNum, handleClickProfile,
  userMobIconRef
}) {

  const { i18n, t } = useTranslation();
  const [load, setLoad] = React.useState(false);
  const hasQueues = !!userInfo.queues.length;
  const profileRef = React.useRef();

  React.useEffect(() => {
    function handleClickOutside(event) {
      if (profileRef.current && !profileRef.current.contains(event.target) &&
        userMobIconRef.current && !userMobIconRef.current.contains(event.target)) {
        handleClickProfile();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [profileRef]);

  return (
    <MobileProfileWrapper ref={profileRef}>
      <div className="profile-wrap-mob">
        <div className="profile-name-mob">{userInfo.fullName}</div>
        <div className="info-wrap">
          <div className="prof-info-mob">
            <div>ИИН: {userInfo.iin}</div>
          </div>
          {userInfo.email && userInfo.email !== "null" && (
            <div className="prof-info-mob">
              <div>Email: {userInfo.email}</div>
            </div>
          )}
          {userInfo.orgNameRu && userInfo.orgNameRu !== "null" && (
            <div className="prof-info-mob">
              <div>{t("user-profile.organization")} {userInfo.orgNameRu}</div> 
            </div>
          )}
          {userInfo.bin && userInfo.bin !== "null" && (
            <div className="prof-info-mob">
              <div>Бин: {userInfo.bin}</div>
            </div>
          )}
        </div>

        <div className="queue-info-mob">
          {hasQueues ?
            <div>{t("user-profile.queues")}</div>
            :
            <div>{t("user-profile.stand-line")}</div>
          }
          <div
            className={load ? " load" : ""}
            href=""
            onClick={() => {
              setLoad(true);
              handleUpdateQueue(i18n.language)
                .then(() => setLoad(false))
                .catch(e => {
                  console.err(e);
                  setLoad(false);
                });
            }}
          >
            <img src={svgRefresh} alt="refresh" />
          </div>
        </div>

        <Scrollbars
          autoHeight={true}
          autoHide={true}
          autoHeightMax={"calc(100vh - 300px)"}
          renderView={props => <div {...props} className="scrollbar-view" />}
        >
          {hasQueues && userInfo.queues.map((item, i) =>
            <div key={i} className="queue-prof-wrap"
              onClick={() => {
                setAte(item.ate);
                setQueueNum(item.num);
                handleClickProfile();
                handleOpenQueue();
              }}
            >
              <div className="prof-lbl-mob">{t("info-tab.status")}</div>
              <div className="prof-val-mob">{item.status}</div>
              <div className="prof-line" />
              <div style={{ display: "flex" }}>
                <div>
                  <div className="prof-lbl-mob">{t("queue.num")}</div>
                  <div className="prof-val-mob">{item.app_num}</div>
                </div>
                <div>
                  <div className="prof-lbl-mob">{t("user-profile.date")}</div>
                  <div className="prof-val-mob">{item.app_date}</div>
                </div>
              </div>
              <div className="prof-line" />
              <div className="prof-lbl-mob">{t("user-profile.region")}</div>
              <div className="prof-val-mob">{item.region}</div>
            </div>
          )}
        </Scrollbars>

        <div
          className="prof-logout-btn"
          href=""
          onClick={event => {
            handleLogout();
            event.preventDefault();
          }}
        >
          {t("user-profile.exit")}
        </div>
      </div>
    </MobileProfileWrapper>
  );
}
