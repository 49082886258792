import React from "react";
import Checkbox from "./Checkbox";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import closeImg from "../../assets/img/stat_close.svg";
import opacityScaleImg from "../../assets/img/opacityScale.svg";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  root: {
    color: '#31D437',
    height: 5,
    zIndex: 1,
  },
  thumb: {
    background: '#00C533',
    border: '1px Solid #FFFFFF',
    width: 14,
    height: 14,
    boxShadow: '1.3px 1.3px 3.91px rgba(0, 90, 0, 0.35)',
    filter: "progid:DXImageTransform.Microsoft.dropshadow(OffX=1.3, OffY=1.3, Color='#005A00')",

    marginTop: -4,
    marginLeft: -6,
    '&:focus,&:hover,&$active': {
      boxShadow: '1.3px 1.3px 3.91px rgba(0, 90, 0, 0.35)',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 1px)',
    top: -18,
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 10,
    fontColor: '#FFFFFF',
    '&>span': {
      width: 22,
      height: 22,
    },
  },
  track: {
    height: (props) => props.standalone ? 5 : 2,
    borderRadius: 2,
    background: '#31D437',
    border: '1px Solid #005b2d',
    display: 'inherit !important'
  },
  rail: {
    height: (props) => props.standalone ? 5 : 2,
    borderRadius: 2,
    opacity: 1,
    background: '#FFFFFF',
    border: '1px Solid #005b2d'
  }
}))

const SliderMUI = ({
  theme,
  handleCloseSlider,
  defaultValue,
  handleSliderChange,
  viewportOffset,
  sliderName,
  showLayer,
  opacityTop,
  opacityLeft,
  standalone
}) => {
  const [labelVisibility, setLabelVisibility] = React.useState({
    lands_label: true,
    buildings_label: true,
    pch_label: true,
    rst_label: true,
  });

  const handleLabelVisibility = sliderName => {
    const layerLabel = `${sliderName}_label`;
    const newShowState = !labelVisibility[layerLabel];
    const newLabelVisibility = Object.assign({}, labelVisibility);
    newLabelVisibility[layerLabel] = newShowState;
    setLabelVisibility(newLabelVisibility);
    showLayer(null, layerLabel, newShowState);
  };
  
  const { t } = useTranslation();

  const classes = useStyles({standalone});


  return (
    //style={props.standalone ? style1 : style2}
    <div className="opacity-window"       style={{ top: opacityTop, left:  standalone ? opacityLeft : "-400px"  }}>
      <div className="opacity-title">
        <span style={{fontSize: '15px', color: 'rgb(255, 255, 255)'}}>{t('manage-map.1')}   (%):</span>
      </div>
      <button className="opacity-close-btn" onClick={handleCloseSlider}>
        <img src={closeImg} style={{ width: 8, marginLeft: -1 }} alt="close btn" />
      </button>
      <div className="opacity-slider">
        {/* <PrettoSlider valueLabelDisplay="auto" aria-label="pretto slider" value={defaultValue} onChange={handleSliderChange} /> */}
        <Slider
          classes={{
            root: classes.root,
            thumb: classes.thumb,
            active: classes.active,
            valueLabel: classes.valueLabel,
            track: classes.track,
            rail: classes.rail
          }}
          valueLabelDisplay="auto" 
          aria-label="pretto slider" 
          value={defaultValue} 
          onChange={handleSliderChange}
        />
        <img src={opacityScaleImg} alt="opacity scale"/>
      </div>

      {["lands", "buildings", "pch", "rst"].includes(sliderName) ? (
        <div className="label-vis">
          <Checkbox
            theme={theme}
            isChecked={labelVisibility[`${sliderName}_label`]}
            checkHandler={() => handleLabelVisibility(sliderName)}
          />
          <span style={{padding: "2px 0 0 20px", display: "revert"}}>{t("manage-map.4")}</span>
        </div>
      ) : null}
    </div>
  );
};

export default SliderMUI;
