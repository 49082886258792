import React from "react";
import "./App.css";
import EknMap from "./EknMap";

window.GS_URL = window.GS_URL || `${window.location.protocol}//${window.location.host}/geoserver`;
window.GGK_GS_URL = window.GGK_GS_URL || `${window.location.protocol}//${window.location.host}/ggkgs`;
window.REST_URL = window.REST_URL || `${window.location.protocol}//${window.location.host}/egkn/rest`;
window.OLAP_URL = window.OLAP_URL || `${window.location.protocol}//${window.location.host}/olap/rest`;
window.COSMO_URL = window.COSMO_URL || `${window.location.protocol}//${window.location.host}/cosmo/rest`;

window.IDP_URL = window.IDP_URL || 'https://idp.egov.kz/idp/oauth/authorize?response_type=code&client_id=egkn&redirect_uri=http://map.gov4c.kz/egkn/rest/sso';
window.IDP_LOGOUT_URL = window.IDP_LOGOUT_URL || 'https://idp.egov.kz/idp/rest/v2/sso/logout?redirect_url=https://map.gov4c.kz';
window.EGOV_URL = window.EGOV_URL || 'http://egov.kz/services';
window.P414_URL = "https://egov.kz/services/P4.14/";
window.EOTN = "https://eotinish.kz/sendAppeal";

//window.P414_URL = "https://egov.kz";
//window.IDP_URL = window.IDP_URL || 'http://vsheptest.test.idp.egov.kz/idp/oauth/authorize?response_type=code&client_id=egkn2&redirect_uri=http://tmap.gov4c.kz/egkn/rest/sso';
//window.EGOV_URL = window.EGOV_URL || 'http://vsheptest.egov.kz/services';

window.CK_EMAIL_VERIFY = 'emailVerify'
window.CK_EMAIL_ERROR = 'emailError';
window.HEAD_FILE_SIZE = 15000000;

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      changeLayerVisibility: {},
      selection: undefined,
      standalone: props.id === "gis1",
    };
    this.id = props.id;

    if (props.id === "gis1") {
//      window.GS_URL = "http://192.168.13.178/geoserver";
      //window.GS_URL = "http://tmap.gov4c.kz/geoserver";
      //window.REST_URL = "http://192.168.13.178/egkn/rest";
//      window.REST_URL = "http://localhost:8080/egkn/rest";
      //window.OLAP_URL = "http://192.168.13.178/olap/rest";
//      window.OLAP_URL = "http://localhost:8080/olap/rest";
      //window.COSMO_URL = "http://192.168.13.178/cosmo/rest";
      //window.COSMO_URL = "http://localhost:8081/cosmo/rest";
    }
  }

  componentDidMount() {
    fetch('./config.json').then(res => res.json()).then(json => {
      this.setState(
        Object.assign({}, this.state, {
          conf: json,
        }))
    });
  }

  render() {
    return (
      <EknMap
        //check={111}
        regions={this.state.regions}
        changeLayerVisibility={this.state.changeLayerVisibility}
        selection={this.state.selection}
        standalone={this.state.standalone}
        selectDistrict={this.state.selectDistrict}
        selectDistrict_ts={this.state.selectDistrict_ts}
        selectCadnum={this.state.selectCadnum}
        selectCadnum_ts={this.state.selectCadnum_ts}
        updateSize={this.state.updateSize}
        extDrawItems={this.state.extDrawItems}
        extEditGeom={this.state.extEditGeom}
        extFitExtent={this.state.extFitExtent}
        extGetNeighbours={this.state.extGetNeighbours}
        extGetIntersections={this.state.extGetIntersections}
        extGetMeasures={this.state.extGetMeasures}
        extGetQuality={this.state.extGetQuality}
        uid={this.id}
        conf={this.state.conf}
      />
    );
  }


  // Внешние функции

  updateSize() {
    this.setState(Object.assign({}, this.state, { updateSize: Date.now() }));
  }

  parseRequest(json) {
    Object.keys(json).forEach(funcName => {
      switch (funcName) {
        case "showLayer":
          this.showLayer(json[funcName].layer, json[funcName].show);
          break;
        case "selectObject":
			    this.selectObject(json[funcName]);
		      break;
        case "selectCadnum":
          this.selectCadnum(json[funcName].cadnum);
          break;
        case "selectDistrict":
          this.selectDistrict(json[funcName].rnCode);
          break;
        case "extDrawItems":
          this.extDrawItems(json[funcName]);
          break;
        case "extEditGeom":
          this.extEditGeom(json[funcName].wkt);
          break;
        case "extStopEditGeom":
          this.extStopEditGeom();
          break;
        case "extFitExtent":
          this.extFitExtent(json[funcName]);
          break;
          case "extGetNeighbours":
          this.extGetNeighbours(json[funcName]);
          break;
        case "extGetIntersections":
          this.extGetIntersections(json[funcName]);
          break;
        case "extGetMeasures":
          this.extGetMeasures(json[funcName]);
          break;
        case "extGetQuality":
          this.extGetQuality(json[funcName]);
          break;
        default:
          break;
      }
    });
  }

  /**
   *
   * @param {*} layerName - наименование слоя (buildibgs, lands, redlines и т.д.)
   * @param {*} show - true or false
   *
   * Например: showLayer('buildings', true)
   */
  showLayer(layerName, show) {
    let arr = {};
    arr[layerName] = show;
    this.setState(
      Object.assign({}, this.state, { changeLayerVisibility: arr }),
    );
  }

  /**
   *
   * @param {*} wkt
   * Например: 'POLYGON((680767.21487425 5793487.61627134,680804.708877024 5793447.73074242,680786.613906474 5793446.80150074,681059.569243689 5793176.6141648,682930.390545765 5791186.44744925,682460.663224626 5790753.40854621,682460.791333795 5790753.26356394,682460.757955872 5790753.23280712,682670.191791482 5790516.2423368,680702.773294162 5789155.94611479,680262.615644875 5789653.60993824,680515.174795243 5789850.50117689,680398.739846767 5790032.30452798,680145.329920468 5790271.98644112,679670.183020981 5789848.32776223,679245.613904825 5790207.46773992,678380.748149366 5790863.172772,678112.003281343 5793081.06219,678093.783462244 5793231.42064128,680744.98967984 5793485.66973821,680745.087548049 5793485.49424938,680767.21487425 5793487.61627134))'
   */
   selectObject(obj) {
    this.setState(
      Object.assign({}, this.state, {
        selection: obj
      }),
    );
  }

  selectCadnum(cadnum) {
    this.setState(
      Object.assign({}, this.state, {
        selectCadnum: cadnum,
        selectCadnum_ts: Date.now(),
      }),
    );
  }

  selectDistrict(rnCode) {
    this.setState(
      Object.assign({}, this.state, {
        selectDistrict: rnCode,
        selectDistrict_ts: Date.now(),
      }),
    );
  }

  extDrawItems(json) {
    this.setState(
      Object.assign({}, this.state, {
        extDrawItems: json,
      }),
    );
  }

  extEditGeom(wkt) {
    this.setState(
      Object.assign({}, this.state, {
        extEditGeom: wkt,
        selection: undefined,
      }),
    );
  }

  extStopEditGeom() {
    this.setState(
      Object.assign({}, this.state, {
        extEditGeom: undefined,
      }),
    );
  }

  extFitExtent(params) {
    this.setState(
      Object.assign({}, this.state, {
        extFitExtent: params,
      }),
    );
  }

  extGetNeighbours(json) {
    this.setState(
      Object.assign({}, this.state, {
        extGetNeighbours: json,
      }),
    );
  }

  extGetIntersections(json) {
    this.setState(
      Object.assign({}, this.state, {
        extGetIntersections: json,
      }),
    );
  }

  extGetMeasures(json) {
    this.setState(
      Object.assign({}, this.state, {
        extGetMeasures: json,
      }),
    );
  }

  extGetQuality(json) {
    this.setState(
      Object.assign({}, this.state, {
        extGetQuality: json,
      }),
    );
  }
}

export default App;
