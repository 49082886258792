import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4lang_ru_RU from "@amcharts/amcharts4/lang/ru_RU";
import * as Constants from './Constants';

function createLineLegend(chart) {
    chart.legend.position = "bottom";
    chart.legend.scrollable = true;
    chart.legend.itemContainers.template.events.on("over", function (event) {
        processOver(event.target.dataItem.dataContext, chart);
    })

    chart.legend.itemContainers.template.events.on("out", function (event) {
        processOut(event.target.dataItem.dataContext, chart);
    })
}

function createLegend(chart, nodesNamesY, type) {
    chart.legend = new am4charts.Legend();
    if (type === "LINE") {
        createLineLegend(chart);
    } else {
        chart.legend.maxHeight = 150;
        chart.legend.scrollable = true;
    }

    let legenddata = chart.legend.data;
    if (legenddata.length) {
        let i;
        for (i in legenddata) {
            let nodeY = nodesNamesY.find(e => e.id === legenddata[i].dataFields.valueY);
            if (nodeY && nodeY.color)
                legenddata[i].fill = nodeY.color;
        }
    } else {
        chart.events.on("ready", function (event) {
            let series = chart.series.values;
            chart.legend.data = series[series.length - 1].dataItems.values;
        });
    }

    if (type === "PIE" || type === "DONUT") {
        chart.legend.position = "right";
        chart.legend.maxHeight = null;
        chart.legend.maxWidth = am4core.percent(30);
    }
}

export const processOut = (hoveredSeries, chart) => {
    chart.series.each(function (series) {
        series.segments.each(function (segment) {
            segment.setState("default");
        })
        series.bulletsContainer.setState("default");
    });
}

export const processOver = (hoveredSeries, chart) => {
    hoveredSeries.toFront();

    hoveredSeries.segments.each(function (segment) {
        segment.setState("hover");
    })

    chart.series.each(function (series) {
        if (series != hoveredSeries) {
            series.segments.each(function (segment) {
                segment.setState("dimmed");
            })
            series.bulletsContainer.setState("dimmed");
        }
    });
}

// Create series
export const createBarSeries = (chart, nodeNameY, i) => {
    let field = nodeNameY.id;
    let name = nodeNameY.name;

    // Set up series
    var series = chart.series.push(new am4charts.ColumnSeries());

    /* Подсвечивание легенды при наведении на колонку */
    /*function myFunction(ev) {
        let legend_values = chart.legend.markers.values;
        legend_values.forEach((el, j) => {
            if (el.dataItem.name === ev)
                el.children.getIndex(0).fill = am4core.color("#ccc");
        });
    }
    series.columns.template.events.on("over", myFunction, this);*/

    series.columns.template.propertyFields.fill = "color" + i;
    series.columns.template.propertyFields.stroke = "color" + i;
    series.name = name;
    series.dataFields.valueY = field;
    series.dataFields.categoryX = Constants.nameX;
    series.sequencedInterpolation = true;

    // Make it stacked
    series.stacked = true;

    // Configure columns
    series.columns.template.width = am4core.percent(60);
    series.columns.template.tooltipText = "[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}";

    // Add label
    var labelBullet = series.bullets.push(new am4charts.LabelBullet());
    labelBullet.label.text = "{valueY}";
    labelBullet.locationY = 0.5;
    labelBullet.label.hideOversized = true;

    return series;
}

export const create3DBarSeries = (chart, nodeNameY, i) => {
    var series = chart.series.push(new am4charts.ColumnSeries3D());
    series.columns.template.propertyFields.fill = "color" + i;
    series.columns.template.propertyFields.stroke = "color" + i;
    series.name = nodeNameY.name;
    series.dataFields.valueY = nodeNameY.id;
    series.dataFields.categoryX = Constants.nameX;
    series.clustered = false;
    series.columns.template.tooltipText = "[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}";
    series.columns.template.fillOpacity = 0.9;
}

export const createPieSeries = (chart, nodeNameY) => {
    // Add and configure Series
    var pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.slices.template.propertyFields.fill = "color";
    pieSeries.slices.template.propertyFields.stroke = "color";
    pieSeries.name = nodeNameY.name;
    pieSeries.dataFields.value = nodeNameY.id;
    pieSeries.dataFields.category = Constants.nameX;
    pieSeries.slices.template.stroke = am4core.color("#fff");
    pieSeries.slices.template.strokeWidth = 2;
    pieSeries.slices.template.strokeOpacity = 1;
    pieSeries.ticks.template.disabled = true;
    pieSeries.labels.template.disabled = true;
    pieSeries.slices.template.tooltipText = "[bold]{name}[/]\n[font-size:14px]{category}: {value}";

    // This creates initial animation
    pieSeries.hiddenState.properties.opacity = 1;
    pieSeries.hiddenState.properties.endAngle = -90;
    pieSeries.hiddenState.properties.startAngle = -90;
}

export const createDonutSeries = (chart, nodeNameY) => {
    // Add and configure Series
    var pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.slices.template.propertyFields.fill = "color";
    pieSeries.slices.template.propertyFields.stroke = "color";
    pieSeries.name = nodeNameY.name;
    pieSeries.dataFields.value = nodeNameY.id;
    pieSeries.dataFields.category = Constants.nameX;
    pieSeries.slices.template.stroke = am4core.color("#fff");
    pieSeries.slices.template.tooltipText = "[bold]{name}[/]\n[font-size:14px]{category}: {value}";

    // Disabling labels and ticks on inner circle
    pieSeries.labels.template.disabled = true;
    pieSeries.ticks.template.disabled = true;

    // Disable sliding out of slices
    pieSeries.slices.template.states.getKey("hover").properties.shiftRadius = 0;
    pieSeries.slices.template.states.getKey("hover").properties.scale = 1.0;
}

export const createLineSeries = (chart, nodeNameY, i) => {
    var series = chart.series.push(new am4charts.LineSeries());
    series.propertyFields.fill = "color" + i;
    series.propertyFields.stroke = "color" + i;
    series.dataFields.dateX = Constants.nameX;
    series.dataFields.valueY = nodeNameY.id;
    series.name = nodeNameY.name;
    series.tooltipText = "{valueY}";
    series.tooltip.pointerOrientation = "vertical";
    series.tooltip.background.fillOpacity = 0.5;
    series.strokeWidth = 3;

    var segment = series.segments.template;
    segment.interactionsEnabled = true;

    var hoverState = segment.states.create("hover");
    hoverState.properties.strokeWidth = 3;

    var dimmed = segment.states.create("dimmed");
    dimmed.properties.stroke = am4core.color("#dadada");

    segment.events.on("over", function (event) {
        processOver(event.target.parent.parent.parent, chart);
    });

    segment.events.on("out", function (event) {
        processOut(event.target.parent.parent.parent, chart);
    });
}

export const createChart = (chartData, nodesNamesY, type, showLegend) => {
    let chart;
    am4core.useTheme(am4themes_animated);
    let i;
    if (type === "BAR") {
        // Create chart instance
        chart = am4core.create('pkk', am4charts.XYChart)

        // Create axes
        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = Constants.nameX;
        categoryAxis.renderer.grid.template.location = 0;

        categoryAxis.renderer.minGridDistance = 30;

        // Configure axis label
        let label = categoryAxis.renderer.labels.template;
        label.wrap = true;

        categoryAxis.events.on("sizechanged", function (ev) {
            let axis = ev.target;
            let cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);
            axis.renderer.labels.template.maxWidth = cellWidth;
        });

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.inside = true;
        valueAxis.renderer.labels.template.disabled = true;
        valueAxis.min = 0;

        for (i in nodesNamesY) {
            createBarSeries(chart, nodesNamesY[i], i);
        }
    } else if (type === "PIE") {
        chart = am4core.create('pkk', am4charts.PieChart);

        for (i in nodesNamesY) {
            createPieSeries(chart, nodesNamesY[i]);
        }
    } else if (type === "LINE") {
        chart = am4core.create('pkk', am4charts.XYChart);
        chart.paddingRight = 20;

        let xAxis = chart.xAxes.push(new am4charts.DateAxis());
        xAxis.renderer.grid.template.location = 0;
        xAxis.minZoomCount = 5;

        // this makes the data to be grouped
        xAxis.groupData = true;
        xAxis.groupCount = 500;

        chart.yAxes.push(new am4charts.ValueAxis());

        for (i in nodesNamesY) {
            createLineSeries(chart, nodesNamesY[i], i);
        }

        chart.cursor = new am4charts.XYCursor();
        chart.cursor.xAxis = xAxis;

        let scrollbarX = new am4core.Scrollbar();
        scrollbarX.marginBottom = 20;
        chart.scrollbarX = scrollbarX;
    } else if (type === "3DBAR") {
        chart = am4core.create('pkk', am4charts.XYChart3D);

        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = Constants.nameX;
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 30;

        let label = categoryAxis.renderer.labels.template;
        label.wrap = true;
        categoryAxis.events.on("sizechanged", function (ev) {
            let axis = ev.target;
            let cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);
            axis.renderer.labels.template.maxWidth = cellWidth;
        });

        chart.yAxes.push(new am4charts.ValueAxis());

        for (i in nodesNamesY) {
            create3DBarSeries(chart, nodesNamesY[i], i);
        }
    } else if (type === "DONUT") {
        // Create chart instance
        chart = am4core.create('pkk', am4charts.PieChart);

        // Let's cut a hole in our Pie chart the size of 40% the radius
        chart.innerRadius = am4core.percent(40);

        for (i in nodesNamesY) {
            createDonutSeries(chart, nodesNamesY[i]);
        }
    }

    chart.data = chartData;
    chart.language.locale = am4lang_ru_RU;
    chart.exporting.filePrefix = 'Aналитика';

    createLegend(chart, nodesNamesY, type);
    chart.legend.disabled = !showLegend;

    return chart;
}