import React, { useCallback } from "react";
import svgRightArrow from '../assets/img/right-arrow.svg'
import svgLeftArrow from '../assets/img/left-arrow.svg'
import svgTwoRightArrow from '../assets/img/two-right-arrow.svg'
import svgTwoLeftArrow from '../assets/img/two-left-arrow.svg'
import { BottomBar, Button } from './PageNav';
import { useTranslation } from "react-i18next";

const PageNavOutPageNum = props => {
    const handleNextPage = useCallback(() => {
        props.getObjects.func(props.pageNum + 1);
        props.setPageNum(props.pageNum + 1);
    }, [props]);

    const handlePrevPage = useCallback(() => {
        props.getObjects.func(props.pageNum - 1);
        props.setPageNum(props.pageNum - 1);
    }, [props]);

    const handleLastPage = useCallback(() => {
        props.getObjects.func(props.count - 1);
        props.setPageNum(props.count - 1);
    }, [props]);

    const handleFirstPage = useCallback(() => {
        props.getObjects.func(0);
        props.setPageNum(0)
    }, [props]);

    const { i18n } = useTranslation();

    return (
        <BottomBar className={props.className} palette={props.theme}>
            {props.pageNum > 0 && (
                <>
                    <Button onClick={handleFirstPage} palette={props.theme} style={{ marginLeft: "0" }}>
                        <img style={{ width: "12px", height: "12px" }} src={svgTwoLeftArrow} />
                    </Button>

                    <Button onClick={handlePrevPage} palette={props.theme}>
                        <img style={{ width: "12px", height: "10px" }} src={svgLeftArrow} />
                    </Button>
                </>
            )}

            {i18n.language === "ru" ?
             <p>Страница {props.pageNum + 1}  из {props.count} </p>
             : i18n.language === "kz" ? <p>{props.count} ішіндегі {props.pageNum + 1} бет</p>
             : <p>Page {props.pageNum + 1}  of {props.count} </p>
            }
           

            {props.pageNum + 1 <= props.count - 1 && (
                <>
                    <Button onClick={handleNextPage} palette={props.theme}>
                        <img style={{ width: "12px", height: "10px" }} src={svgRightArrow} />
                    </Button>

                    <Button onClick={handleLastPage} palette={props.theme}>
                        <img style={{ width: "12px", height: "12px" }} src={svgTwoRightArrow} />
                    </Button>
                </>
            )}
        </BottomBar>
    );
};

export default PageNavOutPageNum;
