import React from "react";
import logoGreen from "../assets/img/logo.green.svg";
import acceptImg from "../assets/img/apply.svg";
import authorizeImg from "../assets/img/authorize.svg";
import comboImg from "../assets/img/combo.svg";
import { useTranslation } from "react-i18next";
import "../assets/css/header.css";
import ChooseRegion from "./ChooseRegion";
import i18n from "../i18n";
import UserInfo from "./Cabinet/UserInfo";
import ObjectsAndServices from './Cabinet/ObjectsAndServices';
import Notif from './Cabinet/Notif';
import userInfoImg from "../assets/img/userinfo.svg";
import styled from "styled-components";
import Cookies from "js-cookie";

export const HeadImg = styled.div`
  float: left;
  margin: 0px -5px 0px;
  box-shadow: 0px 3px 6px #00000036;
  border: 1px solid #C8D0DE;
  background: ${props => props.background} 
              url(${props => props.img}) 
              ${props => props.position}% 
              ${props => props.position}% 
              no-repeat padding-box;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-right: 7px;
`

export default function Header({
  handleTitleClick,
  handleRegionClick,
  handleSendApplication,
  handleAuthorizationPressed,
  region,
  openRegion,
  setRegion,
  regions,
  maxHeight,
  showSidebar,
  userInfo,
  handleLogout,
  egovRequest,
  handleUpdateQueue,
  setShowListService,
  setMode,
  handleSelectOrder,
  handleOrders,
  orders,
  handlePayment,
  setShowListMyObjs,
  setShowObjSearch,
  handleCloseStats,
  setOrder,
  order,
  docs,
  handlePostDoc,
  handlePostOther,
  orderLoaded,
  handleDocsType,
  handleClickOrder,
  handleClickOrders,
  handleClickProfile,
  showOrder,
  showOrders,
  showProfile,
  handleClickNotif,
  showNotif,
  notifs,
  handleUpdateNotif,
  handleGetNotifs,
  palette,
  setDisplayFormCreate,
  handlePostCoords,
  handleSelectOnMap,
  setShowMyObjects,
  mode,
  setNotification,
  setIsNewObject,
  setQueueNum,
  setAte,
  handleOpenQueue,
  responsive,
  setShowBurger,
  setChooseRegionMob,
  handleOpenInfo,
  handleOpenLayers,
  setOpenRegionsMob,
  setOpenQueueMob,
  setShowOrders,
  setShowOrder,
  setShowNotif,
  notifPage,
  setNotifPage,
  openLayers,
  chooseRegionMob,
  openInfo,
  setSearchQueueFromReg,
  conf,
  setEgovService,
  setShowLandIdObj,
  setShowProviders,
  setLandInd,
  setShowRegistrationLecensee,
  setShowWorkOrders,
  setCadNum,
  setDocs,
  pdfNote,
  checkPdf,
  fileFormatNote,
  checkFileFormat,
  checkFileSize,
  checkFileSizeNote,
  setShowPlanArchive
}) {
  const { t } = useTranslation();

  const devMode = Cookies.get('mode') === 'dev';

  const checkShowBurger = () => {
    return !openLayers && !chooseRegionMob && !openInfo &&
      !showNotif && !showOrder && !showOrders;
  };

  const closeAll = event => {
    setChooseRegionMob(false);
    handleOpenInfo(false);
    handleOpenLayers(event, false);
    setOpenRegionsMob(false);
    setOpenQueueMob(false);
    setShowOrders(false);
    setShowOrder(false);
    setShowNotif(false);
  };

  let flag;
  if (egovRequest !== undefined) {
    flag = true;
  } else {
    flag = false;
  }
  return (
    <header style={{ paddingRight: responsive.mobile ? "10px" : "40px" }}>
      <table width="100%">
        <tbody>
          <tr>
            {responsive.mobile ?
              <></>
              :
              <>
                <td width={showSidebar ? 340 : responsive.mobile ? 100 : 200}>
                  <img
                    className="headerImg"
                    src={logoGreen}
                    alt=""
                    style={{
                      width: 56,
                      height: 56,
                      float: "right",
                    }}
                    onClick={handleTitleClick}
                  />
                </td>
              </>
            }

            <td /*td width={responsive.mobile ? 340 : 200}*/ style={{ paddingLeft: 10 }}>
              <span className={responsive.mobile ? "headerTitleMob" : "headerTitle"} onClick={handleTitleClick}>
                {t("header.public")}
                <br />
                {t("header.kadastr")}
              </span>
            </td>

            {responsive.mobile &&
              (checkShowBurger() ?
                <td className="burger" onClick={event => {
                  closeAll(event);
                  setShowBurger(true);
                }}>
                  <div className="burger-line" />
                  <div className="burger-line" />
                  <div className="burger-line" />
                </td>
                :
                <td className="close-burger close-all"
                  onClick={closeAll}
                >
                  <div></div>
                  <div></div>
                </td>
              )
            }

            {responsive.mobile ?
              <></>
              :
              <>
                <td width={responsive.mobile ? 100 : 340}>
                  <div className="fld-region">
                    <input
                      type="text"
                      className="header-combo-input"
                      placeholder={t("choose-region.1")}
                      //{props.lang === "kz" ? region.nameKz : props.lang === "ru" ? region.nameRu : region.nameEn}
                      defaultValue={region && (i18n.language === 'kz' ?
                      region.type_kz ? region.nameKz + " " + region.type_kz :  region.nameKz : i18n.language === 'ru' ? region.type ? region.type + " " + region.nameRu : region.nameRu : 
                      region.type ?  region.type + " " + region.nameEn : region.nameEn)}
                      disabled={flag}
                    />
                    <button
                      type="submit"
                      className="header-combo-btn"
                      disabled={flag}
                      onClick={handleRegionClick}
                    >
                      <img
                        src={comboImg}
                        style={{
                          width: 14,
                          height: 7,
                        }}
                        alt=""
                      />
                    </button>
                    {openRegion && (
                      <ChooseRegion
                        setRegion={setRegion}
                        regions={regions}
                        maxHeight={maxHeight}
                        lang={i18n.language}
                        responsive={responsive}
                      />
                    )}
                  </div>
                </td>

                {/* Услуги и объекты */}
                {userInfo && (devMode || conf.showOrders) &&
                  <td width="200">
                    <ObjectsAndServices
                      setLandInd={setLandInd}
                      setShowListService={setShowListService}
                      setMode={setMode}
                      handleSelectOrder={handleSelectOrder}
                      showOrders={showOrders}
                      handleClickOrders={handleClickOrders}
                      handleOrders={handleOrders}
                      orders={orders}
                      handleClickOrder={handleClickOrder}
                      showOrder={showOrder}
                      handlePayment={handlePayment}
                      setShowListMyObjs={setShowListMyObjs}
                      setShowLandIdObj={setShowLandIdObj}
                      setShowProviders={setShowProviders}
                      setShowObjSearch={setShowObjSearch}
                      handleCloseStats={handleCloseStats}
                      setOrder={setOrder}
                      order={order}
                      docs={docs}
                      handlePostDoc={handlePostDoc}
                      handlePostOther={handlePostOther}
                      region={region}
                      orderLoaded={orderLoaded}
                      handleDocsType={handleDocsType}
                      palette={palette}
                      setDisplayFormCreate={setDisplayFormCreate}
                      handlePostCoords={handlePostCoords}
                      handleSelectOnMap={handleSelectOnMap}
                      userInfo={userInfo}
                      mode={mode}
                      setNotification={setNotification}
                      setIsNewObject={setIsNewObject}
                      responsive={responsive}
                      setCadNum={setCadNum}
                      setDocs={setDocs}
                      pdfNote={pdfNote}
                      checkPdf={checkPdf}
                      fileFormatNote={fileFormatNote}
                      checkFileFormat={checkFileFormat}
                      checkFileSize={checkFileSize}
                      checkFileSizeNote={checkFileSizeNote}
                      flag={flag}
                    />
                  </td>
                }

                {userInfo && (devMode || conf.showNotif) &&
                  <td width="50">
                    <Notif
                      showNotif={showNotif}
                      handleClickNotif={handleClickNotif}
                      notifs={notifs}
                      handleUpdateNotif={handleUpdateNotif}
                      orderLoaded={orderLoaded}
                      setOrder={setOrder}
                      handleSelectOrder={handleSelectOrder}
                      handleClickOrder={handleClickOrder}
                      handleGetNotifs={handleGetNotifs}
                      palette={palette}
                      responsive={responsive}
                      notifPage={notifPage}
                      setNotifPage={setNotifPage}
                      flag={flag}
                    />
                  </td>
                }

                {userInfo && userInfo.bin && devMode && (
                  <td width="180">
                    <button
                      className="header-btn header-btn-orange btn-apply btn-title"
                      onClick={handleSendApplication}
                    >
                      <img
                        src={acceptImg}
                        style={{
                          width: 18,
                          height: 19,
                        }}
                        alt=""
                      />
                      {t("header.auction_create")}
                    </button>
                  </td>
                )}

                {userInfo && userInfo.result === 0 &&
                  <td width="30">
                    <HeadImg img={userInfoImg} background="transparent" position="40" />
                  </td>
                }

                {/* services list */}
                <td width={responsive.mobile ? 50 : 200}>
                  {userInfo && userInfo.result === 0 ? (
                    <UserInfo
                      userInfo={userInfo}
                      handleLogout={handleLogout}
                      handleUpdateQueue={handleUpdateQueue}
                      handleClick={handleClickProfile}
                      showProfile={showProfile}
                      setShowMyObjects={setShowMyObjects}
                      setQueueNum={setQueueNum}
                      setAte={setAte}
                      handleOpenQueue={handleOpenQueue}
                      setSearchQueueFromReg={setSearchQueueFromReg}
                      setEgovService={setEgovService}
                      setShowRegistrationLecensee={setShowRegistrationLecensee}
                      setShowWorkOrders={setShowWorkOrders}
                      regionsData={regions}
                      setShowPlanArchive={setShowPlanArchive}
					            conf={conf}
                      flag={flag}
                    />
                  ) : (
                    <a
                      href={window.IDP_URL}
                      className="header-btn header-btn-green btn-authorize"
                      onClick={handleAuthorizationPressed}
                    >
                      <img
                        src={authorizeImg}
                        style={{
                          width: 18,
                          height: 19,
                        }}
                        alt=""
                      />
                      {!responsive.mobile && <span className="btn-title">{t("header.auth")}</span>}
                    </a>
                  )}
                </td>
              </>}
          </tr>
        </tbody>
      </table>
    </header>
  );
}
