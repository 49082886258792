import React from "react";
import styled from "styled-components";
import ReactCodeInput from "react-code-input";
import { useTranslation } from 'react-i18next';
import Cookies from "js-cookie";

export const EmailVerifyWrap = styled.div`
  position: absolute;
  z-index: 6;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  backdrop-filter: blur(5px);

  .email-verify-box {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    background: white;
    font-family: "Roboto";
    padding: 20px 20px;
    box-shadow: 10px 10px 14px #888888;
    border-radius: 8px;

    .email-verify-btns {
      display: flex;
    }

    .react-code-input {
      align-self: center;
    }

    .email-verify-head {
      font-size: 22px;
      padding-bottom: 12px;
    }

    .email-verify-body {
      color: #888;
      font-size: 14px;
      padding-bottom: 12px;
    }

    .email-verify-bottom {
      color: #888;
      font-size: 14px;
      padding: 6px 0px;
      display: flex;
      justify-content: space-between;

      .email-verify-invalid {
        color: red;
      }

      .resend {
        color: black;
        cursor: pointer;
      }

      .resend:hover {
        text-decoration: underline;
      }
    }

    .email-verify-btns {
      justify-content: space-between;
      padding-top: 6px;

      .email-verify-btn {
        width: 114px;
        height: 30px;
        border-radius: 5px;
        background: #4a74ed;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 14px;
        font-weight: bold;
      }

      .email-verify-btn.btn-reject {
        background: #e14c4c;
      }

      .email-verify-btn.btn-check {
        background: ${props => props.valid ? "#4a74ed" : "gray"};
        cursor: ${props => props.valid ? "pointer" : "default"};
      }
    }
  }
`;

export default function EmailVerify({
  removeEmailVerify,
  checkEmailVerify,
  resendEmail
}) {

  const [valueInput, setValueInput] = React.useState('');
  const [valid, setValid] = React.useState(false);
  const [serverInvalid, setServerInvalid] = React.useState(false);
  const [time, setTime] = React.useState(60);
  const [timesUp, setTimesUp] = React.useState(false);

  const { t } = useTranslation()

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (time > 0) {
        setTime(time - 1);
      } else {
        setTimesUp(true);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [setTime, time]);

  const handleValueInput = (e) => {
    let val = String(e);
    setValueInput(val);
    const num = Number(val);
    if (Number.isInteger(num) && num > -1 && val.length === 6) {
      setValid(true);
    } else {
      setValid(false);
    }
  };

  const cancel = () => {
    removeEmailVerify();
  };

  const check = () => {
    if (valid) {
      checkEmailVerify(valueInput)
        .catch(e => {
          setServerInvalid(true);
        });
    }
  };

  const resend = () => {
    if (timesUp) {
      resendEmail()
        .then(() => {
          setTime(60);
          setTimesUp(false);
        })
        .catch(e => {
          console.log(e);
        });
    }
  }

  return (
    <EmailVerifyWrap valid={valid}>
      <div className="email-verify-box">
        <div className="email-verify-head">
          {t('email-verify.head')}
        </div>
        <div className="email-verify-body">
          {`${t('email-verify.body1')} ${Cookies.get(window.CK_EMAIL_VERIFY)} ${t('email-verify.body2')}`}
        </div>
        <ReactCodeInput
          name="verifyEmail"
          inputMode="numeric"
          fields={6}
          type="text"
          onChange={(e) => handleValueInput(e)}
          isValid={true}
        />
        <div className="email-verify-bottom">
          <div
            className={timesUp ? "resend" : ""}
            onClick={resend}
          >
            {t('email-verify.bottom') + (timesUp ? "" : " " + time)}
          </div>
          {serverInvalid &&
            <div className="email-verify-invalid">
              {t('email-verify.invalid')}
            </div>
          }
        </div>
        <div className="email-verify-head">
          {t('liability.head')}
        </div>
        <div className="email-verify-body">
          {t('liability.body')}
        </div>
        <div className="email-verify-btns">
          <div
            className="email-verify-btn btn-check"
            onClick={check}
          >
            {t('email-verify.btn1')}
          </div>
          <div
            className="email-verify-btn btn-reject"
            onClick={cancel}
          >
            {t('email-verify.btn2')}
          </div>
        </div>
      </div>
    </EmailVerifyWrap>
  );
}
