import React from "react";
import styled from "styled-components";

const Message = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 36px;
  background-color: ${props => props.palette.primary};
  color: ${props => props.palette.text};
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  z-index: 3;
  width: 100%;
  max-width: ${props => props.responsive.mobile ? "100%" : "350px"};
  border-radius:  0 0 6px 6px ;
`;

const NoObj = props => (
  <Message palette={props.theme} responsive={props.responsive}>
    {props.msg}
  </Message>
);

export default NoObj;
