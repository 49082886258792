import React from 'react'
import { Scrollbars } from "react-custom-scrollbars";
import { List, OnTop } from "./ListService";
import PulseLoader from "react-spinners/PulseLoader";
import { Icon } from './UserProfile';
import svgExit from '../../assets/img/exit.svg';
import { withTranslation } from 'react-i18next';

class ListProviders extends React.Component {

    // componentWillMount() {
    componentDidMount() {
        this.props.setMyObjLoaded(false);
        this.props.handleProviders(this.props.region.id)  
    }

    handleObjectSelect(item) {

        item.id=item.id;
        item.title=item.title;
        this.props.order.providers=[];
        this.props.order.providers.push(item);
        // this.props.setOrder(JSON.parse(JSON.stringify(this.props.order)));
        this.props.setOrder(Object.assign({},  this.props.order))

        this.props.setShowProviders(false)

    }

    render() { 
        const { t } = this.props;
        return (
            <OnTop>
                <List responsive={this.props.responsive}>
                    <div className="header">
                        <h2>{t('new-obj.choose-provider')}</h2>  
                        <button onClick={() =>{
                            this.props.setShowProviders(false)
                            
                        }}>
                            <Icon url={svgExit} />
                        </button>
                    </div>
                    <div palette={this.props.palette}>
                        {!this.props.myObjLoaded ?
                            <div className="justify-center" style={{ padding: '15px' }}><PulseLoader size={15} color={"#02c23e"} loading={true} /></div> :
                            <div className="table">
                                <div className="wrapper">
                                    <Scrollbars
                                        className="scrollbar"
                                        style={{}}
                                        autoHeight
                                        autoHide
                                        autoHeightMin={300}
                                        autoHeightMax={450}
                                        renderView={props => <div {...props} className="scrollbar-view" />}
                                    >
                                        <div className="tbody">
                                            {this.props.providers.map((item, i) => (
                                                <div
                                                    onClick={() => this.handleObjectSelect(item)}
                                                    className={"tr selectable" + (i % 2 === 1 ? " even" : "")}
                                                    key={i}
                                                    style={{
                                                        display: "grid",
                                                        gridTemplateColumns: `repeat(${1}, 1fr)`,
                                                    }}
                                                >
                                                    <div className={"td"}>
                                                        {item.title}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </Scrollbars>
                                    <div className="tfoot">
                                        <div
                                            className="tr"
                                            key={this.props.providers.length}
                                            style={{
                                                display: "grid",
                                                gridTemplateColumns: `repeat(${1}, 1fr)`,
                                            }}
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </List>
            </OnTop>
        );
    }
}

export default withTranslation()(ListProviders);