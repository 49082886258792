import TileLayer from "ol/layer/Tile";
import { TileWMS } from "ol/source";

export default class DistrictLayers {

	constructor(wmsUrl) {
    this.wmsUrl = wmsUrl;
    this.pools = new Map();
    this.layers = new Map();
	}

  createPool(name, gsLayerName, srs, map) {
    const pool = {
      wmsLayer: new TileLayer({
        source: new TileWMS({
          url: this.wmsUrl,
          params: {
            LAYERS: gsLayerName,
            TILED: true,
          },
          serverType: "geoserver",
          transition: 0,
          projection: srs
        }),
        visible: false
      }),
      layers: new Set()
    }
    this.pools.set(name, pool);
    map.addLayer(pool.wmsLayer);
  }

  createLayer(poolName, layerName, visible) {
    const pool = this.pools.get(poolName);
    let layer = this.layers.get(layerName);
    if (!layer) {
      layer = { pools: [] };
      this.layers.set(layerName, layer);
    }
    layer.pools.push(pool);
    if (visible) {
      this.setVisible(layerName, visible);
    }
  }

  getLayerNames() {
    return this.layers.keys();
  }

  hasLayer(layerName) {
    return this.layers.has(layerName);
  }

  setDistrictId(districtId) {
    this.districtId = districtId;
    this.pools.forEach(pool => {
      const source = pool.wmsLayer.getSource();
      const params = source.getParams();
      params.viewparams = this.generateViewParams(pool.layers);
      source.updateParams(params);
    });
  }

  setVisible(layerName, visible) {
    const layer = this.layers.get(layerName);
    if (layer != null) {
      layer.pools.forEach(pool => {
        if (visible !== pool.layers.has(layerName)) {
          if (visible) {
            pool.layers.add(layerName);
          } else {
            pool.layers.delete(layerName);
          }
          let source = pool.wmsLayer.getSource();
          let params = source.getParams();
          params.viewparams = this.generateViewParams(pool.layers);
          source.updateParams(params);
          pool.wmsLayer.setVisible(pool.layers.size > 0);
        }
      });
    }
  }

  generateViewParams(layers) {
    return `district_id:${this.districtId};layer_name:${layers.size > 0 ? Array.from(layers).map(layerName => "'" + layerName + "'").join('\\,') : "''"}`
  }
}