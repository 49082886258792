import React from "react";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";

import OpacityIcon from "../../assets/img/opacity-button.svg";
import { useTranslation } from "react-i18next";

const OpacityButtonComponent = styled.button`
  border: none;
  outline: none;
  background-color: inherit;

  .tooltip {
    padding: 2px 4px;
  }
  &:hover {
    cursor: pointer;
  }
`;

const OpacityButton = ({
  theme,
  menuId,
  opacityButtonId,
  tooltipType,
  opacityValue,
  handleOpacityButtonClick,
  openSlider
}) => {
  const { t } = useTranslation();
  return (
    <OpacityButtonComponent
    className="opacity-btn"
    palette={theme}
    id={menuId}
    onClick={handleOpacityButtonClick}
    data-tip
    data-for={opacityButtonId}
    opacityLevel={opacityValue}
    openSlider={openSlider}
  >
    <ReactTooltip
      className="tooltip"
      type={tooltipType}
      border
      delayShow={250}
      id={opacityButtonId}
    >
      {t("manage-map.2")}
    </ReactTooltip>
    <img src={OpacityIcon} alt="" />
  </OpacityButtonComponent>
  )
};

export default OpacityButton;
