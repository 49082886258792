export default function getFireDepts(regionCode) {
  switch (regionCode) {
    case "21":
      return 7;
    case "20":
      return 8;
    case "22":
      return 9;
    case "01":
      return 10;
    case "02":
      return 11;
    case "03":
      return 12;
    case "04":
      return 13;
    case "12":
      return 14;
    case "09":
      return 15;
    case "10":
      return 16;
    case "13":
      return 17;
    case "14":
      return 18;
    case "15":
      return 19;
    case "19":
      return 20;
    case "05":
      return 21;
    case "06":
      return 22;
    case "08":
      return 23;
    default:
      return 6;
  }
}
