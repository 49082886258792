import React, { useContext, useState, useEffect } from 'react'
import styled from "styled-components";
import { UserInfo } from './UserProfile';
import { ConfirmContext, NotificationContext, MODE_CHOOSE_OBJECT } from "../../EknMap";
import ClipLoader from "react-spinners/ClipLoader";
import { WKT } from "ol/format";
import svgAdd from '../../assets/img/add.svg';
import svgSearch from '../../assets/img/search-obj.svg';
import svgRemove from '../../assets/img/remove.svg';
import svgUpload from '../../assets/img/upload.svg';
import svgArrow from '../../assets/img/arrow.svg';
import { Icon } from './UserProfile';
import {checkFl, checkUl, download, getTemplate} from '../../utils/RestApi';
import { useTranslation } from "react-i18next";
import svgInfo from '../../assets/img/info.svg';
import { postDoc } from '../../EknMap';
import { NF_EXAMPLE } from '../Notification';
import svgDownload from "../../assets/img/download.svg";

export const ObjectsAndServices = styled(UserInfo)`
    overflow-y: hidden;
    overflow-x: hidden;
    min-width: initial;
    max-width: initial;
    width: ${props => props.responsive.mobile ? "100%" : "540px"};
    right: initial;
    padding: 6px 6px 12px 12px;
    border-radius: 7px;
    font-family: 'sf-pro-display-regular',sans-serif;
    font-size: 14px;
    box-shadow: 14px 14px 20px #00000036;
    z-index: 4;
    height: auto;

    tbody tr:nth-child(odd) td {
      background: initial;
    }
  
    tbody tr:nth-child(even) td {
      background: initial;
	  }

    td {
      border: initial;
    }

    table {
        background: #fff;
        margin: 0;
        padding: 0;
        width: 98%;
        border-spacing: 0;
    }

    tr {
        display: block;
        height: initial
        margin-bottom: 6px;
    }

    td {
        width: 100%;
        word-wrap:break-word;
        display: block;
        margin: 0;
        padding: 0;

        p {
          margin: 0;
          margin-bottom: 6px;
          padding: 6px 3px 6px 10px;
          background: #D1E8D9;
          color: #063c0c;
          border-radius: 5px;
        }
    }

    h2 {
      font-size: 14px;
      margin: 0 11px 6px 0;
      font-weight: bold;
      border: 1px solid #B6D4C0;
      background: #fff;
      color: #015F24;
      padding: 7px 0px;
      text-align: center;
      position: relative;
      width: 98%;
    }
    .title-error {
      border: 2px solid #ae2a00;
    }
    .order-title {
      background-color: #009856;
      color: #fff;
      font-weight: normal;
      // margin-bottom: 25px;
    }

    .order-table {
      padding: 0;
      margin: 0;
      // max-height: ${props => props.responsive.mobile ? "calc(100vh - 116px)" : "674px"};
      max-height: ${props => props.responsive.mobile ? "calc(100vh - 116px)" : "80vh"};
      background: #fff;
      height: auto;
      overflow-y: auto;
      overflow-x: hidden;
      display: block;
      position: relative;
      scrollbar-width: thin;

      .serv-input-wrap {
        padding: 0;
        margin-top: 20px;

        .serv-input {
          width: 98%;
          margin-bottom: 8px;
          background: #fff;
          border: 1px solid #B6D4C0;
          padding-left: 8px;
          color: #015F24;
          font-size: 16px;
          position: absolute;
        }

        .input-title {
          margin: 0px 0 45px 8px;
          background: #fff;
          padding: 0 10px;
          font-size: 12px;
          z-index: 1;
        }
      }

      .invalid {
        .serv-input {
          border: 2px solid #ae2a00
          border-radius: 8px;
        }

        .input-title {
          color: #ae2a00;
        }
      }
    }

    .btn-td {
        width: 15%;
        padding: 2px 7px;
        display: flex;
        align-items: end;
        align-content: end;
    }

    td button {
        width: 100%;
        margin: 0;
        background: #009856;
        text-align: start;
        padding-left: 182px;
        font-family: 'sf-pro-display-regular',sans-serif;
        letter-spacing: 0.5px;
    }

    tbody tr:hover td {
      background-color: #fff !important;
      color: #fff;
      font-weight: normal;
    }

    .srvs-btns {
      padding: 0;
      height: initial;
      background: none;
      margin: 6px 0 0 0;
      justify-content: center
    }

    .my-obj-btn {
      width: auto;
      margin-left: 15px;
      margin-right: 15px;
    }

    input {
      width: 30px;
      background-color: #015f24;
      border-radius: 4px;
      color: #fff;
      height: 30px;
      border: none;
      outline: none;
    }

    .load-wrap {
      margin: 0;
      background: none;

      div {
        height: 30px;
        width: 30px;
        border-radius: 100%;
        padding: 0;
        padding-left: 0;
        margin: 0;
      }
    }

    .not-avail {
      background: #999 !important;
      cursor: default !important;
    }

    .btn-minus {
      margin: 0px 0px 0px auto;
      background: #B55023;
    }

    .pay-load {
      width: 40%;
      margin: 0px 10px 10px 0px;
      display: flex;
      justify-content: center;
    }

    .error {
      color: red;
      margin: 0 6px 11px 0px
      border: 1px solid red;
      border-radius: 5px;
      background: #fff;
      height: auto;
      padding-top: 6px;
      padding-bottom: 6px;
    }

    .queue-pagenav {
      border-radius: 7px !important;
      background-color: #009856 !important;
      border: 1px solid #B6D4C0;

      button {
        border-radius: 5px;
        background: #08442a !important;
        height: 22px !important;
        width: 22px !important;
        padding: 0 !important;
        margin-top: 0;
      }
    }
    .serv-input-land {
      background-color: #fff;
      border: 1px solid #B6D4C0;
      color: #015F24;
    }

    .download-instruction {
      background: #2F80ED;
      width: 98%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
    }

    .provider-wrapper {
      margin-top: 0;
      padding-left: 0;
    }
    // .provider-input {
    //   width: 20px;
    //   height: 20px;
    // }
    .provider-zkr {
      padding: 0;
    }

    textarea {
      resize: none;
      font-size: 14px;
      padding-top: 10px;
      padding-bottom: 10px;
      height: 105px;
      width: 98%; 
      padding: 10px; 
    }
    .iin-wrap {
      padding: 0
      margin: 0
    }
    .fa-check {
      color: #009856;
      font-size: 23px;
    }
    .fa-xmark {
      color: red;
      font-size: 25px;
    }

    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type="number"] {
      -moz-appearance: textfield;
    }
    input[type="number"]:hover,
    input[type="number"]:focus {
      -moz-appearance: number-input;
    }

    .error-message {
      color: red;
      cursor: default;
      margin: 0;
      justify-content: center;
      height: 20px;
      width: 98%;
      font-size: 14px;
      font-family: 'sf-pro-display-regular',sans-serif;
    }

    .provider-input[type="radio"] {
      appearance: none;
      position: relative;
      outline: none;
      width: 19px;
      height: 19px;
      margin-right: 5px;
      border-radius: 50%;
    }
    .provider-input[type="radio"]::before {
      content: "";
      box-sizing: border-box;
      width: 20px;
      height: 20px;
      position: absolute;
      background-color: white;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      border: 1.5px solid #009856;
    }
    
    .provider-input[type="radio"]:checked:after {
      content: "";
      box-sizing: border-box;
      width: 10px;
      height: 10px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      background-color: #009856;
    }
    .iin-valid {
      position: absolute;
      right: 7px;
      background: none
    }
    .iin-valid div {
      height: 25px;
    }
    .file-desc {
      width: 100%;
      background-color: #009856;
      color: #fff;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: space-between;
      padding-right: 15px;
      height: auto;
      text-align: left;
    }
    .doc-title {
      position: relative;
      padding: 0;
    }
    .phone-code {
      position: absolute;
      top: 21.5px;
      left: 0;
      z-index: 1;
      font-size: 16px;
      font-family: 'Open Sans',sans-serif;
    }
    .serv-input-phone {
      padding-left: 31px !important;
    }
`;

export const Info = styled.div`
    width: 30px;
    height: 30px;
    background: transparent url('${props => props.url}') 0% 0% no-repeat padding-box !important;
    position: absolute;
    top: -12px;
    right: 20px;
    cursor: pointer;
`;

export default function Services({
  setShowListService,
  setMode,
  handleClickOrder,
  handlePayment,
  setShowListMyObjs,
  setShowLandIdObj,
  setShowObjSearch,
  setShowProviders,
  handleCloseStats,
  setOrder,
  order,
  docsType,
  handlePostDoc,
  handlePostOther,
  region,
  setDisplayFormCreate,
  handlePostCoords,
  handleSelectOnMap,
  userInfo,
  setIsNewObject,
  responsive,
  setLandInd,
  setCadNum,
  setDocs,
  pdfNote,
  checkPdf,
  fileFormatNote,
  checkFileFormat,
  checkFileSize,
  checkFileSizeNote
}) {

  const { t } = useTranslation();
  const formatPhone = (num, auto) => {
    if (auto) {
      if (num) {
        let first = num.substring(0,1);
        if (first === '+') {
          return num.substring(0,2) + '(' + num.substring(2,5) + ')' + num.substring(5,8) + '-' + num.substring(8,10) + '-' + num.substring(10,12);
        } else if (first === '7') {
          return '+' + num.substring(0,1) + '(' + num.substring(1,4) + ')' + num.substring(4,7) + '-' + num.substring(7,9) + '-' + num.substring(9,11);
        } else if (first === '8') {
          return  '+7(' + num.substring(1,4) + ')' + num.substring(4,7) + '-' + num.substring(7,9) + '-' + num.substring(9,11);
        }
      }
    } else if (num) {
      return '+7' + (num.length > 0 ? '(' : '')
        + num.substring(0, 3) + (num.length > 3 ? ')' : '')
        + num.substring(3, 6) + (num.length > 6 ? '-' : '') + num.substring(6, 8) + (num.length>8 ? '-' : '') + num.substring(8,10);
    }
    return '';
  }

  const { setConfirm } = useContext(ConfirmContext);
  const { setNotification } = useContext(NotificationContext);
  const telPattern = /^\d{10}$/;
  const yearPattern = /^\d{4}$/;
  const [phone, setPhone] = useState(formatPhone(userInfo.phone, true));
  const [year, setYear] = useState(new Date().getFullYear());
  const [landId, setLandId] = useState();
  const [cadnumId, setCadnumId]  = useState();
  const [valid, setValid] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [iinBin, setIinBin] = useState();
  const [provider, setProvider] = useState();  
  const [member, setMember] = useState();
  const [formErrors, setFormErrors] = useState({
    landId: '',
    cadnum: '',
    service: false,
    provider: false,
    member: false,
    objects: false,
    docs: false,
    phone: false,
    year: false
  });
  const [disable, setDisable] = useState(false)

  const validateField = (name, value) => {
    let isResult = true;
    let errorMsg = t('auf.msg-empty');

    if(value == '' || value === undefined) {
      formErrors[name] = errorMsg;
      isResult = false
    } else {
      if (name === 'landId' && value.trim().length!==20) {
        formErrors[name] = t('service.landId-length');
        isResult = false;
      } else if (name === 'cadnum' && value.trim().length!==11 && value.trim().length!==12) {
        formErrors[name] = t('service.cadnum-length');
        isResult = false;
      } else {
        formErrors[name] = '';
      }
    }

    setFormErrors(Object.assign({}, formErrors));
    return isResult;
  }

  const validateForm = () => {
    let isResult = true;
    Object.keys(formErrors).forEach((fieldName) => {
      switch (fieldName) {
        case 'service':
          order.services ? order.services.length<1 || !order.services[0].code ? formErrors[fieldName] = true : formErrors[fieldName] = false :  formErrors[fieldName] = true;
          break;
        case 'provider':
          provider==='Другие поставщики ЗКР' ? order.providers && order.providers.length>0 ?  formErrors[fieldName] = false :  formErrors[fieldName] = true : formErrors[fieldName] = false;
          break;
        case 'member':
          const dv = docsType.filter(item => item.name === "Доверенность")[0];
          let doc = order.docs.find(doc => doc.typeCode === dv.code);
          member==='От доверителя' ? iinBin && iinBin.length===12 && valid && doc ? formErrors[fieldName] = false : formErrors[fieldName] = true : formErrors[fieldName] = false;
          break;
        case 'objects':
          order.services && order.services.length>0 ? order.objects && order.objects.length >= order.services[0].minObjCount || !order.services[0].minObjCount ? formErrors[fieldName] = false : formErrors[fieldName] = true : formErrors[fieldName] = false;
          break;
        case 'docs':
          !checkRequiredFiles() ? formErrors[fieldName] = true : formErrors[fieldName] = false;
          break;
        case 'phone':
          phone ? telPattern.test(phone.replace(/\D/g, '').substring(1,phone.length)) ? formErrors[fieldName]=false : formErrors[fieldName]=true : formErrors[fieldName]=true;
          break;
        case 'year':
          (order.services && order.services.length>0 && order.services[0].code !== "807000") || yearPattern.test(year) ? formErrors[fieldName]=false : formErrors[fieldName]=true;
          break;
      }

      if (formErrors[fieldName]) isResult=false
    });

    setFormErrors(Object.assign({}, formErrors));
    return isResult;
  }

  useEffect(() => {
    if (formErrors['service'] && order.services && order.services.length>0) {
      formErrors['service']=false;
      setFormErrors(Object.assign({}, formErrors));
    } else if (formErrors['provider'] && order.providers && order.providers.length>0) {
      formErrors['provider']=false;
      setFormErrors(Object.assign({}, formErrors));
    } else if (formErrors['member']) {
      const dv = docsType.filter(item => item.name === "Доверенность")[0];
      let doc = order.docs.find(doc => doc.typeCode === dv.code);
      if (iinBin && iinBin.length===12 && valid && doc) {
        formErrors['member']=false;
        setFormErrors(Object.assign({}, formErrors));
      }
    } else if (formErrors['objects'] && order.services && order.services.length>0 && order.objects && order.objects.length >= order.services[0].minObjCount) {
      formErrors['objects']=false;
      setFormErrors(Object.assign({}, formErrors));
    } else if (formErrors['docs'] && checkRequiredFiles()) {
      formErrors['docs']=false;
      setFormErrors(Object.assign({}, formErrors));
    }
  }, [order])

  const checkRequiredFiles = () => {
    let isRequired = true;
    const requiredFiles = docsType.filter(item => item.isRequired == 1);
    if (requiredFiles) {
      for (let i = 0; i < requiredFiles.length; i++) {
        if (!order.docs.find(doc => doc.typeCode === requiredFiles[i].code)) {
          isRequired = false;
          break;
        }
      }
    }
    return isRequired;
  }

  const hiddenFilesInputs = [];
  if (docsType) {
    docsType.map(() => hiddenFilesInputs.push(React.createRef()));
  }

  const hidenCoordInput = React.createRef();
  const hiddenDvInput = React.createRef();

  const handleDocClick = i => {
    hiddenFilesInputs[i].current.click();
  };

  const handleDvClick = () => {
    hiddenDvInput.current.click();
  }

  const filNote = () => {
    setNotification({
      title: t("service.file-error-title"),
      text: t("service.file-error-text")
    })
  }

  const checkDuplicateFiles = (code, fileName) => {
    let isDuplicate = false;
    const found = order.docs.find(el => el.typeCode!=code && el.fileName===fileName);
    found ? isDuplicate=true : isDuplicate=false;
    return isDuplicate;
  }

  const handleDocSelect = (event, item) => {
    const fileName = event.target.files[0].name;
    if (checkDuplicateFiles(item.code,fileName)) {
      filNote();
    } else if (checkPdf(fileName)) {
      pdfNote();
    } else if (checkFileSize(event.target.files[0])) {
      checkFileSizeNote(NF_EXAMPLE);
    } else {
      checkPdf(fileName);
      order.docs = order.docs.filter(item => item.fileName != fileName);
      order.docs.push({ typeCode: item.code, fileName: fileName, blob: event.target.files[0] });
      setOrder(Object.assign({}, order))
    }
  }

  const handleDvSelect = event => {
    const fileName = event.target.files[0].name;
    if (checkDuplicateFiles('01011',fileName)) {
      filNote();
    } else if (checkPdf(fileName)) {
      pdfNote();
    } else if (checkFileSize(event.target.files[0])) {
      checkFileSizeNote();
    } else {
      order.docs.push({ typeCode: "01011", fileName: fileName, blob: event.target.files[0] });
      setOrder(Object.assign({}, order))
    }
  }

  const handleDocDelete = (doc, i) => {
    if (i<0) {
      hiddenDvInput.current.value = '';
      order.docs = order.docs.filter((item) => item.typeCode !=='01011');
    } else {
      const orderDocs = order.docs.filter((item) => item.typeCode !==doc);
      let typeDocs = order.docs.filter((item) => item.typeCode ===doc);
      typeDocs.splice(i,1);
      order.docs=[...orderDocs, ...typeDocs];

    }
    setOrder(Object.assign({}, order))
    
  }

  const regionNote = () => {
    setNotification({
      title: t("service.region-error-title"),
      text: t("service.region-error-text")
    })
  }

  const handleCoordDelete = (obj, doc) => {
    order.docs = order.docs.filter((item) => item.typeCode!==doc.typeCode && item.fileName!==doc.fileName);
    obj.coords = undefined;
    setOrder(Object.assign({}, order))
  }

  const handleCoordClick = () => {
    hidenCoordInput.current.click();
  };

  const handleCoordSelect = (event, i) => {
    const fileName = event.target.files[0].name;
    if (checkDuplicateFiles('ZKR1',fileName)) {
      filNote();
    } else if (checkFileFormat(fileName)) {
      fileFormatNote();
    } else if (checkFileSize(event.target.files[0])) {
      checkFileSizeNote();
    } else {
      let doc = order.docs.find(doc => doc.typeCode === 'ZKR1');
      if (doc) {
        doc.fileName = fileName;
      } else {
        postDoc(event.target.files[0], "ZKR1").then(result => {
          order.objects[i].coords=result;
          setOrder(Object.assign({}, order))
        })
        order.docs.push({ typeCode: 'ZKR1', fileName: fileName, blob: event.target.files[0] });
      }
      setOrder(Object.assign({}, order))
    }
  }

  const phoneMask = (phone) => {
    let num;
    if (phone.length>1) {
      num = phone.substring(2, phone.length).replace(/\D/g, '');
    } else {
      num = phone.replace(/\D/g, '');
    }
    order.phone = num;
    if (!telPattern.test(num.replace(/\D/g, ''))) {
      formErrors['phone']=true;
    } else {
      formErrors['phone']=false;
      setFormErrors(Object.assign({}, formErrors));
    }
    setPhone(formatPhone(num));
  }

  const checkIinBinValid = (iinBin) => {
    if (iinBin.length==12) {
      const dv = docsType.filter(item => item.name === "Доверенность")[0];
      let doc = order.docs.find(doc => doc.typeCode === dv.code);
      setValid(false);
      setInvalid('');
      if (iinBin.substring(4, 5)==0 || iinBin.substring(4, 5)==1 || iinBin.substring(4, 5)==2 || iinBin.substring(4, 5)==3) {
        checkFl(iinBin).then(result => {
          if (result.resultCode==='0') {
            setValid(true);
            if (doc) {
              formErrors['member']=false;
              setFormErrors(Object.assign({}, formErrors));
            }
          } else {
            setInvalid(result.resultMessage);
          }
        });
      } else {
        checkUl(iinBin).then(result => {
          if (result.resultCode==='1') {
            setValid(true);
            if (doc) {
              formErrors['member']=false;
              setFormErrors(Object.assign({}, formErrors));
            }
          } else {
            setInvalid(result.resultMessage);
          }
        });
       
      }
    }
  }

  const listService = () => {
    return (order && order.services.map((item, i) => (
      <tr key={i}>
        <td>
          <p>{item.name_ru || item.name}</p>
          {order.services.length && order.services[0].code === "807000" &&
            <div className={"serv-input-wrap" + (yearPattern.test(year) ? "" : " invalid")}>
              <span className="input-title">{t("service.calc-year")} *</span>
              <input
                type="number"
                className="serv-input"
                onChange={e => {
                  item.year = e.target.value;
                  setYear(e.target.value);
                }}
                value={year}
                placeholder={t("service.calc-year")}
                onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                disabled={disable}
              />
            </div>
          }
          <button className="btn-minus"
            onClick={() => {
              order.services = [];
              order.docs = [];
              setDocs([]);
              setOrder(Object.assign({}, order))
            }}
            disabled={disable}
          >
            <Icon url={svgRemove} className="icon" />
            {t("service.srv-delete")}
          </button>
        </td>
      </tr>
    )))
  }

  const listProviders = () => {
    return (order.providers && order.providers.map((item, i) => (
      <tr key={item.id}>
        <td>
          <p>{item.title}</p>
          <button className="btn-minus"
            onClick={() => {
              order.providers = [];
              order.provider = '';
              setOrder(Object.assign({}, order))
            }}
            disabled={disable}
          >
            <Icon url={svgRemove} className="icon" />
            {t("service.provider-delete")}
          </button>
        </td>
      </tr>
    )))
  }

  const listObject = () => {
    const doc = order.docs.find(doc => doc.typeCode === 'ZKR1');
    return (order && order.objects.map((item, i) => (
      <React.Fragment key={i}>
        <tr style={{ margin: "0" }}>
          <td><p>
            {(item.funcUseNameRu ? (item.funcUseNameRu + ": ") :
              item.vidInvNameRu ? (item.vidInvNameRu + ": ") : "")
              + (item.addrRu && item.addrRu!=="null" ? item.addrRu : (t("service.region") + ": " + item.districtName))}
          </p></td>
        </tr>
        <tr><td className="btn-td"
          style={{ padding: "0", width: "100%" }}
        >
          {!item.idExplication && <>
            {!item.onMap && <>
              {
                doc && doc.typeCode ?
                  <button
                    className="btn-minus"
                    style={{ width: "7%", padding: "0px 0px 0 11px", marginRight: "6px" }}
                    onClick={
                      () => handleCoordDelete(item, doc)
                    }
                  >
                    <Icon url={svgRemove} className="icon" />
                  </button> :
                  <>
                    <button
                      className="btn-minus"
                      style={{ width: "7%", padding: "0px 0px 0 10px", marginRight: "6px" }}
                      onClick={() => handleCoordClick()}
                      title={t("service.coords")}
                    >
                      <Icon url={svgUpload} className="icon" />
                    </button>
                    <input
                      type="file"
                      accept='application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/plain, application/zip'
                      ref={hidenCoordInput}
                      onChange={event => {
                        event.target.files.length !== 0 && handleCoordSelect(event, i)
                      }}
                      style={{ display: 'none' }}
                    />
                  </>
              }
            </>}
          </>}
 
          {item.coords && <button
            className="btn-minus"
            style={{ width: "7%", paddingLeft: "12px", marginRight: "6px" }}
            disabled={!item.coords}
            title={t("service.on-map")}
            onClick={() => {
              // handleSelectOnMap(new WKT().readGeometryFromText(item.coords));
              handleSelectOnMap({wkt: item.coords});
            }}
          >
            <Icon url={svgArrow} />
          </button>}
          <button
            className="btn-minus"
            style={{ width: "100%", margin: "0" }}
            onClick={() => {
              order.objects.splice(i, 1);
              setOrder(Object.assign({}, order))
            }}
            disabled={disable}
          >
            <Icon url={svgRemove} className="icon" />
            {t("service.obj-delete")}
          </button>
        </td></tr>
      </React.Fragment>
    )))
  }

  const listDocOne =(typeCode, index) => {
    return (order && order.docs && order.docs.filter(item => item.typeCode === typeCode).map((item, i) => (
      <tr key={index + '.' + i}>
        <td>

          {
            <p 
              style={{ marginTop: "6px", height: "30px", padding: "0 0 0 12px", lineHeight: "30px" }}
              onClick={() => {
                downloadDoc(item, true)
              }}
            >
              {item.fileName}
            </p>
          }

          {
            <button className="btn-minus"
              onClick={
                () => handleDocDelete(typeCode, i)
              }
            >
              <Icon url={svgRemove} className="icon" />
              {t("service.doc-delete")}
            </button>
          }

        </td>
      </tr>
    )))
  }

  const listDoc = () => {
    return (order && docsType && docsType.filter(item => item.name !== "Доверенность").map((item, i) => (
      <React.Fragment key={i}>
        <tr>
          <td>
            <button
              title={item.description ? item.description : ''}
              style={{ display: "flex", minHeight: "30px", height: "auto", padding: "7px 0 7px 10px" }}
              onClick={() => handleDocClick(i, item)}
            >
              <Icon url={svgAdd} className="icon" />
              <div className='file-desc'>
                {item.name}
                {item.isRequired==1 && <i style={{fontSize: "15px"}} className="fa-sharp fa-solid fa-asterisk"></i>  }
              </div>
            </button>
            
            <input
              type="file"
              accept="application/pdf"
              ref={hiddenFilesInputs[i]}
              onChange={event => event.target.files.length !== 0 && handleDocSelect(event, item)}
              onClick={event => event.target.value = null}
              style={{ display: 'none' }}
            />
          </td>
        </tr>
        {listDocOne(item.code, i)}
      </React.Fragment>
    )))
  }

  const listDocDov = () => {
    const dv = docsType.filter(item => item.name === "Доверенность")[0];

    let doc = '';
    if (dv) {
      doc = order.docs.find(doc => doc.typeCode === dv.code);
    }

    return (order && docsType && dv &&
      <tr>
        <td>
          <button
            style={{ display: "flex", minHeight: "30px", height: "auto", padding: "7px 0 7px 10px" }}
            disabled={doc && doc.typeCode}
            onClick={handleDvClick}
          >
            {(!doc || !doc.typeCode) && <Icon url={svgAdd} className="icon" />}
            {/* {dv.name} */}
            <div className='file-desc'>
                {dv.name}
                <i style={{fontSize: "15px"}} className="fa-sharp fa-solid fa-asterisk"></i> 
              </div>
          </button>
          
          <input
            type="file"
            accept="application/pdf"
            ref={hiddenDvInput}
            onChange={event => event.target.files.length !== 0 && handleDvSelect(event)}
            style={{ display: 'none' }}
          />

          {doc && doc.typeCode && 
            <p 
              style={{ marginTop: "6px", height: "30px", padding: "0 0 0 12px", lineHeight: "30px" }}
              onClick={() => downloadDoc(doc, true)}
            >
              {doc.fileName}
            </p>
          }

          {
            doc && doc.typeCode &&
            <button className="btn-minus"
              onClick={
                () => handleDocDelete(doc, -1)
              }
            >
              <Icon url={svgRemove} className="icon" />
              {t("service.doc-delete")}
            </button>
          }
        </td>
      </tr>
    )
  }

  const handleOnConfirm = () => {
    setMode(MODE_CHOOSE_OBJECT);
  }

  useEffect(() => {
    order.provider ? setProvider('Другие поставщики ЗКР') : setProvider('НАО');
    if (order.iinBin) {
      setMember('От доверителя');
      setIinBin(order.iinBin);
      setValid(true);
    } else {
      setMember('От себя');
    }
    if (userInfo.phone) {
      phoneMask(userInfo.phone)
    } else if (order.phone) {
      phoneMask(order.phone)
    }
    order.errorTextRu ? setDisable(true) : setDisable(false)
  },[])

  const downloadDoc = (doc, isFile = false) => {
    if(isFile) {
      if(doc.blob) {
        if (doc.blob.type.includes('pdf')) {
          let file = URL.createObjectURL(doc.blob);
          window.open(file, '_blank');
        } else {
          download(doc.blob, doc.fileName);
        }
      } else {
          downloadDoc(doc.fileName, false)
      }
    } else {
      return fetch(`${window.REST_URL}/upload/${order.id}?filename=${encodeURIComponent(doc)}`, {
          method: "GET",
          mode: "cors",
          credentials: "include"
      })
      .then((res) => res.blob())
      .then((blob) => {
        download(blob, doc);
      });
    }
  }

  const specialService = order.services.find(service => service.isSearchLand);

  return (
    <ObjectsAndServices responsive={responsive}>
      <div className="order-table">
        {order && order.id && <h2 className='order-title'>{t("payment-service.order") + ' ' + order.id}</h2>}
        
        {order && order.errorTextRu && <h2 className="error">{order.errorTextRu}</h2>}

        <div className={"serv-input-wrap" + (phone ? telPattern.test(phone.replace(/\D/g, '').substring(1,phone.length)) ? "" : " invalid" : formErrors['phone'] ? " invalid" : "")}>
          <span className="input-title">{t("service.phone-number")} *</span>
          <input
            className="serv-input"
            type="tel"
            placeholder="+7(777)777-77-77"
            onChange={(e) => {
              phoneMask(e.target.value);
            }}
            onClick={() => console.log(order)}
            disabled={disable}
            value={phone}
          />
        </div>

        <h2 className={formErrors['service'] ? 'title-error' : ''}>{t("service.srv-title")}</h2>
        <table>
          <tbody>
            {listService()}
            {!order.services.find(item => item.code === "807000") &&
              <tr style={{ marginBottom: "15px" }}>
                <td>
                  <button
                    onClick={() => {
                      handleCloseStats();
                      setShowListMyObjs(false);
                      setShowObjSearch(false);
                      setShowListService(true);
                    }}
                    disabled={disable}
                  >
                    <Icon url={svgAdd} className="icon" />
                    {t("service.srv-add")}
                  </button>
                </td>
              </tr>
            }
          </tbody>
        </table>



        {order.services.find(item => item.code === '602100') &&
          <>
            <button 
              className="download-instruction"
              onClick={()=> getTemplate("instructions_comm", "Рекомендуемый порядок установления границ земельного участка (инструкция)")}
            >
              {t("help.download-instruction")}
              <img src={svgDownload} alt="download" />
            </button>

            <div className='provider-wrapper'>
              <input className="provider-input" 
                type="radio" name="provider" 
                value="НАО" 
                onClick={e=> setProvider(e.target.value)} 
                defaultChecked
                disabled={disable}
                />
                {t("service.nao")}
              <input className="provider-input" 
                type="radio" 
                name="provider" 
                value="Другие поставщики ЗКР" 
                onClick={e=> setProvider(e.target.value)}
                defaultChecked={order.provider}
                disabled={disable}
                />
                {t("service.provider-choose")}
            </div>     

            {provider==='Другие поставщики ЗКР' &&
              <>
                <h2 className={formErrors['provider'] ? 'title-error' : ''}>{t("service.provider-title")}</h2>              
                  <table>
                    <tbody>
                      {listProviders()}
                      <tr style={{ marginBottom: "15px" }}>
                        <td>
                          <button
                            onClick={() => {
                              if ((!region || region.code === "000") && order.services[0].code !== "807000") {
                                regionNote();
                              } else {
                                setShowProviders(true);
                              }
                            }}
                            disabled={disable}
                          >
                            {t("service.provider-add")}
                          </button>
                        </td>
                      </tr>
                      
                    </tbody>
                  </table>
              </>  
            }
            <div className='provider-wrapper'>
              <input className="provider-input" 
                type="radio" 
                name="member" 
                value="От себя" 
                onClick={e=>setMember(e.target.value)} 
                defaultChecked
                disabled={disable}
                />
                {t("service.myself")}
              <input className="provider-input" 
                type="radio" 
                name="member" 
                value="От доверителя" 
                onClick={e=>setMember(e.target.value)} 
                defaultChecked={order.iinBin}
                disabled={disable}
                />
                {t("service.principal")}
            </div>  
            {member==='От доверителя' &&
              <>
              <h2 className={formErrors['member'] ? 'title-error' : ''}>{t("service.holder")}</h2>        
                <table>
                  <tbody>
                    <tr>
                      <td>
                      {invalid && iinBin && iinBin.length==12 && <div className='error-message'>{invalid}</div>}
                        <div className='iin-wrap'>
                        <input
                          type="number"
                          className="serv-input-land"
                          style={{ width: "100%", margin: "0", padding: "0", textAlign: "center" }}
                          defaultValue={iinBin}
                          onChange={e => {
                            setIinBin(e.target.value);
                            checkIinBinValid(e.target.value);
                          }}
                          placeholder={t("service.iinBin")}
                          onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                          disabled={disable}
                        />  
                        {iinBin && iinBin.length==12 && 
                          <div className='iin-valid'>
                            {valid ? <i className='fa-solid fa-check'></i> : 
                            !invalid ? <ClipLoader color={"#02c23e"} loading={true} size={"25px"}/> : <i className="fa-solid fa-xmark"></i>}
                          </div>
                        }
      
                        </div>
                      </td>

                    </tr>
                  
                    {listDocDov()}

                  </tbody>
                </table>
              </>
              
            }
          </>
        }
        

        <h2 className={formErrors['objects'] ? 'title-error' : ''}>{t("service.obj-title")}</h2>
        <table>
          <tbody>
            {listObject()}
            {order.services.length>0 && (!order.services[0].maxObjCount 
            || order.services[0].maxObjCount > order.objects.length) &&
              <tr>
                {formErrors['landId'] && formErrors['landId'].length>0 ? <td><div className='error-message'>{formErrors['landId']}</div></td> : null}
                <td style={{ display: "flex" }}>
                  <input
                    type="number"
                    className="serv-input-land"
                    style={{ width: "100%", margin: "0", padding: "0", textAlign: "center" }}
                    onChange={e => {
                      setLandId(e.target.value);
                      setLandInd(e.target.value);
                      if (e.target.value.length==0) {
                        formErrors['landId'] = '';
                        setFormErrors(Object.assign({}, formErrors));
                      }
                    }}
                    value={landId || ''}
                    placeholder={t("service.landId")}
                    onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                    disabled={disable}
                  />
                  <button
                    className="btn-minus"
                    style={{ width: "32px", marginLeft: "6px", paddingLeft: "10px"}}
                    onClick={(e) => {
                      if (!region || region.code === "000") {
                        regionNote();
                      } else if (validateField('landId', landId)) { {
                          setShowLandIdObj(true);
                        }
                      }
                    }}
                    disabled={disable}
                  >
                    <Icon url={svgArrow} />
                  </button>
                </td>
              </tr>
            }

            {specialService && (!order.services[0].maxObjCount
              || order.services[0].maxObjCount > order.objects.length) &&
              <tr>
                {formErrors['cadnum'] && formErrors['cadnum'].length>0 ? <td><div className='error-message'>{formErrors['cadnum']}</div></td> : null}
                <td style={{ display: "flex" }}>
                  <input
                    type="number"
                    className="serv-input-land"
                    style={{ width: "100%", margin: "0", padding: "0", textAlign: "center" }}
                    onChange={e => {
                      setCadnumId(e.target.value);
                      setCadNum(e.target.value);
                      if (e.target.value.length==0) {
                        formErrors['cadnum'] = '';
                        setFormErrors(Object.assign({}, formErrors));
                      }
                    }}
                    value={cadnumId || ''}
                    placeholder={t("service.cadnum")}
                    onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                    disabled={disable}
                  />

                  <button
                    className="btn-minus"
                    style={{ width: "32px", marginLeft: "6px", paddingLeft: "10px"}}
                    onClick={(e) => {
                      if (!region || region.code === "000") {
                        regionNote();
                      } else if (validateField('cadnum', cadnumId)) {
                        setShowObjSearch(true);
                      }
                    }}
                    disabled={disable}
                  >
                    <Icon url={svgArrow} />
                  </button>
                </td>
              </tr>
            }

            {order.services.length > 0
              && (!order.services[0].maxObjCount
                || order.services[0].maxObjCount > order.objects.length) &&
              <tr>
                <td style={{ display: "flex" }}>
                  {order.services.find(item => item.code === "601060") &&
                    <button
                      style={{ width: "7%", padding: "0 0 0 9px", margin: "0 6px 0 0" }}
                      onClick={event => {
                        setConfirm({
                          title: t("notif.choose-title"),
                          text: t("notif.choose-text"),
                          onConfirm: () => {
                            event.preventDefault();
                            handleCloseStats();
                            handleOnConfirm();
                          }
                        });
                      }}
                      disabled={disable}
                    >
                      <Icon url={svgArrow} />
                    </button>
                  }
                  <button className="my-obj-btn"
                    style={{ width: "100%", margin: "0", padding: "0", textAlign: "center" }}
                    onClick={() => {
                      if ((!region || region.code === "000") && order.services[0].code !== "807000") {
                        regionNote();
                      } else {
                        handleCloseStats();
                        setShowListService(false);
                        setShowObjSearch(false);
                        setShowListMyObjs(true);
                      }
                    }}
                    disabled={member==='От доверителя' || disable ? true : false}
                  >
                    {t("service.my-obj")}
                  </button>
                  
                  {!order.objects.length && <button
                    style={{ width: "32px", padding: "0 0 0 10px", margin: "0 0 0 6px" }}
                    onClick={() => {
                      if (!region || region.code === "000") {
                        regionNote();
                      } else {
                        setDisplayFormCreate(true);
                        setIsNewObject(true);
                      }
                    }}
                    disabled={disable}
                  >
                    <Icon url={svgAdd} className="icon" />
                  </button>}
                </td>
              </tr>
            }
          </tbody>
        </table>

        <div className='doc-title'>
          <h2 className={formErrors['docs'] ? 'title-error' : ''}>{t("service.doc-title")}</h2>
          {order.services.length>0 && <Info title={t("service.doc-help")} url={svgInfo} className="icon" />}
        </div>
        <table>
          <tbody>
            {listDoc()}
          </tbody>
        </table>
      </div>

      <div className="srvs-btns">
        <button
          style={{ margin: "0 5px 0 0", background: "#475A52", width: "40%" }}
          href=""
          onClick={() => {
            handleClickOrder(false);
          }}
        >
          {t("service.cancel")}
        </button>
        {order.isLoading ?
          <div className="load-wrap pay-load"><ClipLoader color={"#02c23e"} loading={true} /></div> :
          // <button className={orderValid() ? "" : "not-avail"}
          <button
            style={{ margin: "0 6px 0 5px", background: "#B55023", width: "40%" }}
            href=""
            onClick={(e) => {
              if ((!region || region.code === "000") && order.services[0].code !== "807000") {
                regionNote();
              } else {
                if (validateForm()) {
                  if (order.providers && provider==='НАО') {
                    order.providers = [];
                    order.provider = '';
                  }
                  if (member === 'От доверителя') {
                    order.iinBin=iinBin;
                  } else {
                    const typeCodeExists = order.docs.some(item => item.typeCode === '01011');
                    if (typeCodeExists) {
                      order.docs = order.docs.filter((item) => item.typeCode !=='01011');
                    }
                    order.iinBin=null;
                    setIinBin('');
                  }
                  order.phone=phone;
                  order.isLoading = true;
                  setOrder(Object.assign({}, order))
                  handlePayment(e);
                  order.phone=phone;
                  setOrder(Object.assign({}, order))
                }
              }
              
            }}
          >
            {t("service.send")}
          </button>
        }
      </div>

    </ObjectsAndServices>
  );
}