export const types = [{ id: 'BAR', text: ['Столбчатая'], value: 'BAR' },
{ id: 'PIE', text: ['Круговая'], value: 'PIE' },
{ id: 'LINE', text: ['Линейная'], value: 'LINE' },
{ id: 'DONUT', text: ['Кольцевая'], value: 'DONUT' },
{ id: '3DBAR', text: ['Столбчатая 3D'], value: '3DBAR' }];

export const nameX = "Category";
export const FULL_DATA = 0;
export const EMPTY_DATA = 1;
export const NO_DATE = 2;

export const CREATED = 1;
export const FINDED = 2;
export const FINDED_AND_CREATED = 3
