import React from "react";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";

const Button = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  padding: 5px 5px 2px 5px;
  border-radius: 5px;
  margin: 2px !important;
  font-weight: bold;
  background: ${props =>
    props.isActive ? props.palette.middleblue : props.palette.lightblue};
  background: ${props => (props.isArrestButton ? "#660000" : null)};
  background: ${props =>
    props.isArrestButton && props.isActive ? "#990000" : null};
    
  color: ${props => props.palette.text};
  &:hover {
    background: ${props => props.palette.middleblue};
    background: ${props => (props.isArrestButton ? "#990000" : null)};
  }
  .react-tooltip {
    text-transform: none;
  }
  img {
    width: 24px;
    height: 24px;
  }
`;

function TabButton({setActiveTab, tab, theme, i, activeTab, obj, handleObjExtData, getExtData, dataChange, setDataChange, standalone}) {
    const { t } = useTranslation();
    const handleTabExtData = (code, tab_id) => {
        var cadNum = obj.properties.kad_nomer;
        if (cadNum) {
          cadNum = cadNum.substring(0, 2) + ":" 
                + cadNum.substring(2, 5) + ":" 
                + cadNum.substring(5, 8) + ":" 
                + cadNum.substring(8);
          getExtData([cadNum], code)
            .then(result => {
              obj.properties[tab_id].items = [];
              result[cadNum].items
                  .filter(item => item.isFind === "1")
                  .forEach(item => obj.properties[tab_id].items.push(item));
              obj.properties[tab.id].isLoaded = true;
              setDataChange(!dataChange);
            })
        } else {
          obj.properties[tab.id].isLoaded = true;
          setDataChange(!dataChange);
        }
    }

    const loadExtData = (tab) => {
        if (tab.id === "buildings" && !obj.properties[tab.id].isLoaded) {
            var kad_props = {};
            obj.properties[tab.id].forEach(tabitem => {
                kad_props[tabitem.kad_nomer] = tabitem;
            });
            handleObjExtData(kad_props, tab);
        } else if (tab.id === "owners" && !obj.properties[tab.id].isLoaded) {
            handleTabExtData("INFO_OWNER_BY_CDR", tab.id);
        } else if (tab.id === "arrests" && !obj.properties[tab.id].isLoaded) {
            handleTabExtData("INFO_ARREST_BY_CDR", tab.id);
        } else if (tab.id === "obrems" && !obj.properties[tab.id].isLoaded) {
          handleTabExtData("INFO_CHARGE_BY_CDR", tab.id);
        }
    }
    
    return (
        <>
          {(standalone && tab.id !== 'archive' || (!standalone && ((tab.id === 'archive' && obj.properties.childs) || tab.id === 'info' || tab.id === 'specification'))) && (
            <Button
              onClick={() => {
                  setActiveTab(tab);
                  loadExtData(tab);
              }}
              palette={theme}
              isActive={activeTab === tab}
              isArrestButton={tab.isArrest}
              data-tip
              data-for={`button-tooltip-${i}`}
          >
              <ReactTooltip
                  className="react-tooltip"
                  id={`button-tooltip-${i}`}
                  delayShow={250}
              >
                  {t("info-tab.open-tab")} "{tab.getTitle()}"
              </ReactTooltip>
              <img src={`./${tab.id}.svg`} alt="React Logo" />
          </Button>
          )}
        </>
    )
}

export default TabButton;