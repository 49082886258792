export const tsel = {
  '001': 19, // Аккольский
  '014': 19, // Акколь
  '171': 24, // Бурабайский
  '160': 33, // Зерендинский
  '174': 26, // Кокшетау
  '018': 27, // Степногорск
  '284': 30, // Есиль
  '275': 32, // Жаркаинский
  '172': 25, // Енбекшильдерский
  '009': 23, // Буландынский
  '007': 28, // Егиндыкольский
  '006': 29, // Ерейментауский
  '008': 34, // Коргалжынский
  '011': 37, // Целиноградский
  '012': 36, // Шортандинский
  '002': 21, // Астраханский
  '003': 22, // Атбасарский
  '005': 20, // Аршалынский
  '004': 35, // Сандыктауский
  '016': 29, // Ерейментау
  '015': 22, // Атбасар
  '278': 31, // Жаксынский
  '277': 30, // Есильский

  '223': 57, // Жамбылский
  '164': 63, // Тайыншинский
  '228': 62, // Шал-акына
  '224': 56, // Есильский
  '162': 65, // Уалихановский
  '167': 55, // Акжарский
  '165': 61, // им. Г. Мусрепова
  '220': 58, // Кызылжарский
  '231': 64, // Тимирязевский
  '221': 59, // им. Магжана Жумабаева
  '225': 60, // Мамлютский
  '229': 54, // Аккаиынский
  '157': 53, // Айыртауский  
}