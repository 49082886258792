var webSocket = null;
var heartbeatMsg = '--heartbeat--';
var heartbeatInterval = null;

var nca_callback = null;

var keyType = 'AUTHENTICATION';
var storageAlias = 'PKCS12';

function initNCALayer(callbackM) {
	if (webSocket === null || webSocket.readyState === 3
		|| webSocket.readyState === 2) {
		webSocket = new WebSocket('wss://127.0.0.1:13579/');

		webSocket.onopen = function () {
			if (heartbeatInterval === null) {
				heartbeatInterval = setInterval(pingLayer, 1000);
			}
			if (callbackM) {
				callbackM();
			}
		};

		webSocket.onclose = function (event) {
		};

		webSocket.onmessage = function (event) {
			var result;
			var rw;
			if (event.data === heartbeatMsg) {
				return;
			}
			result = JSON.parse(event.data);
			rw = {
				result: result.result,
				secondResult: result.secondResult,
				errorCode: result.errorCode,
				code: result.code,
				responseObject: result.responseObject,
				message: result.message,
				getResult: function () {
					return this.result;
				},
				getSecondResult: function () {
					return this.secondResult;
				},
				getErrorCode: function () {
					return this.errorCode;
				},
				getMessage: function () {
					return this.message;
				},
				getResponseObject: function () {
					return this.responseObject;
				},
				getCode: function () {
					return this.code;
				}
			};
			if (nca_callback) {
				nca_callback(rw);
			}
		}

		webSocket.onerror = function (event) {
			openNcaLayerError("Ошибка соединения с NCALayer!");
		};
	} else if (callbackM) {
		callbackM();
	}
}

function chooseNCAStorageCall(callback) {
	if (webSocket === null || webSocket.readyState === 3
		|| webSocket.readyState === 2) {
		initNCALayer(function () {
			chooseNCAStorageCall(callback);
		});
	} else {
		// signXmlNewCall(function(result) {
		// signXmlNewBack(result);
		// });
		getKeyInfo(function (result) {
			callback(result);
			// webSocket.close();
		});
	}
}

function openNcaLayerError(message) {
	alert(message ? message : 'Ошибка');
}

function signXmlNewCall(data, keyType, callbackM) {
	var args = [storageAlias, keyType, data, "", ""];
	getDataNew('signXml', args, callbackM);
}

export function signXml(data, keyType) {
	return new Promise((resolve, reject) => {
		initNCALayer(() => {
			var args = [storageAlias, keyType, data, "", ""];
			getDataNew('signXml', args, (result) => {
				if (result.code === "200") {
					resolve(result)
				} else if (result.code === "500") {
					reject(result)
				}
			});
		})
	});
}

export function signB64Str(data, keyType) {
	return new Promise((resolve, reject) => {
		initNCALayer(() => {
			// Кодируем строку в base64
			var args = [storageAlias, keyType, btoa(unescape(encodeURIComponent(data))), false];
			getDataNew('createCMSSignatureFromBase64', args, (result) => {
				if (result.code === "200") {
					resolve(result)
				} else if (result.code === "500") {
					reject(result)
				}
			});
		})
	});
}

export function signText(data, keyType) {
	data = '<signTextWithNCA><![CDATA[' + data + ']]></signTextWithNCA>';
	return signXml(data, keyType);
}

function b64EncodeUnicode(str) {
	return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
		function toSolidBytes(match, p1) {
			return String.fromCharCode('0x' + p1);
		}));
}

/*
 * function signXmlNewBack(result) { if (result) { if (result['code'] === "500") {
 * if (result['message'] != null && result['message'] != 'action.canceled') {
 * openNcaLayerError(); } } else if (result['code'] === "200") { var signedData =
 * result['responseObject']; fillPersonData(signedData); webSocket.close(); } } }
 */

function getKeyInfo(callbackM) {
	var args = [storageAlias];
	getDataNew('getKeyInfo', args, callbackM);
}

/*
 * function getKeyInfoBack(result) { if (result) { if (result['code'] === "500") {
 * if (result['message'] != null && result['message'] != 'action.canceled') {
 * openNcaLayerError(); } } else if (result['code'] === "200") {
 * fillPersonData(result); fillOrgData(result); webSocket.close(); } } }
 * 
 * function fillPersonData(data) { var responseObject = data.responseObject; var
 * subjectDn = responseObject.subjectDn; var subjectAttrs =
 * subjectDn.split(','); var iin = findSubjectAttr(subjectAttrs,
 * 'SERIALNUMBER').substr(3); var email = findSubjectAttr(subjectAttrs, 'E');
 * var cn = findSubjectAttr(subjectAttrs, 'CN'); cn = cn || ''; var middleName =
 * findSubjectAttr(subjectAttrs, 'G'); middleName = middleName || ''; var
 * fullName = cn.concat(" ").concat(middleName);
 * 
 * console.log(iin); console.log(email); console.log(fullName); }
 * 
 * function fillOrgData(data) { var responseObject = data.responseObject; var
 * subjectDn = responseObject.subjectDn; var subjectAttrs =
 * subjectDn.split(','); var bin = findSubjectAttr(subjectAttrs, 'OU'); var
 * organizationName = findSubjectAttr(subjectAttrs, 'O'); if (bin !== null) { if
 * (bin.length > 3) { bin = bin.substr(3); } console.log(bin);
 * console.log(organizationName); } }
 */

function findSubjectAttr(attrs, attr) {
	var tmp;
	var numb;

	for (numb = 0; numb < attrs.length; numb++) {
		tmp = attrs[numb].replace(/^\s\s*/, '').replace(/\s\s*$/, '');
		if (tmp.indexOf(attr + '=') === 0) {
			return tmp.substr(attr.length + 1);
		}
	}

	return null;
}

function getDataNew(method, args, callbackM) {
	var methodVariable = {
		'module': 'kz.gov.pki.knca.commonUtils',
		'method': method,
		'args': args
	};
	if (callbackM)
		nca_callback = callbackM;
	webSocket.send(JSON.stringify(methodVariable));
}

function pingLayer() {
	try {
		webSocket.send(heartbeatMsg);
	} catch (error) {
		clearInterval(heartbeatInterval);
		heartbeatInterval = null;
		webSocket.close();
	}
}
