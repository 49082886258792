import React, { useEffect } from "react";
import { TableWrapper } from "../Stats/Table";
import { Wrapper, Header, Title, CloseButton } from "../Stats/BarChart";
import svgClose from '../../assets/img/stat_close.svg';
import Table from "./TableQueue";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import arrowSvg from "../../assets/img/arrow.svg";
import homeSvg from "../../assets/img/home.svg";
import searchSvg from "../../assets/img/search-obj.svg";
import firstPageArrow from "../../assets/img/first-page-arrow.svg";
import prevPageArrow from "../../assets/img/prev-page-arrow.svg";
import nextPageArrow from "../../assets/img/next-page-arrow.svg";
import lastPageArrow from "../../assets/img/last-page-arrow.svg";
import comboImg from "../../assets/img/combo.svg";
import { Scrollbars } from "react-custom-scrollbars";
import ClipLoader from "react-spinners/ClipLoader";

export const QueueWrapper = styled.div`
  .spinner-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 528px;
  }
  input {
    margin-left: 0;
    max-width: initial;
    width: 100%;
  }
  .queue-pagenav {
    max-width: initial;
    margin-top: 0;
    width: initial;
  }
  .table {
    height: 406px;
    overflow: overlay;
  }
  .tr {
    font-size: 14px !important;
  }
  .td {
    justify-content: flex-start !important;
    padding: 8px 4px !important;
  }

  .queue-search {
    width: 50%;
  }

  .not-found {
    font-family: Roboto;
    font-size: 14px;
    line-height: 15px;
    color: #606060;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 162px;
    height: auto;
    text-align: start;
  }

  .queue-note {
    font-family: Roboto;
    font-size: 16px;
    line-height: 15px;
    color: #606060;
    margin: 24px 23px;
  }
  .queue-note:first-child {
    margin-top: 23px;
  }
  .queue-search-status {
    position: relative;
    width: 50%;
    display: flex;
    border: 1px solid rgb(180, 207, 188);
    border-radius: 5px;
    align-items: center;
    padding-left: 12px;
    font-family: Roboto;
    font-size: 14px;
    color: rgb(41, 74, 55);
    margin-left: 12px;
    height: auto;
  }
  .queue-search-button {
    cursor: pointer;
    width: 35px;
    border-top: 1px solid rgb(180, 207, 188);
    border-right: none;
    border-bottom: 1px solid rgb(180, 207, 188);
    border-left: 1px solid rgb(180, 207, 188);
    border-image: initial;
    border-radius: 5px;
    height: calc(100% + 2px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .queue-search-inner-block {
    position: absolute;
    background: rgb(255, 255, 255);
    top: 34px;
    left: 0px;
    z-index: 1;
    width: 100%;
  }
  .queue-search-inner-variants {
    height: 32px;
    border: 1px solid rgb(180, 207, 188);
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-left: 12px;
    margin-bottom: 2px;
  }

  .queue-search-inner-variants:hover {
    background-color: #DCF0E2 !important;
    color: #294A37;
    font-weight: 600;
  }
  
  .ate-chain {
    font-family: Roboto;
    font-size: 14px;
    line-height: 17px;
    color: #294A37;
    display: flex;
    align-items: center;
  }

  .scrollbar-view {
    margin-bottom: 10px !important;
  }
`;

export const QueuePopup = styled.div`
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.25) !important;
  border-radius: 5px !important;

  .queue-head {
    border-radius: 5px 5px 0px 0px;
    height: 50px;
    padding-right: 20px;
  }

  .queue-pagenav.queue-foot {
    border-radius: 0px 0px 5px 5px;
  }

  .queue-title {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 15px;
    color: #FFFFFF;
  }

  .queue-cls-btn {
    position: initial;
    background: none;
    box-shadow: none;
  }

  .queue-cancel {
    border: 1px solid #B4CFBC;
    box-sizing: border-box;
    border-radius: 5px;
    min-width: 46px;
    height: 32px;
    margin-right: 12px;
    cursor: pointer;
  }

  .queue-cancel.queue-home {
    background: url(${homeSvg}) no-repeat;
    background-size: 40%;
    background-position: center;
  }

  .queue-cnl-img {
    width: 40px;
    height: 30px;
    background-color: #A0B6A6;
    -webkit-mask: url(${arrowSvg}) no-repeat;
    mask: url(${arrowSvg}) no-repeat;
    -webkit-transform: rotate(225deg);
    -moz-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    -o-transform: rotate(225deg);
    transform: rotate(225deg);
    margin: -17px 0px 0px -2px;
  }

  .queue-search-fld {
    border: 1px solid #B4CFBC;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    width: 50%;
    justify-content: space-between;
    height: 32px;
    padding-right: 8px;
    margin: 0;

    input {
      border: none;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 12px;
      color: #294A37;
    }

    input:focus {
      outline: none;
    }
  }

  .queue-search-fld.queue-search-num {
    margin-left: 12px;
  }

  .queue-search-btn {
    width: 24px;
    height: 24px;
    background-color: #A0B6A6;
    -webkit-mask: url(${searchSvg}) no-repeat;
    mask: url(${searchSvg}) no-repeat;
    cursor: pointer;
  }

  .queue-page-nav {
    background: #FFFFFF;
    border: 1px solid #B4CFBC;
    border-radius: 5px;
    margin: 0px 20px 20px 20px;
    font-family: Roboto;
    font-size: 12px;
    color: #294A37;
    display: flex;
    justify-content: space-between;

    .prev {
      display: flex;
      align-items: center;
      margin: 9px 12px;

      .first-btn {
        width: 12px;
        height: 15px;
        background: url(${firstPageArrow}) no-repeat;
        background-size: 100%;
        margin-right: 14px;
        cursor: pointer;
      }
      .prev-btn {
        width: 12px;
        height: 11px;
        background: url(${prevPageArrow}) no-repeat;
        background-size: 100%;
        cursor: pointer;
      }
    }
    .next {
      display: flex;
      align-items: center;
      margin: 9px 12px;

      .next-btn {
        width: 12px;
        height: 11px;
        background: url(${nextPageArrow}) no-repeat;
        background-size: 100%;
        margin-right: 14px;
        cursor: pointer;
      }
      .last-btn {
        width: 12px;
        height: 15px;
        background: url(${lastPageArrow}) no-repeat;
        background-size: 100%;
        cursor: pointer;
      }
    }
    .pages {
      display: flex;
      padding: 6px 12px;
      align-items: center;
      border-left: 1px solid #B4CFBC;
      border-right: 1px solid #B4CFBC;

      input {
        outline: none;
        border: 1px solid #B4CFBC;
        border-radius: 3.14985px;
        padding: 0px 10px;
        height: 21px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 9px;
      }
    }
    .count {
      margin: 9px 12px;
    }
  }

  .description {
    display: flex;
    margin: 20px 20px 14px 20px;

    a:first-child:hover {
      text-decoration: underline;
      color: blue;
    }

    div:last-child a:last-child:hover {
      text-decoration: underline;
      color: blue;
    }

    a:first-child {
      font-family: Roboto;
      font-style: italic;
      font-size: 13px;
      line-height: 15px;
      color: #606060;
      margin-right: 25px;
    }
    div:last-child {
      display: flex;

      div:first-child {
        width: 60px;
        height: 22px;
        background: #F7FBF8;
        border: 1px solid #D00606;
        margin: 0;
        padding-left: 3px;
        margin-right: 10px;
        color: #D00606;
        font-size: 20px;
        line-height: 1.3;
      }
      a:last-child {
        font-family: Roboto;
        font-size: 14px;
        line-height: 15px;
        color: #606060;
      }
    }
  }

  .region-select {
    background: #DCF0E2;
    border: 1px solid #294A37;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: 'Roboto';
    font-size: 14px;
    line-height: 13px;
    color: #294A37;
    width: 220px;
    height: 32px;
    margin-right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
`;

const QueueTableWrapper = styled(TableWrapper)`
  height: 340px;
  padding: 20px;
  padding-top: 0;
  padding-bottom: 10px;

  table {
    border-spacing: 0;
  }

  thead {
    th {
      background: #DCF0e2;
      color: #294A37;
      border-top: 1px solid rgb(41,74,55);
      border-bottom: 1px solid rgb(41,74,55);
      border-left: 1px solid rgb(41,74,55);
      font-family: 'Roboto';
      font-size: 13px;
      padding: 4px 6px; 
    }

    th:last-child {
      border-right: 1px solid rgb(41,74,55);
    }

    th:first-child {
      border-radius: 5px 0px 0px 0px;
    }

    th:last-child {
      border-radius: 0px 5px 0px 0px;
    }
  }

  tbody {
    tr:nth-child(even) {
      td {
        background: #F7FBF8;
        box-shadow: none;
        color: #294A37;
        font-size: 13px;
        font-family: 'Roboto';
      }
    }
    tr:nth-child(odd) {
      td {
        background: #FFF;
        box-shadow: none;
        color: #294A37;
        font-size: 13px;
        font-family: 'Roboto';
      }
    }
    td {
      min-width: initial;
      max-width: initial;
      border-bottom: 1px solid #B4CFBC;
      border-left: 1px solid #B4CFBC;
    }
    tr {
      td:last-child {
        border-right: 1px solid #B4CFBC;
      }
    }
    tr.queue-err td {
      color: #D00606;
    }
    tr.queue-warn td {
      background: #F4F4F4;
      color: #7C7C7C;
    }
  }
`;

export default function Queue({
  palette, handleCloseQueue, setAte, queueNum, handleCloseQueueAndRegions,
  getQueue, ate, nameAndInitials, setQueueNum, responsive,
  handleOpenRegions, setNameAndInitials, getQueueByNum, getQueueStatus,
  searchQueueNum, searchQueueFromReg
}) {
  const [queue, setQueue] = React.useState();
  const [queueStatus, setQueueStatus] = React.useState();
  const [queueStatusId, setQueueStatusId] = React.useState('');
  const [showStatuses, setShowStatuses] = React.useState(false);
  const { i18n } = useTranslation();
  const [getObjects, setGetObjects] = React.useState({});
  const [pageNum, setPageNum] = React.useState(0);
  const [count, setCount] = React.useState(0);
  const nameRef = React.useRef();
  const numRef = React.useRef();
  const statusesRef = React.useRef();
  const [curPageWidth, setCurPageWidth] = React.useState(0);
  const span = React.useRef();
  const [enteredPage, setEnteredPage] = React.useState("");

  useEffect(() => {
    span.current && setCurPageWidth(span.current.offsetWidth + 30);
  }, [enteredPage]);

  const changeHandler = evt => {
    const num = evt.target.value;
    const a = num.replace(/\D/g, "").replaceAll(/^0+/g, "");
    if (!isNaN(num) && Number(num) % 1 === 0 && a === num) {
      setEnteredPage(num);
    }
  };

  const handleNextPage = React.useCallback(() => {
    if (pageNum + 1 <= Math.ceil(count / 10) - 1) {
      getObjects.func(pageNum + 1);
      setPageNum(pageNum + 1);
    }
  }, [count, pageNum, getObjects]);

  const handlePrevPage = React.useCallback(() => {
    if (pageNum > 0) {
      getObjects.func(pageNum - 1);
      setPageNum(pageNum - 1);
    }
  }, [count, pageNum, getObjects]);

  const handleLastPage = React.useCallback(() => {
    if (pageNum + 1 <= Math.ceil(count / 10) - 1) {
      getObjects.func(Math.ceil(count / 10) - 1);
      setPageNum(Math.ceil(count / 10) - 1);
    }
  }, [count, pageNum, getObjects]);

  const handleFirstPage = React.useCallback(() => {
    if (pageNum > 0) {
      getObjects.func(0);
      setPageNum(0);
    }
  }, [count, pageNum, getObjects]);

  useEffect(() => {
    if (queueNum) {
      getQueueByNum(i18n.language, queueNum, ate.kato)
        .then(result => {
          setQueue(result);
          setCount(result.count);
          setPageNum(result.pageNum);
          setEnteredPage(Math.min(pageNum + 1, Math.ceil(result.count / 10)));
        })
    } else {
      getQueue(i18n.language, 0, 10, ate && !searchQueueFromReg ? ate.kato : '', nameAndInitials, null, searchQueueNum).then(result => { //
        setQueue(result);
        setCount(result.count);
        setEnteredPage(Math.min(pageNum + 1, Math.ceil(result.count / 10)));
      });
    }

    getQueueStatus(i18n.language).then(result => {
      setQueueStatus(result);
    })

    return () => {
      setQueueNum(null);
    };
  }, [i18n.language, getQueue]);

  useEffect(() => {
    setGetObjects({
      func: pageNum => {
        getQueue(i18n.language, pageNum, 10, ate && !searchQueueFromReg ? ate.kato : '', nameAndInitials, queueStatusId, searchQueueNum).then(result => {
          setQueue(result);
          setCount(result.count);
          setEnteredPage(Math.min(pageNum + 1, Math.ceil(result.count / 10)));
        });
      },
    })
  }, [queue, i18n.language, getQueue, ate]);

  const handleSearch = (nameAndInitials, searchQueueNum, statusId) => {
    getQueue(i18n.language, 0, 10, ate && !searchQueueFromReg ? ate.kato : '', nameAndInitials, statusId, searchQueueNum).then(result => {
      setQueue(result);
      setPageNum(0);
      setCount(result.count);
      setEnteredPage(Math.min(1, Math.ceil(result.count / 10)));
    });
  };

  const homeClick = () => {
    setAte(null);
    setNameAndInitials('');
    nameRef.current.value = '';
    getQueue(i18n.language, 0, 10, null, null).then(result => {
      setQueue(result);
      setCount(result.count);
      setEnteredPage(Math.min(pageNum + 1, Math.ceil(result.count / 10)));
    });
  };

  const statusSelect = id => {
    setQueueStatusId(id);
    handleSearch(nameRef.current.value, numRef.current.value, id);
    setShowStatuses(false);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (statusesRef.current && !statusesRef.current.contains(event.target)) {
        setShowStatuses(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [statusesRef]);

  const searchQueue = () => {
    setNameAndInitials(nameRef.current.value);
    setQueueNum(numRef.current.value);
    handleSearch(nameRef.current.value, numRef.current.value, queueStatusId);
  };

  const setPageEnter = (e) => {
    if (e.key === "Enter") {
      const num = Number(enteredPage);
      if (num <= Math.ceil(count / 10)) {
        getObjects.func(num - 1);
        setPageNum(num - 1);
      }
    }
  };

  const curPage = enteredPage ? enteredPage : Math.min(pageNum + 1, Math.ceil(count / 10));

  return (
    <Wrapper palette={palette}>
      <QueuePopup className="center-div"
        style={{
          minWidth: "1060px",
          width: "auto",
          left: "-50%"
        }}
      >
        <QueueWrapper>
          <Header className="queue-head">
            <Title className="queue-title">{i18n.t("queue.title")}</Title>
            <CloseButton className="queue-cls-btn" onClick={() => handleCloseQueueAndRegions()}>
              <img src={svgClose} style={{ width: '16px' }} alt="" />
            </CloseButton>
          </Header>
          {!queue ?
            <div className="spinner-wrap">
              <ClipLoader color={"#02c23e"} loading={true} size={50} />
            </div>
            :
            <>
              <div style={{ display: "flex", padding: "20px 20px 12px" }}>
                <div className="queue-cancel" onClick={() => handleOpenRegions()}>
                  <div className="queue-cnl-img" />
                </div>
                <div className="region-select"
                  onClick={() => {
                    handleOpenRegions();
                  }}
                >
                  {i18n.t("queue.choose-reg")}
                </div>
                <div className="queue-search-fld">
                  <input placeholder={i18n.t("queue.find")} ref={nameRef} defaultValue={nameAndInitials ? nameAndInitials : ''} onKeyDown={(e) => e.key === "Enter" && searchQueue()} />
                  <div className="queue-search-btn" onClick={searchQueue} />
                </div>
                <div className="queue-search-fld queue-search-num">
                  <input placeholder={i18n.t("queue.find-num")} ref={numRef} defaultValue={searchQueueNum ? searchQueueNum : ''} onKeyDown={(e) => e.key === "Enter" && searchQueue()} />
                  <div className="queue-search-btn" onClick={searchQueue} />
                </div>

                <div className="queue-search-status">
                  <div style={{ width: "100%" }}>{queueStatusId ? queueStatus.find(item => item.id == queueStatusId).statusName : i18n.t("queue.all-status")}</div>
                  <div className="queue-search-button"
                    onClick={() => {
                      setShowStatuses(!showStatuses);
                    }}
                  >
                    <img src={comboImg}
                      style={{
                        width: 14,
                        height: 7,
                        marginTop: "2px"
                      }}
                      alt=""
                    />
                  </div>
                  {showStatuses &&
                    <div ref={statusesRef} className="queue-search-inner-block">
                      <div onClick={() => {
                        statusSelect('');
                      }} className="queue-search-inner-variants">{i18n.t("queue.all-status")}</div>
                      {queueStatus.filter(item => item.id != 4).map((item, i) =>
                        <div key={i} onClick={() => {
                          statusSelect(item.id);
                        }} className="queue-search-inner-variants">{item.statusName}</div>
                      )}
                    </div>
                  }
                </div>
              </div>

              {ate && ate.kato === '750000000' ?
                <div style={{ margin: "128px 0px", height: "auto" }}>
                  <div className="queue-note">Уважаемый услугополучатель сообщаем, что в связи с отсутствием свободных территорий (согласно утвержденного Генерального плана), в данном регионе специальный учет на получение земельного участка для индивидуального жилищного строительства (очередь) не ведется</div>
                  <div className="queue-note">Құрметті көрсетілетін қызметті алушы, бос аумақтардың болмауына байланысты (бекітілген Бас жоспарға сәйкес), осы өңірде жеке тұрғын үй құрылысы үшін жер учаскесін алуға арнайы есепке алудың (кезек) жүргізілмейтінін хабарлаймыз</div>
                </div>
                :
                ate && ate.refine ?
                  <div className="not-found">{i18n.t("queue.check-list")}</div>
                  :
                  !queue || !queue.items.length ?
                    <div className="not-found">{i18n.t("queue.check-list")}</div>
                    :
                    <Scrollbars
                      className="select-region-div"
                      autoHeight={true}
                      autoHide={true}
                      autoHeightMax={1000}
                      renderView={props => <div {...props} className="scrollbar-view" />}
                    >
                      <QueueTableWrapper palette={palette} columnCount={7}>
                        <Table queue={queue} lang={i18n.language} i18n={i18n} />
                      </QueueTableWrapper>
                    </Scrollbars>
              }

              <div className="queue-page-nav">
                <div style={{ display: "flex" }}>
                  <div className="prev">
                    <div className="first-btn" onClick={handleFirstPage}></div>
                    <div className="prev-btn" onClick={handlePrevPage}></div>
                  </div>
                  <div className="pages">
                    {i18n.language === "kz" ?
                      <>
                        {/* <div>{Math.min(pageNum + 1, Math.ceil(count / 10))}</div>
                        ішіндегі&nbsp;
                        {Math.ceil(count / 10)}
                        &nbsp;бет */}
                        {Math.ceil(count / 10)} ішіндегі&nbsp;
                        <div>{Math.min(pageNum + 1, Math.ceil(count / 10))}</div>
                        &nbsp;бет
                      </>
                      :
                      <>
                        Страница
                        <span id="hide" ref={span} style={{ position: "absolute", opacity: "0" }}>{enteredPage}</span>
                        <input value={curPage} style={{ width: curPageWidth }} autoFocus onChange={changeHandler} onKeyDown={setPageEnter} />
                        из {Math.ceil(count / 10)}
                      </>
                    }
                  </div>
                  <div className="next">
                    <div className="next-btn" onClick={handleNextPage}></div>
                    <div className="last-btn" onClick={handleLastPage}></div>
                  </div>
                </div>
                <div className="count">
                  {i18n.language === "kz" ?
                    <>
                      {count} жолдың ішіндегі {Math.min(pageNum * 10 + 1, count)} - {Math.min(pageNum * 10 + 10, count)} көрсетілген
                    </>
                    :
                    <>
                      Отображено {Math.min(pageNum * 10 + 1, count)} - {Math.min(pageNum * 10 + 10, count)} из {count} строк
                    </>
                  }
                </div>
              </div>

              <div className="description">
                <a href={window.P414_URL}>* {i18n.t("queue.descr1")}.</a>
                <div>
                  <div>*</div>
                  <a href={window.P414_URL}>{i18n.t("queue.descr2")}</a>
                </div>
              </div>
            </>}
        </QueueWrapper>
      </QueuePopup>
    </Wrapper>
  );
}