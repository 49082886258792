import React from 'react';
import styled from "styled-components";
import { CollapsibleRow, LegendRow, RowComponent } from '../Layers/Row';
import LayerIcon, { LayerSvgComponent } from '../Layers/LayerIcon';
import Select from '../Layers/Select';

const LegendCollapsibleRow = styled(CollapsibleRow)`
    .row {
        padding-left: 0px;
        grid-template-columns: 7% 93% 0% 0% 0% 0%;

        img {
            margin: 8px;
        }
    }

    .ekn-map-sub {
        grid-template-columns: 7% 0% 93% 0% 0% 0% 0% !important;
    }

    .layers-row-input:hover {
        background: #d1e8d9;
        box-shadow: none;
        cursor: default;

        span {
            color: #005b2d;
        }

        svg {
            cursor: pointer;
            fill: #02c23e;
        }
    }

    .layers-row-input {
        svg:hover {
            fill: #fff;
        }
    }
`;

const NewLegendRow = styled(LegendRow)`
    grid-template-columns: 0% 0% 7% 93%;

    img {
        margin-left: 8px;
    }

    svg {
        margin-left: 8px;
        width: 14px;
        height: 14px;
    }
`;

const Row = ({
    theme,
    sublayers,
    child,
    layerName,
    layerText,
    history,
    region,
    handleSetVersion,
    openSlider
}) => {
    const [expanded, setExpanded] = React.useState(true);

    return sublayers ? (
        <LegendCollapsibleRow palette={theme} isExpanded={expanded} openSlider={openSlider}>
            <div
                className={"row layers-row-input " + (expanded ? " layer-expanded " : "") + (sublayers.length > 0 ? " ekn-map-sub" : "")}
            >
                {sublayers.length > 0 ? (
                    <svg
                        className="expanded-icon"
                        onClick={() => setExpanded(!expanded)}
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 20 20"
                    >
                        <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z" />
                    </svg>

                ) : null}
                {[
                    "free",
                    "req",
                    "eval",
                    "func",
                    "pdp",
                    "gp",
                    "voz",
                    "vop",
                    "inv",
                    "water",
                    "gas",
                    "storm_water",
                    "con",
                    "heat",
                    "sewer",
                    "electr",
                    "pch",
                    "rst",
                    "ugl",
                    "ugpol",
                    "base",
                    "bing",
                    "garysh",
                    "kazgeo",

                ].includes(layerName) ? (
                    <div />
                ) : (
                    <LayerIcon theme={theme} layerName={layerName} />
                )}
                {history ? (
                    <Select
                        theme={theme}
                        layerText={layerText}
                        history={history}
                        handleSetVersion={handleSetVersion.bind(this, layerName)}
                    ></Select>
                ) : (
                    <span>{layerText}</span>
                )}
            </div>

            <div className="expandable">
                {sublayers.map((sublayer, i) => (
                    <NewLegendRow palette={theme} key={i} className="legend-row">
                        <div></div>
                        <div />
                        {sublayer.src ? (
                            <img src={sublayer.src} alt="" style={{ borderRadius: "3px" }} />
                        ) : sublayer.svg ? (
                            <LayerSvgComponent>
                                <rect x="0" y="0" height="16" width="16" style={sublayer.svg} />
                            </LayerSvgComponent>
                        ) : (
                            <LayerIcon theme={theme} layerName={sublayer.name} />
                        )}
                        <span>{sublayer.title}</span>
                    </NewLegendRow>
                ))}
            </div>
        </LegendCollapsibleRow>
    ) : (
        <RowComponent
            palette={theme}
            child={child}
            region={region}
            className="layers-row-input"
        >
            <div></div>
            {child ? <div></div> : null}
            {[
                "req",
                "eval",
                "func",
                "pdp",
                "gp",
                "voz",
                "vop",
                "inv",
                "water",
                "gas",
                "storm_water",
                "con",
                "heat",
                "sewer",
                "electr",
                "pch",
                "rst",
                "ugl",
                "ugpol",
                "base",
                "bing",
                "garysh",
                "kazgeo",

            ].includes(layerName) ? (
                <div />
            ) : (
                <LayerIcon theme={theme} layerName={layerName} />
            )}
            {history ? (
                <Select
                    theme={theme}
                    layerText={layerText}
                    history={history}
                    handleSetVersion={handleSetVersion.bind(this, layerName)}
                ></Select>
            ) : (
                <span className="layer-row-select">{layerText}</span>
            )}
        </RowComponent>
    );
}

export default Row;