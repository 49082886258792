import React, { useEffect } from "react";
import { Wrapper, Header, Title, CloseButton } from "../Stats/BarChart";
import { QueuePopup, QueueWrapper } from "./Queue";
import svgClose from '../../assets/img/stat_close.svg';
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import flagSvg from "../../assets/img/flag.svg";
import listSvg from "../../assets/img/list.svg";
import { Scrollbars } from "react-custom-scrollbars";

const RegionsPopup = styled(QueuePopup)`
  .regions {
    width: 1060px; 
    display: flex; 
    flex-wrap: wrap; 
    padding: 10px; 
    padding-bottom: 30px;

    .sub-reg-btn {
      position: relative;
      div {
        display: none;
        position: absolute;
        top: -30px;
        font-size: 12px;
        background: #DCF0E2;
        padding: 3px 6px;
        width: max-content;
        color: #779480;
        font-family: Roboto;

        div {
          top: 14px;
          left: 10px;
          width: 10px;
          height: 11px;
          z-index: -1;
          transform: rotate(45deg);
        }
      }
    }

    .sub-reg-btn:hover {
      div {
        display: flex;
      }
    }

    .sub-reg-btn:first-child {
      margin-left: 10px;
    }
  }

  .region-btn {
    height: fit-content;
    position: relative;
    border: 1px solid #DCF0E2;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 8px 15px;
    font-family: Roboto;
    font-size: 14px;
    line-height: 17px;
    color: #294A37;
    justify-content: space-between;
  }

  .region-btn.mobile {
    flex-grow: 1;
  }

  .region-btn-img {
    width: 35px;
    height: 35px;
    border: 1px solid #DCF0E2;
    margin-left: 10px;
    cursor: pointer;
    background-position: center !important;
  }
`;

export default function Regions({
  ate, setAte, getAtes, palette, ates,
  setAtes, handleCloseQueueAndRegions,
  setNameAndInitials, handleOpenQueue,
  ateChain, setAteChain, nameAndInitials,
  queueNum, setQueueNum, setSearchQueueFromReg
}) {

  const { i18n } = useTranslation();
  const [parent, setParent] = React.useState();
  const [region, setRegion] = React.useState(ate ? ate.parent.name : undefined);
  const nameRef = React.useRef();
  const numRef = React.useRef();

  useEffect(() => {
    getAtes(i18n.language, ate ? ate.parent.kato : "000000000").then(result => {
      if (ate) {
        setRegion(ate.parent.name)
        setParent(ate.parent.parent);
      }
      setAtes(result);
    });
  }, [i18n.language, getAtes]);

  const homeClick = () => {
    setAte(null);
    setNameAndInitials('');
    handleOpenQueue();
  };

  const handleAteClick = (ate) => {
    if (!ate.isLeaf) {
      getAtes(i18n.language, ate.kato).then(result => {
        setParent(ate.parent.kato);
        setRegion(ate.name);
        setAtes(result);
        setAteChain((!ateChain ? ate.name : (ateChain + " - " + ate.name)));
      });
    } else {
      setAte(ate);
      setNameAndInitials('');
      handleOpenQueue();
    }
  };

  const handleAteSelect = (ate) => {
    setAte(ate);
    setNameAndInitials('');
    setSearchQueueFromReg(false);
    handleOpenQueue();
  };

  const searchQueue = () => {
    setNameAndInitials(nameRef.current.value);
    setQueueNum(numRef.current.value);
    setSearchQueueFromReg(true);
    handleOpenQueue();
  };

  return (ates.length > 0 ?
    <Wrapper palette={palette}>
      <RegionsPopup className="center-div"
        style={{
          minWidth: "1060px",
          width: "auto",
          left: "-50%"
        }}
      >
        <QueueWrapper>
          <Header className="queue-head">
            <Title className="queue-title">{i18n.t("queue.title")}</Title>
            <CloseButton className="queue-cls-btn" onClick={() => {
              setAteChain('');
              handleCloseQueueAndRegions();
            }}>
              <img src={svgClose} style={{ width: '16px' }} alt="" />
            </CloseButton>
          </Header>

          <div style={{ display: "flex", padding: "20px 20px 0px" }}>
            <div className="queue-search-fld">
              <input placeholder={i18n.t("queue.find")} ref={nameRef} defaultValue={nameAndInitials ? nameAndInitials : ''} onKeyDown={(e) => e.key === "Enter" && searchQueue()} />
              <div className="queue-search-btn" onClick={searchQueue} />
            </div>
            <div className="queue-search-fld queue-search-num">
              <input placeholder={i18n.t("queue.find-num")} ref={numRef} defaultValue={queueNum ? queueNum : ''} onKeyDown={(e) => e.key === "Enter" && searchQueue()} />
              <div className="queue-search-btn" onClick={searchQueue} />
            </div>
          </div>

          <div style={{ display: "flex", padding: "20px 20px 0px" }}>
            {(parent ?
              <div className="queue-cancel" onClick={() => {
                getAtes(i18n.language, parent).then(result => {
                  if (result[0].parent.kato === '000000000') {
                    setParent(null);
                    setAtes(result);
                    setRegion(null);
                    setAteChain('');
                  } else {
                    setParent(result[0].parent.parent);
                    setRegion(result[0].parent.name);
                    setAtes(result);
                    const ateChainArr = ateChain.split(" - ").slice(0, -1);
                    setAteChain(ateChainArr.join(" - "));
                  }
                });
              }}>
                <div className="queue-cnl-img" />
              </div>
              : null
            )}
            {/*<div className="queue-cancel queue-home"
              onClick={() => {
                homeClick();
              }}
            />*/}
            <div className="ate-chain">
              {ateChain}
            </div>
          </div>

          <Scrollbars
            className="select-region-div"
            autoHeight={true}
            autoHide={true}
            autoHeightMax={1000}
            renderView={props => <div {...props} className="scrollbar-view" />}
          >
            <div style={{ height: "481px" }}>
              <div className="regions">
                {ates.map((ate, i) => (
                  <div key={i} style={{ display: "flex", width: "31%", margin: "10px" }}>
                    <div className="region-btn" style={{ width: ate.isLeaf ? "86%" : "72%" }}>
                      {ate.name}
                    </div>
                    {!ate.isLeaf &&
                      <div className="region-btn-img sub-reg-btn" style={{ background: `url(${flagSvg}) no-repeat`, backgroundSize: "18px" }}
                        onClick={(e) => {
                          handleAteClick(ate);
                        }}
                      >
                        <div>
                          <div></div>
                          {i18n.t("queue.list-ate")}
                        </div>
                      </div>
                    }
                    <div className="region-btn-img sub-reg-btn" style={{ background: `url(${listSvg}) no-repeat`, backgroundSize: "22px" }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleAteSelect(ate);
                      }}
                    >
                      <div>
                        <div></div>
                        {i18n.t("queue.list-queue")}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Scrollbars>
        </QueueWrapper>
      </RegionsPopup>
    </Wrapper>
    : null
  );

}
