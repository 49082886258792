import React from "react";
import styled from "styled-components";

export const TableWrapper = styled.div` 
  padding: 12px;

  .table-container {
    max-width: 1000px;
    max-height: 600px;
    overflow-y: auto;
    font-family: sf-pro-display-regular;
    border-radius: 4px;

    ::-webkit-scrollbar {
      width: 16px;
      height: 16px;
    }
    ::-webkit-scrollbar-thumb {
      border: 8px solid rgba(0, 0, 0, 0);  
      background-clip: padding-box;
      -webkit-border-radius: 0;
      background-color: rgba(0, 0, 0, 0.15);
      -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.025);
      border-right-width: 0;
      border-left-width: 8px;
      border-bottom-width: 0;
      border-top-width: 8px;
    }
    ::-webkit-scrollbar-button {
      width: 0;
      height: 0;
      display: none;
    }
    ::-webkit-scrollbar-corner {
        background-color: transparent;
    }

    ::-webkit-scrollbar-track {
      background: none;
      -webkit-box-shadow: none;
      border-radius: none;
    }
  }

  td {
    box-shadow: inset -0.25px 0.25px #707070;
    color: #005A2F;
    background: #B4D1BD;
    /*border: solid 0.25px #707070;*/
  }

  th {
    box-shadow: inset 0.25px 0.25px #02C23E, 0 0.25px #02C23E;
  }

  .min {
    color: red;
  }

  .max {
    color: #000;
  }

  .title {
    text-align: start;
    font-weight: normal;
  }

  .even {
    td {
      background: #D3E8DA;
    }
  }

  tfoot {
    td {
      background: #97BDA3;
      padding-bottom: 18px;
      padding-top: 8px;
    }
    td.title {
      font-weight: bold;
    }
    font-weight: bold;
    padding-bottom: 18px;
  }

  thead th {
    position: sticky; 
    top: 0;
  }

  table  { 
    width: 100%; 
  }
  
  th { 
    padding: 8px 16px; 
  }

  td {
    padding: 6px 11px;
    font-size: 14px;
    min-width: 100px;
    max-width: 100px;
    word-break: break-word;
  }

  th { 
    background: #005A2F;
    color: #fff;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: normal;
  }
`;

export default function Table({ palette, stats, xDimName }) {
  const min = stats.legend.map(e => Math.min.apply(Math, stats.items.map(item => item.data[e.id])));
  const max = stats.legend.map(e => Math.max.apply(Math, stats.items.map(item => item.data[e.id])));

  return (
    <TableWrapper>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>{xDimName}</th>
              {stats.legend.map((el, i) => (
                <th key={i}>
                  {el.text}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {stats.items.map((item, i) => (
              <tr
                key={i}
                className={i % 2 === 0 ? " even" : ""}
              >
                <td className="title">{item.text}</td>
                {stats.legend.map((e, j) => (
                  <td className={(item.data[e.id] === min[j] ? "min" : (item.data[e.id] === max[j] ? "max" : ""))}
                    key={i + '' + j}>
                    {formatNumber(item.data[e.id])}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td className="title">Итого</td>
              {stats.legend.map((e, i) => (
                <td key={i}>
                  {formatNumber(stats.items.reduce((total, item) => {
                    let val = item.data[e.id];
                    return total + val;
                  }, 0))}
                </td>
              ))}
            </tr>
          </tfoot>
        </table>
      </div>
    </TableWrapper>
  );
}

function formatNumber(n) {
  if (!Number.isInteger(n)) {
    return n;
  }
  const numAsString = "" + n;
  return numAsString
    .split("")
    .reverse()
    .join("")
    .match(/\d{1,3}/g)
    .join(" ")
    .split("")
    .reverse()
    .join("");
}
