export function formatDate(date) {
  var dd = date.getDate();
  var mm = date.getMonth() + 1; //January is 0!

  var yyyy = date.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  return dd + '.' + mm + '.' + yyyy;
}

export function formatTime(date) {
  var dd = date.getDate();
  var mm = date.getMonth() + 1;
  var yyyy = date.getFullYear();
  var hh = date.getHours();
  var min = date.getMinutes();
  var ss = date.getSeconds();

  if (ss < 10) {
    ss = '0' + ss;
  }
  if (min < 10) {
    min = '0' + min;
  }
  if (hh < 10) {
    hh = '0' + hh;
  }
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  return dd + '-' + mm + '-' + yyyy + ' ' + hh + ':' + min + ':' + ss;
}

export function findPoint(geom, num) {
	let polygons = geom.getType() === 'Polygon' ? [geom] : geom.getPolygons();
	let pointNum = 1;
	for (let j = 0; j < polygons.length; j++) {
	  let polygon = polygons[j];
	  let rings = polygon.getLinearRings();
	  for (let k = 0; k < rings.length; k++) {
      let ring = rings[k];
      let coords = ring.getCoordinates();
      for (let i = 0; i < coords.length - 1; i++) {
        if (pointNum++ === num) {
          return coords[i];
        }
      }
    }
  }
}

export const formatPhone = (num) => {
    if (num) {
      return num.substring(0, 1) + (num.length > 1 ? '(' : '')
        + num.substring(1, 4) + (num.length > 4 ? ')' : '')
        + num.substring(4, 7) + (num.length > 7 ? '-' : '') + num.substring(7, 11);
    }
    return '';
  }

export const matchScale = (geometry, scales, width, height, margin) => {
  let dpi = 90; // Стандартное разрешение WMS для geoserver
  let inch = 0.0254; // 1 дюйм в метрах
  // Определяем коородинаты описанного прямоугольника
  let box = geometry.getExtent();
  let boxWidht = box[2] - box[0];
  let boxHeight = box[3] - box[1];
  let scale = Math.max(boxWidht / (width - 2*margin), boxHeight / (height - 2*margin)) * dpi / inch;
  for (let i = 0; i < scales.length; i++) {
    if (scales[i] > scale) {
      return scales[i];
    }
  }
  return 0;
}