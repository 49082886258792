import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios';
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Scrollbars } from "react-custom-scrollbars";
import { Icon } from './../UserProfile';
import svgAdd from '../../../assets/img/add.svg';
import svgTrash from '../../../assets/img/trash.svg';
import ConfirmDialog from '../../ConfirmDialog';
import { signText, signXml } from '../../../NCALayerOps';
import ClipLoader from "react-spinners/ClipLoader";
import { NotificationContext } from '../../../EknMap';

export const Modal = styled.div`
    position: absolute;
    left: ${props => props.responsive.mobile ? "0" : "50%"};
    top: ${props => props.responsive.mobile ? "0" : "40%"};
    transform: translate(-50%, -50%);
    max-height: ${props => props.responsive.mobile ? "100%" : "900px"};
    height: ${props => props.responsive.mobile ? "100%" : "550px"};
    width: ${props => props.responsive.mobile ? "100%" : "1200px"};
    background-color: #fff;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    z-index: 1;
    font-family: 'sf-pro-display-regular',sans-serif;
`;

export const ModalHeader = styled.div`
    .header {
        display: flex;
        height: 50px;
        margin-bottom: 15px;
        border-radius: 5px 5px 0px 0px;
        background: linear-gradient(180deg, #01C13D 0%, #00A824 100%);
        align-items: center;
        padding: 0px 80px 0px 20px;
        
        .title {
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 15px;
            color: #FFFFFF;
        }
    }
`;

export const ModalFooter = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin-top: 5px;

    button {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 10px 10px 0px;
        width: 93%;
        border-radius: 5px;
        color: #fff;
        // height: 35px;
        height: 30px;
        border: none;
        outline: none;
        &:hover {
            cursor: pointer
        }
    }

    .success-btn {
        background: #00A824;
        // width: 200px;
        width: 90px;
    }

    .cancel-btn {
        background: #777777;
        width: 90px;
    }
`;

export const ModalBody = styled.div`
    padding: 10px;

    button {
        margin: 0 10px 10px 0px;
        width: 93%;
        border-radius: 4px;
        color: #fff;
        height: 30px;
        border: none;
        outline: none;
        background-color: #de2027;
        &:hover {
            cursor: pointer
        }
    }

	.data-div {
		padding: 5px;
	}

    .data-table {
        width: ${props => props.responsive.mobile ? "100%" : "1180px"};
        height: auto;
        margin-bottom: 15px;

        table {
            width: 100%;
			table-layout:fixed;

            tbody {
                tr {
                    width: 100%;
                    height: auto;
                    display: flex;
                    margin: 0;
                    padding: 0;
                    background: #fff;
    
                    td {
                        padding: 3px 8px;

                        .order-row {
                            display: flex;
                            flex-wrap: wrap;
                            width: 100%;
                        }

                        .add-doc-btn {
                            display: flex;
                            padding: 6px 10px;
                            background: #009856;
                            height: 30px;
                            width: 11%;
                        }

                        .doc-info {
                            border: 1px solid #B6D4C0;
                            border-radius: 5px;
                            margin: 0;
                            width: 88%;
                            height: 30px;
                            padding: 5px 8px;
                            text-align: left;
                        }

						input {
							width: 100%;
                            border-radius: 6px;
                            padding-right: 15px;
                            background: #f3f3f7;
                            border-style: Solid;
                            border-color: #c9d1de;
                            border-width: 1px;
                            -moz-border-radius: 6px;
                            -webkit-border-radius: 6px;
                            font-family: "Open Sans", sans-serif;
                            font-size: 15px;
						}

                        textarea {
                            height: 90px;
                            border-radius: 6px;
                            padding-right: 15px;
                            background: #f3f3f7;
                            border-style: Solid;
                            border-color: #c9d1de;
                            border-width: 1px;
                            -moz-border-radius: 6px;
                            -webkit-border-radius: 6px;
                            font-family: "Open Sans", sans-serif;
                            font-size: 15px;
						}
                    }
                }
            }
        }
    }

	.data-field {
		label { display:block; }
		textarea { display:block; }
	}

    .add-owner-btn {
        display: flex;
        padding: 6px 10px;
        background: #2F80ED;
        height: 30px;
        width: 195px;
    }

	.register-table {
        min-height: 100px;
        max-height: ${props => props.responsive.mobile ? "initial" : "352px"};
        height: auto;

        .region-btn-img {
            width: 30px;
            height: 30px;
            cursor: pointer;
            background-position: center !important;
            margin-left: 5px;
        }

        .sub-reg-btn {
            position: relative;
            div {
              position: absolute;
              font-size: 12px;
              padding: 7px 30px;
              width: max-content;
              color: #779480;
              font-family: Roboto;
            }
        }
      
		table {
            border: 1px solid #B6D4C0;
            border-spacing: 0;
            width: 100%;
			table-layout:fixed;

            thead {
                tr {
                    width: 100%;
                    height: auto;
                    display: flex;
                    margin: 0;
                    padding: 0;
                    background: #fff;
    
                    td {
                        padding: 3px 8px;
                        border: 1px solid #B6D4C0;
                        text-overflow: ellipsis;
                        background: #E0F0E5;
                        color: #008A78;
						word-wrap: break-word;
                    }
                }
            }

            tbody {
                tr {
                    width: 100%;
                    height: auto;
                    display: flex;
                    margin: 0;
                    padding: 0;
                    background: #fff;
    
                    td {
                        padding: 3px 8px;
                        border: 1px solid #B6D4C0;
                        height: 42px;
                        overflow: hidden;
                        position: relative;

						input {
							width: 100%;
							height: 90%;
							border: none;
						}
                    }
                       
                    td:after {
                        content: "";
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        height: 10px;
                        background: linear-gradient(180deg, transparent, #fff 50%);
                    }
                }
            }

            .even {
                background: #F8FFFA;
            }
        }
    }
`;

export const OnTop = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    backdrop-filter: blur(3px);
    background-color: rgb(0 0 0 / 49%);
    z-index: 1000;
`;

export default function ActUstForm({
    order,
    setVisible,
    setActUstForm,
    setActRecoverySign,
    setActTransferSign,
    responsive,
    act,
    setAct,
    actRecoverySign,
    actTransferSign
}) {
    const { t } = useTranslation();
    const hiddenOtherInput = React.createRef();
    const [formErrors, setFormErrors] = useState({
        landmark_count: '',
        landmark_desc_ru: '',
        landmark_desc_kz: '',
        landmark_proc_ru: '',
        landmark_proc_kz: '',
        landmark_date: '',
        scheme_scale: '',
        schemeFile: '',
        adjacent: '',
        area: ''
    });
    const [tableErrors, setTableErrors] = useState({
        beginPoint: '',
        endPoint: '',
        cadNum: '',
        ownerFIO: '',
        thirdFaceFIO: ''
    });
    const [isLoading, setLoading] = useState(false);
    const [isShowConfirm, setShowConfirm] = useState(false);
	const [schemeFile, setSchemeFile] = useState(null);
    const [saveLoading, setSaveLoading] = useState(false);
    const scalePattern = /^1:[\d]+$/;   
    const [data, setData] = useState(act.data || {})
    const { setNotification } = useContext(NotificationContext);
 
    const handleOtherSelect = event => {
        const fileName = event.target.files[0].name;
		setSchemeFile(event.target.files[0])
		setData(Object.assign({}, data, { scheme_file_name: fileName }))
        setLoading(!isLoading)

        let fieldValidationErrors = formErrors;
        fieldValidationErrors['schemeFile'] = '';
        setFormErrors(fieldValidationErrors)
    }

    const handleOtherClick = () => {
        hiddenOtherInput.current.click();
    }

	const handleAddOwner = () => {
		data.adjacent.push({
			num: data.adjacent.length + 1
		});
		setData(Object.assign({}, data));

        let fieldValidationErrors = formErrors;
        fieldValidationErrors['adjacent'] = '';
        setFormErrors(fieldValidationErrors)
	};

	const handleDataChanged = (propName, type, event) => {
		let value = event.target.value;
		if (type === 2) {
			value = value.replace(/\D/g, '');
		} else if (type === 3) {
            const selected = new Date(event.target.value);
            if (selected > new Date()) {
                setNotification({
                    title:  t("notif.attention"),
                    text: t("notif.date")
                })
                return;
            }
        }

		data[propName] = value;
		setData(Object.assign({}, data));

        //вызываем валидацию поля
        validateField(propName, value)
	}

	const handleOwnerChanged = (i, propName, event) => {
		data.adjacent[i][propName] = event.target.value;
		setData(Object.assign({}, data));
	}

    //валидация полей
    const validateField = (fieldName, value) => {
        let fieldValidationErrors = formErrors;
        if(value == '') {
            fieldValidationErrors[fieldName] = t('auf.msg-empty');
        } else {
            if (fieldName==='scheme_scale') {
                if (!scalePattern.test(value)) {
                    fieldValidationErrors[fieldName] = t('auf.scale-format');
                } else {
                    fieldValidationErrors[fieldName] = '';
                }
            } else {
                fieldValidationErrors[fieldName] = '';
            }
        }

        setFormErrors(fieldValidationErrors)
    }

    const validateTable = () => {
        let isResult = true;
        let adjacents = data.adjacent;

        //проходимся по каждой строке таблицы и проверяем на заполнение колонку
        for (var i = 0; i < adjacents.length; i++) {
            Object.keys(tableErrors).forEach((columnName) => {
                let value = adjacents[i][columnName]
                if(value == '' || value === undefined) {
                    isResult = false
                }
            });
        }

        return isResult
    }

    //валидация формы в целом
    // return tru/false
    const validateForm = () => {
        let isResult = true;
        let fieldValidationErrors = {};
        Object.keys(formErrors).forEach((fieldName) => {
            let value = data[fieldName]
            let errorMsg = t('auf.msg-empty');
            let errorMsgFormat = '';
            if(fieldName == 'schemeFile') {
                // value = schemeFile;
                value = data.scheme_file_name;
                errorMsg = t('auf.msg-empty-file'); 
            } else if(fieldName == 'adjacent') {
                value = data.adjacent;
                errorMsg = t('auf.msg-empty-table');
            } else if (fieldName==='scheme_scale') {
                errorMsgFormat = t('auf.scale-format');
            }
            
            if(value == '' || value === undefined) {
                fieldValidationErrors[fieldName] = errorMsg;
                isResult = false
            } else {
                if(fieldName == 'adjacent') {
                    //дополнительная валидация заполнения полей внутри таблицы
                    if(validateTable()) {
                        fieldValidationErrors[fieldName] = '';
                    } else {
                        fieldValidationErrors[fieldName] = errorMsg;
                        isResult = false
                    }
                } else if (fieldName==='scheme_scale') {
                    if (scalePattern.test(value)) {
                        fieldValidationErrors[fieldName] = '';
                    } else {
                        fieldValidationErrors[fieldName] = errorMsgFormat;
                        isResult = false;
                    }
                }
                else {
                    fieldValidationErrors[fieldName] = '';
                }
            }
        });

        setFormErrors(fieldValidationErrors)
        return isResult;
    }

	//производит проверку на заполненность обязательных полей и открывает диалог с запросом подтверждения
    const onSave = () => {
        if(validateForm()) {
            setShowConfirm(true)
        }
    }

    //запускается после подтвержения из диалога и отправляет данные на сервер
    const sendDataRequest = () => {
        setSaveLoading(true);
		const formData = new FormData();
        
        formData.append('data', JSON.stringify(data));
        if (schemeFile) {
            formData.append('scheme', new Blob([schemeFile]), schemeFile.name);
            act.schemeFile=schemeFile;
        } else {
            act.schemeFile=null;
        }
        
        order.area=data.area;
        act.data=data;
        setAct(Object.assign({}, act));
        
        
        axios({
            url: `${window.REST_URL}/cabinet/zkr/actMezh`,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            method: "post",
            data: formData,
            responseType: 'blob'
        })
        .then((res) => {
            actTransferSign.data=res.data;
            actTransferSign.fileName='Акт на сдачу межевых знаков.pdf';
            actTransferSign.sign=false;
            setActTransferSign(Object.assign({}, actTransferSign));
            
            axios({
                url: `${window.REST_URL}/cabinet/zkr/actUst`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                method: "post",
                data: formData,
                responseType: 'blob'
            })
            .then((res) => {                
                actRecoverySign.data=res.data;
                actRecoverySign.fileName='Акт установления (восстановления) границ ЗУ.pdf';
                actRecoverySign.sign=false;
                setActRecoverySign(Object.assign({}, actRecoverySign));
                setVisible(false)
                setActUstForm(true)
                /*
                let file = window.URL.createObjectURL(res.data);
                    window.location.assign(file);
                    */
            })
            .catch((err) => {
                console.log(err);
            });

            /*                
            let file = window.URL.createObjectURL(res.data);
                window.location.assign(file);
                */
        })
        .catch((err) => {
            console.log(err);
        });
	}

	const getField = (propName, type, label) => {
        console.log(data)
		return (
			<div className='data-field'>
				<label>{label}</label>
                {formErrors[propName] != '' && <div style={{color: 'red'}}>{formErrors[propName]}</div>}
				{type === 0	? <input type="text" readOnly value={data ? data[propName] ? data[propName] : "" : ""}/>
					: type === 5 ? <textarea style={{width: '100%'}} value={data ? data[propName] ? data[propName] : "" : ""} onChange={handleDataChanged.bind(this, propName, type)}/>
                    : type === 3 ? <input type="date" value={data ? data[propName] ? data[propName] : "" : ""} onChange={handleDataChanged.bind(this, propName, type)}/>
                    : type === 6 ? <input type="number" value={data ? data[propName] ? data[propName] : "" : ""} 
                    onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                    onChange={handleDataChanged.bind(this, propName, type)}/>
                    : type === 1 ? <input placeholder='1:XXX' type="text" value={data ? data[propName] ? data[propName] : "" : ""} onChange={handleDataChanged.bind(this, propName, type)}/>
					: <input type="text" value={data ? data[propName] ? data[propName] : "" : ""} onChange={handleDataChanged.bind(this, propName, type)}/>
				}
			</div>
		)
	}

    const onDelete = (row) => {
        data.adjacent = data.adjacent.filter(item => item.num != row);
        if (data.adjacent.length>0) {
            data.adjacent.map((item,i) => {
                item.num=i+1;
            })
        }
        setData(Object.assign({}, data));
    }

    //отрисовывает таблицу с документами-основаниями
    const getOwnerTable = () => {
        return (
            <table>
                <thead>
                    <tr>
                        <td style={{ width: "4%", textAlign: "center" }}>№</td>
                        <td style={{ width: "7%", textAlign: "center" }}>{t("auf.from")}*</td>
                        <td style={{ width: "7%", textAlign: "center" }}>{t("auf.before")}*</td>
                        <td style={{ width: "30%", textAlign: "center" }}>{t("auf.cadastr")}*</td>
                        <td style={{ width: "21%", textAlign: "center" }}>{t("auf.name-or-fio")}*</td>
                        <td style={{ width: "21%", textAlign: "center" }}>{t("auf.fio-member")}*</td>
                        <td style={{ width: "10%", textAlign: "center" }}>{t("auf.actions")}</td>
                    </tr>
                </thead>
                <tbody>
                { data && data.adjacent && data.adjacent.map((item, i) => (
                    <tr key={item.num}>
                        <td style={{ width: "4%", textAlign: "center", paddingTop: "8px" }}>
                            {/* <input type="text" value={item.num} disabled={true}></input> */}
                            {item.num}
                        </td>
                        <td style={{ width: "7%" }}>
							<input type="text" value={item.beginPoint || ''} onChange={handleOwnerChanged.bind(this, i, "beginPoint")}></input>
						</td>
                        <td style={{ width: "7%" }}>
							<input type="text" value={item.endPoint || ''} onChange={handleOwnerChanged.bind(this, i, "endPoint")}></input>
						</td>
                        <td style={{ width: "30%" }}>
							<input type="text" value={item.cadNum || ''} onChange={handleOwnerChanged.bind(this, i, "cadNum")}></input>
						</td>
                        <td style={{ width: "21%" }}>
							<input type="text" value={item.ownerFIO || ''} onChange={handleOwnerChanged.bind(this, i, "ownerFIO")}></input>
						</td>
                        <td style={{ width: "21%" }}>
							<input type="text" value={item.thirdFaceFIO || ''} onChange={handleOwnerChanged.bind(this, i, "thirdFaceFIO")}></input>
						</td>
                        <td style={{ width: "10%" }}>
                            <div className="region-btn-img sub-reg-btn" style={{ background: `url(${svgTrash}) no-repeat`, backgroundSize: "18px" }}
                                onClick={(e) => {
                                    // data.adjacent.splice(i - 1, 1);
                                    // setLoading(!isLoading)
                                    onDelete(item.num);
                                }}
                            >
                                <div>{t("auf.delete")}</div>
                            </div>
                        </td>
                    </tr>
                )) }
                </tbody>
            </table>
        )
    };

    return (
        <OnTop>
            <Modal responsive={responsive}>
            <ModalHeader>
            <div className="header">
                <div className='title'>{t("auf.title")}</div>
            </div>
            </ModalHeader>
            <Scrollbars
                autoHeight={false}
                autoHide={true}
                autoHeightMax={"45vh"}
                renderView={props => <div {...props} className="scrollbar-view" />}
            >
            <ModalBody responsive={responsive}>
				<div className='data-div'>
                    <div className="data-table">
                        <table>
                            <tbody>
                                <tr>
                                    <td style={{ width: "50%" }}>{getField('appId', 0, t("auf.register-number"))}</td>
                                    <td style={{ width: "50%" }}>{getField('landmark_count', 2, t("auf.count"))}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: "50%" }}>{getField('landmark_desc_ru', 5, t("auf.description-landmarks-ru"))}</td>
                                    <td style={{ width: "50%" }}>{getField('landmark_desc_kz', 5, t("auf.description-landmarks-kz"))}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: "50%" }}>{getField('landmark_proc_ru', 5, t("auf.description-process-ru"))}</td>
                                    <td style={{ width: "50%" }}>{getField('landmark_proc_kz', 5, t("auf.description-process-kz"))}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: "33.3%" }}>{getField('landmark_date', 3, t("auf.date-establishment"))}</td>
                                    <td style={{ width: "33.3%" }}>{getField('scheme_scale', 1, t("auf.scale"))}</td>
                                    <td style={{ width: "33.3%" }}>{getField('area', 6, t("egov-service.area")+', ГА*')}</td>
                                </tr>
                                <tr>
                                    <td colSpan={2} style={{ width: "100%"}}>
                                        <label>{t("auf.name-file")}</label>
                                        {formErrors['schemeFile'] != '' && <div style={{color: 'red'}}>{formErrors['schemeFile']}</div>}
                                        <div className="order-row">
                                            <button
                                                className="add-doc-btn"
                                                href=""
                                                onClick={() => {
                                                    handleOtherClick();
                                                }}
                                            >
                                                <Icon url={svgAdd} className="icon" />
                                                {t("auf.attach")}
                                            </button>
                                            <input
                                                type="file"
                                                accept="image/jpeg,image/png"
                                                ref={hiddenOtherInput}
                                                onChange={event => {
                                                    event.target.files.length !== 0 && handleOtherSelect(event)
                                                }}
                                                style={{ display: 'none' }}
                                            />
                                            {/* { schemeFile  ?
                                                <div className="doc-info">
                                                    <span>{schemeFile.name}</span>
                                                </div>
                                            : data.scheme_file_name ?
                                                <div className="doc-info">
                                                    <span>{data.scheme_file_name}</span>
                                                </div>
                                            : ""
                                            } */}
                                            { 
                                                <div className="doc-info">
                                                    <span>
                                                        {schemeFile ? schemeFile.name : data && data.scheme_file_name ? data.scheme_file_name : ""}
                                                    </span>
                                                </div>

                                            }
                                            {/* {schemeFile &&
                                                <div className="doc-info">
                                                    <span>{schemeFile.name}</span>
                                                </div>
                                            } */}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
				</div>
                <div className='data-div' style={{ padding: "0px 15px"}}>
                    <button
                        className="add-owner-btn"
                        href=""
                        onClick={() => {
                            handleAddOwner();
                        }}
                    >
                        <Icon url={svgAdd} className="icon" />
                        {t("auf.add-person")}
                    </button>
                    <label>{t("auf.description-person")} (***в поле без значения проставьте прочерк "-")</label>
                    {formErrors['adjacent'] != '' && <div style={{color: 'red'}}>{formErrors['adjacent']}</div>}
                    <div className="register-table">
                        {getOwnerTable()}
                    </div>
                </div>

            </ModalBody>
            </Scrollbars>
            <ModalFooter responsive={responsive}>
                <button
                    className="success-btn"
                    href=""
                    onClick={() => {
                        {onSave()}
                    }}
                >
                    {saveLoading ?
                        <ClipLoader size="25px" color={"#fff"} loading={true} className="sign-loader" /> :
                        t("auf.save")
                    }
                </button>
                

                <button
                    className="cancel-btn"
                    href=""
                    onClick={() => {
                        setVisible(false);
                    }}
                >
                    {t("auf.cancel")}
                </button>
            </ModalFooter>

            {isShowConfirm && (
                <ConfirmDialog
                    title={t("auf.save")}
                    text={t("auf.confirm-save")}
                    handleOnConfirm={() => sendDataRequest()}
                    handleOnCancel={() => setShowConfirm(false)}
                />
            )}
            </Modal>
        </OnTop>
    );
}