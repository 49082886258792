import styled from "styled-components";

const StatsStyled = styled.div`
  border-radius: 13px 13px 0 0;
  
  box-shadow: 0px 0px 9px rgba(102, 102, 102, 0.55);
  filter: progid:DXImageTransform.Microsoft.dropshadow(OffX=0, OffY=0, Color='#666666');

  box-sizing: border-box;
  
  background-color: ${props => props.palette.secondary};

  position: relative;
  h4 {
   
    padding: 5px;
    background-color: ${props => props.palette.primary};
    &:hover {
      cursor: default;
    }
  }
  * {
    margin: 0;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
  }
  .tooltip-pie:hover {
    cursor: pointer;
  }

  div.Collapsible__contentOuter {
    background-color: #fff;
  }
  div.Collapsible__contentInner {
    padding: 4px;
  }

  .Collapsible {
    .Collapsible__trigger {
      div {
        width: 290px;
        height: 40px;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        font-weight: normal;
        line-height: 40px;
      }
    }
    .stats-title {
      margin-left: 50px;
    }
    img {
      position: absolute;
      left: 15px;
      top: 10px;
      width: 18px;
      height: 18px;
    }
  }
  .stats-group {
    background: #d3e8da;
  }
`;

const Button = styled.button`
  display: block;
  width: 100%;
  outline: none;
  text-align: left;
  padding: 4px;
  border: none;
  cursor: pointer;
position: relative;
  background: ${props =>
    props.isActive ? props.palette.turq : props.palette.secondary};
  background: ${props => (props.isArrestButton ? "#660000" : null)};
  background: ${props =>
    props.isArrestButton && props.isActive ? "#990000" : null};
  color: ${props => props.palette.text};
  &:hover {
    background: ${props => props.palette.turq};
    background: ${props => (props.isArrestButton ? "#990000" : null)};
  }
  .react-tooltip {
    text-transform: none;
  }
`;

const Tooltip = styled.div`
  h3 {
    font-size: 12px;
    padding: 4px;
    padding-left: 12px;
  }
  &:hover {
    cursor: default;
  }
  box-sizing: border-box;
  .row {
    display: grid;
    grid-template-columns: 20px auto;
    align-items: center;
    padding: 4px;
    border-radius: 2px;
  }
  .row:hover {
    background-color: ${props =>
    props.level ? props.palette.secondary : "inherit"};
    cursor: ${props => (props.level ? "pointer" : "default")};
  }
  * {
    margin: 0;
    box-sizing: border-box;
    color: ${props => props.palette.text};
  }
`;

const LegendStats = styled.div`

  box-sizing: border-box;
  position: absolute;
  top: 98%;
  width: 100%;
  z-index: 2;


  .stats-wrapper {
    background: #fff;
    width: 100%;
    height:100%;
    height: 140px;

    max-width: 960px;
    
 display: flex;

  border-radius: 0 0 13px 13px;

  }
 
  .stats-legend-title {
    width: 25%;
    max-width: 250px;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  color: green;
  margin-left: 140px
  height:100%;
  max-height: 160px;
  margin-top: 18px;
  }
  
  .row-stats-wrapper {
    position: absolute;
    right: 0;
    width: 77%;
    max-width: 750px;
    display: grid;
    grid-template-columns: auto auto auto auto;
    flex-wrap: wrap;
    grid-gap: 10px;
    background-color :#fff;
    padding-right: 10px;
    margin-top: 20px;
    border-radius: 0 0 13px 0;
    }

  
     
    .row-stats-legend {
      font-family: 'Open Sans', sans-serif;
      display: flex;  
      font-size : 12px;
      color : #4D4D4D;
      border-radius: 0 0 13px 13px;
    }
  
  .row-stats-legend div {
    margin-right: 4px;
    border-radius: 3px;
  }

  h2 {
    font-size: 14px;
    padding: 4px;
    background-color: ${props => props.palette.additional};
  }

  * {
    margin: 0;
    box-sizing: border-box;
    padding: 0;
  }
`;

const Icon = styled.div`
  width: 16px;
  height: 16px;
  background-color: ${props => props.color};
`;

export { StatsStyled, Button, LegendStats, Icon, Tooltip };
