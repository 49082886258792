/* eslint-disable no-lone-blocks */
import React, { useState } from "react";
import styled from "styled-components";
import PageNav from "./components/PageNav";
import { Scrollbars } from "react-custom-scrollbars";
import TableObject from "./TableObject"

const FeaturesWrapper = styled.div`
  .container {
    border: 1px solid red;
    overflow-y: hidden;

    border-radius: 0 0 0px 0px;
   background: transparent;
  }
  .handler {
    outline: none;  
    border: none;
    background: inherit;
    color: ${props => props.palette.text};
    text-decoration: underline;
    &:hover {
      
      cursor: pointer;
    }
  }
  .Collapsible__contentInner {
    nth:child(odd) {background-color: ${props => props.palette.blue1}}
    margin-right: 10px;
    margin-left: 4px;
  }
 
`;

function ObjectsInfoTable(props) {

  const [activeObj, setActiveObj] = useState();

  return (
    //{v, [..]}, - by Keys - Gp, props.info[gp], - 0{...}, 1{...} 
    <FeaturesWrapper palette={props.theme}>

      <Scrollbars
        autoHeight={true}
        autoHide={true}
        // autoHeightMax={"65vh"}
        autoHeightMax={"57vh"}
        renderView={props => <div {...props} className="scrollbar-view" />}
      >
        {Object.keys(props.info).map(layerName =>
          Object.values(props.info[layerName]).map((obj) =>
            <TableObject
              key={layerName + obj.id}
              layerName={layerName}
              obj={obj}
              i={layerName + obj.id}
              standalone={props.standalone}
              mode={props.mode}
              region={props.region}
              theme={props.theme}
              handleSend={props.handleSend}
              setActiveObj={setActiveObj}
              isActive={obj === activeObj}
              handleAuctionSelect={props.handleAuctionSelect}
              arrestIsChecked={props.arrestIsChecked}
              handleCreateBuffer={props.handleCreateBuffer}
              handleDeleteBuffer={props.handleDeleteBuffer}
              handleSelect={props.handleSelect}
              getExtData={props.getExtData}
              setMode={props.setMode}
              order={props.order}
              setOrder={props.setOrder}
              handleSearchByCadNum={props.handleSearchByCadNum}
              searchText={props.searchText}
              searchResult={props.info}
            />
          )
        )}
      </Scrollbars>
      {props.getObjects.func && (
        <PageNav getObjects={props.getObjects} count={props.info.count} theme={props.theme} />
      )}

    </FeaturesWrapper>
  );
}

export default ObjectsInfoTable;