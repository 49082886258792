import React from 'react'
import UserProfile from './UserProfile';
import styled from "styled-components";

const ProfileBtn = styled.button`
  height: 30px;
  width: 212px;
  background: #fff;
  color: #9b9b9b;
  padding-left: 14px;
  padding-bottom: 0px;
  text-align: left;
  font-size: 9pt;
  border: 1px solid #C8D0DE;
  border-radius: 15px;

  :focus {
    outline: none;
  }
`;

export default function UserInfo({
  userInfo, handleLogout, handleUpdateQueue, handleClick,
  showProfile, setShowMyObjects, setAte, setQueueNum,
  handleOpenQueue, setSearchQueueFromReg,setEgovService,
  setShowRegistrationLecensee, setShowWorkOrders, setShowPlanArchive, conf, flag
}) {
  return <>
    <ProfileBtn
      onClick={handleClick}
      disabled={flag}
    >
      {userInfo.fullName}
    </ProfileBtn>
    {showProfile && <UserProfile
      userInfo={userInfo}
      handleLogout={handleLogout}
      handleUpdateQueue={handleUpdateQueue}
      setShowMyObjects={setShowMyObjects}
      setQueueNum={setQueueNum}
      setAte={setAte}
      handleOpenQueue={handleOpenQueue}
      handleClickProfile={handleClick}
      setSearchQueueFromReg={setSearchQueueFromReg}
      setEgovService={setEgovService}
      setShowRegistrationLecensee={setShowRegistrationLecensee}
      setShowWorkOrders={setShowWorkOrders}
      setShowPlanArchive={setShowPlanArchive}
	    conf={conf}
    />}
  </>;
}