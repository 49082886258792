import React, { useEffect } from 'react'
import styled from "styled-components";
import Select from 'react-select'
import { Icon } from './UserProfile';
import svgSearch from '../../assets/img/gray-search.svg';
import { FINDED_AND_CREATED } from '../../analytic/Constants';
import { useTranslation } from "react-i18next";

const CreateObjForm = styled.div`
    font-family: 'sf-pro-display-regular',sans-serif;

    .ontop {
        z-index: 999;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: ${props => props.display};
        position: absolute;				
        color: #aaaaaa;
        backdrop-filter: blur(3px);
        background-color: rgb(0 0 0 / 49%);
    }

    .popup {
        width: ${props => props.responsive.mobile ? "100%" : "840px"};
        position: absolute;
        color: #000000;
        background-color: #ffffff;
        top: ${props => props.responsive.mobile ? "0" : "50%"};
        left: ${props => props.responsive.mobile ? "0" : "50%"};
        transform:  ${props => props.responsive.mobile ? "0" : "translate(-50%, -50%)"};
        height: ${props => props.responsive.mobile ? "100%" : "auto"};
        padding: 12px;
        border-radius: 7px;
        box-shadow: 9px 10px 21px #00000069;
    }

    .form-content {
        color: #000;
        border-radius: 4px;
    }

    h1 {
        color: #fff;
        font-size: 18px;
        background: #008B78;
        margin: 0 0 15px 0;
        padding: 0;
        border-radius: 5px;
        text-align: center;
        height: 40px;
        line-height: 40px;
        font-weight: normal;
    }

    .form-mem {
        margin: 0 0 12px 0;
        height: 34px;
        padding: 0 0 0 18px;
        border: none;
        border-radius: 5px;
        font-family: 'sf-pro-display-regular',sans-serif;
        outline: 0;
        -webkit-transition: .2s;
        -webkit-transition: .2s;
        transition: .2s;
        width: -webkit-fill-available;
        width: -moz-available;
        font-size: 16px;
        background: #D9F2E1;
        color: #466E5D;
    }
    .form-mem-br:focus {
        border: 1px solid #2684FF;
    }

    .special-mem {
        position: relative;
        div {
            position: absolute;
            top: 1px;
            right: 3px;
            width: 28px !important;
            height: 28px !important;
            cursor: pointer;
        }
    }

    .gray-color {
        color: hsl(0,0%,50%);
    }

    select:invalid {
        color: #a8a8a8;

        option {
            color: #000;
        }
    }

    button {
        margin: 0 14px 0 0;
        width: 153px;
        border-radius: 4px;
        color: #fff;
        height: 40px;
        border: none;
        outline: none;
        background-color: ${props => props.active ? '#009856' : '#999'};
        padding: 0 16px;
        font-size: 14px;
        font-weight: bold;
    }

    .decisions__submit:hover {
        cursor: ${props => props.active ? 'pointer' : 'default'};
    }

    .sel {
        padding: 0;
        // margin: 0 0 12px 0;
        border: 0 none;
    }

    .decisions__gray {
        background-color: #475A52 !important;
        cursor: pointer;
    }

    .load-wrap {
        margin: 0 0 0 28px;
  
        div {
          height: 30px;
          width: 30px;
        }
    }

    .search-input-wrap {
        background: #DCF0E2;
        border: 1px solid #B4CFBC;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 2px 3px 2px 12px;
        display: flex;
        height: 35px;

        input {
            width: 100%;
            background: none;
            border: none;
            font-size: 18px;
            line-height: 13px;
        }

        input:focus {
            outline: none;
        }
    }

    .search-btn {
        cursor: pointer;

        div {
            width: 27px !important; 
            height: 27px !important;
        }
    }
    .decisions {
        display: flex;
        justify-content: center;
    }
    
`;

export default function FormNewObj({
    display,
    order,
    setOrder,
    setDisplay,
    dicts,
    handleDicts,
    region,
    createdObject,
    setCreatedObject,
    setIsNewObject,
    setShowObjSearch,
    foundObject,
    setFoundObject,
    responsive,
    setCadNum,
    cadNum
}) {

    const { t } = useTranslation();
    const funcUserRef = React.createRef();
    const purposeUserRef = React.createRef();
    const landCatRef = React.createRef();
    const rightTypeRef = React.createRef();
    const areaRef = React.createRef();
    const locationRef  = React.createRef();
    const kadnumRef = React.createRef();
    const kadnumRefWrapper = React.createRef();

    const specialService = order.services.find(service => service.code === '601070');

    const [formErrors, setFormErrors] = React.useState({cadnum: ''});

    const validateForm = (name, value) => {
        let isResult = true;
        let errorMsg = t('auf.msg-empty');
    
        if(value == '' || value === undefined) {
          formErrors[name] = errorMsg;
          isResult = false
        } else {
          if (name === 'cadnum' && value.trim().length!==11 && value.trim().length!==12) {
            formErrors[name] = t('service.cadnum-length');
            isResult = false;
          } else {
            formErrors[name] = '';
          }
        }
    
        setFormErrors(Object.assign({}, formErrors));
        return isResult;
      }

    if (foundObject) {
        createdObject.cadnum = foundObject.cadnum;
        createdObject.ateCode = foundObject.ateCode;
        createdObject.idInv = foundObject.idInv;
        createdObject.addressCode = foundObject.addressCode;
        createdObject.addrRu = foundObject.addrRu;
        createdObject.addrKz = foundObject.addrKz;
        createdObject.idExplication = foundObject.idExplication;
        createdObject.objType = FINDED_AND_CREATED;
    }

    const customStyles = {
        control: base => ({
            ...base,
            background: "#D9F2E1",
            // match with the menu
            borderRadius: "5px",
            // Overwrittes the different states of border
            border: "none",
            // Removes weird border around container
            color: "red",
            paddingLeft: "5px",
            cursor: "pointer"
        })
    };

    useEffect(() => {
        handleDicts();
    }, []);

    function handleSubmit() {
        if (active()) {
            createdObject.ateCode = region.ate_code;
            createdObject.districtName = region.nameRu;
            order.objects.push(createdObject);
            setOrder(Object.assign({}, order))
            setDisplay(false);
            setCreatedObject({});
            setIsNewObject(false);
            setFoundObject(undefined);
            funcUserRef.current.state.value = undefined;
            purposeUserRef.current.state.value = undefined;
            landCatRef.current.state.value = undefined;
            rightTypeRef.current.state.value = undefined;
            locationRef.current.value = '';
            areaRef.current.value = '';
            kadnumRef.current.value = '';
        }
    }

    function active() {
        let res = createdObject.funcUseCode
            && createdObject.purposeUseCode
            && createdObject.landCategoryCode
            && createdObject.rightCode;

        if (specialService) {
            return res && createdObject.cadnum;
        } else {
            return res;
        }
        // return res;
    }

    return (
        <CreateObjForm
            display={display ? 'block' : 'none'}
            active={active()}
            responsive={responsive}
        >
            <div className="ontop">
                <div className="popup">
                    <div className="form-content">
                        <h1>{t('new-obj.create')}</h1>
                        {specialService ? (
                            <>
                            {formErrors['cadnum'] && formErrors['cadnum'].length>0 ? <div style={{color: 'red'}}>{formErrors['cadnum']}</div> : null}
                            
                           <div ref={kadnumRefWrapper} className={"special-mem" + (foundObject ? "" : " gray-color")}>
                                <input 
                                    type="number" 
                                    ref={kadnumRef}
                                    id="kadnum" 
                                    className={"form-mem form-mem-br" + (foundObject ? "" : " gray-color")}
                                    placeholder={t('new-obj.cadnum-share')}
                                    onChange={e => {
                                        setCadNum(e.target.value);
                                        if (e.target.value.length==0) {
                                            formErrors['cadnum'] = '';
                                            setFormErrors(Object.assign({}, formErrors));
                                        }
                                    }}
                                    onClick={() => kadnumRefWrapper.current.focus()}
                                />
                                <Icon onClick={() => {
                                    if (validateForm('cadnum', cadNum)) {
                                        setShowObjSearch(true);
                                        }
                                }} url={svgSearch} 
                                />
                           </div>

                            <div style={{padding: "7px 8px 0px 16px"}} className={"form-mem" + (foundObject ? "" : " gray-color")}>{foundObject ? foundObject.addrRu : "Адрес"}</div>
                            </>
                        ) : (
                            <>
                            <input
                                ref={kadnumRef}
                                id="kadnum" type="text" className="form-mem form-mem-br" placeholder={t('new-obj.cadnum-available')}
                                onChange={e => {
                                    createdObject.cadnum = e.target.value;
                                    setCreatedObject(Object.assign({}, createdObject));
                                }}
                            />
                            </>
                        )             
                        }
                        <Select
                            styles={customStyles}
                            ref={funcUserRef}
                            className="form-mem sel"
                            options={dicts.funcUseCode}
                            placeholder={t('new-obj.func-purpose')}
                            isSearchable={true}
                            noOptionsMessage={() => ''}
                            onChange={e => {
                                createdObject.funcUseCode = e.value;
                                createdObject.funcUseNameRu = e.label;
                                setCreatedObject(Object.assign({}, createdObject));
                            }}
                        />
                        <Select
                            styles={customStyles}
                            ref={purposeUserRef}
                            className="form-mem sel"
                            options={dicts.purposeUseCode}
                            placeholder={t('new-obj.purpose')}
                            isSearchable={true}
                            noOptionsMessage={() => ''}
                            onChange={e => {
                                createdObject.purposeUseCode = e.value;
                                setCreatedObject(Object.assign({}, createdObject));
                            }}
                        />
                        <Select
                            styles={customStyles}
                            ref={landCatRef}
                            className="form-mem sel"
                            options={dicts.landCategoryCode}
                            placeholder={t('new-obj.category')}
                            isSearchable={true}
                            noOptionsMessage={() => ''}
                            onChange={e => {
                                createdObject.landCategoryCode = e.value;
                                setCreatedObject(Object.assign({}, createdObject));
                            }}
                        />
                        <Select
                            styles={customStyles}
                            ref={rightTypeRef}
                            className="form-mem sel"
                            options={dicts.rightCode}
                            placeholder={t('new-obj.pravo')}
                            isSearchable={true}
                            noOptionsMessage={() => ''}
                            onChange={e => {
                                createdObject.rightCode = e.value;
                                setCreatedObject(Object.assign({}, createdObject));
                            }}
                        />

                        <input
                            ref={locationRef}
                            id="location" type="text" className="form-mem form-mem-br" placeholder={t('new-obj.location')}
                            onChange={e => {
                                createdObject.addrRu = e.target.value;
                                setCreatedObject(Object.assign({}, createdObject));
                            }}
                        />
                        <input
                            ref={areaRef}
                            id="area" 
                            type="number" 
                            className="form-mem form-mem-br" 
                            placeholder={t('new-obj.area')}
                            onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                            onChange={e => {
                                createdObject.area = e.target.value;
                                setCreatedObject(Object.assign({}, createdObject));
                            }}
                        />
                        
                        <div className='decisions'>
                            <button className="decisions__gray" onClick={() => {
                                setCreatedObject({});
                                setDisplay(false);
                                setIsNewObject(false);
                                setFoundObject(undefined);
                            }}>{t('new-obj.cancel')}</button>
                            <button className='decisions__submit' onClick={handleSubmit}>{t('new-obj.add')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </CreateObjForm>
    );
}