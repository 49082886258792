import React from "react";
import styled from "styled-components";


const LayerIconComponent = styled.img`
  box-sizing: border-box;
  width: 14px;
  height: 14px;
  border-radius: 3px;
`;

export const LayerSvgComponent = styled.svg`
  box-sizing: border-box;
  width: 16px;
  height: 16px;
`;

const LayerIcon = ({ layerName }) => {
  if ([
    'arrest',
    'buildings',
    'free',
    'lands',
    ...generateOz(),
    'podacha',
    'podano',
    'postan',
    'razrabot',
    'redlines',
    'soglas',
    'ukPDP1',
    'ukPDP10',
    'ukPDP11',
    'ukPDP13',
    'ukPDP14',
    'ukPDP18',
    'ukPDP29',
    'ukPDP30',
    'ukPDP34',
    'ukPDP38',
    'ukPDP40',
    'ukPDP41',
    'ukPDP47',
    'semPDP39',
    'semPDP37',
    'semPDP47',
    'semPDP52',
    'semPDP58',
    'semPDP33',
    'semPDP35',
    'semPDP61',
    'semPDP63',
    'semPDP66',
    'semPDP54',
    'utver',
    'vydan',
    'pastb_ch',
    'pastb_zk',
    'pastb_pzp',
    'goroda',
    'ogor_n',
    'kol_sady',
    'pitomnik_lidp',
    'lesa_rvsk',
    'pashnya_ch',
    'pashnya_ch_oros',
    'pashnya_ch_ovnk',
    'pastb_poluk',
    'pastb_zpk',
    'gig_pov',
    'peski_r',
    'kladb_tip1',
    'pastb_zpo',
    'peski_zk',
    'lesopolosy_d210',
    'zemli_orv',
    'terr_ozf',
    'kladb_sod',
    'pastb_rls',
    'lesa_svz',
    'pastb_zch',
    'les_gv',
    'boloto_pkt',
    'otstoiniki',
    'izrytye',
    'pastb_ruk',
    'senokos_sch',
    'zalezh_ch',
    'uk'
  ].includes(layerName)) {
    return (
      <LayerIconComponent
        src={`legend-img/layerIcons/${layerName}.png`}
        alt=""
      />
    );
  } else {
    return <LayerIconComponent alt="" src='legend-img/layerIcons/fallback.png' />
  }
};

export default LayerIcon;

const generateOz = () => {
  return new Array(40).fill(0).map((val, index) => `oz${index + 1}`)
}