import React, { useEffect } from "react";
import svgClose from '../../assets/img/stat_close.svg';
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import flagSvg from "../../assets/img/flag.svg";
import listSvg from "../../assets/img/list.svg";
import arrowSvg from "../../assets/img/arrow.svg";
import { Scrollbars } from "react-custom-scrollbars";

export const RegionsMobileWrapper = styled.div`
  position: absolute;
  top: 62px;
  width: 100%;
  height: calc(100% - 70px);
  background: #fff;
  z-index: 4;

  .queue-cancel-mob {
    height: 40px;
    min-width: 50px;
    border-radius: 6px;
    border: 1px solid #B4CFBC;
  }

  .mob-cancel-img {
    width: 40px;
    height: 30px;
    background-color: #A0B6A6;
    -webkit-mask: url(${arrowSvg}) no-repeat;
    mask: url(${arrowSvg}) no-repeat;
    -webkit-transform: rotate(225deg);
    -moz-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    -o-transform: rotate(225deg);
    transform: rotate(225deg);
    margin: -13px 0px 0px 2px;
  }

  .mob-ate-chain {
    font-family: "sf-pro-display-regular";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #A0B6A6;
    margin-left: 12px;
    display: flex;
    align-items: center;
  }

  .queue-head-mob {
    background: #01B733;
    display: flex;
    justify-content: space-between;
    padding: 16px 18px;
    maxHeight: 52px;
  }

  .queue-title-mob {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #FFFFFF;
  }

  .region-btn-mob {
    border-radius: 6px;
    border: 1px solid #B4CFBC;
    color: #294A37;
    font-family: "sf-pro-display-regular";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    width: 100%;
    display: flex;
    padding-left: 16px;
    align-items: center;
  }

  .region-btn-img-mob {
    background: url(${flagSvg}) no-repeat;
    background-size: 18px;
    background-position: center;
    min-width: 40px;
    height: 40px;
    border-radius: 6px;
    border: 1px solid #B4CFBC;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
  }

  .region-list-btn-mob {
    background: url(${listSvg}) no-repeat;
    background-size: 18px;
    background-position: center;
  }
`;

export default function RegionsMobile({
  ate, setAte, getAtes, ates,
  setAtes, setNameAndInitials, setOpenQueueMob,
  ateChain, setAteChain, setOpenRegionsMob
}) {

  const { i18n } = useTranslation();
  const [parent, setParent] = React.useState();

  useEffect(() => {
    getAtes(i18n.language, ate ? ate.parent.kato : "000000000").then(result => {
      if (ate) {
        setParent(ate.parent.parent);
      }
      setAtes(result);
    });
  }, [i18n.language, getAtes]);

  const handleAteClick = (ate) => {
    if (!ate.isLeaf) {
      getAtes(i18n.language, ate.kato).then(result => {
        setParent(ate.parent.kato);
        setAtes(result);
        setAteChain((!ateChain ? ate.name : (ateChain + " - " + ate.name)));
      });
    } else {
      setAte(ate);
      setNameAndInitials('');
      setOpenRegionsMob(false);
      setOpenQueueMob(true);
    }
  };

  const handleAteSelect = (ate) => {
    setAte(ate);
    setNameAndInitials('');
    setOpenRegionsMob(false);
    setOpenQueueMob(true);
  };

  return <RegionsMobileWrapper>
    <div className="queue-head-mob">
      <div className="queue-title-mob">
        {i18n.t("queue.title1")}
      </div>
      <div onClick={() => {
        setAteChain('');
        setOpenRegionsMob(false);
      }}>
        <img src={svgClose} style={{ width: '16px' }} alt="" />
      </div>
    </div>
    <div style={{ display: "flex", padding: "10px 20px 0px 20px" }}>
      {(parent ?
        <div className="queue-cancel-mob"
          onClick={() => {
            getAtes(i18n.language, parent).then(result => {
              if (result[0].parent.kato === '000000000') {
                setParent(null);
                setAtes(result);
                setAteChain('');
              } else {
                setParent(result[0].parent.parent);
                setAtes(result);
                const ateChainArr = ateChain.split(" - ").slice(0, -1);
                setAteChain(ateChainArr.join(" - "));
              }
            });
          }}
        >
          <div className="mob-cancel-img" />
        </div>
        : null
      )}
      <div className="mob-ate-chain">
        {ateChain}
      </div>
    </div>
    <Scrollbars
      autoHide={true}
      autoHeight={true}
      autoHeightMax={"calc(100vh - " + (ateChain ? "180px" : "140px") + ")"}
      renderView={props => <div {...props} className="scrollbar-view" />}
    >
      <div style={{ margin: "10px 20px" }}>
        {ates.map((ate, i) => (
          <div key={i} style={{ marginBottom: "10px", display: "flex" }}>
            <div className="region-btn-mob">
              {ate.name}
            </div>
            {!ate.isLeaf &&
              <div className="region-btn-img-mob"
                onClick={() => {
                  handleAteClick(ate);
                }}
              />
            }
            <div className="region-btn-img-mob region-list-btn-mob"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleAteSelect(ate);
              }}
            />
          </div>
        ))}
      </div>
    </Scrollbars>
  </RegionsMobileWrapper>;
}