import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Scrollbars } from "react-custom-scrollbars";
import { Icon } from './UserProfile';
import svgAdd from '../../assets/img/add.svg';
import svgTrash from '../../assets/img/trash.svg';
import RegionDialog from "../RegionDialog";
import ConfirmDialog from '../ConfirmDialog';

export const Modal = styled.div`
    position: absolute;
    left: ${props => props.responsive.mobile ? "0" : "50%"};
    top: ${props => props.responsive.mobile ? "0" : "50%"};
    transform: ${props => props.responsive.mobile ? "0" : "translate(-50%, -50%)"};
    max-height: ${props => props.responsive.mobile ? "100%" : "500px"};
    height: ${props => props.responsive.mobile ? "100%" : "auto"};
    width: ${props => props.responsive.mobile ? "100%" : "800px"};
    background-color: #fff;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    z-index: 1;
    font-family: 'sf-pro-display-regular',sans-serif;
`;

export const ModalHeader = styled.div`
    .header {
        display: flex;
        height: 50px;
        margin-bottom: 15px;
        border-radius: 5px 5px 0px 0px;
        background: linear-gradient(180deg, #01C13D 0%, #00A824 100%);
        align-items: center;
        padding: 0px 80px 0px 20px;
        
        .title {
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 15px;
            color: #FFFFFF;
        }
    }
`;

export const ModalFooter = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin-top: 5px;

    button {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 10px 10px 0px;
        width: 93%;
        border-radius: 5px;
        color: #fff;
        height: 30px;
        border: none;
        outline: none;
        &:hover {
            cursor: pointer
        }
    }

    .success-btn {
        background: #00A824;
        width: 90px;
    }

    .cancel-btn {
        background: #777777;
        width: 90px;
    }
`;

export const ModalBody = styled.div`
    padding: 10px;

    button {
        margin: 0 10px 10px 0px;
        width: 93%;
        border-radius: 4px;
        color: #fff;
        height: 30px;
        border: none;
        outline: none;
        background-color: #de2027;
        &:hover {
            cursor: pointer
        }
    }

    .add-doc-btn {
        display: flex;
        padding: 6px 10px;
        background: #2F80ED;
        height: 30px;
        width: 245px;
    }

    .add-region-btn {
        display: flex;
        padding: 6px 10px;
        background: #2F80ED;
        height: 30px;
        width: 155px;
        margin-top: 12px;
    }

    .register-table {
        min-height: 100px;
        max-height: ${props => props.responsive.mobile ? "initial" : "352px"};
        height: auto;

        .region-btn-img {
            width: 30px;
            height: 30px;
            cursor: pointer;
            background-position: center !important;
            margin-left: 5px;
        }

        .sub-reg-btn {
            position: relative;
            div {
              position: absolute;
              font-size: 12px;
              padding: 8px 30px;
              width: max-content;
              color: #779480;
              font-family: Roboto;
            }
        }

        table {
            border: 1px solid #B6D4C0;
            border-spacing: 0;
            width: 100%;

            thead {
                tr {
                    width: 100%;
                    height: auto;
                    display: flex;
                    margin: 0;
                    padding: 0;
                    background: #fff;
    
                    td {
                        padding: 3px 8px;
                        border: 1px solid #B6D4C0;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        background: #E0F0E5;
                        color: #008A78;
                    }
                }
            }

            tbody {
                tr {
                    width: 100%;
                    height: auto;
                    display: flex;
                    margin: 0;
                    padding: 0;
                    background: #fff;
    
                    td {
                        padding: 3px 8px;
                        border: 1px solid #B6D4C0;
                    }
                }
            }

            .even {
                background: #F8FFFA;
            }
        }
    }
`;

export const OnTop = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    backdrop-filter: blur(3px);
    background-color: rgb(0 0 0 / 49%);
    z-index: 1000;
`;

export default function RegistrationLecensee({
    setShowRegistrationLecensee,
    responsive,
    regionsData,
	userInfo
}) {
    const { i18n, t } = useTranslation();
    const hiddenOtherInput = React.createRef();
    const [docs, setDocs] = useState([]);
    const [regions, setRegion] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [isShowConfirm, setShowConfirm] = useState(false);
    const [isShowRegionsModal, setShowRegionsModal] = useState(false);

	const isLicensee = userInfo.roles && userInfo.roles.find(role => role.id === 1);
	
	useEffect(() => {
        axios({
            url: `${window.REST_URL}/cabinet/zkr/provider`,
            method: 'get'
        })
        .then((res) => {
			setDocs(res.data.files !== undefined ? res.data.files : []);
			setRegion(res.data.regions.map((item) => {
				const region = regionsData.find(region => region.code === item.regionCode);
				if (item.districtId) {
					return region.districts.find(district => district.id === item.districtId);
				}
				return region;
			}))
        })
        .catch((err) => {
            console.log(err);
        });
	}, [])

    const handleOtherSelect = event => {
        const fileName = event.target.files[0].name;
        docs.push({
            typeCode: "00000",
            fileName: fileName,
            file: event.target.files[0]
        });

        setDocs(docs);
        setLoading(!isLoading)
    }

    const handleOtherClick = () => {
        hiddenOtherInput.current.click();
    }

    //производит проверку на заполненность обязательных полей и открывает диалог с запросом подтверждения
    const onSave = () => {
        //для начала запустим флк
        //документ-основание и регион должны быть обязательно выбраны
        if(docs.length == 0) {
            alert('Выберите документ основание')
            return
        }
        if(regions.length == 0) {
            alert('Выберите регион')
            return
        }

        //покажем диалоговое окно с запросом подветверждения отправки данных
        setShowConfirm(true)
    }

    //запускается после подтвержения из диалога и отправляет данные на сервер
    const sendDataRequest = () => {
        //готовим данные к отправке

        const regionsData = []
        for (var i = 0; i < regions.length; i++) {
            let obj = {};
			if ('id' in regions[i]) {
				obj.districtId = regions[i].id;
				obj.regionCode = regions[i].regionCode;
			} else {
				obj.regionCode = regions[i].code;
			}
            regionsData.push(obj)
        }

        const formData = new FormData();

		for (var i = 0; i < docs.length; i++) {
			formData.append(`files[${i}]`, docs[i].file ? new Blob([docs[i].file]) : new Blob([]), docs[i].fileName)
        }

        formData.append('regions', JSON.stringify(regionsData));

        var object = {};
        formData.forEach((value, key) => object[key] = value);

        axios({
            url: `${window.REST_URL}/cabinet/zkr/provider`,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            method: "post",
            data: formData
        })
        .then((res) => {
			if (!isLicensee) {
				userInfo.roles.push({id: 1});
			}
            setShowRegistrationLecensee(false)
        })
        .catch((err) => {
            console.log(err);
        });
    }

    const getRegionName = (region) => {
        let name = ''
        if(region.districts) {
            name = region.nameRu
        } else {
            if (typeof region['parent'] !== "undefined") {
                name = region.parent.nameRu
            } else {
                name = regionsData.find(item => item.code === region.regionCode).nameRu;
            }
        }

        return name
    }

    const getDistrictNames = (region) => {
        let names = ''
        if(region.districts) {
            for (var i = 0; i < region.districts.length; i++) {
                if(names == '') {
                    names = region.districts[i].nameRu
                } else {
                    names = names + ', ' + region.districts[i].nameRu
                }
            }
        } else {
            names = region.nameRu
        }
        
        return names
    }

    //отрисовывает таблицу с документами-основаниями
    const getDocsTable = () => {
        return (
            <table>
                <thead>
                    <tr>
                        <td style={{ width: "5%", textAlign: "center" }}>№</td>
                        <td style={{ width: "80%", textAlign: "center" }}>{t("zkr.name-doc")}</td>
                        <td style={{ width: "15%", textAlign: "center" }}>{t("zkr.actions")}</td>
                    </tr>
                </thead>
                <tbody>
                { docs && docs.map((item, i) => (
                    <tr key={i}>
                        <td style={{ width: "5%", textAlign: "center" }}>{++i}</td>
                        <td style={{ width: "80%" }}>{item.fileName}</td>
                        <td style={{ width: "15%" }}>
                            <div className="region-btn-img sub-reg-btn" style={{ background: `url(${svgTrash}) no-repeat`, backgroundSize: "18px" }}
                                onClick={(e) => {
                                    docs.splice(i - 1, 1);
                                    setLoading(!isLoading)
                                }}
                            >
                                {/* <div>
                                    <div></div>
                                    {t("zkr.delete")}
                                </div> */}
                                <div>{t("zkr.delete")}</div>
                            </div>
                        </td>
                    </tr>
                )) }
                </tbody>
            </table>
        )
    };

    //отрисовывает таблицу с выбранными регионами
    const getRegionsTable = () => {
        return (
            <table>
                <thead>
                    <tr>
                        <td style={{ width: "5%", textAlign: "center" }}>№</td>
                        <td style={{ width: "40%", textAlign: "center" }}>{t("zkr.region")}</td>
                        <td style={{ width: "40%", textAlign: "center" }}>{t("zkr.area")}</td>
                        <td style={{ width: "15%", textAlign: "center" }}>{t("zkr.actions")}</td>
                    </tr>
                </thead>
                <tbody>
                { regions && regions.map((item, i) => (
                    <tr key={i}>
                        <td style={{ width: "5%", textAlign: "center" }}>{++i}</td>
                        <td style={{ width: "40%" }} title={getRegionName(item)}>{getRegionName(item)}</td>
                        <td style={{ width: "40%" }} title={getDistrictNames(item)}>{getDistrictNames(item)}</td>
                        <td style={{ width: "15%" }}>
                            <div className="region-btn-img sub-reg-btn" style={{ background: `url(${svgTrash}) no-repeat`, backgroundSize: "18px" }}
                                onClick={(e) => {
                                    regions.splice(i - 1, 1);
                                    setLoading(!isLoading)
                                }}
                            >
                                {/* <div>
                                    <div></div>
                                    {t("zkr.delete")}
                                </div> */}
                                <div>{t("zkr.delete")}</div>
                            </div>
                        </td>
                    </tr>
                )) }
                </tbody>
            </table>
        )
    }

    return (
        <OnTop>
            <Modal responsive={responsive}>
            <ModalHeader>
            <div className="header">
                <div className='title'>{t("zkr.reg-data")}</div>
            </div>
            </ModalHeader>
            <Scrollbars
                autoHeight={true}
                autoHide={true}
                autoHeightMax={"37vh"}
                renderView={props => <div {...props} className="scrollbar-view" />}
            >
            <ModalBody responsive={responsive}>
                <button
                    className="add-doc-btn"
                    href=""
                    onClick={() => {
                        handleOtherClick();
                    }}
                >
                    <Icon url={svgAdd} className="icon" />
                    {t("zkr.add-doc")}
                </button>
                <input
                    type="file"
                    accept="application/pdf,application/msword"
                    ref={hiddenOtherInput}
                    onChange={event => {
                        event.target.files.length !== 0 && handleOtherSelect(event)
                    }}
                    style={{ display: 'none' }}
                />

                <div className="register-table">
                    {getDocsTable()}
                </div>

                <button
                    className="add-region-btn"
                    href=""
                    onClick={() => {
                        setShowRegionsModal(true)
                    }}
                >
                    <Icon url={svgAdd} className="icon" />
                    {t("zkr.add-region")}
                </button>

                <div className="register-table">
                    {getRegionsTable()}
                </div>
            </ModalBody>
            </Scrollbars>
            <ModalFooter responsive={responsive}>
                <button
                    className="success-btn"
                    href=""
                    onClick={() => {
                        {onSave()}
                    }}
                >
                    {t("request.13")}
                </button>

                <button
                    className="cancel-btn"
                    href=""
                    onClick={() => {
                        setShowRegistrationLecensee(false)
                    }}
                >
                    {t("request.cancel")}
                </button>
            </ModalFooter>

            {isShowRegionsModal && (
                <RegionDialog
                    setRegion={setRegion}
                    selectedRegions={regions}
                    setShowRegionsModal={setShowRegionsModal}
                    regions={regionsData}
                    lang={i18n.language}
                    responsive={responsive}
                />
            )}
            {isShowConfirm && (
                <ConfirmDialog
                    title={t("request.13")}
                    text={t("zkr.confirm")}
                    handleOnConfirm={() => sendDataRequest()}
                    handleOnCancel={() => setShowConfirm(false)}
                />
            )}
            </Modal>
        </OnTop>
    );
}
