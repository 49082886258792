import React from 'react';
import { OnTop } from './ListService';
import { Objects } from './ProfileObjects';
import { Icon } from './UserProfile';
import svgExit from '../../assets/img/exit.svg'
import { Properties } from '../../utils/info/config';
import PulseLoader from "react-spinners/PulseLoader";

export default function ProfileObjects({
    setShowObjectArrests,
    setShowProfileObjects,
    objectArrests,
    objectArrestsLoading,
    arrestsKadNum
}) {

    const arrestProps = Properties.lands.tabs[2].groups[0].props;

    const listArrests = () => {
        return objectArrests.filter(item => item.isFind !== 0).map((item, i) => (
            <div key={i} className="inventory-item show">
                 <div className="title">
                    <div className="name">
                        <p>{item.chargeTypeRu}</p>
                    </div>
                </div>
                <div className={"items-list"}>
                    {Object.keys(arrestProps).filter(key => item[key]).map((key, j) => (
                        <div key={"" + i + j} className={"object-item" + (j % 2 === 1 ? " even" : "")}>
                            <div className="td">
                                <p>{arrestProps[key][0]()}</p>
                            </div>
                            <div className="td">
                                <p>{item[key]}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        ));
    }

    return (
        <OnTop>
            <Objects>
                <div className="header">
                    <h1>{arrestsKadNum}</h1>
                    <button
                        onClick={() => {
                            setShowObjectArrests(false)
                            setShowProfileObjects(true);
                        }}
                    >
                        <Icon url={svgExit} />
                    </button>
                </div>
                {objectArrestsLoading ? 
                    <div className="justify-center" style={{padding: '15px'}}>
                        <PulseLoader size={15} color={"#02c23e"} loading={true} />
                    </div> : 
                    <div className="objects-list">{listArrests()}</div>
                }
            </Objects>
        </OnTop>
    );
}