import React from 'react';
import styled from 'styled-components';
import { Scrollbars } from 'react-custom-scrollbars';
import { Wrapper, Header, Title } from "./Stats/BarChart";
import svgClose from '../assets/img/stat_close.svg';
import { getTemplate} from "../utils/RestApi";
import { useTranslation } from "react-i18next";
import downloadSvg from "../assets/img/download.svg";

const CloseButton = styled.div`
  position: absolute;
  right: 42px;
  display: flex;
  :hover {
    cursor: pointer;
  }
`;

const HelpContent = styled.div`
  border: 2px solid #DCF0E2;
  margin: 10px;
  border-radius: 5px;
`;

const HelpRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 8px 8px 15px;
  padding-left:15px;
  font-family: Roboto;
  font-size: 14px;
  color: #294A37;
  gap: 15px;

  &:not(:last-child) {
    border-bottom: 2px solid #DCF0E2;
  }
  
  p {
    flex: 1;
    margin: 0;
    padding: 0;
  }
  .download_btn {
    height: 25px;
    width: 120px;
    background-color: #009856;
    color: #ffffff;
    border: none; 
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }

`;

const Help = ({ palette, handleCloseHelp }) => {

    const { t } = useTranslation();

    const helpRows = [
      {
          onClick: () => getTemplate("instructions_ru", 'Инструкция на русском'),
          text: t("help.instr_ru"),
          buttonText: t("zkr.download"),
      },
      {
          onClick: () => getTemplate("instructions_kz", 'Инструкция на казахском'),
          text: t("help.instr_kz"),
          buttonText: t("zkr.download"),
      },
      {
          onClick: () => getTemplate("instructions_sogl", "Согласование проектируемого земельного участка (инструкция)"),
          text: t("help.instr_sogl"),
          buttonText: t("zkr.download"),
      },
      {
        onClick: () => getTemplate("instructions_comm", "Рекомендуемый порядок установления границ земельного участка (инструкция)"),
        text: t("help.instr_comm"),
        buttonText: t("zkr.download"),
      }
  ];
  
    return (
      <Wrapper palette={palette}>
        <div className="center-div" style={{ minWidth: "1060px", width: "auto", left: "-50%" }}>
          <Header>
            <Title>{t("help.title")}</Title>
            <CloseButton onClick={handleCloseHelp}>
              <img src={svgClose} style={{ width: '16px' }} alt="" />
            </CloseButton>
          </Header>
          <Scrollbars autoHeight={true} autoHide={true} autoHeightMax={"80vh"}>
              <HelpContent>
                    {helpRows.map((row, index) => (
                      <HelpRow key={index}>
                          <p>{row.text}</p>
                          <button 
                            className="download_btn"
                            onClick={row.onClick}
                          >
                            {row.buttonText}
                            <img src={downloadSvg}/>
                          </button>
                      </HelpRow>  
                    ))}
              </HelpContent>
          </Scrollbars>
        </div>
      </Wrapper>
    );
  };
  
  
  
export default Help;


