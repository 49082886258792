import React from 'react'
import { Scrollbars } from "react-custom-scrollbars";
import { List, OnTop } from "./ListService";
import PulseLoader from "react-spinners/PulseLoader";
import { Icon } from './UserProfile';
import svgExit from '../../assets/img/exit.svg';
import { withTranslation } from 'react-i18next';

class ListMyObjs extends React.Component {

    // componentWillMount() {
    componentDidMount() {
        this.props.setMyObjLoaded(false);
        if (this.props.showListMyObjs) {
            if (this.props.order.services[0].code === "807000") {
                this.props.handleMyObjs("taxobject");
            } else {
                this.props.handleMyObjs("appobject");
            }
        } else  {
            this.props.handleLand(this.props.landInd);
        }   
    }

    handleObjectSelect(item) {
        item.cadnum = item.cadastrNum;
        item.districtName = this.props.region.nameRu;
        item.srs = this.props.region.srs;
        this.props.order.objects.push(item);
        this.props.setOrder(Object.assign({}, this.props.order));

        if (this.props.showListMyObjs) {
            if (item.cadnum) {
                this.props.handleGetCoords(item, "cadnum");
            }
            this.props.setShowListMyObjs(false)
        } else if (this.props.showLandIdObj) {
            this.props.handleGetCoords(item, "landid");
            this.props.setShowLandIdObj(false)
        } 
    }

    render() { 
        const { t } = this.props;
        return (
            <OnTop>
                <List responsive={this.props.responsive}>
                    <div className="header">
                        <h2>{t('new-obj.choose-obj')}</h2>  
                        <button onClick={() =>{
                            if (this.props.showListMyObjs) {
                                this.props.setShowListMyObjs(false)
                            } else if(this.props.showLandIdObj) {
                                this.props.setShowLandIdObj(false);
                            } else if (this.props.showProvider) {
                                this.props.setShowProviders(false)
                            }
                        }}>
                            <Icon url={svgExit} />
                        </button>
                    </div>
                    <div palette={this.props.palette}>
                        {!this.props.myObjLoaded ?
                            <div className="justify-center" style={{ padding: '15px' }}><PulseLoader size={15} color={"#02c23e"} loading={true} /></div> :
                            <div className="table">
                                <div className="wrapper">
                                    <Scrollbars
                                        className="scrollbar"
                                        style={{}}
                                        autoHeight
                                        autoHide
                                        autoHeightMin={300}
                                        autoHeightMax={450}
                                        renderView={props => <div {...props} className="scrollbar-view" />}
                                    >
                                        <div className="tbody">
                                            {this.props.myObjs.length>0 ? this.props.myObjs.map((item, i) => (
                                                <div
                                                    onClick={() => this.handleObjectSelect(item)}
                                                    className={"tr selectable" + (i % 2 === 1 ? " even" : "")}
                                                    key={i}
                                                    style={{
                                                        display: "grid",
                                                        gridTemplateColumns: `repeat(${1}, 1fr)`,
                                                    }}
                                                >
                                                    <div className={"td"}>
                                                        {(item.funcUseNameRu ? (item.funcUseNameRu + ": ")
                                                            : (item.vidInvNameRu ? (item.vidInvNameRu + ": ") : ""))
                                                            + item.addrRu}
                                                    </div>
                                                </div>
                                            ))
                                            : this.props.showListMyObjs ? t('new-obj.search-my-obj') : t('new-obj.search-obj')
                                            }
                                        </div>
                                    </Scrollbars>
                                    <div className="tfoot">
                                        <div
                                            className="tr"
                                            key={this.props.myObjs.length}
                                            style={{
                                                display: "grid",
                                                gridTemplateColumns: `repeat(${1}, 1fr)`,
                                            }}
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </List>
            </OnTop>
        );
    }
}

export default withTranslation()(ListMyObjs);