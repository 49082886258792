import React from 'react'
import { List, OnTop } from "./ListService";
import { Icon } from './UserProfile';
import svgSearch from '../../assets/img/gray-search.svg';
import styled from "styled-components";
import ClipLoader from "react-spinners/ClipLoader";
import { Scrollbars } from "react-custom-scrollbars";
import { withTranslation } from 'react-i18next';

const SearchList = styled(List)`
    font-family: sf-pro-display-regular;
    font-style: normal;
    font-weight: normal;
    left: ${props => props.responsive.mobile ? "0" : "50%"};
    width: ${props => props.responsive.mobile ? "100%" : "35%"};
    padding: 6px 12px 16px 12px;
    color: #294A37;
    

    .search-input-wrap {
        background: #DCF0E2;
        border: 1px solid #B4CFBC;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 2px 3px 2px 12px;
        display: flex;
        height: 35px;

        input {
            width: 100%;
            background: none;
            border: none;
            font-size: 18px;
            line-height: 13px;
        }

        input:focus {
            outline: none;
        }
    }

    .search-btn {
        cursor: pointer;

        div {
            width: 27px !important; 
            height: 27px !important;
        }
    }

    .search-title {
        font-size: 16px;
        line-height: 24px;
    }

    .found-obj {
        margin-top: 12px;
        background: #DCF0E2;
        border-radius: 5px;
        padding: 12px;
        font-size: 18px;
        cursor: pointer;
    }

    .selected-obj {
        border: 1px solid #B4CFBC;
        box-sizing: border-box;
        background: #FFFFFF;
        box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.25);
    }

    .search-btns {
        display: flex;
        margin: 12px 0px 0px 0px;
        justify-content: end;
    }

    .submit-btn {
        background: #01C240;
        border-radius: 5px;
        width: 100px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        line-height: 13px;
        color: #fff;
        cursor: pointer;
    }

    .search-btn-cancel {
        background: #636476;
        margin: 0 0px 0px 12px;
    }

    .search-btn-disabled {
        cursor: default;
        background: #9595a1;
    }

    .object-not-found {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
    }
`;

class ListMyObjs extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            objects: [],
            value: props.cadNum,
            selectedObj: undefined,
            searchLoading: false,
            isNotFound: false
        };

        this.handleOnInputChange = this.handleOnInputChange.bind(this);
        this.handleOnSearch = this.handleOnSearch.bind(this);
        this.handleConfirmObject = this.handleConfirmObject.bind(this);
    }

    componentDidMount() {
        this.handleOnSearch(false);
    }

    handleOnSearch() {
        if (this.state.value && this.state.value.length < 11) {
            return;
        }
        this.setState({ searchLoading: true });
        const objParams = {
            kadNum: this.state.value,
            ateCode: this.props.region.ate_code
        }
        fetch(`${window.REST_URL}/cabinet/search/object`, {
            method: "POST",
            mode: "cors",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(objParams),
        })
            .then(response => {
                if (response.status==200) {
                    return response.json();
                } else {
                    this.setState({ searchLoading: false, isNotFound: true});
                }
            }).
            then(json => {
                this.setState({ objects: json, searchLoading: false, isNotFound: json.length === 0 });
            })
            .catch(err => {
                this.setState({ searchLoading: false });
            });
    }

    handleOnInputChange(value) {
        this.setState({ value: value });
    }

    handleConfirmObject() {
        const object = this.state.selectedObj;

        object.cadnum = object.cadastrNum;
        object.districtName = this.props.region.nameRu
        object.srs = this.props.region.srs;
        object.area = object.square;

        if (this.props.isNewObject) {
            this.props.setFoundObject(object);
        } else {
            this.props.order.objects.push(object);
            // this.props.setOrder(JSON.parse(JSON.stringify(this.props.order)));
            this.props.setOrder(Object.assign({},  this.props.order))
            if (object.cadnum) {
                this.props.handleGetCoords(object, "cadnum");
            }
        }

        this.props.setShowObjSearch(false);
    }
    

    render() {
        const { t } = this.props;

        return (
            <OnTop>
                <SearchList responsive={this.props.responsive}>
                    <div className="search-wrap">
                        <div className="search-title">{t('new-obj.cadnum')}</div>
                        <div className="search-input-wrap">
                            <input disabled={true} value={this.state.value} onChange={(e) => this.handleOnInputChange(e.target.value)} />
                            {this.state.searchLoading ?
                                <ClipLoader color={"#02c23e"} loading={true} size="25px" /> :
                                <div className="search-btn" onClick={() => this.handleOnSearch(false)}>
                                    <Icon url={svgSearch} />
                                </div>
                            }
                        </div>
                        {this.state.isNotFound && <div className="object-not-found">
                            {t('new-obj.cadnum-search')}
                        </div>}
                        {this.state.objects && this.state.objects.length > 0 &&
                            <Scrollbars
                                autoHeight={true}
                                autoHide={true}
                                autoHeightMax={"350px"}
                                renderView={props => <div {...props} className="scrollbar-view" />}
                            >
                                {
                                    this.state.objects.map((obj, i) =>
                                    <div key={i} className={"found-obj" + (this.state.selectedObj && this.state.selectedObj.idInv === obj.idInv ? " selected-obj" : "")}
                                        onClick={() => {
                                            this.setState({ selectedObj: obj });
                                        }}
                                    >
                                        <div>РКА: {obj.addressCode}</div>
                                        <div>{t('new-obj.address')} {obj.addrRu}</div>
                                        <div>{t('new-obj.target')} {obj.celRu}</div>
                                        <div>{t('new-obj.target-desc')} {obj.celUseNameRu}</div>
                                    </div>
                                )
                                }
                            </Scrollbars>
                            // <div className='test'>
                            //     {
                            //         this.state.objects.map((obj, i) =>
                            //         <div key={i} className={"found-obj" + (this.state.selectedObj && this.state.selectedObj.idInv === obj.idInv ? " selected-obj" : "")}
                            //             onClick={() => {
                            //                 this.setState({ selectedObj: obj });
                            //             }}
                            //         >
                            //             <div>РКА: {obj.addressCode}</div>
                            //             <div>Адрес: {obj.addrRu}</div>
                            //             <div>Целевое назначение: {obj.celRu}</div>
                            //             <div>Описание целевого назначения: {obj.celUseNameRu}</div>
                            //         </div>
                            //     )
                            //     }
                            // </div>
                        }
                        <div className="search-btns">
                            <div className={"submit-btn" + (this.state.selectedObj ? "" : " search-btn-disabled")}
                                onClick={() => {
                                    if (this.state.selectedObj) {
                                        this.handleConfirmObject();
                                    }
                                }}
                            >
                                {t('new-obj.choose')}
                            </div>
                            <div className="submit-btn search-btn-cancel"
                                onClick={() => {
                                    this.props.setShowObjSearch(false);
                                }}
                            >
                                {t('new-obj.cancel')}
                            </div>
                        </div>
                    </div>
                </SearchList>
            </OnTop>
        );
    }
}

export default withTranslation()(ListMyObjs);