import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { transform } from "ol/proj";
import Overlay from "ol/Overlay";
import ReactTooltip from "react-tooltip";
import { Tooltip, Icon } from "./StatsStyled";
import Pie from "./Pie";
import { VIEW_PIES } from './Stats';
// import Tooltip from "./Tooltip";

export default function PieChart(props) {
  const [vpie, setVpie] = useState();
  const [empty, setEmpty] = useState(true);

  useEffect(() => {
    if (props.open && !empty) {
      const overlay = addOverlay(props.map, props.info.position); // when a region is selected, needs to be positioned relative to the region
      setVpie(overlay.getElement());
      return () => {
        props.map.removeOverlay(overlay);
        setVpie();
      };
    }
  }, [props.map, props.open, props.info.position, empty]);

  useEffect(() => {
    let total = Object.keys(props.info.data)
      .filter(key => key !== "position")
      .reduce((sum, key) => sum + props.info.data[key], 0);
    total && setEmpty(false);
  }, []);

  const tooltipContent = (
    <Tooltip palette={props.theme} level={props.level}>
      <h3>{props.info.title}</h3>
      {props.legend.filter(obj => obj.color).map((obj, i) => (
        <div key={obj.title}>
          {props.info.data[i] ? (
            <div
              className="row"
              onClick={
                props.level
                  ? () => props.handleGetStatObjects(obj.id, props.info.region, props.info.district)
                  : () => { }
              }
            >
              <Icon color={obj.color} />
              <p>{props.info.data[i].x}</p>
            </div>
          ) : null}
        </div>
      ))}
    </Tooltip>
  );

  const x = (
    <div onClick={() => { props.setItem(props.info); props.setView(VIEW_PIES) }}>
      <ReactTooltip
        id={"legend" + props.num}
        event="click"
        place="top"
        multiline={true}
        type={props.dark ? "light" : "dark"}
        effect="solid"
        clickable
        scrollHide
        getContent={showTooltip => (showTooltip ? tooltipContent : null)}
      ></ReactTooltip>
      <Pie
        colors={props.legend.map(obj => obj.color)}
        items={props.legend.map(e => props.info.data[e.id])}
        width={125}
        height={125}
        total={props.legend.map(e => props.info.data[e.id]).reduce((sum, item) => sum + item)}
      />

    </div>
  );

  if (vpie) {
    return createPortal(x, vpie);
  }

  return null;
}

function addOverlay(map, position) {
  const e = document.createElement("div");
  const mapOverlay = new Overlay({
    element: e,
    position: transform(position, "EPSG:3857", map.getView().getProjection()),
    positioning: "top-left",
    offset: [-30, -30],
    stopEvent: false,
  });
  e.click(e => {
    console.log(e.target);
  });
  map.addOverlay(mapOverlay);

  return mapOverlay;
}
