import React, { useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { useTranslation } from "react-i18next";
import "..//Layers//MapLayers.css";
import styled from "styled-components";
import {postDoc} from "../../EknMap";
import { WKT } from "ol/format";
import { postJson, getSvg, getTemplate} from "../../utils/RestApi";
import parse from 'html-react-parser';
import {matchScale} from "../../util";
import { MODE_SELECT, MODE_SELECT_PDP } from "../../EknMap";
import { Feature } from "ol";

const DecisionBtn = styled.button`
    border: none;
    outline: none;
    background-color: ${props => props.color};
    width: ${props => props.width ? props.width : "120px"};
    border-radius: 6px;
    font-size: 14px;
    color: #fff;
    padding: ${props => props.padding ? props.padding : "10px"}
    margin-right: 5px;
    margin-top: ${props => props.margin ? props.margin : ""};

    &:hover {
        cursor: pointer;
    }
    &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
    }
`

const CoordinatesContent = styled.div`

    position: relative;
    padding: 20px;
    border-radius: 12px;
    background-color: #fff;
    height: 550px;
    width: 1300px;
    // overflow-y: auto;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    input {
        width: 100%;
        // background-color: #015f24;
        background-color: rgba(243, 243, 247, 1)
        border-radius: 4px;
        // color: rgb(152, 152, 152);
        height: 20px;

        border: 1px solid #B6D4C0;
    }

    .coords-wrapper {
        display: flex;
    }
    .coords-info {
        width: 800px
    }
    .coords-svg {
        width: 450px;
        display: flex;
        flex-direction: column;

        .coords-svg_title {
            margin-top: 0;
            padding: 10px;
            padding-top: 0;
            text-align: center;
            font-size: 16px;
        }
        .coords-svg_item {
            margin-top: 50px
        }

        .coords-map {
            margin: 0 auto;
            margin-top: auto;
            width: 150px;
            background: #009856;
            color: #fff;
            border: none;
            padding: 5px;
            border-radius: 5px;
        }
    }
    

    .order-table {
        min-height: 100px;
        // height: 350px;
        height: ${props => props.errInfo && props.errInfo.length>0 ? "250px" : "350px"};
        overflow: auto;
        margin-top: 10px

        table {
            border: 1px solid #B6D4C0;
            border-spacing: 0;
            // margin: 5px 0px 5px 6px;
            // width: 98%;
            width: 100%

            th {
                po
            }
            tr {
                width: 100%;
                height: auto;
                display: flex;
                margin: 0;
                padding: 0;
                background: #fff;

                td {
                    padding: 6px 8px;
                    // border: 1px solid #B6D4C0;
                    text-align: center;

                    p {
                        overflow: auto;
                        line-height: 20px;
                    }
                }
            }

            .even {
                background: #009856;
                color #fff;
                text-align: center;
               
            }
            // thead {
            //     position: -webkit-sticky;
            //     position: -moz-sticky;
            //     position: -ms-sticky;
            //     position: -o-sticky;
            //     position: sticky;
            //     top: 0;
            // }
        }
    }

    tbody tr {
        position: relative;
    }
      
    td input {
        height: auto;
        border: none
        margin-top: 20px;
        text-align: center;
        font-size: 14px;
        color: #000000;
        font-family: 'sf-pro-display-regular',sans-serif;
    }
    td .border {
        border-right: 2px solid #B6D4C0; 
        border-bottom: 2px solid #B6D4C0;
    }

    td .border-r {
        // border-right: 2px solid #B6D4C0; 
        // border-bottom: 2px solid #B6D4C0;
        margin-top: -20px
    }

    .btn-container {
        // display: flex;
        // alig-items: center;
        margin-top: 15px;
    }

    .fa-pencil{
        color: #009856;
    }

    .btn-trash {
        color: #e5383b;
    }

    .btn-td {
        border: none;
        background-color: #fff;
    }

    .btn-eraser-container {
        // display: flex;
        // alig-items: center;
        // justify-content: center;
        margin-top: 15px;
    }
    .btn-eraser {
        border: 1px solid #fff;
        background-color: #009856;
    }
    
    .fa-eraser {
        color: #fff;
        background: #009856;
        
    }

    .add-panel {
        display: flex;
        align-items: center;
        justify-content: end;
        padding: 10px;
        padding-right: 2px;
        position: relative;
    }

    .add-panel__item {
        margin-right: 5px;
        height: 40px;
    }    
    .add-panel__item_update {
        width: 30px;
    }
    .add-panel__item:second-child {
        width: 30px;
    }
    .btn-add {
        background-color: #fff;
        border-radius: 2px;
        border: 1px solid #009856;
        margin-top: -21px;
    }
    .btn-visible {
        background-color: #fff;
        border: none;
        margin-top: -21px
    }
    .fa-plus {
        color: #009856;
        margin-top: 2px;
    }
    .fa-ban {
        color: red;
    }
    .fa-rotate-right {
        color: #2485bd;
    }

    .import-icon {
        float: right !important;
        margin: 2px 7px 0 0 !important;
        width: 15px !important;
        height: 15px !important;
        background: transparent url(/static/media/add.3666f495.svg) 0% 0% no-repeat padding-box !important;
        padding: 0 !important;
        padding-left: 9px !important;
    }

    .btn-import {
        width: 100%;
        margin: 0;
        background: #009856;
        text-align: start;
        padding-left: 62px;
        font-family: 'sf-pro-display-regular',sans-serif;
        -webkit-letter-spacing: 0.5px;
        -moz-letter-spacing: 0.5px;
        -ms-letter-spacing: 0.5px;
        letter-spacing: 0.5px;
    }

    .field__wrapper {
        width: 100%;
        position: relative;
        margin: 15px 0;
        text-align: center;
    }
       
    .input__file {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        width: 760px;
        // height: 30px;
    }
       
    .field__file-wrapper {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
       
    .field__file-fake {
        height: 30px;
        width: calc(100% - 130px);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 0 15px;
        border: 1px solid #c7c7c7;
        border-radius: 3px 0 0 3px;
        border-right: none;
        cursor: pointer;
    }
       
    .field__file-button {
        width: 130px;
        height: 30px;
        background: #009856;
        color: #fff;
        font-size: 14px;
        // font-weight: 700;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        border-radius: 0 3px 3px 0;
        cursor: pointer;
    }

    .input__wrapper {
        margin-bottom: 15px;
    }


    .decision {
        display: flex;
        justify-content: center;
        // position: fixed;
        // bottom: 8%;
        // right: 10%;
    }
    .show-plan {
        border: none;
        outline: none;
        background-color: #009856;
        width: 180px;
        border-radius: 6px;
        font-size: 14px;
        color: #fff;
        padding: 5px;
        margin-right: 5px;
        position: absolute;
        left: 2px;
        top: 10px;

        &:hover {
        cursor: pointer;
        }
    }

    .coords_system {
        display: flex;
        align-items: center;
        height: 30px;

        p {
            width: 200px;
            display: block;
            height: 20px;
            margin: 0;
            padding: 10px;
            line-height: 0;
            background-color: #009856;
            height: 100%;
            color: #fff;
            text-align: center;
            line-height: 10px
        }

        .coords_system__input {
            height: 100%;
            border: 1px solid #c7c7c7;
            border-radius: 0 3px 3px 0;
            padding-left: 10px;
            font-size: 14px;
            font-family: 'sf-pro-display-regular',sans-serif;
            color: #000;
        }
    }

    .coords_area {
        display: flex;
        margin-top: 10px;
        h2 {
            width: 162.78px;
            height: 30px;
            margin:0;
            margin-right: 12px;
           
        }
        p {
            margin: 0;
            line-height: 25px;
            font-size: 14px;
        }
    }  
    .loading {
        display: flex;
        height: 200px;
        justify-content: center;
        align-items: center;
    }
    .data-loading {
        display: flex;
        height: 350px;
        justify-content: center;
        align-items: center;
    }
    .td-loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .templates {
        display: flex;
        margin-bottom: 30px;
    }
    .templates__item {
        margin-right: 75px;

        button {
            width: 170px;
            height: 30px;
            background-color: rgb(47, 128, 237);
            color: #fff;
            border: none;
            border-radius: 15px;
            font-size: 14px;
        }
    }
    .templates__item:last-child {
        margin-right: 0;
    }
`;

const Errors = styled.div`
    background : #E8D3D3;
    border-radius: 5px;
    margin-bottom: 10px;

    h2 {
        font-weight: bold;
        border-radius: 5px 5px 0 0;
    }

    p {
        color: rgb(193, 39, 45);  
        margin: 0;
        padding: 7px;   
    }
`;

const Coordinates = ({active, setActive, setGeom, publicService, setPublicService, useInput, fileFormatNote, checkFileFormat, errors, setErrors, region, features, setFeatures, handleLoadFeature, setMode, removeSegments, setSegments, layers, source, serviceId, cadNumData }) => {
    const { t } = useTranslation();
    const [data, setData] = React.useState([]);

    const [number, setNumber] = React.useState('');
    const [part, setPart] = React.useState('');
    const [x, setX] = React.useState('');
    const [y, setY] = React.useState('');
    const [btnVisible, setBtnVisible] = React.useState(false);
    const [fileName, setFileName] = React.useState(t("coordinates.doc"));
    const [geom, setGeometry] = React.useState('');
    const [informAreaActive, setInformAreaActive] = React.useState(true);

    const [partError, setPartError] = React.useState(false);
    const [xError, setXError] = React.useState(false);
    const [yError, setYError] = React.useState(false);

    const [svg, setSvg] = React.useState('');
    const [coordsSystem, setCoordsSystem] = React.useState('');
    const [area, setArea] = React.useState();
    const [scale, setScale] = React.useState();
    const [land, setLand] = React.useState();

    const [prevObj, setPrevObj] = React.useState({fileName : '', svg: '', data: [], area: '', srs: ''});

    const [dataLoading, setDataLoading] = React.useState(false);
    const [svgLoading, setSvgLoading] = React.useState(false);
    const templates = [{name: t('coordinates.template') + ' TXT', ext: 'txt_template'},{name: t('coordinates.template') + ' Excel', ext: 'xlsx_template'},{name: t('coordinates.template') + ' Shape', ext: 'zip_template'}];
    const [errIntersect, setErrIntersect] = React.useState(true);
    const [errInfo, setErrInfo] = React.useState(true);

    const imporFile = (e) => {
        if (e.target.files.length> 0) {
            if (checkFileFormat(e.target.files[0].name)) {
                fileFormatNote();
            } else {
                setDataLoading(true);
                setSvgLoading(true);
                setData([]);
                setFileName(e.target.files[0].name);
                postDoc(e.target.files[0], "ZKR1").then(result => {
                    setGeometry(result);
                });
            }
        }
    }

    useEffect(() => {
        if (geom.length>0) {
                        
            let payload = `&ate=${region.ate_code}`
            if (serviceId) {
                payload += `&egovreq=${serviceId}`;
            }
            if (cadNumData && cadNumData.length>0) {
                const scad = cadNumData.map(item => item.name).join(';');
                payload += `&scad=${scad}`;
            }
            fetch(
                `${window.REST_URL}/map/check?layers=lands,redlines,free,req,pdp_u,ohz,voz,vop${payload}`,
                {
                method: "POST",
                mode: "cors",
                credentials: "include",
                body: geom
                },
            )
            .then(response => response.json())
            .then(result => {
                //
                if (layers.select) {
                    removeSegments();
                    setSegments([]);
                    if (result.segments) {
                    const segmentsArr = [];
                    result.segments.forEach(seg => {
                        segmentsArr.push(seg);
                        source.addFeature(
                        new Feature({
                            type: "segment",
                            geometry: new WKT().readGeometryFromText(seg.wkt),
                        }),
                        );
                    });
                    setSegments(segmentsArr);
                    }
                }
                //
                if (result.errors) {
                    const tempErrInfo = [];
                    const tempErrIntersect = [];

                    for (let i = 0; i < result.errors.length; i++) {
                        const error = result.errors[i];
                        if (error.statusId === 503100) {
                            tempErrInfo.push(error);
                        } else {
                            tempErrIntersect.push(error);
                        }
                    }
                    setErrInfo(tempErrInfo);
                    setErrIntersect(tempErrIntersect);

                } else {
                    setErrIntersect([]);
                    setErrInfo([]);
                }

                if (result.errors) {
                    setDataLoading(false);
                }
            });
        }
    }, [geom, dataLoading])

    useEffect(() => {
        if (geom.length>0) {
                const wkt = new WKT().readGeometry(geom);
                if (errIntersect.length<1) {
                    coordParseFromFile(wkt);
                }
                setArea((new WKT().readGeometry(geom).getArea() / 10000).toFixed(4));
                const scale = matchScale(wkt, [200,500,1000,2000,5000,25000,10000,50000,100000,200000,300000,500000,1000000], 425, 380, 20);
                const part = {
                    geom: geom,
                    width: 425,
                    height: 380,
                    scale: scale,
                    pointNumbers: true,
                    lineLengths: true,
                    adjacentArrows: true
                };

                setScale(scale)

                getSvg(part).then(result => {
                    new Response(result).text().then(result => {
                        setSvg(result);
                        setSvgLoading(false);
                    });
                })
            }
        
    }, [errIntersect])

    const coordParseFromFile = (wkt) => {
        let number = 1;
        let part = 0;
        let arrCoord = [];
        const polygons = wkt.getPolygons();
        polygons.map(polygon => {
            const linearRings = polygon.getLinearRings();
            let firstPoint = number;
            linearRings.map(linearRing => {
                const coords = linearRing.getCoordinates();
                part = part +1;
                coords.map((cd, i) => {
                    const obj = {
                        number: i != coords.length-1 ? number++ : firstPoint,
                        part: part,
                        x: cd[0],
                        y: cd[1]
                    }
                    arrCoord.push(obj);
                })
            })
        })
        setData(arrCoord);
        setDataLoading(false);
    }

    const showPlan = () => {
        const json = Object.assign({}, data);
            postJson(json).then(result => {
                setGeometry(result);
                const part = {
                    geom: result,
                    width: 450,
                    height: 500,
                    scale: 0
                };
                getSvg(part).then(result => {
                    new Response(result).text().then(result => setSvg(result));
                })
        });
    }

    const onValueChange = (e) => {
        if (e.target.name=='part') {
            setPart(e.target.value);
        } else if (e.target.name=='x') {
            setX(e.target.value)
        } else if (e.target.name=='y') {
            setY(e.target.value)
        }
    }
 
    const calculateDistance = (i) => {
        return Math.sqrt(Math.pow((data[i].x - data[i-1].x), 2) + Math.pow((data[i].y - data[i-1].y), 2));
    }

    const onDelete = (id) => {
        let k = 0;
        setData(data.filter(item=>item.number!==id).map(item => {
            const container = {};
            container.number = ++k;
            container.part = item.part;
            container.x = item.x;
            container.y = item.y;
            return container;
        }))
    }

    const addCoordinate = () => {
        setPartError(false);
        setXError(false);
        setYError(false);
        if (part.length==0) {
            setPartError(true);
        } else if (x.length==0) {
            setXError(true);
        } else if (y.length==0) {
            setYError(true);
        } else {
            if (data.length>0) {
                const id = data[data.length-1].number + 1;
                const obj = {number: id, part: part, x: x, y:y};
                setData([...data, obj]);
            } else {
                const id = 1;
                const obj = {number: id, part: part, x: x, y:y};
                setData([obj]);
            }
            setPart('');
            setX('');
            setY('');
        }
        
        
    }

    const clearValues = () => {
        setBtnVisible(!btnVisible);
        setPart('');
        setX('');
        setY('');
    }

    const updateCoordinate = (e) => {
        setBtnVisible(true);
        const id = e.currentTarget.name;
        setNumber(id);
        setPart(data[id-1].part);
        setX(data[id-1].x);
        setY(data[id-1].y);
    }

    const updateCoordinateValues = () => {
        const obj = {
            number : number,
            part: part,
            x: x,
            y: y
        }
        const updeteData = data.map(item => {
            if (item.number==obj.number) {
                return obj;
            } else {
                return item;
            }
        })
        setData(updeteData);
        setBtnVisible(false);
        setPart('');
        setX('');
        setY('');
    }

    const onSave = () => {
        setGeom(geom, coordsSystem, fileName, scale, svg);
        setActive(false);
        prevObj.data=data;
        prevObj.srs=coordsSystem;
        prevObj.svg=svg;
        prevObj.fileName=fileName;
        prevObj.area=area;
        prevObj.geom=geom;
        if (errIntersect) {
            prevObj.errIntersect=errIntersect;
        }
        if (errInfo) {
            prevObj.errInfo=errInfo;
        }
    }

    const oncancel = () => {
        if (prevObj.fileName && prevObj.fileName.length>0) {        
            setFileName(prevObj.fileName);
            setCoordsSystem(prevObj.srs);
            setData(prevObj.data);
            setSvg(prevObj.svg);
            setArea(prevObj.area);
            setGeometry(prevObj.geom);

            if (prevObj.errIntersect) {
                setErrIntersect(prevObj.errIntersect)
            } else {
                setErrIntersect([]);
            }

            if (prevObj.errInfo) {
                setErrInfo(prevObj.errInfo)
            } else {
                setErrInfo([]);
            }
            loadFuture(prevObj.geom);
        }
        setActive(false);
    }

    const showOnMap = () => {
        setActive(false);
        loadFuture();
    }

    const loadFuture = (geometry = geom) => {
        let feature = new WKT().readFeature(geometry);
        feature.set("temp", true);
        handleLoadFeature(feature.getGeometry());
        setMode(MODE_SELECT);
    }


    const style = btnVisible ? {display: 'block'} : {display: 'none'};
    const addStyle = btnVisible ? {display: 'none'} : {display: 'block'};
    const panelStyle = fileName ===t("coordinates.doc") ? {display: 'flex'} : {display: 'none'};
    const systemVisible = fileName ===t("coordinates.doc") || errIntersect.length>0 ? {display: 'none'} : {display: 'flex'};
    const coordSystem = useInput('', {isEmpty: true})
    
    return (
        <div className={active ? "coordinates_modal active" : "coordinates_modal"} onClick={() => setActive(false)}>
            <CoordinatesContent 
                onClick={e => e.stopPropagation()}
                errInfo={errInfo}
            >

                
                <div className="coords-wrapper">
                    <div className="coords-info">

                        <div className="templates">
                            {templates.map((item,i) => 
                                <div key={i} className="templates__item">
                                    <button onClick={()=> getTemplate(item.ext)}>
                                        {item.name}
                                    </button>
                                </div>
                            )}                    
                        </div>

                        <div className="input__wrapper">
                            <input
                                accept='application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/plain, application/zip'
                                type="file" 
                                name="file" 
                                id="input__file" 
                                className="input input__file" 
                                onChange={e => {
                                    e.target.files.length !== 0 && imporFile(e)
                                }}
                                onClick={e => e.target.value = null}
                            />
                            <label className="field__file-wrapper" htmlFor="input__file">
                                <div className="field__file-fake">{fileName}</div>
                                <div className="field__file-button">{t("coordinates.import")}</div>
                            </label>
                        </div>
                
                        {dataLoading ? 
                            <div className="data-loading"><ClipLoader color={"#02c23e"} loading={true} size={"50px"}/></div>
                        :
                        <>
                        {geom.length>0 && errIntersect.length>0 ?
                            <Errors>
                                <h2 style={{background: "linear-gradient(rgb(237, 28, 36) 0%, rgb(193, 39, 45) 100%)"}}>
                                    {t("egov-service.errors")}
                                </h2>
                                {errIntersect.map((err, i) => <p key={i}>{err.msgRu} </p>)}
                            </Errors>
                        :
                        <>
                            {geom.length>0 && errInfo.length>0 &&
                                <Errors>
                                    <h2 style={{background: "linear-gradient(rgb(237, 28, 36) 0%, rgb(193, 39, 45) 100%)"}}>
                                        {t("egov-service.errors")}
                                    </h2>
                                    {errInfo.map((err, i) => <p key={i}>{err.msgRu} </p>)}
                                </Errors>
                            }
                            {(coordSystem.isDirty && coordSystem.isEmpty) && <div style={{color: 'red'}}>{coordSystem.emptyError}</div>}
                            <div className="coords_system" style={systemVisible}>
                            
                                <p>{t("coordinates.system")}*</p>
                                <input type="text" 
                                    className="coords_system__input"
                                    name="coordSystem"
                                    value={coordsSystem}
                                    onChange={(e) => {
                                        coordSystem.onChange(e);
                                        setCoordsSystem(e.target.value)
                                    }}
                                    onBlur={(e) => coordSystem.onBlur(e)}
                                    />
                            </div>
                            <div className="coords_area" style={systemVisible}>
                                <h2>{t("coordinates.area")}</h2>
                                <p>{area}</p>
                            </div>

                            <div className="order-table" style={systemVisible}>
                                <table>
                                    <thead>
                                    <tr className="even">
                                        <th style={{ width: "7%" }}><p>#</p></th>
                                        <th style={{ width: "8%" }}><p>{t("coordinates.part")}</p></th>
                                        <th style={{ width: "30%" }}><p>X</p></th>
                                        <th style={{ width: "30%" }}><p>Y</p></th>
                                        <th style={{ width: "25%" }}><p>{t("coordinates.lines")}</p></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((item, i) => {
                                            return (
                                            <tr key={i}>
                                                <td style={{ width: "7%" }}>
                                                    <input 
                                                        className="border"
                                                        type="number"
                                                        defaultValue={item.number}
                                                        disabled={true} 
                                                    />
                                                </td>
                                                <td style={{ width: "8%" }}>
                                                    <input 
                                                        className="border"
                                                        type="number"
                                                        defaultValue={item.part} 
                                                        disabled={true}
                                                    />
                                                </td>
                                                <td style={{ width: "30%" }}>
                                                    <input 
                                                        className="border"
                                                        type="number"
                                                        defaultValue={item.x} 
                                                        disabled={true}
                                                    />
                                                </td>
                                                <td style={{ width: "30%" }}>
                                                    <input 
                                                        className="border"
                                                        type="number"
                                                        defaultValue={item.y} 
                                                        disabled={true}
                                                    />
                                                </td>
                                                <td style={{ width: "25%" }} >
                                                    <input 
                                                        className="border-r"
                                                        type="number"
                                                        defaultValue={data[i-1] ? calculateDistance(i) : ""} 
                                                        disabled={true}
                                                    />
                                                </td>
                                            </tr>
                                            )
                                        })}

                                    </tbody>
                                </table>
                            </div>
                        </>
                        }
                        </>
                        }
                        
                    </div>
                    <div className="coords-svg">
                        <h3 className="coords-svg_title">{t("coordinates.plan")}</h3>
                        <div className="coords-svg_item">
                            {svgLoading ? <div className="loading"><ClipLoader color={"#02c23e"} loading={true} size={"50px"}/></div> : parse(svg)}
                        </div>
                        {svg && <button className="coords-map" onClick={showOnMap}>Показать на карте</button>}
                        
                    </div>
                
                </div>
                

            </CoordinatesContent>

            <div className="decision-wrapper" onClick={e => e.stopPropagation()}>
                <div className="decision">
                    <DecisionBtn
                        color={'#008B78'}
                        onClick={onSave}
                        disabled={fileName === t("coordinates.doc") && geom.length==0 || data.length==0 || errIntersect.length>0 || !coordsSystem ? true : geom.length>0 ? false : true}
                        // disabled={ate.length>0 ? false : true}
                        >
                        {t("coordinates.save")}
                    </DecisionBtn>
                    <DecisionBtn
                        color={'#e5383b'}
                        onClick={oncancel}>
                        {t("coordinates.cancel")}
                    </DecisionBtn>
                </div>
            </div>

        </div>
    )
}

export default Coordinates;